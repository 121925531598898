import { LayoutDto, LayoutType } from '../interfaces';
import { BaseCommand } from './base.command';

export interface ApplyLayoutEvent {
  sectionId: string;
  templateLayoutId: number;
  layoutType: LayoutType;
  layoutData: LayoutDto;
}

export class ApplyLayoutToSectionCommand extends BaseCommand<ApplyLayoutEvent> {
  run({ sectionId, templateLayoutId, layoutType, layoutData }: ApplyLayoutEvent) {
    if (!sectionId || !this.source.sections[sectionId]) {
      return this.error(`Section with id ${sectionId} not found.`);
    }

    this.applyLayout(sectionId, templateLayoutId, layoutType, layoutData)

    this.duplicateAssetsForSection(sectionId);

    return this.ok();
  }
}
