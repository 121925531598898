<form class="login-form">

  <span class="login-title">Create your account</span>
  <p class="sub-title-gray">Create branded videos with no time.</p>
  <p style="margin: 8px"></p>

  <input id="username" style="height: 0; width: 0; border: none; background: none; margin: 0 !important; padding: 0 !important; margin-top: -4px"
         type="email" name="fakeemailremembered">

  <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Enter Email Address" [formControl]="emailFC" type="email" [(ngModel)]="email" name="fakeemailremembered">
      <mat-error *ngIf="emailFC.invalid">{{emailFC.errors.message}}</mat-error>
  </mat-form-field>

  <button class="mat-button-large" mat-flat-button color="primary" type="submit" (click)="magicLink()"
          [ngStyle]="{'margin-top': emailFC.invalid ? '12px' : 'auto'}"
          [disabled]="disabled" style="margin-bottom: 18px">Get Magic Link</button>

 <!--  <button class="mat-button-large" style="padding: 0.1rem;" mat-stroked-button (click)="signUpWithGoogle()">
    <mat-icon style="width: 50px; height:30px;"
              svgIcon="g-sso"></mat-icon>
    Sign up with Google</button> -->
</form>


<div class="footer-note">
  <p><span class="sub-title-gray">Already have an account? <a [routerLink]="loginRoute" >Sign in</a></span></p>
  <p><span class="sub-title-gray">I have a Session ID. <a routerLink="/subject/MODE_OTP">Enter Code Here</a></span></p>
</div>

