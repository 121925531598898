import {
  WorkflowDataDto,
  TrimmerData,
  getSectionTypeTimelines,
  getMaxTimelinesDuration,
} from '@openreel/creator/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common';
import { TrimData } from '../../../timelines-canvas/services/canvas.service';
import { BehaviorSubject } from 'rxjs';
import { UpdateTrimBoundsEvent } from '../../../timelines-canvas/canvas.interfaces';
import { generateTrimmerWorkflow } from '../../helpers/generateTrimmerWorkflow';

@Component({
  selector: 'openreel-wf-trimmer-split',
  templateUrl: './trimmer-split.component.html',
  styleUrls: ['./trimmer-split.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrimmerSplitComponent extends Cleanupable implements OnChanges {
  @Input() data: TrimmerData;
  @Input() allowDelete = false;
  @Input() trimmers: TrimData[];
  @Input() loadedProjectWorkflow: WorkflowDataDto;

  @Output() trim = new EventEmitter<UpdateTrimBoundsEvent>();
  @Output() remove = new EventEmitter<void>();

  private timelinesWorkflow = new BehaviorSubject<Partial<WorkflowDataDto>>(null);
  workflow$ = this.timelinesWorkflow.asObservable();

  timelines$ = this.timelinesWorkflow.asObservable().pipe(
    map((workflow) => {
      const timelines = getSectionTypeTimelines(workflow.sections, 'main');
      return { hardReload: true, timelines };
    })
  );
  durations$ = this.timelines$.pipe(
    map(({ timelines }) => {
      const duration = getMaxTimelinesDuration(timelines);
      return {
        intro: 0,
        outro: 0,
        main: duration,
        total: duration,
      };
    })
  );

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.update();
    }
  }

  onTrim = (event: UpdateTrimBoundsEvent) => this.trim.emit(event);
  onRemove = () => this.remove.emit();

  private update() {
    const workflow = generateTrimmerWorkflow(this.data, false);
    this.timelinesWorkflow.next(workflow);
  }
}
