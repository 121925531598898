import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  AudioDevice,
  AuthService,
  DirectorSessionInfo,
  DirectorSocketService,
  EVT_TRANSCODE_STATUS_CHANGE,
  filterDevices,
  getBlobVideoDuration,
  LocalRecorderService,
  SessionApiService,
  SessionType,
  startScreenStream,
  startStream,
  stopStream,
  TranscodeStatus,
  UploadFileNetworkStatus,
  VideoDevice,
  VideoService,
} from '@openreel/common';
import { forkJoin, from, fromEvent, merge, of, Subscription } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromActions from '../actions/self-record.actions';
import { SelfRecordFacade } from '../facades/self-record.facade';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { ProjectFacade } from '../../../../store/facades/project.facade';
import { RecordingUploadService } from '@openreel/creator/app/core/services/recording-upload.service';
import { Store } from '@ngrx/store';
import { commonenv } from '@openreel/common/env/environment';
import { SelfRecordTeleprompterFacade } from '../facades/self-record-teleprompter.facade';
import { Router } from '@angular/router';
import { noOp } from '../../../../store/actions/global.actions';
import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';
import { SelfRecordingVideo } from '../interfaces/self-record.interfaces';

const DEFAULT_VIDEO_CONSTRAINTS: MediaTrackConstraints = {
  width: { ideal: 1920 },
  height: { ideal: 1080 },
  frameRate: { ideal: 30 },
  aspectRatio: 1.77777777778, // 16/9
};

@Injectable()
export class SelfRecordEffects {
  screenRecorder: LocalRecorderService;
  webcamRecorder: LocalRecorderService;
  subscriptions: Subscription[] = [];
  wsSubscriptions: Subscription[] = [];

  private navigatorDeviceChange$ = merge(
    fromEvent(navigator.mediaDevices, 'ondevicechange')
  ).pipe(tap(() => of(fromActions.requestDevicesList())));

  cleanup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cleanup),
      mergeMap(() => {
        this.subscriptions.forEach((subscription) =>
          subscription.unsubscribe()
        );
        this.subscriptions = [];
        this.wsSubscriptions.forEach((subscription) =>
          subscription.unsubscribe()
        );
        this.wsSubscriptions = [];
        this.screenRecorder.stopRecording();
        this.screenRecorder.removeFileData(this.screenRecorder.lastFileName);
        this.webcamRecorder.stopRecording();
        this.webcamRecorder.removeFileData(this.webcamRecorder.lastFileName);
        this.uploader.cancelAllUploads();
        this.socket.ngOnDestroy();
        return [
          fromActions.closeOpenStreams({ restartStream: false }),
          fromActions.cleanupSuccess(),
        ];
      })
    )
  );

  initSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.init),
      withLatestFrom(this.projectFacade.captureProjectId$),
      switchMap(([, captureProjectId]) =>
        from(
          this.sessionService.getSessionList({
            limit: 1,
            project_id: captureProjectId,
            order_field: 'created_at',
            order_sort: 'asc',
          })
        )
      ),
      map((sessions) => {
        if (sessions.length > 0) {
          return fromActions.sessionSelect({
            sessionId: sessions[0].session_id,
            sessionAccountId: sessions[0].account_id,
          });
        }

        return noOp();
      })
    )
  );

  gotToSelfRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.gotToSelfRecord),
        withLatestFrom(this.projectFacade.id$),
        map(([options, projectId]) => {
          if (!options?.timelineIds || options?.timelineIds?.length === 0) {
            console.error('No target timelines for self record clips!');
            return;
          }

          const limit = options?.limit;
          const count = options?.count;
          this.router.navigate(['project', projectId, 'recording'], {
            queryParams: {
              limit,
              count,
              timelineIds: options?.timelineIds.join(','),
            },
          });
        })
      ),
    { dispatch: false }
  );

  cancelVideoUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelVideoUpload),
      withLatestFrom(
        this.selfRecordFacade.uploadWebcamVideo$,
        this.selfRecordFacade.uploadScreenVideo$
      ),
      map(([{ videoId }, selectUploadWebcamVideo, selectUploadScreenVideo]) => {
        try {
          this.uploader.cancelVideoUpload(videoId);
        } catch (error) {
          console.error(error);
        }

        if (selectUploadWebcamVideo?.videoId === videoId) {
          return fromActions.cancelWebcamUpload();
        }
        if (selectUploadScreenVideo?.videoId === videoId) {
          return fromActions.cancelScreenUpload();
        }

        return fromActions.reset(); //fallback in case of error when canceling
      })
    )
  );

  requestDevicesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.requestDevicesList),
      switchMap(() =>
        //this is required for first time camera/mic permission
        from(navigator.mediaDevices.getUserMedia({ video: true, audio: true }))
      ),
      switchMap((stream) => {
        stopStream(stream);
        return from(this.getDevices());
      }),
      catchError((error) => {
        this.selfRecordFacade.updateWebcamStreamStatus('denied');
        throw error;
      }),
      withLatestFrom(this.selfRecordFacade.status$),
      filter(([, status]) => status !== 'cleanup'),
      withLatestFrom(
        this.selfRecordFacade.selectedAudioDevice$,
        this.selfRecordFacade.selectedWebcamDevice$
      ),
      switchMap(
        ([
          [{ videoDevices, audioDevices }],
          selectedAudioDevice,
          selectedWebcamDevice,
        ]) => {
          const actions: (
            | ({
                devices: VideoDevice[];
              } & TypedAction<string>)
            | ({
                devices: AudioDevice[];
              } & TypedAction<string>)
            | ({
                device: VideoDevice;
              } & TypedAction<string>)
            | ({
                videoDevice: VideoDevice;
                audioDevice: AudioDevice;
              } & TypedAction<string>)
            | ({
                device: AudioDevice;
              } & TypedAction<string>)
          )[] = [
            fromActions.setWebcamDevices({ devices: videoDevices }),
            fromActions.setAudioDevices({ devices: audioDevices }),
          ];

          if (
            selectedWebcamDevice?.id !== videoDevices[0]?.id &&
            selectedAudioDevice?.id !== audioDevices[0]?.id
          ) {
            actions.push(
              fromActions.setSelectedAudioVideoDevice({
                videoDevice: videoDevices[0],
                audioDevice: audioDevices[0],
              })
            );
          } else if (selectedWebcamDevice?.id !== videoDevices[0]?.id) {
            actions.push(
              fromActions.setSelectedWebcamDevice({ device: videoDevices[0] })
            );
          } else if (selectedAudioDevice?.id !== audioDevices[0]?.id) {
            actions.push(
              fromActions.setSelectedAudioDevice({ device: audioDevices[0] })
            );
          }

          return actions;
        }
      )
    )
  );

  closeOpenStreams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.closeOpenStreams),
      withLatestFrom(
        this.selfRecordFacade.source$,
        this.selfRecordFacade.webcamStream$,
        this.selfRecordFacade.webcamStreamStatus$,
        this.selfRecordFacade.screenStream$,
        this.selfRecordFacade.screenStreamStatus$
      ),
      switchMap(
        ([
          { restartStream },
          source,
          webcamStream,
          webcamStreamStatus,
          screenStream,
          screenStreamStatus,
        ]) => {
          const actions = [];

          if (webcamStream) {
            stopStream(webcamStream);
            actions.push(fromActions.closeWebcamStreamSuccess());
          } else if (webcamStreamStatus) {
            actions.push(fromActions.closeWebcamStreamSuccess());
          }

          if (screenStream) {
            stopStream(screenStream);
            actions.push(fromActions.closeScreenStreamSuccess());
          } else if (screenStreamStatus) {
            actions.push(fromActions.closeScreenStreamSuccess());
          }

          if (restartStream) {
            switch (source) {
              case 'camera': {
                actions.push(fromActions.openWebcamStream());
                break;
              }
              case 'screen': {
                actions.push(fromActions.openScreenStream());
                break;
              }
              case 'both-pip': {
                actions.push(fromActions.openWebcamStream());
                actions.push(fromActions.openScreenStream());
                break;
              }
            }
          }

          return actions;
        }
      )
    )
  );

  reset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.reset),
        tap(() => {
          this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
          );
          this.subscriptions = [];
        })
      ),
    { dispatch: false }
  );

  updateStreams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.setSource,
        fromActions.reset,
        fromActions.retry,
        fromActions.setSelectedWebcamDevice,
        fromActions.setSelectedAudioDevice,
        fromActions.setSelectedAudioVideoDevice
      ),
      map(() => fromActions.closeOpenStreams({ restartStream: true }))
    )
  );

  openScreenshareStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openScreenStream),
      withLatestFrom(this.selfRecordFacade.screenStream$),
      switchMap(([, stream]) => {
        stopStream(stream);
        return from(
          startScreenStream({ audio: false, video: true,})
        );
      }),
      catchError((error) => {
        this.selfRecordFacade.updateScreenStreamStatus('denied');
        throw error;
      }),
      withLatestFrom(this.selfRecordFacade.screenStreamStatus$),
      map(([stream, screenStreamStatus]) => {
        if (screenStreamStatus !== 'initializing') {
          stopStream(stream);
          console.error('Stream should not have been opened, closing it.');
          return null;
        }
        return stream;
      }),
      filter((stream) => !!stream),
      withLatestFrom(
        this.selfRecordFacade.selectedAudioDevice$
      ),
      switchMap(([screenStream, selectedAudioDevice]) =>
        from(
          startStream({
            audio: { deviceId: selectedAudioDevice?.id },
            video: false,
          })
        ).pipe(
          catchError((error) => {
            this.selfRecordFacade.updateScreenStreamStatus('denied');
            throw error;
          }),
          withLatestFrom(this.selfRecordFacade.screenStreamStatus$),
          map(([stream, screenStreamStatus]) => {
            if (screenStreamStatus !== 'initializing' && screenStreamStatus !== 'playing') {
              stopStream(stream);
              stopStream(screenStream);
              console.error(
                'Stream should not have been opened, closing it.'
              );
              return null;
            }
            return stream;
          }),
          map((audioStream) => {
            const tracks = audioStream?.getAudioTracks();
            if (tracks?.length) {
              screenStream.addTrack(tracks[0]);
            } else {
              console.warn('No audio stream available');
            }
            return fromActions.openScreenStreamSuccess({
              stream: screenStream,
            });
          })
        )
      )
    )
  );

  openWebcamStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openWebcamStream),
      withLatestFrom(
        this.selfRecordFacade.webcamStream$,
        this.selfRecordFacade.selectedAudioDevice$,
        this.selfRecordFacade.selectedWebcamDevice$
      ),
      switchMap(([, stream, selectedAudioDevice, selectedWebcamDevice]) => {
        stopStream(stream);
        return from(
          startStream({
            audio: { deviceId: selectedAudioDevice?.id },
            video: {
              ...DEFAULT_VIDEO_CONSTRAINTS,
              deviceId: selectedWebcamDevice?.id,
            },
          })
        );
      }),
      catchError((error) => {
        this.selfRecordFacade.updateWebcamStreamStatus('denied');
        throw error;
      }),
      withLatestFrom(
        this.selfRecordFacade.webcamStreamStatus$,
        this.selfRecordFacade.status$
      ),
      map(([stream, webcamStreamStatus, status]) => {
        if (
          (webcamStreamStatus !== 'initializing' &&
            webcamStreamStatus !== 'playing') ||
          status === 'cleanup'
        ) {
          stopStream(stream);
          console.error('Stream should not have been opened, closing it.');
          return null;
        }
        return stream;
      }),
      filter((stream) => !!stream),
      map((stream) => fromActions.openWebcamStreamSuccess({ stream }))
    )
  );

  toggleRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleRecording),
      withLatestFrom(this.selfRecordFacade.status$),
      map(([, status]) => {
        if (status === 'idle') {
          return fromActions.startRecording();
        } else if (status === 'recording') {
          return fromActions.stopRecording();
        }

        return noOp();
      })
    )
  );

  startRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startRecording),
      withLatestFrom(
        this.selfRecordFacade.webcamStream$,
        this.selfRecordFacade.screenStream$,
        this.projectFacade.id$
      ),
      map(([, webcamStream, screenStream, projectId]) => {
        let canStart = false;

        if (webcamStream?.active) {
          this.webcamRecorder.startRecordingStream(
            null,
            webcamStream,
            `IV_${projectId}_${this.screenRecorder.getNewFileName('webcam')}`
          );
          canStart = true;
        }

        if (screenStream?.active) {
          this.screenRecorder.startRecordingStream(
            null,
            screenStream,
            `IV_${projectId}_${this.screenRecorder.getNewFileName('screen')}`
          );
          canStart = true;
        }

        if (canStart) {
          this.selfRecordTeleprompterFacade.updateStatus('playing');
          return fromActions.startRecordingSuccess();
        }

        return noOp();
      })
    )
  );

  startRecordingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startRecordingSuccess),
      withLatestFrom(this.selfRecordFacade.sessionId$),
      map(([, sessionId]) => {
        if (!sessionId) {
          return fromActions.sessionCreate();
        }

        return noOp();
      })
    )
  );

  stopRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.stopRecording),
      mergeMap(() =>
        forkJoin([
          from(this.screenRecorder.stopRecording()),
          from(this.webcamRecorder.stopRecording()),
        ])
      ),
      switchMap(() => {
        this.selfRecordTeleprompterFacade.updateStatus('stopped');

        return [
          fromActions.stopRecordingSuccess(),
          fromActions.startUploading(),
        ];
      })
    )
  );

  startUploading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startUploading),
      withLatestFrom(
        this.selfRecordFacade.webcamStream$,
        this.selfRecordFacade.screenStream$
      ),
      switchMap(([, webcamStream, screenStream]) =>
        this.selfRecordFacade.sessionId$.pipe(
          filter((sessionId) => !!sessionId),
          take(1),
          switchMap(() => {
            const actions = [];

            if (webcamStream) {
              actions.push(fromActions.startUploadWebcam());
            }

            if (screenStream) {
              actions.push(fromActions.startUploadScreen());
            }

            return actions;
          })
        )
      )
    )
  );

  sessionCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sessionCreate),
      withLatestFrom(
        this.projectFacade.projectName$,
        this.projectFacade.captureProjectId$
      ),
      switchMap(([, wfProjectName, captureProjectId]) => {
        const member = {
          member_id: this.authService.getUserDetails().data.user_id,
          role: 'editor',
        };

        return from(
          this.sessionService.createSession({
            session_name: wfProjectName,
            members: [member],
            project_id: captureProjectId,
            session_type: SessionType.SelfRecord,
          })
        );
      }),
      map((session) => {
        if (session) {
          return fromActions.sessionCreated({
            sessionId: session.session_id,
            sessionAccountId: session.account_id,
          });
        }

        return noOp();
      })
    )
  );

  sessionCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.sessionCreated, fromActions.sessionSelect),
        tap(({ sessionId, sessionAccountId }) => {
          const subscription = this.connectWebSocket(
            sessionId,
            sessionAccountId
          ).subscribe(({ data }) => {
            this.store.dispatch(
              fromActions.transcodeWebcamProgress({ transcodeStatus: data })
            );
            this.store.dispatch(
              fromActions.transcodeScreenProgress({ transcodeStatus: data })
            );
          });

          this.wsSubscriptions.push(subscription);
        })
      ),
    { dispatch: false }
  );

  transcodeFinish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.transcodeWebcamFinish,
        fromActions.transcodeScreenFinish
      ),
      withLatestFrom(
        this.selfRecordFacade.transcodeScreenStatus$,
        this.selfRecordFacade.uploadScreenStatus$,
        this.selfRecordFacade.transcodeWebcamStatus$,
        this.selfRecordFacade.uploadWebcamStatus$
      ),
      map(
        ([
          ,
          transcodeScreenStatus,
          uploadScreenStatus,
          transcodeWebcamStatus,
          uploadWebcamStatus,
        ]) => {
          if (
            !transcodeScreenStatus &&
            !uploadScreenStatus &&
            !transcodeWebcamStatus &&
            !uploadWebcamStatus
          ) {
            return fromActions.transcodeFinish();
          }

          return noOp();
        }
      )
    )
  );

  transcodeFinishedMixPanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.transcodeFinish),
        withLatestFrom(
          this.selfRecordFacade.source$,
          this.selfRecordFacade.lastUploadWebcamVideo$,
          this.selfRecordFacade.lastUploadScreenVideo$
        ),
        tap(([, source, lastUploadWebcamVideo, lastUploadScreenVideo]) => {
          this.mixpanelService.logEvent(
            'self_record_create',
            'A new self recording has been created successfully',
            {
              recording_type: source,
              duration_s:
                lastUploadWebcamVideo?.duration ||
                lastUploadScreenVideo?.duration,
            }
          );
        })
      ),
    { dispatch: false }
  );

  applyTrimToSelfRecordClipsMixPanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.applyTrimToSelfRecordClips),
        tap(() => {
          this.mixpanelService.logEvent(
            'self_record_trim',
            'Trim self recording'
          );
        })
      ),
    { dispatch: false }
  );

  // Webcam
  startUploadingWebcam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startUploadWebcam),
      withLatestFrom(this.selfRecordFacade.sessionId$),
      switchMap(([, sessionId]) =>
        from(
          this.startStreamUpload(
            this.webcamRecorder,
            sessionId,
            fromActions.uploadWebcamFinish,
            fromActions.uploadWebcamProgress
          )
        ).pipe(
          map(({ uploadBlob, uploadVideoId, uploadVideoDuration }) =>
            fromActions.startUploadWebcamSuccess({
              uploadBlob,
              uploadVideoId,
              uploadVideoDuration,
            })
          )
        )
      )
    )
  );

  startUploadWebcamSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.startUploadWebcamSuccess),
        withLatestFrom(this.selfRecordFacade.webcamStream$),
        tap(([, webcamStream]) => {
          stopStream(webcamStream);
        })
      ),
    { dispatch: false }
  );

  transcodeWebcamProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.transcodeWebcamProgress),
      withLatestFrom(
        this.selfRecordFacade.transcodeWebcamStatus$,
        this.selfRecordFacade.uploadWebcamVideo$,
      ),
      switchMap(([, transcodeWebcamStatus, uploadWebcamVideo]) => {
        if (transcodeWebcamStatus?.status === 'ready') {
          return this.videoService.getVideo(uploadWebcamVideo.videoId).pipe(
            map((video): SelfRecordingVideo => ({
              ...uploadWebcamVideo,
              duration: video.video_length,
            }))
          );
        }
        return [];
      }),
      switchMap((uploadWebcamVideo) => {
        if (uploadWebcamVideo) {
          return [fromActions.transcodeWebcamFinish({ uploadWebcamVideo })];
        }
        return [];
      })
    )
  );

  cancelWebcamUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelWebcamUpload),
      withLatestFrom(this.selfRecordFacade.uploadScreenStatus$),
      switchMap(([, uploadScreenStatus]) => {
        if (uploadScreenStatus === null) {
          return [fromActions.reset()];
        }
        return [];
      })
    )
  );

  // Screen
  startUploadingScreen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startUploadScreen),
      withLatestFrom(this.selfRecordFacade.sessionId$),
      switchMap(([, sessionId]) =>
        from(
          this.startStreamUpload(
            this.screenRecorder,
            sessionId,
            fromActions.uploadScreenFinish,
            fromActions.uploadScreenProgress
          )
        ).pipe(
          map(({ uploadBlob, uploadVideoId, uploadVideoDuration }) =>
            fromActions.startUploadScreenSuccess({
              uploadBlob,
              uploadVideoId,
              uploadVideoDuration,
            })
          )
        )
      )
    )
  );

  startUploadScreenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.startUploadScreenSuccess),
        withLatestFrom(this.selfRecordFacade.screenStream$),
        tap(([, screenStream]) => {
          stopStream(screenStream);
        })
      ),
    { dispatch: false }
  );

  transcodeScreenProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.transcodeScreenProgress),
      withLatestFrom(
        this.selfRecordFacade.transcodeScreenStatus$,
        this.selfRecordFacade.uploadScreenVideo$,
      ),
      switchMap(([, transcodeScreenStatus, uploadScreenVideo]) => {
        if (transcodeScreenStatus?.status === 'ready') {
          return this.videoService.getVideo(uploadScreenVideo.videoId).pipe(
            map((video): SelfRecordingVideo => ({
              ...uploadScreenVideo,
              duration: video.video_length,
            }))
          );
        }
        return [];
      }),
      switchMap((uploadScreenVideo) => {
        if (uploadScreenVideo) {
          return [fromActions.transcodeScreenFinish({ uploadScreenVideo })];
        }
        return [];
      })
    )
  );

  cancelScreenUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelScreenUpload),
      withLatestFrom(this.selfRecordFacade.uploadWebcamStatus$),
      switchMap(([, uploadWebcamStatus]) => {
        if (uploadWebcamStatus === null) {
          return [fromActions.reset()];
        }
        return [];
      })
    )
  );

  constructor(
    private readonly socket: DirectorSocketService,
    private readonly videoService: VideoService,
    private readonly mixpanelService: MixpanelService,
    private readonly sessionService: SessionApiService,
    private readonly authService: AuthService,
    private readonly projectFacade: ProjectFacade,
    private readonly uploader: RecordingUploadService,
    private readonly selfRecordFacade: SelfRecordFacade,
    private readonly selfRecordTeleprompterFacade: SelfRecordTeleprompterFacade,
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly router: Router
  ) {
    this.screenRecorder = new LocalRecorderService();
    this.webcamRecorder = new LocalRecorderService();
  }

  private connectWebSocket(sessionId: number, sessionAccountId: number) {
    const sessionInfo: DirectorSessionInfo = {
      deviceType: 'WEB',
      userType: this.authService.role,
      accountId: String(sessionAccountId),
      token: this.authService.token$.value,
      session: String(sessionId),
      ovraId: null,
      identity: null,
    };

    this.socket.connect(
      commonenv.websocketUrl + '?access-token=' + sessionInfo.token
    );

    this.wsSubscriptions.push(
      this.socket.socket.connectionStatus$
        .pipe(
          filter((status) => status.connected),
          map((status) => status.connected)
        )
        .subscribe(() => {
          this.socket.joinRoom(sessionInfo);
        })
    );

    return this.socket.getSocketEventByName<TranscodeStatus>(
      EVT_TRANSCODE_STATUS_CHANGE
    );
  }

  private startStreamUpload(
    recorder: LocalRecorderService,
    sessionId: number,
    finishAction: ActionCreator<string, () => TypedAction<string>>,
    progressAction: ActionCreator<
      string,
      (props: { uploadStatus: UploadFileNetworkStatus }) => {
        uploadStatus: UploadFileNetworkStatus;
      } & TypedAction<string>
    >
  ) {
    return new Promise((resolve) => {
      this.uploader
        .upload(sessionId, recorder.newVideoInfo, recorder.lastFileName)
        .then((uploadFileInfo) => {
          const subscription = uploadFileInfo.status$.subscribe(
            (uploadStatus) => {
              if (uploadStatus.percentage === 1 || uploadStatus.isCanceled) {
                subscription.unsubscribe();
                this.store.dispatch(finishAction());
              } else {
                this.store.dispatch(progressAction({ uploadStatus }));
              }
            }
          );
          this.subscriptions.push(subscription);
          getBlobVideoDuration(uploadFileInfo.localFileData).then(
            (uploadVideoDuration) => {
              resolve({
                uploadBlob: uploadFileInfo.localFileData,
                uploadVideoId: uploadFileInfo.status$.value.videoId,
                uploadVideoDuration,
              });
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.store.dispatch(fromActions.reset());
        });
    });
  }

  private async getDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const { videoDevices, audioDevices } = filterDevices(devices);

    for (let i = 0; i < videoDevices.length; i++) {
      const stream = await startStream({
        video: {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          deviceId: videoDevices[i].id,
        },
      });
      const { width, height } = stream.getTracks()[0].getSettings();
      videoDevices[i].width = width;
      videoDevices[i].height = height;
    }

    videoDevices.sort((a, b) => b.height - a.height);

    return { videoDevices, audioDevices };
  }
}
