import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FileSelectorService {

    selectFile(accept: string = null): Observable<File> {
        return from(new Promise<File>(resolve => {
            const fileInputElement = document.createElement('input') as HTMLInputElement;
            fileInputElement.type = 'file';
            fileInputElement.multiple = false;
            fileInputElement.style.display = 'none';
            if (accept) fileInputElement.accept = accept;
            fileInputElement.addEventListener('change', () => {
                const file = fileInputElement.files[0];
                console.log('File "' + file.name + '" selected.');
                document.body.removeChild(fileInputElement);
                resolve(file);
            });
            document.body.appendChild(fileInputElement);
            setTimeout(_ => {
                fileInputElement.click();
                const onFocus = () => {
                    window.removeEventListener('focus', onFocus);
                    document.body.addEventListener('mousemove', onMouseMove);
                };
                const onMouseMove = () => {
                    document.body.removeEventListener('mousemove', onMouseMove);
                    if (!fileInputElement.files.length) {
                        document.body.removeChild(fileInputElement);
                        console.log('No file selected.');
                        resolve(null);
                    }
                }
                window.addEventListener('focus', onFocus);
            }, 0);
        }));
    }
}
