import { Injectable } from '@angular/core';
import { merge, BehaviorSubject, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  public online$: BehaviorSubject<boolean>;

  constructor() {
    this.online$ = new BehaviorSubject<boolean>(navigator.onLine);
    merge(
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    ).subscribe((status) => {
      this.online$.next(status);
    });
  }
}
