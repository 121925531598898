import { ToastrService } from 'ngx-toastr';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { User } from '../../models';
import { AuthService } from '../../services/auth/auth.service';
import { GUEST_MAGIC_LINK, GUEST_SELECT_ACCOUNT, ROUTE_FORGOT_PASSWORD } from '../../route-utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ILoginRespDto } from '../../interfaces/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { TfaPopupComponent } from '../tfa-popup/tfa-popup.component';

@Component({
  selector: 'openreel-director-login-form',
  templateUrl: './director-login-form.component.html',
  styleUrls: ['./director-login-form.component.scss'],
})
export class DirectorLoginComponent {
  public user: User = new User();
  hide = true;
  disabled = false;
  forgotPasswordRoute = '/' + ROUTE_FORGOT_PASSWORD;
  magicLinkRoute = '/' + GUEST_MAGIC_LINK;

  @Output()
  loginEvent = new EventEmitter();

  @Input()
  showCreateAccount = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  async loginClick() {
    this.disabled = true;
    try {
      const res: ILoginRespDto = await this.authService.login(this.user.username, this.user.password, true).toPromise();
      if (!res.data.tfaEnabled || res.data.token) {
        let redirect = this.activatedRoute.snapshot.queryParams?.redirect;
        if (this.activatedRoute.snapshot.fragment) {
          redirect += `#${this.activatedRoute.snapshot.fragment}`;
        }
        if (redirect) {
          this.router.navigateByUrl('/' + GUEST_SELECT_ACCOUNT + '?redirect=' + redirect);
        } else {
          this.router.navigate(['/' + GUEST_SELECT_ACCOUNT]);
        }
      } else {
        this.toastr.info(res.message, 'Info');
        this.dialog.open(TfaPopupComponent, {
          data: { jwtToken: res.data.jwtToken },
          maxWidth: '400px',
          disableClose: true,
        });
      }
    } catch (err) {
      const error = err.error && err.error.message ? err.error.message : err.message;
      this.toastr.error(error, 'Error!');
    }
    this.disabled = false;
  }
}
