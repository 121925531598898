import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as convert from 'xml-js';
interface XmlAttribute {
  [key: string]: string;
}
interface XmlElement {
  type: string;
  name: string;
  attributes: XmlAttribute;
  text?: string;
  elements: XmlElement[];
}
interface SsoConfig {
  issuer: string;
  entryPoint: string;
  certificate: string;
  firstNameKey: string;
  lastNameKey: string;
  fullNameKey: string;
  emailKey: string;
}
const issuerKeyName = 'EntityDescriptor';
const issuerAttr = 'entityID';

const entryPointKeyName = 'SingleSignOnService';
const entryPointAttr = 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST';

const certificateKeyNames = 'X509Certificate';

const claimAttr = 'ClaimType';
const emailClaim = 'emailaddress';
const firstNameClaim = 'givenname';
const lastNameClaim = 'surname';
const fullNameClaim = 'displayname';

const nameIdAttr = 'NameIDFormat';
const nameIdFormat = 'nameid-format:emailAddress';

@Injectable({
  providedIn: 'root',
})
export class SsoXmlParserService {
  ssoConfig: SsoConfig;
  constructor(private toastr: ToastrService) {}

  parseXml(xmlData: string) {
    this.ssoConfig = {
      issuer: '',
      entryPoint: '',
      certificate: '',
      firstNameKey: '',
      lastNameKey: '',
      fullNameKey: '',
      emailKey: '',
    };
    try {
      const result = convert.xml2json(xmlData, { compact: false, spaces: 4 });
      const obj = JSON.parse(result);
      if (obj.elements.length > 0) {
        this.fetchIssuer(obj.elements);
        this.fetchEntryPoint(obj.elements);
        this.fetchCertificate(obj.elements);
        this.fetchMetaData(obj.elements, 'emailKey', emailClaim);
        this.fetchMetaData(obj.elements, 'firstNameKey', firstNameClaim);
        this.fetchMetaData(obj.elements, 'lastNameKey', lastNameClaim);
        this.fetchMetaData(obj.elements, 'fullNameKey', fullNameClaim);
        if (!this.ssoConfig.emailKey) this.checkEmailInNameIdFormat(obj.elements);
      }
      return this.ssoConfig;
    } catch (error) {
      this.toastr.error('Unable to parse XML. Please upload a valid XML file', 'Error!');
    }
  }
  fetchIssuer(elements: XmlElement[]) {
    elements.forEach((element) => {
      const attributesFound = element.attributes && !!element.attributes[issuerAttr];
      if (issuerKeyName === this.getElementName(element.name) && attributesFound) {
        this.ssoConfig.issuer = element.attributes[issuerAttr];
      } else if (element.elements) {
        this.fetchIssuer(element.elements);
      }
    });
  }
  fetchCertificate(elements: XmlElement[]) {
    elements.forEach((element) => {
      if (this.getElementName(element.name) === certificateKeyNames && element.elements.length > 0) {
        this.ssoConfig.certificate = element.elements[0].text;
      } else if (element.elements) {
        this.fetchCertificate(element.elements);
      }
    });
  }
  fetchEntryPoint(elements: XmlElement[]) {
    elements.forEach((element) => {
      const attributesFound = element.attributes && element.attributes['Binding'] === entryPointAttr;
      if (entryPointKeyName === this.getElementName(element.name) && attributesFound) {
        this.ssoConfig.entryPoint = element.attributes['Location'];
      } else if (element.elements) {
        this.fetchEntryPoint(element.elements);
      }
    });
  }
  fetchMetaData(elements: XmlElement[], key: string, claim: string) {
    elements.forEach((element) => {
      if (this.getElementName(element.name) === claimAttr && element.attributes['Uri'].indexOf(claim) !== -1) {
        this.ssoConfig[key] = element.attributes['Uri'];
      } else if (element.elements) {
        this.fetchMetaData(element.elements, key, claim);
      }
    });
  }
  checkEmailInNameIdFormat(elements: XmlElement[]) {
    elements.forEach((element) => {
      if (
        this.getElementName(element.name) === nameIdAttr &&
        element.elements.length > 0 &&
        element.elements[0].text.indexOf(nameIdFormat) !== -1
      ) {
        this.ssoConfig.emailKey = 'nameID';
      } else if (element.elements) {
        this.checkEmailInNameIdFormat(element.elements);
      }
    });
  }
  getElementName(name: string) {
    if (!name) return null;
    const nameArr = name.split(':');
    if (nameArr.length > 1) {
      return nameArr[nameArr.length - 1];
    } else {
      return nameArr[0];
    }
  }
}
