import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  CaptureTeleprompterStatus,
  TeleprompterParticipant,
} from '@openreel/common/interfaces';

@Component({
  selector: 'openreel-teleprompter-participant',
  templateUrl: './teleprompter-participant.component.html',
  styleUrls: ['./teleprompter-participant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeleprompterParticipantComponent {
  CaptureTeleprompterStatus = CaptureTeleprompterStatus;

  @Input()
  device: TeleprompterParticipant;

  @Input()
  activationDisabled: boolean;

  @Input()
  previewDisabled: boolean;

  @Input()
  isCounting: boolean;

  @Output()
  previewClicked = new EventEmitter();

  @Output()
  teleprompterActiveToggle = new EventEmitter<{
    identities: string[];
    active: boolean;
  }>();

  @Output()
  playClick = new EventEmitter();

  @Output()
  stopClick = new EventEmitter();

  @Output()
  restartClick = new EventEmitter();

  @Output()
  pauseClick = new EventEmitter();

  get canStop(): boolean {
    return [
      CaptureTeleprompterStatus.COUNTING,
      CaptureTeleprompterStatus.PLAYING,
      CaptureTeleprompterStatus.PAUSED,
      CaptureTeleprompterStatus.IDLE,
    ].includes(this.device.teleprompterProperties.status);
  }

  get canPause(): boolean {
    return [
      CaptureTeleprompterStatus.COUNTING,
      CaptureTeleprompterStatus.PLAYING,
    ].includes(this.device.teleprompterProperties.status);
  }

  get canStart(): boolean {
    return (
      this.device.teleprompterProperties.isTeleprompterVisible &&
      ![
        CaptureTeleprompterStatus.PLAYING,
        CaptureTeleprompterStatus.COUNTING,
      ].includes(this.device.teleprompterProperties.status)
    );
  }
}
