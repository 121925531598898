import {
  Asset,
  FontAsset,
  GoogleFontAsset,
  ImageLayer,
  Layer,
  LayerOptions,
  LottieLayer,
  LottieLayerData,
  LottieLayerFieldData,
  RemoveFreemiumWatermarkCommand,
  Style,
  Timeline,
  TimelineType,
  VideoLayer,
  WorkflowDataDto,
  getColorLottie,
  getFontAssetWeight,
  LottieProcessedFieldsData,
  getColorLottieTransformation,
  getLayerFromId,
  Bounds,
} from '@openreel/creator/common';
import {
  ControllerData,
  ImagePlayerData,
  LottiePlayerData,
  PlayerData,
  TimelinesPlayerData,
  VideoPlayerData,
} from '@openreel/ui/openreel-cue-player';
import { EMPTY, Observable, combineLatest, from, of } from 'rxjs';
import { concatMap, filter, first, map, mergeMap, reduce, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { cloneDeep, isNumber } from 'lodash-es';
import { AssetsBaseService } from '@openreel/common';
import { Animation, Font } from '@openreel/lottie-parser';

const DEFAULT_WEIGHT = '400';
const DEFAULT_COLOR = '#000000';

const DEFAULT_LAYOUT: Bounds = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

export interface PlayerOptions {
  allowSelection: boolean;
  allowControls: boolean;
  showFreemiumWatermark: boolean;
}

export interface ControllerOptions {
  hasAudio?: boolean;
  zIndex?: number;
}

type LayerForPreview = Layer & {
  interaction: {
    selectable: boolean;
    zoomToFit: boolean;
    switch: boolean;
  };
};

export abstract class TemplatingBaseService {
  abstract apply<T>(fileAsset: Asset, values: unknown): Observable<T>;
}

@Injectable()
export class CuePlayerService {
  constructor(
    private readonly assetsService: AssetsBaseService,
    private readonly templatingService: TemplatingBaseService
  ) {}

  createPlayerData(
    workflow: WorkflowDataDto,
    timelines: Timeline[],
    options: PlayerOptions
  ): Observable<TimelinesPlayerData> {
    let updatedWorkflow = workflow;
    if (!options.showFreemiumWatermark) {
      const result = new RemoveFreemiumWatermarkCommand(workflow).run();
      updatedWorkflow = result.updatedWorkflow;
    }

    return this.toTimelinePlayer(
      updatedWorkflow,
      workflow.assets.reduce((acc, asset) => {
        acc[asset.id] = asset;
        return acc;
      }, {}),
      timelines,
      options
    );
  }

  private toTimelinePlayer(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    timelines: Timeline[],
    playerOptions: PlayerOptions
  ): Observable<TimelinesPlayerData> {
    const mainTimelines = this.getMainTimelineLayers(timelines).sort(
      ({ layers: layers1 }, { layers: layers2 }) =>
        this.getLastLayer(layers2)?.visibility?.endAt - this.getLastLayer(layers1)?.visibility?.endAt
    );
    const background = this.getTimelineLayers(timelines, 'background');
    const bRoll = this.getTimelineLayers(timelines, 'b-roll');
    const overlays = this.getTimelineLayers(timelines, 'overlays');
    const watermark = this.getTimelineLayers(timelines, 'watermark');
    const freemium = this.getTimelineLayers(timelines, 'freemium');

    return combineLatest(
      mainTimelines
        .map(({ layers, hasAudio, zIndex }) =>
          this.toControllerDataList(workflow, assets, layers ?? [], playerOptions, {
            hasAudio,
            zIndex,
          })
        )
        .concat([
          this.toControllerDataList(workflow, assets, background.layers, playerOptions, {
            hasAudio: background.hasAudio,
          }),
          this.toControllerDataList(workflow, assets, bRoll.layers, playerOptions, {
            hasAudio: bRoll.hasAudio,
          }),
          this.toControllerDataList(workflow, assets, overlays.layers, playerOptions, {
            hasAudio: overlays.hasAudio,
          }),
          this.toControllerDataList(workflow, assets, watermark.layers, playerOptions, {
            hasAudio: watermark.hasAudio,
          }),
          this.toControllerDataList(workflow, assets, freemium.layers, playerOptions, {
            hasAudio: freemium.hasAudio,
          }),
        ])
    ).pipe(
      map(
        (controllers) =>
          new TimelinesPlayerData({
            mainPlayers: controllers[0],
            backgroundPlayers: controllers[1],
            overlays: controllers.slice(2, controllers.length),
          })
      ),
      filter(
        (playerData) =>
          playerData.mainPlayers.length > 0 || playerData.overlays?.filter((overlay) => overlay.length > 0).length > 0
      )
    );
  }

  private getMainTimelineLayers(timelines: Timeline[]) {
    return timelines
      .filter((t) => t.type === 'main' && t.layers?.length)
      .map((t) => {
        const hasAudio = typeof t.hasAudio === 'boolean' ? t.hasAudio : false;
        const layers = t.layers.map(
          (l): LayerForPreview => ({
            ...l,
            interaction: {
              selectable: t.isLayerSelectable ?? false,
              zoomToFit: t.controls?.zoomToFit ?? false,
              switch: t.controls?.switch ?? false,
            },
          })
        );

        return { layers, hasAudio, zIndex: t.zIndex };
      });
  }

  private getLastLayer(layers: LayerForPreview[]): LayerForPreview | undefined {
    let lastLayer: LayerForPreview | undefined;
    layers.forEach((layer) => {
      if (layer?.visibility?.endAt ?? 0 > lastLayer?.visibility?.endAt ?? 0) {
        lastLayer = layer;
      }
    });

    return lastLayer;
  }

  private getTimelineLayers(timelines: Timeline[], type: TimelineType) {
    let layers: LayerForPreview[] = [];
    let hasAudio = false;
    timelines
      .filter((t) => t.type === type)
      .map((t) => {
        // TODO: since we flatten timelines, we take hasAudio property from last timeline of type
        // Dont flatten?
        hasAudio = t.hasAudio ?? false;

        return t.layers.map(
          (l): LayerForPreview => ({
            ...l,
            interaction: {
              selectable: t.isLayerSelectable ?? false,
              zoomToFit: t.controls?.zoomToFit ?? false,
              switch: t.controls?.switch ?? false,
            },
          })
        );
      })
      .forEach((l) => (layers = layers.concat(l)));

    return { layers, hasAudio };
  }

  private toControllerDataList(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layers: LayerForPreview[],
    playerOptions: PlayerOptions,
    options?: ControllerOptions
  ): Observable<ControllerData[]> {
    return from(layers).pipe(
      concatMap((layer) => [this.toControllerData(workflow, assets, layer, playerOptions, options)]),
      concatMap((data) => data),
      reduce((acc, val) => {
        acc.push(val);
        return acc;
      }, [] as ControllerData[])
    );
  }

  private toControllerData(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: LayerForPreview,
    playerOptions: PlayerOptions,
    options?: ControllerOptions
  ): Observable<ControllerData> {
    return this.toPlayerData(workflow, assets, layer, playerOptions, options).pipe(
      switchMap((playerData) => {
        const data: ControllerData = { playerData, layout: DEFAULT_LAYOUT };
        return this.addTransition(data, layer, workflow, assets, playerOptions);
      }),
      map((data) => {
        this.addExternalId(data, layer);
        this.addVisibility(data, layer);
        this.addPosition(data, layer);
        this.addStyles(data, layer);
        this.addSelection(workflow, data, layer, playerOptions);
        this.addControls(data, layer, playerOptions);
        return data;
      })
    );
  }

  private toPlayerData(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: LayerForPreview,
    playerOptions: PlayerOptions,
    options?: ControllerOptions
  ): Observable<PlayerData> {
    return of(layer).pipe(
      filter((l) => l.enabled === undefined || l.enabled),
      mergeMap((l) => {
        if (l.type === 'lottie') {
          return this.toLottiePlayer(workflow, assets, l);
        } else if (l.type === 'video') {
          return this.toVideoPlayer(assets, l, options);
        } else if (l.type === 'image') {
          return this.toImagePlayer(assets, l);
        } else if (l.type === 'section') {
          return this.toTimelinePlayer(workflow, assets, workflow.sections[l.sectionId].timelines, playerOptions);
        } else if (l.type === 'timelines') {
          return this.toTimelinePlayer(workflow, assets, l.children, playerOptions);
        }
        return EMPTY;
      })
    );
  }

  private toVideoPlayer(
    assets: { [key: string]: Asset },
    layer: LayerOptions & VideoLayer,
    options?: ControllerOptions
  ): Observable<VideoPlayerData> {
    const { file, trimFrom, trimTo } = assets[layer.assetId];
    const type = 'video/mp4';
    return this.assetsService.getAssetUrlById(file.provider, file.path).pipe(
      map(
        (url) =>
          new VideoPlayerData({
            source: url,
            loop: layer.loop,
            type,
            startAt: trimFrom,
            endAt: trimTo,
            hasAudio: Boolean(options?.hasAudio),
            zIndex: options?.zIndex ?? 0,
          })
      )
    );
  }

  private toImagePlayer(assets: { [key: string]: Asset }, layer: ImageLayer): Observable<ImagePlayerData> {
    const { file } = assets[layer.assetId];
    return this.assetsService.getAssetUrlById(file.provider, file.path).pipe(
      map(
        (url) =>
          new ImagePlayerData({
            source: url,
          })
      )
    );
  }

  toLottiePlayer(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: LayerOptions & LottieLayer
  ): Observable<LottiePlayerData> {
    const asset = assets[layer.assetId];

    if (!layer.data) {
      return of(
        new LottiePlayerData({
          source: asset.file.path as string,
          renderer: layer.renderer,
        })
      );
    }

    const dataForTransformation$ = this.getDataValuesTransformation(workflow, assets, layer);
    const preset$ = of(asset.preset);

    return this.getDataValues(workflow, assets, layer.data).pipe(
      concatMap((values) =>
        this.templatingService.apply<Animation>(asset, values).pipe(
          map(
            (animation) =>
              ({
                ...animation,
                fonts: { list: this.toLottieFonts(workflow) },
                values,
              } as unknown as Animation)
          ),
          withLatestFrom(dataForTransformation$, preset$),
          map(
            ([animation, dataForTransformation, preset]) =>
              new LottiePlayerData({
                animation,
                data: dataForTransformation,
                preset,
                loop: layer.loop,
                renderer: layer.renderer,
                duration:
                  !isNaN(layer.visibility?.startAt) && !isNaN(layer.visibility.endAt)
                    ? layer.visibility?.endAt - layer.visibility?.startAt
                    : asset?.data?.duration
                    ? asset.data.duration
                    : undefined,
              })
          )
        )
      )
    );
  }

  private getDataValuesTransformation(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: LottieLayer
  ): Observable<LottieProcessedFieldsData> {
    const layerData = layer.data;
    return from(Object.keys(layerData)).pipe(
      map((key) => ({ key, fieldData: layerData[key] })),
      filter(
        ({ fieldData }) => fieldData.assetId in assets || ['text', 'shape', 'hidden'].indexOf(fieldData.type) > -1
      ),
      concatMap(({ key, fieldData }) => {
        if (fieldData.type === 'text') {
          const style = workflow.styles.find((s) => s.id === fieldData.styleId);
          const color = this.getColor(style);
          return of({
            [key]: {
              value: fieldData.value,
              font: this.getFont(style, workflow.fonts),
              color: getColorLottieTransformation(color),
            },
          });
        } else if (fieldData.type === 'shape') {
          const style = workflow.styles.find((s) => s.id === fieldData.styleId);
          const color = this.getColor(style);
          return of({
            [key]: {
              color: getColorLottieTransformation(color, style?.colorShade),
            },
          });
        } else {
          const { file } = assets[fieldData.assetId];
          return this.assetsService.getAssetUrlById(file.provider, file.path).pipe(map((url) => ({ [key]: { url } })));
        }
      }),
      reduce((acc, val) => {
        Object.assign(acc, val);
        return acc;
      }, {})
    );
  }

  private getDataValues(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    data: LottieLayerData
  ): Observable<{ [key: string]: string | number }> {
    return from(Object.keys(data))
      .pipe(
        map((key) => ({ key, obj: data[key] })),
        filter(({ obj }) => obj.assetId in assets || ['text', 'shape', 'hidden'].indexOf(obj.type) > -1),
        concatMap(({ key, obj }) => {
          if (obj.type === 'text') {
            return this.getTextValues(key, obj, workflow);
          } else if (obj.type === 'shape') {
            return this.getShapeValues(key, obj, workflow);
          } else {
            return this.getImageValues(key, obj, assets);
          }
        }),
        reduce((acc, val) => {
          Object.assign(acc, val);
          return acc;
        }, {})
      )
      .pipe(
        map((values) => ({
          ...values,
          ...this.getBaseFontToken(workflow),
        }))
      );
  }

  private addPosition(data: ControllerData, layer: LayerForPreview) {
    if (!layer.bounds) {
      return;
    }

    data.layout = {
      x: layer.bounds.x,
      y: layer.bounds.y,
      width: layer.bounds.width,
    };

    if (isNumber(layer.bounds.height)) {
      data.layout.height = layer.bounds.height;
    }
  }

  private addExternalId(data: ControllerData, layer: LayerForPreview) {
    data.playerData.externalId = layer.layerId;
  }

  private addVisibility(data: ControllerData, layer: LayerForPreview) {
    if (layer.visibility) {
      data.cue = {
        startAt: layer.visibility.startAt,
        endAt: layer.visibility.endAt,
      };
    }
  }

  private addTransition(
    data: ControllerData,
    layer: LayerForPreview,
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    playerOptions: PlayerOptions
  ) {
    if (layer.transitions) {
      if (layer.transitions.crossLayer?.type === 'layer' && layer.transitions.crossLayer?.layer?.enabled) {
        return this.toPlayerData(
          workflow,
          assets,
          {
            ...layer.transitions.crossLayer.layer,
            interaction: { selectable: false, zoomToFit: false, switch: false },
          },
          playerOptions,
          { hasAudio: true }
        ).pipe(
          first(),
          map((playerData) => {
            data.transitions = {
              crossLayer: {
                type: 'layer',
                duration: layer.transitions.crossLayer.duration,
                playerData,
              },
            };
            return data;
          })
        );
      } else {
        const transitions = cloneDeep(layer.transitions);
        data.transitions = {
          entrance: transitions.entrance,
          exit: transitions.exit,
          cross: transitions.cross,
        };
      }
    }
    return of(data);
  }

  private addStyles(data: ControllerData, layer: LayerForPreview) {
    if (layer.styles) {
      data.styles = cloneDeep(layer.styles);
    }
  }

  private addSelection(
    workflow: WorkflowDataDto,
    data: ControllerData,
    layer: LayerForPreview,
    playerOptions: PlayerOptions
  ) {
    const isLayerSelectable = playerOptions.allowSelection && (layer.interaction?.selectable ?? false);
    data.selection = {
      selectable: isLayerSelectable,
    };

    if (isLayerSelectable) {
      const layerInfo = getLayerFromId(layer.layerId, workflow);

      if (layerInfo.timeline.bounds) {
        data.defaultLayout = layerInfo.timeline.bounds;
      }
    }
  }

  private addControls(data: ControllerData, layer: LayerForPreview, playerOptions: PlayerOptions) {
    data.controls = {
      zoomToFit: playerOptions.allowControls && (layer.interaction?.zoomToFit ?? false),
      switch: playerOptions.allowControls && (layer.interaction?.switch ?? false),
    };
  }

  private getTextValues(
    key: string,
    { value, styleId }: LottieLayerFieldData,
    { styles, fonts }: WorkflowDataDto
  ): Observable<{ [key: string]: string }> {
    const style = styles.find((s) => s.id === styleId);
    const fontKey = `${key}Font`;

    const color = this.getColor(style);

    return of({
      [key]: value,
      [fontKey]: this.getFont(style, fonts),
      ...getColorLottie(key, color, false),
    });
  }

  private getShapeValues(
    key: string,
    { styleId }: LottieLayerFieldData,
    { styles }: WorkflowDataDto
  ): Observable<{ [key: string]: string }> {
    const style = styles.find((s) => s.id === styleId);
    const color = this.getColor(style);

    return of({
      ...getColorLottie(key, color, true),
    });
  }

  private getHiddenValues(
    key: string,
    { styleId }: LottieLayerFieldData,
    { styles }: WorkflowDataDto
  ): Observable<{ [key: string]: string }> {
    const style = styles.find((s) => s.id === styleId);
    const color = this.getColor(style);

    return of({
      ...getColorLottie(key, color, false),
    });
  }

  private getImageValues(
    key: string,
    { assetId }: LottieLayerFieldData,
    assets: { [key: string]: Asset }
  ): Observable<{ [key: string]: string }> {
    const { file } = assets[assetId];
    return this.assetsService.getAssetUrlById(file.provider, file.path).pipe(map((url) => ({ [key]: url })));
  }

  private toLottieFonts({ fonts }: WorkflowDataDto): Font[] {
    const lottieFonts: Font[] = [];
    fonts.forEach((font) => {
      if (font.weights) {
        font.weights
          .filter((weight) => weight === '400' || weight === '700')
          .forEach((weight) => {
            lottieFonts.push({
              origin: 0,
              fFamily: font.family,
              fName: `${font.family}_${weight}`,
              ascent: 75.9994506835938,
              fStyle: 'normal',
              fWeight: weight,
              fPath: '',
            });
          });
      } else {
        lottieFonts.push({
          origin: 0,
          fFamily: font.family,
          fName: `${font.family}_${(font as GoogleFontAsset).weight}`,
          ascent: 75.9994506835938,
          fStyle: 'normal',
          fWeight: (font as GoogleFontAsset).weight,
          fPath: '',
        });
      }
    });

    return lottieFonts;
  }

  // TODO: can be removed with handlebars
  private getBaseFontToken(workflow: WorkflowDataDto) {
    const fontIndex = workflow.globalSettings.fontIndex ?? 0;
    const fontWeight = +DEFAULT_WEIGHT;

    return {
      font: this.getFont({ fontIndex, fontWeight }, workflow.fonts),
    };
  }

  private getFont(style: Partial<Style>, fonts: FontAsset[]) {
    const fontIndex = style?.fontIndex ?? 0;
    const font = fonts[Math.min(fontIndex, fonts.length - 1)];

    const fontFamily = font.family;
    const fontWeight = style?.fontWeight || getFontAssetWeight(font) || DEFAULT_WEIGHT;

    return `${fontFamily}_${fontWeight}`;
  }

  private getColor(style: Style) {
    let color = DEFAULT_COLOR;
    if (style?.color) {
      color = style.color;
    }

    return color;
  }
}
