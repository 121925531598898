import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';

export class MoveMainSectionsCommand extends BaseCommand<TimelineInterfaces.SectionsMovedEvent> {
  run(event: TimelineInterfaces.SectionsMovedEvent) {
    this.reorderMainSections(event.sections.map((section) => section.sectionId));
    return this.ok();
  }
}
