import * as fromActions from '../actions/timelines.actions';
import * as fromSelectors from '../selectors/timelines.selectors';

import {
  AddClipsEvent,
  ItemsMovedEvent,
  LayerIdEvent,
  StretchTextOverlayEvent,
  UpdateBoundsEvent,
  TimelineType,
  ToggleLayerFitEvent,
  UpdateLayerFitPositionEvent,
  TrimmerEvent,
  UpdateClipTrimsEvent,
  ItemTimelineChangedEvent,
} from '@openreel/creator/common';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TimelinesFacade {
  sentToTrimmer$ = this.store.select(fromSelectors.selectSendToTrimmer);

  constructor(private readonly store: Store) {}

  openSelectFilesDialog(timelineType: TimelineType) {
    this.store.dispatch(fromActions.openSelectFilesDialog({ timelineType }));
  }

  addMainClip(event: AddClipsEvent) {
    this.store.dispatch(fromActions.addMainClip({ event }));
  }

  removeClip(event: LayerIdEvent) {
    this.store.dispatch(fromActions.removeClip({ event }));
  }

  changeVideoOverlayType(event: LayerIdEvent) {
    this.store.dispatch(fromActions.changeVideoOverlayType({ event }));
  }

  stretchTextOverlay(event: StretchTextOverlayEvent) {
    this.store.dispatch(fromActions.stretchTextOverlay({ event }));
  }

  moveItems(event: ItemsMovedEvent) {
    this.store.dispatch(fromActions.moveItems({ event }));
  }

  changeItemTimeline(event: ItemTimelineChangedEvent) {
    this.store.dispatch(fromActions.changeItemTimeline({ event }));
  }

  setTrimmerData(event: TrimmerEvent) {
    this.store.dispatch(fromActions.setTrimmerData({ event }));
  }

  updateClipTrims(event: UpdateClipTrimsEvent) {
    this.store.dispatch(fromActions.updateClipTrims({ event }));
  }

  updateBounds(event: UpdateBoundsEvent) {
    this.store.dispatch(fromActions.updateBounds({ event }));
  }

  updateLayerFit(event: ToggleLayerFitEvent) {
    this.store.dispatch(fromActions.updateLayerFit({ event }));
  }

  updateLayerFitPosition(event: UpdateLayerFitPositionEvent) {
    this.store.dispatch(fromActions.updateLayerFitPosition({ event }));
  }
}
