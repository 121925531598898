import { BaseCommand } from './base.command';

export class WatermarkToggleCommand extends BaseCommand<boolean> {
  run(enabled: boolean, sectionId: string = null) {
    if (enabled) {
      this.addWatermark(sectionId);
    } else {
      this.removeWatermark(sectionId);
    }

    return this.ok();
  }
}
