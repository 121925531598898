import { BrandKitDto } from './brand-kit.interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandKitService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/brand-kits`,
    byId: (brandKitId) =>
      `${commonenv.nextGenApiUrl}workflows/brand-kits/${brandKitId}`,
    forAccount: `${commonenv.nextGenApiUrl}workflows/brand-kits/for-account`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getBrandKits() {
    return this.httpClient.get<BrandKitDto[]>(this.urls.root);
  }

  getBrandKitById(brandKitId: number) {
    return this.httpClient.get<BrandKitDto>(this.urls.byId(brandKitId));
  }

  getBrandKitForAccount() {
    return this.httpClient.get<BrandKitDto>(this.urls.forAccount);
  }

  updateBrandKit(brandKitId: number, brandKit: BrandKitDto) {
    return this.httpClient.put<BrandKitDto>(this.urls.byId(brandKitId), brandKit);
  }
}
