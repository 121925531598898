import { Injectable } from '@angular/core';
import { DirectorSocketService } from '../../socket/director-socket.service';
import {
  EVT_S2D_SCRIPT_CONTENT_ACK,
  EVT_D2S_SCRIPT_CONTENT,
  EVT_S2D_SCRIPT_PLAY_ACK,
  EVT_S2D_SCRIPT_TOGGLE_VIEW_ACK,
  EVT_D2S_SCRIPT_PLAY,
  EVT_D2S_SCRIPT_TOGGLE_VIEW,
  EVT_S2D_SCRIPT_PAUSE_ACK,
  EVT_D2S_SCRIPT_RESTART,
  EVT_S2D_SCRIPT_RESTART_ACK,
  EVT_D2S_TELEPROMPTER_INFO,
  EVT_S2D_TELEPROMPTER_INFO_ACK,
  EVT_D2D_DIRECTOR_SCRIPT_UPDATE,
} from '../../../interfaces/socket-events';
import {
  TeleprompterSocketRequest,
  AckTeleprompterInfo,
  CaptureTeleprompterCommand,
  TeleprompterUpdateInfo,
  TeleprompterInfo,
} from '../../../interfaces/teleprompter.interface';
import { MyIdentitySendType } from 'libs/common/src/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SocketDirectorExtensionTeleprompterService {
  // Emits scripts either new or updated by other directors
  public directorScriptUpdate$: Observable<TeleprompterUpdateInfo>;

  constructor(private socket: DirectorSocketService) {
    this.directorScriptUpdate$ = socket
      .getSocketEventByName(EVT_D2D_DIRECTOR_SCRIPT_UPDATE)
      .pipe(map((event) => event.data as TeleprompterUpdateInfo));
  }

  async sendTeleprompterScript(
    req: TeleprompterSocketRequest,
    identities: string[]
  ) {
    const toWait = this.socket.waitForMultipleAck(
      identities,
      EVT_S2D_SCRIPT_CONTENT_ACK,
      true
    );
    this.socket.emitSocketToArr(EVT_D2S_SCRIPT_CONTENT, req, identities);
    await toWait;
  }

  async loadTeleprompter(req: TeleprompterSocketRequest, identities: string[]) {
    const iosIdenties = this.getDeviceIdentities(identities, 'ios');
    const nonIosIdenties = this.getDeviceIdentities(identities, 'nonios');

    if (nonIosIdenties.length > 0) {
      await this.sendNonIosSocket(req, nonIosIdenties);
    }

    if (iosIdenties.length > 0) {
      await this.formatIosValueSend(req);
      await this.sendNonIosSocket(req, iosIdenties);
    }
  }

  async updateTeleprompter(
    req: TeleprompterSocketRequest,
    identities: string[]
  ) {
    const iosIdenties = this.getDeviceIdentities(identities, 'ios');
    const nonIosIdenties = this.getDeviceIdentities(identities, 'nonios');

    if (nonIosIdenties.length > 0) {
      await this.sendNonIosSocket(req, nonIosIdenties);
    }

    if (iosIdenties.length > 0) {
      await this.formatIosValueSend(req);
      await this.sendNonIosSocket(req, iosIdenties);
    }
  }
  async playTeleprompter(req: TeleprompterSocketRequest, identities: string[]) {
    const iosIdenties = this.getDeviceIdentities(identities, 'ios');
    const nonIosIdenties = this.getDeviceIdentities(identities, 'nonios');

    if (nonIosIdenties.length > 0) {
      await this.sendNonIosSocket(req, nonIosIdenties);
    }

    if (iosIdenties.length > 0) {
      await this.formatIosValueSend(req);
      await Promise.all([
        this.sendNonIosSocket(req, iosIdenties),
        this.sendIosSocket(CaptureTeleprompterCommand.PLAY, iosIdenties, req),
      ]);
    }
  }

  async pauseTeleprompter(
    req: TeleprompterSocketRequest,
    identities: string[]
  ) {
    this.sendToSocket(req, identities, CaptureTeleprompterCommand.PAUSE);
  }

  async resumeTeleprompter(
    req: TeleprompterSocketRequest,
    identities: string[]
  ) {
    this.sendToSocket(req, identities, CaptureTeleprompterCommand.PLAY);
  }

  async closeTeleprompter(
    req: TeleprompterSocketRequest,
    identities: string[]
  ) {
    this.sendToSocket(req, identities, CaptureTeleprompterCommand.CLOSE);
  }

  async restartTeleprompter(
    req: TeleprompterSocketRequest,
    identities: string[]
  ) {
    this.sendToSocket(req, identities, CaptureTeleprompterCommand.RESTART);
  }

  /*
   * Send message to a list of identities,
   * handle sending the correct message to ios and non ios clients
   */
  private async sendToSocket(
    req: TeleprompterSocketRequest,
    identities: string[],
    iosCommand: string
  ) {
    const iosIdenties = this.getDeviceIdentities(identities, 'ios');
    const nonIosIdenties = this.getDeviceIdentities(identities, 'nonios');

    const promises: Promise<void>[] = [];

    if (iosIdenties.length > 0) {
      promises.push(this.sendIosSocket(iosCommand, iosIdenties, req));
    }
    if (nonIosIdenties.length > 0) {
      promises.push(this.sendNonIosSocket(req, nonIosIdenties));
    }

    await Promise.all(promises);
  }

  /*
   * Notifies directors of a single script change
   */
  async sendDirectorScriptUpdate(data: TeleprompterUpdateInfo) {
    this.socket.emitSocket(
      EVT_D2D_DIRECTOR_SCRIPT_UPDATE,
      data,
      MyIdentitySendType.NO_IDENTITY
    );
  }


  listenToTeleprompterFinish() {
    // TODO: Create the actual type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.socket.getSocketEventByName<any>(EVT_S2D_SCRIPT_CONTENT_ACK);
  }

  // eslint-disable-next-line max-lines-per-function
  async sendIosSocket(
    type: string,
    identities: string[],
    req: TeleprompterSocketRequest
  ) {
    let sendables, sendCommand, ackCommand;
    switch (type) {
      case CaptureTeleprompterCommand.PLAY:
        sendables = {
          play_status: 1,
          scroll_speed: req.scroll_speed,
          keepOnScreen: req.keepOnScreen,
          countDown: req.countDown,
          tele_script_id: req.tele_script_id,
        };
        sendCommand = EVT_D2S_SCRIPT_PLAY;
        ackCommand = EVT_S2D_SCRIPT_PLAY_ACK;
        break;
      case CaptureTeleprompterCommand.PAUSE:
        sendables = {
          play_status: 0,
          scroll_speed: req.scroll_speed,
          keepOnScreen: req.keepOnScreen,
          countDown: req.countDown,
          tele_script_id: req.tele_script_id,
        };
        sendCommand = EVT_D2S_SCRIPT_PLAY;
        ackCommand = EVT_S2D_SCRIPT_PAUSE_ACK;
        break;
      case CaptureTeleprompterCommand.CLOSE:
        sendables = {
          tele_script_id: 0,
          keepOnScreen: req.keepOnScreen,
          switchto: 'record',
        };
        sendCommand = EVT_D2S_SCRIPT_TOGGLE_VIEW;
        ackCommand = EVT_S2D_SCRIPT_TOGGLE_VIEW_ACK;
        break;
      case CaptureTeleprompterCommand.RESTART:
        sendables = {
          restart_status: 1,
          scroll_speed: req.scroll_speed,
          keepOnScreen: req.keepOnScreen,
          countDown: req.countDown,
          tele_script_id: req.tele_script_id,
        };
        sendCommand = EVT_D2S_SCRIPT_RESTART;
        ackCommand = EVT_S2D_SCRIPT_RESTART_ACK;
        break;
    }
    const toWait = this.socket.waitForMultipleAck(identities, ackCommand, true);
    this.socket.emitSocketToArr(sendCommand, sendables, identities);
    await toWait;
  }

  async sendNonIosSocket(req: TeleprompterSocketRequest, identities: string[]) {
    const toWait = this.socket.waitForMultipleAck(
      identities,
      EVT_S2D_SCRIPT_CONTENT_ACK,
      true
    );
    this.socket.emitSocketToArr(EVT_D2S_SCRIPT_CONTENT, req, identities);
    await toWait;
  }

  getDeviceIdentities(identities: string[], type) {
    let data: string[] = [];
    if (type === 'ios') {
      data = identities.filter((identity) => identity.startsWith('ios_'));
    } else {
      data = identities.filter((identity) => !identity.startsWith('ios_'));
    }
    return data;
  }
  async formatIosValueSend(req: TeleprompterSocketRequest) {
    //create HTML for iOS support
    let style = 'font-family: Roboto, sans-serif;';
    if (req.value !== '-1') style += 'background-color:' + req.value + ';';
    if (req.txtColor !== '-1') style += 'color:' + req.txtColor + ';';
    if (req.font_size !== '-1') style += 'font-size:' + req.font_size + 'px;';
    let scriptContent = req.script_content.scriptData[0];
    scriptContent = scriptContent.replace(/(?:\r\n|\r|\n)/g, '<br>');
    scriptContent = '<div style="' + style + '">' + scriptContent + '</div>';
    req.script_content.scriptData[0] = scriptContent;
  }
  getTeleprompterInfo(data: TeleprompterInfo, identities: string[]) {
    this.socket.emitSocketToArr(EVT_D2S_TELEPROMPTER_INFO, data, identities);
  }
  listenToTeleprompterInfo() {
    return this.socket.getSocketEventByName<AckTeleprompterInfo>(
      EVT_S2D_TELEPROMPTER_INFO_ACK
    );
  }
}
