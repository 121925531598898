import { pixelsToMs } from './pixelsToMs';
import { BoundsInfo, ObjectDrawInfo, TimingInfo } from '../canvas.interfaces';

export const getTimingFromBounds = (
  bounds: BoundsInfo,
  containerItem: ObjectDrawInfo
) =>
  new TimingInfo(
    pixelsToMs(bounds.left, containerItem),
    pixelsToMs(bounds.right, containerItem)
  );
