<ng-container *ngIf="palette">
  <div
    *ngFor="let color of palette"
    class="color-card-container"
  >
    <div
      class="color-card"
      [style]="{ backgroundColor: color.hex }"
    ></div>
    <div class="shade">
      {{ color.shade }}
    </div>
  </div>
</ng-container>
