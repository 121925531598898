import { Component } from '@angular/core';

@Component({
  selector: 'openreel-generic-reconnect-spinner',
  templateUrl: './generic-reconnect.component.html',
  styleUrls: ['./generic-reconnect.component.scss']
})
export class GenericReconnectSpinnerComponent {
  message = 'Connecting';
}
