<h2 mat-dialog-title>Public Sharing Options</h2>

<mat-dialog-content>
    <div [formGroup]="shareForm">
        <div class="form-field-title">Public URL</div>
        <mat-form-field class="form-share-text"  appearance="outline">
            <input matInput formControlName="link" readonly> 
            <button mat-icon-button matSuffix (click)="copyLink($event)">
                <mat-icon>content_copy</mat-icon>
            </button>
        </mat-form-field>
        <ng-container *ngIf="shareForm.value.embed">
            <div class="form-field-title">Embed Code</div>
            <mat-form-field class="form-share-text"  appearance="outline">
                <input matInput formControlName="embed" readonly>
                <button mat-icon-button matSuffix (click)="copyEmbed($event)">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
    </div>
    <hr>
    <form #formRef="ngForm" [formGroup]="shareForm" (submit)="share()">
        <!-- email address -->
        <div class="form-field-title margin-top-16">Share Via Email</div>
        <openreel-email-list [emailList]="emailList" [maxEmails]="10" [formCtrl]="shareForm.get('email')"></openreel-email-list>

        <!-- email body -->
        <div class="form-field-title">Message (optional)</div>
        <mat-form-field class="form-share-text"  appearance="outline">
            <textarea matInput class="email-message"
                formControlName="message"
                placeholder="Enter message..."
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="6"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="formRef.onSubmit(); share()" [disabled]="!shareForm.valid">Share</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>