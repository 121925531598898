<div class="container">
  <div *ngFor="let cr of conversationsWithRole" class="conversation" [ngClass]="{
      selected: cr.conversation.id === selectedConversationId,
      message: cr.conversation.hasNewMessage
    }" (click)="onClick(cr.conversation)">
    <div class="avatar">
      <img [src]="cr.conversation.image" [openreelFallback]="'assets/common/logos/all-white.svg'" alt="Chat Icon"/>
    </div>
    <div class="info"
      matTooltip="{{cr.conversation.name}} (  
        {{(userRoleTypeHumanReadable[cr.role] ? userRoleTypeHumanReadable[cr.role] :
          cr.role) | titlecase}}
      )"> 
      <p class="role">{{ userRoleTypeHumanReadable[cr.role] ? userRoleTypeHumanReadable[cr.role] :
        cr.role | titlecase }}</p>
      <p class="name">{{ cr.conversation.name }}</p>
    </div>
  </div>
</div>