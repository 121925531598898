<ng-container *ngIf="uploadStatus$ | async as uploadStatus">
  <div
    *ngIf="uploadStatus"
    class="wrapper"
  >
    <div data-cy="upload-status" class="label">
      {{ uploadStatus.isUploading ? "Uploading" : (uploadStatus.hasFailed ? "Failed" : "Pending") }}
    </div>
    <mat-progress-bar
      data-cy="progress-bar"
      *ngIf="!uploadStatus.hasFailed"
      color="primary"
      [mode]="uploadStatus.percentage ? 'determinate' : 'indeterminate'"
      [value]="uploadStatus.percentage * 100"
    ></mat-progress-bar>
    <button
      *ngIf="!uploadStatus.percentage || uploadStatus.percentage < 1"
      mat-flat-button
      class="mat-button-white mat-button-pill-rounded"
      data-cy="cancel-delete-button"
      (click)="selfRecordFacade.cancelVideoUpload(uploadStatus.videoId)"
    >
      Cancel & delete
    </button>
  </div>
</ng-container>
<ng-container *ngIf="transcodeStatus$ | async as transcodeStatus">
  <div
    *ngIf="transcodeStatus"
    class="wrapper"
  >
    <div data-cy="transcode-status" class="label">
      {{ transcodeStatus.status === 'processing' ? "Transcoding" : transcodeStatus.status }}
    </div>
    <mat-progress-bar
      color="primary"
      [mode]="transcodeStatus.transcoding_percentage ? 'determinate' : 'indeterminate'"
      [value]="transcodeStatus.transcoding_percentage * 100"
    ></mat-progress-bar>
  </div>
</ng-container>
