import { createAction, props } from '@ngrx/store';
import { FontAsset } from '@openreel/creator/common';

const actionType = '[Font]';

export const loadSuggestedFonts = createAction(`${actionType} Load suggested fonts`);

export const loadSuggestedFontsSuccess = createAction(
  `${actionType} Load suggested fonts success`,
  props<{
    suggestedFonts: FontAsset[];
  }>()
);

export const loadTrendingFonts = createAction(`${actionType} Load trending fonts`);

export const loadTrendingFontsSuccess = createAction(
  `${actionType} Load trending fonts success`,
  props<{
    trendingFonts: FontAsset[];
  }>()
);

export const addExtraFontOnProject = createAction(
  `${actionType} Add extra font on project`,
  props<{
    font: FontAsset;
  }>()
);
