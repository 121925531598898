<div class="uploader">
  <openreel-uploader
    type="video"
    [ngModel]="control.value.assetFileId"
    [uploadCaption]="uiControl.label"
    [uploadedClasses]="uploadedClasses"
    [disabled]="disabled"
    (remove)="onVideoRemove()"
    (started)="onUploadStarted()"
    (failed)="onUploadFailed()"
    (upload)="onUploadDone($event)"
  ></openreel-uploader>
</div>
