<mat-form-field class="font text-12" appearance="outline">
  <mat-select
    data-cy="select-font"
    [panelClass]="options.selectClasses"
    [value]="selectedFont?.id"
    [disabled]="isDisabled"
    [style.font-family]="selectedFont?.family"
    (valueChange)="onFontChange($event)"
    (openedChange)="onSelectToggled($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [class]="options.searchClasses"
        placeholderLabel="Search fonts"
        [formControl]="filterQueryControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="selectedFont"
      class="hidden"
      [attr.data-cy]="selectedFont.name"
      [style.font-family]="selectedFont.family"
      [value]="selectedFont.id"
    >
      {{ selectedFont.name }}
    </mat-option>
    <mat-optgroup label="Company fonts" *ngIf="options.showCompanyFonts">
      <mat-option
        *ngIf="!filteredCompanyFonts.length; else elseCompany"
        disabled>
        No company fonts found
      </mat-option>
      <ng-template #elseCompany>
        <mat-option
          *ngFor="let font of filteredCompanyFonts"
          [attr.data-cy]="font.name"
          [style.font-family]="font.family"
          [value]="font.id">
          {{ font.name }}
        </mat-option>
      </ng-template>
    </mat-optgroup>
    <mat-optgroup
      [label]="options.suggestedFontsCaption"
      *ngIf="options.showSuggestedFonts"
    >
      <mat-option
        *ngIf="!filteredSuggestedFonts.length; else elseSuggested"
        disabled
      >
        No suggested fonts found
      </mat-option>
      <ng-template #elseSuggested>
        <mat-option
          *ngFor="let font of filteredSuggestedFonts"
          [attr.data-cy]="font.name"
          [style.font-family]="font.family"
          [value]="font.id"
        >
          {{ font.name }}
        </mat-option>
      </ng-template>
    </mat-optgroup>
    <mat-optgroup label="Trending fonts" *ngIf="options.showTrendingFonts">
      <mat-option
        *ngIf="!filteredTrendingFonts.length; else elseTrending"
        disabled
      >
        No trending fonts found
      </mat-option>
      <ng-template #elseTrending>
        <mat-option
          *ngFor="let font of filteredTrendingFonts"
          [attr.data-cy]="font.name"
          [style.font-family]="font.family"
          [value]="font.id"
        >
          {{ font.name }}
        </mat-option>
      </ng-template>
    </mat-optgroup>
    <mat-optgroup *ngIf="options.showOtherFonts" label="Other fonts">
      <mat-option *ngIf="!filteredOtherFonts.length; else elseOther" disabled>
        <span *ngIf="!filterQuery">Search for other fonts</span>
        <span *ngIf="filterQuery && isLoadingOtherFonts">Loading...</span>
        <span *ngIf="filterQuery && !isLoadingOtherFonts"
          >No other fonts found</span
        >
      </mat-option>
      <ng-template #elseOther>
        <ng-container *ngFor="let font of filteredOtherFonts">
          <mat-option
            [attr.data-cy]="font.name"
            [style.font-family]="font.family"
            [value]="font.id"
          >
            {{ font.name }}
          </mat-option>
        </ng-container>
      </ng-template>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
