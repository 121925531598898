/* eslint-disable @typescript-eslint/no-explicit-any */
import { PresetField } from '@openreel/creator/common';
import { TransformerBase } from './transformer.base';

export class TransformerText extends TransformerBase {
  test = (fieldDef: PresetField) => fieldDef.type === 'text';

  process = (animation: any, fieldDef: PresetField, fieldValues: any) => {
    if (!fieldDef.lottiePath) {
      return animation;
    }

    const rootNode = this.getRootNode(animation, fieldDef.lottiePath);
    rootNode.t.d.k[0].s = {
      ...rootNode.t.d.k[0].s,
      // TODO: strongly type this in cue player service, maybe tagged union type for each preset field type?
      t: fieldValues.value,
      f: fieldValues.font,
      fc: fieldValues.color,
    };

    return animation;
  };
}
