import { IUserAssociatedAccount } from '@openreel/common';

export interface GuestUserAccountInfo {
  first_name: string;
  last_name: string;
  company_name: string;
  password: string;
  token: string;
  account_id?: number;
  available_accounts?: IUserAssociatedAccount[];
}

export const GUEST_USER_ACCOUNT_INFO = 'GUEST_USER_ACCOUNT_INFO';
