import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';

import { MatDividerModule } from '@angular/material/divider';
import { OverlayModule } from '@angular/cdk/overlay';
import { DomSanitizer } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';

const svgIconNames = [
  'advanced',
  'apple',
  'ban',
  'bell-notification',
  'bell',
  'bold',
  'both-pip',
  'brightness-filter',
  'camera',
  'calendar-alt',
  'calendar',
  'cancel-process',
  'capture-card',
  'caret',
  'chair',
  'chat-Copy',
  'chat',
  'check-round',
  'check-circle',
  'check',
  'checklist',
  'checkmark-empty',
  'checkmark-filled',
  'chek-round',
  'chek',
  'chevron-left',
  'chevron-right',
  'clipboard',
  'clock',
  'close',
  'close-round',
  'cloud',
  'cloud-upload',
  'cloud-upload-filled',
  'cog-outline',
  'color-palette',
  'color-picker',
  'colour-filter',
  'computer',
  'contain',
  'creator-card',
  'dashboard',
  'design-and-styling',
  'devices',
  'diamond',
  'down-arrow',
  'download-round',
  'download-square',
  'download',
  'redo',
  'drag',
  'dynamic',
  'edit-alt',
  'edit',
  'emoji',
  'energy',
  'enlarge',
  'export',
  'exposure',
  'eye-dropper',
  'eye',
  'eye-off',
  'files',
  'fill',
  'filter',
  'finger',
  'fingerprint',
  'fingerprint2',
  'captions',
  'flag',
  'flip',
  'focus-filter',
  'focus',
  'folder',
  'font-family',
  'fps-60',
  'framerate-filter',
  'frame-rate',
  'full-down-arrow',
  'full-left-arrow',
  'full-right-arrow',
  'full-up-arrow',
  'fullscreen',
  'gear',
  'globe',
  'grid-alt',
  'grid',
  'heart-fill',
  'heart-outline',
  'headphone',
  'hidden',
  'home',
  'hosting-card',
  'icon-analytics',
  'icon-dashboard',
  'icon-map',
  'infinity',
  'info-alt',
  'info',
  'information',
  'laptop-green',
  'laptop-grey',
  'lock',
  'unlock',
  'left-arrow-alt',
  'left-arrow',
  'library',
  'lightbulb',
  'line',
  'list',
  'logout',
  'mail',
  'marker',
  'menu',
  'menu-layout',
  'menu-logo',
  'menu-watermark',
  'message',
  'mic-green',
  'mic-grey',
  'mic',
  'minus',
  'mountain',
  'music-copy',
  'music-off',
  'music',
  'mute',
  'no-signal',
  'notebook',
  'options',
  'p-1080',
  'p-720',
  'pause',
  'phone-app',
  'phone',
  'photo-camera',
  'photo',
  'pin',
  'pip',
  'play-icon',
  'play-circle',
  'play',
  'plus-alt',
  'plus',
  'preset-filter',
  'preset',
  'projects',
  'question-filled',
  'question',
  'questionmark',
  'record-start',
  'record-stop',
  'record',
  'record-stop2',
  'record2',
  'record-start-16',
  'record-stop-16',
  'refresh',
  'refresh-alt',
  'remove',
  'replace',
  'reset',
  'resolution-filter',
  'right-arrow-alt',
  'right-arrow',
  'save',
  'scissors',
  'screen-black',
  'screen-grey',
  'screen',
  'split',
  'split-screen',
  'search',
  'send',
  'settings',
  'share-green',
  'share-grey',
  'share',
  'shield',
  'shutter',
  'signal',
  'star',
  'star-alt',
  'stop-process',
  'stop-record-circle',
  'stop-record-square',
  'sun',
  'support',
  'text',
  'trash',
  'trim',
  'fork',
  'up-dpwn-arrow',
  'upload',
  'upload-file',
  'user-black',
  'user-edit',
  'user-frame',
  'user-grey',
  'user-target',
  'user',
  'user-icon-alt',
  'users',
  'video-camera',
  'videocam-off',
  'video-captions',
  'video-edit',
  'video-overlay',
  'video-soundtrack',
  'video-timeline',
  'visable',
  'web',
  'white-balance',
  'white-balance-filter',
  'teleprompter-not-visible',
  'teleprompter-pause',
  'teleprompter-play',
  'teleprompter-stop',
  'teleprompter-preview',
  'teleprompter-reset',
  'teleprompter-visible',
  'templates',
  'bulk-check',
  'bulk-checkfilled',
  'bulk-check-white',
  'gain-control',
  'workflow',
  'webinar',
  'webinar-card',
  'camera-fill',
  'screen-fill',
  'camera-fill-active',
  'screen-fill-active',
  'swap-horizontal',
  /* Workflow Editor Icons */
  'video-elements',
  'intro-outro',
  'wf-blue-gradient',
  'wf-check',
  'wf-brand-kit',
  'wf-template',
  'text-left',
  'text-centre',
  'text-right',
  'control',
  'pin',
  'photo-camera',
  'wf-narration',
  'wf-text-overlay-lower-third',
  'wf-text-overlay-quote',
  'wf-text-overlay-hero-title',
  'wf-text-overlay-stacked',
  'wf-text-overlay-podcast-layout',
  'wf-overlays',
  'wf-record',
  'wf-video',
  'pro-audio',
  'g-sso',
  'dest_dropbox_dark_mode_default',
  'layout',
];

/**
 * NgModule that includes all Material modules that are required to serve
 * the Plunker.
 */
@NgModule({
  exports: [
    // Material
    MatFormFieldModule,
    MatAutocompleteModule,
    OverlayModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    MatDividerModule,
    MatBadgeModule,
    ClipboardModule,
    DragDropModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class MaterialModule {
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {
    for (const svgIconName of svgIconNames) {
      this.addSvgIcon(svgIconName, `assets/ui/material-icons/${svgIconName}.svg`);
    }
  }

  private addSvgIcon(name, path) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
