import { BufferScan } from './buffer-scan';
import { MediaMeta, Track } from './validate-media';

export class mediaValidatorMP4 {

    tagDictionary: Record<string, string>;
    data: MediaMeta;

    videoParsedMeta:Track[] = [];
    currentTrack:Track;
    read4K = 4*1024;

    constructor(){
        this.tagDictionary = this.setupTagDictionary();
    }

    //
    // set of TAGs - not entire set, but msot important
    //
    setupTagDictionary() {    
        const tagDict = {};
        tagDict['ftyp'] = 'ftyp';       // Contains the file type, description, and the common data structures used.
        tagDict['mdat'] = 'mdat';       // Data container for media.
        tagDict['mvhd'] = 'mvhd';       // Contains the video header information with full details of the video.
        tagDict['tkhd'] = 'tkhd';       // Contains track header
        tagDict['elst'] = 'elst';       // 
        tagDict['mdhd'] = 'mdhd';       // Contains media header
        tagDict['moov'] = 'moov';       // Container for all the movie metadata.
        tagDict['udta'] = 'udta';       // The container with the user and track information.
        tagDict['trak'] = 'trak';       // Container with the individual track.
        tagDict['free'] = 'free';       // 
        tagDict['edts'] = 'edts';       // 
        tagDict['hdlr'] = 'hdlr';       // 
        tagDict['minf'] = 'minf';       // 
        tagDict['mdia'] = 'mdia';       // 
        tagDict['vmhd'] = 'vmhd';       // video media header
        tagDict['dinf'] = 'dinf';       //
        tagDict['stbl'] = 'stbl';       // sample table box
        tagDict['smhd'] = 'smhd';       // sound media header 
        tagDict['dref'] = 'dref';       // data reference
        tagDict['stsd'] = 'stsd';       // sample description
        tagDict['stts'] = 'stts';       // time to sample
        tagDict['stss'] = 'stss';       // sync sample
        tagDict['ctts'] = 'ctts';       // 
        tagDict['stsc'] = 'stsc';       // sample to chunk
        tagDict['stsz'] = 'stsz';       // sample size
        tagDict['stco'] = 'stco';       // chunk offset
        tagDict['sgpd'] = 'sgpd';       // 
        tagDict['sbgp'] = 'sbgp';       // 
        tagDict['meta'] = 'meta';       // The container with the metadata information.
        return tagDict;
    }

    //
    // scan the mp4 tag - first 4 bytes is the size, second 4 bytes is the type
    //
    private scanMp4Tag(buff, pos) {
        const size = BufferScan.scanDataValueU(buff, pos, 4);
        const type = BufferScan.scanDataUTF8(buff, pos + 4, 4);

        const res = {
            str: type,
            size: 8,        //always 2x32 bit
            value: size,
        };
        return res;
    }

    //
    // recursive call - scans all tags in stream
    //
    async parse(file, offset, position, maxPosition){

        offset += position;
        let buffer = await BufferScan.readFile(file, offset, maxPosition);
        position = 0; //reset offset

        while(position < maxPosition){

            // -- TAG --
            const result = this.scanMp4Tag(buffer, position);
            if (!result) {
                break; 
            }

            const tagName = this.tagDictionary[result.str];

            if(tagName === 'tkhd'){
                let offset = 0; // offset to values
                
                offset += 8;// creation time
                offset += 8;// modification time
                offset += 4;// track id
                offset += 4;// reserved
                offset += 8;// duration
                offset += 4;// reserved
                offset += 4;// reserved
                offset += 2;// layer
                offset += 2;// alternate group
                offset += 2;// volume
                offset += 2;// reserved
                offset += 9*4;// matrix

                const width = BufferScan.scanDataValueU(buffer, position + offset, 2);
                const height = BufferScan.scanDataValueU(buffer, position + offset + 4, 2);

                this.currentTrack = new Track();
                this.currentTrack.width = width;
                this.currentTrack.height = height;
                this.videoParsedMeta.push(this.currentTrack);

            }else if(tagName === 'mvhd'){
            }else if (tagName === 'hdlr'){
                let offset = 8;

                offset += 4; // versions and flags    
                const type = BufferScan.scanDataUTF8(buffer, position + offset, 4);
                offset +=4;

                const subtype = BufferScan.scanDataUTF8(buffer, position + offset, 4);
                // 'vide' - video
                // 'soun' - sound
                // 'subt' - subtitle
                offset +=4;

                if(subtype === 'vide')
                    this.currentTrack.type = 'video';
                else if(subtype === 'soun')
                    this.currentTrack.type = 'audio';
            }else if(tagName === 'stsd'){
                // sample description box
                let offset = 8;

                offset += 4; // versions and flags    
                const count = BufferScan.scanDataValueU(buffer, position + offset, 4);

                offset += 4;
                for(let i = 0; i < count; i++){
                    offset +=4; // size
                    const type = BufferScan.scanDataUTF8(buffer, position + offset, 4);
                    this.currentTrack.codec = type;
                }
            }else if(tagName === 'moov'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }else if(tagName === 'udta'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }else if(tagName === 'trak'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }else if(tagName === 'mdia'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }else if(tagName === 'minf'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }else if(tagName === 'dinf'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }else if(tagName === 'stbl'){
                await this.parse(file, offset, position + 8, result.value - 8);
            }

            if (result.value >= 0) {
                position += result.value;
            }
            else {
                // the END
                break;
            }

            // -- check EOF ---
            if (position === maxPosition) {
                break;
            }
            else if (position > maxPosition) {
                offset += position; // move offset

                // check end of the file
                if(offset >= file.size){
                    break;
                }

                // read more
                buffer = await BufferScan.readFile(file, offset, this.read4K);
                position = 0; //reset position in buffer
            }
        } 
    }

    //
    // get data from the scan
    //
    getData():Track[]{
        return this.videoParsedMeta;
    }
}