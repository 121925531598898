import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, sampleTime, takeWhile, finalize, shareReplay } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';
import { clamp } from 'lodash-es';

const KEYBOARD_STEP = 100;
const PROGRESS_SAMPLE_INTERVAL = 100;

export interface ControlsOptions {
  type: 'full' | 'minimal';
}

const DEFAULT_OPTIONS: ControlsOptions = {
  type: 'full'
}

@Component({
  selector: 'openreel-video-controls',
  templateUrl: './openreel-video-controls.component.html',
  styleUrls: ['./openreel-video-controls.component.scss'],
})
export class OpenreelVideoControlsComponent implements OnInit {
  @ViewChild('progressBar')
  private readonly progressBarDiv: ElementRef<HTMLDivElement>;

  @ViewChild('progressDuration')
  private readonly progressDurationDiv: ElementRef<HTMLDivElement>;
  private readonly _currentTime = new BehaviorSubject<number>(0);

  Infinity = Infinity;
  fullScreen = false;

  @Input() set currentTime(value: number) {
    this._currentTime.next(value);
  }
  @Input() duration: number;
  @Input() muted = false;
  @Input() playing = false;
  @Input() options: ControlsOptions = DEFAULT_OPTIONS;

  @Output() readonly onSeek = new EventEmitter<number>();
  @Output() readonly onPlay = new EventEmitter();
  @Output() readonly onPause = new EventEmitter();
  @Output() readonly onMute = new EventEmitter();
  @Output() readonly onUnmute = new EventEmitter();
  @Output() readonly onFullscreen = new EventEmitter();
  @Output() readonly onExitFullscreen = new EventEmitter();

  currentTime$ = this._currentTime.asObservable().pipe(
    filter(() => Boolean(this.duration)),
    sampleTime(PROGRESS_SAMPLE_INTERVAL),
    distinctUntilChanged(),
    takeWhile((currentTime) => currentTime - PROGRESS_SAMPLE_INTERVAL < this.duration),
    finalize(() => (this.playing = false)),
    shareReplay()
  );

  ngOnInit() {
    if (!this.options) {
      this.options = DEFAULT_OPTIONS;
    }
  }

  roundDuration(duration: number) {
    const seconds = duration / 1000;
    const mod = seconds % 1;
    return mod >= 0.5 ? Math.ceil(seconds) * 1000 : Math.floor(seconds) * 1000;
  }

  togglePlaying() {
    if (this.playing) {
      this.onPause.emit();
    } else {
      this.onPlay.emit();
    }
  }

  toggleMute() {
    if (this.muted) {
      this.onUnmute.emit();
    } else {
      this.onMute.emit();
    }
  }

  get isFullScreen() {
    return !!document.fullscreenElement;
  }

  toggleFullscreen() {
    if (this.isFullScreen) {
      this.onExitFullscreen.emit();
      this.fullScreen = false;
    } else {
      this.onFullscreen.emit();
      this.fullScreen = true;
    }
  }

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  escFullScreen() {
    this.fullScreen = this.isFullScreen;
  }

  onKeyPress($event: KeyboardEvent) {
    if (this.playing) {
      return;
    }

    const current = this.progressBarDiv.nativeElement.getBoundingClientRect().width;
    const total = this.progressDurationDiv.nativeElement.getBoundingClientRect().width;

    const currentTime = (current / total) * this.duration;
    switch ($event.key) {
      case ',': {
        this.onSeek.emit(clamp(currentTime - KEYBOARD_STEP, 0, this.duration));
        break;
      }
      case '.': {
        this.onSeek.emit(clamp(currentTime + KEYBOARD_STEP, 0, this.duration));
        break;
      }
    }
  }

  onProgressClick(event: MouseEvent) {
    const clickX = event.clientX;
    const boundingRect = this.progressDurationDiv.nativeElement.getBoundingClientRect();

    const percentage = ((clickX - boundingRect.left) / boundingRect.width) * 100.0;

    const seekTime = (this.duration * percentage) / 100.0;
    this.onSeek.emit(clamp(seekTime, 0, this.duration));
  }
}
