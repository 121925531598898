import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  public attempts = 10;
  constructor(private clipboard: Clipboard) {}

  copy(text:string) {
    return new Promise((resolve, reject)=>{
      const pending = this.clipboard.beginCopy(text);
      let remainingAttempts = this.attempts;
      while(--remainingAttempts){
        const result = pending.copy();
        if(result){
          // Copy is successful 
          pending.destroy();
          resolve(true);
        }
      }
      pending.destroy();
      reject(false);
    })
  }
}
