import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  public transform(value: number) {
    value = Number(value);
    return Math.round(value);
  }
}
