import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_4 } from '../transformation_0_4/schema_after';

function addSchemaType(schema: WorkflowDataDto_0_4.WorkflowDataDto) {
  Object.keys(schema.sections).forEach((sectionId) => {
    schema.sections[sectionId].sectionType = sectionId;
  });
}

export const SchemaTransformation_0_5: SchemaTransformation = {
  version: '0.5',

  run(oldSchema: WorkflowDataDto_0_4.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema);
    addSchemaType(newSchema);
    return newSchema;
  },
};
