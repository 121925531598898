import { getFontAssetWeight } from '../helpers';
import { BaseCommand } from './base.command';

export class UpdateFontCommand extends BaseCommand<{ fontIndex: number }> {
  run({ fontIndex }) {
    this.source.globalSettings.fontIndex = fontIndex;

    const selectedFont = this.source.fonts[fontIndex];
    const fontWeight = +getFontAssetWeight(selectedFont);

    this.source.styles = this.source.styles.map((style) => ({
      ...style,
      fontIndex,
      fontWeight,
    }));

    return this.ok();
  }
}
