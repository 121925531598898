import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ChatListService } from './chat-list.service';
import { Conversation } from '@openreel/common/components';
import { NextgenParticipant } from '../../../interfaces/nextgen-participant.interface';
import { UserRoleTypeHumanReadable } from '@openreel/common/interfaces';

export const allChatId = 0;

@Component({
  selector: 'openreel-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnChanges {
  @Input()
  conversations: Conversation[];
  @Input()
  selectedConversationId: number | null;
  @Input()
  participants: NextgenParticipant[];

  conversationsWithRole: { conversation: Conversation; role: string }[];

  userRoleTypeHumanReadable = UserRoleTypeHumanReadable;

  constructor(private readonly chatListService: ChatListService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversations || changes.participants) {
      const roleById = new Map(
        this.participants?.map((p) => [p.loginId, p.role]) || []
      );

      this.conversationsWithRole = this.conversations.map((conversation) => ({
        conversation,
        role:
          conversation.id === allChatId
            ? 'group'
            : roleById.get(conversation.id),
      }));
    }
  }

  onClick(conversation?: Conversation) {
    this.chatListService.toggleSelectConversation(conversation.id);
  }
}
