import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeBarValue' })
export class TimeBarValuePipe implements PipeTransform {
  transform(value: number) {
    const minutes = Math.floor(value / 60);
    const seconds = value - minutes * 60;

    return `${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
