import { getSectionTimelines } from '../helpers';
import { Layer } from '../interfaces';
import { BaseCommand } from './base.command';

export interface AddTextOverlayEvent {
  sectionId: string;
}

export class AddTextOverlayCommand extends BaseCommand<AddTextOverlayEvent, Layer[]> {
  run({ sectionId }: AddTextOverlayEvent) {
    const sectionTimelines = getSectionTimelines(this.source.sections, 'main', 'overlays', sectionId);
    if (sectionTimelines.length === 0) {
      return { success: false };
    }

    const layers: Layer[] = [];

    sectionTimelines[0].timelines.forEach((timeline) => {
      if (timeline.layers.length === 0) {
        this.addTextOverlay(timeline, 0);
      }

      layers.push(timeline.layers[0]);
    });

    return this.ok(layers);
  }
}
