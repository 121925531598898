import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MouseEventsService {
  private clickTargets = new Subject<{
    start: HTMLElement;
    end: HTMLElement;
  }>();
  clickTargets$ = this.clickTargets.asObservable();

  setClickTargets = (start: HTMLElement, end: HTMLElement) =>
    this.clickTargets.next({ start, end });
}
