import { fabric } from 'fabric';
import { TimelineType } from '@openreel/creator/common';
import { CanvasConfig, TimelineConfig } from '../canvas.config';

export interface LayerDataOptions {
  timelineType: TimelineType;
  selectable: boolean;
  stretchable: boolean;
  hasThumbnails: boolean;
  config: CanvasConfig;
  getThumbnails?: (layerId: string) => fabric.Image[];
}

export interface LayerOptions extends fabric.IGroupOptions {
  data: LayerDataOptions;
}

export class Layer extends fabric.Group {
  type: 'layer';

  backgroundRect: fabric.Rect;
  thumbImages: fabric.Image[];

  timelineConfig: TimelineConfig;

  data: LayerDataOptions;

  private lastRenderWidth: number;

  constructor(options: LayerOptions) {
    const timelineConfig = options.data.config.timelines[options.data.timelineType];
    const objects = [];

    objects.push(
      new fabric.Rect({
        rx: 4,
        ry: 4,
        hasControls: false,
        selectable: false,
        stroke: timelineConfig.layerColor,
        fill: timelineConfig.layerFill ? timelineConfig.layerColor : 'transparent',
        width: options.width - 4,
        height: options.height - 2,
      })
    );

    if (options.data.config.debug) {
      objects.push(
        new fabric.Text(options.name.substr(0, 5), {
          left: 0,
          top: 4,
          fill: 'white',
          fontFamily: 'Rubik',
          fontSize: 12,
        })
      );
    }

    super(objects, { ...options, type: 'layer' });
    this.timelineConfig = timelineConfig;

    this.backgroundRect = this.item(0) as fabric.Rect;
    this.thumbImages = [];

    this.setupSelection();
    this.setupScaleControls();
  }

  render(ctx: CanvasRenderingContext2D) {
    if (this.visible) {
      const halfWidth = this.width / 2;
      const halfHeight = this.height / 2;

      this.backgroundRect.set({
        left: -halfWidth + 2,
        top: -halfHeight,
        width: this.width - 4,
        strokeWidth: this.data.config.timelineTrackOptions.showLayerBorder ? 2 : 0,
      });

      this.renderThumbs();
    }

    super.render(ctx);
  }

  showSelectionBorder() {
    this.backgroundRect.set({ stroke: this.data.config.selection.borderColor });
  }

  hideSelectionBorder() {
    this.backgroundRect.set({ stroke: this.timelineConfig.layerColor });
  }

  startStretch() {
    this.backgroundRect.set({
      rx: 0,
      ry: 0,
      stroke: this.timelineConfig.layerColor,
    });
  }

  endStretch() {
    this.backgroundRect.set({
      rx: 4,
      ry: 4,
      stroke: this.timelineConfig.layerColor,
    });
  }

  renderThumbs() {
    const showThumbnails = this.data.config.thumbnails.enabled && this.data.hasThumbnails && this.data.getThumbnails;

    if (!showThumbnails) {
      return;
    }

    const newThumbs = this.data.getThumbnails(this.name);
    newThumbs.forEach((thumb) => (thumb.hoverCursor = this.hoverCursor));

    if (newThumbs.length === 0) {
      return;
    }

    const shouldRefreshThumbnails = Math.trunc(this.lastRenderWidth) !== Math.trunc(this.width);

    if (shouldRefreshThumbnails) {
      this.lastRenderWidth = Math.trunc(this.width);

      this.thumbImages.forEach((thumb) => this.remove(thumb));
      this.thumbImages = [...newThumbs];
      this.add(...this.thumbImages);
    }

    const halfWidth = this.width / 2;
    const halfHeight = this.height / 2;

    // NOTE: adding some pixels to right border since background rect isnt 100% centered
    const thumbnailsRightBorder = halfWidth - this.data.config.thumbnails.padding.x + 2;
    this.thumbImages.forEach((thumb, index, images) => {
      const originalWidth = images[0].width;
      const left = -halfWidth + this.data.config.thumbnails.padding.x + index * originalWidth;

      const top = -halfHeight + this.data.config.thumbnails.padding.y;

      const width = left + thumb.width > thumbnailsRightBorder ? thumbnailsRightBorder - left : thumb.width;

      thumb.set({
        top,
        left,
        width,
        clipPath: this.generateThumbClipPath(index, this.thumbImages.length, width),
      });
    });
  }

  private setupSelection() {
    this.selectable = this.data.selectable;
    this.hoverCursor = this.data.selectable ? 'pointer' : 'default';
  }

  private setupScaleControls() {
    this.hasControls = this.data.stretchable;
    this.lockScalingFlip = true;
    this.lockScalingY = true;
    this.setControlsVisibility({
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mt: false,
      mb: false,
      mtr: false,
    });

    this.cornerStyle = 'circle';
    this.cornerColor = this.timelineConfig.stretchHandleColor;
    this.cornerSize = 12;
    this.transparentCorners = false;
  }

  private generateThumbClipPath(index: number, count: number, width: number) {
    if (index !== 0 && index !== count - 1) {
      return null;
    }

    const hw = width / 2;
    const hh = this.thumbImages[index].height / 2;
    const cut = Math.min(width, this.data.config.thumbnails.borderRadius);

    const isFirst = index === 0;
    const isLast = index === count - 1;

    let svgPath = '';
    if (isFirst && isLast) {
      svgPath =
        `M ${-hw + cut} ${-hh} ` +
        `Q ${-hw} ${-hh} ${-hw} ${-hh + cut} ` +
        `L ${-hw} ${hh - cut} ` +
        `Q ${-hw} ${hh} ${-hw + cut} ${hh} ` +
        `L ${hw - cut} ${hh} ` +
        `Q ${hw} ${hh} ${hw} ${hh - cut} ` +
        `L ${hw} ${-hh + cut} ` +
        `Q ${hw} ${-hh} ${hw - cut} ${-hh}` +
        `L ${-hw + cut} ${-hh}`;
    } else if (isFirst) {
      svgPath =
        `M ${-hw + cut} ${-hh} ` +
        `Q ${-hw} ${-hh} ${-hw} ${-hh + cut} ` +
        `L ${-hw} ${hh - cut} ` +
        `Q ${-hw} ${hh} ${-hw + cut} ${hh} ` +
        `L ${hw} ${hh} ` +
        `L ${hw} ${-hh} ` +
        `L ${-hw + cut} ${-hh} `;
    } else if (isLast) {
      svgPath =
        `M ${hw - cut} ${-hh} ` +
        `Q ${hw} ${-hh} ${hw} ${-hh + cut} ` +
        `L ${hw} ${hh - cut} ` +
        `Q ${hw} ${hh} ${hw - cut} ${hh} ` +
        `L ${-hw} ${hh} ` +
        `L ${-hw} ${-hh} ` +
        `L ${hw + cut} ${-hh} `;
    }

    return new fabric.Path(svgPath);
  }
}
