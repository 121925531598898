<div class="input-bg-white input-primary-underline text-12 controls" [formGroup]="rangeGroup">
  <div>
    <label>{{ startCaption }}</label>
    <mat-form-field class="w-full white-bg" [appearance]="appearance">
      <input matInput type="text" formControlName="startAt" (blur)="propagateOnTouched()" />
    </mat-form-field>
  </div>
  <div>
    <label>{{ endCaption }}</label>
    <mat-form-field class="w-full white-bg" [appearance]="appearance">
      <input matInput type="text" formControlName="endAt" (blur)="propagateOnTouched()" />
    </mat-form-field>
  </div>
</div>
