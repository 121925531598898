<div
  class="player-container"
  [class.has-controls]="hasControls"
  [class.is-standalone]="isStandAlone"
  *ngIf="{
    duration: totalDuration$ | async,
    currentTime: totalTime$ | async,
    isLoading: isLoading$ | async
  } as player"
>
  <div class="player">
    <openreel-cue-loader *ngIf="player.isLoading"></openreel-cue-loader>
    <openreel-cue-player-timelines
      [style.background-color]="(isLoading || player.isLoading) && playerBgColor ? playerBgColor : null"
      [class.opacity-0]="player.isLoading"
      *ngIf="data"
      [data]="data"
      (onLoaded)="onTimelineLoaded($event)"
      (onTimeUpdate)="onTimelineTimeUpdate($event)"
      (onEnded)="onTimelineEnded()"
      (onSeeking)="onTimelineSeeking()"
      (onSeeked)="onTimelineSeeked()"
      (onError)="onTimelineError($event)"
    ></openreel-cue-player-timelines>
    <openreel-cue-player-captions
      *ngIf="captions"
      [data]="captions"
      [currentTime]="captionsCurrentTime"
    ></openreel-cue-player-captions>

    <div
      *ngIf="controlOptions?.type === 'minimal' && !player.isLoading"
      class="play-overlay"
      [class.paused]="!isPlaying()"
      (click)="isPlaying() ? onControlsPause() : onControlsPlay()"
    >
      <mat-icon *ngIf="!isPlaying()" svgIcon="play"></mat-icon>
      <mat-icon *ngIf="isPlaying()" svgIcon="pause"></mat-icon>
    </div>
  </div>
  <div *ngIf="hasControls" class="controls" [class.minimal]="controlOptions?.type === 'minimal'">
    <openreel-cue-controls
      [currentTime]="player.currentTime"
      [duration]="player.duration"
      [playing]="isPlaying()"
      (onSeek)="onControlsSeek($event)"
      (onPlay)="onControlsPlay()"
      (onPause)="onControlsPause()"
      (onFullScreen)="onControlsFullScreen()"
      [options]="controlOptions"
    ></openreel-cue-controls>
  </div>
  <openreel-cue-selection-toolbar *ngIf="layerSelected$ | async" (switchMainVideos)="onSwitchMainVideos()"></openreel-cue-selection-toolbar>
</div>
