<h1 mat-dialog-title>
    New User Joined
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="30px" class="dialog-content">
    <div>
        <span>{{name}} has now entered the session</span>
    </div>
    <div fxLayoutGap="10px" fxLayout="row">
        <mat-icon>phone_android</mat-icon>
        <span>{{deviceName}}</span>
    </div>
    <div fxLayoutGap="10px" fxLayout="row">
        <mat-icon>person_pin_circle</mat-icon>
        <span fxLayoutGap="10px">{{country}}</span>
    </div>
    <div>
        <button mat-raised-button color="primary" (click)="onDialogCloseClicked()" class="close-device-info">
            Ok
        </button>
    </div>
</div>