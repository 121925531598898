<div class="scroll-zoom-bar" (mousewheel)="mouseWheelZoom($event)">
  <div #track class="track" (click)="trackClick($event)">
    <div
      class="thumb"
      cdkDrag
      cdkDragBoundary=".track"
      cdkDragLockAxis="x"
      (cdkDragStarted)="onThumbDragStarted()"
      (cdkDragMoved)="onThumbDragMoved($event)"
      (cdkDragEnded)="onThumbDragEnded()"
      [cdkDragFreeDragPosition]="{ x: handles[0], y: 0 }"
      [style.width.px]="handles[1] - handles[0]"
      (dblclick)="zoomOutFull()"
    >
      <div class="handle left" (mousedown)="mouseDownHandle($event, 0)"></div>
      <div class="handle right" (mousedown)="mouseDownHandle($event, 1)"></div>
    </div>
  </div>
</div>
