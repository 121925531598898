import { Directive } from '@angular/core';
import { from, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { onCancel } from '../utils';

/**
 * This is a class that cleans all necessary resources after it is destroyed.
 * You should extend this class in all components where you use Observables.
 * You can also extend from this class if you have a service that is not
 * provided in root (i.e. its lifetime is not infinite).
 *
 * @export
 * @abstract
 * @class Cleanupable
 */
 @Directive()
export abstract class Cleanupable {
  /**
   * List of all subscriptions in this class. These are automatically cleaned
   * when {@link Cleanupable#ngOnDestroy|ngOnDestroy} is called.
   *
   * @type {Subscription[]}
   * @memberof Cleanupable
   */
  protected subscriptions: Subscription[] = [];

  protected ngUnsubscribe = new Subject();

  // New way of unsubscribing. wrap all external observables with mortalize(), no other actions necessary.
  private isComplete = new Subject();
  protected mortalize<T>(obs: Observable<T>) {
    return obs.pipe(takeUntil(this.isComplete));
  }
  /**
   * Cleans all subscriptions that have been accumulated throughout the
   * lifetime of this component/service. Also complete any subjects this
   * component has.
   *
   * @memberof Cleanupable
   */
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.cleanAllSubscriptions();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.isComplete.next();
    this.isComplete.complete();
  }

  /**
   * Clean all subscriptions in {@link Cleanupable#subscriptions|subscriptions}
   *
   * @protected
   * @memberof Cleanupable
   */
  protected cleanAllSubscriptions() {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  /**
   * Converts obervable to promise. If ngOnDestroy is called, the promise
   * is rejected.
   *
   * @protected
   * @template T Type for observable/promise
   * @param {Observable<T>} observable The observable to convert
   * @returns {Promise<T>} Converted promise that will reject if ngOnDestroy is called
   * @memberof Cleanupable
   */
  protected cancelablePromise<T>(
    waitable: Observable<T> | Promise<T>
  ): Promise<T> {
    const observable = waitable instanceof Promise ? from(waitable) : waitable;
    return new Promise((resolve, reject) => {
      let lastResult: T;
      this.subscriptions.push(
        observable
          .pipe(
            onCancel(() => {
              reject();
            })
          )
          .subscribe(
            (val) => {
              lastResult = val;
            },
            (err) => {
              reject(err);
            },
            () => {
              resolve(lastResult);
            }
          )
      );
    });
  }
}
