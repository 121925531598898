import { Asset, AssetId, AssetsFileProviderType, LayerOptions, VideoLayer } from '@openreel/creator/common';
import { v4 as uuidv4 } from 'uuid';

export function generateLayer(
  videoId: AssetId,
  videoProvider: AssetsFileProviderType,
  duration: number,
  isOverlay: boolean,
  startAt: number,
  trimFrom?: number,
  trimTo?: number
) {
  const asset: Asset = {
    id: uuidv4(),
    file: {
      path: videoId,
      provider: videoProvider,
    },
    type: 'clip',
    trimFrom: trimFrom || 0,
    trimTo: trimTo || duration,
  };

  const assetDuration = asset.trimTo - asset.trimFrom;

  const layer: VideoLayer & LayerOptions = {
    type: 'video',
    layerId: uuidv4(),
    assetId: asset.id,
    visibility: {
      startAt: startAt,
      endAt: startAt + assetDuration,
    },
  };

  if (isOverlay) {
    layer.bounds = {
      x: 75,
      y: 75,
      width: 20,
    }
  }

  return { layer, asset, assetDuration };
}
