import { Injectable } from '@angular/core';
import { merge, throwError, BehaviorSubject, Observable } from 'rxjs';
import { take, timeoutWith, filter, map } from 'rxjs/operators';
import { ReceiveSocketMessageInfo, ISocketIoLibService } from '../../interfaces/socket-lib-service.interface';
import { EVT_SEND_JOIN_ROOM, AckResponse, EVT_SEND_JOIN_ROOM_ACC } from '../../interfaces/socket-events';
import { BaseSocketService } from './base-socket.service';
import { EVT_SUBJECT_REQUEST_TO_JOIN, SocketRequestToJoin } from '../../interfaces/websocket/socket-request-to-join';
import { SessionBase } from '@openreel/common/interfaces';

// lightweight info about current session
interface CurrentSessionInfo {
  sessionId: number;
  // my identity
  identityId: string;
}

interface JoinRoomRequest {
  token: string;
  device_type: 'DESKTOP' | 'WEB';
  user_type: 'subject' | 'director';
}

interface JoinUgcRoomRequest {
  token: string;
  account_id: number;
  device_type: 'DESKTOP' | 'WEB';
  user_type: 'subject' | 'director';
}

export interface SendSocketInfo<SendType> {
  eventName: string;
  data: SendType;
  waitForAck?: boolean;
  sessionOverride?: CurrentSessionInfo;
}

export class SocketAckTimeout extends Error {}

@Injectable()
export class SubjectSocketService extends BaseSocketService {
  // All messages that are sent to me.
  private mySocketEvent$: Observable<ReceiveSocketMessageInfo<unknown>>;
  private currentSessionInfo$ = new BehaviorSubject<CurrentSessionInfo>(null);

  constructor(socketProvider: ISocketIoLibService) {
    super(socketProvider);
    this.mySocketEvent$ = this.anySocketEvent$.pipe(
      filter(({ eventName, data }) => this.isSocketForMe(eventName, data))
    );
  }

  // Utility function to return identity from current session.
  //   Purposely doesn't check if session is valid, you should
  //   check that yourself.
  private get currentIdentity() {
    return this.currentSessionInfo$.value.identityId;
  }
  // Utility function to return sessionid from current session.
  //   Purposely doesn't check if session is valid, you should
  //   check that yourself.
  private get currentSession() {
    return this.currentSessionInfo$.value.sessionId;
  }

  async requestToJoin(name: string, sessionBase: SessionBase) {
    const payload: SocketRequestToJoin = { name, id: sessionBase.login_id };
    this.basicSocketEmitWithSessionOverride(EVT_SUBJECT_REQUEST_TO_JOIN, payload, {
      identityId: sessionBase.identity,
      sessionId: sessionBase.session_id,
    });
  }

  async joinRoom(session: CurrentSessionInfo, request: JoinRoomRequest) {
    this.currentSessionInfo$.next(session);
    try {
      await this.sendAndWaitAck(
        {
          eventName: EVT_SEND_JOIN_ROOM,
          data: request,
        },
        BaseSocketService.ackTimeoutTime,
        EVT_SEND_JOIN_ROOM
      );
    } catch (err) {
      this.currentSessionInfo$.next(null);
      throw err;
    }
  }

  async joinUgcRoom(session: CurrentSessionInfo, request: JoinUgcRoomRequest) {
    this.currentSessionInfo$.next(session);
    await this.sendAndWaitAck(
      {
        eventName: EVT_SEND_JOIN_ROOM_ACC,
        data: request,
      },
      1000000,
      EVT_SEND_JOIN_ROOM_ACC
    );
  }

  // Have we ever successfuly joined the room on current session info.
  isJoinedRoom() {
    return this.currentSessionInfo$.value != null;
  }

  // Emit event and wait for ackownledgement from someone. If timeout is
  // reached, error is thrown. If ackEventName is not provided, some common
  // event names are waited for (for example ack_requestedName,
  // ackRequestedName, etc)
  async sendAndWaitAck<SendType, ReceiveType extends AckResponse>(
    req: SendSocketInfo<SendType>,
    ackTimeout = BaseSocketService.ackTimeoutTime,
    ackEventName?: string
  ): Promise<ReceiveType> {
    this.emitSocket(req);
    let evt: Observable<ReceiveType>;
    if (!ackEventName) {
      evt = await merge(
        this.getMySocketEventByName<ReceiveType>('ack_' + req.eventName),
        this.getMySocketEventByName<ReceiveType>('ack' + req.eventName)
      );
    } else {
      evt = this.getMySocketEventByName<ReceiveType>(ackEventName);
    }
    const ret = await evt
      .pipe(
        take(1),
        timeoutWith(ackTimeout, throwError(new SocketAckTimeout('Timeout waiting for ack for ' + req.eventName)))
      )
      .toPromise();
    if (ret.stat === '0') {
      throw new Error(ret.message);
    } else {
      return ret;
    }
  }
  getMySocketEventByName<T>(eventName: string): Observable<T> {
    return this.mySocketEvent$.pipe(
      filter((event: ReceiveSocketMessageInfo<T>) => event.eventName === eventName),
      map((event) => event.data)
    );
  }
  getSocketEventByName<T>(eventName: string): Observable<T> {
    return this.anySocketEvent$.pipe(
      filter((event: ReceiveSocketMessageInfo<T>) => event.eventName === eventName),
      map((event) => event.data)
    );
  }

  basicSocketEmit<SendValueType>(event: string, value: SendValueType) {
    this.emitSocket<SendValueType>({
      data: value,
      eventName: event,
    });
  }

  basicSocketEmitWithSessionOverride<SendValueType>(
    event: string,
    value: SendValueType,
    sessionOverride: CurrentSessionInfo
  ) {
    this.emitSocket<SendValueType>({
      data: value,
      eventName: event,
      sessionOverride,
    });
  }

  // Send message on websocket. Appends identity and session id automatically.
  emitSocket<SendType>(data: SendSocketInfo<SendType>) {
    let session = data.sessionOverride;
    if (!session) {
      session = this.currentSessionInfo$.value;
    }
    if (session) {
      this.socket.emit(data.eventName, {
        ...data.data,
        identity: session.identityId,
        SessionID: session.sessionId,
      });
    } else {
      throw new Error('Tried to emit socket while not joined to the room.');
    }
  }
  private isSocketForMe(eventName: string, data): boolean {
    if (!this.isJoinedRoom()) {
      return false;
    }
    const ret =
      // eslint-disable-next-line eqeqeq
      data.SessionID == this.currentSession &&
      (data.identity === this.currentIdentity ||
        (data.identityArr && data.identityArr.indexOf(this.currentIdentity) !== -1));
    // console.log(eventName + " " + (ret ? "FOR ME" : "NOT FOR ME"));
    // console.log(data);
    return ret;
  }
}
