import { Injectable } from '@angular/core';
import { HostingHub } from '../hosting-interfaces';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HostingService } from './hosting.service';

@Injectable({providedIn: 'root'})
export class HostingHubService {

  constructor(private hostingService: HostingService) { }

  // link and unlink the video to Hub(s).
  linkUnlinkHubs(videoId: number, result: {checked: HostingHub[], unchecked: HostingHub[]}) {
    const batchObservables = [
      ...result.checked.map(checked => 
          this.hostingService.link2Hub(videoId, checked.id).pipe(catchError(error => of({error})))),
      ...result.unchecked.map(unchecked => 
          this.hostingService.unlinkFromHub(videoId, unchecked.id).pipe(catchError(error => of({error})))),
    ];
    return forkJoin(batchObservables);
  }
}
