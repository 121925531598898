<form *ngIf="form" [formGroup]="form">
  <div fxLayout="row" fxLayout.xs="column" style="margin-top: 30px;" fxLayoutGap="20px">
    <div fxFlex.gt-xs="190px">
      <div class="control-label">Speed</div>
      <mat-form-field class="telepromter-form-field" appearance="outline" style="width: 100%;">
        <mat-select formControlName="speed" style="padding-bottom: 0px">
          <mat-option [attr.data-cy]="'teleprompt-speed-value-'+speed.value" *ngFor="let speed of options.speeds" [value]="speed.value">
            {{speed.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex.gt-xs="190px">
      <div class="control-label">Font Size</div>
      <mat-form-field class="telepromter-form-field" appearance="outline" style="width: 100%;">
        <mat-select formControlName="font_size" style="padding-bottom: 0px">
          <mat-option [attr.data-cy]="'teleprompt-font-value-'+fontSize.value" *ngFor="let fontSize of options.fontSizes" [value]="fontSize.value">
            {{fontSize.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" style="margin-top: 20px;" fxLayoutGap="20px">
    <div fxFlex.gt-xs="190px">
      <div class="control-label">Screen Setup</div>
      <mat-form-field class="telepromter-form-field" appearance="outline" style="width: 100%;">
        <mat-select formControlName="screen_setup" style="padding-bottom: 0px">
          <mat-option [attr.data-cy]="'teleprompt-screensetup-value-'+split.value" *ngFor="let split of options.splits" [value]="split.value">
            {{split.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex.gt-xs="190px">
      <div class="control-label">Background</div>
      <mat-form-field class="telepromter-form-field" appearance="outline" style="width: 100%;">
        <mat-select formControlName="background" style="padding-bottom: 0px" panelClass="background-panel">
          <mat-option [attr.data-cy]="'teleprompt-background-value-'+background.value" *ngFor="let background of options.backgrounds" [value]="background.value" [ngClass]="background.cls">
            <div class="option-border" > {{background.viewValue}}</div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
