import * as fromActions from './../actions/self-record-clips.actions';
import * as fromGlobalActions from '@openreel/creator/app/store/actions/global.actions';
import * as fromTimelineActions from '@openreel/creator/app/store/actions/timelines.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { SelfRecordFacade } from '@openreel/creator/app/common/recording/store/facades/self-record.facade';
import { ProjectFacade } from '@openreel/creator/app/store/facades/project.facade';
import { getTimelineById } from '@openreel/creator/common';
import { SelfRecordClipsFacade } from '../facades/self-record-clips.facade';
import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';
import { ApplySelfRecordClipsEvent } from '../interfaces/self-record-clips.interfaces';

@Injectable()
export class SelfRecordClipsEffects {
  applySelfRecordLastClips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applySelfRecordLastClips),
      withLatestFrom(
        this.selfRecordFacade.lastUploadWebcamVideo$,
        this.selfRecordFacade.lastUploadScreenVideo$,
        this.selfRecordFacade.trims$,
        this.selfRecordFacade.source$,
        this.selfRecordFacade.options$
      ),
      map(([_, lastUploadWebcamVideo, lastUploadScreenVideo, trims, source, options]) => {
        const event: ApplySelfRecordClipsEvent = {
          clips: SelfRecordClipsFacade.constructClipsFromSelfRecordVideos(
            source,
            lastUploadWebcamVideo,
            lastUploadScreenVideo,
            trims
          ),
          timelineIds: options.timelineIds.get(source),
          goToTrim: false,
        };

        this.mixpanelService.logEvent('self_record_use_video', 'Use recorded video');

        return fromActions.applySelfRecordClips({ event });
      })
    )
  );

  applySelfRecordClipsEventSingleTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applySelfRecordClips),
      withLatestFrom(this.projectFacade.workflow$),
      filter(([{ event }]) => event.timelineIds.length === 1),
      map(([{ event }, workflow]) => {
        const timelineId = event.timelineIds[0];
        const { timeline } = getTimelineById(timelineId, workflow.sections);

        if (event.goToTrim) {
          const addEvent = {
            timelineId,
            clips: event.clips,
            replace: false,
          };

          if (timeline.type === 'main') {
            return fromTimelineActions.addMainClip({ event: addEvent });
          } else {
            return fromTimelineActions.addVideoOverlay({ event: addEvent });
          }
        } else {
          return fromTimelineActions.addClipsToTimelines({
            timelineIds: [timelineId],
            clips: event.clips,
          });
        }
      })
    )
  );

  applySelfRecordClipsEventMultiTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applySelfRecordClips),
      filter(({ event }) => event.timelineIds.length > 1),
      map(({ event }) => {
        if (event.goToTrim) {
          console.warn('Go to trim not supported on multiple timelines.');
          return fromGlobalActions.noOp();
        }
        return fromTimelineActions.addClipsToTimelines({
          timelineIds: event.timelineIds,
          clips: event.clips,
        });
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly mixpanelService: MixpanelService,
    private readonly selfRecordFacade: SelfRecordFacade,
    private readonly projectFacade: ProjectFacade
  ) {}
}
