import { Injectable } from '@angular/core';
import {
  MSG_DELETE_RECORDING,
  MSG_GET_RECORDING_CONTENTS,
  MSG_LIST_RECORDINGS,
} from '../../messages';
import {
  LocalRecording,
  LocalRecordingVideo,
} from '../../models/local-recording.interface';
import { ChromeMessagingService } from '../chrome-messaging/chrome-messaging.service';

@Injectable()
export class LocalRecordingsService {
  constructor(
    private readonly chromeMessagingService: ChromeMessagingService
  ) {}

  async listRecordings(): Promise<LocalRecording[]> {
    try {
      return await this.chromeMessagingService.send<LocalRecording[]>({
        event: MSG_LIST_RECORDINGS,
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async deleteRecording(id: string): Promise<void> {
    try {
      return await this.chromeMessagingService.send({
        event: MSG_DELETE_RECORDING,
        data: id,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getRecordingContents(id: string): Promise<LocalRecordingVideo[]> {
    try {
      return await this.chromeMessagingService.send({
        event: MSG_GET_RECORDING_CONTENTS,
        data: id,
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
