import { LoggingService } from './logging.service';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConsoleLoggingService extends LoggingService {
  constructor(private logger: NGXLogger) {
    super();
  }

  log(message: string, ...args: []) {
    this.logger.log(message, ...args);
  }
  debug(message: string, ...args: []) {
    this.logger.debug(message, ...args);
  }
  warn(message: string, ...args: []) {
    this.logger.warn(message, ...args);
  }
  trace(message: string, ...args: []) {
    this.logger.trace(message, ...args);
  }
  info(message: string, ...args: []) {
    this.logger.info(message, ...args);
  }
  fatal(message: string, ...args: []) {
    this.logger.fatal(message, ...args);
  }
  error(message: string, ...args: []) {
    this.logger.error(message, ...args);
  }
}
