import { createAction, props } from '@ngrx/store';
import { ApplySelfRecordClipsEvent } from '../interfaces/self-record-clips.interfaces';

const actionType = '[SelfRecordClips]';

export const applySelfRecordClips = createAction(
  `${actionType} Apply Self Record Clips`,
  props<{ event: ApplySelfRecordClipsEvent }>()
);

export const applySelfRecordLastClips = createAction(
  `${actionType} Apply Self Record Last Clips`
);
