<mat-card class="p-24" *ngIf="selfRecordFacade.status$ | async as recordStatus">
  <mat-card-content
    *ngIf="recordStatus === 'preview'"
    class="flex-col flex-align-center w-full"
  >
    <div class="record-header m-bottom-16">
      <div>New self record</div>
    </div>
    <openreel-wf-self-record-trim></openreel-wf-self-record-trim>
    <div>
      <div class="footer">
        <button
          *ngIf="
            options?.finalActions.retakeVideo ||
            options?.finalActions.recordNextVideo
          "
          class="mat-button-dark"
          data-cy="retake-video-button"
          mat-flat-button
          (click)="retake()"
        >
          <ng-container *ngIf="options?.finalActions.retakeVideo">
            <mat-icon class="mat-icon-16" svgIcon="reset"></mat-icon>
            Retake video
          </ng-container>
          <ng-container *ngIf="options?.finalActions.recordNextVideo">
            <mat-icon class="mat-icon-16" svgIcon="plus-alt"></mat-icon>
            Record next video
          </ng-container>
        </button>
        <button
          *ngIf="options?.finalActions.useVideo"
          color="primary"
          data-cy="use-video-button"
          mat-flat-button
          (click)="useClips()"
        >
          <mat-icon class="mat-icon-16" svgIcon="check"></mat-icon>
          Use video
        </button>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content
    *ngIf="recordStatus !== 'preview'"
    class="flex-col flex-align-center w-full"
  >
    <div class="preview">
      <ng-container *ngIf="recordStatus === 'uploading'; else elseBlock1">
        <openreel-wf-self-record-preview
          previewType="upload"
        ></openreel-wf-self-record-preview>
      </ng-container>
      <ng-template #elseBlock1>
        <openreel-double-video
          *ngIf="
            (selfRecordFacade.streamStatus$ | async) !== 'denied';
            else persmissionDeniedBlock
          "
          [screenStream]="selfRecordFacade.screenStream$ | async"
          [webcamStream]="selfRecordFacade.webcamStream$ | async"
          [recordingType]="source"
          (onScreenStreamPlaying)="
            selfRecordFacade.updateScreenStreamStatus('playing')
          "
          (onWebcamStreamPlaying)="
            selfRecordFacade.updateWebcamStreamStatus('playing')
          "
        ></openreel-double-video>
        <ng-template #persmissionDeniedBlock>
          <div
            *ngIf="(selfRecordFacade.webcamStreamStatus$ | async) === 'denied'"
            class="permissions py-20"
          >
            Camera and/or microphone permission denied.
            <br />
            <br />
            Please grant both camera and microphone permissions to OpenReel
            using the browser's settings in order to record a video.

            <openreel-browser-permissions-instructions-popup></openreel-browser-permissions-instructions-popup>
          </div>
        </ng-template>
      </ng-template>
      <div class="progress">
        <openreel-self-record-upload-progress
          [uploadStatus$]="selfRecordFacade.uploadWebcamStatus$"
        ></openreel-self-record-upload-progress>
        <openreel-self-record-upload-progress
          [uploadStatus$]="selfRecordFacade.uploadScreenStatus$"
        ></openreel-self-record-upload-progress>
        <openreel-self-record-upload-progress
          [transcodeStatus$]="selfRecordFacade.transcodeWebcamStatus$"
        ></openreel-self-record-upload-progress>
        <openreel-self-record-upload-progress
          [transcodeStatus$]="selfRecordFacade.transcodeScreenStatus$"
        ></openreel-self-record-upload-progress>
      </div>
    </div>

    <div class="flex recording-footer">
      <div
        *ngIf="
          (recordStatus === 'idle' || recordStatus === 'recording') &&
            selfRecordFacade.streamStatus$ | async as streamStatus
        "
        class="text-center recording-footer-button"
      >
        <button
          *ngIf="streamStatus !== 'denied'"
          mat-button
          (click)="toggleRecording()"
          [attr.data-cy]="'record-button' + recordStatus"
          class="flex flex-align-center record py-8 px-16"
          [class.recording]="recordStatus === 'recording'"
          [class.loading]="
            streamStatus === 'loading' || streamStatus === 'initializing'
          "
          [disabled]="
            streamStatus === 'loading' || streamStatus === 'initializing'
          "
        >
          <div class="record-icon">
            <mat-icon class="mat-icon-20" svgIcon="record-start-16"></mat-icon>
          </div>
          <span class="record-label">
            {{
              streamStatus === 'loading' || streamStatus === 'initializing'
                ? 'Loading...'
                : 'Start Recording '
            }}
          </span>

          <div class="recording-icon">
            <mat-icon class="mat-icon-20" svgIcon="record-stop-16"></mat-icon>
          </div>
          <span class="recording-label">
            <div class="recording-label-text">Stop</div>
            <div class="recording-timer">
              <ng-container
                *ngIf="
                  recordingTimer$ | async as recordingTimer;
                  else elseBlock2
                "
              >
                {{ recordingTimer }}
              </ng-container>
              <ng-template #elseBlock2> 00:00:00 </ng-template>
            </div>
          </span>
        </button>

        <button
          *ngIf="(selfRecordFacade.screenStreamStatus$ | async) === 'denied'"
          mat-button
          class="mat-button-dark"
          (click)="selfRecordFacade.retry()"
        >
          Screen permission denied, click to retry
        </button>
      </div>
      <div
        class="flex recording-footer-options"
        *ngIf="recordStatus === 'idle'"
      >
        <button
          *ngIf="options?.teleprompter"
          mat-button
          (click)="openTeleprompterDialog()"
          data-cy="teleprompter-button"
          class="m-right-8 py-8 px-16 teleprompter-button"
        >
          Teleprompter
        </button>
        <mat-select
          class="p-8"
          [disabled]="
            (selfRecordFacade.streamStatus$ | async) === 'initializing'
          "
          [(ngModel)]="source"
          (ngModelChange)="onSourceChange()"
        >
          <mat-select-trigger class="flex flex-align-center">
            <mat-icon
              class="mat-icon-16 m-right-16"
              [svgIcon]="source"
            ></mat-icon>
            <span class="f-normal-14 source-label" [ngSwitch]="source">
              <ng-container *ngSwitchCase="'screen'">Screen</ng-container>
              <ng-container *ngSwitchCase="'camera'">Camera</ng-container>
              <ng-container *ngSwitchCase="'both-pip'"
                >Screen and Camera</ng-container
              >
            </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let recordingType of allowedSources()"
            [value]="recordingType"
          >
            <mat-icon
              [attr.data-cy]="'toggle-' + recordingType"
              [svgIcon]="recordingType"
              aria-hidden="false"
              [attr.aria-label]="recordingType"
            ></mat-icon>
            <span class="" [ngSwitch]="recordingType">
              <ng-container *ngSwitchCase="'screen'">Screen</ng-container>
              <ng-container *ngSwitchCase="'camera'">Camera</ng-container>
              <ng-container *ngSwitchCase="'both-pip'"
                >Screen and Camera</ng-container
              >
            </span>
          </mat-option>
        </mat-select>
        <button
          mat-icon-button
          [matMenuTriggerFor]="sources"
          data-cy="settings-button"
          class="settings-button m-left-20"
        >
          <mat-icon class="mat-icon-20" svgIcon="gear"></mat-icon>
        </button>
        <mat-menu #sources="matMenu">
          <button
            *ngIf="source !== 'screen'"
            mat-menu-item
            [matMenuTriggerFor]="videoSources"
          >
            <mat-icon
              class="source-icon"
              data-cy="video-camera"
              svgIcon="video-camera"
              aria-hidden="false"
              aria-label="Video camera"
            ></mat-icon>
            <span
              *ngIf="
                selfRecordFacade.selectedWebcamDevice$
                  | async as selectedWebcamDevice
              "
            >
              {{ selectedWebcamDevice.name }}
            </span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="audioSources">
            <mat-icon
              class="source-icon"
              svgIcon="mic"
              aria-hidden="false"
              aria-label="Microphone"
            ></mat-icon>
            <span
              *ngIf="
                selfRecordFacade.selectedAudioDevice$
                  | async as selectedAudioDevice
              "
            >
              {{ selectedAudioDevice.name }}
            </span>
          </button>
        </mat-menu>
        <mat-menu #videoSources="matMenu">
          <ng-container
            *ngIf="
              selfRecordFacade.availableWebcamDevices$
                | async as availableWebcamDevices
            "
          >
            <button
              *ngFor="let availableWebcamDevice of availableWebcamDevices"
              (click)="
                selfRecordFacade.setSelectedWebcamDevice(availableWebcamDevice)
              "
              mat-menu-item
            >
              <div class="webcam-item">
                <div class="webcam-name">
                  {{ availableWebcamDevice.name }}
                </div>
                <div class="webcam-resolution">
                  {{ availableWebcamDevice.width }}x{{
                    availableWebcamDevice.height
                  }}
                </div>
              </div>
            </button>
          </ng-container>
        </mat-menu>
        <mat-menu #audioSources="matMenu">
          <ng-container
            *ngIf="
              selfRecordFacade.availableAudioDevices$
                | async as availableAudioDevices
            "
          >
            <button
              *ngFor="let availableAudioDevice of availableAudioDevices"
              (click)="
                selfRecordFacade.setSelectedAudioDevice(availableAudioDevice)
              "
              mat-menu-item
            >
              <span>{{ availableAudioDevice.name }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<openreel-wf-self-record-takes
  *ngIf="options?.previousTakes"
></openreel-wf-self-record-takes>
