import * as OT from '@opentok/client';
import { from } from 'rxjs';
import {
  NextgenParticipant,
  PartialNextgenParticipant,
} from '../../interfaces/nextgen-participant.interface';
import { ParticipantMapper, StreamMapper } from './participant-mapper';

export class OpentokParticipantMapper
  extends ParticipantMapper
  implements StreamMapper
{
  private videoPublisher: OT.Publisher;
  private audioPublisher: OT.Publisher;
  public connected = false;
  get initialLocal(): NextgenParticipant {
    const identity = this.getIdentity(this.session?.connection?.data);
    return {
      ...this.initialParticipant,
      audioOutputEnabled: true,
      identity: identity,
      isIosDevice: identity.split('_')[0] === 'ios',
    };
  }

  get initialRemote(): NextgenParticipant[] {
    return [];
  }

  constructor(private session: OT.Session) {
    super();
    this._participantConnectedCreator(session);
    session.on('sessionConnected', () => {
      this.connected = true;
      this._connected.next();
    });
    session.on('sessionDisconnected', (error) => {
      this.connected = false;
      this._disconnected.next(error.reason);
    });
    session.on('sessionReconnected', () => {
      this.connected = true;
      this._reconnected.next();
    });
    session.on('sessionReconnecting', () => this._reconnecting.next());

    session.on('connectionDestroyed', (event) => {
      this._participantEvent.next({
        identity: this.getIdentity(event.connection.data),
        isConnected: false,
      });
    });
  }

  private _participantConnectedCreator(session: OT.Session) {
    session.on('connectionCreated', (event) => {
      //when new participant connection found
      const identity = this.getIdentity(event.connection.data);
      if (identity !== this.initialLocal.identity) {
        //if the participant is not local participant
        this._participantConnected.next({
          ...this.initialParticipant,
          identity: identity,
          isIosDevice: identity.split('_')[0] === 'ios',
        });
      }
    });
    session.on('streamCreated', (event) => {
      //when new audio or video stream found on the session
      this.subscribeStream(event.stream);
    });

    session.on('streamPropertyChanged', (event) => {
      //when session property change (mic or camera enabled/disabled)
      const partial: PartialNextgenParticipant = {
        identity: this.getIdentity(event.stream.connection.data),
      };
      if (event.changedProperty === 'hasAudio') {
        partial.isMuted = !event.stream.hasAudio;
      } else if (event.changedProperty === 'hasVideo') {
        partial.isVideoDisabled = !event.stream.hasVideo;
      }
      this._participantEvent.next(partial);
    });
  }

  public toggleMuteStatus(status: boolean) {
    this.audioPublisher.publishAudio(!status);
  }

  public toggleVideoEnabledStatus(status: boolean) {
    this.videoPublisher.publishVideo(!status);
  }

  public disconnectRoom() {
    this.session?.disconnect();
  }

  public publishVideoTrack(
    videoSource: MediaStreamTrack,
    destroyPreviousTrack = true
  ) {
    if (this.videoPublisher?.stream?.hasVideo) {
      this.unpublishStream(this.videoPublisher, destroyPreviousTrack);
    }
    const div = document.createElement('div');
    this.videoPublisher = OT.initPublisher(div, {
      videoSource: videoSource,
      audioSource: null,
      name: this.initialLocal.identity,
    });
    this.videoPublisher.on('streamDestroyed', (e) => {
      e.preventDefault();
    });
    return from(this.publishTrackToSession(this.videoPublisher));
  }

  public publishAudioTrack(audioSource: MediaStreamTrack) {
    if (this.audioPublisher?.stream?.hasAudio) {
      this.unpublishStream(this.audioPublisher, true);
    }
    const div = document.createElement('div');
    this.audioPublisher = OT.initPublisher(div, {
      audioSource: audioSource,
      videoSource: null,
      name: this.initialLocal.identity,
    });
    return from(this.publishTrackToSession(this.audioPublisher));
  }

  public unpublishVideo() {
    if (this.videoPublisher) {
      this.unpublishStream(this.videoPublisher, true);
    }
  }

  public unpublishAudio() {
    if (this.audioPublisher) {
      this.unpublishStream(this.audioPublisher, true);
    }
  }

  private unpublishStream(
    publisher: OT.Publisher,
    destroyPreviousTrack: boolean
  ) {
    this.session.unpublish(publisher);
    if (destroyPreviousTrack) publisher.destroy();
  }

  private publishTrackToSession(publisher: OT.Publisher): Promise<OT.Stream> {
    return new Promise((resolve, reject) => {
      this.session.publish(publisher, (err) => {
        if (err) {
          reject(err);
        } else {
          this._localTrackPublished.next({
            type: publisher.stream.hasAudio ? 'audio' : 'video',
            id: publisher.stream.streamId,
          });
          resolve(publisher.stream);
        }
      });
    });
  }

  private getIdentity(data: string) {
    if(!data) return '';
    const parseData = JSON.parse(data);
    return parseData.identity;
  }
  private subscribeStream(stream: OT.Stream) {
    const div = document.createElement('div');
    const subscriber = this.session.subscribe(stream, div, null, () => {
      const video = subscriber.element.querySelector('video');
      //this is the only way to get the stream & track from opentok subscriber
      const mediaStream: MediaStream = video.srcObject as MediaStream;
      const videoTracks = mediaStream.getVideoTracks();
      const audioTracks = mediaStream.getAudioTracks();
      const identity = this.getIdentity(stream.connection.data);
      this._trackPublication(
        videoTracks,
        audioTracks,
        stream.hasVideo,
        stream.hasAudio,
        identity,
        stream.streamId
      );
      subscriber.setAudioVolume(0); // We need to mute the actual subscriber audio
    });
  }
  private _trackPublication = (
    videoTracks: MediaStreamTrack[],
    audioTracks: MediaStreamTrack[],
    hasVideo: boolean,
    hasAudio: boolean,
    identity: string,
    streamId: string
  ) => {
    const partial: PartialNextgenParticipant = {
      identity: identity,
    };
    if (videoTracks.length > 0) {
      partial.videoTracks = videoTracks;
      partial.isVideoDisabled = !hasVideo;
      partial.videoStreamId = streamId;
    }
    if (audioTracks.length > 0) {
      partial.audioTracks = audioTracks;
      partial.isMuted = !hasAudio;
      partial.audioStreamId = streamId;
    }
    this._participantEvent.next(partial);
  };
}
