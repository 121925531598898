import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrModule } from 'ngx-toastr';
import { UiModule } from '@openreel/ui';
import { OpenreelCommonDirectivesModule } from '../directives/openreel-common-directives.module';
import { CreateHostableComponent } from './dialogs/create-hostable/create-hostable.component';
import { CreateHubComponent } from './dialogs/create-hub/create-hub.component';
import { AddToHubComponent } from './dialogs/add-to-hub/add-to-hub.component';
import { HostingShareComponent } from './dialogs/hosting-share/hosting-share.component';
import { HostingConfirmComponent } from './dialogs/hosting-confirm/hosting-confirm.component';
import { EmailListComponent } from './components/email-list/email-list.component';
import { 
    PreviewThumbComponent, 
    SlugInfoIconComponent,
    PermissionSelectorComponent,
    InlineEditableComponent,
    ThumbnailInfoIconComponent,
} from './components';

const PUBLIC_COMPONENTS = [
    SlugInfoIconComponent, 
    PreviewThumbComponent,
    EmailListComponent,
    PermissionSelectorComponent,
    InlineEditableComponent,
    ThumbnailInfoIconComponent,
];

const PRIVATE_COMPONENTS = [
    CreateHostableComponent,
    AddToHubComponent,
    CreateHubComponent,
    HostingShareComponent,
    HostingConfirmComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule, 
        MatTooltipModule, 
        MatIconModule, 
        MatDialogModule, 
        MatInputModule, 
        MatCheckboxModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCardModule,
        MatSelectModule,
        MatChipsModule,
        ClipboardModule,
        OpenreelCommonDirectivesModule,
        UiModule,
        ToastrModule,
        MatProgressBarModule,
    ],
    declarations: [...PUBLIC_COMPONENTS, ...PRIVATE_COMPONENTS],
    exports: [...PUBLIC_COMPONENTS],
})
export class HostingCommonModule {}