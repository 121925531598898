import { LayerStyles, LottieProcessedFieldsData, Preset, Transition } from '@openreel/creator/common';
import { Animation } from '@openreel/lottie-parser';

export class TimelinesPlayerData {
  backgroundPlayers?: ControllerData[];
  overlays?: ControllerData[][];
  mainPlayers: ControllerData[];
  backgroundColor?: string;

  constructor(partial: Partial<TimelinesPlayerData>) {
    Object.assign(this, partial);
  }
}

export class VideoPlayerData {
  source: string;
  type: string;
  startAt?: number;
  endAt?: number;
  hasAudio?: boolean;
  zIndex: number;
  loop?: boolean;

  constructor(partial: Partial<VideoPlayerData>) {
    Object.assign(this, partial);
  }
}

export type LottieRenderer = 'svg' | 'canvas' | 'html';

export class LottiePlayerData {
  animation?: Animation;
  data?: LottieProcessedFieldsData;
  preset?: Preset;
  renderer: LottieRenderer;
  duration?: number;
  loop?: boolean;

  constructor(partial: Partial<PlayerData>) {
    Object.assign(this, partial);
  }
}

export class FabricPlayerData {
  constructor(partial: Partial<FabricPlayerData>) {
    Object.assign(this, partial);
  }
}

export class ImagePlayerData {
  source: string;
  layout?: Layout;

  constructor(partial: Partial<ImagePlayerData>) {
    Object.assign(this, partial);
  }
}

export interface PlayerDataOptions {
  externalId?: string;
}

export type PlayerData = PlayerDataOptions &
  (TimelinesPlayerData | VideoPlayerData | LottiePlayerData | FabricPlayerData);

export interface SelectionOptions {
  selectable: boolean;
}

export interface ControlOptions {
  zoomToFit: boolean;
  switch: boolean;
}

export interface Transitions {
  entrance?: Transition;
  exit?: Transition;
  cross?: Transition;
  crossLayer?: ParsedTransitionLayer;
}

export interface ParsedTransitionLayer {
  type: 'layer';
  duration?: number;
  playerData: PlayerData;
}

export interface ControllerData {
  playerData: PlayerData;
  layout: Layout;
  selection?: SelectionOptions;
  controls?: ControlOptions;
  cue?: CueData;
  transitions?: Transitions;
  defaultLayout?: Layout;
  styles?: LayerStyles;
}

export interface Layout {
  x: number;
  y: number;
  width: number;
  height?: number;
}

export interface CueData {
  startAt: number;
  endAt?: number;
}
