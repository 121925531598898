import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import {
  FeatureFlaggingService,
  WORKFLOWS_SELF_RECORDING,
} from '@openreel/common';

@Injectable()
export class SelfRecordGuard implements CanActivate, CanActivateChild {
  constructor(private featureFlaggingService: FeatureFlaggingService) {}

  checkRoutePermission(): boolean {
    return this.featureFlaggingService.getFlag(WORKFLOWS_SELF_RECORDING);
  }

  canActivate(): boolean {
    return this.checkRoute();
  }

  canActivateChild(): boolean {
    return this.checkRoute();
  }

  private checkRoute(): boolean {
    if (!this.checkRoutePermission()) {
      return false;
    }

    return true;
  }
}
