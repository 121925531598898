import { CommonModule } from '@angular/common';
import { MaterialModule } from '@openreel/common';
import { NgModule } from '@angular/core';
import { components } from './components';
import { OpenreelCuePlayerModule } from '@openreel/ui/openreel-cue-player';

@NgModule({
  declarations: [...components],
  imports: [CommonModule, OpenreelCuePlayerModule, MaterialModule],
  exports: [...components],
})
export class EditorPreviewerModule {}
