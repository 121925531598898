import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'openreel-search-input',
  templateUrl: './orsearchinput.component.html',
  styleUrls: ['./orsearchinput.component.scss'],
})
export class OrSearchInputComponent implements OnInit {
  @Input()
  placeholder: string;

  @Input()
  debounceTime = 500;

  @Output()
  valueChange = new EventEmitter<string>();

  private search$ = new Subject<string>();

  ngOnInit() {
    this.search$
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((text: string) => {
        this.valueChange.emit(text);
      });
  }

  onValueChange(value) {
    this.search$.next(value);
  }
}
