// contains all the interfaces used by sessions or by the services or classes under current directory
import { AdmitUsersOptions, Member, SingleInvitation, UserRoleType, VideoEncodeOptions } from '../../interfaces';
import { ProjectData } from '../project/project.interface';
export interface SessionSettingsDto {
  recording_countdown_enabled: boolean;
  teleprompter_countdown_enabled: boolean;
  countdown_value: number;
  keep_teleprompter_on_screen: boolean;
  upload_during_recording: string[];
  admitUsers: AdmitUsersOptions;
  video_encode_type: VideoEncodeOptions;
}

export class SessionProjectDto {
  data: ProjectData;
}

export enum SessionDeleteStatus {
  Trash = '1',
  PermanentDelete = '0',
}
export interface SessionArchivedData {
  session_id: number;
  session_name: string;
  deleted_at: Date;
  total_clips: number;
}

export interface DashboardSummarySessionsResponse {
  status: number;
  data: DashboardSummarySession[];
}

export interface DashboardSummarySession {
  project_id: number;
  session_id: number;
  session_name: string;
  created_at: Date;
  session_updatedAt: Date;
  last_access_at: Date;
}

export interface RemoveInviteeDto {
  SessionID: number;
  user_mapping_id: number;
  subject_email: string;
}

export interface UpdateResendInvitation {
  session_id: number;
  invitee_name: string;
  invitee_email: string;
  invitee_type: UserRoleType;
}

export interface SessionUserDetails {
  session_id: number;
  session_name: string;
  admin_members: Array<Member>;
  invited_members: SingleInvitation[];
}

export enum SessionType {
  Pro = 'pro',
  UGC = 'ugc',
  SelfRecord = 'self_record',
}
