import * as fromActions from '../actions/self-record-clips.actions';

import { Injectable } from '@angular/core';
import { ApplySelfRecordClipsEvent } from '../interfaces/self-record-clips.interfaces';
import { Store } from '@ngrx/store';
import { RecordingType } from '../../../../../../../../libs/common/src';
import { SelfRecordClipTrim, SelfRecordingVideo } from '../interfaces/self-record.interfaces';
import { NewClip, Source } from '@openreel/creator/common';

@Injectable({
  providedIn: 'root',
})
export class SelfRecordClipsFacade {
  static constructClipsFromSelfRecordVideos(
    source: RecordingType,
    lastUploadWebcamVideo: SelfRecordingVideo,
    lastUploadScreenVideo: SelfRecordingVideo,
    trims: SelfRecordClipTrim[]
  ) {
    const clips: NewClip[] = [];

    function getClip(video: SelfRecordingVideo, name: string, source: Source) {
      const trim = trims.find((t) => t.videoId.toString() === video.videoId.toString());
      const duration = video.duration * 1000;
      const clip: NewClip = {
        assetFileId: video.videoId,
        assetProviderType: 'or-recordings',
        source: source,
        duration,
        name,
        trimFrom: trim?.trimFrom ?? 0,
        trimTo: trim?.trimTo ?? duration,
      };
      return clip;
    }

    switch (source) {
      case 'camera': {
        clips.push(getClip(lastUploadWebcamVideo, 'webcam_recording', 'webcam'));
        break;
      }
      case 'screen': {
        clips.push(getClip(lastUploadScreenVideo, 'screen_recording', 'screen'));
        break;
      }
      case 'both-pip': {
        clips.push(getClip(lastUploadWebcamVideo, 'webcam_recording', 'webcam'));
        clips.push(getClip(lastUploadScreenVideo, 'screen_recording', 'screen'));
        break;
      }
    }

    return clips;
  }

  constructor(private readonly store: Store) {}

  applySelfRecordClipsEvent(event: ApplySelfRecordClipsEvent) {
    this.store.dispatch(fromActions.applySelfRecordClips({ event }));
  }

  applySelfRecordLastClips() {
    this.store.dispatch(fromActions.applySelfRecordLastClips());
  }
}
