<input
  #fileInput
  [hidden]="true"
  type="file"
  data-cy="file-upload"
  [accept]="acceptedFileExtensions"
  (change)="onFileDialogChange($event)"
/>
<div
  class="container"
  [class.dark]="darkBackground"
  (dragover)="onDragOver($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <div class="background" [class.has-color]="parsedOptions.showBackgroundColor"></div>
  <ng-container *ngIf="!isUploading">
    <div *ngIf="!isUploaded" class="upload">
      <div data-cy="upload-caption">
        <ng-container *ngIf="uploadCaption">{{ uploadCaption }}</ng-container>
        <ng-content *ngIf="!uploadCaption"></ng-content>
      </div>
      <button *ngIf="!isDisabled" type="button" (click)="onSelectFile()"></button>
    </div>
    <div *ngIf="isUploaded" [class]="'uploaded ' + uploadedClasses" [class.full-size]="fullSize">
      <ng-container *ngIf="type === 'image'">
        <img data-cy="uploaded-image" [openreelAssetImage]="sourceId" alt="Uploaded Image" />
      </ng-container>
      <ng-container *ngIf="type === 'video'">
        <video
          #video
          muted
          loop
          disablepictureinpicture
          oncontextmenu="return false;"
          preload="metadata"
          src=""
          [openreelAssetVideo]="sourceId"
          [assetVideoProvider]="selectedSourceProvider"
        ></video>
      </ng-container>
      <div class="file-info" *ngIf="type === 'document'">
        <mat-icon class="doc-icon">description</mat-icon>
        <div class="name-size">
          <div class="file-name">{{ fileName }}</div>
          <div class="file-size">{{ fileSize }}</div>
        </div>
      </div>
      <button *ngIf="!isDisabled && parsedOptions.showRemoveAction" type="button" (click)="removeFile()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="isUploading">
    <div class="file-info">
      <mat-icon svgIcon="upload-file"></mat-icon>
      <div class="name-size">
        <div class="file-name">{{ fileName }}</div>
        <div class="file-size">{{ fileSize }}</div>
      </div>
    </div>
    <div class="progress-bar-container">
      <div class="progress-bar-full">
        <div class="progress-bar" data-cy="upload-progressbar" [ngStyle]="{ width: uploadProgress + '%' }"></div>
      </div>
      <div class="progress-percentage">{{ this.uploadProgress }}%</div>
    </div>
  </ng-container>
</div>
