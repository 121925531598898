import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ColorTag } from '@openreel/creator/common';
import { cloneDeep, noop } from 'lodash-es';
import { OpenreelColorPickerDialogComponent } from './openreel-color-picker-dialog.component';

@Component({
  selector: 'openreel-color-picker',
  templateUrl: './openreel-color-picker.component.html',
  styleUrls: ['./openreel-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelColorPickerComponent,
    },
  ],
})
export class OpenreelColorPickerComponent implements ControlValueAccessor {
  @Input() showPalette = false;
  @Input() showName = true;
  @Input() showColorString = true;
  @Input() set disabled(value) {
    this.setDisabledState(value);
  }

  @Output() colorPickerClosed = new EventEmitter<void>();
  @Output() valueChanged = new EventEmitter<ColorTag>();

  colorTag: ColorTag = null;
  isDisabled = false;

  propagateOnChanged: (colorTag: ColorTag) => void = noop;
  propagateOnTouched = noop;

  constructor(private readonly dialog: MatDialog) {}

  writeValue(colorTag: ColorTag) {
    this.colorTag = colorTag;
  }

  registerOnChange(fn: (colorTag: ColorTag) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  onColorClick() {
    if (this.isDisabled) {
      return;
    }

    const dialogRef = this.dialog.open(OpenreelColorPickerDialogComponent, {
      panelClass: 'white-bg',
      data: {
        color: this.colorTag.color,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.propagateOnTouched();
      this.colorPickerClosed.emit();

      if (!result) {
        return;
      }

      const color = result.toHexString();
      if (color === this.colorTag.color) {
        return;
      }

      this.colorTag = cloneDeep(this.colorTag);

      this.colorTag.color = color;

      this.propagateOnChanged(this.colorTag);
      this.valueChanged.emit(this.colorTag);
    });
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
