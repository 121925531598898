import * as fromActions from '../actions/timelines.actions';
import * as fromProjectActions from '@openreel/creator/app/store/actions/project.actions';
import * as fromGlobalActions from '@openreel/creator/app/store/actions/global.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProjectFacade } from '@openreel/creator/app/store/facades/project.facade';
import { AlertService } from '@openreel/ui/openreel-alert/openreel-alert.service';
import { Action } from '@ngrx/store';
import { ProjectUpdateSource } from '../interfaces/project.interface';
import {
  MoveItemsCommand,
  UpdateBoundsCommand,
  UpdateLayerFitCommand,
  UpdateLayerFitPositionCommand,
  AddClipsCommand,
  getTimelineById,
  AddVideoOverlaysCommand,
  ChangeItemTimelineCommand,
  RemoveClipCommand,
} from '@openreel/creator/common';

@Injectable()
export class CommonEffects {
  moveItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveItems),
      withLatestFrom(this.projectFacade.workflow$),
      concatMap(([{ event }, workflow]) => {
        const { updatedWorkflow } = new MoveItemsCommand(workflow).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
          }),
        ];
      })
    )
  );

  moveItemBetweenTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeItemTimeline),
      withLatestFrom(this.projectFacade.workflow$),
      concatMap(([{ event }, workflow]) => {
        const { success, errorMessage, updatedWorkflow } = new ChangeItemTimelineCommand(workflow).run(event);

        if (!success) {
          this.alertService.error(errorMessage);
          return [fromGlobalActions.noOp()];
        }

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
          }),
        ];
      })
    )
  );

  removeClip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeClip),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([action, workflow]) => {
        const { success, errorMessage, updatedWorkflow } = new RemoveClipCommand(workflow).run(action.event);

        if (!success) {
          this.alertService.error(errorMessage);
          return [fromGlobalActions.noOp()];
        }

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
          }),
        ];
      })
    )
  );

  updateBounds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateBounds),
      withLatestFrom(this.projectFacade.workflow$),
      concatMap(([{ event }, workflow]) => {
        const { success, errorMessage, updatedWorkflow } = new UpdateBoundsCommand(workflow).run(event);

        if (!success) {
          this.alertService.error(errorMessage);
          return [fromGlobalActions.noOp()];
        }

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
            updateSource: ProjectUpdateSource.Previewer,
          }),
        ];
      })
    )
  );

  updateLayerFit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateLayerFit),
      withLatestFrom(this.projectFacade.workflow$),
      concatMap(([{ event }, workflow]) => {
        const { updatedWorkflow } = new UpdateLayerFitCommand(workflow).run(event);

        const actions = [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
            updateSource: ProjectUpdateSource.Previewer,
          }),
        ] as Action[];

        return actions;
      })
    )
  );

  updateLayerFitPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateLayerFitPosition),
      withLatestFrom(this.projectFacade.workflow$),
      concatMap(([{ event }, workflow]) => {
        const { updatedWorkflow } = new UpdateLayerFitPositionCommand(workflow).run(event);

        const actions = [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
            updateSource: ProjectUpdateSource.Previewer,
          }),
        ] as Action[];

        return actions;
      })
    )
  );

  addClipsToTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addClipsToTimelines),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ timelineIds, clips }, workflow]) => {
        clips.forEach((clip, index) => {
          const timelineId = index <= timelineIds.length ? timelineIds[index] : timelineIds[0];

          const { timeline } = getTimelineById(timelineId, workflow.sections);
          if (timeline.type === 'main') {
            const { updatedWorkflow } = new AddClipsCommand(workflow).run({
              timelineId,
              clips: [clip],
              replace: false,
            });
            workflow = updatedWorkflow;
          } else {
            const { updatedWorkflow } = new AddVideoOverlaysCommand(workflow).run({
              timelineId,
              clips: [clip],
              replace: false,
            });
            workflow = updatedWorkflow;
          }
        });

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: workflow,
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly projectFacade: ProjectFacade,
    private readonly alertService: AlertService
  ) {}
}
