import { Timeline, WorkflowDataDto } from '../interfaces/index';
import { BaseCommand } from './base.command';

const FREEMIUM_ASSET_ID = 'freemium-watermark-black';
const FREEMIUM_LAYER_BOUNDS = { x: 85, y: 90, width: 10 };

export class AddFreemiumWatermarkCommand extends BaseCommand<void> {
  run() {
    this.addFreemiumWatermark(this.source);

    return this.ok();
  }

  private addFreemiumWatermark(workflow: WorkflowDataDto) {
    const freemiumAssets = workflow.assets.filter((a) => a.id === FREEMIUM_ASSET_ID && a.type === 'image');
    if (!freemiumAssets.length) {
      workflow.assets.push({
        id: FREEMIUM_ASSET_ID,
        file: { path: FREEMIUM_ASSET_ID, provider: 'or-assets' },
        type: 'image',
      });
    }

    const allSectionsHaveFreemiumTimeline = Object.values(workflow.sections).every((s) =>
      s.timelines.some((t: Timeline) => t.type === 'freemium')
    );
    if (!allSectionsHaveFreemiumTimeline) {
      Object.entries(workflow.sections).forEach(([, section]) => {
        if (section.timelines.some((t) => t.type === 'freemium')) {
          return;
        }

        section.timelines.push({
          id: this.getUniqueId(),
          type: 'freemium',
          layers: [
            {
              type: 'image',
              layerId: this.getUniqueId(),
              bounds: FREEMIUM_LAYER_BOUNDS,
              assetId: FREEMIUM_ASSET_ID,
              visibility: {
                startAt: 0,
              },
            },
          ],
        });
      });
    }
  }
}
