import { Component, OnInit } from '@angular/core';
import {
  GUEST_USER_ACCOUNT_INFO,
  GuestUserAccountInfo,
} from './guest-fill-account-info-form.util';
import { GUEST_ACCOUNT_ACTIVATED } from '../../route-utils';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { commonenv } from '@openreel/common/env/environment';
import { ToastrService } from 'ngx-toastr';
import { RegistrationRespDto } from '../../interfaces/interfaces';

@Component({
  selector: 'openreel-guest-fill-account-info-form',
  templateUrl: './guest-fill-account-info-form.component.html',
  styleUrls: ['./guest-fill-account-info-form.component.scss'],
})
export class GuestFillAccountInfoFormComponent implements OnInit {
  urls = {
    accountActivated: '/' + GUEST_ACCOUNT_ACTIVATED,
    registration: commonenv.nextGenApiUrl + 'registration',
  };

  user = {} as GuestUserAccountInfo;

  myFormGroup: FormGroup;
  submitted = false;

  guest = {} as GuestUserAccountInfo;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.guest = JSON.parse(localStorage.getItem(GUEST_USER_ACCOUNT_INFO));

    this.myFormGroup = this.formBuilder.group({
      name: [
        this.guest?.first_name ? this.guest?.first_name : '',
        Validators.required,
      ],
      lastName: [
        this.guest?.last_name ? this.guest?.last_name : '',
        Validators.required,
      ],
      companyName: ['', !this.guest?.account_id ? Validators.required : false],
    });
  }

  keyPressNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (
      event.key === '+' ||
      event.key === '-' ||
      event.key === ' ' ||
      event.key === '(' ||
      event.key === ')'
    ) {
      return true;
    }

    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  activateAccount() {
    this.submitted = true;

    if (this.myFormGroup.invalid) {
      return;
    }

    this.guest.first_name = this.myFormGroup.get('name').value;
    this.guest.last_name = this.myFormGroup.get('lastName').value;
    if (!this.guest.account_id) {
      this.guest.company_name = this.myFormGroup.get('companyName').value;
    }

    this.httpClient.post(this.urls.registration, this.guest).subscribe(
      (registrationInfo: RegistrationRespDto) => {
        if (registrationInfo.data.account_id) {
          this.guest.account_id = registrationInfo.data.account_id;
          localStorage.setItem(
            GUEST_USER_ACCOUNT_INFO,
            JSON.stringify(this.guest)
          );
        }
        this.router.navigate([this.urls.accountActivated]);
      },
      (error) => {
        this.toastr.error(
          error.message
            ? error.message
            : 'An error occurred. Please try again later.',
          'Error!'
        );
      }
    );
  }

  get f() {
    return this.myFormGroup.controls;
  }
}
