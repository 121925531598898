<form class="login-form" (submit)="loginClick()">
  <span class="login-title">Login</span>
  <p class="sub-title-gray">Sign into your existing account.</p>
  <p style="margin: 8px"></p>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="Enter Email Address"
      [(ngModel)]="user.username"
      name="username"
      [disabled]="disabled"
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input
      class="fs-exclude"
      matInput
      placeholder="Enter Password"
      [type]="hide ? 'password' : 'text'"
      [(ngModel)]="user.password"
      name="password"
      [disabled]="disabled"
    />
    <mat-icon style="cursor: pointer" matSuffix (click)="hide = !hide">{{
      hide ? 'visibility_off' : 'visibility'
    }}</mat-icon>
  </mat-form-field>

  <button
    class="mat-button-large"
    mat-flat-button
    color="primary"
    [disabled]="disabled"
  >
    Sign in
  </button>
</form>

<div class="footer-note">
  <p *ngIf="showCreateAccount">
    <span class="sub-title-gray"
      >No account? <a [routerLink]="magicLinkRoute">Create an Account</a></span
    >
  </p>
  <p>
    <span class="sub-title-gray"
      >I have a Session ID.
      <a routerLink="/subject/MODE_OTP">Enter Code Here</a></span
    >
  </p>
  <div class="form-field-label">
    <span class="sub-title-gray"
      >Forgot password?
      <a [routerLink]="forgotPasswordRoute">Click Here</a></span
    >
  </div>
</div>
