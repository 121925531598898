<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon
      color="primary"
      class="navigate-icon"
      [ngClass]="{ 'disable-icon': disablePrev }"
      (click)="showPrevious()"
      >navigate_before</mat-icon
    >
    <div
      *ngFor="let option of displayOptions; index as idx"
      class="text-slider-value"
      [class.no-click]="disableSelection"
      [class.selected-value]="option.value === selectedValue"
      (click)="changeSelection(option)"
    >
      {{ option.label ? option.label : option.value }}
    </div>
    <mat-icon
      color="primary"
      class="navigate-icon"
      [ngClass]="{ 'disable-icon': disableNext }"
      (click)="showNext()"
      >navigate_next</mat-icon
    >
  </div>
</div>
