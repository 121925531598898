import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GUEST_FILL_ACCOUNT_INFO } from '../../route-utils';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { commonenv } from '@openreel/common/env/environment';
import { MustMatch } from '../../form.util';
import {
  GUEST_USER_ACCOUNT_INFO,
  GuestUserAccountInfo,
} from '../guest-fill-account-info-form/guest-fill-account-info-form.util';
import { STRONG_PASSWORD_PATTERN } from '../../password.util';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'openreel-guest-create-password-form',
  templateUrl: './guest-create-password-form.component.html',
  styleUrls: ['./guest-create-password-form.component.scss'],
})
export class GuestCreatePasswordFormComponent implements OnInit {
  myFormGroup: FormGroup;
  submitted = false;
  matcher = new MyErrorStateMatcher();

  passwordHide = true;
  passwordConfirmHide = true;

  guest = {} as GuestUserAccountInfo;

  private urls = {
    validateMagicLink: commonenv.nextGenApiUrl + 'magic-link/:token',
    fillAccountInfo: '/' + GUEST_FILL_ACCOUNT_INFO,
  };

  constructor(private router: Router, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.guest = JSON.parse(localStorage.getItem(GUEST_USER_ACCOUNT_INFO));
    this.myFormGroup = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            Validators.pattern(STRONG_PASSWORD_PATTERN),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }

  get formControl() {
    return this.myFormGroup.controls;
  }

  onSubmit() {
    if (this.myFormGroup.invalid) {
      return;
    }

    this.guest.password = this.myFormGroup.get('password').value;
    localStorage.setItem(GUEST_USER_ACCOUNT_INFO, JSON.stringify(this.guest));
    this.router.navigate([this.urls.fillAccountInfo]);
  }
}
