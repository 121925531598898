<div class="login-form">
  <span class="login-title">Email Sent</span>
  <p class="sub-title-gray">
    We sent you an email, it contains a link that will sign you in.
  </p>
  <p style="margin: 8px"></p>

  <input
    id="username"
    style="
      height: 0;
      width: 0;
      border: none;
      background: none;
      margin: 0 !important;
      padding: 0 !important;
      margin-top: -4px;
    "
    type="email"
    name="fakeemailremembered"
  />

  <div class="mailchimp-guest-image"></div>
  <p style="margin: 8px"></p>
  <p class="sub-title-gray">
    If you do not receive the email within a few minutes, please check your spam
    folder.
  </p>
</div>

<div class="footer-note">
  <p>
    <span class="sub-title-gray"
      >Enter the wrong email?
      <a [routerLink]="magicLinkRoute">Try again</a></span
    >
  </p>
</div>
