import {
  CreateWorkflowProjectRequest,
  UpdateWorkflowProjectRequest,
  WorkflowProjectDto,
  WorkflowProjectVideoDto,
} from './workflow-project.interfaces';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkflowProjectService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/projects`,
    createFromInstantWizard: `${commonenv.nextGenApiUrl}workflows/projects/instant-wizard`,
    byId: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}`,
    byIdName: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/name`,
    byIdRender: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/render`,
    byIdCancelRender: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/render/cancel`,
    byIdTranscribe: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/transcribe`,
    byIdCancelTranscribe: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/transcribe/cancel`,
    signedUrl: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/signed-url`,
    duplicate: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/copy`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getProjects() {
    return this.httpClient.get<WorkflowProjectDto[]>(this.urls.root);
  }

  getProjectById(projectId: number) {
    return this.httpClient.get<WorkflowProjectDto>(this.urls.byId(projectId));
  }

  create(data: CreateWorkflowProjectRequest) {
    return this.httpClient.post<WorkflowProjectDto>(
      this.urls.createFromInstantWizard,
      data
    );
  }

  updateProjectById(data: UpdateWorkflowProjectRequest) {
    return this.httpClient.put<WorkflowProjectDto>(
      this.urls.byId(data.id),
      data
    );
  }

  updateProjectNameById(projectId: number, newName: string) {
    return this.httpClient.put<WorkflowProjectDto>(
      this.urls.byIdName(projectId),
      {
        name: newName,
      }
    );
  }

  renderProject(projectId: number) {
    return this.httpClient.post<WorkflowProjectVideoDto>(
      this.urls.byIdRender(projectId),
      {}
    );
  }

  cancelProjectRender(projectId: number) {
    return this.httpClient.post<void>(
      this.urls.byIdCancelRender(projectId),
      {}
    );
  }

  transcribe(projectId: number) {
    return this.httpClient.post<{done: boolean}>(
      this.urls.byIdTranscribe(projectId),
      {}
    );
  }

  cancelTranscribe(projectId: number) {
    return this.httpClient.post<void>(
      this.urls.byIdCancelTranscribe(projectId),
      {}
    );
  }

  public updateClipsListSubject = new Subject();

  getSignedUrl(projectId, forceDownload) {
    const params = new HttpParams().set('forceDownload', forceDownload);

    return this.httpClient.get<{ signedUrl: string }>(
      this.urls.signedUrl(projectId),
      {
        params,
      }
    );
  }

  getCaptureProjectWorkflows(projectId) {
    const params = new HttpParams()
      .set('projectId', projectId)
      .set('includeClipEdits', 'true');

    return this.filterWorkflows(params);
  }

  delete(id: number, confirm: boolean = false) {
    const params = new HttpParams().set('confirm', confirm.toString());

    return this.httpClient
      .delete(this.urls.byId(id), { params })
      .pipe(tap(() => this.updateClipsListSubject.next({})));
  }

  restore(id: number) {
    return this.httpClient.patch(this.urls.byId(id), { restore: true });
  }

  duplicate(id: number) {
    return this.httpClient
      .post(this.urls.duplicate(id), {})
      .pipe(tap(() => this.updateClipsListSubject.next({})));
  }

  getProjectArchivedWorkflows(projectId: number) {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('archived', 'true');

    return this.filterWorkflows(params);
  }

  private filterWorkflows(params: HttpParams) {
    return this.httpClient.get(this.urls.root, {
      params,
    });
  }
}
