import { ObjectDrawInfo, ObjectTiming, TimingInfo } from '../canvas.interfaces';
import { Inject, Injectable } from '@angular/core';
import { round } from 'lodash-es';
import { sortLayers } from '../helpers';
import { CanvasConfig } from '../canvas.config';
import { CANVAS_CONFIG_TOKEN } from '../components/timelines-canvas/timelines-canvas.component';
import { Subject } from 'rxjs';
import { Cleanupable } from '@openreel/common';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class DrawDataService extends Cleanupable {
  private _items: ObjectDrawInfo[] = [];
  private _itemsDict = new Map<string, ObjectDrawInfo>();
  private selectableItemsDict = new Map<string, ObjectDrawInfo>();

  private canvasConfig: CanvasConfig;

  constructor(
    @Inject(CANVAS_CONFIG_TOKEN)
    private readonly canvasConfig$: Subject<CanvasConfig>
  ) {
    super();

    this.canvasConfig$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config) => (this.canvasConfig = config));
  }

  get items() {
    return this._items;
  }

  exists = (name: string) => this._itemsDict.has(name);

  get = (name: string) => this._itemsDict.get(name);

  add(
    name: string,
    parentObjectInfo: ObjectDrawInfo,
    timing: ObjectTiming,
    options?: {
      selectable?: boolean;
      reorderable?: boolean;
      singleSelectionOnly?: boolean;
      gap?: number;
      linkedObjectName?: string;
      minDuration?: number;
      video?: {
        id: string;
        trimFrom: number;
        trimTo: number;
      };
    }
  ) {
    const newObject = new ObjectDrawInfo(name, parentObjectInfo);
    newObject.timing = new TimingInfo(timing.startAt, timing.endAt);

    if (newObject.parent) {
      const parentDrawInfo = this._itemsDict.get(newObject.parent.name);
      parentDrawInfo.children.push(newObject);
    } else {
      this._items.push(newObject);
    }

    this._itemsDict.set(newObject.name, newObject);
    newObject.gap = options?.gap || 0;
    newObject.singleSelectionOnly = options?.singleSelectionOnly || false;
    newObject.reorderable = options?.reorderable || false;
    newObject.linkedObjectName = options?.linkedObjectName;
    newObject.minDuration = options?.minDuration;
    newObject.video = options?.video;

    if (options?.selectable) {
      this.selectableItemsDict.set(newObject.name, newObject);
    }

    return newObject;
  }

  remove(name: string) {
    const objectToRemove = this.selectableItemsDict.get(name);
    const parent = objectToRemove.parent;

    if (!parent) {
      console.error(
        'Cant remove object without parent! That would mean we are trying to delete canvas or time cursor!'
      );
      return false;
    }

    parent.children = parent.children.filter((c) => c.name !== name);
    this._itemsDict.delete(name);
    this.selectableItemsDict.delete(name);

    return true;
  }

  clear() {
    this._itemsDict.clear();
    this._items = [];
  }

  count() {
    return this._itemsDict.size;
  }

  isInBounds(parent: ObjectDrawInfo, { startAt, endAt }: TimingInfo) {
    const leftBound = round(parent.timing.startAt);
    const rightBound = round(parent.timing.endAt);

    const newLeftRound = round(startAt);
    const newRightRound = round(endAt);

    return newLeftRound >= leftBound && newRightRound <= rightBound;
  }

  getConnectedObjects(item: ObjectDrawInfo) {
    const sortedItems = sortLayers(item.parent.children);

    const itemIndex = sortedItems.findIndex((i) => i.name === item.name);

    const connectedObjects = [item];
    let itemToCheck = item;
    for (let i = itemIndex - 1; i >= 0; i--) {
      const diffMs = itemToCheck.timing.startAt - sortedItems[i].timing.endAt;
      if (diffMs < this.canvasConfig.selection.nearThresholdMs) {
        itemToCheck = sortedItems[i];
        connectedObjects.push(itemToCheck);
      }
    }

    itemToCheck = item;
    for (let i = itemIndex + 1; i < sortedItems.length; i++) {
      const diffMs = sortedItems[i].timing.startAt - itemToCheck.timing.endAt;
      if (diffMs < this.canvasConfig.selection.nearThresholdMs) {
        itemToCheck = sortedItems[i];
        connectedObjects.push(itemToCheck);
      }
    }

    return sortLayers(connectedObjects);
  }

  getOverlappingObjects(
    parent: ObjectDrawInfo,
    { startAt, endAt }: TimingInfo,
    exceptObjects: ObjectDrawInfo[]
  ) {
    const items = parent.children;

    const overlapItems: ObjectDrawInfo[] = [];
    items.forEach((item) => {
      if (exceptObjects.some((o) => o.name === item.name)) {
        return;
      }

      if (item.timing.startAt <= endAt && item.timing.endAt >= startAt) {
        overlapItems.push(item);
      }
    });

    return sortLayers(overlapItems).map((item) => ({
      item,
      middlePointPassed:
        startAt < item.timing.centerPoint && endAt > item.timing.centerPoint,
    }));
  }
}
