import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { ProjectFacade } from '../../store/facades/project.facade';

@Injectable()
export class ProjectLoadedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly projectFacade: ProjectFacade
  ) {}

  async canActivate() {
    let isLoaded = false;
    this.projectFacade.isWorkflowLoaded$
      .pipe(first())
      .subscribe((value) => (isLoaded = value));

    return isLoaded ? true : this.router.parseUrl('/');
  }
}
