import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services';

@Injectable()
export class InvalidateTokenInterceptor<T> implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      map((resp) => {
        if (resp instanceof HttpResponse) {
          return resp.clone();
        }
      }),
      catchError((error) => {
        console.log(error);
        if (error.message === 'token_invalid') {
          console.log();
          this.authService.invalidateToken();
        }
        return throwError(error);
      })
    );
  }
}
