import { ToastrService } from 'ngx-toastr';
import { Component, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services';
import { commonenv } from '../../environments/environment';

@Component({
  selector: 'openreel-collaborator-login-form',
  templateUrl: './collaborator-login-form.component.html',
  styleUrls: ['./collaborator-login-form.component.scss'],
})
export class CollaboratorLoginComponent {
  hide = true;

  public url =
    'https://remote.openreel.com/session-login/abduvohid.karimjonov@gmail.com/897b52d1cc13561b01454c1ac6a82fe040baadd3';
  public password = '5#q.W+';
  @Output()
  loginEvent = new EventEmitter();

  constructor(public authService: AuthService, private toastr: ToastrService) {}


  // TODO: Move logic to service
  loginClick() {
    const urlRegex = new RegExp(
      commonenv.appUrl + '/session-login/(.*?)/([a-zA-Z0-9]*?)[/|$]'
    );
    const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    /**
     * Extract email and token out of the url
     */
    let email, token;
    const url = this.url + '/';
    const result = urlRegex.exec(url);
    if (result == null) {
      console.log('Not Valid URL');
    } else if (result.length && result.length === 3) {
      email = result[1];
      token = result[2];
    }

    /**
     * Make sure email matches the email regex
     */
    if (!emailRegex.test(email)) {
      console.log('Email is not valid');
      return;
    }

    console.log('Match is made', email, token);

    this.authService
      .collaborator_login(email, token, this.password)
      .subscribe((res) => {
        if (res.status === 1) {
          this.loginEvent.emit(res.user);
        } else {
          this.toastr.error('Invalid Credentials', 'Error!');
        }
      });
  }
}
