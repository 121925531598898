import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { isIPadPro, isMobile } from '../utils';
import { ROUTE_DOWNLOAD_MOBILE_APP } from '../route-utils';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate() {
    return isMobile() || isIPadPro()
      ? this.router.parseUrl('/' + ROUTE_DOWNLOAD_MOBILE_APP)
      : true;
  }
}
