import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PreviewRecordingPopupInput {
  url: string;
}

@Component({
  selector: 'openreel-preview-recording-popup',
  templateUrl: './preview-recording-popup.component.html',
  styleUrls: ['./preview-recording-popup.component.css'],
})
export class PreviewRecordingPopupComponent {
  url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PreviewRecordingPopupInput,
    private dialogRef: MatDialogRef<PreviewRecordingPopupComponent>
  ) {
    this.url = data.url;
  }

  closePopup() {
    this.dialogRef.close();
  }
}
