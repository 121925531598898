import { takeUntil, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Component, Input } from '@angular/core';
import { Cleanupable } from '../../classes/cleanupable';

@Component({
  selector: 'openreel-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent extends Cleanupable {
  @Input() for: 'recording' | 'teleprompter' = 'recording';
  messages = {
    recording: 'Recording starts in',
    teleprompter: 'Teleprompter starts in',
    smallTeleprompter: 'Teleprompter starts in',
  };

  // This prop lets us use this component as a dumb component
  //  It's kind of bad flow of control to have the display component also count down for us
  @Input() overrideCountdown: number;

  number: number;
  countdown$: Observable<number>;
  countdownSubscription: Subscription;

  async countDown(countdown$: Observable<number>) {
    this.countdown$ = countdown$;
    this.countdownSubscription = this.countdown$
      .pipe((takeUntil(this.ngUnsubscribe)))
      .subscribe((number) => {
        this.number = number ? number : null;
      });

    await this.countdown$.toPromise();
  }

  cancel() {
    this.number = null;
    this.countdownSubscription?.unsubscribe();
    this.countdownSubscription = null;
  }
}
