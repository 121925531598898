import * as fromAssetCache from './reducers/asset-cache.reducer';
import * as fromFont from './reducers/font.reducer';
import * as fromProject from './reducers/project.reducer';
import * as fromTemplate from './reducers/template.reducer';
import * as fromTimelines from './reducers/timelines.reducer';

import { AssetCache } from './interfaces/asset-cache.interface';
import { AssetCacheEffects } from './effects/asset-cache.effects';
import { Font } from './interfaces/font.interface';
import { FontEffects } from './effects/font.effects';
import { Project } from './interfaces/project.interface';
import { ProjectEffects } from './effects/project.effects';
import { RenderEffects } from './effects/render.effects';
import { Template } from './interfaces/template.interface';
import { CommonEffects } from './effects/common.effects';
import { TextOverlaysEffects } from './effects/text-overlays.effects';
import { TrimmerEffects } from './effects/trimmer.effects';
import { VideoOverlaysEffects } from './effects/video-overlays.effects';
import { BrandKitsEffects } from './effects/brand-kits.effects';
import { AssetsEffects } from './effects/assets.effects';
import { CaptionsEffects } from './effects/captions.effects';

export interface AppState {
  [fromTimelines.key]: fromTimelines.TimelinesState;
  project: Project;
  template: Template;
  assetCache: AssetCache;
  font: Font;
}

export const effects = [
  ProjectEffects,
  RenderEffects,
  AssetsEffects,
  FontEffects,
  AssetCacheEffects,
  FontEffects,
  TextOverlaysEffects,
  VideoOverlaysEffects,
  TrimmerEffects,
  CommonEffects,
  BrandKitsEffects,
  CaptionsEffects,
];

export const rootReducers = {
  [fromTimelines.key]: fromTimelines.reducerFn,
  project: fromProject.reducerFn,
  template: fromTemplate.reducerFn,
  assetCache: fromAssetCache.reducerFn,
  font: fromFont.reducerFn,
};
