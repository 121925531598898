import {
  CANVAS_NAME,
  ItemData,
  ObjectDrawInfo,
  SectionData,
  TimelineData,
  TIME_CURSOR_NAME,
  TimingInfo,
} from '../canvas.interfaces';
import { Layer, Section, Track, Trimmer } from '../models';
import { fabric } from 'fabric';
import { TimeCursor } from '../models/time-cursor.model';
import { Inject, Injectable } from '@angular/core';
import { CanvasConfig } from '../canvas.config';
import { CANVAS_CONFIG_TOKEN } from '../components/timelines-canvas/timelines-canvas.component';
import { Subject } from 'rxjs';
import { Cleanupable } from '@openreel/common';
import { takeUntil } from 'rxjs/operators';
import { getLayersTiming } from '../helpers';
import { TrimData } from './canvas.service';

@Injectable()
export class FabricService extends Cleanupable {
  private canvasConfig: CanvasConfig;

  constructor(
    @Inject(CANVAS_CONFIG_TOKEN)
    private readonly canvasConfig$: Subject<CanvasConfig>
  ) {
    super();

    this.canvasConfig$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config) => (this.canvasConfig = config));
  }

  sectionName = ({ type }: SectionData) => `section_${type}`;
  timelineName = ({ index }: TimelineData) => `timeline_${index}`;
  trimmerName = ({ index }: TimelineData) => `timeline_${index}_trimmer`;

  createMainObject(canvas: fabric.Canvas) {
    return new fabric.Rect({
      name: CANVAS_NAME,
      left: 0,
      top: this.canvasConfig.canvas.padding.y,
      height: canvas.height - 2 * this.canvasConfig.canvas.padding.y,
      backgroundColor: 'rgba(0,0,0,0)',
      fill: 'rgba(0,0,0,0)',
      hasControls: false,
      hasBorders: false,
      selectable: false,
      hoverCursor: 'default',
    });
  }

  createSectionObject(section: SectionData, canvas: fabric.Canvas) {
    return new Section({
      name: this.sectionName(section),
      data: {
        sectionType: section.type,
        config: this.canvasConfig,
      },
      left: 0,
      width: 0,
      top: this.canvasConfig.canvas.padding.y,
      height: canvas.height - 2 * this.canvasConfig.canvas.padding.y,
      hasControls: false,
      hasBorders: false,
      selectable: false,
      hoverCursor: 'default',
      strokeWidth: 0,
    });
  }

  createTimelineObject(timeline: TimelineData, top: number) {
    const timelineTypeOptions = this.canvasConfig.timelines[timeline.type];

    return new Track({
      name: this.timelineName(timeline),
      data: {
        emptyText: timelineTypeOptions.emptyText,
        childrenCount: timeline.items.length,
        config: this.canvasConfig,
      },
      left: 0,
      width: 0,
      top,
      height: timelineTypeOptions.height,
      hasControls: false,
      hasBorders: false,
      selectable: false,
      hoverCursor: 'default',
    });
  }

  createTrimmerObject(
    timeline: TimelineData,
    top: number,
    trimData?: TrimData
  ) {
    const timelineTypeOptions = this.canvasConfig.timelines[timeline.type];

    return new Trimmer({
      name: this.trimmerName(timeline),
      data: {
        timelineType: timeline.type,
        duration: timeline.duration,
        from: trimData?.from || 0,
        to: trimData?.to || timeline.duration,
        config: this.canvasConfig,
      },
      left: 0,
      width: 0,
      top,
      height: timelineTypeOptions.height,
      subTargetCheck: true,
      hasControls: false,
      hasBorders: false,
      selectable: false,
      hoverCursor: 'default',
    });
  }

  createItemObject(
    item: ItemData,
    timeline: TimelineData,
    top: number,
    allowSelection: boolean
  ) {
    const timelineTypeOptions = this.canvasConfig.timelines[timeline.type];

    return new Layer({
      name: item.layerId,
      data: {
        timelineType: timeline.type,
        selectable: allowSelection,
        stretchable: timelineTypeOptions.itemsStretchable,
        hasThumbnails: timelineTypeOptions.hasThumbnails,
        config: this.canvasConfig,
      },
      left: 0,
      width: 0,
      top,
      height: timelineTypeOptions.height,
      subTargetCheck: true,
      strokeWidth: 0,
      hasBorders: false,
      lockMovementX: false,
      lockMovementY: true,
    });
  }

  createTimeCursorObject(canvas: fabric.Canvas) {
    return new TimeCursor({
      name: TIME_CURSOR_NAME,
      left: 0,
      top: 0,
      height: canvas.height,
      data: {
        config: this.canvasConfig,
      },
    });
  }

  setObjectBoundsFromFabricBounds(item: ObjectDrawInfo, object: fabric.Object) {
    let { left, top } = object;
    const { width, height } = object;

    const selectedObject = object.canvas.getActiveObject();
    if (selectedObject && selectedObject instanceof fabric.ActiveSelection) {
      const selectedObjects = selectedObject.getObjects();
      if (selectedObjects.some((o) => o.name === object.name)) {
        const selectionCenterPoint = selectedObject.getCenterPoint();
        // Object is inside group, we have to use group-relative position
        left += selectionCenterPoint.x;
        top += selectionCenterPoint.y;
      }
    }

    item.bounds.set({ left, width, top, height });
  }

  setFabricBoundsFromObjectBounds(object: fabric.Object, item: ObjectDrawInfo) {
    const selectedObject = object.canvas.getActiveObject();
    if (selectedObject && selectedObject instanceof fabric.ActiveSelection) {
      const selectedObjects = selectedObject.getObjects();
      if (selectedObjects.some((o) => o.name === object.name)) {
        const selectionCenterPoint = selectedObject.getCenterPoint();
        // Object is inside group, we have to use group-relative position
        object.set({
          left: item.bounds.left - selectionCenterPoint.x,
          top: item.bounds.top - selectionCenterPoint.y,
          scaleX: 1,
        });
        return;
      }
    }

    // Object is not inside group, we can use absolute position
    object.set({
      left: item.bounds.left,
      width: item.bounds.width,
      scaleX: 1,
    });
    object.setCoords();
  }

  setFabricScaleFromObjectTiming(
    object: fabric.Object,
    item: ObjectDrawInfo,
    timingBefore: TimingInfo
  ) {
    object.set({
      left: item.bounds.left,
      scaleX: item.timing.duration / timingBefore.duration,
    });
    object.setCoords();
  }

  removeFabricScaleFromObject(object: fabric.Object) {
    object.set({
      width: object.getScaledWidth(),
      scaleX: 1,
    });
    object.setCoords();
  }

  animateFabricLeft(
    canvas: fabric.Canvas,
    object: fabric.Object,
    newLeft: number
  ) {
    const options = {
      duration: 100,
      onChange: () => canvas.requestRenderAll(),
      easing: fabric.util.ease.easeInOutCubic,
    };
    object.animate('left', newLeft, options);
  }

  swapObjectTimings(
    sourceItems: ObjectDrawInfo[],
    targetItems: ObjectDrawInfo[],
    currentSourceTiming?: TimingInfo
  ) {
    const sourceTiming = currentSourceTiming ?? getLayersTiming(sourceItems);
    const targetTiming = getLayersTiming(targetItems);

    const boundsToSwap =
      sourceTiming.startAt > targetTiming.startAt
        ? [targetTiming, sourceTiming]
        : [sourceTiming, targetTiming];

    const rightItemRightBound = boundsToSwap[1].endAt;
    boundsToSwap[1].startAt = boundsToSwap[0].startAt;
    boundsToSwap[0].startAt = rightItemRightBound - boundsToSwap[0].duration;

    const sourceDiffMs =
      sourceTiming.startAt - getLayersTiming(sourceItems).startAt;
    sourceItems.forEach((item) => item.timing.addDelta(sourceDiffMs));

    const targetDiffMs =
      targetTiming.startAt - getLayersTiming(targetItems).startAt;
    targetItems.forEach((item) => item.timing.addDelta(targetDiffMs));

    return [...sourceItems, ...targetItems];
  }
}
