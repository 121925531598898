import { fabric } from 'fabric';
import { CanvasConfig } from '../canvas.config';

export interface TimeCursorDataOptions {
  config: CanvasConfig;
}

export interface TimeCursorOptions extends fabric.ILineOptions {
  data: TimeCursorDataOptions;
}

export class TimeCursor extends fabric.Line {
  type = 'timeCursor';

  data: TimeCursorDataOptions;

  constructor(options: TimeCursorOptions) {
    super([0, 0, 0, options.height], options);

    this.stroke = this.data.config.timeCursor.color;
    this.strokeWidth = this.data.config.timeCursor.width;
    this.strokeLineCap = 'round';
    this.hasControls = false;
    this.hasBorders = false;
    this.lockMovementX = false;
    this.lockMovementY = true;
    this.hoverCursor = 'ew-resize';
  }
}
