<h2 mat-dialog-title>Save to Video Hub</h2>

<mat-dialog-content>
    <form>
        <div class="add-to-hub-container">
            <div *ngFor="let hub of hubList">
                <div class="hub-item">
                    <mat-checkbox color="primary" class="add-to-hub-check"
                        [checked]="hub.checked" 
                        (change)="onChange($event, hub)">
                        {{hub.hub.title}}
                    </mat-checkbox>
                    <mat-icon *ngIf="hub.hub.permission === 'private'" inline=true class="blue lock-icon">group_off</mat-icon>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="add2Hub()" [disabled]="bDisabled">Save</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>