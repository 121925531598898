import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { BaseConditionalStructuralDirective } from './base-conditional-structural.directive';

import { FeatureEnabledService } from '../services';

@Directive({
  selector: '[featureEnabled],[featureEnabledElse]',
})
export class FeatureEnabledDirective extends BaseConditionalStructuralDirective implements OnInit {
  private featureSource = new ReplaySubject<string>(1);
  @Input() set featureEnabled(feature: string) {
    this.featureSource.next(feature);
  }
  @Input('featureEnabledElse') elseTemplate?: TemplateRef<unknown>

  constructor(private featureEnabledService: FeatureEnabledService,
              templateRef: TemplateRef<unknown>, 
              viewContainer: ViewContainerRef) {
    super(templateRef, viewContainer)
  }

  ngOnInit() {
    const enabled$ = this.featureSource.pipe(
      switchMap((feature) => this.featureEnabledService.featureEnabled(feature))
    );
    this.mortalize(enabled$).subscribe((enabled) => this.renderView(enabled))
  }
}
