<div
  *ngFor="let bucket of buckets; index as i; last as isLast"
  class="bucket"
  [style.width.px]="bucket.width"
>
  <span *ngIf="showLabel(isLast, bucket.width)">
    {{ bucket.value | timeBarValue }}
  </span>
  <span class="last-value" *ngIf="isLast">
    {{ bucket.value + bucketRemainder | timeBarValue }}
  </span>
</div>
