import { Observable } from 'rxjs';

const MIME_TYPES = new Map<string, string>([
  ['png', 'image/png'],
  ['jpg', 'image/jpeg'],
  ['jpeg', 'image/jpeg'],
  ['mp4', 'video/mp4'],
  ['webm', 'video/webm'],
  ['mov', 'video/quicktime'],
  ['vtt', 'text/vtt'],
  ['srt', 'text/srt'],
  ['json', 'application/json'],
]);

export const getMimeTypeFromExtension = (fileExtension: string) => MIME_TYPES.get(fileExtension);

export const splitNameAndExtension = (fileName: string): [string, string] => {
  const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;
  const extension = FILE_EXTENSION_REGEX.exec(fileName)[1];
  const name = fileName.slice(0, -(extension.length + 1));
  return [name, extension];
};

export const RxTextFileReader = (file: File) =>
  new Observable<string>((obs) => {
    let fr = new FileReader();
    const loadedCb = (event: ProgressEvent<FileReader>) => {
      obs.next(event.target.result.toString());
      obs.complete();
    };

    const errorCb = () => {
      obs.error("Couldn't parse file");
    };

    fr.addEventListener('loadend', loadedCb);
    fr.addEventListener('error', errorCb);
    fr.readAsText(file);
    return () => {
      fr.removeEventListener('error', errorCb);
      fr.removeEventListener('loadend', loadedCb);
      fr = null;
    };
  });

export const RxDataURLFileReader = (file: File) =>
  new Observable<string>((obs) => {
    let fr = new FileReader();
    const loadedCb = (event: ProgressEvent<FileReader>) => {
      obs.next(event.target.result.toString());
      obs.complete();
    };

    const errorCb = () => {
      obs.error("Couldn't parse file");
    };

    fr.addEventListener('loadend', loadedCb);
    fr.addEventListener('error', errorCb);
    fr.readAsDataURL(file);
    return () => {
      fr.removeEventListener('error', errorCb);
      fr.removeEventListener('loadend', loadedCb);
      fr = null;
    };
  });

export const RxImage = (url: string) =>
  new Observable<HTMLImageElement>((obs) => {
    const img = new Image();
    const loadedCb = () => {
      obs.next(img);
      obs.complete();
    };

    const errorCb = () => {
      obs.error("Couldn't parse image");
    };

    img.addEventListener('load', loadedCb);
    img.addEventListener('error', errorCb);
    img.src = url;
    return () => {
      img.removeEventListener('error', errorCb);
      img.removeEventListener('load', loadedCb);
    };
  });
