import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { components, exportComponents } from './components';

@NgModule({
  declarations: [...components],
  imports: [SharedModule],
  exports: [...exportComponents],
  providers: [],
})
export class TimelinesCanvasModule {}
