import { getLayerFromId } from '../helpers/timelines.helpers';
import { LayersDataChangedEvent } from '../interfaces/element.interfaces';
import { BaseCommand } from './base.command';
import { cloneDeep } from 'lodash';

export interface UpdateLayersDataEvent {
  data: LayersDataChangedEvent;
}

export class UpdateLayersDataCommand extends BaseCommand<UpdateLayersDataEvent> {
  run({ data }: UpdateLayersDataEvent) {
    const newData = cloneDeep(data);
    for (const [layerId, changes] of Object.entries(newData)) {
      const { layer } = getLayerFromId(layerId, this.source);
      this.applyLayerChanges(layer, changes);
    }

    return this.ok();
  }
}
