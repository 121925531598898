import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AddTokenInterceptor,
  ApiUrlInterceptor,
  ILocalRecorderService,
  LocalRecorderService,
  RedirectForbiddenInterceptor,
  ResponseParserInterceptor,
  WorkflowsSocketService,
  WorkflowProjectSocketService,
  ISocketIoLibService,
  SocketIoProvider,
  CaseConverterInterceptor,
} from '@openreel/common';
import { guards } from './guards';
import { UPLOADER_SERVICE_TOKEN } from 'libs/ui/src/components/openreel-uploader/openreel-uploader.component';
import { UploaderService } from './services/uploader.service';
import { AssetsBaseService } from 'libs/common/src/services/assets/assets-base.service';
import { AssetsCacheFacade } from '../store/facades/asset-cache.facade';
import { TemplatingBaseService } from '../../../../../libs/ui/src/components/openreel-cue-player';
import { FileTemplatingService } from './services/file-templating.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    ...guards,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectForbiddenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseParserInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaseConverterInterceptor,
      multi: true,
    },
    {
      provide: ILocalRecorderService,
      useFactory: () => new LocalRecorderService(),
      deps: [],
    },
    WorkflowsSocketService,
    WorkflowProjectSocketService,
    {
      provide: ISocketIoLibService,
      useClass: SocketIoProvider,
    },

    {
      provide: UPLOADER_SERVICE_TOKEN,
      useExisting: UploaderService,
    },
    {
      provide: AssetsBaseService,
      useExisting: AssetsCacheFacade,
    },
    {
      provide: TemplatingBaseService,
      useExisting: FileTemplatingService,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Core module should only be imported in the AppModule.');
    }
  }
}
