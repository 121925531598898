import { WorkflowDataDto } from '@openreel/creator/common';

import { AppState } from '..';
import { createSelector } from '@ngrx/store';

export const selectProject = createSelector(
  (state: AppState) => state.project,
  (project) => project
);

export const selectProjectLoadFinished = createSelector(selectProject, (project) => project.loadFinished);

export const selectProjectUpdateSource = createSelector(selectProject, (project) => project.updateSource);

export const selectRenderProgress = createSelector(selectProject, (project) => project?.renderProgress);

export const selectIsRenderInProgress = createSelector(
  selectProject,
  (project) => project?.renderProgress?.state === 'rendering'
);

// Project data selectors
export const selectProjectId = createSelector(selectProject, (project) => project.data?.id);

export const selectProjectBrandKitHasNewerVersion = createSelector(
  selectProject,
  (project) => project.data?.brandKitHasNewerVersion ?? false
);

export const selectProjectBrandKitPermissions = createSelector(
  selectProject,
  (project) => project.data?.brandKitPermissions
);

export const selectProjectWorkflow = createSelector(selectProject, (project) => project.data?.workflow);

export const selectProjectTemplateSettings = createSelector(selectProject, (project) => project.data?.templateSettings);

export const selectIsBuilding = createSelector(selectProject, (project) => project.building);

export const selectProjectLayouts = createSelector(selectProject, (project) => project.data?.layouts);

export const selectProjectTemplateLayoutId = createSelector(selectProjectWorkflow, (workflow) =>
  workflow?.sections.main ? workflow?.sections.main.layout?.templateLayoutId : null
);

export const selectProjectWorkflowWithUpdateSource = createSelector(
  selectProjectWorkflow,
  selectProjectUpdateSource,
  (workflow, updateSource) => ({ workflow, updateSource })
);

export const selectProjectLayoutType = createSelector(
  selectProjectWorkflow,
  (workflow) => workflow?.sections['main'].layout?.layoutType ?? workflow?.globalSettings.layout?.defaultLayoutType
);

export const selectProjectSections = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.sections
);

export const selectProjectFeatures = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.features
);

export const selectProjectMainTimeline = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.timelines
);

export const selectProjectFonts = createSelector(selectProject, (project) => project.data?.workflow?.fonts || []);
