import { Injectable } from '@angular/core';
import { FileValidatorBaseService, FileValidationError } from '@openreel/ui/openreel-uploader/openreel-uploader.component';
import { from, Observable } from 'rxjs';
import { MediaMeta, MediaValidator } from '../../media/helpers/validate-media';
import { splitNameAndExtension } from '../../file.utils';
import * as Sentry from '@sentry/browser';

const ONE_GB = 1024 * 1024 * 1024;
const MAX_SIZE = ONE_GB * 5;
const VALID_EXTENSIONS = ['mov', 'webm', 'mp4'];

@Injectable({providedIn: 'root'})
export class UserUploadFileValidatorService extends FileValidatorBaseService {

    message = 'Only .mov, .webm, and .mp4 files allowed.';

    constructor() {
        super();
    }
    
    // null is valid, a string is invalid. string will be displayed to user.
    validate(file: File): FileValidationError | Observable<FileValidationError> {
        const [, ext] = splitNameAndExtension(file.name)
        if (!VALID_EXTENSIONS.includes(ext.toLowerCase())) {
            return this.message;
        }
        if (file.size > MAX_SIZE) {
            return 'Only files up to 5GB allowed';
        }

        // validate actual media
        return from(this.validateFile(file));
    }

    //
    // validate Webm file
    //
    validateFile(file: File):Promise<FileValidationError>{
        return (async () => {
            let videoData:MediaMeta;
            try{
                videoData = await MediaValidator.getMediaDataAsync(file);
            }
            catch(e){
                Sentry.captureException(e);
                return this.message;
            }
            if(videoData && videoData.video) 
                return null;
            else
                return this.message;   
        })();
    } 
}
