import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common';
import { CanvasConfig } from '../canvas.config';
import { CANVAS_CONFIG_TOKEN } from '../components/timelines-canvas/timelines-canvas.component';
import { BoundsInfo, Viewport } from '../canvas.interfaces';

@Injectable()
export class ScrollService extends Cleanupable {
  private canvasConfig: CanvasConfig;

  constructor(
    @Inject(CANVAS_CONFIG_TOKEN)
    private readonly canvasConfig$: Subject<CanvasConfig>
  ) {
    super();

    this.canvasConfig$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config) => (this.canvasConfig = config));
  }

  shouldScroll(
    bounds: BoundsInfo,
    moveDirection: number,
    viewport: Viewport
  ): { shouldScroll: boolean; pixelsToScroll?: number; msToScroll?: number } {
    const leftRatio = bounds.left / viewport.width;
    const rightRatio = bounds.right / viewport.width;
    const shouldScroll =
      (leftRatio < 0.05 && moveDirection < 0) ||
      (rightRatio > 0.95 && moveDirection > 0);

    if (!shouldScroll) {
      return { shouldScroll };
    }

    const viewportPxPerMs = viewport.width / viewport.duration;

    const pixelsToScroll =
      this.canvasConfig.scroll.amount * (moveDirection < 0 ? -1 : 1);
    const msToScroll = pixelsToScroll * viewportPxPerMs * 1000;
    return { shouldScroll, pixelsToScroll, msToScroll };
  }
}
