<div class="chat-wrapper" fxLayout="column" fxLayoutGap="15px">
  <div style="padding-bottom: 5px; border-bottom: 1px solid #000">
    <mat-tab-group
      #tabGroup
      mat-stretch-tabs
      (selectedTabChange)="onSelectedTabChange($event)"
      [selectedIndex]="selectedTabIndex"
      style="margin-bottom: 0"
    >
      <mat-tab
        *ngFor="let conv of activeConversationDetails; let i = index"
        class="single-active-conversation"
      >
        <ng-template mat-tab-label>
          <div>
            <span
              [matBadge]="conv.unreadCount"
              [matBadgeHidden]="conv.unreadCount == 0"
              matBadgeOverlap="false"
            >
              {{ conv.conversation.name }}
            </span>
            <button
              mat-icon-button
              style="margin-left: 20px"
              *ngIf="!conv.isGroupChat"
              (click)="deactivateConversation(conv)"
            >
              <mat-icon>highlight_off</mat-icon>
            </button>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="selectedConversation?.isGroupChat">
      <p *ngFor="let name of typingParticipantNames">Typing: {{ name }}</p>
    </ng-container>
    <div
      class="messages-holder slim-scroll"
      #messagesHolder
      (scroll)="paginateChat()"
    >
      <div class="spinner-wrapper">
        <img
          class="spinner"
          *ngIf="isLoading"
          src="/assets/common/images/webcam-loading.svg"
          alt="Loading Spinner"
          height="50px"
        />
      </div>

      <div
        *ngFor="let message of selectedConversation?.messages"
        [fxLayout]="myId === message.sender ? 'row-reverse' : 'row'"
        fxLayoutAlign="start end"
        class="single-message"
        [class.my]="myId === message.sender"
        [class.their]="myId !== message.sender"
      >
        <img
          src="assets/common/logos/all-white.svg"
          class="profile-image"
          alt="Profile Image"
          *ngIf="myId !== message.sender"
        />
        <div fxFlex="1 1">
          <div class="sender-name">
            {{ myId === message.sender ? 'You' : message.senderName }}
          </div>
          <div class="message-content-holder">
            <div class="message-text">
              {{ message.text }}
            </div>
            <div class="message-time">
              {{ message.createdAt | date: 'HH:mm' }}
            </div>
          </div>
        </div>
        <div class="message-row-spacer"></div>
      </div>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
    fxShow.xs
    fxHide.gt-xs
  >
    <button fxShow.xs fxHide.gt-xs mat-button (click)="markConversationRead()">
      Mark all read
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div>To:</div>
    <mat-form-field class="telepromter-form-field" appearance="outline">
      <mat-select
        [value]="selectedConversation?.id"
        (selectionChange)="selectedConversationChange($event.value)"
      >
        <mat-option *ngFor="let conv of allChatOptions" [value]="conv.id">
          {{ conv.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      fxHide.xs
      fxShow.gt-xs
      fxFlex="1 1"
      mat-button
      (click)="markConversationRead()"
    >
      Mark all read
    </button>
  </div>
  <form fxLayout="row" [formGroup]="form" class="send-form" autocomplete="off">
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
    <textarea
      (keydown.enter)="sendMessage()"
      class="message-compose-input"
      matInput
      name="message"
      formControlName="message"
      placeholder="Type message"
      (change)="textChange()"
      rows="1"
      autoResize
    ></textarea>
  </form>
</div>
