<ng-container *ngFor="let player of data; let i = index">
  <openreel-cue-player-controller
    [hide]="i > 0"
    [data]="player"
    (onLoaded)="onChildPlayerLoaded($event)"
    (onTimeUpdate)="onChildPlayerTimeUpdate($event)"
    (onEnded)="onChildPlayerEnded($event)"
    (onSeeked)="onChildPlayerSeeked($event)"
    (onError)="onChildPlayerError($event)"
    (onTransitionStart)="onChildPlayerTransitionStart($event)"
    (onTransitionEnd)="onChildPlayerTransitionEnd($event)"
    [style.z-index]="data.length - i"
  >
  </openreel-cue-player-controller>
</ng-container>
