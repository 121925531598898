import { Injectable } from '@angular/core';

@Injectable()
export abstract class FlatChatService {
  abstract handleSelectConversation(conversationId: number): void;
  abstract sendMessage(message: string, convserationId: number): void;
  abstract markConversationAsRead(conversationId: number): void;
  abstract handleTextChange(): void;
  abstract handleSelectDropownparticipant(
    participantId: number,
    name: string
  ): void;
  abstract handleRemoveActiveConversation(conversationId: number): void;
  abstract paginateChat(): void;
}
