import { AdmitUsersOptions } from './settings.interface';

export interface RequestToJoinAck {
  id: number;
  SessionID: number;
  status: AdmissionJoinStatus;
  admit_selected_option: AdmitUsersOptions;
  is_director_online: boolean;
  is_web_user_online: boolean;
}

export enum AdmissionState {
  Approved = 'approved',
  Pending = 'pending',
  Denied = 'denied',
}

export enum AdmissionJoinStatus {
  Pending = 0,
  Declined = -1,
  Approved = 1,
}
