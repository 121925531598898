<div #wrapper>
  <div *ngIf="video$ | async as video" class="wrapper">
    <openreel-player
      #videoPlayer
      [video$]="video$"
      class="preview"
      [options]="{ controls: false, fluid: true }"
      (state)="playStateUpdate($event)"
    ></openreel-player>

    <div class="m-top-8 wrapper">
      <openreel-video-controls
        *ngIf="videoPlayer"
        [currentTime]="(videoPlayer.currentTime$ | async) * 1000"
        [duration]="video.duration * 1000"
        [playing]="isPlaying$ | async"
        [muted]="videoPlayer.player?.muted()"
        (onPlay)="videoPlayer.play()"
        (onMute)="videoPlayer.mute()"
        (onUnmute)="videoPlayer.unmute()"
        (onPause)="videoPlayer.pause()"
        (onSeek)="onSeek($event)"
        (onFullscreen)="fullscreen()"
        (onExitFullscreen)="exitFullscreen()"
      ></openreel-video-controls>
    </div>
  </div>
</div>
