import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class CtaEditorService {
    private toggleEditModeSource = new BehaviorSubject<boolean>(false);
    toggleEditMode$ = this.toggleEditModeSource.asObservable();
    toggleEditMode(on: boolean) {
        this.toggleEditModeSource.next(on);
    }

    private ctaEditorUpdateSource = new Subject();
    ctaEditorUpdate$ = this.ctaEditorUpdateSource.asObservable();
    ctaEditorUpdate(value) {
        this.ctaEditorUpdateSource.next(value);
    }

    setCtaEditorSource = new Subject();
    setCtaEditor$ = this.setCtaEditorSource.asObservable();
    setCtaEditor(value) {
        this.setCtaEditorSource.next(value);
    }

    addCtaSource = new Subject();
    addCta$ = this.addCtaSource.asObservable();
    addCta(value) {
        this.addCtaSource.next(value);
    }

    resetCtaSource = new Subject();
    resetCta$ = this.resetCtaSource.asObservable();
    resetCta() {
        this.resetCtaSource.next();
    }
}