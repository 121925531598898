/* eslint-disable @typescript-eslint/no-explicit-any */
import { PresetField } from '@openreel/creator/common';
import { TransformerBase } from './transformer.base';

export class TransformerShape extends TransformerBase {
  test = (fieldDef: PresetField) => fieldDef.type === 'shape';

  process = (animation: any, fieldDef: PresetField, fieldValues: any) => {
    if (!fieldDef.lottiePath) {
      return animation;
    }

    const rootNode = this.getRootNode(animation, fieldDef.lottiePath);
    rootNode.c = {
      ...rootNode.c,
      k: fieldValues.color,
    };

    return animation;
  };
}
