import { FontExtension, FontAsset, GoogleFontAsset, CustomFontAsset } from "../interfaces";

export function fontExtensionToFormatName(ext: FontExtension) {
  switch (ext) {
    case 'ttf':
      return 'truetype';
    case 'otf':
      return 'opentype';
    case 'woff':
    case 'woff2':
    default:
      return ext;
  }
}

export function fontWeightToValue(weight: string) {
  switch (weight) {
    case 'regular':
      return '400';
    case 'bold':
      return '700';
    default:
      return weight;
  }
}

export function getConcreteFontAsset(asset: FontAsset): [GoogleFontAsset, CustomFontAsset] {
  if (asset.custom) {
    return [null, asset as CustomFontAsset];
  }

  return [asset as GoogleFontAsset, null];
}

export function getFontAssetWeight(asset: FontAsset) {
  const [googleFont, customFont] = getConcreteFontAsset(asset);

  return googleFont ? googleFont.weight ?? googleFont.weights[0] : customFont.weights[0];
}

export function getFontAssetWeights(asset: FontAsset) {
  const [googleFont, customFont] = getConcreteFontAsset(asset);

  if (customFont) {
    return customFont.weights;
  }

  return googleFont.weight ? [googleFont.weight] : googleFont.weights;
}
