<h1 mat-dialog-title style="text-align: center;">
    <span style="margin-right: 80px;">Enter one-time code</span>
    <button class="close" mat-button (click)="onDialogCloseClicked()">
        <mat-icon color="primary">navigate_before</mat-icon> Login
    </button>
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="30px" class="dialog-content">
    <form class="otp-form" [formGroup]="form">
        <mat-form-field appearance="outline" style="width: 100%">
            <input matInput placeholder="One-time code will expire in 5 mins" formControlName="otp" />
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <span *ngIf="!countDownCompleted" class="countdown-sentence">Re-send one-time code in {{ countDown$ | async
        }}
        <span>secs</span>
    </span>
    <div fxFill fxLayout="row-reverse">
        <button (click)="resendOtp()" mat-flat-button color="primary" class="resend-btn"
            [disabled]="disableResendBtn()">Re-send one-time code</button>

        <button (click)="sendOtp()" style="margin-left: 0px" mat-flat-button color="primary" class="send-btn"
            [disabled]="!form || form.disabled || disabled">Send</button>
    </div>
</div>