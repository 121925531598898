import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { TelepromterApiService } from '@openreel/common';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as fromActions from '../actions/self-record-teleprompter.actions';
import { ProjectFacade } from '../../../../store/facades/project.facade';
import { from } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SelfRecordTeleprompterDialogComponent } from '../../components/self-record-teleprompter/self-record-teleprompter-dialog.component';

@Injectable()
export class SelfRecordTeleprompterEffects {
  refreshScripts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refreshScripts),
      withLatestFrom(this.projectFacade.captureProjectId$),
      mergeMap(([, captureProjectId]) =>
        from(this.teleprompterApiService.getScriptsInProject(captureProjectId))
      ),
      mergeMap((scripts) => [fromActions.refreshScriptsSuccess({ scripts })])
    )
  );

  openTeleprompterDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openTeleprompterDialog),
        map(() => {
          if (this.dialog.openDialogs?.length) {
            this.dialog.closeAll();
          } else {
            this.dialog.open(SelfRecordTeleprompterDialogComponent, {
              hasBackdrop: false,
              position: { top: '10px' },
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly teleprompterApiService: TelepromterApiService,
    private readonly projectFacade: ProjectFacade,
    private readonly actions$: Actions,
    private readonly dialog: MatDialog
  ) {}
}
