import { Component } from '@angular/core';
import {
  GUEST_CREATE_PASSWORD,
  GUEST_MAGIC_LINK_SENT,
  ROUTE_DIRECTOR_LOGIN,
} from '../../route-utils';
import { Router } from '@angular/router';
import { commonenv } from '@openreel/common/env/environment';
import { HttpClient } from '@angular/common/http';

import { FormControl } from '@angular/forms';
import { setCustomErrorStatus } from '../../form.util';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import {
  GUEST_USER_ACCOUNT_INFO,
  GuestUserAccountInfo,
} from '../guest-fill-account-info-form/guest-fill-account-info-form.util';
import { take } from 'rxjs/operators';

@Component({
  selector: 'openreel-guest-magiclink-form',
  templateUrl: './guest-magiclink-form.component.html',
  styleUrls: ['./guest-magiclink-form.component.scss'],
})
export class GuestMagiclinkFormComponent {
  emailFC = new FormControl();
  public email: string;
  private idToken: string;
  guest = {} as GuestUserAccountInfo;

  hide = true;
  disabled = false;
  loginRoute = '/' + ROUTE_DIRECTOR_LOGIN;
  magicLinkSentRoute = '/' + GUEST_MAGIC_LINK_SENT;
  createPasswordRoute = '/' + GUEST_CREATE_PASSWORD;

  private urls = {
    requestMagicLink: commonenv.nextGenApiUrl + 'magic-link',
  };

  private businessErrors = {
    emailAlreadyExists: 'Email already exist in an account',
    nonBusinessDomain: 'Email cannot be a free email domain',
    emailMustBe: 'email must be',
  };

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private authService: SocialAuthService
  ) {}

  magicLink() {
    const payload = { email: this.email, idToken: this.idToken };

    this.httpClient.post(this.urls.requestMagicLink, payload).subscribe(
      (resp: { err: boolean; jwt: string }) => {
        if (resp.err) {
          this.toastr.error(
            'An error occurred. Please try again later.',
            'Error!'
          );
        }

        if (this.idToken) {
          this.guest.token = resp.jwt;
          localStorage.setItem(
            GUEST_USER_ACCOUNT_INFO,
            JSON.stringify(this.guest)
          );
          this.router.navigate([this.createPasswordRoute]);
        } else {
          this.router.navigate([this.magicLinkSentRoute]);
        }
      },
      (error) => {
        if (
          error.message === this.businessErrors.emailAlreadyExists ||
          error.message === this.businessErrors.nonBusinessDomain
        ) {
          setCustomErrorStatus(this.emailFC, true, error.message);
        } else if (
          error.message.indexOf(this.businessErrors.emailMustBe) >= 0
        ) {
          const msg =
            error.message.charAt(0).toUpperCase() + error.message.slice(1);
          const errMsg = msg.split(',').join(', ');
          setCustomErrorStatus(this.emailFC, true, errMsg);
        } else {
          this.toastr.error(
            error.message
              ? error.message
              : 'An error occurred. Please try again later.',
            'Error!'
          );
        }
      }
    );
  }

  signUpWithGoogle() {
    this.authService.initState.pipe(take(1)).subscribe(() => {
      this.authService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((data) => {
          this.email = data.email;
          this.idToken = data.idToken;
          this.guest.first_name = data.firstName;
          this.guest.last_name = data.lastName;
          this.magicLink();
        })
        .catch((err) => {
          // Do Something
        });
    });
  }
}
