<div
  [ngSwitch]="recordingType"
  class="preview"
>
  <ng-container *ngSwitchCase="'camera'">
    <openreel-single-video
      [mirrored]="mirrored"
      [stream]="webcamStream"
      [autoplay]="autoplay"
      (onPlaying)="onChildPlaying('webcam')"
    >
    </openreel-single-video>
  </ng-container>

  <ng-container *ngSwitchCase="'screen'">
    <openreel-single-video
      [stream]="screenStream"
      [autoplay]="autoplay"
      (onPlaying)="onChildPlaying('screen')"
    >
    </openreel-single-video>
  </ng-container>

  <ng-container *ngSwitchCase="'both-pip'">
    <openreel-single-video
      [stream]="screenStream"
      [autoplay]="autoplay"
      (onPlaying)="onChildPlaying('screen')"
    >
    </openreel-single-video>
    <div class="pip">
      <openreel-single-video
        [mirrored]="true"
        [stream]="webcamStream"
        [autoplay]="autoplay"
        (onPlaying)="onChildPlaying('webcam')"
      >
      </openreel-single-video>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'both-split'">
    <openreel-single-video
      [stream]="screenStream"
      [autoplay]="autoplay"
      (onPlaying)="onChildPlaying('screen')"
    >
    </openreel-single-video>
    <div class="split">
      <openreel-single-video
        mirrored="true"
        [stream]="webcamStream"
        [autoplay]="autoplay"
        (onPlaying)="onChildPlaying('webcam')"
      >
      </openreel-single-video>
    </div>
  </ng-container>
</div>
