import { Component } from '@angular/core';

@Component({
  selector: 'openreel-guest-wizard-container',
  templateUrl: './guest-wizard-container.component.html',
  styleUrls: ['./guest-wizard-container.component.scss']
})
export class GuestWizardContainerComponent {

}
