import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Cleanupable } from '@openreel/common';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkflowDataDto, TrimmerData } from '@openreel/creator/common';
import { generateTrimmerWorkflow } from '../../helpers/generateTrimmerWorkflow';

@Component({
  selector: 'openreel-wf-trimmer-preview',
  templateUrl: './trimmer-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrimmerPreviewComponent extends Cleanupable implements OnChanges {
  @Input() data: TrimmerData;
  @Input() loadedProjectWorkflow: WorkflowDataDto;

  @Output() loaded = new EventEmitter<void>();

  private previewWorkflow = new BehaviorSubject<Partial<WorkflowDataDto>>(null);
  previewWorkflow$ = this.previewWorkflow.asObservable();

  previewTimelines$ = this.previewWorkflow$.pipe(map((workflow) => workflow?.timelines));

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.update();
    }
  }

  onPreviewLoaded = () => this.loaded.emit();

  update() {
    const workflow = generateTrimmerWorkflow(this.data, true);

    this.previewWorkflow.next(workflow);
  }
}
