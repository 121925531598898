import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[phoneNumber]'
})
export class PhoneNumberDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    const startsPlus1 = initalValue.startsWith('+1');
    let cleared = '';
    if(startsPlus1){
      cleared = initalValue.replace(/[^0-9]*/g, '');
    } else {
      this._el.nativeElement.value = initalValue.replace(/[^0-9|\s|+|\-|\(|\)]*/g, '');
      return;
    }

    // +1-400-500-2000
    cleared = '+' + cleared;
    if(cleared.length > 2) {
      cleared = cleared.slice(0, 2) + '-' + cleared.slice(2);
    }

    if(cleared.length > 6) {
      cleared = cleared.slice(0, 6) + '-' + cleared.slice(6);
    }

    if(cleared.length > 10) {
      cleared = cleared.slice(0, 10) + '-' + cleared.slice(10);
    }

    if(cleared.length > 15) {
      cleared = cleared.slice(0, 15);

    }
    this._el.nativeElement.value = cleared;
  }

}
