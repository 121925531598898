import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Ng5SliderModule } from 'ng5-slider';
import { PlayerComponent } from './player/player.component';
import { TrimmerComponent } from './trimmer/trimmer.component';
import { VideoEditorComponent } from './video-editor.component';

@NgModule({
  imports: [CommonModule, Ng5SliderModule],
  exports: [VideoEditorComponent, PlayerComponent],
  declarations: [VideoEditorComponent, TrimmerComponent, PlayerComponent],
  providers: [],
})
export class VideoEditorModule {}
