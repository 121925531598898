import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { commonenv } from '../../environments/environment';
import { ILoginRespDto } from '../../interfaces/interfaces';
import { SeatsInfo, SeatsResponse, TeamJoiningRules, UpdateDefaultProductAccess, UpdateSeats } from './seats.interface';

@Injectable({
  providedIn: 'root',
})
export class SeatsService {
  public urls = {
    seatsInfo: `${commonenv.nextGenApiUrl}seats/info`,
    seats: `${commonenv.nextGenApiUrl}seats`,
    updateTeamRule: commonenv.nextGenApiUrl + 'company/joining-rules',
    defaultProductAccess: commonenv.nextGenApiUrl + 'company/default-product-access',
    resendSeatInvitation: commonenv.nextGenApiUrl + 'company/re-invite-directors',
    verifyInvitation: (token: string) => commonenv.nextGenApiUrl + `company/verify-invitation/${token}`,
    acceptInvitation: (token: string) => commonenv.nextGenApiUrl + `company/accept-invitation/${token}`,
  };

  seatsUpdatedSubject = new BehaviorSubject(null);
  seatInfo$ = new BehaviorSubject(null);

  constructor(public httpClient: HttpClient) {}

  getSeatsInfo() {
    return this.httpClient.get<SeatsInfo>(this.urls.seatsInfo).pipe(
      tap((info) => {
        this.seatInfo$.next(info);
      })
    );
  }

  getSeats(limit: number, page: number, query: string) {
    return this.httpClient.get<SeatsResponse>(this.urls.seats, {
      params: {
        limit,
        page,
        query: encodeURIComponent(query),
      },
    });
  }

  updateSeats(userId: number, data: UpdateSeats) {
    return this.httpClient.put(this.urls.seats + '/' + userId, data);
  }

  updateTeamJoiningRule(selectedRule: TeamJoiningRules) {
    return this.httpClient.patch(this.urls.updateTeamRule, {
      rule: selectedRule,
    });
  }

  updateDefaultProductAccess(data: UpdateDefaultProductAccess) {
    return this.httpClient.patch(this.urls.defaultProductAccess, data);
  }

  resendSeatsInvitation(userId: number) {
    return this.httpClient.patch(this.urls.resendSeatInvitation + '/' + userId, {});
  }

  verifyInvitation(token: string) {
    return this.httpClient.get<{
      data: { generate_password: boolean; email: string; account_id: number };
    }>(this.urls.verifyInvitation(token));
  }

  acceptInvitation(token: string, password?: string) {
    return this.httpClient.patch<ILoginRespDto>(this.urls.acceptInvitation(token), {
      password: password,
    });
  }
}
