/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreatorNode, ProcessorBase } from './processor.base';

export class ProcessorText extends ProcessorBase {
  type = 'text' as const;

  test = (node: any) => node.ty == 5;

  process = (node: any) => {
    const newNode: CreatorNode = {
      token: node.nm,
      path: node.path,
      type: 'text',
    };
    return newNode;
  };
}
