<div class="permission-popup">
  <div class="content">
    <svg
      class="arrow-icon"
      width="113px"
      height="151px"
      viewBox="0 0 113 151"
      version="1.1"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g transform="translate(-460.000000, -332.000000)" stroke="#358DFF" stroke-width="3">
          <g transform="translate(462.256978, 334.370579)">
            <path d="M-8.52651283e-14,146.493804 C54.3616007,147.723905 157.847054,82.7860099 80.8999057,0.169995009" id="Path-9"></path>
            <polyline id="Path-10" points="80.0474803 15.2426515 80.8999057 0.169995009 93.6804574 -4.48399567e-14"></polyline>
          </g>
        </g>
      </g>
    </svg>
    <p>
      Click here to open the browser's permissions settings.
    </p>
  </div>
</div>
