import * as fromProjectActions from '../actions/project.actions';

import { createReducer, on } from '@ngrx/store';

import { Template } from '../interfaces/template.interface';

export const initialState: Partial<Template> = {
  data: null,
};

const reducer = createReducer(
  initialState,
  on(fromProjectActions.loadProjectSuccess, (_, action) => ({
    data: action.data.template,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
