/* eslint-disable no-bitwise */
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Color, ContrastRatio, WHITE } from './color.model';

interface RGB {
    r: number,
    g: number,
    b: number,
}

const DEFAULT_RGB = {r:0,g:0,b:0};

@Injectable({providedIn: 'root'})
export class ImageColorService {
    private renderer: Renderer2
    constructor(
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    private getImageData(imgEl: HTMLImageElement) {
        let canvas: HTMLCanvasElement = this.renderer.createElement('canvas');
        const context = canvas.getContext && canvas.getContext('2d');
        
        if (!context) {
            canvas = null;
            return null;
        }
        
        const height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        const width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
        context.drawImage(imgEl, 0, 0);
        
        try {
            const data = context.getImageData(0, 0, width, height);
            canvas = null;
            return data;
        } catch(e) {
            console.error(e);
            canvas = null;
            return null;
        }
    }

    private countColors(imgEl: HTMLImageElement) {
        const imgData = this.getImageData(imgEl);
        if (!imgData) {
            return null; // for non-supporting envs;
        }
        
        const data = imgData.data;
        const length = data.length;
        let i = -4;
        const counts: {[key:string]: number} = {};
        const blockSize = 5; // only visit every 5 pixels
        while ( (i += blockSize * 4) < length ) {
            if (data[i+3]) { // skip transparent pixels
                const key = `${data[i]},${data[i+1]},${data[i+2]}`;
                counts[key] = (counts[key] || 0) + 1;
            }
        }
        return counts;
    }

    private loadImage(url: string) {
        const preloaderImg: HTMLImageElement = this.renderer.createElement('img');
        preloaderImg.setAttribute('crossOrigin', '');
        preloaderImg.src = url;
        return fromEvent(preloaderImg, 'load').pipe(
            map(() => preloaderImg),
        );
    }

    private _getImageContrast(imgEl: HTMLImageElement, contrastWith: Color, using: 'mode' | 'average') {
        const {r,g,b} = using === 'average' ? this._getAverageRGB(imgEl) : this._getModeRGB(imgEl);
        const color = new Color([r,g,b]);
        return color.contrast(contrastWith);
    }

    
    private _getAverageRGB(imgEl: HTMLImageElement) {
        const imgData = this.getImageData(imgEl);
        if (!imgData) {
            return {...DEFAULT_RGB}; // for non-supporting envs;
        }
        
        const data = imgData.data;
        const length = data.length;
        let i = -4;
        let count = 0;
        const blockSize = 5; // only visit every 5 pixels
        const rgb = {r:0,g:0,b:0};
        while ( (i += blockSize * 4) < length ) {
            if (data[i+3]) { // skip transparent pixels
                ++count;
                rgb.r += data[i];
                rgb.g += data[i+1];
                rgb.b += data[i+2];
            }
        }
        
        // ~~ used to floor values
        rgb.r = ~~(rgb.r/count);
        rgb.g = ~~(rgb.g/count);
        rgb.b = ~~(rgb.b/count);
        return rgb;
    }

    private _getModeRGB(imgEl: HTMLImageElement) {
        const counts = this.countColors(imgEl);
        if (!counts) {
            return {...DEFAULT_RGB}; // for non-supporting envs;
        }

        let curMax = 0;
        let curMaxKey: string;
        for (const k in counts) {
            if (counts[k] >= curMax) {
                curMax = counts[k];
                curMaxKey = k;
            }
        }
        
        if (!curMaxKey) {
            return {...DEFAULT_RGB}; // if transparent
        }

        const [r,g,b] = curMaxKey.split(',').map(a => +a);
        return {r,g,b};
    }

    private _getAllRGB(imgEl: HTMLImageElement, filter: boolean, minContrast: number) {
        const counts = this.countColors(imgEl);
        if (!counts) {
            return [{...DEFAULT_RGB}]; // for non-supporting envs;
        }

        let total = 0;
        let sorted: [Color, number][] = Object.entries(counts)
                    .sort(([,c1],[,c2]) => c2 - c1)
                    .map(([colKey, count]) => {
                        total += count;
                        const rgb = colKey.split(',').map(a => +a);
                        return [new Color(rgb), count];
                    });
        if (filter) {
            for (let i = 0; i < sorted.length; i++) {
                const topColor = sorted[i][0];
                let j = i + 1;
                while (j < sorted.length) {
                    const compColor = sorted[j][0];
                    const constrast = topColor.contrast(compColor);
                    if (constrast.ratio < minContrast) {
                        sorted[i][1] += sorted[j][1];
                        sorted.splice(j, 1);
                    } else {
                        j++;
                    }
                }
            }
            sorted = sorted.filter(([,count]) => (count / total) > 0.02).sort(([,c1],[,c2]) => c2 - c1);
        }
        return sorted.map(([{rgb:[r,g,b]}]) => ({r,g,b}));
    }

    getImageContrast(img: string, contrastWith?: Color | RGB, using?: 'mode' | 'average'): Observable<ContrastRatio>
    getImageContrast(img: HTMLImageElement, contrastWith?: Color | RGB, using?: 'mode' | 'average'): ContrastRatio
    getImageContrast(img: string | HTMLImageElement, contrastWith: Color | RGB = WHITE, using: 'mode' | 'average' = 'average') {
        const colorContrastWith = contrastWith instanceof Color ?
            contrastWith : 
            new Color([contrastWith.r, contrastWith.g, contrastWith.b]);
        if (typeof img === 'string') {
            return this.loadImage(img).pipe(
                map((imgEl) => this._getImageContrast(imgEl, colorContrastWith, using)),
            );
        } else {
            return this._getImageContrast(img, colorContrastWith, using);
        }
    }

    getAverageRGB(img: string): Observable<RGB>
    getAverageRGB(img: HTMLImageElement): RGB
    getAverageRGB(img: HTMLImageElement | string) {
        if (typeof img === 'string') {
            return this.loadImage(img).pipe(
                map(imgEl => this._getAverageRGB(imgEl)),
            );
        } else {
            return this._getAverageRGB(img);
        }
    }

    getModeRGB(img: string): Observable<RGB>
    getModeRGB(img: HTMLImageElement): RGB
    getModeRGB(img: HTMLImageElement | string) {
        if (typeof img === 'string') {
            return this.loadImage(img).pipe(
                map(imgEl => this._getModeRGB(imgEl)),
            );
        } else {
            return this._getModeRGB(img);
        }
    }

    getAllRGB(img: string, filter?: boolean, minContrast?: number): Observable<RGB[]>
    getAllRGB(img: HTMLImageElement, filter?: boolean, minContrast?: number): RGB[]
    getAllRGB(img: HTMLImageElement | string, filter = false, minContrast = 2) {
        if (typeof img === 'string') {
            return this.loadImage(img).pipe(
                map(imgEl => this._getAllRGB(imgEl, filter, minContrast)),
            );
        } else {
            return this._getAllRGB(img, filter, minContrast);
        }
    }
}

