import { Component, ViewChild, AfterViewInit } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js';

@Component({
  selector: 'openreel-mic-waves',
  templateUrl: './mic-waves.component.html',
  styleUrls: ['./mic-waves.component.scss'],
})
export class MicWavesComponent implements AfterViewInit {
  public wavesurfer;
  @ViewChild('waveSurferElement') waveSurferElement;

  ngAfterViewInit(): void {
    this.init();
  }
  init() {
    WaveSurfer.microphone = MicrophonePlugin;

    this.wavesurfer = WaveSurfer.create({
      container: this.waveSurferElement.nativeElement,
      waveColor: '#36b7ff',
      interact: false,
      cursorWidth: 0,
      audioContext: null,
      audioScriptProcessor: null,
      fillParent: true,
      height: 35,
      barMinHeight: 5,
      barHeight: 2,
      barWidth: 2,
      plugins: [
        WaveSurfer.microphone.create({
          bufferSize: 4096,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
          constraints: {
            video: false,
            audio: true,
          },
        }),
      ],
    });
    this.wavesurfer.microphone.start();
  }
}
