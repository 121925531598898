import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Video } from '../../interfaces';
import { Transformation, Trimming } from './transformation.interface';

@Component({
  selector: 'openreel-video-editor',
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.scss'],
})
export class VideoEditorComponent {
  trimming$ = new BehaviorSubject<Trimming>(null);

  video$ = new BehaviorSubject<Video>(null);
  @Input()
  set video(value) {
    this.video$.next(value);
  }
  get video() {
    return this.video$.getValue();
  }

  @Output()
  transformationChange = new EventEmitter<Transformation>();

  onTrimmingChange(event: Trimming) {
    this.trimming$.next(event);

    this.transformationChange.emit({
      trimming: event,
    });
  }
}
