import {
  AddClipsEvent,
  ItemsMovedEvent,
  LayerIdEvent,
  NewClip,
  StretchTextOverlayEvent,
  UpdateBoundsEvent,
  TimelineType,
  ToggleLayerFitEvent,
  UpdateLayerFitPositionEvent,
  TrimmerEvent,
  UpdateClipTrimsEvent,
  ItemTimelineChangedEvent,
} from '@openreel/creator/common';
import { createAction, props } from '@ngrx/store';

const actionType = '[Timelines]';

export const openSelectFilesDialog = createAction(
  `${actionType} Open Select Files Dialog`,
  props<{ timelineType: TimelineType }>()
);

// Main Clips
export const addMainClip = createAction(`${actionType} Add Main Clip`, props<{ event: AddClipsEvent }>());

export const removeClip = createAction(`${actionType} Remove Clip`, props<{ event: LayerIdEvent }>());

// Trimmer interaction
export const editSplits = createAction(`${actionType} Edit Splits`);

export const setTrimmerData = createAction(
  `${actionType} Set Trimmer Data To Timelines`,
  props<{ event: TrimmerEvent }>()
);

export const updateClipTrims = createAction(`${actionType} Update Clip Trim`, props<{ event: UpdateClipTrimsEvent }>());

export const addClipsAfterTrimmer = createAction(
  `${actionType} Add Clips After Trimmer`,
  props<{ clips: NewClip[] }>()
);

// Video Overlays
export const addVideoOverlay = createAction(`${actionType} Add Video Overlay`, props<{ event: AddClipsEvent }>());

export const changeVideoOverlayType = createAction(
  `${actionType} Change Video Overlay Type`,
  props<{ event: LayerIdEvent }>()
);

// Text Overlays
export const stretchTextOverlay = createAction(
  `${actionType} Stretch Text Overlay`,
  props<{ event: StretchTextOverlayEvent }>()
);

// Common actions
export const addClipsToTimelines = createAction(
  `${actionType} Add Clips To Timelines`,
  props<{ timelineIds: string[]; clips: NewClip[] }>()
);

export const moveItems = createAction(`${actionType} Move Items`, props<{ event: ItemsMovedEvent }>());

export const changeItemTimeline = createAction(
  `${actionType} Change Item Timeline`,
  props<{ event: ItemTimelineChangedEvent }>()
);

export const updateBounds = createAction(`${actionType} Update Bounds`, props<{ event: UpdateBoundsEvent }>());

export const updateLayerFit = createAction(`${actionType} Update Layer Fit`, props<{ event: ToggleLayerFitEvent }>());

export const updateLayerFitPosition = createAction(
  `${actionType} Update Layer Fit Position`,
  props<{ event: UpdateLayerFitPositionEvent }>()
);
