import * as fromActions from '../actions/asset-cache.actions';

import { createReducer, on } from '@ngrx/store';
import { AssetCache } from '../interfaces/asset-cache.interface';
import { cloneDeep } from 'lodash-es';

export const initialState: AssetCache = {
  assets: [],
  thumbnails: {
    transcoding: new Set(),
    spritesheets: new Map(),
  },
};

const reducer = createReducer(
  initialState,

  on(fromActions.cacheAsset, (state, cachedAsset) => {
    const assets = state.assets.filter(
      (asset) =>
        asset.assetId !== cachedAsset.assetId ||
        asset.provider !== cachedAsset.provider
    );

    return { ...state, assets: [...assets, cachedAsset] };
  }),

  on(fromActions.removeCachedAsset, (state, { id, provider }) => {
    const assets = state.assets.filter(
      (asset) => asset.assetId !== id || asset.provider !== provider
    );

    return { ...state, assets };
  }),

  on(fromActions.getSpritesheetS3Url, (state, { videoId }) => {
    if (
      state.thumbnails.spritesheets.has(videoId.toString()) ||
      state.thumbnails.transcoding.has(videoId.toString())
    ) {
      return { ...state };
    }

    const newState = cloneDeep(state);
    newState.thumbnails.transcoding.add(videoId);

    return { ...newState };
  }),

  on(fromActions.saveSpritesheet, (state, { videoId, spritesheet }) => {
    const newState = cloneDeep(state);
    newState.thumbnails.transcoding.delete(videoId.toString());
    newState.thumbnails.spritesheets.set(videoId.toString(), spritesheet);

    return { ...newState };
  })
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
