<form class="login-form" [formGroup]="myFormGroup">

  <div *ngIf="!guest.account_id; else userInfo">
    <span class="login-title">Team information</span>
    <p class="sub-title-gray">Setup your team.</p>
  </div>
  <ng-template #userInfo>
    <span class="login-title">User information</span>
  </ng-template>

  <p style="margin: 8px"></p>

  <input id="username"
    style="height: 0; width: 0; border: none; background: none; margin: 0 !important; padding: 0 !important; margin-top: -4px"
    type="email" name="fakeusernameremembered">

  <mat-form-field appearance="outline">
    <mat-label>First Name</mat-label>
    <input matInput formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
      placeholder="First Name" name="name">
    <mat-error *ngIf="submitted && f.name.errors?.required">Name is required</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Last Name</mat-label>
    <input matInput formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
      placeholder="Last Name" name="lastName">
    <mat-error *ngIf="submitted && f.lastName.errors?.required">Last name is required</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="!guest.account_id">
    <mat-label>Team Name</mat-label>
    <input matInput placeholder="Team Name" formControlName="companyName"
      [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" name="companyName">
    <mat-error *ngIf="submitted && f.companyName.errors?.required">Team name is required</mat-error>
  </mat-form-field>

  <button class="mat-flat-button" (click)="activateAccount()" mat-flat-button color="primary">Continue</button>
</form>