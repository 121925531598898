import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
} from 'rxjs';
import {  take, filter } from 'rxjs/operators';
import { Cleanupable } from '../../classes';
import {
  IStreamingLib,
  IStreamingLibService,
  SessionBase,
  StreamTokenResponse,
} from '../../interfaces';
import { ISessionService } from '../../interfaces/session-state.interface';
import {
  VideoConstraints,
  VideoStream,
} from '../../media';
import { AudioStream } from '../../media/interfaces/audio-stream.interface';
import { NetworkQualityService } from '../network-quality/network-quality.service';
import { SessionConfigService } from '../session/session-config.service';


@Injectable()
export abstract class BaseStreamingService<
    SubjectSessionClass extends SessionBase
  >
  extends Cleanupable
  implements OnDestroy {
  public videoStreamingInstance$ = new BehaviorSubject<IStreamingLib>(null);
  public get videoStreamingInstance() {
    return this.videoStreamingInstance$.value;
  }

  currentVideoStream: VideoStream;
  currentAudioStream: AudioStream;
  currentToken: StreamTokenResponse;
  connectionStatus = false;

  constructor(
    protected twilioProvider: IStreamingLibService,
    protected sessionConfig: SessionConfigService,
    protected networkQualityService: NetworkQualityService,
    protected sessionService: ISessionService
  ) {
    super();
    this.subscriptions.push(
      // this.audioSource.audio$
      //   .pipe(finalize(() => this.audioSource.closeStream()))
      //   .subscribe(async (audioStream) => {
      //     this.currentAudioStream = audioStream;
      //   }),
      // this.videoSource.video$.subscribe((videoStream) => {
      //   this.currentVideoStream = videoStream;
      // }),
      // this.videoSource.currentConstraints$.subscribe((newConstraints) => {
      //   this.handleVideoConstraintsChange(newConstraints);
      // })
    );
  }

  async joinVideo() {
    await this.sessionService.sessionInitialized$.pipe(
      filter(isInit => isInit),
      take(1)
    );
  }

  disconnectVideo() {
    if (this.videoStreamingInstance) {
      this.videoStreamingInstance.disconnect();
    }
  }

  async handleAudioSourceChange(startIfInactive: boolean = false) {
    if (this.videoStreamingInstance) {
      if (this.currentAudioStream?.stream?.active) {
        await this.videoStreamingInstance.broadcastAudio(
          this.currentAudioStream.track
        );
      } else if (
        startIfInactive &&
        this.currentAudioStream &&
        !this.currentAudioStream.error
      ) {
        this.sessionConfig.setNewAudioDevice(this.currentAudioStream.device);
      }
    }
  }

  private handleVideoConstraintsChange(newConstraints: VideoConstraints) {
    if (
      newConstraints &&
      this.currentVideoStream?.stream?.active &&
      this.videoStreamingInstance
    ) {
      this.videoStreamingInstance.changeVideoParameters(newConstraints);
    }
  }

  private async handleVideoSourceChange() {
    if (this.videoStreamingInstance) {
      if (this.currentVideoStream?.stream?.active) {
        this.videoStreamingInstance.broadcastVideo(
          this.currentVideoStream.track
        );
      }
    }
  }

  ngOnDestroy() {
    this.videoStreamingInstance$.pipe(take(1)).subscribe((instance) => {
      console.log('disconnecting');
      if (instance) instance.disconnect();
    });
  }
}
