import { Component, OnInit, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { HostingHub } from '../../hosting-interfaces';
import { HostingService } from '../../services/hosting.service';

class HostingHubCheck {
  hub: HostingHub;
  checked: boolean;  
}

@Component({
  selector: 'openreel-add-to-hub',
  templateUrl: './add-to-hub.component.html',
  styleUrls: ['./add-to-hub.component.scss']
})
export class AddToHubComponent implements OnInit {

  bDisabled = true;
  isLightThemed = true;
  isPopup = true;

  hubPageNumber = 1;
  hubPageSize = 1000;
  totalHubs = 0;

  hubList: Array<HostingHubCheck> = [];
  preselectedList: Array<number> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {videoId: number},
    public dialogRef: MatDialogRef<AddToHubComponent>,
    private hostingService: HostingService,
  ) { }

  ngOnInit() {
    this.getAllHubs();
  }

  //
  // Get all hubs for account
  //
  getAllHubs() {
    forkJoin([
      this.hostingService.getHubs(this.hubPageNumber, this.hubPageSize),
      this.hostingService.getHubs4video(this.data.videoId),
    ]).subscribe(
      ([hubResp, preselected]) => {
        this.preselectedList = preselected;
        this.totalHubs = hubResp.count;
    
        hubResp.rows.forEach(hub => {
          const hostingHub: HostingHubCheck = new HostingHubCheck();
          hostingHub.hub = hub;
          hostingHub.checked = false;
          this.hubList.push(hostingHub);        
        });
        this.markPreselected();
      }
    );
  }

  //
  // Get all pre selected
  //
  markPreselected() {
    if (!this.preselectedList.length) return;

    // walk throuth and set checked flag
    this.hubList.forEach(hub => {
      hub.checked = this.preselectedList.includes(hub.hub.id);
    });
  }

  onChange(event: MatCheckboxChange, hub: HostingHubCheck) {
    this.bDisabled = false;
    hub.checked = event.checked;
  }

  add2Hub() {
    const checked: Array<HostingHub> = [];
    const unchecked: Array<HostingHub> = [];

    // collect checked and unchecked
    this.hubList.forEach(hub => {
      if (hub.checked) { // checked
        // test if it was checked before
        if (!this.preselectedList.includes(hub.hub.id))
          checked.push(hub.hub);
      } else { // unchecked
        // test if it was checked before and got unchecked
        if (this.preselectedList.includes(hub.hub.id)) 
          unchecked.push(hub.hub);
      }
    });

    this.dialogRef.close({checked, unchecked});
  }

}
