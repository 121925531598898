<mat-icon
  *ngIf="data.icon"
  data-cy="title-icon"
  class="title-icon"
  [svgIcon]="data.icon"></mat-icon>

<h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>

<div mat-dialog-content class="m-top-20">
  <mat-icon
    *ngIf="data.type === AlertTypeEnum.Error"
    data-cy="cancel-process"
    class="mat-icon-40 flex-none text-color-error m-right-20"
    svgIcon="cancel-process"></mat-icon>
  <mat-icon
    *ngIf="data.type === AlertTypeEnum.Info"
    data-cy="info-alt"
    class="mat-icon-40 flex-none m-right-20"
    svgIcon="info-alt"></mat-icon>
  <mat-icon
    *ngIf="data.type === AlertTypeEnum.Confirmation"
    data-cy="info"
    class="mat-icon-40 flex-none m-right-20"
    svgIcon="info"></mat-icon>
  <span data-cy="alert-message"> {{ data.message }} </span>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="data.type !== AlertTypeEnum.Confirmation">
    <button
      data-cy="close-button"
      mat-raised-button
      color="primary"
      (click)="ok()">
      {{ data.confirmButtonText || 'Close' }}
    </button>
  </ng-container>
  <ng-container *ngIf="data.type === AlertTypeEnum.Confirmation">
    <button
      [attr.data-cy]="data.confirmButtonText || 'yes-button'"
      mat-raised-button
      color="primary"
      (click)="yes()">
      {{ data.confirmButtonText || 'Yes' }}
    </button>
    <button
      [attr.data-cy]="data.rejectButtonText || 'no-button'"
      mat-raised-button
      (click)="no()">
      {{ data.rejectButtonText || 'No' }}
    </button>
  </ng-container>
</div>
