import { NextgenParticipant } from '../../interfaces/nextgen-participant.interface';
import { InvitedUser, UserRoleType } from '../../interfaces/interfaces';

export const mapInvitedUserToParticipant = (
  invitedUser: InvitedUser,
  participant: NextgenParticipant
): NextgenParticipant => ({
  ...participant,
  identity: !participant
    ? invitedUser.ovra_sessionUser_mapping_id.toString()
    : participant.identity,
  isPinned: !invitedUser ? true : invitedUser.is_pinned,
  isMuted:
    !participant || participant.isMuted == null
      ? invitedUserIsAudible(invitedUser)
      : participant.isMuted,
  isVideoDisabled:
    !participant || participant.isVideoDisabled == null
      ? false
      : participant.isVideoDisabled,
  name: invitedUser
    ? invitedUser.invited_users_details?.fullname || invitedUser.app_user_name
    : participant.name,
  email: invitedUser ? invitedUser.user_email : participant.email,
  isConnected: !participant ? false : participant.isConnected,
  role: invitedUser ? invitedUser.sum_role : UserRoleType.Subject,
  loginId: invitedUser?.ovra_sessionUser_mapping_id || participant?.loginId,
});

const invitedUserIsAudible = (invitedUser: InvitedUser) => {
  if (invitedUser) {
    return !invitedUser.is_audible;
  }
  return false;
};
