import {
  BoundsInfo,
  CANVAS_NAME,
  GROUP_SELECTION_NAME,
  ItemData,
  ObjectDrawInfo,
  ObjectTiming,
  PROGRAMATIC_GROUP_SELECTION_EVENT_TYPE,
  PROGRAMATIC_SELECTION_CLEAR_EVENT_TYPE,
  SECTION_INDEX_TIMELINES,
  SectionData,
  TIME_CURSOR_NAME,
  TimelineData,
  TimingInfo,
  Viewport,
  TIME_CURSOR_Z_INDEX,
  TRIMMER_Z_INDEX,
} from '../canvas.interfaces';
import { DragCalcResultAction, DragService } from '../interactions/drag.service';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { StretchService, StretchSide } from '../interactions/stretch.service';
import { Subscription, fromEvent, timer, Subject } from 'rxjs';
import { getLayersTiming } from '../helpers';

import { CanvasEventsService } from './canvas-events.service';
import { Cleanupable } from '@openreel/common';
import { DrawDataService } from './draw-data.service';
import { InteractionService } from '../interactions/interaction-base.service';
import { Layer, Trimmer } from '../models';
import { ScrollService } from '../interactions/scroll.service';
import { SelectionService } from '../interactions/selection.service';
import { ThumbnailsService } from './thumbnails.service';
import { CanvasConfig } from '../canvas.config';
import { fabric } from 'fabric';
import { merge, round } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { CANVAS_CONFIG_TOKEN } from '../components/timelines-canvas/timelines-canvas.component';
import { FabricService } from './fabric.service';
import { AssetId } from '@openreel/creator/common';

export interface TrimData {
  timelineIndex: number;
  from: number;
  to: number;
}

export interface CanvasCreateOptions {
  allowItemInteraction?: boolean;
  showTrimmers?: boolean;
  showTimeCursor?: boolean;
}

const DEFAULT_CREATE_OPTIONS: Partial<CanvasCreateOptions> = {
  showTimeCursor: true,
  allowItemInteraction: true,
};

export interface RedrawOptions {
  // Do we recreate objects
  recreateObjects?: boolean;
}

@Injectable()
export class CanvasService extends Cleanupable implements OnDestroy {
  private canvas: fabric.Canvas;
  private options: CanvasCreateOptions;
  private config: CanvasConfig;
  private canvasObjects = new Map<string, fabric.Object>();
  private viewport = new Viewport();

  private isInitialized = false;

  private data: SectionData[] = [];
  private totalDuration = 0;
  private currentTime = 0;
  private trimData: TrimData[];

  private scrollSubscription: Subscription;

  private currentInteraction: InteractionService;

  constructor(
    @Inject(CANVAS_CONFIG_TOKEN)
    private readonly canvasConfig$: Subject<CanvasConfig>,
    private readonly canvasEventsService: CanvasEventsService,
    private readonly drawDataService: DrawDataService,
    private readonly dragService: DragService,
    private readonly stretchService: StretchService,
    private readonly scrollService: ScrollService,
    private readonly selectionService: SelectionService,
    private readonly thumbnailsService: ThumbnailsService,
    private readonly fabricService: FabricService
  ) {
    super();
    this.canvasConfig$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config) => (this.config = config));
  }

  ngOnDestroy() {
    if (this.canvas) {
      this.clearCanvas();
      this.canvas.dispose();
    }

    super.ngOnDestroy();
  }

  initCanvas(elementId: string, options: CanvasCreateOptions) {
    this.options = merge({}, DEFAULT_CREATE_OPTIONS, options);

    this.canvas = new fabric.Canvas(elementId, {
      backgroundColor: 'rgba(0,0,0,0)',
      selection: false,
      preserveObjectStacking: true,
    });

    this.setupCanvasEvents();

    if (this.options.allowItemInteraction) {
      this.setupCanvasItemSelectionEvents();
      this.setupCanvasItemDragEvents();
      this.setupCanvasItemStretchEvents();
      this.setupSelectionEvents();
    }
  }

  setData(data: SectionData[], totalDuration: number, recreateObjects: boolean = false) {
    this.data = data;
    this.totalDuration = totalDuration;

    const height = this.data[SECTION_INDEX_TIMELINES].timelines.reduce(
      (prev, curr, currIndex, arr) =>
        prev +
        this.config.timelines[curr.type].height +
        (currIndex < arr.length - 1 ? this.config.timelineTrackOptions.bottomMargin : 0),
      this.config.canvas.padding.y * 2
    );
    this.canvas.setHeight(height);

    this.redraw({ recreateObjects });
  }

  setSpritesheets(spritesheets: Map<AssetId, HTMLImageElement>) {
    this.thumbnailsService.setSpritesheets(spritesheets);
    this.canvas.requestRenderAll();
  }

  setViewport(startAt: number, duration: number) {
    this.viewport.setTime(startAt, duration, this.totalDuration);
    this.redraw();
  }

  setWidth(width: number) {
    this.canvas.setWidth(width);
    this.viewport.setWidth(width - 2 * this.config.canvas.padding.x);
    this.redraw();
  }

  setCurrentTime(currentTime: number) {
    this.currentTime = currentTime || 0;

    this.redrawTimeCursor();
  }

  setSelectedItem(layerId: string | null) {
    if (layerId === null) {
      this.canvas.discardActiveObject(new Event(PROGRAMATIC_SELECTION_CLEAR_EVENT_TYPE));
      this.canvas.requestRenderAll();
    }
  }

  setTrimmers(trimData: TrimData[]) {
    this.trimData = trimData;

    if (!this.isInitialized) {
      this.redraw();
    } else {
      this.redrawTrimmers();
    }
  }

  redraw(options?: RedrawOptions) {
    if (this.data.length === 0) {
      return;
    }
    if (!this.isInitialized || options?.recreateObjects) {
      this.isInitialized = true;
      this.createObjects();
      this.setCurrentTime(0);
    }
    this.recalcObjects();
    this.recalcTimeCursorObject();
    this.bringToFrontObjects();
    this.canvas.requestRenderAll();
  }

  private redrawTimeCursor() {
    if (!this.options.showTimeCursor) {
      return;
    }

    this.recalcTimeCursorObject();
    this.canvas.requestRenderAll();
  }

  private clearCanvas() {
    this.drawDataService.clear();
    this.canvas.remove(...this.canvas.getActiveObjects());
    this.canvas.clear();
  }

  private redrawTrimmers() {
    this.data
      .find((s) => s.type === 'main')
      .timelines.forEach((t, index) => {
        const fabricObject = this.canvasObjects.get(this.fabricService.trimmerName(t)) as Trimmer;

        fabricObject.data.from = this.trimData[index].from;
        fabricObject.data.to = this.trimData[index].to;
        fabricObject.updateBounds();
      });
    this.canvas.requestRenderAll();
  }

  // eslint-disable-next-line max-lines-per-function
  private createObjects() {
    this.clearCanvas();

    const canvasObjectInfo = this.createMainObject();

    if (this.options.showTimeCursor) {
      this.createTimeCursorObject(canvasObjectInfo);
    }

    // Create sections/timelines/items objects
    this.data.forEach((section) => {
      const sectionFabricObject = this.fabricService.createSectionObject(section, this.canvas);
      this.canvasObjects.set(this.fabricService.sectionName(section), sectionFabricObject);
      this.canvas.add(sectionFabricObject);

      const sectionObjectInfo = this.drawDataService.add(
        this.fabricService.sectionName(section),
        canvasObjectInfo,
        section,
        { selectable: false }
      );
      this.fabricService.setObjectBoundsFromFabricBounds(sectionObjectInfo, sectionFabricObject);

      // Timelines
      let timelineTop = sectionObjectInfo.bounds.top;
      section.timelines.forEach((timeline) => {
        const { timelineObjectInfo } = this.createTimelineObject(timeline, timelineTop, sectionObjectInfo);

        timelineTop += timelineObjectInfo.bounds.height + this.config.timelineTrackOptions.bottomMargin;
      });
    });
  }

  private createMainObject() {
    // Create main object
    const mainFabricObject = this.fabricService.createMainObject(this.canvas);
    this.canvasObjects.set(mainFabricObject.name, mainFabricObject);
    this.canvas.add(mainFabricObject);

    const canvasObjectInfo = this.drawDataService.add(
      CANVAS_NAME,
      null,
      {
        startAt: 0,
        endAt: this.totalDuration,
        duration: this.totalDuration,
      },
      {
        selectable: false,
        gap: this.config.sectionOptions.gap,
      }
    );

    this.fabricService.setObjectBoundsFromFabricBounds(canvasObjectInfo, mainFabricObject);
    return canvasObjectInfo;
  }

  private createTimeCursorObject(canvasObj: ObjectDrawInfo) {
    const timeCursorFabricObject = this.fabricService.createTimeCursorObject(this.canvas);
    this.canvasObjects.set(timeCursorFabricObject.name, timeCursorFabricObject);
    this.canvas.add(timeCursorFabricObject);

    const timeCursorObjectInfo = this.drawDataService.add(TIME_CURSOR_NAME, canvasObj, {
      startAt: 0,
      endAt: 0,
      duration: 0,
    });
    timeCursorObjectInfo.bounds.height = canvasObj.bounds.height;
  }

  private createTimelineObject(timeline: TimelineData, timelineTop: number, sectionObjectInfo: ObjectDrawInfo) {
    const timelineFabricObject = this.fabricService.createTimelineObject(timeline, timelineTop);
    this.canvasObjects.set(this.fabricService.timelineName(timeline), timelineFabricObject);
    this.canvas.add(timelineFabricObject);

    const timelineObjectInfo = this.drawDataService.add(
      this.fabricService.timelineName(timeline),
      sectionObjectInfo,
      timeline,
      { selectable: false }
    );
    this.fabricService.setObjectBoundsFromFabricBounds(timelineObjectInfo, timelineFabricObject);

    // Trimmer
    if (this.options.showTrimmers) {
      const trimData = this.trimData && this.trimData.length > 0 ? this.trimData[timeline.index] : null;
      const trimmerFabricObject = this.fabricService.createTrimmerObject(timeline, timelineTop, trimData);
      trimmerFabricObject.data.updateTrimBounds = (from: number, to: number, done: boolean) => {
        this.canvasEventsService.setTrimBounds({
          index: timeline.index,
          from,
          to,
          done,
        });
      };

      this.canvasObjects.set(trimmerFabricObject.name, trimmerFabricObject);
      this.canvas.add(trimmerFabricObject);

      const trimmerObjectInfo = this.drawDataService.add(trimmerFabricObject.name, timelineObjectInfo, timeline, {
        selectable: false,
      });
      this.fabricService.setObjectBoundsFromFabricBounds(trimmerObjectInfo, trimmerFabricObject);
    }

    // Items
    timeline.items.forEach((item) => this.createLayerObject(item, timeline, timelineObjectInfo));

    return { timelineObjectInfo };
  }

  private createLayerObject(itemData: ItemData, timelineData: TimelineData, timelineObjectInfo: ObjectDrawInfo): void {
    const fabricObject = this.fabricService.createItemObject(
      itemData,
      timelineData,
      timelineObjectInfo.bounds.top,
      this.options.allowItemInteraction
    );
    fabricObject.data.getThumbnails = (layerId: string) => this.thumbnailsService.generateThumbs(layerId);

    this.canvasObjects.set(fabricObject.name, fabricObject);
    this.canvas.add(fabricObject);

    const itemObjectInfo = this.drawDataService.add(itemData.layerId, timelineObjectInfo, itemData, {
      singleSelectionOnly: false,
      selectable: true,
      reorderable: this.config.timelines[timelineData.type].itemsReorderable,
      linkedObjectName: itemData.linkedLayerId,
      minDuration: itemData.minDuration,
      video: itemData.video,
    });
    this.fabricService.setObjectBoundsFromFabricBounds(itemObjectInfo, fabricObject);
  }

  private removeDeletedLayers(existingLayers: string[]) {
    const removedLayers = Array.from(this.canvasObjects.keys()).filter(
      (key) => this.canvasObjects.get(key).type === 'layer' && existingLayers.indexOf(key) === -1
    );
    removedLayers.forEach((removedLayer) => {
      this.drawDataService.remove(removedLayer);

      const fabricObjectToRemove = this.canvasObjects.get(removedLayer);
      this.canvasObjects.delete(removedLayer);
      this.canvas.remove(fabricObjectToRemove);
    });
  }

  private recalcObjects() {
    const objectStillInUse: string[] = [];

    this.recalcCanvasObject();

    this.data.forEach((section, sectionIndex) => {
      this.recalcObject(
        this.fabricService.sectionName(section),
        section,
        section.timelines.length,
        sectionIndex,
        this.data.length
      );

      section.timelines.forEach((timeline, timelineIndex) => {
        this.recalcObject(
          this.fabricService.timelineName(timeline),
          timeline,
          timeline.items.length,
          timelineIndex,
          section.timelines.length
        );

        if (this.options.showTrimmers) {
          this.recalcObject(this.fabricService.trimmerName(timeline), timeline);
        }

        timeline.items.forEach((item, itemIndex) => {
          const itemExists = this.drawDataService.exists(item.layerId);
          if (!itemExists) {
            const timelineObjectInfo = this.drawDataService.get(this.fabricService.timelineName(timeline));

            this.createLayerObject(item, timeline, timelineObjectInfo);
          }

          this.recalcObject(item.layerId, item, 0, itemIndex, timeline.items.length);

          objectStillInUse.push(item.layerId);
        });
      });
    });

    this.removeDeletedLayers(objectStillInUse);
  }

  private recalcCanvasObject() {
    const left = this.config.canvas.padding.x + (-this.viewport.startAt / this.viewport.duration) * this.viewport.width;
    const width = (this.totalDuration / this.viewport.duration) * this.viewport.width;
    const canvasObjectInfo = this.drawDataService.get(CANVAS_NAME);
    canvasObjectInfo.timing.setValues(0, this.totalDuration);
    canvasObjectInfo.bounds.set({
      ...canvasObjectInfo.bounds,
      left,
      width,
    });

    const canvasFabricObject = this.canvasObjects.get(CANVAS_NAME);
    this.fabricService.setFabricBoundsFromObjectBounds(canvasFabricObject, canvasObjectInfo);
  }

  private recalcObject(
    name: string,
    data: ObjectTiming,
    childrenCount: number = 0,
    index: number = 0,
    count: number = 0
  ) {
    const objectInfo = this.drawDataService.get(name);

    // Update metadata for fabric object
    const fabricObject = this.canvasObjects.get(objectInfo.name);
    fabricObject.data.childrenCount = childrenCount;

    // Recalculate position of active items
    if (this.selectionService.isSelected(name)) {
      if (this.currentInteraction?.type === 'drag') {
        const bounds = this.selectionService.bounds;
        this.calculateValidMovePosition(bounds.left);
        this.scrollIfNecessary();
        return;
      }

      if (this.currentInteraction?.type === 'stretch') {
        const selectedItem = this.selectionService.items[0];
        const selectedItemFabric = this.canvasObjects.get(selectedItem.name);
        this.calculateValidStretchPosition(selectedItem.bounds.left, selectedItemFabric.scaleX);
        return;
      }
    }

    // Dont update timing of items that have changed but the change has not been applied
    const hasUnsavedChange = this.dragService.changedItems.has(name);
    if (!hasUnsavedChange) {
      objectInfo.timing.setValues(data.startAt, data.endAt);
    }

    objectInfo.calculateBoundsFromTiming(count, index);
    this.fabricService.setFabricBoundsFromObjectBounds(fabricObject, objectInfo);
  }

  private recalcTimeCursorObject() {
    if (!this.options.showTimeCursor) {
      return;
    }

    if (this.selectionService.isSelected(TIME_CURSOR_NAME)) {
      if (this.currentInteraction) {
        return;
      }
    }

    const timeCursorObject = this.drawDataService.get(TIME_CURSOR_NAME);

    const viewportPxPerMs = this.viewport.width / this.viewport.duration;

    const newLeft = this.config.canvas.padding.x + (this.currentTime - this.viewport.startAt) * viewportPxPerMs;

    timeCursorObject.setBoundsAndTiming(
      BoundsInfo.from({
        ...timeCursorObject.bounds,
        left: newLeft,
      }),
      new TimingInfo(this.currentTime, this.currentTime)
    );

    const fabricObject = this.canvasObjects.get(TIME_CURSOR_NAME);
    fabricObject.set({
      left: timeCursorObject.bounds.left,
    });
    fabricObject.setCoords();
  }

  private bringToFrontObjects() {
    if (this.options.showTimeCursor) {
      this.canvasObjects.get(TIME_CURSOR_NAME).moveTo(TIME_CURSOR_Z_INDEX);
    }

    if (this.options.showTrimmers) {
      this.data
        .find((s) => s.type === 'main')
        .timelines.forEach((t) => this.canvasObjects.get(this.fabricService.trimmerName(t)).moveTo(TRIMMER_Z_INDEX));
    }
  }

  private setupCanvasEvents() {
    // Scrolling Events
    this.canvas.on('mouse:wheel', (opt) => {
      this.zoomToPoint((opt.e as WheelEvent).deltaY);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // Debug events
    if (this.config.debug) {
      this.canvas.on('mouse:move', (opt) => {
        if ((opt.e as MouseEvent).altKey) {
          console.log(opt.pointer.x, opt.pointer.y);
        }
      });
      this.canvas.on('mouse:up', (opt) => {
        if ((opt.e as MouseEvent).altKey) {
          console.log('Clicked object: ', opt.target.name);
        }
      });

      fromEvent(document, 'keyup')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((e: KeyboardEvent) => {
          if (e.key.toUpperCase() === 'P') {
            console.log('Cache objects');
            console.log(this.drawDataService.items);
            console.log('Canvas objects');
            console.log(this.canvasObjects);
          }

          if (e.key.toUpperCase() === 'R') {
            console.log('Hard redraw (recreateObjects: true)');
            this.redraw({ recreateObjects: true });
          }
        });
    }
  }

  private setupCanvasItemSelectionEvents() {
    this.canvas.on('selection:created', (opt) => this.createOrUpdateSelection('selection:created', opt));
    this.canvas.on('selection:updated', (opt) => this.createOrUpdateSelection('selection:updated', opt));
    this.canvas.on('before:selection:cleared', (opt) => this.clearSelection(opt));
  }

  private setupCanvasItemDragEvents() {
    this.canvas.on('object:moving', (opt) => {
      if (!this.currentInteraction) {
        this.currentInteraction = this.dragService;
        this.dragService.start();
      }
      this.calculateValidMovePosition(opt.target.left);
      this.scrollIfNecessary();
    });
    this.canvas.on('object:moved', (opt) => {
      this.dropObject(opt.target.name);
      this.currentInteraction = null;
    });
  }

  private setupCanvasItemStretchEvents() {
    this.canvas.on('object:scaling', (opt) => {
      if (!this.currentInteraction) {
        this.currentInteraction = this.stretchService;

        const stretchSide = opt.transform.corner === 'ml' ? StretchSide.Left : StretchSide.Right;
        this.stretchService.start(stretchSide);

        if (opt.target instanceof Layer) {
          opt.target.startStretch();
        }
      }
      this.calculateValidStretchPosition(opt.target.left, opt.target.scaleX);
      this.scrollIfNecessary();
    });
    this.canvas.on('object:scaled', (opt) => {
      const selectedItem = this.selectionService.items[0];
      this.canvasEventsService.setStretch(selectedItem.name, selectedItem.timing.startAt, selectedItem.timing.endAt);

      if (this.scrollSubscription && !this.scrollSubscription.closed) {
        this.scrollSubscription.unsubscribe();
      }

      this.fabricService.removeFabricScaleFromObject(this.canvasObjects.get(selectedItem.name));
      this.stretchService.reset();
      this.canvas.discardActiveObject();

      this.currentInteraction = null;

      if (opt.target instanceof Layer) {
        opt.target.endStretch();
      }
    });
  }

  private setupSelectionEvents() {
    this.selectionService.selectionChanged$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ oldValues, newValues }) => {
        oldValues.forEach((item) => {
          const fabricObject = this.canvasObjects.get(item.name);
          if (fabricObject instanceof Layer) {
            fabricObject.hideSelectionBorder();
          }
        });

        newValues.forEach((item) => {
          const fabricObject = this.canvasObjects.get(item.name);
          if (fabricObject instanceof Layer) {
            fabricObject.showSelectionBorder();
          }
        });

        if (newValues.length > 1) {
          const fabricObjects = newValues.map((item) => this.canvasObjects.get(item.name));
          const selection = new fabric.ActiveSelection(fabricObjects, {
            name: GROUP_SELECTION_NAME,
            hasBorders: false,
            hasControls: false,
            canvas: this.canvas,
          });
          selection.setCoords();

          this.canvas.discardActiveObject(new Event(PROGRAMATIC_GROUP_SELECTION_EVENT_TYPE));

          this.canvas.setActiveObject(selection, new Event(PROGRAMATIC_GROUP_SELECTION_EVENT_TYPE));
        }

        this.canvas.requestRenderAll();
      });
  }

  private createOrUpdateSelection(eventType: string, opt: fabric.IEvent) {
    if (this.config.debug) {
      if ((opt.e as MouseEvent).altKey) {
        console.group('Clicked object');
        console.log(this.canvasObjects.get(opt.target.name));
        console.log(this.drawDataService.get(opt.target.name));
      }
    }
    opt.target.lockMovementY = true;
    if (opt.e?.type === PROGRAMATIC_GROUP_SELECTION_EVENT_TYPE) {
      return;
    }

    const clickedItem = this.drawDataService.get(opt.target.name);

    if (clickedItem.singleSelectionOnly) {
      this.selectionService.addWithSiblings(opt.target.name);
    } else {
      const connectedItems = this.drawDataService.getConnectedObjects(clickedItem);

      const pointer = this.canvas.getPointer(opt.e);
      const clickRatio = (pointer.x - opt.target.left) / opt.target.width;

      const clickedOnFirstItemBoundary =
        connectedItems[0].name === clickedItem.name && clickRatio < this.config.selection.nearThresholdRatio;
      const clickedOnLastItemBoundary =
        connectedItems[connectedItems.length - 1].name === clickedItem.name &&
        clickRatio > 1 - this.config.selection.nearThresholdRatio;

      const selectConnected = clickedOnFirstItemBoundary || clickedOnLastItemBoundary;

      if (selectConnected) {
        this.selectionService.add(connectedItems[0].name);
        this.selectionService.add(connectedItems[connectedItems.length - 1].name, true);
      } else {
        this.selectionService.add(opt.target.name, (opt.e as MouseEvent).shiftKey);
      }
    }

    if (opt.target instanceof Layer) {
      this.canvasEventsService.setItemSelected(this.selectionService.items.map((i) => i.name));
    }
  }

  private clearSelection(opt: fabric.IEvent) {
    if (opt.e?.type === PROGRAMATIC_GROUP_SELECTION_EVENT_TYPE) {
      return;
    }

    this.selectionService.clear();
    if (opt.e?.type !== PROGRAMATIC_SELECTION_CLEAR_EVENT_TYPE) {
      this.canvasEventsService.setItemSelected(null);
    }
  }

  private zoomToPoint(deltaY: number) {
    const delta = deltaY < 0 ? -1 : 1;
    this.canvasEventsService.setZoom(delta, delta < 0 ? this.currentTime : null);
  }

  private calculateValidMovePosition(targetLeft: number) {
    const calcResult = this.dragService.calcDragPosition(targetLeft);
    const changedItems =
      calcResult.action === DragCalcResultAction.Move
        ? this.moveObjects()
        : this.reorderObjects(calcResult.reorderTarget);

    changedItems.forEach((item) => {
      item.calculateBoundsFromTiming();
      this.fabricService.setFabricBoundsFromObjectBounds(this.canvasObjects.get(item.name), item);
    });
  }

  private scrollIfNecessary() {
    if (this.viewport.isZoomed()) {
      const { shouldScroll, msToScroll } = this.scrollService.shouldScroll(
        this.selectionService.bounds,
        this.currentInteraction.moveDirection,
        this.viewport
      );

      if (shouldScroll && !this.currentInteraction.isSnapped) {
        if (!this.scrollSubscription || this.scrollSubscription.closed) {
          this.scrollSubscription = timer(0, 100).subscribe(() => this.canvasEventsService.setScroll(msToScroll));
        }
      } else {
        if (this.scrollSubscription && !this.scrollSubscription.closed) {
          this.scrollSubscription.unsubscribe();
        }
      }
    } else {
      if (this.scrollSubscription && !this.scrollSubscription.closed) {
        this.scrollSubscription.unsubscribe();
      }
    }
  }

  private moveObjects() {
    const selectedItems = this.selectionService.items;

    const deltaMs = round(this.dragService.validTiming.startAt - this.selectionService.timing.startAt);

    if (deltaMs !== 0) {
      selectedItems.forEach((item) => {
        item.timing.addDelta(deltaMs);
      });
    }

    return selectedItems;
  }

  private reorderObjects(reorderTarget: ObjectDrawInfo) {
    const sourceItems = this.selectionService.items;
    const targetItems = [reorderTarget];

    const changedItems = this.fabricService.swapObjectTimings(sourceItems, targetItems, this.dragService.validTiming);
    this.dragService.updateDragValidTiming(getLayersTiming(sourceItems));

    // Reorder linked items (for templates that have pairs of main clips)
    if (reorderTarget.linkedObjectName) {
      const linkedSourceItems = sourceItems.map((sourceItem) => this.drawDataService.get(sourceItem.linkedObjectName));
      const linkedTargetItems = targetItems.map((targetItem) => this.drawDataService.get(targetItem.linkedObjectName));

      const linkedChangedItems = this.fabricService.swapObjectTimings(linkedSourceItems, linkedTargetItems);

      this.dragService.addToChangedItems(linkedChangedItems);
      changedItems.push(...linkedChangedItems);
    }

    return changedItems;
  }

  private dropObject(targetName: string) {
    if (targetName === TIME_CURSOR_NAME) {
      const item = this.drawDataService.get(targetName);
      this.canvasEventsService.setSeek(item.timing.startAt);
    } else {
      const changesMs = [...this.dragService.changedItems].map((layerId) => {
        const item = this.drawDataService.get(layerId);
        return {
          layerId,
          newStartAt: item.timing.startAt,
        };
      });
      this.canvasEventsService.setDrop(changesMs);
    }

    if (this.scrollSubscription && !this.scrollSubscription.closed) {
      this.scrollSubscription.unsubscribe();
    }

    this.dragService.reset();
  }

  private calculateValidStretchPosition(targetLeft: number, targetScaleX: number) {
    const selectedItem = this.selectionService.items[0];
    this.stretchService.calcStretchPosition(targetLeft, targetScaleX, selectedItem.minDuration);
    selectedItem.timing.set(this.stretchService.validTiming);
    selectedItem.calculateBoundsFromTiming();

    this.fabricService.setFabricScaleFromObjectTiming(
      this.canvasObjects.get(selectedItem.name),
      selectedItem,
      this.stretchService.startTiming
    );
  }
}
