import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  mobileAudioGainLabel,
  mobileAudioGainRange,
  webAudioGainRange,
} from '../../interfaces/audio-gain.interface';
enum DragAxis {
  'X' = 'x',
  'Y' = 'y',
}
interface DragPosition {
  x: number;
  y: number;
}

const IconSize = 24;
@Component({
  selector: 'openreel-audio-gain-control',
  templateUrl: './audio-gain-control.component.html',
  styleUrls: ['./audio-gain-control.component.scss'],
})
export class AudioGainControlComponent {
  @Input() dragAxis: DragAxis;
  @Input() isIosDevice: boolean;
  @Output() gainValueChange = new EventEmitter<number>();
  DragAxis = DragAxis;
  position: DragPosition = { x: 0, y: 0 };
  @ViewChild('gainWrapper') gainWrapper: ElementRef<HTMLDivElement>;
  width: number;
  height: number;
  currentGain: number;
  gainTitle: number;
  mobileAudioGainLabel = mobileAudioGainLabel;

  @Input() set gain(value: number) {
    if (value !== this.currentGain) {
      this.currentGain = value;
      this.setDragPosition();
    }
  }

  onResizeWrapper() {
    this.height = this.gainWrapper.nativeElement.offsetHeight - IconSize;
    this.width = this.gainWrapper.nativeElement.offsetWidth - IconSize;
    this.setDragPosition();
  }

  onDragEnd(event) {
    const position = event.source.getFreeDragPosition();
    let currentDragValue: number;
    if (this.dragAxis === DragAxis.Y) {
      currentDragValue = this.getVerticalDragValue(position.y);
    } else {
      currentDragValue = this.getHorizontalDragValue(position.x);
    }
    // keep the label consistent between web and mobile, hence using mobile range for title
    this.gainTitle = Math.round(
      currentDragValue * (mobileAudioGainRange.max - mobileAudioGainRange.min) +
        mobileAudioGainRange.min
    );
    const value = this.isIosDevice
      ? Math.round(this.getActualValue(currentDragValue))
      : Number(this.getActualValue(currentDragValue).toFixed(2));
    this.currentGain = value;
    this.gainValueChange.emit(value);
  }

  setDragPosition() {
    if (!this.currentGain) this.currentGain = 0;
    const gainRange = this.isIosDevice
      ? mobileAudioGainRange
      : webAudioGainRange;
    const percentage =
      (this.currentGain - gainRange.min) / (gainRange.max - gainRange.min);
    if (this.dragAxis === DragAxis.Y) {
      this.setVerticalDrag(percentage);
    } else {
      this.setHorizontalDrag(percentage);
    }
    this.setGainTitle();
  }

  private setGainTitle() {
    let currentDragValue: number;
    if (this.dragAxis === DragAxis.Y) {
      currentDragValue = this.getVerticalDragValue(this.position.y);
    } else {
      currentDragValue = this.getHorizontalDragValue(this.position.x);
    }
    // keep the label consistent between web and mobile, hence using mobile range for title
    this.gainTitle = Math.round(
      currentDragValue * (mobileAudioGainRange.max - mobileAudioGainRange.min) +
        mobileAudioGainRange.min
    );
  }

  private getActualValue(value: number) {
    const gainRange = this.isIosDevice
      ? mobileAudioGainRange
      : webAudioGainRange;
    return value * (gainRange.max - gainRange.min) + gainRange.min;
  }
  private getHorizontalDragValue(value: number) {
    return value / this.width;
  }
  private getVerticalDragValue(value: number) {
    return (this.height - value) / this.height;
  }

  private setVerticalDrag(percenatge: number) {
    const dragValue = this.height - this.height * percenatge;
    this.position = { x: 0, y: dragValue };
  }
  private setHorizontalDrag(percenatge: number) {
    const dragValue = this.width * percenatge;
    this.position = { x: dragValue, y: 0 };
  }

  stopDragPropagation(event: Event) {
    event.stopPropagation();
  }
}
