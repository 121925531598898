<div class="email-logo-container">
  <mat-form-field class="logo-email text-12" appearance="outline">
    <mat-select
      data-cy="select-email-logo"
      [value]="selectedLogo?.path"
      (valueChange)="onLogoChange($event)"
      (openedChange)="onSelectToggled($event)"
    >
      <mat-select-trigger *ngIf="selectedLogo" [attr.data-cy]="selectedLogo.fileName" [value]="selectedLogo.path">
        <img data-cy="logo" [openreelAssetImage]="selectedLogo.path" alt="Logo" />
      </mat-select-trigger>
      <mat-option *ngIf="!logoAssets.length; else elseLogos" disabled> No logos found </mat-option>
      <ng-template #elseLogos>
        <mat-option
          class="logo-email-option"
          *ngFor="let logo of logoAssets"
          [attr.data-cy]="logo.fileName"
          [value]="logo.path"
        >
          <img data-cy="logo" [openreelAssetImage]="logo.path" alt="Logo" />
        </mat-option>
      </ng-template>
    </mat-select>
  </mat-form-field>
</div>
