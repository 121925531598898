import {
  AudioTrack,
  VideoTrack,
  LocalAudioTrackPublication,
  RemoteAudioTrackPublication,
  LocalVideoTrackPublication,
  RemoteVideoTrackPublication,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteVideoTrack,
  RemoteAudioTrack,
  LocalTrackPublication,
  AudioTrackPublication,
  VideoTrackPublication,
} from 'twilio-video';

export function isLocalAudioPublication(
  value
): value is LocalAudioTrackPublication {
  return value.kind == 'audio' && value.track?.id ? true : false;
}
export function isRemoteAudioPublication(
  value
): value is RemoteAudioTrackPublication {
  return value.kind == 'audio' && value.track?.sid ? true : false;
}
export function isLocalVideoPublication(
  value
): value is LocalVideoTrackPublication {
  return value.kind == 'video' && value.track?.id ? true : false;
}
export function isRemoteVideoPublication(
  value: RemoteVideoTrackPublication
): value is RemoteVideoTrackPublication {
  return value.kind == 'video' && value.trackSid ? true : false;
}
export function isLocalTrackPublication(value): value is LocalTrackPublication {
  return isLocalAudioPublication(value) || isLocalVideoPublication(value);
}
export function isLocalVideoTrack(value): value is LocalVideoTrack {
  return value.kind == 'video' && value.id ? true : false;
}
export function isLocalAudioTrack(value): value is LocalAudioTrack {
  return value.kind == 'audio' && value.id ? true : false;
}
export function isRemoteVideoTrack(value): value is RemoteVideoTrack {
  return value.kind == 'video' && value.sid ? true : false;

}
export function isRemoteAudioTrack(value): value is RemoteAudioTrack {
  return value.kind == 'audio' && value.sid ? true : false;
}
export function isVideoTrack(value): value is VideoTrack {
  return isLocalVideoTrack(value) || isRemoteVideoTrack(value);
}
export function isAudioTrack(value): value is AudioTrack {
  return isLocalAudioTrack(value) || isRemoteAudioTrack(value);
}
export function isVideoTrackPublication(value): value is VideoTrackPublication {
  return isLocalVideoPublication(value) || isRemoteVideoPublication(value);
}
export function isAudioTrackPublication(value): value is AudioTrackPublication {
  return isLocalAudioPublication(value) || isRemoteAudioPublication(value);
}
