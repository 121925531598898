import {
  Asset,
  AssetId,
  AssetsFileProviderType,
  Bounds,
  OrAssetsFile,
  SectionLayer,
  Timeline,
} from './workflow.interfaces';
import { TrimmerData } from './trimmer.interfaces';

export interface TimelinesWithOptions {
  timelines: Timeline[];
  hardReload: boolean;
}

export interface Durations {
  main: number;
  intro: number;
  outro: number;
  total: number;
}

export enum TrimmerAction {
  EditSplits,
  EditTrim,
  AddClips,
}

export interface VideoLayerInfo {
  layerId: string;
  asset: Asset;
  assetFile: OrAssetsFile;
  startAt: number;
  duration: number;
  tooltip: string;
  bounds: Bounds;
}

// Events
export interface LayerIdEvent {
  layerId: string;
}

export interface SectionsIdEvent {
  sectionId: string;
}

export interface TrimmerEvent {
  trimmmerAction: TrimmerAction;
  timelineId: string;
  layerId: string;
}

export interface AddClipsEvent {
  timelineId: string;
  clips: NewClip[];
  replace: boolean;
}

export interface NewClip {
  source?: 'webcam' | 'screen';
  assetId?: string;
  assetFileId: AssetId;
  assetProviderType: AssetsFileProviderType;
  duration: number;
  name: string;
  trimFrom?: number;
  trimTo?: number;
  startAt?: number;
  endAt?: number;
  isPlaceholder?: boolean;
}

export interface StretchTextOverlayEvent extends LayerIdEvent {
  newStartAt: number;
  newEndAt: number;
}

export interface SectionsMovedEvent {
  sections: SectionsIdEvent[];
}

export interface SectionToggledEvent {
  isIntro: boolean;
  enabled: boolean;
}

export interface SectionDeletedEvent {
  layer: SectionLayer;
}

export type SectionEvent = {
  sectionId: string;
};

export interface ItemMovedEvent extends LayerIdEvent {
  newStartAt: number;
}

export interface ItemsMovedEvent {
  items: ItemMovedEvent[];
}
export interface SwapTimelinesEvent {
  previousTimelineId: string;
  currentTimelineId: string;
}

export interface ItemTimelineChangedEvent extends SwapTimelinesEvent {
  previousIndex: number;
  currentIndex: number;
}

export interface UpdateClipTrimsEvent {
  trimmerData: TrimmerData;
}

export interface UpdateClipSplitsEvent extends UpdateClipTrimsEvent {
  layoutId: number;
}

export interface UpdateBoundsEvent extends LayerIdEvent {
  bounds: Bounds;
  maintainAspectRatio: boolean;
}

export interface ResetBoundsEvent extends LayerIdEvent {
  defaultBounds: Bounds;
}

export interface ToggleLayerFitEvent extends LayerIdEvent {
  objectFit: 'contain' | 'cover';
}

export interface UpdateLayerFitPositionEvent extends LayerIdEvent {
  objectPosition: number[];
}

export interface UpdateAssetsPrimaryColor {
  color: string;
}
