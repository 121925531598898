import { Border, BorderRadius, GradientColor, SolidColor } from '../interfaces';

export function calculateStylesBackground(backgroundColor: SolidColor | GradientColor) {
  let cssBackground = 'none';
  if (backgroundColor?.type === 'solid') {
    cssBackground = backgroundColor.color;
  } else if (backgroundColor?.angle) {
    const colors = backgroundColor.colors.map((color) => `${color.color} ${color.stop}%`);
    cssBackground = `linear-gradient(${backgroundColor.angle}deg, ${colors.join(',')})`;
  }

  return { cssBackground };
}

export function calculateStylesBorderWidth(border: Border, hostWidth: number, hostHeight: number) {
  let cssBorderWidth: string;
  const borderWidthPx = { x: 0, y: 0 };
  if (border) {
    borderWidthPx.x = Math.trunc(border.x * (hostWidth / 100));
    borderWidthPx.y = Math.trunc(border.y * (hostHeight / 100));
    cssBorderWidth = `${borderWidthPx.x}px ${borderWidthPx.y}px`;
  }
  return { cssBorderWidth, borderWidthPx };
}

export function calculateStylesBorderRadius(
  borderRadius: BorderRadius[],
  borderWidthPx: { x: number; y: number },
  hostWidth: number,
  hostHeight: number
) {
  let cssBorderRadiusOuter: string;
  let cssBorderRadiusInner: string;
  if (borderRadius) {
    const borderRadiusPx = borderRadius.map((br) => ({
      x: (hostWidth * br.x) / 100,
      y: (hostHeight * br.y) / 100,
    }));

    cssBorderRadiusOuter = borderRadiusPx.map((br) => `${br.x}px`).join(' ');
    cssBorderRadiusOuter += ' / ';
    cssBorderRadiusOuter += borderRadiusPx.map((br) => `${br.y}px`).join(' ');

    cssBorderRadiusInner = borderRadiusPx.map((br) => `${Math.max(0, br.x - borderWidthPx.x)}px`).join(' ');
    cssBorderRadiusInner += ' / ';
    cssBorderRadiusInner += borderRadiusPx.map((br) => `${Math.max(0, br.y - borderWidthPx.y)}px`).join(' ');
  }

  return { cssBorderRadiusOuter, cssBorderRadiusInner };
}
