<div class="trimmer" *ngIf="isReady">
  <div class="frames">
    <img *ngFor="let frame of frames" src="{{ frame }}" />
  </div>
  <ng5-slider
    class="controls"
    [(value)]="minValue"
    [(highValue)]="maxValue"
    [options]="options"
    (highValueChange)="emitCurrentTrimming()"
    (valueChange)="emitCurrentTrimming()"
  ></ng5-slider>
</div>
