import { SchemaTransformation_0_2 } from './transformation_0_2/transformation_0_2';
import { SchemaTransformation_0_3 } from './transformation_0_3/transformation_0_3';
import { SchemaTransformation_0_4 } from './transformation_0_4/transformation_0_4';
import { SchemaTransformation_0_5 } from './transformation_0_5/transformation_0_5';
import { SchemaTransformation_0_6 } from './transformation_0_6/transformation_0_6';
import { SchemaTransformation_0_7 } from './transformation_0_7/transformation_0_7';
import { SchemaTransformation_0_8 } from './transformation_0_8/transformation_0_8';
import { SchemaTransformation_0_9 } from './transformation_0_9/transformation_0_9';
import { SchemaTransformation_1_0 } from './transformation_1_0/transformation_1_0';
import { SchemaTransformation_1_1 } from './transformation_1_1/transformation_1_1';
import { SchemaTransformation_1_2 } from './transformation_1_2/transformation_1_2';
import { SchemaTransformation_1_3 } from './transformation_1_3/transformation_1_3';
import { SchemaTransformation_1_4 } from './transformation_1_4/transformation_1_4';

export const SCHEMA_TRANSFORMATIONS = [
  SchemaTransformation_0_2,
  SchemaTransformation_0_3,
  SchemaTransformation_0_4,
  SchemaTransformation_0_5,
  SchemaTransformation_0_6,
  SchemaTransformation_0_7,
  SchemaTransformation_0_8,
  SchemaTransformation_0_9,
  SchemaTransformation_1_0,
  SchemaTransformation_1_1,
  SchemaTransformation_1_2,
  SchemaTransformation_1_3,
  SchemaTransformation_1_4,
];
