import { createAction, props } from '@ngrx/store';
import {
  DirectorUserDetails,
  ProjectRecordingsResponse,
  Recording,
} from '@openreel/common';

const context = '[Director]';

export const fetchDirectorUserDetails = createAction(
  `${context} Fetch User Details`
);
export const setDirectorUserDetails = createAction(
  `${context} Set User Details`,
  props<{ director: DirectorUserDetails }>()
);

export const fetchDirectorProjectRecordings = createAction(
  `${context} Fetch Project Recordings`,
  props<{
    projectId: number;
    page: number;
    perPage: number;
    append: boolean;
    sessionId?: number;
  }>()
);

export const setDirectorProjectRecordings = createAction(
  `${context} Set Project Recordings`,
  props<{ recordings: ProjectRecordingsResponse; append: boolean }>()
);

export const fetchDirectorArchivedRecordings = createAction(
  `${context} Fetch Archived Recordings`,
  props<{
    projectId: number;
    page: number;
    perPage: number;
    append: boolean;
    sessionId?: number;
  }>()
);

export const setDirectorProjectArchivedRecordings = createAction(
  `${context} Set Archived Recordings`,
  props<{ archivedRecordings: ProjectRecordingsResponse; append: boolean }>()
);

export const updateDirectorProjectRecordings = createAction(
  `${context} Update Project Recordings`,
  props<{ selectedRecordings: Recording[] }>()
);

export const fetchDirectorProjectFavouriteRecordings = createAction(
  `${context} Fetch Project Favourite Recordings`,
  props<{ projectId: number; sessionId?: number }>()
);

export const setDirectorProjectFavouriteRecordings = createAction(
  `${context} Set Project Favourite Recordings`,
  props<{ recordings: Recording[] }>()
);

export const archiveDirectorProjectRecordings = createAction(
  `${context} Archive Project Recordings`,
  props<{ recordings: Recording[] }>()
);

export const restoreDirectorProjectRecordings = createAction(
  `${context} Restore Project Recordings`,
  props<{ recordings: Recording[] }>()
);

export const restoreDirectorProjectRecordingsSuccess = createAction(
  `${context} Restore Project Recordings Success`,
  props<{ archivedRecordings: number[] }>()
);

export const deleteDirectorProjectRecordings = createAction(
  `${context} Delete Project Recordings`,
  props<{ recordings: Recording[] }>()
);

export const deleteDirectorProjectRecordingsSuccess = createAction(
  `${context} Delete Project Recordings Success`,
  props<{ recordings: number[] }>()
);

export const toggleDirectorProjectFavouriteRecording = createAction(
  `${context} Toggle Project Favourite Recording`,
  props<{ recording: Recording }>()
);

export const toggleDirectorProjectFavouriteRecordingSuccess = createAction(
  `${context} Toggle Project Favourite Recording Success`,
  props<{ recording: Recording }>()
);

export const updateDirectorProjectFavouriteRecording = createAction(
  `${context} Update Project Favourite Recording`,
  props<{ selectedRecordings: Recording[] }>()
);

export const updateDirectorProjectArchivedRecording = createAction(
  `${context} Update Project Archived Recording`,
  props<{ selectedRecordings: Recording[] }>()
);
