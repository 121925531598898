import { Cleanupable, RecordingState, RecordingType } from '@openreel/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SelfRecordOptions } from '../../store/interfaces/self-record.interfaces';
import { Subject, Subscription, timer } from 'rxjs';

import { SelfRecordClipsFacade } from '../../store/facades/self-record-clips.facade';
import { SelfRecordFacade } from '../../store/facades/self-record.facade';
import { SelfRecordStatus } from './../../store/interfaces/self-record.interfaces';
import { SelfRecordTeleprompterFacade } from '../../store/facades/self-record-teleprompter.facade';
import { humanDateDiff } from 'libs/common/src/date-utils';
import { takeUntil } from 'rxjs/operators';
import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';

@Component({
  selector: 'openreel-wf-self-record',
  templateUrl: './self-record.component.html',
  styleUrls: ['./self-record.component.scss'],
})
export class SelfRecordComponent
  extends Cleanupable
  implements OnInit, OnDestroy
{
  @Input() set selfRecordOptions(options: SelfRecordOptions) {
    if (options) this.selfRecordFacade.setOptions(options);
    this._selfRecordOptions = options;
  }
  get selfRecordOptions(): SelfRecordOptions {
    return this._selfRecordOptions;
  }
  private _selfRecordOptions: SelfRecordOptions;

  @Output() statusChange = new EventEmitter<SelfRecordStatus>();
  @Output() done = new EventEmitter();

  recordingTimer$ = new Subject<string>();
  RecordingState = RecordingState;
  source: RecordingType = 'both-pip';
  options: SelfRecordOptions;
  isStreamPlaying = false;

  private recordingSubscription: Subscription = new Subscription();
  private timerSubscription: Subscription = new Subscription();

  constructor(
    private readonly mixpanelService: MixpanelService,
    private readonly selfRecordClipsFacade: SelfRecordClipsFacade,
    private readonly selfRecordTeleprompterFacade: SelfRecordTeleprompterFacade,
    public readonly selfRecordFacade: SelfRecordFacade
  ) {
    super();
  }

  ngOnInit() {
    this.selfRecordFacade.init(this.selfRecordOptions);
    this.selfRecordFacade.requestDevicesList();
    this.selfRecordFacade.setOptions(this.selfRecordOptions);

    this.selfRecordFacade.options$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((options) => (this.options = options));

    this.selfRecordFacade.source$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((source) => (this.source = source));

    this.selfRecordFacade.status$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        if (status === 'preview') {
          this.isStreamPlaying = false;
        }

        return this.statusChange.emit(status);
      });

    this.recordingSubscription.add(
      this.selfRecordFacade.recordingStartedAt$.subscribe((startedAt) => {
        if (startedAt) {
          if (!this.timerSubscription) {
            this.timerSubscription = timer(0, 1000).subscribe(() => {
              const currentDate = new Date();
              const dateDiff = humanDateDiff(currentDate, startedAt);
              this.recordingTimer$.next(
                `${dateDiff.hours}:${dateDiff.minutes}:${dateDiff.seconds}`
              );
            });
          }
        } else {
          this.timerSubscription?.unsubscribe();
          this.timerSubscription = null;
          this.recordingTimer$.next(null);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.selfRecordFacade.cleanup();
    this.recordingSubscription.unsubscribe();
    this.timerSubscription?.unsubscribe();
  }

  toggleRecording() {
    this.selfRecordFacade.toggleRecording();
  }

  onSourceChange() {
    this.selfRecordFacade.setSource(this.source);
    this.isStreamPlaying = false;
  }

  useClips() {
    this.selfRecordClipsFacade.applySelfRecordLastClips();
    this.done.emit();
  }

  openTeleprompterDialog() {
    this.mixpanelService.logEvent(
      'self_record_open_teleprompter',
      'Self record teleprompter opened'
    );

    this.selfRecordTeleprompterFacade.openTeleprompterDialog();
  }

  retake() {
    this.selfRecordFacade.reset();

    this.mixpanelService.logEvent(
      'self_record_retake',
      'Retake self recording'
    );
  }

  allowedSources(): RecordingType[] {
    if (this.selfRecordOptions.allowedSources?.length > 0) {
      return this.selfRecordOptions.allowedSources;
    }

    return ['screen', 'camera', 'both-pip'];
  }
}
