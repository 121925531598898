import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { clamp } from 'lodash-es';
import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';

const ZOOM_STEP = 0.01;
const MAX_ZOOM = 0.1;

export interface Viewport {
  from: number;
  to: number;
}

const DEFAULT_VALUE: Viewport = {
  from: 0,
  to: 1,
};

@Injectable()
export class ViewportService {
  private state: Viewport = DEFAULT_VALUE;

  private viewport = new BehaviorSubject<Viewport>(DEFAULT_VALUE);
  viewport$ = this.viewport.asObservable();

  constructor(
    private readonly mixpanelService: MixpanelService
  ) {}

  setViewport(from: number, to: number) {
    if (Math.abs(to - from) < MAX_ZOOM) {
      this.viewport.next(this.state);
    }

    this.updateViewport({
      from: clamp(from, 0, 1),
      to: clamp(to, 0, 1),
    });
  }

  scrollDelta(delta: number) {
    const newDelta = this.clampScroll(delta, this.state.from, this.state.to);

    this.updateViewport({
      from: this.state.from + newDelta,
      to: this.state.to + newDelta,
    });
  }

  zoom(delta: 1 | -1, targetPoint?: number) {
    let { from: newFrom, to: newTo } = this.clampZoom(
      this.state.from,
      this.state.to,
      delta,
      ZOOM_STEP,
      MAX_ZOOM
    );

    if (!isNaN(targetPoint) && targetPoint !== null) {
      const centerViewport = newFrom + (newTo - newFrom) / 2;
      const clampedDelta = this.clampScroll(
        targetPoint - centerViewport,
        newFrom,
        newTo
      );
      newFrom += clampedDelta;
      newTo += clampedDelta;
    }

    this.updateViewport({
      from: newFrom,
      to: newTo,
    });
  }

  private updateViewport(newValue: Viewport) {
    this.mixpanelService.logEvent(
      'timelines_zoom',
      'Timelines zoom'
    );

    this.state = newValue;
    this.viewport.next(this.state);
  }

  private clampZoom(
    from: number,
    to: number,
    delta: number,
    step: number,
    maxZoom: number
  ) {
    const newFrom = clamp(delta < 0 ? from + step : from - step, 0, 1);
    const newTo = clamp(delta < 0 ? to - step : to + step, 0, 1);

    if (newFrom > newTo) {
      return { from, to };
    }

    if (Math.abs(newTo - newFrom) < maxZoom) {
      return { from, to };
    }

    return { from: newFrom, to: newTo };
  }

  private clampScroll(scrollDelta: number, from: number, to: number) {
    let finalDelta = scrollDelta;
    if (finalDelta < 0 && from + finalDelta < 0) {
      finalDelta = -from;
    } else if (finalDelta > 0 && to + finalDelta > 1) {
      finalDelta = 1 - to;
    }

    return finalDelta;
  }
}
