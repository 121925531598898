import { DragDropModule } from '@angular/cdk/drag-drop';
import { OpenreelLayersModule } from '@openreel/ui/openreel-layers';
import { NgModule } from '@angular/core';
import { SharedModule } from '@openreel/creator/app/shared/shared.module';
import { components } from './components';
import { pipes } from './pipes';
import { TimelinesCanvasModule } from '@openreel/creator/app/common/timelines-canvas/timelines-canvas.module';
import { OpenreelLoaderModule } from '@openreel/ui/openreel-loader';
import { OpenreelCommonModule } from '@openreel/common';

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    TimelinesCanvasModule,
    SharedModule,
    OpenreelLayersModule,
    DragDropModule,
    OpenreelLoaderModule,
    OpenreelCommonModule,
  ],
  providers: [],
  exports: [...components],
})
export class TimelinesModule {}
