import { BaseCommand } from './base.command';
import { SectionLayer } from '../interfaces';
import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

export class DeleteSceneCommand extends BaseCommand<TimelineInterfaces.SectionDeletedEvent> {
  run({ layer }: TimelineInterfaces.SectionDeletedEvent) {
    const assetIds = this.getSectionAssetIds(layer.sectionId);
    this.removeAssets(assetIds);

    delete this.source.sections[layer.sectionId];

    this.source.timelines.forEach((t) => {
      t.layers = t.layers.filter((l: SectionLayer) => l.sectionId !== layer.sectionId);
    });

    return this.ok();
  }
}
