import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Cleanupable } from '@openreel/common/classes';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface OpenreelDateRange {
  start?: Date;
  end?: Date;
}

@Component({
  selector: 'openreel-date-range',
  templateUrl: './openreel-date-range.component.html',
})
export class OpenreelDateRangeComponent extends Cleanupable implements OnInit {
  @Input() appearance: MatFormFieldAppearance;
  @Input() noLabel = false;
  @Input() set range(range: OpenreelDateRange) {
    if (range?.start !== this.rangeGroup.controls.start.value || range?.end !== this.rangeGroup.controls.end.value) {
      this.rangeGroup.setValue({ ...range });
    }
  }
  @Output() rangeChange = new EventEmitter<OpenreelDateRange>();

  rangeGroup = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  today = new Date();

  ngOnInit() {
    this.rangeGroup.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100))
      .subscribe((value) => this.rangeChange.emit(value));
  }
}
