<div fxLayout="row" fxLayoutAlign="center center" class="screen-overlay">
  <mat-card class="content-holder">
    <mat-card-header>
      <mat-card-title>
        <img src="assets/common/wordmarks/blue-gradient.svg" class="logo">
      </mat-card-title>
      <mat-card-title class="loading-animation" *ngIf="!error">
        <img src="assets/common/images/webcam-loading.svg" height="50px">
      </mat-card-title>
      <mat-card-title *ngIf="!error">{{ error ? "" : "Loading" }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <span [class.color-warn]="error">{{ error ? error.message : message }}</span>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-around">
      <span *ngIf="error">
        <button mat-raised-button color="primary" (click)="retry()" class="generic-loading-retry">Retry</button>
      </span>
      <span *ngIf="error">
        <button mat-raised-button (click)="home()">Home</button>
      </span>
    </mat-card-actions>
  </mat-card>
</div>
