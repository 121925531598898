/* eslint-disable @typescript-eslint/no-explicit-any */
import { PresetField } from '@openreel/creator/common';
import { TransformerBase } from './transformer.base';

export class TransformerImage extends TransformerBase {
  test = (fieldDef: PresetField) => fieldDef.type === 'logo';

  process = (animation: any, fieldDef: PresetField, fieldValues: any) => {
    if (!fieldDef.lottieAssetRefId) {
      return animation;
    }

    const asset = animation.assets.find((a) => a.id === fieldDef.lottieAssetRefId);
    asset.p = fieldValues?.url;
    return animation;
  };
}
