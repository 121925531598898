import { cloneDeep } from 'lodash';
import { getLayers } from '../../helpers';
import { SectionLayer, TimelinesLayer, WorkflowDataDto } from '../../interfaces';
import { SchemaTransformation } from '../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_1 } from './schema_before';
import { WorkflowDataDto_0_2 } from './schema_after';

function addHasAudio(schema: WorkflowDataDto_0_1.WorkflowDataDto) {
  const mainTimeline = schema.timelines.find((t) => t.type === 'main');
  const timelinesWithAudio = [];
  switch (schema.type) {
    case 'standard':
    case 'podcast':
      for (const layer of mainTimeline.layers) {
        const sectionLayer = layer as SectionLayer;
        timelinesWithAudio.push(schema.sections[sectionLayer.sectionId].timelines.filter((t) => t.type === 'main'));
      }
      break;
    case 'presentation':
      for (const layer of mainTimeline.layers) {
        const sectionLayer = layer as SectionLayer;
        if (sectionLayer.sectionId === 'main') {
          const timelinesLayer = schema.sections[sectionLayer.sectionId].timelines.find((t) => t.type === 'main')
            .layers[0] as TimelinesLayer;
          timelinesWithAudio.push(timelinesLayer.children[0]);
        } else {
          timelinesWithAudio.push(schema.sections[sectionLayer.sectionId].timelines.filter((t) => t.type === 'main'));
        }
      }
      break;
  }

  timelinesWithAudio.forEach((t) => (t.hasAudio = true));

  return schema;
}

function addIsLayerSelectable(schema: WorkflowDataDto_0_1.WorkflowDataDto) {
  for (const layerInfo of getLayers(schema as unknown as WorkflowDataDto)) {
    const layer = layerInfo.layer as WorkflowDataDto_0_1.Layer;
    if (!layer.selection) {
      continue;
    }

    const isLayerSelectable = layer.selection.selectable;
    delete layer.selection;

    if (isLayerSelectable) {
      layerInfo.timeline.isLayerSelectable = isLayerSelectable;
    }
  }
}

export const SchemaTransformation_0_2: SchemaTransformation = {
  version: '0.2',

  run(oldSchema: WorkflowDataDto_0_1.WorkflowDataDto): WorkflowDataDto_0_2.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema);
    addHasAudio(newSchema);
    addIsLayerSelectable(newSchema);

    return newSchema as WorkflowDataDto_0_2.WorkflowDataDto;
  },
};
