import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  UserListRequest,
  UserListResponse,
  CreateTeamRequest,
  TeamResponse,
  TeamListRequest,
  TeamListResponse,
  TeamDetailsResponse,
  MyTeam,
  TeamUserResponse,
} from '../../interfaces/team.interface';
@Injectable({
  providedIn: 'root',
})
export class TeamService {
  public urls = {
    getuser: commonenv.nextGenApiUrl + 'user/list',
    createTeam: commonenv.nextGenApiUrl + 'teams/create',
    teamList: commonenv.nextGenApiUrl + 'teams/list',
    myTeams: commonenv.nextGenApiUrl + 'teams/mine',
    teamUsers: commonenv.nextGenApiUrl + 'teams/users',
    getTeam: commonenv.nextGenApiUrl + 'teams',
    updateTeam: commonenv.nextGenApiUrl + 'teams/update',
    deleteTeam: commonenv.nextGenApiUrl + 'teams/delete',
  };
  constructor(public httpClient: HttpClient) {}
  getUserList(data: UserListRequest) {
    let userlistUrl =
      this.urls.getuser +
      '?limit=' +
      data.limit +
      '&team_id=' +
      data.team_id +
      '&page=' +
      data.page;
    if (data.search_string) {
      userlistUrl = userlistUrl + '&search_string=' + data.search_string;
    }
    return this.httpClient.get<UserListResponse>(userlistUrl);
  }
  createTeam(data: CreateTeamRequest) {
    return this.httpClient.post<TeamResponse>(this.urls.createTeam, data);
  }
  getTeamList(data: TeamListRequest) {
    const teamlistUrl =
      this.urls.teamList +
      '?limit=' +
      data.limit +
      '&page=' +
      data.page +
      '&team_name=' +
      data.team_name;
    return this.httpClient.get<TeamListResponse>(teamlistUrl);
  }

  getMyTeams() {
    return this.httpClient.get<MyTeam[]>(this.urls.myTeams);
  }

  getTeamUsers(limit: number, page: number, query: string) {
    return this.httpClient.get<TeamUserResponse>(
      this.urls.teamUsers, {
          params:{
            limit,
            page,
            query: encodeURIComponent(query)
          }
        }
    );
  }

  getTeamDetails(id: number) {
    const teamdetailsUrl = this.urls.getTeam + '/' + id;
    return this.httpClient.get<TeamDetailsResponse>(teamdetailsUrl);
  }
  updateTeam(data: CreateTeamRequest) {
    const teamupdateUrl = this.urls.updateTeam + '/' + data.id;
    return this.httpClient.put<TeamResponse>(teamupdateUrl, data);
  }
  deleteTeam(id: number) {
    const teamdeleteUrl = this.urls.updateTeam + '/' + id;
    return this.httpClient.delete<TeamResponse>(teamdeleteUrl);
  }
}
