import { Component, Input } from '@angular/core';
import { environment } from '../../environments/environment';
import { MemberImg } from '../../interfaces/interfaces';
import { AuthService } from '../../../../common/src/services/auth/auth.service';
@Component({
  selector: 'openreel-team-members',
  templateUrl: './orteammember.component.html',
  styleUrls: ['./orteammember.component.css'],
})
export class OrTeamMembersComponent {
  @Input() memberList: Array<MemberImg> = [];
  baseurl: string;
  constructor(private authService: AuthService) {
    const cookieData = JSON.parse(this.authService.getCookie());
    if(cookieData) {
      this.baseurl = environment.assetsUrl + cookieData?.data.site_user_slug + '/';
    }
  }
}
