import { property } from 'lodash';
import { PresetField } from '@openreel/creator/common';

/* eslint-disable @typescript-eslint/no-explicit-any */
export abstract class TransformerBase {
  abstract test: (fieldDef: PresetField) => boolean;
  abstract process: (animation: any, fieldDef: PresetField, fieldValues: any) => any;

  protected getRootNode(animation: any, path: string) {
    const pathFn = property(path);
    return pathFn(animation) as any;
  }
}
