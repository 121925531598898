import { Component, Inject, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormGroupDirective, FormControl, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HostablePermissionTypes, HostableTypes } from '../../constants';
import { HostingService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';

class SlugErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form.touched);
  }
}

@Component({
  selector: 'openreel-create-hostable',
  templateUrl: './create-hostable.component.html',
  styleUrls: ['./create-hostable.component.scss'],
})
export class CreateHostableComponent implements OnInit {
  isLightThemed = true;
  isPopup = true;
  createForm: FormGroup;
  errorMatcher = new SlugErrorStateMatcher();
  saving = false;

  constructor(
    public dialogRef: MatDialogRef<CreateHostableComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; type: HostableTypes; title: string },
    private fb: FormBuilder,
    private hostingService: HostingService,
    private toastr: ToastrService
  ) {
    this.createForm = this.buildForm(this.data.title);
  }

  ngOnInit() {
    this.createForm.get('title').valueChanges.subscribe((title) => {
      if (this.createForm.get('slug').pristine) {
        this.createForm.get('slug').setValue(title.trim());
      }
    });
  }

  private buildForm(title: string) {
    return this.fb.group({
      title: [title, [Validators.required, Validators.maxLength(100)]],
      slug: [title, [Validators.required, Validators.maxLength(100)]],
      permission: [HostablePermissionTypes.Private, Validators.required],
      description: ['', Validators.maxLength(1000)],
      altText: ['', Validators.maxLength(100)],
    });
  }

  create() {
    if (this.createForm.valid) {
      this.saving = true;
      this.hostingService
        .hostVideo(this.data.id, this.data.type, {
          ...this.createForm.value,
        })
        .subscribe(
          (res) => this.dialogRef.close(res),
          (err: HttpErrorResponse) => this.toastr.error(err.message, 'Error!'),
          () => (this.saving = false)
        );
    }
  }
}
