import { BaseCommand } from './base.command';
export interface CaptionsToggleBurnInEvent {
  burnIn: boolean;
}
export interface CaptionsToggleUpToDateEvent {
  upToDate: boolean;
}
export class CaptionsToggleBurnInCommand extends BaseCommand<CaptionsToggleBurnInEvent> {
  run({ burnIn }: CaptionsToggleBurnInEvent) {
    this.source.features.captions.burnIn = burnIn;
    return this.ok();
  }
}
export class CaptionsToggleUpToDateCommand extends BaseCommand<CaptionsToggleUpToDateEvent> {
  run({ upToDate }: CaptionsToggleUpToDateEvent) {
    this.source.features.captions.upToDate = upToDate;
    return this.ok();
  }
}
