import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_2 } from '../transformation_0_2/schema_after';
import { WorkflowDataDto_0_3 } from './schema_after';

function addSchemaType(schema: WorkflowDataDto_0_3.WorkflowDataDto) {
  schema.timelines.forEach((timeline) => {
    timeline.layers.forEach((layer) => {
      if (layer.type === 'section') {
        layer.sectionType = layer.sectionId;
      }
    })
  })
}

export const SchemaTransformation_0_3: SchemaTransformation = {
  version: '0.3',

  run(oldSchema: WorkflowDataDto_0_2.WorkflowDataDto): WorkflowDataDto_0_3.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_0_3.WorkflowDataDto;
    addSchemaType(newSchema);
    return newSchema as WorkflowDataDto_0_3.WorkflowDataDto;
  },
};
