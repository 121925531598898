import { Timeline } from '../interfaces';
import { BaseCommand } from './base.command';

export interface IntroOutroEnableEvent {
  sectionId: 'intro' | 'outro';
  enabled: boolean;
}

export class IntroOutroEnableCommand extends BaseCommand<IntroOutroEnableEvent> {
  run({ sectionId, enabled }: IntroOutroEnableEvent) {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');

    this.toggleSection(sectionId, enabled);

    if (sectionId === 'outro') {
      this.toggleOutroLayerTransition(mainTimeline, enabled);
    }

    return this.ok();
  }

  private toggleOutroLayerTransition(mainTimeline: Timeline, enabled: boolean) {
    let lastMainSectionIndex: number;
    mainTimeline.layers.forEach((layer, index) => {
      if (layer.type === 'section' && this.source.sections[layer.sectionId].sectionType === 'main') {
        lastMainSectionIndex = index;
      }
    });

    if (lastMainSectionIndex && mainTimeline.layers[lastMainSectionIndex].transitions?.crossLayer?.layer) {
      mainTimeline.layers[lastMainSectionIndex].transitions.crossLayer.layer.enabled = enabled;
    }
  }
}
