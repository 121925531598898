export enum HostableTypes {
  WorkflowVideo = 'workflow_video',
  CaptureVideo = 'video',
  CaptureVideoChild = 'video_child',
  UserUpload = 'user_upload',
  Webinar = 'webinar',
}

export enum HostablePermissionTypes {
  Public = 'public',
  Private = 'private',
}

export enum HostableVideoStatuses {
  NotStarted = 'not_started',
  Rendering = 'rendering',
  Queued = 'queued',
  Done = 'done',
  Failed = 'failed',
  Canceled = 'cancelled',
}

export const CAPTION_LANGUAGE_MAP = {
  'en-US': 'English',
  'es-ES': 'Spanish',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'fr-FR': 'French',
  'hi-IN': 'Hindi',
  'zh-CN': 'Mandarin',
};

export enum HostableCtaType {
  TextButton = 'textButton',
  ImageButton = 'imageButton',
}