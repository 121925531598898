<div
  class="video-wrapper"
  [class.fullscreen]="isFullscreen"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
>
  <div *ngIf="!enabledVideo" class="no-video">
    <div class="no-video-text">
      <mat-icon color="accent" svgIcon="videocam-off"></mat-icon>
      <span> No Video</span>
    </div>
  </div>
  <video
    [class.hidden]="!enabledVideo"
    [class.half-border-curved]="halfBorderCurved && !isFullscreen"
    [style.borderRadius.px]="borderRadius"
    #videoElement
    autoplay
    [muted]="true"
  ></video>
  <div
    class="controls"
    fxLayout="row"
    *ngIf="(isMouseInside || isFullscreen) && enabledVideo"
  >
    <button mat-icon-button (click)="toggleFullscreen()">
      <mat-icon>{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>
  </div>
  <button mat-raised-button *ngIf="isLive" color="warn" class="live-btn">Live</button>
</div>
