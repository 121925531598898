/// <reference types="dom-mediacapture-record" />

import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { DBSchema, deleteDB, IDBPDatabase, openDB } from 'idb';
// import { Subject } from 'rxjs';
import { getOriginalSeekableBlob } from '../../../utils';
import {
  ILocalRecorderService,
  RecordingState,
} from './local-recorder-base.service';

export declare interface MediaRecorderDataAvailableEvent extends Event {
  data: Blob;
}

// This is needed for ts-ebml to work
(window as Window & typeof globalThis & { Buffer: typeof Buffer }).Buffer =
  Buffer;

interface ChunkRecordingSchema extends DBSchema {
  chunks: {
    key: number;
    value: Blob;
  };
}

const DB_NAME_PREFIX = 'recording-chunk-';
const DB_VERSION = 1;
const TIMESLICE = 2000;
const MIN_CHUNK_SIZE = 5 * 1024 * 1024;
const BITS_PER_RESOLUTION_PER_FPS = 250;
const MINIMUM_BITRATE = BITS_PER_RESOLUTION_PER_FPS * 720 * 30; // equivalent to the calculated bitrate of 720p 30 fps
/************************************************/
// DON'T Add space to the name, don't use = instead of :
/************************************************/
const MIME_TYPE_VIDEO_WEBM_VP8 = 'video/webm;codecs:vp8,opus';
const MIME_TYPE_VIDEO_WEBM_VP9 = 'video/webm;codecs:vp9,opus';
const MIME_TYPE_VIDEO_WEBM_H264 = 'video/webm;codecs:h264,opus';
const MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX = 'video/webm;codecs:avc1';
const MIME_TYPE_VIDEO_WEBM_AVC1_CHROME = 'video/webm;codecs=avc1';

// for UGC mobile
const MIME_TYPE_VIDEO_MP4 = 'video/mp4';
/**
 * This is the active local recorder now
 */
@Injectable()
export class LocalRecorderService extends ILocalRecorderService {
  private mediaRecorder = new Map<number, MediaRecorder>(); //multiple recording (Webcam & screen)
  private partSize = new Map<number, number>();
  private parts = new Map<number, BlobPart[]>();
  private db = new Map<number, IDBPDatabase<ChunkRecordingSchema>>();

  // stop$ = new Subject<boolean>(); // why is this exposed or defined here

  private preferedCodec = this.getBestCodec();
  constructor() {
    super();
  }
  private getBestCodec() {
    return MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_AVC1_CHROME)
      ? MIME_TYPE_VIDEO_WEBM_AVC1_CHROME
      : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX)
      ? MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX
      : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_VP8)
      ? MIME_TYPE_VIDEO_WEBM_VP8
      : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_MP4)
      ? MIME_TYPE_VIDEO_MP4
      : null;
  }

  private get isRecording() {
    let isRecording = false;
    for (const mediaRecorder of this.mediaRecorder.values()) {
      if (mediaRecorder.state === 'recording') isRecording = true;
    }
    return isRecording;
  }

  // eslint-disable-next-line max-lines-per-function
  public doStartRecording() {
    throw new Error('not implemented');
  }

  public async doStartRecordingStream(
    videoId: number,
    stream: MediaStream,
    filename = ''
  ) {
    const settings = stream.getVideoTracks()[0].getSettings();
    const videoBitsPerSecond = Math.max(
      BITS_PER_RESOLUTION_PER_FPS * (settings.height ?? 720) * (settings.frameRate ?? 24),
      MINIMUM_BITRATE
    );
    console.log(
      'Effective recording resolution/fps/videoBitsPerSecond: ' +
        settings.height +
        '/' +
        settings.frameRate +
        '/' +
        videoBitsPerSecond
    );

    this.newVideoInfo = {
      bit_rate: `${videoBitsPerSecond}`,
      device_name: `${settings.deviceId}`,
      fps: `${settings.frameRate}`,
      resolution: `${settings.height}`,
      identity: `web_${settings.deviceId}`,
      video_name: filename,
      admin_ovra_id: 0,
      video_type: 1,
    };

    const options: MediaRecorderOptions = {
      videoBitsPerSecond,
      audioBitsPerSecond: 128 * 1000, // The maximum is 128,000 bps
      mimeType: this.preferedCodec,
    };
    const mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.set(videoId, mediaRecorder);

    mediaRecorder.onstop = () => {
      this.handleStop(videoId);

      /**
       * Total mess
       * Need timeout to ensure this happens LAST, should clean this up
       */
      setTimeout(
        () => this.stopSource.next({ value: true, videoId: videoId }),
        1000
      );
      // this.stopSource.next(true);
    };
    mediaRecorder.onerror = (err) =>
      this.handleError(err as MediaRecorderErrorEvent, videoId);
    mediaRecorder.ondataavailable = (evt) =>
      this.handleDataAvailable(evt, filename, videoId);
    mediaRecorder.onstart = () => this.handleStart(videoId);

    this.closeDB(videoId);

    const db = await this.initDbForRecordingChunk(filename);
    this.db.set(videoId, db);
    mediaRecorder.start(TIMESLICE);
  }

  private handleStart(videoId) {
    this.recordingState$.next(RecordingState.RECORDING);
    this.chunkReset();
  }

  private async initDbForRecordingChunk(
    filename = ''
  ): Promise<IDBPDatabase<ChunkRecordingSchema>> {
    return await openDB(DB_NAME_PREFIX + filename, DB_VERSION, {
      upgrade: (db) => {
        db.createObjectStore('chunks' as unknown as never, {
          autoIncrement: true,
        });
        console.log('Database upgraded for ' + filename);
      },
    });
  }

  private closeDB(videoId: number) {
    if (this.db.get(videoId)) {
      const db = this.db.get(videoId);
      db.close();
      this.db.delete(videoId);
    }
  }

  async handleDataAvailable(
    evt: MediaRecorderDataAvailableEvent,
    filename = '',
    videoId: number
  ) {
    const partSize = this.partSize.get(videoId) || 0;
    const parts = this.parts.get(videoId) || [];
    this.partSize.set(videoId, partSize + evt.data.size);
    this.parts.set(videoId, [...parts, evt.data]);
    const isRecording = this.mediaRecorder.get(videoId)?.state === 'recording';
    if (this.partSize.get(videoId) >= MIN_CHUNK_SIZE || !isRecording) {
      const bigBlob = new Blob(this.parts.get(videoId), {
        type: evt.data.type,
      });
      this.partSize.set(videoId, 0);
      this.parts.set(videoId, []);
      try {
        const db = this.db.get(videoId);
        await db.put('chunks' as unknown as never, bigBlob);
      } catch (e) {
        console.error(e);
        if (
          ['QuotaExceededError', 'DataError'].includes(e.name) &&
          this.mediaRecorder.get(videoId)?.state !== 'inactive'
        ) {
          this.doStopRecording(videoId);
          this.stopRecordingOnError.next('run out of storage');
        }
      }
      this.chunkAvailableSource.next({
        localFileName: filename,
        data: bigBlob,
        videoId: videoId,
      });
      if (!isRecording) {
        this.partsStopSource.next({ value: true, videoId: videoId });
      }
    }
  }

  handleStop(videoId: number) {
    this.recordingState$.next(RecordingState.IDLE);
    this.closeDB(videoId);
  }

  private handleError(err: MediaRecorderErrorEvent, videoId: number) {
    this.handleStop(videoId);
    console.error('Error during recording: ');
    console.log(err);
    throw new Error(JSON.stringify(err));
  }

  getNewFileName(fileNameAppend: string = ''): string {
    return new Date().getTime().toString() + '_' + fileNameAppend;
  }

  async doStopRecording(videoId = -1) {
    if (videoId === -1) {
      for (const mediaRecorder of this.mediaRecorder.values()) {
        mediaRecorder.stop();
      }
    } else {
      this.mediaRecorder.get(videoId)?.stop();
    }
  }

  async getFileSizeMB(): Promise<number> {
    return 0;
  }

  async getFileLengthSeconds(): Promise<number> {
    return -1;
  }

  async getFileData(localFileName: string): Promise<Blob> {
    const db = await openDB<ChunkRecordingSchema>(
      DB_NAME_PREFIX + localFileName,
      DB_VERSION
    );
    const allChunks = await db.getAll('chunks' as unknown as never);
    db.close();
    try {
      const ret = await getOriginalSeekableBlob(allChunks);
      return ret.refinedBlob;
    } catch (err) {
      console.log(err);
    }
    console.warn('Sending non-seekable video');
    const codecs = this.getBestCodec()?.split(';');
    const blobType = codecs?.length ? codecs[0] : null;
    const ret = new Blob(allChunks, { type: blobType });
    return ret;
  }
  async removeFileData(localFileName: string) {
    try {
      await deleteDB(DB_NAME_PREFIX + localFileName);
    } catch (err) {
      console.warn('Error deleting database for ' + localFileName);
    }
  }

  getFileNameForUpload(): string {
    return this.lastFileName;
  }
}
