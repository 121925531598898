import { Injectable, RendererFactory2 } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { UploaderBaseService } from '@openreel/ui/openreel-uploader/openreel-uploader.component';
import { UserUploadService } from './user-upload.service';
import { catchError, distinctUntilChanged, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { defer, EMPTY, forkJoin, isObservable, merge, of, throwError } from 'rxjs';
import { UserUploadStatus } from '../constants';
import { UserUploadCredentials } from '../interfaces';
import { MultiPartUploader } from '../../services/upload/multi-part-uploader';
import { UploadDetailsResponse } from '../../services/upload/dto/upload.dto';
import { UserUploadFileValidatorService } from './user-upload-file-validator.service';
import { FileSelectorService } from '../../services/file-selector/file-selector.service';
import { AuthService } from '../../services/auth/auth.service';

interface UserUploadState {
    loaded: number;
    total: number;
    name: string;
    id: number;
    state: UserUploadStatus;
    canceled?: boolean;
    uploader?: MultiPartUploader;
    isLocal?: boolean;
}

interface UploadViewState {
    [id: number]: UserUploadState;
}

@Injectable({providedIn: 'root'})
export class UserUploadStateService extends UploaderBaseService<UserUploadCredentials> {
    private hasUploadsInProgress = false;

    private windowCloseRequested(event) {
        // warn user if uploads in progress
        if (this.hasUploadsInProgress) {
            event.preventDefault();
            event.returnValue = 'Closing the window will cause your uploads to fail. Proceed?';
        }
    }

    private windowClosed() {
        // try to fail and abort all in progress uploads
        this.uploadsInProgress$.pipe(
            first(),
            switchMap(uploads => uploads.length
                ? forkJoin(uploads.map(upload => this.failAndAbort(upload.id).pipe(catchError(() => EMPTY))))
                : of([])),
            tap(() => this.uploadStore.setState({})),
        ).subscribe();
    }

    private handleStorageMessage(event: StorageEvent) {
        if (event.key !== 'upload-message' || !event.newValue)
            return;
        const uploadState: Partial<UserUploadState> = JSON.parse(event.newValue);
        this.upsertState({...uploadState, isLocal: false, uploader: undefined});
    }

    private sendStorageMessage(uploadState: Partial<UserUploadState>) {
        localStorage.setItem('upload-message', JSON.stringify({...uploadState, uploader: undefined, isLocal: false}));
        localStorage.removeItem('upload-message');
    }

    private uploadStore = new ComponentStore<UploadViewState>({});
    private uploadsInProgress$ = this.uploadStore.state$.pipe(
        map(state => Object.values(state).filter(({state, canceled, isLocal}) =>
            isLocal && state === UserUploadStatus.Uploading && !canceled)),
    );

    private upsertState = this.uploadStore.updater((state, upload: Partial<UserUploadState>) => ({
        ...state,
        [upload.id]: {
            ...state[upload.id],
            ...upload,
        }
    }));

    private uploadStarted(uploadState: UserUploadState) {
        this.upsertState({...uploadState, isLocal: true});
        this.sendStorageMessage({...uploadState, uploader: undefined});
    }

    private updateProgress(id: number, loaded: number, total: number) {
        this.upsertState({id, loaded, total, isLocal: true});
        this.sendStorageMessage({id, loaded, total});
    }

    private uploadComplete(id: number) {
        this.upsertState({id, state: UserUploadStatus.Complete, uploader: undefined, isLocal: true});
        this.sendStorageMessage({id, state: UserUploadStatus.Complete});
    }

    private uploadFailed(id: number) {
        this.upsertState({id, state: UserUploadStatus.Failed, isLocal: true});
        this.sendStorageMessage({id, state: UserUploadStatus.Failed});
    }

    private uploadUploading(id: number) {
        this.upsertState({id, state: UserUploadStatus.Uploading, isLocal: true});
        this.sendStorageMessage({id, state: UserUploadStatus.Uploading});
    }

    private uploadCanceled(id: number) {
        this.upsertState({id, canceled: true, uploader: undefined, isLocal: true});
        this.sendStorageMessage({id, canceled: true});
    }

    getUploadState(id: number) {
        return this.uploadStore.select(state => state[id]).pipe(
            takeWhile(uploadState => uploadState?.state !== UserUploadStatus.Complete && !uploadState?.canceled, true),
        );
    }

    constructor(
        private userUploadService: UserUploadService,
        private rendererFactory: RendererFactory2,
        private fileValidatorService: UserUploadFileValidatorService,
        private fileSelectorService: FileSelectorService,
        private authService: AuthService,
    ) {
        super();
        this.setupUnloadListeners();
    }

    private setupUnloadListeners() {
        const renderer = this.rendererFactory.createRenderer(null, null);
        renderer.listen('window', 'beforeunload', (event) => this.windowCloseRequested(event));
        renderer.listen('window', 'unload', () => this.windowClosed());
        renderer.listen('window', 'storage', (event: StorageEvent) => this.handleStorageMessage(event));
        // store so we can access synchronously in beforeunload
        this.uploadsInProgress$.subscribe(uploadsInProgress => {
            this.hasUploadsInProgress = !!uploadsInProgress.length;
            if (this.hasUploadsInProgress) {
                this.authService.setBusy(UserUploadStateService.name);
            } else {
                this.authService.setIdle(UserUploadStateService.name);
            }
        });

        this.authService.logout$.subscribe(() => this.windowClosed());
    }

    private reselectFile(uploadId: number) {
        return this.userUploadService.getUserUpload(uploadId, true).pipe(
            switchMap(upload => {
                if (upload.state === UserUploadStatus.Complete) {
                    // corner case if the upload completed but the render request failed
                    this.uploadStarted({loaded: 1, total: 1, id: uploadId, state: UserUploadStatus.Complete, name: upload.name});
                    return of(true);
                }

                return this.fileSelectorService.selectFile('.mp4,.webm,.mov').pipe(
                    switchMap(file => {
                        if (!file)
                            return EMPTY;
                        const validate = this.fileValidatorService.validate(file);
                        return (isObservable(validate) ? validate : of(validate)).pipe(
                            switchMap(validationError => {
                                if (validationError) {
                                    return throwError(validationError);
                                }
                                return this.upload(upload.uploadCredentials, file, uploadId);
                            }),
                        );
                    }),
                );
            }),
        );
    }

    retryUpload(uploadId: number) {
        return this.getUploadState(uploadId).pipe(
            first(),
            switchMap(uploadState => {
                // no upload state or not local means this upload started in another session. Need to reselect file
                if (!uploadState || !uploadState.isLocal) {
                    return this.reselectFile(uploadId);
                }
                if (uploadState.state !== UserUploadStatus.Failed || uploadState.canceled)
                    return throwError('Upload has not failed or was canceled');
                this.uploadUploading(uploadId);
                // this means the complete marking or render request failed, just try to mark it again
                if (uploadState.uploader.completed) {
                    return this.markComplete(uploadId);
                } else {
                    // the upload actually failed, restart it
                    uploadState.uploader.retryUpload();
                    return of(true);
                }
            }),
        );
    }

    private abortUpload(uploadId: number) {
        return this.getUploadState(uploadId).pipe(
            first(),
            switchMap((uploadState) => {
                if (uploadState 
                    && uploadState.state !== UserUploadStatus.Complete 
                    && !uploadState.canceled
                    && uploadState.uploader
                    && !uploadState.uploader.aborted
                    && !uploadState.uploader.completed) {
                    uploadState.uploader.abortUpload();
                    return uploadState.uploader.aborted$;
                }
                return of(true);
            }),
        );
    }

    cancelUpload(uploadId: number) {
        return forkJoin([
            this.userUploadService.deleteUserUpload(uploadId),
            this.abortUpload(uploadId),
        ]).pipe(tap(() => this.uploadCanceled(uploadId)));
    }

    failAndAbort(uploadId: number) {
        return forkJoin([
            this.markFailed(uploadId),
            this.abortUpload(uploadId),
        ]).pipe(tap(() => this.uploadFailed(uploadId)));
    }

    markFailed(uploadId: number) {
        this.uploadFailed(uploadId)
        return this.userUploadService.updateUserUpload(uploadId, {state: UserUploadStatus.Failed}).pipe(
            // error marking upload as failed, already marked on UI for retry
            catchError(() => EMPTY),
        );
    }

    markComplete(uploadId: number) {
        return this.userUploadService.updateUserUpload(uploadId, {state: UserUploadStatus.Complete}).pipe(
            tap(() => this.uploadComplete(uploadId)),
            // error marking upload as complete, mark it failed so user can resubscribe
            catchError(() => {
                this.uploadFailed(uploadId);
                return EMPTY;
            }),
        );
    }
    
    getUploadCredentials(type: 'image' | 'video', extension: string, name?: string) {
        return this.userUploadService.createUserUpload(name, extension).pipe(
            map(({id, uploadCredentials}) => ({id, credential: uploadCredentials}))
        );
    }

    private getUploaderConfig(credentials: UserUploadCredentials): UploadDetailsResponse {
        return {
            url: '',
            bucket: credentials.bucket,
            path: credentials.key,
            region: credentials.region,
            accessKeyId: credentials.credentials.accessKeyId,
            secretAccessKey: credentials.credentials.secretAccessKey,
            sessionToken: credentials.credentials.sessionToken,
            useAccelerateEndpoint: false,
        };
    }

    private setUpUploader(credentials: UserUploadCredentials, fileSize: number, fileName: string, id?: number) {
        const uploadDetails = this.getUploaderConfig(credentials);
        const uploader = new MultiPartUploader(uploadDetails, {useDb: false});
        this.uploadStarted({
            loaded: 0,
            total: fileSize,
            id,
            state: UserUploadStatus.Uploading,
            uploader,
            name: fileName,
        });
        merge(
            uploader.status$.pipe(
                distinctUntilChanged((s1, s2) => s1.total === s2.total && s1.loaded === s2.loaded),
                tap(({loaded}) => this.updateProgress(id, loaded, fileSize)),
            ),
            uploader.complete$.pipe(
                switchMap(() => this.markComplete(id)),
            ),
            uploader.onError$.pipe(
                // on upload errors, mark failed in DB and UI
                switchMap(() => this.markFailed(id)),
            ),
            uploader.credentialError$.pipe(
                tap(() => console.error('CREDENTIAL ERROR')),
                switchMap(() => this.userUploadService.getUserUpload(id, true)),
                tap((c) => uploader.refreshS3(this.getUploaderConfig(c.uploadCredentials))),
            ),
        ).subscribe();
        return uploader;
    }

    upload(credentials: UserUploadCredentials, file: File, id?: number) {
        return defer(() => {
            const uploader = this.setUpUploader(credentials, file.size, file.name, id);
            uploader.uploadFile(file);
            return of({loaded: 0, total: file.size});
        });
    }
}
