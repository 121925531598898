import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    ChangeDetectionStrategy,
} from '@angular/core';

interface PageEvent {
    previousPageIndex: number;
    pageIndex: number;
    pageSize: number;
    length: number;
}

@Component({
    selector: 'or-infinite-scroll',
    template:  '<ng-content></ng-content><div #anchor></div>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrInfiniteScrollComponent implements AfterViewInit, OnDestroy {
    @Input() options: IntersectionObserverInit = {};
    @Input() pageSize = 0;
    @Input() length = 0;

    private _pageIndex = 0;
    @Input() 
    set pageIndex(i: number) {
        if (i !== this._pageIndex) {
            this._pageIndex = i;
            this.checkEmit();
        }
    }
    get pageIndex() {
        return this._pageIndex;
    }

    @Output() page = new EventEmitter<PageEvent>();
    @ViewChild('anchor') anchor: ElementRef<HTMLElement>;

    private previousPageIndex = 0;
    private _intersectingWithRoot = false;

    private observer: IntersectionObserver;
  
    constructor(private host: ElementRef) { }

    private checkEmit() {
        if (this._intersectingWithRoot) {
            this.onNext();
        }
    }

    private hasNextPage() {
        const maxPageIndex = this.getNumberOfPages() - 1;
        return this.pageIndex < maxPageIndex && this.pageSize !== 0;
    }

    private getNumberOfPages() {
        return (!this.pageSize) ? 0 : Math.ceil(this.length / this.pageSize);
    }

    onNext() {
        if (this.hasNextPage()) {
            this.previousPageIndex = this.pageIndex;
            this._pageIndex++;
            this.pageEmit();
        }
    }

    private pageEmit() {
        this.page.emit({
            previousPageIndex: this.previousPageIndex, 
            pageIndex: this.pageIndex, 
            pageSize: this.pageSize, 
            length: this.length,
        });
    }
  
    get element() {
        return this.host.nativeElement;
    }

    ngAfterViewInit() {
        const options = {
          root: null,
          ...this.options
        };

        if (typeof IntersectionObserver === 'function') {
            this.observer = new IntersectionObserver(([entry]) => {
                this._intersectingWithRoot = entry.isIntersecting;
                if (this._intersectingWithRoot) {
                    this.checkEmit();
                }
            }, options);
        
            this.observer.observe(this.anchor.nativeElement);
        }
    }

    ngOnDestroy() {
        this.observer?.disconnect();
    }
}