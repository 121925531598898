import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@openreel/common';
import { OpenreelCommonPipesModule } from '../../../../common/src/pipes/openreel-common-pipes.module';
import { OpenreelVideoControlsComponent } from './openreel-video-controls.component';

@NgModule({
  imports: [CommonModule, MaterialModule, OpenreelCommonPipesModule],
  declarations: [OpenreelVideoControlsComponent],
  exports: [OpenreelVideoControlsComponent],
})
export class OpenreelVideoControlsModule {}
