import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { clamp, round } from 'lodash-es';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common';
import { ViewportService } from '../../services/viewport.service';

const MAX_ZOOM_PERCENTAGE = 10;

const HANDLE_UPDATE_MS = 5;

@Component({
  selector: 'openreel-wf-scroll-zoom-bar',
  templateUrl: './scroll-zoom-bar.component.html',
  styleUrls: ['./scroll-zoom-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollZoomBarComponent
  extends Cleanupable
  implements OnInit, OnDestroy
{
  @ViewChild('track', { static: true }) track: ElementRef<HTMLDivElement>;

  handles = [0, 0];
  private trackWidth: number = null;

  private isDragging: boolean;
  private handleDraggedIndex: number;
  private handlesBeforeDrag: number[];
  private handlesDuringDrag: number[];
  private handlesDragClampAmount: number[];
  private startX: number;

  private mouseMoveSubscription: Subscription;
  private mouseUpSubscription: Subscription;

  private readonly movedRx = new Subject();

  private readonly resizeObserver = new ResizeObserver(() => {
    const newTrackWidth =
      this.track.nativeElement.getBoundingClientRect().width;

    if (this.trackWidth === null) {
      this.handles[1] = newTrackWidth;
    } else {
      this.handles.forEach(
        (elem, index) =>
          (this.handles[index] = (elem / this.trackWidth) * newTrackWidth)
      );
    }
    this.trackWidth = newTrackWidth;
    this.cdr.markForCheck();
  });

  constructor(
    private readonly viewportService: ViewportService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.resizeObserver.observe(this.track.nativeElement);

    this.movedRx
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(HANDLE_UPDATE_MS))
      .subscribe(() => {
        const handleValues = this.isDragging
          ? this.handlesDuringDrag
          : this.handles;

        const from = round(handleValues[0] / this.trackWidth, 2);
        const to = round(handleValues[1] / this.trackWidth, 2);
        this.viewportService.setViewport(from, to);
      });

    this.viewportService.viewport$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(() => !this.isDragging)
      )
      .subscribe(({ from, to }) => {
        this.handles = [from * this.trackWidth, to * this.trackWidth];
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  onThumbDragStarted() {
    this.isDragging = true;
    this.handlesBeforeDrag = [...this.handles];
  }

  onThumbDragMoved($event: CdkDragMove) {
    let distance = $event.distance.x;
    if (this.handlesBeforeDrag[0] + distance < 0) {
      distance = -this.handlesBeforeDrag[0];
    }
    if (this.handlesBeforeDrag[1] + distance > this.trackWidth) {
      distance = this.trackWidth - this.handlesBeforeDrag[1];
    }

    this.setHandleDuringDragValues(
      this.handlesBeforeDrag[0] + distance,
      this.handlesBeforeDrag[1] + distance
    );
  }

  onThumbDragEnded() {
    this.isDragging = false;
    this.setHandleValues(this.handlesDuringDrag[0], this.handlesDuringDrag[1]);
  }

  mouseDownHandle($event: MouseEvent, handleDraggedIndex: number) {
    $event.stopPropagation();
    this.addListeners();

    this.handlesBeforeDrag = [...this.handles];
    this.handleDraggedIndex = handleDraggedIndex;
    this.handlesDragClampAmount = [0, 0];
    this.startX = $event.clientX;
  }

  mouseWheelZoom($event: WheelEvent) {
    $event.preventDefault();

    const zoomDelta = $event.deltaY < 0 ? -1 : 1;
    this.viewportService.zoom(zoomDelta);
  }

  trackClick($event: MouseEvent) {
    if (
      !($event.target instanceof HTMLElement) ||
      $event.target !== this.track.nativeElement
    ) {
      return;
    }

    const scrollDelta =
      $event.offsetX < this.handles[0]
        ? $event.offsetX - this.handles[0]
        : $event.offsetX - this.handles[1];

    this.viewportService.scrollDelta(scrollDelta / this.trackWidth);
  }

  zoomOutFull() {
    this.setHandleValues(0, this.trackWidth);
  }

  private setHandleValues(from: number, to: number) {
    if (
      from < to &&
      (Math.abs(to - from) / this.trackWidth) * 100 > MAX_ZOOM_PERCENTAGE
    ) {
      this.handles = [
        clamp(from, 0, this.trackWidth),
        clamp(to, 0, this.trackWidth),
      ];
      this.movedRx.next();
    }
  }

  private setHandleDuringDragValues(from: number, to: number) {
    if (
      from < to &&
      (Math.abs(to - from) / this.trackWidth) * 100 > MAX_ZOOM_PERCENTAGE
    ) {
      this.handlesDuringDrag = [
        clamp(from, 0, this.trackWidth),
        clamp(to, 0, this.trackWidth),
      ];
      this.movedRx.next();
    }
  }

  private addListeners() {
    this.mouseMoveSubscription = fromEvent(document, 'mousemove').subscribe(
      ($event: MouseEvent) => {
        const delta = $event.clientX - this.startX;

        const leftDelta = this.handleDraggedIndex === 0 ? delta : -delta;
        const rightDelta = this.handleDraggedIndex === 1 ? delta : -delta;

        const leftPosNew = this.handlesBeforeDrag[0] + leftDelta;
        const rightPosNew = this.handlesBeforeDrag[1] + rightDelta;

        this.setHandleValues(
          leftPosNew + this.handlesDragClampAmount[0],
          rightPosNew - this.handlesDragClampAmount[1]
        );

        this.handlesDragClampAmount = [
          Math.max(
            this.handlesDragClampAmount[0],
            this.handles[0] === 0 ? Math.abs(leftPosNew) : 0
          ),
          Math.max(
            this.handlesDragClampAmount[1],
            this.handles[1] === this.trackWidth
              ? Math.abs(rightPosNew) - this.trackWidth
              : 0
          ),
        ];

        this.cdr.markForCheck();
      }
    );

    this.mouseUpSubscription = fromEvent(document, 'mouseup').subscribe(() => {
      this.removeListeners();
    });
  }

  private removeListeners() {
    if (this.mouseMoveSubscription) {
      this.mouseMoveSubscription.unsubscribe();
    }
    if (this.mouseUpSubscription) {
      this.mouseUpSubscription.unsubscribe();
    }
  }
}
