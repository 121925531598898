import { HttpEventType, HttpUploadProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploaderBaseService } from 'libs/ui/src/components/openreel-uploader/openreel-uploader.component';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AssetsService } from '@openreel/common';
import { ProjectFacade } from '../../store/facades/project.facade';

@Injectable({ providedIn: 'root' })
export class UploaderService extends UploaderBaseService<string> {
  constructor(private readonly projectFacade: ProjectFacade, private readonly assetsService: AssetsService) {
    super();
  }

  getUploadCredentials(type: 'image' | 'video', extension: string) {
    return this.projectFacade.id$.pipe(
      take(1),
      switchMap((id) =>
        this.assetsService.createAssetUrl(id, { type: type, extension }).pipe(
          map((response) => ({
            id: response.id,
            credential: response.uploadUrl.url,
          }))
        )
      )
    );
  }

  upload(credentials: string, file: File) {
    return this.assetsService.uploadAsset(credentials, file).pipe(
      filter((event) => event.type === HttpEventType.UploadProgress),
      map(({ loaded, total }: HttpUploadProgressEvent) => ({ loaded, total }))
    );
  }
}
