import FroalaEditor from 'froala-editor';
export enum IconTemplate {
  'BUTTON_ICON_TEXT' = 'round_button_with_icon_text',
}
export enum FroalaCommand {
  CURSOR_PLAY = 'Play', // used for play teleprompter from cursor position
  SELECTION_PLAY = 'Play Selected', // used for play selected teleprompter text
}
export interface FroalaCommandOptions {
  template: string;
  name: string;
  tooltip: string;
  icon: string;
  focus: boolean;
  undo: boolean;
  refreshAfterCallback: boolean;
}
const DefaultOptions: FroalaCommandOptions = {
  template: IconTemplate.BUTTON_ICON_TEXT,
  name: '',
  tooltip: '',
  icon: '',
  focus: false,
  undo: false,
  refreshAfterCallback: false,
};
const CommandOptions = {
  [FroalaCommand.CURSOR_PLAY]: {
    ...DefaultOptions,
    name: FroalaCommand.CURSOR_PLAY,
    tooltip: 'Play from this location',
    icon: 'teleprompter-play',
  },
  [FroalaCommand.SELECTION_PLAY]: {
    ...DefaultOptions,
    name: FroalaCommand.SELECTION_PLAY,
    tooltip: 'Play and display the selected text only',
    icon: 'teleprompter-play',
  },
};

export function initFroalaCustomIconTemplate() {
  if (!FroalaEditor) return;
  // define custom icon temmplate for editor
  const iconTemplates = Object.values(IconTemplate);
  iconTemplates.forEach((template) => {
    FroalaEditor.DefineIconTemplate(
      template,
      "<div>\
      <img style='float: left;width: 12px;margin: 2px 2px 0;' src='assets/ui/material-icons/[icon].svg'>\
      <span style='font-weight: normal;font-size: 12px;'>[name]</span>\
      </div>"
    );
  });
}

export function registerFrolaCommand(
  command: FroalaCommand,
  clickCallback,
  refreshCallback
) {
  if (!FroalaEditor) return;
  const options: FroalaCommandOptions = CommandOptions[command];
  FroalaEditor.DefineIcon(options.name, {
    name: options.name,
    icon: options.icon,
    template: options.template,
  });
  FroalaEditor.RegisterCommand(options.name, {
    title: options.tooltip,
    icon: options.name,
    focus: options.focus,
    undo: options.undo,
    refreshAfterCallback: options.refreshAfterCallback,
    refresh: ($btn) => {
      refreshCallback($btn);
    },
    callback: () => {
      clickCallback();
    },
  });
}
