import { Component, EventEmitter, Output } from '@angular/core';
import { Cleanupable } from '@openreel/common/classes';

@Component({
  selector: 'openreel-brand-kit-update-bar',
  templateUrl: './openreel-brand-kit-update-bar.component.html',
  styleUrls: ['./openreel-brand-kit-update-bar.component.scss'],
})
export class OpenreelBrandKitUpdateBarComponent extends Cleanupable {
  @Output() apply = new EventEmitter<void>();
  @Output() ignore = new EventEmitter<void>();

  onApply = () => this.apply.emit();
  onIgnore = () => this.ignore.emit();
}
