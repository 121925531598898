import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'backgroundImageFallback' })
export class BackgroundImageFallbackPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url('${url}'), url('assets/common/images/placeholder.jpg')`
    );
  }
}
