import { BaseCommand } from './base.command';

export interface UpdateAssetsPrimaryColorEvent {
  color: string;
}

export class UpdateAssetsPrimaryColorCommand extends BaseCommand<UpdateAssetsPrimaryColorEvent> {
  run({ color }: UpdateAssetsPrimaryColorEvent) {
    this.applyGlobalColor(color, 'primary');
    this.updatePrimaryColor(color);

    return this.ok();
  }
}
