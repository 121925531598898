<h1 mat-dialog-title>
  Play self-recording
  <button mat-button class="dialog-close-button" style="float: right;" (click)="closePopup()">
    <mat-icon color="white">close</mat-icon>
 </button>
</h1>

<div
  mat-dialog-content
  class="dialog-container"
>
  <openreel-player
    *ngIf="previewVideo$ | async"
    [video$]="previewVideo$"
    class="preview"
    [options]="{ controls: true, fluid: true }"
  ></openreel-player>
</div>
