import { UserRoleType } from '../..';

export interface ApiSimpleChatMessage {
  created_at: Date;
  receiver: number;
  sender: number;
  sender_name: string;
  text: string;
  id?: string;
}

export interface ApiSingleChatMessage extends ApiSimpleChatMessage {
  is_read: '0' | '1';
  thumb_image: string;
}

export interface SimpleChatMessage {
  createdAt: Date;
  receiver: number;
  sender: number;
  senderName: string;
  text: string;
  id?: string;
}

export interface SingleChatMessage extends SimpleChatMessage {
  isRead: boolean;
  thumbImage: string;
}

export interface SingleChatMessageSend {
  created_at: Date;
  sender: string;
  receiver: string;
  sender_name: string;
  sender_type: string;
  receiver_type: string;
  senderdetails: {
    fullname: string;
  };
  text: string;
  file: string;
  thumb_image: string;
  user_type: UserRoleType;
  token: string;
}

// Web-socket payload for chatting
export interface ChatTypingInfo {
  name: string;
  receiver: number;
  sender: number;
}

// Used internally for tracking typing users
export interface TypingUserInfo {
  participantId: number;
  conversationId: number;
  name: string;
}

export const CHAT_MESSAGE_EVT = 'chat_message';
export const CHAT_TYPING_EVT = 'is_typing';
