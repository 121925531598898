import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Cleanupable } from '@openreel/common/classes';
import {
  CaptureTeleprompterCommand,
  CaptureTeleprompterStatus,
  IPopupComponent,
} from '@openreel/common/interfaces';
import { BehaviorSubject } from 'rxjs';
import {
  SubjectTeleprompterComponent,
  SubjectTeleprompterOptions,
} from '../subject-teleprompter/subject-teleprompter.component';
import { TeleprompterPreviewPopupComponent } from '../teleprompter-preview-popup/teleprompter-preview-popup.component';

interface SubjectTeleprompterPopupInput {
  options: SubjectTeleprompterOptions;
  isPlay: boolean;
  teleprompterCurrentLine: number;
  teleprompterTotalLines: number;
  width: number;
  height: number;
}
interface PopupPosition {
  x: number;
  y: number;
}

@Component({
  selector: 'openreel-subject-teleprompter-popup',
  templateUrl: './subject-teleprompter-popup.component.html',
  styleUrls: ['./subject-teleprompter-popup.component.scss'],
})
export class SubjectTeleprompterPopupComponent
  extends Cleanupable
  implements OnInit, AfterViewInit, IPopupComponent, OnDestroy
{
  @ViewChild(SubjectTeleprompterComponent)
  teleprompter: SubjectTeleprompterComponent;
  isPopup = true;
  popupPosition: PopupPosition = {
    x: 0,
    y: 0,
  };
  teleprompterOptions: SubjectTeleprompterOptions;
  private resizeDetect = new BehaviorSubject<boolean>(null);
  teleprompterCurrentStatus: CaptureTeleprompterStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubjectTeleprompterPopupInput,
    private dialogRef: MatDialogRef<TeleprompterPreviewPopupComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.teleprompterOptions = this.data.options;
    this.teleprompterOptions.countDown = 0;
    this.teleprompterOptions.totalLines = this.data.teleprompterTotalLines;
    this.teleprompterOptions.currentLine = this.data.teleprompterCurrentLine;
    this.dialogRef.addPanelClass('teleprompter-resize-popup');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startTeleprompter(this.teleprompterOptions);
    });

    this.subscriptions.push(
      this.resizeDetect.debounceTime(500).subscribe((isResize: boolean) => {
        if (isResize) {
          if (
            this.teleprompterCurrentStatus === CaptureTeleprompterStatus.PLAYING
          ) {
            this.teleprompterOptions.countDown = 0;
            this.teleprompter.loadTeleprompter(
              this.teleprompterOptions,
              true,
              CaptureTeleprompterCommand.LOAD
            );
          }
        }
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  startTeleprompter(options: SubjectTeleprompterOptions) {
    this.teleprompter.loadTeleprompter(
      options,
      true,
      CaptureTeleprompterCommand.LOAD
    );
    this.subscriptions.push(
      this.teleprompter.status$.subscribe((status) => {
        this.teleprompterCurrentStatus = status;
        if (status === CaptureTeleprompterStatus.PLAYING && !this.data.isPlay) {
          this.teleprompter.pause();
          this.data.isPlay = true;
        }
      }),

      this.teleprompter.remove$.subscribe(() => this.onDialogCloseClicked())
    );
  }

  onResized(event) {
    //if width/height changed
    if (event.oldWidth && event.oldHeight) {
      const teleprompterPosition = this.teleprompter.getTeleprompterPosition();
      this.teleprompterOptions.currentLine = Math.round(
        teleprompterPosition.currentLine
      );
      this.teleprompterOptions.totalLines = Math.round(
        teleprompterPosition.totalLines
      );
      this.resizeDetect.next(true);
    }
  }

  onDragEnd(event) {
    const position = event.source.getFreeDragPosition();
    this.popupPosition.x = Math.round(position.x);
    this.popupPosition.y = Math.round(position.y);
  }

  onDialogCloseClicked() {
    this.teleprompter.destroyAllTeleprompterEvent();
    this.dialogRef.close();
  }
}
