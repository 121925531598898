import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayerEventsService {
  private assetUploading = new BehaviorSubject<Map<string, boolean>>(new Map());
  assetUploading$ = this.assetUploading.asObservable();

  setAssetUploading(field: string, isUploading: boolean) {
    const uploadingMap = this.assetUploading.getValue();
    if (!isUploading) {
      uploadingMap.delete(field);
    } else {
      uploadingMap.set(field, true);
    }

    this.assetUploading.next(uploadingMap);
  }
}
