import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayerService, UiControl, UiControlType } from '../../services/layer.service';

import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Cleanupable } from '@openreel/common';
import {
  getLayerFromId,
  LayersDataChangedEvent,
  TimelineItem,
  timelineItemFactory,
  WorkflowDataDto,
} from '@openreel/creator/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge } from 'lodash-es';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { LayerEventsService } from '../../services/layer-events.service';

export const SAVE_DEBOUNCE_TIME = 500;

export interface LayerFormPresetOptions {
  caption?: string;
  mode?: 'content' | 'color';
  types?: {
    text?: {
      fieldClasses?: string;
    };
    image?: {
      disabled?: boolean;
      uploadedClasses?: string;
    };
    video?: {
      disabled?: boolean;
      uploadedClasses?: string;
    };
  };
  visibleTypes?: string[];
  appearance?: MatFormFieldAppearance;
}

const DEFAULT_FORM_OPTIONS: LayerFormPresetOptions = {
  appearance: 'legacy',
};

@Component({
  selector: 'openreel-layer-form-preset',
  templateUrl: './layer-form-preset.component.html',
  providers: [LayerEventsService],
})
export class LayerFormPresetComponent extends Cleanupable implements OnInit {
  @Input() workflow$: Observable<WorkflowDataDto>;
  @Input() layerIds$: Observable<string[]>;
  @Input() options: LayerFormPresetOptions;
  @Input() disabledLayerIds$: Observable<string[]>;

  @Output() uploading = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<LayersDataChangedEvent>();

  uiControls: UiControl[][];
  layersFormArray = this.formBuilder.array([]);

  UiControlTypeEnum = UiControlType;

  visibleControlsCount = 0;

  protected items: TimelineItem[] = [];
  protected formChangesListener: Subscription;

  constructor(
    private readonly layerService: LayerService,
    private readonly layerEventsService: LayerEventsService,
    private readonly formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.options = merge({}, DEFAULT_FORM_OPTIONS, this.options);

    combineLatest([this.workflow$, this.layerIds$, this.disabledLayerIds$ ?? of([])])
      .pipe(
        // NOTE: debounceTime is needed since both sources fire at the same time
        // This way we prevent new stream from having two values, instead only emiting once for two changes
        debounceTime(0),
        takeUntil(this.ngUnsubscribe),
        filter(([, layerIds]) => layerIds.length > 0)
      )
      .subscribe(([workflow, layerIds, disabledLayerIds]) => {
        this.createItems(workflow, layerIds, disabledLayerIds);
        this.updateFormSubscriptions();
      });

    this.layerEventsService.assetUploading$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((uploadingMap) => {
      this.uploading.emit(uploadingMap.size > 0);
    });
  }

  trackByLayerFn = (index: number) => this.items[index].layerId;
  trackByLayerGroupControlFn = (_: number, item: UiControl) => item.field;

  getFieldControl(layerFormGroup: FormGroup, uiControl: UiControl) {
    return layerFormGroup.controls[uiControl.field];
  }

  getUpdatedData = () => this.layerService.toData(this.items, this.layersFormArray);

  isFieldVisible(uiControl: UiControl) {
    const { mode, visibleTypes } = this.options;
    const typeVisible = !visibleTypes || visibleTypes.includes(uiControl.type);
    if (!typeVisible) {
      return false;
    }

    const contentField =
      mode === 'content' &&
      (uiControl.type === UiControlType.Text ||
        uiControl.type === UiControlType.Image ||
        uiControl.type === UiControlType.Video);

    const colorField =
      mode === 'color' && (uiControl.type === UiControlType.Text || uiControl.type === UiControlType.Shape);

    return contentField || colorField;
  }

  protected createItems(workflow: WorkflowDataDto, layerIds: string[], disabledLayerIds: string[]) {
    this.items = layerIds.map((layerId) => {
      const layerInfo = getLayerFromId(layerId, workflow);
      return timelineItemFactory(layerInfo.layer, null, workflow);
    });

    this.layersFormArray = this.layerService.toForm(this.items, disabledLayerIds, workflow);
    this.uiControls = this.layerService.toUiControls(this.items);

    this.visibleControlsCount = 0;
    for (const layer of this.uiControls) {
      for (const layerControl of layer) {
        if (this.isFieldVisible(layerControl)) {
          this.visibleControlsCount++;
        }
      }
    }
  }

  protected updateFormSubscriptions() {
    this.formChangesListener?.unsubscribe();
    this.formChangesListener = this.layersFormArray.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(SAVE_DEBOUNCE_TIME))
      .subscribe(() => this.valueChanges.emit(this.getUpdatedData()));
  }
}
