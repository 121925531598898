import * as Interfaces from './workflow-template.interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/common/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private urls = {
    list: `${commonenv.nextGenApiUrl}workflows/templates`,
    byId: (templateId: number) =>
      `${commonenv.nextGenApiUrl}workflows/templates/${templateId}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getTemplateById(templateId: number) {
    return this.httpClient.get<Interfaces.TemplateDto>(
      this.urls.byId(templateId)
    );
  }

  getTemplates() {
    return this.httpClient.get<Interfaces.WorkflowTemplateList[]>(
      this.urls.list
    );
  }
}
