import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeviceInfoPopupInput {
  country: string;
  device_name: string;
  name: string;
}

@Component({
  selector: 'openreel-device-info-popup',
  templateUrl: './device-info-popup.component.html',
  styleUrls: ['./device-info-popup.component.scss'],
})
export class DeviceInfoComponent implements OnInit {
  country: string;
  name: string;
  deviceName: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceInfoPopupInput,
    private dialog: MatDialogRef<DeviceInfoComponent>
  ) {}

  onDialogCloseClicked() {
    this.dialog.close();
  }
  ngOnInit(): void {
    this.country = this.data.country;
    this.name = this.data.name;
    this.deviceName = this.data.device_name;
  }
}
