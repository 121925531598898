<div *ngIf="!isInEditMode" (click)="switchEdit()">
  <span data-cy="span-project-name" class="f-normal-16 title">{{ nameControl.value }}</span>
  <mat-icon class="mat-icon-14" svgIcon="edit"></mat-icon>
</div>

<input
  *ngIf="isInEditMode"
  #input
  (keyup)="onKeyUp($event)"
  (blur)="onBlur()"
  data-cy="input-project-name"
  [formControl]="nameControl"
  placeholder="Type project name here"
  type="text"
/>
