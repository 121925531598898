import { Injectable } from '@angular/core';
import { environment } from '@openreel/creator/environments/environment';
import mixpanel from 'mixpanel-browser';
import { EventName, EventProperties, EventPropertyValue, SuperProperties } from './mixpanel.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  init(): void {
    mixpanel.init(environment.mixpanelToken);
  }

  setSuperProperty(property: SuperProperties, value?: EventPropertyValue): void {
    if (value) {
      const obj = {};
      obj[`*${property}`] = value;
      mixpanel.register(obj);
    } else {
      mixpanel.unregister(property);
    }
  }

  logEvent(eventName: EventName, description: string, properties?: EventProperties): void {
    if (properties && description) {
      properties = {
        ...properties,
        description
      };
    }

    const eventProperties = {};
    if (properties) {
      for (const key in properties) {
        if (properties[key]) {
          eventProperties[`#${key}`] = properties[key];
        }
      }
    }

    mixpanel.track(eventName, eventProperties);

    mixpanel.people.increment('^total_event_count', 1);
    mixpanel.people.set({ '^last_event': new Date() });
    this.setSuperProperty('session_event_count', this.incrementMixPanelSuperProperty('session_event_count'));
  }

  identify(mixpanelId: string) {
    if (mixpanelId) {
      mixpanel.identify(mixpanelId);
    }
  }

  private incrementMixPanelSuperProperty(property: string): number {
    let value = mixpanel.get_property(`*${property}`);
    if (value && typeof(value) === 'number') {
      value = value + 1;
    } else {
      value = 1;
    }
    return value;
  }
}
