import { createAction, props } from '@ngrx/store';
import {
  AudioDevice,
  RecordingType,
  TranscodeStatus,
  UploadFileNetworkStatus,
  VideoDevice,
} from '@openreel/common';
import {
  ApplyTrimToSelfRecordClipsEvent,
  SelfRecordingVideo,
  SelfRecordOptions,
  SelfRecordStreamStatus,
} from '../interfaces/self-record.interfaces';

const actionType = '[SelfRecord]';

export const requestDevicesList = createAction(
  `${actionType} Request Devices List`
);

export const reset = createAction(`${actionType} Reset`);
export const retry = createAction(`${actionType} Retry`);

export const gotToSelfRecord = createAction(
  `${actionType} Got to record screen`,
  props<{
    timelineIds: string[];
    limit?: number;
    count?: number;
  }>()
);

export const setWebcamDevices = createAction(
  `${actionType} Set Webcam Devices`,
  props<{ devices: VideoDevice[] }>()
);

export const setAudioDevices = createAction(
  `${actionType} Set Audio Devices`,
  props<{ devices: AudioDevice[] }>()
);

export const setSelectedWebcamDevice = createAction(
  `${actionType} Select Webcam Device`,
  props<{ device: VideoDevice }>()
);

export const setSelectedAudioDevice = createAction(
  `${actionType} Select audio device`,
  props<{ device: AudioDevice }>()
);

export const setSelectedAudioVideoDevice = createAction(
  `${actionType} Select audio/video device`,
  props<{ audioDevice: AudioDevice; videoDevice: VideoDevice }>()
);

export const toggleRecording = createAction(`${actionType} Toggle recording`);

export const sessionSelect = createAction(
  `${actionType} Select session`,
  props<{ sessionId: number; sessionAccountId: number }>()
);

export const sessionCreate = createAction(`${actionType} Create new session`);

export const sessionCreated = createAction(
  `${actionType} New session created`,
  props<{ sessionId: number; sessionAccountId: number }>()
);

export const startRecording = createAction(`${actionType} Start recording`);

export const startRecordingSuccess = createAction(
  `${actionType} Start recording success`
);

export const stopRecording = createAction(`${actionType} Stop recording`);

export const stopRecordingSuccess = createAction(
  `${actionType} Stop recording success`
);

export const startUploading = createAction(`${actionType} Start uploading`);

export const startUploadWebcam = createAction(
  `${actionType} Start uploading webcam video`
);

export const startUploadScreen = createAction(
  `${actionType} Start uploading screen video`
);

export const startUploadWebcamSuccess = createAction(
  `${actionType} Start uploading webcam video success`,
  props<{
    uploadBlob: Blob;
    uploadVideoId: number;
    uploadVideoDuration: number;
  }>()
);

export const startUploadScreenSuccess = createAction(
  `${actionType} Start uploading screen video success`,
  props<{
    uploadBlob: Blob;
    uploadVideoId: number;
    uploadVideoDuration: number;
  }>()
);

export const uploadWebcamProgress = createAction(
  `${actionType} Upload webcam progress`,
  props<{ uploadStatus: UploadFileNetworkStatus }>()
);
export const uploadWebcamFinish = createAction(
  `${actionType} Upload webcam finish`
);

export const transcodeWebcamProgress = createAction(
  `${actionType} Transcode webcam progress`,
  props<{ transcodeStatus: TranscodeStatus }>()
);
export const transcodeWebcamFinish = createAction(
  `${actionType} Transcode webcam finish`,
  props<{ uploadWebcamVideo: SelfRecordingVideo }>()
);

export const transcodeFinish = createAction(`${actionType} Transcode finish`);

export const uploadScreenProgress = createAction(
  `${actionType} Upload Screen progress`,
  props<{ uploadStatus: UploadFileNetworkStatus }>()
);
export const uploadScreenFinish = createAction(
  `${actionType} Upload Screen finish`,
);

export const transcodeScreenProgress = createAction(
  `${actionType} Transcode Screen progress`,
  props<{ transcodeStatus: TranscodeStatus }>()
);
export const transcodeScreenFinish = createAction(
  `${actionType} Transcode Screen finish`,
  props<{ uploadScreenVideo: SelfRecordingVideo }>()
);

export const cancelVideoUpload = createAction(
  `${actionType} Cancel video upload`,
  props<{ videoId: number }>()
);

export const cancelWebcamUpload = createAction(
  `${actionType} Cancel Webcam upload`
);

export const cancelScreenUpload = createAction(
  `${actionType} Cancel Screen upload`
);

export const setSource = createAction(
  `${actionType} Set source`,
  props<{ source: RecordingType }>()
);

export const openScreenStream = createAction(
  `${actionType} Open screen stream`
);

export const openWebcamStream = createAction(
  `${actionType} Open webcam stream`
);

export const openScreenStreamSuccess = createAction(
  `${actionType} Open screen stream Success`,
  props<{ stream: MediaStream }>()
);

export const updateScreenStreamStatus = createAction(
  `${actionType} Update screen stream status`,
  props<{ status: SelfRecordStreamStatus }>()
);

export const updateWebcamStreamStatus = createAction(
  `${actionType} Update webcam stream status`,
  props<{ status: SelfRecordStreamStatus }>()
);

export const openWebcamStreamSuccess = createAction(
  `${actionType} Open webcam stream Success`,
  props<{ stream: MediaStream }>()
);

export const closeOpenStreams = createAction(
  `${actionType} Close opened streams`,
  props<{ restartStream: boolean }>()
);

export const closeWebcamStreamSuccess = createAction(
  `${actionType} Close webcam stream Success`
);

export const closeScreenStreamSuccess = createAction(
  `${actionType} Close screen stream Success`
);

export const setOptions = createAction(
  `${actionType} Set Options`,
  props<{ options: SelfRecordOptions }>()
);

export const applyTrimToSelfRecordClips = createAction(
  `${actionType} Apply Trim To Self Record Clips`,
  props<{ event: ApplyTrimToSelfRecordClipsEvent }>()
);

export const init = createAction(
  `${actionType} Init`,
  props<{ options: SelfRecordOptions }>()
);
export const cleanup = createAction(`${actionType} Cleanup`);
export const cleanupSuccess = createAction(`${actionType} Cleanup Success`);
