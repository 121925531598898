import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cleanupable, Recording, Video } from '@openreel/common';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AssetsCacheFacade } from '@openreel/creator/app/store/facades/asset-cache.facade';

export interface RecordingPreviewDialogData {
  recording: Recording;
}

@Component({
  templateUrl: './recording-preview-dialog.component.html',
  styleUrls: ['./recording-preview-dialog.component.scss'],
})
export class RecordingPreviewDialogComponent
  extends Cleanupable
  implements OnInit, OnDestroy
{
  previewVideo$: BehaviorSubject<Video> = new BehaviorSubject<Video>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RecordingPreviewDialogData,
    private dialogRef: MatDialogRef<RecordingPreviewDialogComponent>,
    private readonly assetsCacheFacade: AssetsCacheFacade
  ) {
    super();
  }

  ngOnInit(): void {
    const recording = this.data.recording;
    this.assetsCacheFacade
      .getAssetUrlById('or-recordings', recording.ovra_session_videos_id)
      .pipe(first())
      .subscribe((url) =>
        this.previewVideo$.next({
          mainStream: url,
        })
      );
  }

  closePopup() {
    this.dialogRef.close();
  }
}
