import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorPickerControl } from '@iplab/ngx-color-picker';
import { Cleanupable } from '@openreel/common/classes';

export interface ColorTagDialogData {
  color: string;
}

@Component({
  selector: 'openreel-color-picker-dialog',
  templateUrl: './openreel-color-picker-dialog.component.html',
  styleUrls: ['./openreel-color-picker-dialog.component.scss'],
})
export class OpenreelColorPickerDialogComponent
  extends Cleanupable
  implements OnInit
{
  customColorControl = new ColorPickerControl().hidePresets();

  constructor(
    private readonly dialogRef: MatDialogRef<ColorTagDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ColorTagDialogData
  ) {
    super();
  }

  ngOnInit() {
    this.customColorControl.setValueFrom(this.data.color);
  }

  onSave() {
    this.dialogRef.close(this.customColorControl.value);
  }
}
