import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FileDownloadService {
    constructor(private http: HttpClient) {}

    forceDownloadUrl(url: string, filename: string) {
        return this.http.get(url, {responseType: 'arraybuffer'}).pipe(
            tap(blob => {
                this.forceDownloadFile(new Blob([blob]), filename);
            }),
        );
    }

    forceDownloadFile(file: Blob, filename: string) {
        const blobUrl = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.download = filename;
        a.href = blobUrl;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
        a = null;
    }
}
