<form
  class="login-form"
  (submit)="updateUserPassword()"
>
  <div class="form-field-label">
    <span>Password</span>
  </div>
  <mat-form-field appearance="outline">
    <input
      class="fs-exclude"
      matInput
      placeholder="Password"
      [type]="passwordHide ? 'password' : 'text'"
      [(ngModel)]="password"
      name="password"
      [disabled]="disabled"
    />
    <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
      passwordHide ? 'visibility_off' : 'visibility'
    }}</mat-icon>
  </mat-form-field>
  <div class="form-field-label">
    <span>Password</span>
  </div>
  <mat-form-field appearance="outline">
    <input
      class="fs-exclude"
      matInput
      placeholder="Confirm Password"
      [type]="confPasswordHide ? 'password' : 'text'"
      [(ngModel)]="confPassword"
      name="password"
      [disabled]="disabled"
    />
    <mat-icon matSuffix (click)="confPasswordHide = !confPasswordHide">{{
      confPasswordHide ? 'visibility_off' : 'visibility'
    }}</mat-icon>
  </mat-form-field>
  <button
    class="mat-button-large"
    mat-flat-button
    color="primary"
    [disabled]="disabled"
  >
    Submit
  </button>
</form>

<p class="footer-note">
  <a [routerLink]="loginRoute">Login</a>
</p>
