import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { DirectorApiService } from '../../services/director/director-api/director-api.service';
import { HelperService } from '../../services/helper/helper.service';
import { ToastrService } from 'ngx-toastr';
import { UserUpdateRequest } from './or-user-info-update.interface';
import { UserService } from './or-user-info-update.service';
import { COUNTRY_PHONE_CODES } from '../../country-phone-code-utils';
import { ErrorStateMatcher } from '@angular/material/core';
import { Cleanupable } from '../../classes';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
@Component({
  selector: 'openreel-user-info-update',
  templateUrl: './or-user-info-update.component.html',
  styleUrls: ['./or-user-info-update.component.css'],
})
export class OrUserInfoUpdateComponent extends Cleanupable implements OnInit {
  currentpasshide = true;
  newpasshide = true;
  confirmpasshide = true;
  userFrm: FormGroup;
  disabledBtn = false;
  countryPhoneCodes = COUNTRY_PHONE_CODES;
  filteredPhoneCodes = this.countryPhoneCodes;
  tfaInitValue = false;

  @Input()
  show2FaToggle = false;

  @Input()
  showContactUpdate = false;

  @ViewChild('countrySearchInput') countrySearchInput: ElementRef<HTMLInputElement>;

  errorMatcher = new CrossFieldErrorMatcher();

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private directorApiService: DirectorApiService
  ) {
    super();
  }
  ngOnInit() {
    this.getUserDetails();
  }
  getUserDetails() {
    this.directorApiService.getUserDetails().subscribe((res) => {
      this.initUserForm(res);
    });
  }
  initUserForm(userDetails) {
    this.tfaInitValue = userDetails.tfaEnabled;
    this.userFrm = this.formBuilder.group(
      {
        fullname: [userDetails.fullname, [Validators.required]],
        email: [userDetails.email, [Validators.required, Validators.pattern(this.helperService.emailPattern)]],
        currentPassword: [''],
        newPassword: ['', [Validators.pattern(this.helperService.passPattern)]],
        confirmNewPassword: ['', [Validators.pattern(this.helperService.passPattern)]],
        contact: [userDetails.contact],
        contactCountryCode: [userDetails.contactCountryCode],
        tfaEnabled: [userDetails.tfaEnabled],
        countrySearch: [],
      },
      { validator: this.contactRequiredValidation('contactCountryCode', 'contact') }
    );
    this.subscriptions.push(
      this.userFrm?.get('contact').valueChanges.subscribe(() => this.userFrm.get('contactCountryCode').markAsDirty())
    );
  }
  submitUserDetails() {
    if (!this.userFrm.valid) return;
    if (this.userFrm.value.newPassword !== this.userFrm.value.confirmNewPassword) {
      this.toastr.error('Password confirmation does not match', 'Error!');
    } else {
      const contact = +(this.userFrm.value.contactCountryCode + this.userFrm.value.contact);
      const sendData: UserUpdateRequest = {
        fullName: this.userFrm.value.fullname.trim(),
        email: this.userFrm.value.email.trim(),
        ...(this.tfaInitValue !== this.userFrm.value.tfaEnabled && { enableTfa: this.userFrm.value.tfaEnabled }),
        contact: contact || null,
      };

      if (this.userFrm.value.currentPassword || this.userFrm.value.newPassword) {
        sendData.oldPass = this.userFrm.value.currentPassword;
        sendData.newPass = this.userFrm.value.newPassword;
        sendData.newPassconf = this.userFrm.value.confirmNewPassword;
      }
      this.disabledBtn = true;
      this.userService.updateUserDetails(sendData).subscribe(
        (res) => {
          this.tfaInitValue = this.userFrm.value.tfaEnabled;
          this.disabledBtn = false;
          this.toastr.success(res.message, 'Success!');
        },
        (error) => {
          this.getUserDetails();
          this.disabledBtn = false;
          this.toastr.error(error.message, 'Error!');
        }
      );
    }
  }

  onCountrySearch(value: string) {
    value = value.trim();
    if (value.length === 0) {
      this.filteredPhoneCodes = this.countryPhoneCodes;
    }
    const filter = value.toLowerCase();
    this.filteredPhoneCodes = this.countryPhoneCodes.filter(
      (option) => option.name.toLowerCase().includes(filter) || option.phone_code.includes(filter)
    );
  }

  openedChange() {
    setTimeout(() => {
      this.countrySearchInput.nativeElement.focus();
    }, 100); // Delay is used to make the focus of input work.
  }

  contactRequiredValidation(contactCountryCodeCtrl: string, contactCtrl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const contactCountryCode = control.get(contactCountryCodeCtrl).value;
      const contact = control.get(contactCtrl).value;

      if ((contactCountryCode && !contact) || (!contactCountryCode && contact)) {
        return { contact_require: true };
      }

      return null;
    };
  }

  countrySearchDrpDwnClosed() {   
    this.filteredPhoneCodes = this.countryPhoneCodes;
    this.userFrm.get('countrySearch').setValue('');
    this.userFrm.controls['contact'].markAsDirty();
  }

  clearContact() {
    this.userFrm.get('contact').setValue('');
    this.userFrm.get('contactCountryCode').setValue('');
  }
}
