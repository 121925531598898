import { Component, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorTag } from '@openreel/creator/common';
import { noop } from 'lodash-es';

@Component({
  selector: 'openreel-color-pickers',
  templateUrl: './openreel-color-pickers.component.html',
  styleUrls: ['./openreel-color-pickers.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelColorPickersComponent,
    },
  ],
})
export class OpenreelColorPickersComponent implements ControlValueAccessor {
  formArray: FormArray = this.formBuilder.array([]);

  @Input() showPalette = false;
  @Input() showTagName = true;
  @Input() showColorString = true;

  @Input() set disabled(value) {
    this.setDisabledState(value);
  }

  @HostBinding('class.has-items') get hasItemsClass() {
    return this.formArray?.controls.length > 0;
  }

  colorTags: ColorTag[] = [];
  isDisabled = false;

  propagateOnChanged: (colorTags: ColorTag[]) => void = noop;
  propagateOnTouched = noop;

  constructor(private formBuilder: FormBuilder) {}

  writeValue(colorTags: ColorTag[]) {
    this.formArray.clear();

    colorTags?.forEach((colorTag) => {
      this.formArray.push(this.formBuilder.control(colorTag));
    });
  }

  registerOnChange(fn: (colorTags: ColorTag[]) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  onColorPickerClosed() {
    this.propagateOnTouched();
  }

  onColorPickerValueChanged() {
    this.propagateOnChanged(this.formArray.value);
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
  }
}
