<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <h2 class="sub-title">{{data.question}}</h2>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button mat-raised-button
    [color]="answer.color"
    *ngFor="let answer of data.answers"
    (click)="setAnswer(answer.responseValue)"
  >
    {{answer.text}}
  </button>
</div>