import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Layout } from '../interfaces/player-data.interfaces';
import { clamp } from 'lodash-es';

export interface PlayerToggleLayerFit {
  type: 'toggle-layer-fit';
  objectFit: 'contain' | 'cover';
  externalId: string;
}

export interface PlayerUpdateLayerFitPosition {
  type: 'update-layer-fit-position';
  objectPosition: number[];
  externalId: string;
}

export interface PlayerEventUpdateBounds {
  type: 'update-bounds';
  externalId: string;
  bounds: Layout;
  maintainAspectRatio: boolean;
}

export interface PlayerEventResetBounds {
  type: 'reset-bounds';
  externalId: string;
  defaultBounds: Layout;
}

export interface PlayerEventSwitchMainVideos {
  type: 'switch-main-videos';
}

export interface PlayerAnalyticEvent {
  eventName: string;
  description: string;
}

export type PlayerExternalEvent =
  | PlayerEventUpdateBounds
  | PlayerEventResetBounds
  | PlayerToggleLayerFit
  | PlayerUpdateLayerFitPosition
  | PlayerEventSwitchMainVideos;

export interface PreviousPlayerState {
  wasPlaying: boolean;
  shouldReset: boolean;
  time: number;
}

@Injectable()
export class CueEventsService {
  private volume = new BehaviorSubject<number>(1);
  volume$ = this.volume.asObservable();

  private fullscreen = new BehaviorSubject<boolean>(false);
  fullscreen$ = this.fullscreen.asObservable();

  private playerEvents = new Subject<PlayerExternalEvent>();
  playerEvents$ = this.playerEvents.asObservable();

  private analyticsEvents = new Subject<PlayerAnalyticEvent>();
  analyticsEvents$ = this.analyticsEvents.asObservable();

  private previousPlayerState = new BehaviorSubject<PreviousPlayerState>(null);
  previousPlayerState$ = this.previousPlayerState.asObservable();

  setVolume = (volume: number) => this.volume.next(clamp(volume, 0, 1));

  setFullscreen = (fullscreen: boolean) => {
    this.fullscreen.next(fullscreen);
  };

  setPreviousPlayerState = (previousState: PreviousPlayerState) => this.previousPlayerState.next(previousState);

  setPlayerEvent = (event: PlayerExternalEvent) => this.playerEvents.next(event);

  setAnalyticsEvent = (eventName: string, description: string) => this.analyticsEvents.next({ eventName, description });
}
