import * as fromActions from '../actions/asset-cache.actions';
import * as fromSelectors from '../selectors/asset-cache.selectors';

import { filter, map, take, tap } from 'rxjs/operators';

import { AssetId, AssetsFileProviderType } from '@openreel/creator/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AssetsCacheFacade {
  cachedAssets$ = this.store.select(fromSelectors.cachedAssets);

  transcodingThumbnails$ = this.store.select(fromSelectors.transcodingThumbnails);

  thumbnailSpritesheets$ = this.store.select(fromSelectors.thumbnailSpritesheets);

  constructor(private readonly store: Store) {}

  getAssetUrlById(provider: 'url' | 'or-assets' | 'or-recordings', id: AssetId): Observable<string> {
    return this.cachedAssets$.pipe(
      tap(() => this.store.dispatch(fromActions.loadAsset({ id, provider }))),
      map((assets) =>
        assets.find((currentAsset) => currentAsset.assetId === id.toString() && currentAsset.provider === provider)
      ),
      filter((asset) => asset && !asset.isLoading),
      take(1),
      map((asset) => asset.url)
    );
  }

  loadSpritesheet = (videoId: number, videoType: AssetsFileProviderType) =>
    this.store.dispatch(fromActions.loadSpritesheet({ videoId, videoType }));

  getSpritesheet = (videoId: number, signedUrl: string) =>
    this.store.dispatch(fromActions.getSpritesheet({ videoId, signedUrl }));

  saveSpritesheet(videoId: number, spritesheet: HTMLImageElement) {
    this.store.dispatch(fromActions.saveSpritesheet({ videoId, spritesheet }));
  }
}
