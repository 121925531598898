import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'secondsToHms'
})
export class SecondsToHms implements PipeTransform {

 public transform(value: number) {
    value = Number(value);
	const h = Math.floor(value / 3600);
	const m = Math.floor(value % 3600 / 60);
	const s = Math.floor(value % 3600 % 60);

	const hDisplay = h > 0 ? h + 'h' : '';
	const mDisplay = m > 0 ? m + 'm' : '';
	const sDisplay = s > 0 ? s + 's' : '0s';
	return hDisplay || mDisplay || sDisplay;
  }
}
