import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Layout } from '../interfaces/player-data.interfaces';

export interface SelectedLayer {
  playerId: string;
  externalId: string;
  playerElement: Element;
  defaultLayout: Layout;
}

export enum SelectedActions {
  ResetBounds,
  SwitchMainVideos,
  ContainContent,
  FillContent,
}

@Injectable()
export class CueSelectionService {
  private selected = new BehaviorSubject<SelectedLayer>(null);
  selected$ = this.selected.asObservable();

  private actions = new BehaviorSubject<SelectedActions[]>([]);
  actions$ = this.actions.asObservable();

  private moving = new Subject<boolean>();
  moving$ = this.moving.asObservable();

  setSelection = (info: SelectedLayer, actions: SelectedActions[]) => {
    this.selected.next(info);
    this.actions.next(actions);
  };

  setMoving = (moving: boolean) => this.moving.next(moving);

  setActions = (actions: SelectedActions[]) => this.actions.next(actions);

  clearSelection = () => this.selected.next(null);
}
