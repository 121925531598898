import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { uniq } from 'lodash-es';
import { Observable } from 'rxjs';

import { CustomFontDto, CustomFontVariant, FontDto } from './font.interfaces';

import { commonenv } from '@openreel/common/env/environment';
import { CustomFontAsset, fontWeightToValue, GoogleFontAsset } from '@openreel/creator/common';

@Injectable({
  providedIn: 'root',
})
export class FontService {
  private urls = {
    company: () => `${commonenv.nextGenApiUrl}fonts/company`,
    suggested: () => `${commonenv.nextGenApiUrl}fonts/suggested`,
    trending: () => `${commonenv.nextGenApiUrl}fonts/trending`,
    search: (name: string, limit: number, page: number) =>
      `${commonenv.nextGenApiUrl}fonts/search?name=${name}&limit=${limit}&page=${page}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  searchFont(query = '', limit = 20, page = 1) {
    return this.httpClient.get<FontDto[]>(this.urls.search(query, limit, page));
  }

  companyFonts(): Observable<CustomFontDto[]> {
    return this.httpClient.get<CustomFontDto[]>(this.urls.company());
  }

  trendingFonts() {
    return this.httpClient.get<FontDto[]>(this.urls.trending());
  }

  suggestedFonts() {
    return this.httpClient.get<FontDto[]>(this.urls.suggested());
  }

  static fontToFontAsset(font: FontDto): GoogleFontAsset {
    return {
      id: `extra_${font.id}`,
      type: 'font',
      name: font.family,
      family: font.family,
      weights: FontService.filterGoogleFontsVariants(font.variants),
      extra: true,
      file: {
        path: FontService.buildGoogleFontStylesheetUrl(font.family, font.variants),
        provider: 'url',
      },
      custom: false,
    };
  }

  static customFontToFontAsset(font: CustomFontDto): CustomFontAsset {
    return {
      id: `custom_${font.id}`,
      type: 'font',
      name: font.family,
      family: font.family,
      weights: FontService.getCustomFontWeights(font.variants),
      files: font.variants.map((variant) => ({
        path: `${variant.file}`,
        provider: 'url',
      })),
      formats: font.variants.map((variant) => variant.format),
      custom: true,
    };
  }

  static buildGoogleFontStylesheetUrl(fontFamily: string, variants: string[]): string {
    variants = FontService.filterGoogleFontsVariants(variants);
    return `https://fonts.googleapis.com/css2?display=block&family=${FontService.buildGoogleFontFamily(fontFamily, variants)}`;
  }

  static buildGoogleFontFamily(fontFamily: string, variants: string[]): string {
    variants = FontService.filterGoogleFontsVariants(variants);
    return `${fontFamily}:wght@${variants.join(';')}`;
  }

  static filterGoogleFontsVariants(variants: string[]) {
    return variants
      .filter((variant) => ['regular', 'bold', '400', '700'].includes(variant))
      .map((variant) => fontWeightToValue(variant));
  }

  static getCustomFontWeights(variants: CustomFontVariant[]) {
    return uniq(variants.map((variant) => fontWeightToValue(variant.variant)));
  }
}
