/// <reference types="chrome"/>
import { Injectable } from '@angular/core';
import {
  ChromeMessage,
  ChromeResponse,
} from '../../models/chrome-message.interface';

declare const extensionId: string;

@Injectable()
export class ChromeMessagingService {
  extensionId: string;

  constructor() {
    this.extensionId = chrome?.runtime.id;

    if (!this.extensionId && typeof extensionId === 'string') {
      this.extensionId = extensionId;
    }
  }

  hasExtension() {
    return this.extensionId ? true : false;
  }

  send<T = unknown>(message: ChromeMessage<unknown>): Promise<T> {
    if (!this.extensionId) {
      return;
    }

    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        this.extensionId,
        message,
        (response: ChromeResponse<T>) => {
          if (!response && chrome.runtime.lastError) {
            reject(new Error(chrome.runtime.lastError.message));
          } else if (response && !response.success) {
            reject(new Error(response.message));
          } else if (response) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        }
      );
    });
  }
}
