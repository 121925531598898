<p *ngIf="loading" style="text-align: center;">Loading ...</p>
<div *ngIf="!loading">

  <div class="stats-container" [ngClass]="{ 'stats-container-margin':addStatMarginClass }">
    <div class="flex-container">

      <div class="flex-child">
        <div class="stats-num">{{recordingHour}}</div>
      </div>

      <div class="flex-child">
        <div class="stats-label stats-label-1">RECORDING HOURS USED</div>
      </div>

    </div>

    <div class="flex-container">

      <div class="flex-child">
        <div class="stats-num">{{recordedClipsCount}}</div>
      </div>

      <div class="flex-child">
        <div class="stats-label stats-label-2">CLIPS RECORDED</div>
      </div>

    </div>
  </div>

</div>
