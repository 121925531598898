import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  UserUpdateRequest,
  UserUpdateResponse,
} from './or-user-info-update.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public urls = {
    updateUser: commonenv.nextGenApiUrl + 'user/update',
  };
  constructor(public httpClient: HttpClient) {}
  updateUserDetails(data: UserUpdateRequest) {
    return this.httpClient.put<UserUpdateResponse>(this.urls.updateUser, data);
  }
}
