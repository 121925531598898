import {
  Component,
  Input,
} from '@angular/core';
import { CardData } from '../../interfaces/interfaces';

@Component({
  selector: 'openreel-list-view',
  templateUrl: './orlistview.component.html',
  styleUrls: ['./orlistview.component.css'],
})
export class OrListViewComponent{
  @Input() listdata:CardData;
  shwimg = true;
}
