import { Directive, OnDestroy, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Directive({
  selector: '[orVirtualInfiniteScroll]',
})
export class VirtualInfiniteScrollDirective implements OnDestroy, AfterViewInit {
    @Output() moreData = new EventEmitter<void>();

    viewSubscibtion: Subscription;  

    constructor(
        private viewport:CdkVirtualScrollViewport) {
    }

    ngAfterViewInit(): void {
        this.viewSubscibtion = this.viewport.elementScrolled().pipe(
            map(() => this.viewport.measureScrollOffset('bottom')),
            pairwise(),
            map(([y1, y2]) => (y2 < y1 && y2 < 140)),
            distinctUntilChanged(),
            filter(s => !!s),
            throttleTime(200),
        ).subscribe(() => {
            this.moreData.emit();
        });
    }

    ngOnDestroy(){
        this.viewSubscibtion.unsubscribe();
    }
}