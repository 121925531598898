import { Component, Inject } from '@angular/core';
import { IPopupComponent } from '../../interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionPopupInput } from './question.service';

export interface QuestionPopupOutput<AnswerType> {
  result: AnswerType;
}

@Component({
  selector: 'openreel-question-popup',
  templateUrl: './question-popup.component.html',
  styleUrls: ['./question-popup.component.css'],
})
export class QuestionPopupComponent<AnswerType>
  implements IPopupComponent {
  isPopup: true;
  isLightThemed?: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionPopupInput<AnswerType>,
    private dialogRef: MatDialogRef<
      QuestionPopupComponent<AnswerType>,
      QuestionPopupOutput<AnswerType>
    >
  ) {
    this.isPopup = true;
    this.isLightThemed = data.isLightThemed;
  }

  setAnswer(value: AnswerType) {
    this.dialogRef.close({
      result: value,
    });
  }
}
