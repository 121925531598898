import { Injectable } from '@angular/core';
import { pixelsToMs } from '../helpers';
import {
  ObjectDrawInfo,
  TIME_CURSOR_NAME,
  TimingInfo,
} from '../canvas.interfaces';
import { DrawDataService } from '../services/draw-data.service';
import { InteractionService } from './interaction-base.service';
import { SelectionService } from './selection.service';

interface CheckSiblingOverlap {
  overlaps: boolean;
  snap?: boolean;
  snapTiming?: TimingInfo;
  reorder?: boolean;
  reorderTarget?: ObjectDrawInfo;
}

export enum DragCalcResultAction {
  Move,
  Reorder,
}

export interface DragCalcResult {
  action: DragCalcResultAction;
  reorderTarget?: ObjectDrawInfo;
}

@Injectable()
export class DragService extends InteractionService {
  type = 'drag' as const;

  constructor(
    private readonly drawDataService: DrawDataService,
    private readonly selectionService: SelectionService
  ) {
    super();
  }

  start() {
    super.startInteraction(
      this.selectionService.items,
      this.selectionService.bounds,
      this.selectionService.timing
    );
  }

  calcDragPosition(currentLeft: number): DragCalcResult {
    this._snapped = false;

    const currentTiming = new TimingInfo(
      pixelsToMs(currentLeft, this.getItemsParent()),
      pixelsToMs(currentLeft + this._startBounds.width, this.getItemsParent())
    );

    this._moveDirection =
      currentTiming.startAt < this.validTiming.startAt ? -1 : 1;

    if (this.checkOverlap()) {
      const overlapResult = this.checkSiblingOverlaps(
        this.getItemsParent(),
        currentTiming,
        this._items
      );

      if (overlapResult.overlaps) {
        this._snapped = overlapResult.snap;

        if (overlapResult.reorder) {
          this.addToChangedItems([overlapResult.reorderTarget]);

          return {
            action: DragCalcResultAction.Reorder,
            reorderTarget: overlapResult.reorderTarget,
          };
        }
        if (overlapResult.snap) {
          this._validTiming.set(overlapResult.snapTiming);
        }
        return { action: DragCalcResultAction.Move };
      }
    }

    const parentResult = this.snapParentBounds(
      this.getItemsParent(),
      currentTiming,
      this._items
    );

    if (!parentResult.inBounds) {
      this._snapped = parentResult.snapped;
      this._validTiming.set(parentResult.snapTiming ?? this._validTiming);
      return { action: DragCalcResultAction.Move };
    }

    this._validTiming.set(currentTiming);
    return { action: DragCalcResultAction.Move };
  }

  updateDragValidTiming(newTiming: TimingInfo) {
    this._validTiming.set(newTiming);
  }

  private checkOverlap() {
    return this._items.length !== 1 || this._items[0].name !== TIME_CURSOR_NAME;
  }

  // eslint-disable-next-line max-lines-per-function
  private checkSiblingOverlaps(
    parent: ObjectDrawInfo,
    currentTiming: TimingInfo,
    exceptObjects: ObjectDrawInfo[]
  ): CheckSiblingOverlap {
    // If current timing is completely outside of last valid timing, we need to include space between in overlap check
    // We do this since user might have moved mouse too quickly
    // and its possible we'll skip an item if we only consider current timing bounds
    const overlapCheckBounds = TimingInfo.from(currentTiming);
    if (
      overlapCheckBounds.endAt < this.validTiming.startAt ||
      overlapCheckBounds.startAt > this.validTiming.endAt
    ) {
      if (this._moveDirection < 0) {
        overlapCheckBounds.endAt = this.validTiming.startAt - 1;
      } else {
        overlapCheckBounds.startAt = this.validTiming.endAt + 1;
      }
    }

    const overlapItems = this.drawDataService.getOverlappingObjects(
      parent,
      overlapCheckBounds,
      exceptObjects
    );

    if (overlapItems.length === 0) {
      return { overlaps: false };
    }

    // We take first overlap in direction of movement
    const firstOverlap =
      this._moveDirection > 0
        ? overlapItems[0]
        : overlapItems[overlapItems.length - 1];

    if (firstOverlap.item.reorderable && firstOverlap.middlePointPassed) {
      return {
        overlaps: true,
        reorder: true,
        reorderTarget: firstOverlap.item,
      };
    }

    const firstOverlapTiming = firstOverlap.item.timing;
    const snapStartAt =
      currentTiming.centerPoint < firstOverlapTiming.centerPoint
        ? firstOverlapTiming.startAt - currentTiming.duration - 1
        : firstOverlapTiming.startAt + firstOverlapTiming.duration + 1;

    const snapTiming = new TimingInfo(
      snapStartAt,
      snapStartAt + currentTiming.duration
    );

    // Try to snap
    const isSnapInParentBounds = this.drawDataService.isInBounds(
      parent,
      snapTiming
    );
    const isSnapOverlapping =
      this.drawDataService.getOverlappingObjects(
        parent,
        snapTiming,
        exceptObjects
      ).length > 0;

    const canSnap = isSnapInParentBounds && !isSnapOverlapping;

    return {
      overlaps: true,
      snap: canSnap,
      snapTiming: canSnap ? snapTiming : null,
    };
  }

  private snapParentBounds(
    parent: ObjectDrawInfo,
    timing: TimingInfo,
    exceptObjects: ObjectDrawInfo[]
  ) {
    const isInBounds = this.drawDataService.isInBounds(parent, timing);
    if (isInBounds) {
      return { inBounds: true };
    }

    const leftBound = parent.timing.startAt;
    const rightBound = parent.timing.endAt;

    const snapStartAt =
      timing.startAt < leftBound ? leftBound : rightBound - timing.duration;

    const snapTiming = new TimingInfo(
      snapStartAt,
      snapStartAt + timing.duration
    );
    const timelineBoundOverlaps = this.drawDataService.getOverlappingObjects(
      parent,
      snapTiming,
      exceptObjects
    );

    const canSnap = timelineBoundOverlaps.length === 0;
    return {
      inBounds: false,
      snapped: canSnap,
      snapTiming: canSnap ? snapTiming : null,
    };
  }
}
