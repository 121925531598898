import { BoundsInfo, ObjectDrawInfo, TimingInfo } from '../canvas.interfaces';

export abstract class InteractionService {
  protected _items: ObjectDrawInfo[] = [];

  protected _startBounds: BoundsInfo;
  protected _startTiming: TimingInfo;

  protected _validTiming: TimingInfo;
  protected _snapped = false;

  protected _moveDirection: number;

  protected _changedItems = new Set<string>();

  get moveDirection() {
    return this._moveDirection;
  }

  get startTiming() {
    return TimingInfo.from(this._startTiming);
  }

  get validTiming() {
    return TimingInfo.from(this._validTiming);
  }

  get changedItems() {
    return this._changedItems;
  }

  get isSnapped() {
    return this._snapped;
  }

  abstract type: 'drag' | 'stretch';

  reset() {
    this._items = [];
    this._startBounds = null;
    this._startTiming = null;
    this._validTiming = null;
    this._moveDirection = null;
    this._snapped = false;
    this._changedItems.clear();
  }

  addToChangedItems(changedItems: ObjectDrawInfo[]) {
    changedItems.forEach(({ name }) => this._changedItems.add(name));
  }

  protected startInteraction(
    items: ObjectDrawInfo[],
    bounds: BoundsInfo,
    timing: TimingInfo
  ) {
    this.reset();

    this._items = items;

    this._startBounds = bounds;
    this._startTiming = timing;

    this._validTiming = TimingInfo.from(this._startTiming);

    this.addToChangedItems([...this._items]);
  }

  protected getItemsParent() {
    if (this._items.length === 0) {
      return null;
    }

    return this._items[0].parent;
  }
}
