import { HostableTypes, HostablePermissionTypes, HostableVideoStatuses, HostableCtaType } from './constants';
export const EVT_HOSTING_RENDER_PROGRESS = 'hosting-render-progress';
export const EVT_HOSTING_JOIN_BY_PROJECT_ID = 'join-hosting-via-project-id';

export interface HostingRenderProgress {
  state: HostableVideoStatuses;
  progress: number;
  id: number;
}

export class HostableVideo {
  id: number;
  title: string;
  assetURL: string;
  slug: string;
  state: HostableVideoStatuses;
  permission: HostablePermissionTypes;
  createdAt: string;
  sourceType: HostableTypes;
  sourceId: number;
  thumbnailSignedUrl: string[];
  duration: number;
  description?: string;
  altText?: string;
  hasCustomThumbnail: boolean;
}

export interface HostingVideoAnalytics {
  id: number;
  playback: number;
}

export interface HostingTag {
  id: number;
  tag: string;
}

export interface HostingHub {
  id: number;
  title: string;
  permission: HostablePermissionTypes;
  slug: string;
}

export interface VideoPlaybackData {
  id: number;
  srcUrl: string;
  thumbUrl: string;
  captions: HostingCaption[];
  cta: HostingCtaDefinition[];
}

export interface HostingParentObject {
  id: number;
  type: HostableTypes;
}

export interface UpdateVideoDetails {
  title?: string;
  description?: string;
  permission?: HostablePermissionTypes;
  altText?: string;
}

export interface SortOption {
  field: string;
  direction: 'ASC' | 'DESC';
}

export interface CreateHostingCaption {
  assetId: number;
  languageCode: string;
}

export type UpdateHostingCaption = Omit<CreateHostingCaption, 'assetId'>;

export interface HostingCaption extends CreateHostingCaption {
  readUrl: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  languageDisplay: string;
}

export interface CreateHostingThumbail {
  assetId: number;
}

export interface HostingThumbnail {
  assetId: number;
  id: number;
  readUrl: string;
  createdAt: Date;
  updatedAt: Date;
  isCustom: boolean;
}

export interface HostableCountFilters {
  sourceType?: HostableTypes;
}

export interface HostableListFilters extends HostableCountFilters {
  videoStatus?: HostableVideoStatuses;
  title?: string;
  permission?: HostablePermissionTypes;
}

interface BaseCtaDefinition {
  type: HostableCtaType;
  timeFrame: {
    start: number;
    end: number;
  };
}

export interface TextButtonCtaDefinition extends BaseCtaDefinition {
  type: HostableCtaType.TextButton;
  buttonText: string;
  linkUrl: string;
  position:
    | {
        x: number;
        y: number;
      }
    | string;
}

interface ImageButtonCtaDefinition extends BaseCtaDefinition {
  type: HostableCtaType.ImageButton;
  imageAsset: string;
  linkUrl: string;
  position:
    | {
        x: number;
        y: number;
      }
    | string;
}

export type HostingCtaDefinition = TextButtonCtaDefinition | ImageButtonCtaDefinition;

export interface CreateHostingCta {
  ctaDefinition: HostingCtaDefinition;
}

export interface UpdateHostingCta {
  ctaDefinition: HostingCtaDefinition;
}

export interface HostingCta extends CreateHostingCta {
  id: number;
  updatedAt: Date;
  createdAt: Date;
}

export interface HostingCreateData {
  title: string;
  slug: string;
  permission: HostablePermissionTypes;
  description?: string;
  altText?: string;
}

export interface HostingPlayerActions {
  play: () => Promise<void>;
  pause: () => unknown;
}
