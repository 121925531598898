import { DirectorApiService } from './director-api/director-api.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

const LAST_NOTFIED_MOBILE_VERSION = 'last_notified_mobile_version';

@Injectable()
export class MobileVersionNotifierService {
  version$ = new BehaviorSubject<string>(null);
  constructor(private directorApiService: DirectorApiService) {
    this.init();
  }

  private async init() {
    const versionControl = await this.directorApiService.getAppVersionControl({
      device: 'ios',
      version: '1.0.0',
      app_name: 'capture2',
    });
    const latestVersion = versionControl.latest_version;
    const lastNotifiedVersion = localStorage.getItem(
      LAST_NOTFIED_MOBILE_VERSION
    ) || '0';
    if (
      lastNotifiedVersion &&
      latestVersion &&
      this.versionCompare(latestVersion, lastNotifiedVersion) === 1
    ) {
      this.version$.next(latestVersion);
    }
  }

  versionCompare(v1, v2, options = {lexicographical: false, zeroExtend: false}) {
    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    function isValidPart(x) {
      return (options.lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (options.zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push('0');
      while (v2parts.length < v1parts.length) v2parts.push('0');
    }

    if (!options.lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }

      if (v1parts[i] == v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }

    return 0;
  }

  markRead() {
    localStorage.setItem(LAST_NOTFIED_MOBILE_VERSION, this.version$.value);
    this.version$.next(null);
  }
}