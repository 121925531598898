import { Injectable } from '@angular/core';
import {
  AuthService,
  DirectorApiService,
  NewVideoInfo,
  UploadFileInfo,
  UploadService,
  UserRoleType,
} from '@openreel/common';

@Injectable({
  providedIn: 'root',
})
export class RecordingUploadService {
  get queue$() {
    return this.uploadService.queue$;
  }

  constructor(
    private readonly directorApi: DirectorApiService,
    private readonly uploadService: UploadService,
    private readonly authService: AuthService
  ) {}

  async upload(
    sessionId: number,
    videoInfo: NewVideoInfo,
    localFileName: string
  ): Promise<UploadFileInfo> {
    const response = await this.directorApi
      .createNewVideo({
        session_id: sessionId,
        videos: [videoInfo],
      })
      .toPromise();

    const responseVideo = response.videos[0];

    const videoId = responseVideo.ovra_session_videos_id;
    const metadata = {
      sessionId: sessionId,
      token: this.authService.getUserDetails().auth_token,
      role: UserRoleType.Internal,
      identity: videoInfo.identity,
      localFileName,
      resolution: Number(videoInfo.resolution),
      videoId: videoId,
      fileNameForUpload: `${videoInfo.video_name}`,
    };

    const uploadFileInfo = await this.uploadService.uploadVideoRecording(metadata);
    if (!uploadFileInfo) {
      throw new Error('uploadFileInfo returned null from uploader.');
    }
    return uploadFileInfo;
  }

  async uploadAllStoredRecordings() {
    await this.uploadService.uploadAllStoredRecordings();
  }

  cancelVideoUpload(videoId: number) {
    this.uploadService.cancelVideoUpload(videoId);
    this.directorApi.deleteVideo(videoId);
  }

  cancelAllUploads() {
    const cancelledVideoIds = this.uploadService.cancelAllUploads();
    cancelledVideoIds.forEach(videoId => this.directorApi.deleteVideo(videoId));
  }
}
