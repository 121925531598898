import { flatten, sortBy } from 'lodash';
import {
  Asset,
  AssetsFileProviderType,
  ElementAssetFeature,
  ELEMENT_ASSET_LOGO_ID,
  Layer,
  LayerDataChanges,
  LayersDataChangedEvent,
  LottieLayer,
  LottieLayerData,
  OrAssetsFile,
  Preset,
  Sections,
  SectionType,
  Style,
  Timeline,
  TimelinesLayer,
  TimelineType,
  VideoLayer,
  WorkflowDataDto,
} from '../interfaces';
import { getLayerFromId, getSectionsOfType } from './timelines.helpers';
import { v4 as uuidv4 } from 'uuid';

const SHORTER_VIDEO_FADE_TIME = 500;
const SHORTER_VIDEO_FADE_MAX_OFFSET = 100;

function flattenLayers(layers: Layer[], timelineFilter?: (timeline: Timeline) => boolean): Layer[] {
  return flatten(
    layers.map((layer) => {
      if (layer.type === 'timelines') {
        return flatten(
          (layer as TimelinesLayer).children
            .filter((t) => (timelineFilter ? timelineFilter(t) : true))
            .map((c) => flattenLayers(c.layers))
        );
      }

      return layer;
    })
  );
}

export function getMainClips(dto: WorkflowDataDto, timelineFilter?: (timeline: Timeline) => boolean): VideoLayer[] {
  const mainTimelines = getSectionTypeTimelinesByType(dto.sections, 'main', 'main');
  const topLayers: Layer[] = [];

  const addLayers = (timelines: Timeline[]) => {
    for (const timeline of timelines) {
      for (const layer of timeline.layers) {
        if (layer.type === 'video') {
          topLayers.push(layer);
        } else if (layer.type === 'timelines') {
          addLayers(layer.children);
        }
      }
    }
  };

  addLayers(mainTimelines);

  return flattenLayers(topLayers, timelineFilter).filter((layer) => layer.type === 'video') as VideoLayer[];
}

export function assetTypeToAssetFileProviderType(type: string): AssetsFileProviderType {
  switch (type) {
    case 'asset':
      return 'or-assets';
    case 'video':
      return 'or-recordings';
    default:
      return null;
  }
}

export function assetFileProviderTypeToAssetType(type: AssetsFileProviderType): string {
  switch (type) {
    case 'or-assets':
      return 'asset';
    case 'or-recordings':
      return 'video';
    default:
      return null;
  }
}

export function getTimelineDuration(timeline: Timeline): number {
  if (timeline.layers.length === 0) {
    return 0;
  }

  const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);

  if (sortedLayers[0].type === 'timelines') {
    const timelinesLayer = sortedLayers[0] as TimelinesLayer;
    return timelinesLayer.children.reduce((prev, curr) => Math.max(prev, getTimelineDuration(curr)), 0);
  }

  return sortedLayers[sortedLayers.length - 1].visibility.endAt - sortedLayers[0].visibility.startAt;
}

export function updateMainSceneTransitions(workflow: WorkflowDataDto) {
  const introSectionIds = Object.entries(workflow.sections)
    .filter(([, section]) => section.sectionType === 'intro')
    .map(([key]) => key);
  const mainSectionIds = Object.entries(workflow.sections)
    .filter(([, section]) => section.sectionType === 'main')
    .map(([key]) => key);

  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  const introSectionLayers = mainTimeline.layers.filter(
    (l) => l.type === 'section' && introSectionIds[0] === l.sectionId
  );
  const mainSectionLayers = mainTimeline.layers.filter(
    (l) => l.type === 'section' && mainSectionIds.includes(l.sectionId)
  );

  if (mainSectionLayers.length === 0) {
    return;
  }

  if (mainSectionLayers.length === 1 && introSectionLayers.length > 0) {
    mainSectionLayers[0].transitions = { ...introSectionLayers[0].transitions };
  }

  if (mainSectionLayers.length > 1) {
    const transitions =
      mainSectionLayers.find((l) => !!l.transitions)?.transitions ?? introSectionLayers[0]?.transitions;
    mainSectionLayers.forEach((layer, index, arr) => {
      layer.transitions = index < arr.length - 1 ? null : transitions;
    });
  }
}

export function updateMainClipTransitions(workflow: WorkflowDataDto) {
  const sections = getSectionTimelines(workflow.sections, 'main', 'main');

  sections.forEach(({ timelines }) => {
    if (timelines.length < 2) {
      return;
    }

    const mainTimelineDuration = timelines.reduce((prev, curr) => Math.max(prev, getTimelineDuration(curr)), 0);

    const fullLengthTimelines = timelines.filter((timeline) => getTimelineDuration(timeline) === mainTimelineDuration);
    const shorterTimelines = timelines.filter((timeline) => getTimelineDuration(timeline) < mainTimelineDuration);

    fullLengthTimelines.forEach((timeline) => {
      timeline.layers.forEach((layer) => {
        layer.transitions = {};
      });
    });

    shorterTimelines.forEach((timeline) => {
      timeline.layers.sort((a: Layer, b: Layer) => a.visibility.startAt - b.visibility.startAt);

      let lastVideoEndAt = 0;
      timeline.layers.forEach((layer, index) => {
        const { startAt, endAt } = layer.visibility;
        layer.transitions = {};

        if (startAt - lastVideoEndAt > SHORTER_VIDEO_FADE_MAX_OFFSET) {
          layer.transitions.entrance = {
            duration: SHORTER_VIDEO_FADE_TIME,
            type: 'fade-in',
          };
        }

        if (index !== timeline.layers.length - 1) {
          const nextLayer = timeline.layers[index + 1];
          if (endAt + SHORTER_VIDEO_FADE_MAX_OFFSET < nextLayer.visibility.startAt) {
            layer.transitions.exit = {
              duration: SHORTER_VIDEO_FADE_TIME,
              type: 'fade-out',
            };
          }
        }

        if (index === timeline.layers.length - 1) {
          if (endAt + SHORTER_VIDEO_FADE_MAX_OFFSET < mainTimelineDuration) {
            layer.transitions.exit = {
              duration: SHORTER_VIDEO_FADE_TIME,
              type: 'fade-out',
            };
          }
        }

        lastVideoEndAt = endAt;
      });
    });
  });
}

export function getOrderedPresetFields(preset: Preset) {
  return Object.keys(preset)
    .map((field) => ({ field, order: preset[field].order || 0 }))
    .sort((a, b) => a.order - b.order)
    .map((d) => d.field);
}

export function getChangesEventFromLottieLayer(layer: LottieLayer, workflow: WorkflowDataDto) {
  const result: LayerDataChanges = {
    assetChanges: {},
    styleChanges: {},
    values: {},
  };
  const assetId = layer.assetId;
  const asset = assetId ? workflow.assets.find((a) => a.id === assetId) : null;

  for (const key of getOrderedPresetFields(asset.preset)) {
    const presetField = asset.preset[key];
    const dataField = layer.data[key];
    const style = dataField.styleId ? workflow.styles.find((s) => s.id === dataField.styleId) : null;
    switch (presetField.type) {
      case 'text': {
        result.values[key] = {
          type: presetField.type,
          styleId: null,
          value: dataField.value,
        };
        result.styleChanges[key] = {
          color: style?.color,
          fontIndex: style?.fontIndex,
          fontWeight: style?.fontWeight,
        };
        break;
      }
      case 'image':
      case 'logo': {
        result.assetChanges[key] = {
          newAssetFileId: +asset?.file.path,
          removedAssetId: null,
        };
        result.values[key] = {
          type: presetField.type,
          assetId: assetId,
        };
        break;
      }
      case 'shape': {
        result.values[key] = {
          type: presetField.type,
          styleId: null,
          value: dataField.value,
        };
        result.styleChanges[key] = {
          color: style?.color,
        };
        break;
      }
    }
  }

  return result;
}

export function getChangesEventFromLayers(layerIds: string[], workflow: WorkflowDataDto) {
  const changes: LayersDataChangedEvent = {};

  for (const layerId of layerIds) {
    const layerInfo = getLayerFromId(layerId, workflow);
    if (!layerInfo) {
      throw new Error(`Layer id: ${layerId} not found in workflow.`);
    }

    let layerChanges: LayerDataChanges;
    switch (layerInfo.layer.type) {
      case 'lottie': {
        layerChanges = getChangesEventFromLottieLayer(layerInfo.layer, workflow);
        break;
      }
      case 'image':
      case 'video': {
        const assetId = layerInfo.layer.assetId;
        const asset = assetId ? workflow.assets.find((a) => a.id === assetId) : null;
        layerChanges = {
          assetChanges: {
            default: {
              newAssetFileId: +asset?.file.path,
              removedAssetId: null,
            },
          },
          styleChanges: {},
          values: {
            default: {
              type: layerInfo.layer.type,
              assetId: assetId,
            },
          },
        };
        break;
      }
    }

    changes[layerId] = layerChanges;
  }

  return changes;
}

export function getSectionTimelines(
  sections: Sections,
  sectionType: SectionType,
  timelineType: TimelineType,
  sectionId?: string
) {
  const filteredSections = Object.entries(sections)
    .filter(([key, section]) => section.sectionType === sectionType && (!sectionId || key === sectionId))
    .map(([key, section]) => ({ sectionId: key, timelines: section.timelines }));

  const filteredSectionTimelines: { sectionId: string; timelines: Timeline[] }[] = [];
  filteredSections.forEach((section) => {
    const timelinesForType = section.timelines.filter((t) => t.type === timelineType);

    if (timelineType !== 'main') {
      filteredSectionTimelines.push({ sectionId: section.sectionId, timelines: timelinesForType });
      return;
    }

    if (timelinesForType.length === 0) {
      throw new Error(`There needs to be at least one timeline with type 'main'`);
    }

    if (timelinesForType[0].layers.length === 0 || timelinesForType[0].layers[0].type !== 'timelines') {
      filteredSectionTimelines.push({
        sectionId: section.sectionId,
        timelines: [timelinesForType[0]],
      });
    } else {
      filteredSectionTimelines.push({
        sectionId: section.sectionId,
        timelines: (timelinesForType[0].layers[0] as TimelinesLayer).children,
      });
    }
  });

  return filteredSectionTimelines;
}

export function getSectionTypeTimelinesByType(
  sections: Sections,
  sectionType: SectionType,
  timelineType: TimelineType,
  sectionId: string = null
): Timeline[] {
  return getSectionTypeTimelines(sections, sectionType, sectionId).filter((t) => t.type === timelineType);
}

export function getSectionTypeTimelines(
  sections: Sections,
  sectionType: SectionType,
  sectionId: string = null
): Timeline[] {
  const filteredSections = getSectionsOfType(sections, sectionType).filter(
    (e) => !sectionId || e.sectionId === sectionId
  );
  return filteredSections.reduce((arr, { section }) => {
    arr.push(...section.timelines);
    return arr;
  }, []);
}

export function getDefaultPresetData(
  asset: Asset,
  workflow: WorkflowDataDto
): {
  data: LottieLayerData;
  styles: Style[];
} {
  const styles = [];
  const data = {};

  for (const [key, field] of Object.entries(asset.preset)) {
    let color = null;
    if (field.colorTag) {
      const colorTag = asset.colorTags.find((t) => t.tag === field.colorTag);
      color = colorTag.color;
    }

    const style: Style = {
      id: uuidv4(),
      fontWeight: field.fontWeight,
      fontIndex: field.fontIndex,
      color,
      colorShade: field.colorShade,
    };
    styles.push(style);

    if (field.type === 'logo') {
      const logoAsset = workflow.features.wizard.elements.find(
        (e) => e.type === 'asset' && e.id === ELEMENT_ASSET_LOGO_ID
      ) as ElementAssetFeature;
      if (logoAsset) {
        field.assetId = logoAsset.asset.id;
      }
    }

    data[key] = {
      type: field.type,
      value: field.defaultValue,
      assetId: field.assetId,
      styleId: style.id,
    };
  }

  return { data, styles };
}

export const pxToPct = (value: number, max: number) => ((value ?? 0) / max) * 100;

export const getMaxTimelinesDuration = (timelines: Timeline[]) => {
  const mainTimelines = timelines.filter((t) => t.type === 'main');
  return mainTimelines.reduce((prev, curr) => Math.max(prev, getTimelineDuration(curr)), 0);
};

export function getIntroOutroLayerIds(intro: boolean, source: WorkflowDataDto) {
  const sectionType = intro ? 'intro' : 'outro';
  const feature = source.features[sectionType];
  const layerIds = [...(feature?.dataLayerIds || [])];

  if (layerIds.length === 0) {
    const mainTimeline = getSectionTypeTimelines(source.sections, sectionType).find((t) => t.type === 'main');
    if (mainTimeline.layers.length > 0) {
      layerIds.push(mainTimeline.layers[0].layerId);
    }
  }

  return layerIds;
}

export function assetFileEquals(asset1: OrAssetsFile, asset2: OrAssetsFile) {
  return asset1.path === asset2.path && asset1.provider === asset2.provider;
}
