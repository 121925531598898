<div *ngIf="number || overrideCountdown"
    class="countdown" [ngClass]="['countdown-' + for]"
    fxLayout="column"
    fxLayoutAlign="center center">
  <div>
    <div class="countdown-counter">
      <div class="countdown-number-wrapper mat-elevation-z10">
        <div class="countdown-number">
          {{ overrideCountdown != null ? overrideCountdown : number }}
        </div>
      </div>
    </div>
    <div class="countdown-text mat-elevation-z10">
      {{messages[for]}}
    </div>
  </div>
</div>