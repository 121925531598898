import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RecordingType } from '../../extension';

@Component({
  selector: 'openreel-double-video',
  templateUrl: './double-video.component.html',
  styleUrls: ['./double-video.component.scss'],
})
export class DoubleVideoComponent {
  @Input() screenStream: MediaStream | string = null;
  @Input() webcamStream: MediaStream | string = null;

  @Input() recordingType: RecordingType;
  @Input() mirrored = true;

  @Input() autoplay = true;
  @Output() onScreenStreamPlaying = new EventEmitter();
  @Output() onWebcamStreamPlaying = new EventEmitter();

  onChildPlaying(stream: 'webcam' | 'screen') {
    if (stream === 'screen') {
      setTimeout(() => this.onScreenStreamPlaying.emit(), 500);
    }
    if (stream === 'webcam') {
      setTimeout(() => this.onWebcamStreamPlaying.emit(), 500);
    }
  }
}
