import { WorkflowDataDto } from './../interfaces/workflow.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface CommandResult<R> {
  success: boolean;
  errorMessage?: string;
  result?: R;
  updatedWorkflow?: WorkflowDataDto;
}

export abstract class BaseCommand<T, R = null> extends WorkflowBaseBuilder {
  abstract run(event: T): CommandResult<R>;

  ok(result?: R): CommandResult<R> {
    return {
      success: true,
      result,
      updatedWorkflow: this.toWorkflow(),
    };
  }

  error(errorMessage: string): CommandResult<R> {
    return {
      success: false,
      errorMessage
    };
  }
}
