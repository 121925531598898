<h2 mat-dialog-title class="create-title">Select File</h2>

<mat-dialog-content>
    <div class="selector-container">
        <or-user-upload-selector (uploadStarted)="uploadStarted($event)"></or-user-upload-selector>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
        Cancel
    </button>
</mat-dialog-actions>