import { fabric } from 'fabric';
import { CanvasConfig } from '../canvas.config';

const FABRIC_TYPE = 'track';

export interface TrackDataOptions {
  emptyText: string;
  childrenCount: number;
  config: CanvasConfig;
}

export interface TrackOptions extends fabric.IRectOptions {
  data: TrackDataOptions;
}

export class Track extends fabric.Group {
  type: typeof FABRIC_TYPE;

  trackRect: fabric.Rect;
  trackEmptyText: fabric.Text;

  data: TrackDataOptions;

  constructor(options: TrackOptions) {
    const objects: fabric.Object[] = [];

    objects.push(
      new fabric.Rect({
        rx: 4,
        ry: 4,
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        hasControls: false,
        selectable: false,
        strokeWidth: 0,
        fill: options.data.config.timelineTrackOptions.backgroundColorLight,
      }),

      new fabric.Text(options.data.emptyText, {
        left: 0,
        top: 0,
        fill: options.data.config.timelineTrackOptions.textColorLight,
        fontFamily: 'Rubik',
        fontSize: 12,
      })
    );

    super(objects, { ...options, type: FABRIC_TYPE });

    this.trackRect = objects[0] as fabric.Rect;
    this.trackEmptyText = objects[1] as fabric.Text;
  }

  render(ctx: CanvasRenderingContext2D) {
    if (this.visible) {
      this.trackRect.set({
        left: -this.width / 2,
        top: -this.height / 2,
        width: this.width,
        height: this.height,
      });
      this.trackRect.setCoords();

      this.trackEmptyText.set({
        opacity: this.data.childrenCount === 0 ? 1 : 0,
      });
      this.trackEmptyText.setCoords();
    }

    super.render(ctx);
  }
}
