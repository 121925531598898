import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  SessionData,
  DirectorSession,
  CreateSessionResponse,
  SessionRecordingsResponse,
  SessionDataResponseBody,
  SessionListParams,
  SessionIndexResponse,
  SessionListResponse,
  SingleSessionInList,
  OldSessionListResponse,
  OldSessionList,
  StreamType,
  Recording,
} from '../../interfaces/interfaces';
import { Member } from '../../interfaces/team.interface';
import { TeleprompterPresetsDto } from '../../interfaces/teleprompter.interface';
import {
  SessionSettingsDto,
  SessionProjectDto,
  SessionArchivedData,
  DashboardSummarySessionsResponse,
  UpdateResendInvitation,
  SessionType,
} from './session.interfaces';
import { map } from 'rxjs/operators';
import { CreateUpdateUgcSessionData, CreateUpdateUgcSessionPayload, UgcSessionResponse } from '@openreel/common';

interface CreateSessionRequest {
  session_name: string;
  members: Member[];
  project_id: number;
  stream_type?: StreamType;
  session_type?: SessionType;
}

interface UpdateSessionRequest {
  session_name?: string;
  internal_members?: Member[];
}
@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  public urls = {
    // index: commonenv.apiUrl + 'all_sessions',
    index: commonenv.nextGenApiUrl + 'sessions/query',
    list: (params: SessionListParams) => {
      const url = new URL(commonenv.nextGenApiUrl + 'sessions/list');
      for (const param in params) {
        if (Object.keys(params).includes(param)) {
          url.searchParams.append(param, params[param]);
        }
      }
      return url.toString();
    },
    get: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}`,
    create: commonenv.nextGenApiUrl + 'sessions/create',
    getArchivedSessions: commonenv.nextGenApiUrl + 'sessions/archived',
    update: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}`,
    delete: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}`,
    getSessionRecordings: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/recordings`,
    getSessionSettings: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/settings`,
    putSessionSettings: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/settings`,
    getTeleprompterPresets: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/teleprompter-presets`,
    putTeleprompterPresets: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/teleprompter-presets`,
    getSessionProjectData: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/project`,
    getRestoreSessions: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/restore`,

    getDashboardSummarySessions: commonenv.nextGenApiUrl + 'sessions/dashboard-recent-sessions',
    removeInviteeImmediately: (userMappingId: number) =>
      `${commonenv.nextGenApiUrl}invitations/remove-invitee-immediately/${userMappingId}`,
    resendInvitationMail: (userMappingId: number) =>
      `${commonenv.nextGenApiUrl}invitations/re-send-invitation-mail/${userMappingId}`,
    updateAndResendInvitationMail: (userMappingId: number) =>
      `${commonenv.nextGenApiUrl}invitations/update-resend-invitee-mail/${userMappingId}`,

    webUserOnline: (sessionId: number) => `${commonenv.nextGenApiUrl}sessions/${sessionId}/web-user-online`,
    requestForApproval: commonenv.nextGenApiUrl + 'admission/request-approval',
    multipleSession: (params: SessionListParams) => {
      const url = new URL(commonenv.nextGenApiUrl + 'sessions');
      for (const param in params) {
        if (Object.keys(params).includes(param)) {
          url.searchParams.append(param, params[param]);
        }
      }
      return url.toString();
    },

    importSessions: commonenv.nextGenApiUrl + 'import-old-session',
    createUgc: commonenv.nextGenApiUrl + 'ugc-sessions',
    updateUgc: (sessionId: number) => `${commonenv.nextGenApiUrl}ugc-sessions/${sessionId}`,
    getUgcSessionDetails: (sessionId: number) => `${commonenv.nextGenApiUrl}ugc-sessions/${sessionId}`,
    getVideoDetails: (videoId: number) => `${commonenv.nextGenApiUrl}videos/${videoId}`,
  };
  constructor(public httpClient: HttpClient) {}

  getDashboardSummarySessions(): Observable<DashboardSummarySessionsResponse> {
    return this.httpClient.get<DashboardSummarySessionsResponse>(this.urls.getDashboardSummarySessions);
  }

  getSessionData(sessionId): Observable<SessionDataResponseBody<DirectorSession>> {
    return this.httpClient
      .get<SessionData<DirectorSession>>(this.urls.get(sessionId))
      .pipe(map((response) => response.data));
  }

  getSessionList(params: SessionListParams): Observable<SingleSessionInList[]> {
    return this.httpClient.post<SessionListResponse>(this.urls.list(params), {}).pipe(map((response) => response.data));
  }

  index(
    text: string = null,
    page = 1,
    limit = 10,
    startDate = '',
    endDate = '',
    status = ['', '1', '2', '3', '0', '-1'],
    period = ''
  ) {
    const data = {
      limit,
      page,
      search_string: text,
      start_date: !startDate ? '' : startDate,
      end_date: !endDate ? '' : endDate,
      status,
      period,
    };
    return this.httpClient.post(this.urls.index, data) as Observable<SessionIndexResponse>;
  }

  async createSession(data: CreateSessionRequest) {
    const response = await this.httpClient
      .post<CreateSessionResponse>(this.urls.create, {
        is_safari_support: 0,
        session_id: '',
        session_name: data.session_name,
        project_id: data.project_id,
        members: data.members,
        session_type: data.session_type,
        stream_type: data.stream_type ?? StreamType.Twilio,
      })
      .toPromise();

    return response.data;
  }

  async updateSession(session_id: number, data: UpdateSessionRequest) {
    return await this.httpClient.put(this.urls.update(session_id), data).toPromise();
  }

  deleteSession(session_id: number, isTrash: string) {
    return this.httpClient.delete(this.urls.delete(session_id), {
      params: { is_trash: isTrash },
    });
  }

  async createUgcSession(createSessionPayload: CreateUpdateUgcSessionPayload): Promise<CreateUpdateUgcSessionData> {
    const response = await this.httpClient
      .post<UgcSessionResponse>(this.urls.createUgc, createSessionPayload)
      .toPromise();

    return response.data;
  }

  async updateUgcSession(
    session_id: number,
    updateSessionPayload: CreateUpdateUgcSessionPayload
  ): Promise<CreateUpdateUgcSessionData> {
    const response = await this.httpClient
      .patch<UgcSessionResponse>(this.urls.updateUgc(session_id), updateSessionPayload)
      .toPromise();

    return response.data;
  }

  async getUgcSessionData(sessionId) {
    const response = await this.httpClient
      .get<UgcSessionResponse>(this.urls.getUgcSessionDetails(sessionId))
      .toPromise();
    return response.data;
  }

  getSessionRecordings(sessionId: number, params: HttpParams): Promise<SessionRecordingsResponse> {
    return this.httpClient
      .get<SessionRecordingsResponse>(this.urls.getSessionRecordings(sessionId), { params })
      .toPromise();
  }

  getVideoDetails(videoId: number) {
    return this.httpClient.get<Recording>(this.urls.getVideoDetails(videoId)).toPromise();
  }

  getSessionSettings(sessionId: number): Observable<SessionSettingsDto> {
    return this.httpClient.get<SessionSettingsDto>(this.urls.getSessionSettings(sessionId));
  }

  updateSessionSettings(sessionId: number, payload: SessionSettingsDto): Observable<SessionSettingsDto> {
    return this.httpClient.put<SessionSettingsDto>(this.urls.putSessionSettings(sessionId), payload);
  }

  async getTeleprompterPresets(sessionId: number): Promise<TeleprompterPresetsDto> {
    return this.httpClient.get<TeleprompterPresetsDto>(this.urls.getTeleprompterPresets(sessionId)).toPromise();
  }

  async updateTeleprompterPresets(sessionId: number, payload: TeleprompterPresetsDto): Promise<TeleprompterPresetsDto> {
    return this.httpClient
      .put<TeleprompterPresetsDto>(this.urls.putTeleprompterPresets(sessionId), payload)
      .toPromise();
  }

  async getSessionProjectData(sessionId: number) {
    return this.httpClient.get<SessionProjectDto>(this.urls.getSessionProjectData(sessionId)).toPromise();
  }

  getProjectArchivedSessions(projectId: number) {
    return this.httpClient.get<SessionArchivedData[]>(this.urls.getArchivedSessions, {
      params: { project_id: projectId.toString() },
    });
  }
  restoreSession(sessionId: number) {
    return this.httpClient.put(this.urls.getRestoreSessions(sessionId), {});
  }

  removeInviteeImmediately(userMappingId: number, deviceLogId?: number) {
    let url = this.urls.removeInviteeImmediately(userMappingId);
    if (deviceLogId) {
      url = `${this.urls.removeInviteeImmediately(userMappingId)}?device_log_id=${deviceLogId}`;
    }
    return this.httpClient.delete(url);
  }

  resendInvitationMail(userMappingId: number) {
    return this.httpClient.patch(this.urls.resendInvitationMail(userMappingId), {});
  }

  updateAndResendInvitationMail(userMappingId: number, params: UpdateResendInvitation) {
    return this.httpClient.patch(this.urls.updateAndResendInvitationMail(userMappingId), params);
  }

  requestForApproval(sessionId: number, loginId: number) {
    return this.httpClient.post<{
      data: { request_sent: boolean; login_id?: number };
    }>(this.urls.requestForApproval, {
      session_id: sessionId,
      login_id: loginId,
    });
  }

  checkingWebUserInSession(sessionId: number) {
    return this.httpClient.get<{
      data: { web_user_count: number; director_count: number };
    }>(this.urls.webUserOnline(sessionId));
  }

  getOldSessions(params: SessionListParams): Observable<OldSessionList[]> {
    params.old_session = true;
    return this.httpClient
      .get<OldSessionListResponse>(this.urls.multipleSession(params), {})
      .pipe(map((response) => response.result));
  }

  importSessions(sessionIds: number[], projectName?: string, projectId?: number) {
    return this.httpClient.post<{
      message: string;
    }>(this.urls.importSessions, {
      project_name: projectName,
      project_id: projectId,
      session_ids: sessionIds,
    });
  }
}
