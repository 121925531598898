import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

interface OptionType {
  value: number | string;
  label?: string;
}

@Component({
  selector: 'openreel-text-slider',
  templateUrl: './or-text-slider.component.html',
  styleUrls: ['./or-text-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrTextSliderComponent implements OnInit, OnChanges {
  @Input() availableOptions: OptionType[];
  @Input() selectedValue: number | string;
  @Input() disableSelection: boolean;
  @Output() selectionChange = new EventEmitter<string>();

  displayStartIndex: number;
  displayStopIndex: number;
  maxDisplay: number = 3;
  displayOptions: any;
  disableNext: boolean;
  disablePrev: boolean;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      JSON.stringify(changes.availableOptions?.currentValue) !==
      JSON.stringify(changes.availableOptions?.previousValue)
    ) {
      this.displayStartIndex = 0;
      this.displayStopIndex = 3;
      this.displayOptions = this.availableOptions.slice(0, this.maxDisplay);
      this.disableIcons();
    }
  }

  ngOnInit(): void {}

  showPrevious() {
    if (
      this.availableOptions.length > this.maxDisplay &&
      this.displayStartIndex > 0
    ) {
      this.displayStartIndex -= 1;
      this.displayStopIndex -= 1;
      this.displayOptions = this.availableOptions.slice(
        this.displayStartIndex,
        this.displayStopIndex
      );
    }
    this.disableIcons();
  }

  showNext() {
    if (
      this.availableOptions.length > this.maxDisplay &&
      this.displayStopIndex < this.availableOptions.length
    ) {
      this.displayStartIndex += 1;
      this.displayStopIndex += 1;
      this.displayOptions = this.availableOptions.slice(
        this.displayStartIndex,
        this.displayStopIndex
      );
    }
    this.disableIcons();
  }

  disableIcons() {
    if (this.displayStartIndex === 0) {
      this.disablePrev = true;
    } else {
      this.disablePrev = false;
    }
    if (this.displayStopIndex === this.availableOptions.length) {
      this.disableNext = true;
    } else {
      this.disableNext = false;
    }
  }

  changeSelection(value) {
    this.selectionChange.next(value);
  }
}
