import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { AudioMeterService } from 'libs/common/src/services';
import { AudioMeterBase } from '../audio-meter.base';

@Component({
  selector: 'openreel-audio-meter',
  templateUrl: './audio-meter.component.html',
  styleUrls: ['./audio-meter.component.scss'],
  providers: [AudioMeterService],
})
export class AudioMeterComponent extends AudioMeterBase
  implements AfterViewInit, OnDestroy {
  @ViewChild('canvas')
  canvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  public width;
  public height;


  public interval;

  public previousVolume = 0;
  levels = [
    {
      color: '#61d55b',
      stop: 0,
    },
    {
      color: '#61d55b',
      stop: 0.4,
    },
    {
      color: '#f5aa00',
      stop: 0.6,
    },
    {
      color: '#ea6e13',
      stop: 0.8,
    },
    {
      color: '#ff0000',
      stop: 1,
    },
  ];

  ngAfterViewInit(): void {
    this.context = this.canvas.nativeElement.getContext('2d');
    this.width = this.elementRef.nativeElement.offsetWidth;
    this.height = this.elementRef.nativeElement.offsetHeight;

    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;

    /**
     * Why interval? check the requestAnimationFrame comment below. 
     */
    /* this.interval = setInterval(()=>{
      if(this.value <= this.previousVolume) {
        this.value = this.previousVolume * this.smoothingFactor;
      }
      this.previousVolume = this.value;
      console.log('Perforrmance ver', this.value)
      this.tick();
      
    }, 100); */
  }

  constructor(
    public audioMeter: AudioMeterService,
    public zone: NgZone,
    public elementRef: ElementRef
  ) {
    super(audioMeter, zone);
  }
  tick() {
    /**
     * If you need to replace the interval with request animation frame
     * This isn't  a good idea with everything going on, the processing will end up happening all the time. 
     */
    // requestAnimationFrame(() => this.tick());

    let grd = this.context.createLinearGradient(0, 0, this.width, 0);

    if (this.vertical === true) {
      grd = this.context.createLinearGradient(0, this.height, 0, 0);
    }

    for (const level of this.levels) {
      grd.addColorStop(level.stop, level.color);
    }

    // Fill with gradient
    this.context.fillStyle = grd;
    this.context.fillRect(0, 0, this.width, this.height);

    if (this.vertical === false) {
      this.context.clearRect(
        (this.width * this.value) / 100,
        0,
        this.width,
        this.height
      );
    } else {
      this.context.clearRect(
        0,
        0,
        this.width,
        this.height * (1 - this.value / 100)
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    clearInterval(this.interval);
    console.log('AudioMeterComponent Destroy');
  }
}
