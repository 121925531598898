<mat-form-field
  *ngIf="sortdata"
  [appearance]="appearance"
>
  <mat-label>
    {{ labelname }}
  </mat-label>
  <mat-select
    data-cy="select-sorting"
    (selectionChange)="searchChange($event)"
    [value]="defaultValue"
  >
	   <mat-option
      *ngFor="let data of sortdata"
      [value]="data"
      [attr.data-cy]="data.label + '-sort-label'"
    >
      {{ data.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
