import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import {ILocalRecorderService} from '../services/subject/local-recording/local-recorder-base.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'downloadUrl' })
export class DownloadUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private recorder: ILocalRecorderService) {}

  transform(localFileName: string) {
    return from(this.recorder.getFileData(localFileName)).pipe(
      map(recordingBlob => 
        this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(recordingBlob)))
    )
  }
}
