<div class="timelines">
  <div *ngFor="let player of backgroundPlayers | async; let i = index">
    <openreel-cue-player-controller
      [data]="player"
      (onLoaded)="onChildLoaded()"
      (onError)="onChildError($event)"
      [style.z-index]="1 + player.playerData.zIndex"
    ></openreel-cue-player-controller>
  </div>
  <openreel-cue-player-controller-main
    [data]="mainPlayers | async"
    (onLoaded)="onChildLoaded()"
    (onError)="onChildError($event)"
    (onTimeUpdate)="onMainTimeUpdate($event)"
    (onSeeked)="onMainSeeked()"
    (onEnded)="onMainEnded()"
  ></openreel-cue-player-controller-main>
  <div *ngFor="let player of overlayPlayers | async; let i = index">
    <openreel-cue-player-controller
      [data]="player"
      (onLoaded)="onChildLoaded()"
      (onError)="onChildError($event)"
      [style.z-index]="i + 100"
    ></openreel-cue-player-controller>
  </div>
</div>
