import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

// Class that provides global state of application
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // Currently active component in router.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeComponent$ = new BehaviorSubject<any>(null);
}
