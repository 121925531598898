import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Cleanupable } from '@openreel/common/classes';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'openreel-select-search',
  templateUrl: './openreel-select-search.component.html',
})
export class OpenreelSelectSearchComponent extends Cleanupable implements OnInit {
  @Input() appearance: MatFormFieldAppearance;
  @Input() label: string;
  @Input() optionLabel: string;
  @Input() options: Observable<unknown[]>;

  @Input() set selectedOption(selectedOption: unknown) {
    if (selectedOption !== this._selectedOption) {
      this._selectedOption = selectedOption;
      this.control.setValue(selectedOption && selectedOption[this.optionLabel]);
    }
  }
  get selectedOption(): unknown {
    return this._selectedOption;
  }
  private _selectedOption: unknown;
  @Output() selectedOptionChange = new EventEmitter<unknown>();

  @Output() searchOptions = new EventEmitter<string>();
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

  control = new FormControl();
  subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.control.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100))
      .subscribe(() => this.onSearchOptions());
  }

  onOptionSelected(value: unknown) {
    this._selectedOption = value;
    this.selectedOptionChange.emit(value);
    this.reset();
  }

  onSearchOptions(): void {
    this.searchOptions.emit(this.control.value);
  }

  onBlur(): void {
    if (!this.autocomplete?.isOpen) {
      this.reset();
    }
  }

  reset() {
    this.control.setValue(this.selectedOption && this.selectedOption[this.optionLabel]);
    this.searchOptions.emit(this.control.value);
  }
}
