import { getTimelineById } from '../helpers/timelines.helpers';
import { BaseCommand } from './base.command';

export class VideoOverlayToggleCommand extends BaseCommand<boolean> {
  run(enabled: boolean) {
    this.source.features.wizard.videoOverlays.enabled = enabled;

    const { timeline } = getTimelineById(this.source.features.wizard.videoOverlays.timelineId, this.source.sections);
    timeline.layers.forEach((layer) => (layer.enabled = enabled));

    return this.ok();
  }
}
