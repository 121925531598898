import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiSingleChatMessage } from './chat.interface';

export interface ConversationResponse {
  messages: ApiSingleChatMessage[];
}

@Injectable({ providedIn: 'root' })
export class ChatApiService {
  urls = {
    getConversation: (sessionId: number, convId: number): string =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/conversations/${convId}`,
  };

  constructor(private http: HttpClient) {}

  getConversation(
    sessionId: number,
    loginId: number,
    convId: number,
    page: number,
    limit: number
  ): Observable<ConversationResponse> {
    return this.http
      .get<ConversationResponse>(this.urls.getConversation(sessionId, convId), {
        params: {
          page: page.toString(),
          limit: limit.toString(),
          loginId: loginId.toString(),
        },
      })
      .pipe(
        catchError((error) => {
          console.error(
            error.error instanceof ErrorEvent
              ? error.error.message
              : error.message
          );
          return of({ messages: [] });
        })
      );
  }
}
