import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'openreel-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent {

  @Input() formCtrl: FormControl;
  @Input() maxEmails = 5;
  @Input() emailList: string[] = [];

  @ViewChild('chipWrapper', {static: true})
  chipWrapper: ElementRef<HTMLDivElement>;
  
  private happyEmail = 'happy@email.com';
  
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  validation_messages = {
    'email': [
      { type: 'required', message: 'At least one Email address required' },
      { type: 'email', message: 'Invalid Email address format' },
      { type: 'duplicate', message: 'Duplicate email address' },
    ]
  }

  //
  // limit number of email adresses 
  //
  isMoreEmailsAllow(){
    return this.emailList.length < this.maxEmails;
  }

  //
  // add to the list
  //
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if(value){
      // test for copy/paste list of emails
      const csv = value.split(',');
      if(csv.length > 1){
        for(let i = 0; i < csv.length; i++){
          const email = csv[i].replace(/['"]+/g, '').trim();

          this.formCtrl.setValue(email);
          if(this.formCtrl.valid === false){
            this.formCtrl.setValue(this.happyEmail); //keep validator happy after 'bad' email
            continue; // skip bad emails
          }

          // prevent duplicate emails
          if(this.emailList.includes(email)){
            continue;
          }         

          this.emailList.push(email);

          // check for max emails
          if(this.emailList.length >= this.maxEmails){
            break;
          }
        }
      }else{
        // validate
        this.formCtrl.setValue(value);
        if(this.formCtrl.valid === false){
          return;
        }
        // replace with happy email - need for duplicate validation
        this.formCtrl.setValue(this.happyEmail);
        
        this.emailList.push(value);
      }
    }else {
      // the message is coming with empty value when control loses focus
      // we will set validator happy only when control has some chips
      if(this.emailList.length > 0){
        this.formCtrl.setValue(this.happyEmail);
      }
    }
    // Clear the input value
    event.chipInput.clear();
    setTimeout(() => 
      this.chipWrapper.nativeElement.scrollTop = this.chipWrapper.nativeElement.scrollHeight, 1);
  }

  //
  // remove from the list
  //
  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);
    }

    // invalidate
    if(this.emailList.length === 0){
      this.formCtrl.setValue(''); 
    }
  }
}
