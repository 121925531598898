import chroma from 'chroma-js'
import { range } from 'lodash';

export class ColorPaletteGenerator {
  static generatePalette(color: string) {
    const palette: string[] = chroma.scale(chroma.bezier(this.autoGradient(color)))
    .correctLightness()
    .colors(10);

    return palette.map((paletteColor, index) => ({
      hex: paletteColor,
      shade: index === 0 ? 50 : index * 100,
    }));
  }

  private static autoGradient(color: string, numColors = 10) {
    const lab = chroma(color).lab();
    const lRange = 100 * (1 - 1 / numColors);
    const lStep = lRange / (numColors - 1);
    const lStart = (100 - lRange) * 0.5;
    const colorRange = range(lStart, lStart + numColors * lStep, lStep);
    let offset = 9999;

    for (let i = 0; i < numColors; i++) {
      const diff = lab[0] - colorRange[i];
      if (Math.abs(diff) < Math.abs(offset)) {
        offset = diff;
      }
    }

    return colorRange.map(l => chroma.lab([l + offset, lab[1], lab[2]]));
  }
}
