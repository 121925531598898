<div class="login-form"  fxFlex="1 1" fxLayout="column">
    <mat-form-field appearance="outline">
      <mat-label>URL</mat-label>
      <input matInput placeholder="URL" [(ngModel)]="url">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input matInput placeholder="Password" [(ngModel)]="password"
        name="password">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="loginClick()">Login</button>
    <p class="footer-note">
      By signing in you agree to our <a href="https://www.openreel.com/enterprisetos/" target="_blank">Terms of service.</a>
    </p>
  </div>