<div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="space-between center">
  <div *ngIf="titleAlign == 'center' || titleAlign == 'end'" class="line" fxFlex="1 1 0%"></div>
  <div class="title">
    {{ title }}
  </div>
  <div *ngIf="titleAlign == 'center' || titleAlign == 'start'" class="line" fxFlex="1 1 0%"></div>

  <span style="color:lightslategrey" title="Destinations for your clips">
      <mat-icon (click)="isOpen = !isOpen" *ngIf="accountDestinations"
                      class="material-icons-outlined destination-upload-icon">
        cloud_upload
      </mat-icon>
      <span class="destinations-count" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        {{accountDestinations?.length == 0 ? '' : accountDestinations?.length}}
      </span>
  </span>

  <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="isOpen = !isOpen"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
    >
      <div class="destinations-overlay">
        <table class="dest-table" *ngIf="accountDestinations?.length > 0">
          <tr *ngFor="let dest of accountDestinations">
            <td class="dest-name">
              <mat-icon class="dest-dropbox-icon" svgIcon="dest_dropbox_dark_mode_default"></mat-icon>
              <span class="name-txt" style="color: black !important;">{{dest}}</span>
            </td>
          </tr>
        </table>
        <div style="color: black" class="no-destinations-defined" *ngIf="!accountDestinations || accountDestinations?.length === 0">
          No destinations.
        </div>
      </div>

    </ng-template>



  <ng-container *ngIf="s3IntegrationStatus">
    <ng-container *ngIf="s3IntegrationStatus.connected === true; else s3NotIntegrated">
      <div *ngIf="s3IntegrationStatus.keepCopy === true" style="width: 70px;" title="Session clips"><img
          openreelfallback="assets/common/images/logo.png" style="width:24px;vertical-align: middle;"
          src="assets/common/images/logo.png" alt="OpenReel"> + <h2 style="color:lightslategrey;vertical-align: middle;display: inline;">S3</h2>
      </div>
      <div *ngIf="s3IntegrationStatus.keepCopy === false" title="Session clips">
        <h2 style="color:lightslategrey">S3</h2>
      </div>
    </ng-container>
    <ng-template #s3NotIntegrated>
      <img openreelfallback="assets/common/images/logo.png" title="Session clips" style="width:24px" src="assets/common/images/logo.png" alt="OpenReel">
    </ng-template>
  </ng-container>
</div>
