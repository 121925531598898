import * as fromActions from '../actions/font.actions';
import * as fromProjectActions from '../actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProjectFacade } from '../facades/project.facade';
import { FontService } from 'libs/common/src/services/font/font.service';
import { of } from 'rxjs';

@Injectable()
export class FontEffects {
  loadTrendingFonts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTrendingFonts),
      switchMap(() =>
        this.fontService.trendingFonts().pipe(
          map((fonts) => {
            const trendingFonts = fonts.map((font) =>
              FontService.fontToFontAsset(font)
            );
            return fromActions.loadTrendingFontsSuccess({ trendingFonts });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  loadSuggestedFonts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuggestedFonts),
      switchMap(() =>
        this.fontService.suggestedFonts().pipe(
          map((fonts) => {
            const suggestedFonts = fonts.map((font) =>
              FontService.fontToFontAsset(font)
            );
            return fromActions.loadSuggestedFontsSuccess({ suggestedFonts });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  addExtraFontOnProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addExtraFontOnProject),
      withLatestFrom(this.projectFacade.workflow$),
      map(([{ font }, workflow]) => {
        let fonts = workflow.fonts;
        if (!fonts.find((el) => el.id === font.id)) {
          fonts = [...fonts, font];
        }

        return fromProjectActions.updateProjectWorkflowAPI({
          data: {
            ...workflow,
            fonts,
          },
        });
      })
    )
  );

  constructor(
    private readonly projectFacade: ProjectFacade,
    private readonly fontService: FontService,
    private actions$: Actions
  ) {}
}
