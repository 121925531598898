import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';

import { CueEventsService } from '../services/cue-events.service';
import { Cleanupable } from 'libs/common/src/classes/cleanupable';
import { ControlsOptions } from '@openreel/ui/openreel-video-controls/openreel-video-controls.component';

@Component({
  selector: 'openreel-cue-controls',
  templateUrl: './cue-controls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CueControlsComponent extends Cleanupable implements OnInit {
  @Input() currentTime: number;
  @Input() duration: number;
  @Input() playing = false;

  @Output() onSeek = new EventEmitter<number>();
  @Output() onPlay = new EventEmitter<void>();
  @Output() onPause = new EventEmitter<void>();
  @Output() onFullScreen = new EventEmitter<void>();

  @Input() options: ControlsOptions;

  muted = false;

  @HostListener('document:fullscreenchange') onFullscreenChange() {
    this.cueEventsService.setFullscreen(document.fullscreenElement !== null);
  }

  constructor(private readonly cueEventsService: CueEventsService) {
    super();
  }

  ngOnInit() {
    this.cueEventsService.volume$.pipe(first()).subscribe((value) => (this.muted = value === 0));
  }

  async play() {
    this.onPlay.emit();
  }

  pause() {
    this.onPause.emit();
  }

  mute() {
    this.cueEventsService.setVolume(0);
    this.muted = true;

    this.cueEventsService.setAnalyticsEvent('preview_mute', 'Preview player mute');
  }

  unmute() {
    this.cueEventsService.setVolume(1);
    this.muted = false;

    this.cueEventsService.setAnalyticsEvent('preview_unmute', 'Preview player unmute');
  }

  seek(time: number) {
    this.onSeek.emit(time);
  }

  async fullscreen() {
    this.onFullScreen.emit();

    this.cueEventsService.setAnalyticsEvent('preview_fullscreen', 'Toggle fullscreen preview');
  }

  exitFullscreen = () => document.exitFullscreen();
}
