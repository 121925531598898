import { TrimmerComponent } from './pages/trimmer/trimmer.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { EditorPreviewerModule } from '../editor-previewer/editor-previewer.module';
import { TimelinesModule } from '../timelines/timelines.module';
import { components } from './components';
import { OpenreelLoaderModule } from '@openreel/ui/openreel-loader';
import { OpenreelCommonModule } from '@openreel/common';

@NgModule({
  declarations: [...components, TrimmerComponent],
  imports: [
    SharedModule,
    OpenreelLoaderModule,
    OpenreelCommonModule,
    EditorPreviewerModule,
    TimelinesModule,
  ],
  exports: [TrimmerComponent],
  providers: [],
})
export class TrimmerModule {}
