import { Input, NgZone, OnDestroy, OnInit, Directive } from '@angular/core';
import { AudioMeterService } from '../../services';
import { Cleanupable } from '../../classes/cleanupable';
import { Observable } from 'rxjs';

@Directive()
export class AudioMeterBase extends Cleanupable implements OnDestroy, OnInit {
  /**
   * Audio track that should be monitored. Can be null.
   *
   * @type {MediaStreamTrack}
   * @memberof AudioMeterComponent
   */
  @Input()
  set audioTrack(audioTrack: MediaStreamTrack) {
    this._audioTrack = audioTrack;
    this.initNewTrack().then();
  }
  get audioTrack() {
    return this._audioTrack;
  }
  _audioTrack: MediaStreamTrack;

  /**
   * Audio sensitivity of meter. Lower the number, more sensitive the display
   * will be (meaning, for more quiet sounds, bar will show higher power).
   * I suggest you change only this default value throughout the entire
   * application (not per-use) in order to ensure consistant user experience.
   *
   * @memberof AudioMeterComponent
   */
  @Input()
  sensitivity = 0.5;

  /**
   * Whether or not to show the meter vertically or horizontally.
   *
   * @type {boolean}
   * @memberof AudioMeterComponent
   */
  @Input()
  vertical = true;

  /**
   * Current value of volume. We prepend _ because this is assigned from other
   * services, and we don't want Cleanupable class to forcefully stop it once
   * ngOnDestroy is called.
   *
   * @type {Observable<number>}
   * @memberof AudioMeterComponent
   */
  _currentVolume$: Observable<number>;

  public value: number;

  _currentVolumeSub;

  @Input()
  public mode='dotted';

  public discrete = true;

  public smoothingFactor = 0.9;
  constructor(public audioMeter: AudioMeterService, public zone: NgZone) {
    super();

  }

  ngOnInit() {
    console.log('AudioMeterComponent Init');
    console.log(this.audioTrack);
  }

  private async initNewTrack() {
    this.cleanAllSubscriptions();
    this.subscriptions = [];
    if (this.audioTrack) {
      await this.audioMeter
          .hookAudioStream(this._audioTrack, null);
        this._currentVolume$ = this.audioMeter.getVolume$();
      // if (this.audioTrack instanceof MediaStreamTrack) {

      // } else {
      //   const participant = this.audioTrack as NextgenParticipant;
      //   this._currentVolume$ = this.audioTrack.audioLevel$;

      //   participant.videoParticipant$
      //     .pipe(takeUntil(this.ngUnsubscribe))
      //     .subscribe((part) => {
      //       part?.audioStream$
      //         ?.pipe(takeUntil(this.ngUnsubscribe))
      //         .subscribe(async (audioStream) => {
      //           await this.audioMeter.hookAudioStream(
      //             audioStream,
      //             participant
      //           );
      //         });
      //     });
      // }

      this._currentVolumeSub = this._currentVolume$.subscribe((value) => {
        this.value = value * this.sensitivity;
      });
    } else {
      delete this._currentVolume$;
    }
  }

  draw() {
    // Use this in canvas derivative
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    console.log('AudioMeterComponent Destroy');
  }
}
