import { cloneDeep, isNil } from 'lodash';
import { getTimelineById } from '../helpers/timelines.helpers';
import { VideoLayer } from '../interfaces';
import { AddClipsEvent } from '../interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';

export class AddVideoOverlaysCommand extends BaseCommand<AddClipsEvent> {
  run({ clips, timelineId, replace }: AddClipsEvent) {
    const { timeline } = getTimelineById(timelineId, this.source.sections);

    const options = {
      bounds: timeline.bounds ?? { x: 0, y: 0, width: 100, height: 100 },
      styles: timeline.styles,
    };

    if (replace) {
      timeline.layers.forEach((layer) => this.removeAssets((layer as VideoLayer).assetId));
      timeline.layers = [];
    }

    const newClips = cloneDeep(clips);
    newClips.forEach((clip) => {
      if (isNil(clip.startAt) || isNil(clip.endAt)) {
        timeline.layers.sort((a, b) => a.visibility.startAt - b.visibility.startAt);

        const clipDuration = isNil(clip.trimTo) || isNil(clip.trimFrom) ? clip.duration : clip.trimTo - clip.trimFrom;

        clip.startAt = timeline.layers.length ? timeline.layers[timeline.layers.length - 1].visibility.endAt + 1 : 0;
        clip.endAt = clip.startAt + clipDuration;
      }

      this.insertClips(timeline, [clip], timeline.layers.length, options);
    });

    this.recalculateVisibility(timeline);

    return this.ok();
  }
}
