<div *ngIf="editing">
    <mat-form-field class="editable-field">
        <input
            matInput
            [placeholder]="placeholder"
            [formControl]="valueCtrl" />
        <mat-icon class="toggle-btn save-cancel" (click)="saveValue()" matSuffix>check_circle</mat-icon>
        <mat-icon class="toggle-btn save-cancel" (click)="cancelEdit()" matSuffix>clear</mat-icon>
        <mat-error *ngIf="valueCtrl.errors?.required">Required</mat-error>
        <mat-error *ngIf="valueCtrl.errors?.maxlength">Max length is {{valueCtrl.errors.maxlength.requiredLength}} characters</mat-error>
    </mat-form-field>
    <!-- <span class="material-icons save-cancel toggle-btn"
        (click)="saveValue()">check_circle</span>
    <span class="material-icons save-cancel toggle-btn"
        (click)="cancelEdit()">clear</span> -->
</div>
<ng-container *ngIf="!editing">
    <span >{{value}}</span>
    <mat-icon *ngIf="!disableEdit"
        class="toggle-btn edit-btn"
        (click)="toggleEdit()">
        edit
    </mat-icon>
</ng-container>