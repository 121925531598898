import * as Interfaces from './workflow-template-builders.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/common/src/environments/environment';
import { AssetDto } from '../assets/assets.interfaces';
import { getMimeTypeFromExtension, splitNameAndExtension } from '../../file.utils';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class TemplateBuildersService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}workflows/templates-builders`,
    byId: (id: number) => `${commonenv.nextGenApiUrl}workflows/templates-builders/${id}`,
    createAsset: `${commonenv.nextGenApiUrl}workflows/templates-builders/create-asset`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getBuilders() {
    return this.httpClient.get<Interfaces.TemplateBuilder[]>(this.urls.base);
  }

  getBuilderById(id: number) {
    return this.httpClient.get<Interfaces.TemplateBuilder>(this.urls.byId(id));
  }

  create() {
    return this.httpClient.post<Interfaces.TemplateBuilder>(this.urls.base, {});
  }

  update(id: number, data: Interfaces.UpdateTemplateBuilderRequest) {
    return this.httpClient.put<Interfaces.TemplateBuilder>(this.urls.byId(id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  createAssetUrl(data: Interfaces.CreateTemplateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.createAsset, data);
  }

  uploadAsset(signedUrl: string, file: File) {
    const [_, extension] = splitNameAndExtension(file.name);

    return this.httpClient.put(signedUrl, file, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
        'Content-Type': getMimeTypeFromExtension(extension),
      }),
      reportProgress: true,
      observe: 'events',
    });
  }
}
