import { Component, Input } from '@angular/core';
import { Color, ColorPaletteGenerator } from '@openreel/creator/common';

@Component({
  selector: 'openreel-color-palette',
  templateUrl: './openreel-color-palette.component.html',
  styleUrls: ['./openreel-color-palette.component.scss'],
})
export class OpenreelColorPaletteComponent {
  @Input()
  set color(color: string) {
    if (color !== this._color) {
      this.palette = ColorPaletteGenerator.generatePalette(color);
      this._color = color;
    }
  }
  get color(): string {
    return this._color;
  }
  _color: string;

  palette: Color[];
}
