import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { filter, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../ui/src/environments/environment';

export const CaseConverterInterceptorSkipHeader = 'X-Skip-Interceptor-CaseConverter';

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Injectable()
export class CaseConverterInterceptor implements HttpInterceptor {
  private skiplist = [
    `${environment.apiUrl}multiple-video-create`,
    `${environment.nextGenApiUrl}login`,
    `${environment.nextGenApiUrl}projects`,
    `${environment.nextGenApiUrl}sessions`,
    `${environment.nextGenApiUrl}video`,
    `${environment.nextGenApiUrl}teleprompter`,
    `${environment.nextGenApiUrl}user`,
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.skiplist.some((url) => req.url.includes(url))) {
      return next.handle(req);
    }

    if (req.headers.has(CaseConverterInterceptorSkipHeader)) {
      return next.handle(
        req.clone({
          headers: req.headers.delete(CaseConverterInterceptorSkipHeader),
        })
      );
    }

    return next
      .handle(
        req.clone({
          body: decamelizeKeys(req.body),
        })
      )
      .pipe(
        filter((event) => event instanceof HttpResponse && event.ok),
        switchMap((event: HttpResponse<any>) => [
          event.clone({
            body: camelizeKeys(event.body),
          }),
        ])
      );
  }
}
