import {
  TimelinesLayer,
  WorkflowDataDto,
  TrimmerClip,
  TrimmerData,
  TrimmerVideoOverlayClip,
  getSectionTypeTimelinesByType,
  getSectionsOfType,
  Timeline,
} from '@openreel/creator/common';
import { generateLayer } from './generateLayer';

export function generateTrimmerWorkflow(data: TrimmerData, useSplits: boolean) {
  let mainClips = data.clips.filter((t) => t.timelineType === 'main');
  const useOverlayAsMain = !mainClips.length;

  if (useOverlayAsMain) {
    mainClips = data.clips;
  }

  const workflow = emptyWorkflow(mainClips);
  addMainClips(mainClips, useSplits, workflow);

  if (!useOverlayAsMain) {
    addVideoOverlay(
      data.clips.find((t) => t.timelineType === 'b-roll') as TrimmerVideoOverlayClip,
      useSplits,
      workflow
    );
  }

  return workflow;
}

function emptyWorkflow(mainVideos: TrimmerClip[]) {
  const timelines: Partial<Timeline>[] =
    mainVideos.length === 1
      ? [{ id: 'main', type: 'main' }]
      : mainVideos.map((_, index) => ({
          id: `main_${index}`,
          type: 'main',
          showInEditor: true,
        }));

  const workflow: Partial<WorkflowDataDto> = {
    assets: [],
    features: {},
    sections: {
      main: {
        enabled: true,
        sectionType: 'main',
        timelines: [{ id: 'main', type: 'main', layers: [] }],
      },
    },
    timelines: [
      {
        type: 'main',
        layers: [
          {
            type: 'section',
            enabled: true,
            sectionId: 'main',
          },
        ],
      },
    ],
  };

  if (mainVideos.length === 1) {
    getSectionTypeTimelinesByType(workflow.sections, 'main', 'main')[0].hasAudio = true;
  } else if (mainVideos.length > 1) {
    const mainSection = getSectionsOfType(workflow.sections, 'main')[0];
    const mainTimeline = getSectionTypeTimelinesByType(workflow.sections, 'main', 'main')[0];

    mainTimeline.layers.push({
      type: 'timelines',
      children: timelines.map(({ id, type }, index) => ({
        id,
        type,
        hasAudio: index === 0 || mainSection.section.layout.layoutType !== 'presentation',
        layers: [],
      })),
    });
  }

  return workflow;
}

function addMainClips(clips: TrimmerClip[], useSplits: boolean, workflow: Partial<WorkflowDataDto>) {
  const isSimple = clips.length === 1;
  const mainSections = getSectionsOfType(workflow.sections, 'main');
  const mainTimeline = mainSections[0].section.timelines.find((t) => t.type === 'main');

  if (useSplits) {
    const visibility = new Map<number, number>();
    clips.forEach((clip, index) => {
      if (!visibility.has(index)) {
        visibility.set(index, 0);
      }

      clip.splits.forEach((split) => {
        const { layer, asset } = generateLayer(
          clip.assetFileId,
          clip.assetProviderType,
          clip.duration,
          false,
          visibility.get(index),
          split.startAt,
          split.endAt
        );

        let targetTimeline = mainTimeline;
        if (!isSimple) {
          targetTimeline = (mainTimeline.layers[0] as TimelinesLayer).children[index];
        }

        workflow.assets.push(asset);
        targetTimeline.layers.push(layer);

        visibility.set(index, visibility.get(index) + (split.endAt - split.startAt) + 1);
      });
    });
  } else {
    clips.forEach((clip, index) => {
      const { layer, asset } = generateLayer(clip.assetFileId, clip.assetProviderType, clip.duration, false, 0);

      let targetTimeline = mainTimeline;
      if (!isSimple) {
        targetTimeline = (mainTimeline.layers[0] as TimelinesLayer).children[index];
      }

      workflow.assets.push(asset);
      targetTimeline.layers.push(layer);
    });
  }
}

function addVideoOverlay(clip: TrimmerVideoOverlayClip, useSplits: boolean, workflow: Partial<WorkflowDataDto>) {
  if (!clip) {
    return;
  }

  const mainSections = getSectionsOfType(workflow.sections, 'main');

  mainSections[0].section.timelines.push({
    id: 'b-roll',
    type: 'b-roll',
    layers: [],
  });

  const videoOverlayTimeline = mainSections[0].section.timelines.find((t) => t.type === 'b-roll');

  let visibility = 0;
  if (useSplits) {
    clip.splits.forEach((split) => {
      const { layer, asset } = generateLayer(
        clip.assetFileId,
        clip.assetProviderType,
        clip.duration,
        true,
        visibility,
        split.startAt,
        split.endAt
      );

      visibility += split.endAt - split.startAt + 1;

      workflow.assets.push(asset);
      // TODO: if we add both videos, where should we place video overlay now in multi-scene projects?
      videoOverlayTimeline.layers.push({ ...layer, bounds: { x: 67, y: 1, width: 28 } });
    });
  } else {
    const { layer, asset } = generateLayer(clip.assetFileId, clip.assetProviderType, clip.duration, true, 0);

    workflow.assets.push(asset);
    videoOverlayTimeline.layers.push(layer);
  }
}
