import { BoundsInfo, ObjectDrawInfo } from '../canvas.interfaces';
import { sortLayers } from './sortLayersByTiming';

export const getLayersBounds = (items: ObjectDrawInfo[]) => {
  if (items.length === 0) {
    return new BoundsInfo(0, 0, 0, 0);
  }

  const sortedItems = sortLayers(items);
  let leftGroupBound = sortedItems[0].bounds.left;
  let rightGroupBound = sortedItems[0].bounds.right;

  sortedItems.forEach((item) => {
    leftGroupBound = Math.min(leftGroupBound, item.bounds.left);
    rightGroupBound = Math.max(rightGroupBound, item.bounds.right);
  });

  return new BoundsInfo(
    leftGroupBound,
    sortedItems[0].bounds.top,
    rightGroupBound - leftGroupBound,
    sortedItems[0].bounds.height
  );
};
