import { filter, first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

// Used for resource-locking with promise-based api.
export class Mutex {
  // true = locked, false = unlocked
  private mutexLocked = new BehaviorSubject<boolean>(false);
  // Locks the mutex. If mutex already locked, it will wait for it to be
  // unlocked. That's why this is a Promise function
  // You don't need to use this function manually, try using doWhenUnlocked
  async lockMutex() {
    if (this.mutexLocked.value) {
      console.warn('Mutex is locked, waiting');
      // This is in loop because only one handler can be served at the time.
      // After mutex is unlocked, it may be locked before it reaches 'while'
      // line
      do {
        // Wait for mutex to unlock.
        await this.mutexLocked.pipe(
          filter(v => !v),
          first()
        ).toPromise();
      } while(this.mutexLocked.value);
    }
    this.mutexLocked.next(true);
  }
  // Immediately unlocks the mutex.
  // You don't need to use this function manually, try using doWhenUnlocked
  unlockMutex() {
    this.mutexLocked.next(false);
  }
  // Do something with mutex unlocked. It also unlocks mutex when error happens
  // in the handler. The handler is put in a waiting queue, so only one handler
  // at the time can access the resource.
  async doWhenUnlocked<T = void>(handlerWhenUnlocked: () => Promise<T>): Promise<T> {
    try {
      await this.lockMutex();
      const ret = await handlerWhenUnlocked();
      this.unlockMutex();
      return ret;
    } catch(err) {
      this.unlockMutex();
      throw err;
    }
  }
}