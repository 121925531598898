import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { components, entryComponents } from './components';
import { LayerService } from './services/layer.service';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OpenreelUploaderModule } from '../openreel-uploader';
import { MaterialModule } from '@openreel/common';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [...components],
  imports: [
    ReactiveFormsModule,
    ColorPickerModule,
    NgxMatSelectSearchModule,
    OpenreelUploaderModule,
    FormsModule,
    MaterialModule,
    OpenreelUploaderModule,
    CommonModule,
  ],
  exports: [...components],
  entryComponents: [...entryComponents],
  providers: [LayerService],
})
export class OpenreelLayersModule {}
