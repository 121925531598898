import { AppState } from '../../../store';
import { combineReducers, Action, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducers/self-record.reducer';
import * as fromTeleprompterReducer from './reducers/self-record-teleprompter.reducer';
import { SelfRecordState } from './interfaces/self-record.interfaces';
import { SelfRecordTeleprompterFeatureState } from './interfaces/self-record-teleprompter.interfaces';

export const featureKey = 'self-record';

export interface SelfRecordFeatureState {
  [fromReducer.key]: SelfRecordState;
  [fromTeleprompterReducer.key]: SelfRecordTeleprompterFeatureState;
}

export interface State extends AppState {
  [featureKey]: SelfRecordFeatureState;
}

export function reducers(
  state: SelfRecordFeatureState | undefined,
  action: Action
) {
  return combineReducers({
    [fromReducer.key]: fromReducer.reducerFn,
    [fromTeleprompterReducer.key]: fromTeleprompterReducer.reducerFn,
  })(state, action);
}

export const selectSelfRecordFeatureState = createFeatureSelector<
  State,
  SelfRecordFeatureState
>(featureKey);
