import {
  AckStartVideo,
  EVT_S2D_START_UPLOADING,
  AckStartUploading,
  VideoUploadStatus,
  TranscodeStatus,
  EVT_TRANSCODE_STATUS_CHANGE,
  CheckRecordingStatus,
  EVT_S2D_UPLOAD_STATUS,
  EVT_D2S_START_UPLOADING,
  StartUploading,
  EVT_D2S_CANCEL_UPLOAD,
  EVT_S2D_CANCEL_UPLOAD_ACK,
  AckUploadCancel,
  EVT_D2D_STOP_RECORDING_ACK,
  VideoStatus,
  EVT_D2D_RECORDING_START_REQUEST,
  EVT_S2D_RECORDING_COMPLETE,
} from './../../../interfaces/socket-events';
import { Injectable } from '@angular/core';
import { DirectorSocketService } from '../../socket/director-socket.service';
import {
  StartVideo,
  EVT_D2S_START_RECORDING,
  EVT_S2D_START_RECORDING_ACK,
  StopVideo,
  EVT_D2S_STOP_RECORDING,
  EVT_S2D_STOP_RECORDING_ACK,
  EVT_S2D_CHECK_RECORDING,
  EVT_D2D_START_RECORDING_ACK,
  AckDirectorStartVideo,
  AckStopVideo,
  EVT_S2D_DELETE_VIDEO_REQUEST,
  EVT_D2S_DELETE_VIDEO_REQUEST_ACK,
  EVT_D2S_DELETE_VIDEO_REQUEST,
  EVT_S2D_DELETE_VIDEO_ACK,
  DeleteVideo,
  AckDeleteVideo,
  EVT_D2S_SET_UPLOAD_VIDEO_ACTIVE,
  EVT_S2D_SET_UPLOAD_VIDEO_ACTIVE_ACK,
  SetVideoUploadActive,
  AckSetVideoUploadActive,
} from '../../../interfaces/socket-events';
import { merge, Subject } from 'rxjs';
import {
  MultiSendSocketCompleteInfo,
  RecordingMetadata,
} from '@openreel/common';
import { map } from 'rxjs/operators';

@Injectable()
export class SocketDirectorExtensionRecordingService {
  constructor(private socket: DirectorSocketService) {}

  async startRecording(
    req: StartVideo[],
    finishedIdentities$: Subject<MultiSendSocketCompleteInfo>,
    individual: boolean
  ) {
    const toWait = this.socket.waitForMultipleAck(
      req.map((data) => data.identity),
      EVT_S2D_START_RECORDING_ACK,
      true,
      finishedIdentities$,
      20000
    );
    this.socket.basicSocketEmit(EVT_D2D_RECORDING_START_REQUEST, {
      subjects: req.map((r) => r.identity),
      individual,
    });
    req.forEach((data) => {
      this.socket.emitSocketToArr(EVT_D2S_START_RECORDING, data, [
        data.identity,
      ]);
    });
    await toWait;
  }

  listenToRecordingStartRequest() {
    return this.socket
      .getSocketEventByName<
        StartVideo & { individual: boolean; subjects: string[] }
      >(EVT_D2D_RECORDING_START_REQUEST)
      .pipe(map((event) => event.data));
  }

  listenToRecordingStartVideoRequest() {
    return this.socket
      .getSocketEventByName<StartVideo>(EVT_D2S_START_RECORDING)
      .pipe(map((event) => event.data));
  }

  listenToRecordingStatus() {
    return this.socket.getSocketEventByName<CheckRecordingStatus>(
      EVT_S2D_CHECK_RECORDING
    );
  }

  listenToRecordingStart() {
    return this.socket.getSocketEventByName<AckStartVideo>(
      EVT_S2D_START_RECORDING_ACK
    );
  }

  listenToRecordingStop() {
    return merge(
      this.socket.getSocketEventByName<AckStopVideo>(EVT_S2D_STOP_RECORDING_ACK),
      this.socket.getSocketEventByName<AckStopVideo>(EVT_S2D_RECORDING_COMPLETE)
    );
  }

  listenToUploadingStart() {
    return this.socket.getSocketEventByName<AckStartUploading>(
      EVT_S2D_START_UPLOADING
    );
  }

  listenToUploadingStatus() {
    return this.socket.getSocketEventByName<VideoUploadStatus>(
      EVT_S2D_UPLOAD_STATUS
    );
  }

  listenToTranscodingStatus() {
    return this.socket.getSocketEventByName<TranscodeStatus>(
      EVT_TRANSCODE_STATUS_CHANGE
    );
  }
  //listen when subject send delete confirmation request to director
  listenToVideoDeleteRequest() {
    return this.socket.getSocketEventByName<DeleteVideo>(
      EVT_S2D_DELETE_VIDEO_REQUEST
    );
  }
  //listen when subject delete video & send ack
  listenToOnDeleteVideo() {
    return this.socket.getSocketEventByName<AckDeleteVideo>(
      EVT_S2D_DELETE_VIDEO_ACK
    );
  }

  // listen when subject cancel video uploading
  listenToUploadingCancel() {
    return this.socket.getSocketEventByName<AckUploadCancel>(
      EVT_S2D_CANCEL_UPLOAD_ACK
    );
  }

  listenToSetVideoUploadActive() {
    return this.socket.getSocketEventByName<AckSetVideoUploadActive>(
      EVT_S2D_SET_UPLOAD_VIDEO_ACTIVE_ACK
    );
  }

  async stopRecording(
    req: StopVideo,
    identities: string[],
    finishedIdentities$: Subject<MultiSendSocketCompleteInfo>
  ) {
    this.socket.emitSocketToArr(EVT_D2S_STOP_RECORDING, req, identities);
    await this.socket.waitForMultipleAck(
      identities,
      EVT_S2D_STOP_RECORDING_ACK,
      true,
      finishedIdentities$,
      20000
    );
  }
  listenToDirectorRecordingStart() {
    return this.socket.getSocketEventByName<AckDirectorStartVideo>(
      EVT_D2D_START_RECORDING_ACK
    );
  }
  sendAckDirectorStartRecord(
    data: RecordingMetadata,
    stat: '1' | '0',
    uploadDuringRecording?: boolean
  ) {
    this.socket.emitSocketTo(
      EVT_D2D_START_RECORDING_ACK,
      {
        videoid: data.videoId,
        video_name: data.localFileName,
        stat: stat,
        uploadDuringRecording,
      },
      data.identity
    );
  }
  sendAckDirectorStopRecord(data: RecordingMetadata, stat: '1' | '0') {
    this.socket.emitSocketTo(
      EVT_D2D_STOP_RECORDING_ACK,
      {
        videoid: data.videoId,
        video_name: data.localFileName,
        stat: stat,
      },
      data.identity
    );
  }
  sendAckDirectorStartUploading(data: RecordingMetadata, stat: '1' | '0') {
    this.socket.emitSocketTo(
      EVT_S2D_START_UPLOADING,
      {
        status: VideoStatus.UPLOADING,
        videoid: data.videoId,
        stat,
      },
      data.identity
    );
  }

  //when director send delete(local video) request to subject
  sendDeleteRequestToSubject(
    videoId: number,
    identity: string,
    value: '0' | '1'
  ) {
    this.socket.emitSocketToArr(
      EVT_D2S_DELETE_VIDEO_REQUEST,
      { videoid: videoId, value: value },
      [identity]
    );
  }
  //After getting delete request from subject when director send ack
  sendDeleteRequestAckToSubject(
    videoId: number,
    identity: string,
    value: '0' | '1'
  ) {
    this.socket.emitSocketTo(
      EVT_D2S_DELETE_VIDEO_REQUEST_ACK,
      { videoid: videoId, stat: value },
      identity
    );
  }

  async sendUploadingRequest(req: StartUploading, identities: string[]) {
    this.socket.emitSocketToArr(EVT_D2S_START_UPLOADING, req, identities);
    await this.socket.waitForMultipleAck(
      identities,
      EVT_S2D_START_UPLOADING,
      true
    );
  }

  async sendUploadCancelRequest(req: StartUploading, identities: string[]) {
    this.socket.emitSocketToArr(EVT_D2S_CANCEL_UPLOAD, req, identities);
    await this.socket.waitForMultipleAck(
      identities,
      EVT_S2D_CANCEL_UPLOAD_ACK,
      true
    );
  }

  async sendUploadVideoActiveRequest(
    req: SetVideoUploadActive,
    identities: string[]
  ) {
    this.socket.emitSocketToArr(
      EVT_D2S_SET_UPLOAD_VIDEO_ACTIVE,
      req,
      identities
    );
    await this.socket.waitForMultipleAck(
      identities,
      EVT_S2D_SET_UPLOAD_VIDEO_ACTIVE_ACK,
      true
    );
  }

  // listenToUploadingStatus() {
  //   this.socket.getSocketEventByName()
  // }
}
