import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationToastComponent } from './confirmation-toast.component';

@NgModule({
  imports: [MatIconModule, MatButtonModule, CommonModule],
  declarations: [ConfirmationToastComponent],
  exports: [ConfirmationToastComponent],
})
export class ConfirmationToastModule {}
