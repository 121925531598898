import { getLayers } from '../helpers/timelines.helpers';
import { BaseCommand } from './base.command';

export class WizardElementAssetRemoveCommand extends BaseCommand<{
  elementId: string;
}> {
  run({ elementId }) {
    const element = this.source.features.wizard.elements.find((e) => e.type === 'asset' && e.id === elementId);

    if (element.type !== 'asset') {
      return { success: false };
    }
    if (element.asset.file === null) {
      return { success: false };
    }

    // Remove asset from layers
    element.targetTags.forEach((tag) => {
      for (const { layer } of getLayers(this.source)) {
        if (!layer?.tags?.includes(tag)) {
          continue;
        }

        if (layer.type === 'image' && layer.assetId === element.asset.id) {
          layer.assetId = null;
          layer.enabled = false;
        }
        if (layer.type === 'video' && layer.assetId === element.asset.id) {
          layer.assetId = null;
          layer.enabled = false;
        }
        if (layer.type === 'lottie') {
          for (const [, value] of Object.entries(layer.data)) {
            if (value.type !== 'logo') {
              continue;
            }

            if (value.assetId === element.asset.id) {
              value.assetId = null;
            }
          }
        }
      }
    });

    // Remove asset from assets & element
    this.removeAssets(element.asset.id);
    element.asset.file = null;

    return this.ok();
  }
}
