<div mat-dialog-title>
  <mat-icon
  *ngIf="data.iconSvg"
  [svgIcon]="data.iconSvg"
  class="image"
  >
  </mat-icon>
  <h2>{{ data.title }}</h2>
</div>
<mat-dialog-content>
  <p *ngIf="!data.unsanitizedContent">{{ data.content }}</p>
  <p *ngIf="data.unsanitizedContent" [innerHTML]="data.content"></p>
  <div class="btn-holder">
    <button
      mat-raised-button
      color="primary"
      *ngIf="data.confirmButtonLabel"
      (click)="onDialogCloseClicked(true)"
    >
      {{ data.confirmButtonLabel }}
    </button>
    <button
      *ngIf="data.cancelButtonLabel"
      class="cancel-btn"
      mat-raised-button
      (click)="onDialogCloseClicked(false)"
    >
      {{ data.cancelButtonLabel }}
    </button>
  </div>
  <div *ngIf="data.neverShowText">
    <mat-checkbox color="primary" [formControl]="isNeverShow"
      >{{data.neverShowText}}</mat-checkbox
    >
  </div>
</mat-dialog-content>
