import {
  PLATFORM_ID,
  Inject,
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import videojs from 'video.js';
import { isPlatformBrowser} from '@angular/common';
import './overlay-plugin';
import { HostingPlayerActions, TextButtonCtaDefinition } from '../../hosting-interfaces';

@Component({
  selector: 'openreel-hosting-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class HostingPlayerComponent implements AfterViewInit, OnDestroy {

  @Input()
  srcData!: {
    srcUrl: string, 
    thumbUrl: string,
    expired: boolean,
    captions?: {readUrl: string, languageDisplay: string, languageCode: string}[];
    cta?: TextButtonCtaDefinition[];
  };

  @Input()
  autoplay = false;

  @Input()
  border = false;

  @Output() videoEvent = new EventEmitter<{action: string, url: string, position: number}>();

  @ViewChild('videoContainer')
  videoContainer!: ElementRef<HTMLDivElement>;

  @ViewChild('videoEl')
  videoEl!: ElementRef<HTMLVideoElement>;

  @Output() playerReady = new EventEmitter<HostingPlayerActions>();
  @Output() playerDestroyed = new EventEmitter();

  player?: videojs.Player

  isBrowser: boolean;
  isVideoEndedReported = false;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.setUpPlayer();
    }
  }

  ngOnDestroy() {
    this.player?.dispose();
    this.playerDestroyed.emit();
  }
  
  private addWatermark(){
    const videoEl = this.player.el();
    const div = document.createElement('div');
    const img = document.createElement('img');

    div.classList.add('or-watermark-content');
    img.classList.add('or-watermark-content-img');

    img.src = 'assets/common/wordmarks/watermark.png';

    div.appendChild(img);
    videoEl.appendChild(div);
  }

  private setUpPlayer() {
    const videoElem = this.videoEl.nativeElement;
    this.player = videojs(
      videoElem,
      {
        html5: {
          vhs: {
            withCredentials: true
          },
          mp4: {
            withCredentials: true
          }
        },
        fluid: true,
        autoplay: this.autoplay,
        sources: [{ src: this.srcData.srcUrl }],
        poster: this.srcData.thumbUrl,
        preload: 'auto',
        textTrackSettings: false as unknown,
        plugins: {
          overlays: {overlays: this.srcData.cta ?? []},
        },
      },
      () => {
        this.playerReady.emit({ play: this.play.bind(this), pause: this.pause.bind(this) });

        // add text tracks
        if(this.srcData.captions){
          for(let i = 0; i < this.srcData.captions.length; i++){
            const captionStream = this.srcData.captions[i];
            
            this.player?.addRemoteTextTrack({
              kind: 'captions', 
              label:captionStream.languageDisplay,
              language: captionStream.languageCode,
              id: captionStream.languageCode,
              src: captionStream.readUrl }, false);
          }
        }

        // add watermark
        if(this.srcData.expired){
          this.addWatermark();
        }
      },
    );

    this.player.on('play', (evt) => {
      const position = this.player.currentTime();
      this.videoEvent.emit({action: 'play', url:this.srcData.srcUrl, position: position});
    });

    this.player.on('pause', (evt) => {
      const position = this.player.currentTime();
      this.videoEvent.emit({action: 'pause', url:this.srcData.srcUrl, position: position});
    });

    this.player.on('ended', (evt) => {
      const position = this.player.currentTime();
      this.videoEvent.emit({action: 'ended', url:this.srcData.srcUrl, position: position});
    });

    this.player.on('seeked', (evt) => {
      const position = this.player.currentTime();
      this.videoEvent.emit({action: 'seeked', url:this.srcData.srcUrl, position: position});
    });

    this.player.on('overlayClick', (evt) => {
      console.log(evt)
    })
    this.player.on('timeupdate', (evt) => {
      const position = this.player.currentTime();
      const duration = this.player.duration();
      const played:TimeRanges = this.player.played();
      let durationPlayed = 0;

      for(let i = 0; i < played.length; i++) {
        durationPlayed += played.end(i) - played.start(i);
      }

      // using logic: if user watched >90% of content - means he watched entire video clip
      if(duration > 0 && durationPlayed/duration > 0.9 && !this.isVideoEndedReported){
        this.videoEvent.emit({action: 'video_completed', url:this.srcData.srcUrl, position: position});
        this.isVideoEndedReported = true;
      }
    });
  }

  private async play() {
    if (!this.player) {
      console.warn('Trying to play video before player is initialized');
      return;
    }
    return await this.player.play();
  }

  private pause() {
    if (!this.player) {
      console.warn('Trying to pause video before player is initialized');
      return;
    }
    this.player.pause();
  }
}
