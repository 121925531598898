import { filter } from 'rxjs/operators';
import {
  EVT_D2S_SCRIPT_CONTENT,
  EVT_S2D_SCRIPT_CONTENT_ACK,
  EVT_D2S_TELEPROMPTER_INFO,
  EVT_S2D_TELEPROMPTER_INFO_ACK,
  EVT_S2D_TELEPROMPTER_STATUS,
} from './../../../interfaces/socket-events';
import {
  TeleprompterSocketRequest,
  AckTeleprompterInfo,
  CaptureTeleprompterCommand,
  TeleprompterInfo,
  CaptureTeleprompterStatus,
} from './../../../interfaces/teleprompter.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectSocketService } from '../../socket/subject-socket.service';

@Injectable()
export class SocketExtensionTeleprompterService {
  loadTeleprompter$: Observable<TeleprompterSocketRequest>;
  playTeleprompter$: Observable<TeleprompterSocketRequest>;
  pauseTeleprompter$: Observable<TeleprompterSocketRequest>;
  resumeTeleprompter$: Observable<TeleprompterSocketRequest>;
  closeTeleprompter$: Observable<TeleprompterSocketRequest>;
  restartTeleprompter$: Observable<TeleprompterSocketRequest>;
  teleprompterInfo$: Observable<TeleprompterInfo>;

  constructor(private socket: SubjectSocketService) {
    const scriptContent$ =
      this.socket.getMySocketEventByName<TeleprompterSocketRequest>(
        EVT_D2S_SCRIPT_CONTENT
      );

    this.playTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.PLAY)
    );
    this.loadTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.LOAD)
    );
    this.pauseTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.PAUSE)
    );
    this.resumeTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.RESUME)
    );
    this.closeTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.CLOSE)
    );
    this.restartTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.RESTART)
    );
    this.teleprompterInfo$ = socket.getMySocketEventByName(
      EVT_D2S_TELEPROMPTER_INFO
    );
  }

  sendAckForTeleprompterInfo(data: AckTeleprompterInfo) {
    this.socket.emitSocket({
      eventName: EVT_S2D_TELEPROMPTER_INFO_ACK,
      data: data,
    });
  }

  sendAckForTeleprompterPlay(req?: TeleprompterSocketRequest) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.PLAY,
        fromRequest: req,
      },
    });
  }

  sendAckForTeleprompterLoad(req?: TeleprompterSocketRequest) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.LOAD,
        fromRequest: req,
      },
    });
  }

  sendAckForTeleprompterPause(req?: TeleprompterSocketRequest) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.PAUSE,
        fromRequest: req,
      },
    });
  }

  sendAckForTeleprompterResume(req?: TeleprompterSocketRequest) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.RESUME,
        fromRequest: req,
      },
    });
  }

  sendAckForTeleprompterClose(req?: TeleprompterSocketRequest) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.CLOSE,
        fromRequest: req,
      },
    });
  }

  sendAckForTeleprompterRestart(req?: TeleprompterSocketRequest) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.RESTART,
        fromRequest: req,
      },
    });
  }

  // todo: this should not be an acknowlegement
  // because teleprompter goes idle on its own over time
  // thus, it should be different socket message
  // for example, teleprompter_notify_idle
  sendAckForTeleprompterIdle() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.IDLE,
      },
    });
  }

  notifyTeleprompterStatus(
    status: CaptureTeleprompterStatus,
    identity: string,
    teleprompterHidden: boolean,
    teleprompterScriptId: number
  ) {
    this.socket.basicSocketEmit(EVT_S2D_TELEPROMPTER_STATUS, {
      status,
      identity,
      teleprompterHidden,
      teleprompterScriptId,
    });
  }
}
