import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';
import { VideoLayer } from '../interfaces';
import { getLayerFromId, getTimelineById } from '../helpers/timelines.helpers';

export class RemoveClipCommand extends BaseCommand<TimelineInterfaces.LayerIdEvent> {
  run(event: TimelineInterfaces.LayerIdEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);

    if (!layerInfo) {
      return this.error(`Can't find layer to remove. Layer id: ${event.layerId}`);
    }

    const { layer, timelineId, sectionId } = layerInfo;

    const { timeline } = getTimelineById(timelineId, this.source.sections);

    timeline.layers = timeline.layers.filter((l) => l.layerId !== event.layerId);
    this.removeAssets((layer as VideoLayer).assetId);

    this.recalculateVisibility(timeline);
    if (timeline.type === 'main') {
      this.removeLayersOutOfBounds(sectionId);
    }

    return this.ok();
  }
}
