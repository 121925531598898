import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ROUTE_DASHBOARD } from '../route-utils';

@Injectable({
  providedIn: 'root',
})
export class ImportOldSessionsGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  canActivate() {
    if (this.authService.isRootAdmin()) {
      return true;
    } else {
      const redirect = this.location.path(true);

      this.router.navigate([ROUTE_DASHBOARD], {
        queryParams: {
          redirect: redirect,
        },
      });
    }
  }
}
