import * as fromActions from './../actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';

import { AssetsFacade } from '../facades/assets.facade';
import { Injectable } from '@angular/core';
import { FontDOMService } from 'libs/common/src/services/font/font-dom.service';

@Injectable()
export class AssetsEffects {
  downloadFontsOnProjectLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadProjectSuccess),
        switchMap(() => this.assetsFacade.fonts$),
        filter((fonts) => fonts?.length > 0),
        map((fonts) => {
          this.fontDOMService.addFontsToDOM(fonts);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private assetsFacade: AssetsFacade,
    private fontDOMService: FontDOMService,
    private actions$: Actions
  ) {}
}
