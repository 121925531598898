import { getLayers } from '../helpers/timelines.helpers';
import { BaseCommand } from './base.command';

export class WizardElementAssetReplaceCommand extends BaseCommand<{
  elementId: string;
  assetFileId: string;
}> {
  run({ elementId, assetFileId }) {
    const element = this.source.features.wizard.elements.find((e) => e.type === 'asset' && e.id === elementId);
    if (element.type !== 'asset') {
      return { success: false };
    }

    element.asset.file = {
      path: assetFileId,
      provider: 'or-assets',
    };

    const existingAsset = this.source.assets.find((a) => a.id === element.asset.id);
    if (!existingAsset) {
      this.source.assets.push({
        ...element.asset,
      });
    } else {
      existingAsset.file = { ...element.asset.file };
    }

    const assetIdsToRemove = [];

    element.targetTags.forEach((tag) => {
      for (const { layer } of getLayers(this.source)) {
        if (!layer?.tags?.includes(tag)) {
          continue;
        }

        if (layer.type === 'image' || layer.type === 'video') {
          if (layer.assetId !== null) {
            continue;
          }

          layer.assetId = element.asset.id;
          layer.enabled = true;
        }

        if (layer.type === 'lottie') {
          for (const [, layerField] of Object.entries(layer.data)) {
            if (layerField.type !== 'logo' || layerField.assetId !== null) {
              continue;
            }

            layerField.assetId = element.asset.id;
          }
        }
      }
    });

    this.removeAssets(assetIdsToRemove);

    return this.ok();
  }
}
