import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { isEqual, isNumber, round } from 'lodash-es';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { LayerStyles } from '@openreel/creator/common';
import { Layout } from '../../interfaces/player-data.interfaces';
import { CuePlayerItemComponent } from '../cue-player-controller/cue-player-item.component';
import { CueSelectionService, SelectedActions } from '../../services/cue-selection.service';
import { Cleanupable } from '@openreel/common';

interface Point {
  x: number;
  y: number;
}

const RESIZE_MARKER_DIMS_FOR_UPDATE = {
  nw: ['x', 'y'],
  ne: ['y'],
  sw: ['x'],
  se: [],
  n: ['y'],
  s: [],
  w: ['x'],
  e: [],
};

const MIN_BOUNDS_WIDTH = 10;
const MIN_BOUNDS_HEIGHT = 10;
const ZOOM_TO_FIT_TOLERANCE_PX = 5;

export interface Interactions {
  resizeable: boolean;
  actions: {
    canSwitch: boolean;
    canZoom: boolean;
  };
}

@Component({
  selector: 'openreel-cue-player-selectable',
  templateUrl: './cue-player-selectable.component.html',
  styleUrls: ['./cue-player-selectable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerSelectableComponent extends Cleanupable implements OnInit, OnDestroy, OnChanges {
  @Input() layout: Layout;
  @Input() defaultLayout: Layout;
  @Input() styles: LayerStyles;
  @Input() interactions: Interactions;
  @Input() maintainAspectRatio = true;
  @Input() player: CuePlayerItemComponent;

  @Output() updateBounds = new EventEmitter<{ layout: Layout; done: boolean }>();

  @HostBinding('class.selected') isSelected = false;
  @HostBinding('class.resizeable') isResizeable = false;
  private isJustSelected = false;

  private layoutStart: Layout;
  private boundsStart: Layout;
  private mouseStart: Point;

  private layoutCurrent: Layout;

  private handlePosition: string;
  private mouseEventsSub = new Subscription();

  constructor(
    private cueSelectionService: CueSelectionService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.cueSelectionService.selected$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selection) => {
      this.isSelected = selection?.playerId === this.player.id;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.mouseEventsSub.unsubscribe();
  }

  ngOnChanges() {
    this.isResizeable = this.interactions.resizeable;
    this.cueSelectionService.setActions(this.getPossibleActions());
    this.getPossibleActions();
  }

  @HostListener('mousedown', ['$event'])
  mousedown(event: MouseEvent) {
    if (!(event.target instanceof Element)) {
      return;
    }

    this.isJustSelected = false;
    if (!this.isSelected) {
      this.isSelected = true;
      this.isJustSelected = true;
      this.cueSelectionService.setSelection(
        {
          playerId: this.player.id,
          externalId: this.player.playerData.externalId,
          playerElement: this.elementRef.nativeElement,
          defaultLayout: this.defaultLayout,
        },
        this.getPossibleActions()
      );
      this.cdr.markForCheck();
    }

    if (!this.interactions.resizeable) {
      this.attachUnSelectEvents();
    } else {
      this.attachDragAndUnSelectEvents(event);
    }
  }

  private attachUnSelectEvents() {
    if (this.mouseEventsSub) {
      this.mouseEventsSub.unsubscribe();
    }
    fromEvent(document, 'mouseup')
      .pipe(first())
      .subscribe(() => {
        if (!this.isJustSelected) {
          this.isSelected = false;
          this.cueSelectionService.clearSelection();
          this.cdr.markForCheck();
        }
      });
  }

  private attachDragAndUnSelectEvents(event: MouseEvent) {
    if (!(event.target instanceof Element)) {
      return;
    }

    const playerElementBounds = this.getElementBounds();
    const hostElementBounds = this.getHostElementBounds();

    this.layoutStart = { ...this.layout };
    this.layoutCurrent = { ...this.layout };
    this.boundsStart = {
      x: playerElementBounds.x - hostElementBounds.x,
      y: playerElementBounds.y - hostElementBounds.y,
      width: playerElementBounds.width,
      height: playerElementBounds.height,
    };

    this.mouseStart = { x: event.pageX, y: event.pageY };

    const mouseEvents = merge(fromEvent(document, 'mousemove'), fromEvent(document, 'mouseup'));

    if (this.mouseEventsSub) {
      this.mouseEventsSub.unsubscribe();
    }

    this.handlePosition = event.target.getAttribute('data-position');
    const userActionObservable =
      this.handlePosition !== null ? this.calculateResizeChanges(mouseEvents) : this.calculateDragChanges(mouseEvents);

    this.mouseEventsSub = userActionObservable.subscribe((event: MouseEvent) => {
      const done = event.type === 'mouseup';
      this.cueSelectionService.setMoving(!done);

      const hasChanges = !isEqual(this.layout, this.layoutCurrent);

      if (hasChanges) {
        this.updateBounds.emit({ layout: this.layoutCurrent, done });
      }

      if (done) {
        this.mouseEventsSub.unsubscribe();
        if (!hasChanges && !this.isJustSelected) {
          this.isSelected = false;
          this.cueSelectionService.clearSelection();
          this.cdr.markForCheck();
        }
      }
    });
  }

  private getResizedBounds(currentMousePos: Point) {
    const newBounds = {
      ...this.boundsStart,
    };

    // Get new bounds in px
    const updateAxis = this.getAxisForUpdate();
    if (updateAxis.has('x')) {
      newBounds.x = currentMousePos.x;
    }
    if (updateAxis.has('y')) {
      newBounds.y = currentMousePos.y;
    }

    if (this.handlePosition === 'nw') {
      newBounds.width += this.boundsStart.x - currentMousePos.x;
      newBounds.height += this.boundsStart.y - currentMousePos.y;
    }
    if (this.handlePosition === 'ne') {
      newBounds.width += currentMousePos.x - (this.boundsStart.x + this.boundsStart.width);
      newBounds.height += this.boundsStart.y - currentMousePos.y;
    }
    if (this.handlePosition === 'n') {
      newBounds.height += this.boundsStart.y - currentMousePos.y;
    }
    if (this.handlePosition === 'sw') {
      newBounds.width += this.boundsStart.x - currentMousePos.x;
      newBounds.height += currentMousePos.y - (this.boundsStart.y + this.boundsStart.height);
    }
    if (this.handlePosition === 'se') {
      newBounds.width += currentMousePos.x - (this.boundsStart.x + this.boundsStart.width);
      newBounds.height += currentMousePos.y - (this.boundsStart.y + this.boundsStart.height);
    }
    if (this.handlePosition === 's') {
      newBounds.height += currentMousePos.y - (this.boundsStart.y + this.boundsStart.height);
    }
    if (this.handlePosition === 'w') {
      newBounds.width += this.boundsStart.x - currentMousePos.x;
    }
    if (this.handlePosition === 'e') {
      newBounds.width += currentMousePos.x - (this.boundsStart.x + this.boundsStart.width);
    }

    if (this.maintainAspectRatio) {
      const ratio = Math.min(newBounds.width / this.boundsStart.width, newBounds.height / this.boundsStart.height);

      newBounds.width = this.boundsStart.width * ratio;
      newBounds.height = this.boundsStart.height * ratio;
    }

    return newBounds;
  }

  private calculateResizeChanges(mouseEvents: Observable<Event>) {
    return mouseEvents.pipe(
      tap((event: MouseEvent) => {
        const hostElementBounds = this.getHostElementBounds();

        const currentMousePos = {
          x: event.pageX - hostElementBounds.x,
          y: event.pageY - hostElementBounds.y,
        };

        const newBounds = this.getResizedBounds(currentMousePos);

        // Get relative bounds and maintain fixed opposite corner
        const newLayout: Layout = {
          ...this.layoutStart,
        };

        // Update relative bounds
        const updateAxis = this.getAxisForUpdate();
        if (updateAxis.has('x')) {
          const newX =
            ((this.boundsStart.x + this.boundsStart.width - newBounds.width) / hostElementBounds.width) * 100;
          newLayout.x = newX;
        }
        if (updateAxis.has('y')) {
          const newY =
            ((this.boundsStart.y + this.boundsStart.height - newBounds.height) / hostElementBounds.height) * 100;
          newLayout.y = Math.max(0, newY);
        }
        newLayout.width = (newBounds.width / hostElementBounds.width) * 100;
        if (this.layout.height) {
          newLayout.height = (newBounds.height / hostElementBounds.height) * 100;
        }

        this.roundValues(newLayout);

        const invalidBounds =
          (newLayout.width && newLayout.width < MIN_BOUNDS_WIDTH) ||
          (newLayout.height && newLayout.height < MIN_BOUNDS_HEIGHT);

        this.layoutCurrent = invalidBounds ? { ...this.layoutCurrent } : newLayout;
      })
    );
  }

  private calculateDragChanges(mouseEvents: Observable<Event>) {
    return mouseEvents.pipe(
      tap((event: MouseEvent) => {
        const delta = {
          x: event.pageX - this.mouseStart.x,
          y: event.pageY - this.mouseStart.y,
        };

        const hostElementBounds = this.getHostElementBounds();
        const deltaXPercent = (delta.x / hostElementBounds.width) * 100;
        const deltaYPercent = (delta.y / hostElementBounds.height) * 100;

        const newLayout: Layout = {
          ...this.layoutStart,
          x: this.layoutStart.x + deltaXPercent,
          y: Math.max(0, this.layoutStart.y + deltaYPercent),
        };

        this.roundValues(newLayout);
        this.layoutCurrent = newLayout;
      })
    );
  }

  private getElementBounds() {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }

  private getHostElementBounds() {
    return this.elementRef.nativeElement.parentNode.parentNode.getBoundingClientRect();
  }

  private roundValues(layout: Layout) {
    layout.x = round(layout.x, 2);
    layout.y = round(layout.y, 2);

    if (isNumber(layout.width)) {
      layout.width = round(layout.width, 2);
    }
    if (isNumber(layout.height)) {
      layout.height = round(layout.height, 2);
    }
  }

  private getAxisForUpdate() {
    return new Set<string>(RESIZE_MARKER_DIMS_FOR_UPDATE[this.handlePosition]);
  }

  private getPossibleActions() {
    const toolbarActions: SelectedActions[] = [];
    if (this.defaultLayout && !isEqual(this.layout, this.defaultLayout)) {
      toolbarActions.push(SelectedActions.ResetBounds);
    }

    if (this.interactions.actions.canSwitch) {
      toolbarActions.push(SelectedActions.SwitchMainVideos);
    }

    if (this.interactions.actions.canZoom && this.player?.player) {
      const dimensions = this.player.dimensions;
      const aboveToleranceWidth =
        Math.abs(dimensions.elementWidth - dimensions.renderedWidth) > ZOOM_TO_FIT_TOLERANCE_PX;
      const aboveToleranceHeight =
        Math.abs(dimensions.elementHeight - dimensions.renderedHeight) > ZOOM_TO_FIT_TOLERANCE_PX;

      if (this.player.isVideoPlayer && (aboveToleranceWidth || aboveToleranceHeight)) {
        if (this.styles?.objectFit === 'cover') {
          toolbarActions.push(SelectedActions.ContainContent);
        } else {
          toolbarActions.push(SelectedActions.FillContent);
        }
      }
    }

    return toolbarActions;
  }
}
