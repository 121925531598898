import { BehaviorSubject, Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  CueEventsService,
  CuePlayerService,
  PlayerExternalEvent,
  TimelinesPlayerData,
  OnTimeUpdateEvent,
  CuePlayerComponent,
  PlayerAnalyticEvent,
} from '@openreel/ui/openreel-cue-player';
import { Timeline, WorkflowDataDto } from '@openreel/creator/common';
import { concatMap, debounceTime, withLatestFrom } from 'rxjs/operators';

import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';
import { AuthService } from '@openreel/common';
import { EventName } from '../../../analytics/mixpanel.interfaces';
import { PlayerCaptionsData } from '@openreel/ui/openreel-cue-player/cue-player-captions/cue-player-captions.interfaces';

@Component({
  selector: 'openreel-editor-previewer',
  templateUrl: './editor-previewer.component.html',
  styleUrls: ['./editor-previewer.component.scss'],
})
export class EditorPreviewerComponent implements OnInit {
  @ViewChild(CuePlayerComponent)
  private readonly player: CuePlayerComponent;
  private readonly isPlaying = new BehaviorSubject<boolean>(false);

  @Input() workflow$: Observable<WorkflowDataDto>;
  @Input() timelines$: Observable<Timeline[]>;
  @Input() autoplay = false;
  @Input() allowSelection = false;
  @Input() allowControls = false;
  @Input() captions: PlayerCaptionsData = null;

  @Output() timeUpdated = new EventEmitter<OnTimeUpdateEvent>();
  @Output() loaded = new EventEmitter<void>();
  @Output() events = new EventEmitter<PlayerExternalEvent>();

  playerData$: Observable<TimelinesPlayerData>;
  playerBgColor: string;

  constructor(
    private readonly cueEventsService: CueEventsService,
    private readonly cuePlayerService: CuePlayerService,
    private readonly mixpanelService: MixpanelService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.playerData$ = this.workflow$.pipe(
      withLatestFrom(this.timelines$),
      debounceTime(250),
      concatMap(([workflow, timelines]) => {
        this.playerBgColor = workflow.features.styles?.backgroundColor || '#000000';

        this.cueEventsService.setPreviousPlayerState({
          wasPlaying: this.player.isPlaying(),
          shouldReset: this.player.resetOnPlay,
          time: this.player.currentTime() || 0,
        });
        return this.cuePlayerService.createPlayerData(workflow, timelines, {
          allowControls: this.allowControls,
          allowSelection: this.allowSelection,
          showFreemiumWatermark: this.authService.isCreatorFreemium(),
        });
      })
    );
  }

  onEnded() {
    this.isPlaying.next(false);
  }

  async onLoaded() {
    this.mixpanelService.logEvent('preview_loaded', 'Preview loaded video');

    this.loaded.emit();
    if (this.autoplay) {
      await this.player.play();
    }
  }

  onPlaying(isPlaying: boolean) {
    this.isPlaying.next(isPlaying);
  }

  onTimeUpdate($event: OnTimeUpdateEvent) {
    this.timeUpdated.emit($event);
  }

  onPlayerEvent($event: PlayerExternalEvent) {
    this.events.emit($event);
  }

  onPlayerAnalyticEvents({ eventName, description }: PlayerAnalyticEvent) {
    this.mixpanelService.logEvent(eventName as EventName, description);
  }

  seek(time: number) {
    this.player.seek(time);
  }
}
