import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  PresetFieldType,
  LayersDataChangedEvent,
  TimelineItem,
  WorkflowDataDto,
  getOrderedPresetFields,
} from '@openreel/creator/common';

import { Injectable } from '@angular/core';
import { ValidationError } from 'aws-sdk/clients/datapipeline';
import {
  getValidators,
  toDataImageVideo,
  toDataShape,
  toDataText,
  toFormControlImage,
  toFormControlShape,
  toFormControlText,
  toFormControlFromImageVideoLayer,
} from '../helpers/layer.helpers';

export enum UiControlType {
  Text = 'text',
  Image = 'image',
  Logo = 'logo',
  Shape = 'shape',
  Video = 'video',
}

export interface UiControl {
  type: UiControlType;
  label: string;
  field: string;
}

export interface FormControlImage {
  assetId: string;
  newAssetFileId: number;
  removedAssetId: string;
}

export interface FormControlText {
  value: string;
  styleId: string;
  color?: string;
  fontIndex: number;
  fontWeight: number;
}

@Injectable()
export class LayerService {
  constructor(private readonly formBuilder: FormBuilder) {}

  toUiControls(items: TimelineItem[]) {
    const uiControls: UiControl[][] = [];

    items.forEach((item) => {
      const itemControls = [];

      if (item.layer.type === 'lottie') {
        getOrderedPresetFields(item.preset).forEach((key) => {
          const field = item.preset[key];
          itemControls.push({
            label: field.label,
            type: field.type as UiControlType,
            field: key,
          });
        });
      }

      if (item.layer.type === 'video') {
        itemControls.push({
          label: item.layer.editing?.label ?? 'Select Video',
          type: UiControlType.Video,
          field: 'default',
        });
      }

      if (item.layer.type === 'image') {
        itemControls.push({
          label: item.layer.editing?.label ?? 'Select Image',
          type: UiControlType.Image,
          field: 'default',
        });
      }

      uiControls.push(itemControls);
    });

    return uiControls;
  }

  toForm(items: TimelineItem[], disabledLayerIds: string[], workflow: WorkflowDataDto) {
    const formData = [];

    items.forEach((item) => {
      const isItemDisabled = disabledLayerIds.indexOf(item.layerId) > -1;
      let itemData: FormGroup;

      if (item.layer.type === 'lottie') {
        const preset = item.preset;
        const data = [item.data];

        data.forEach((field) => {
          const groupData = {};

          getOrderedPresetFields(preset).forEach((key) => {
            const validators = getValidators(preset[key]);
            switch (preset[key].type) {
              case 'text':
                groupData[key] = toFormControlText(field[key], [], workflow.styles, this.formBuilder);

                if (isItemDisabled) {
                  (groupData[key] as FormGroup).disable();
                }
                break;
              case 'image':
              case 'logo':
                groupData[key] = toFormControlImage(field[key], workflow.assets);
                break;
              case 'shape':
                groupData[key] = toFormControlShape(field[key], validators, workflow.styles, this.formBuilder);
                break;
            }
          });

          itemData = this.formBuilder.group(groupData);
        });
      }

      if (['video', 'image'].includes(item.layer.type)) {
        const layerFormGroup = this.formBuilder.group({
          default: toFormControlFromImageVideoLayer(item.layer, workflow.assets),
        });
        itemData = layerFormGroup;
      }

      formData.push(itemData);
    });

    return this.formBuilder.array(formData);
  }

  toData(items: TimelineItem[], layersFormArray: FormArray): LayersDataChangedEvent {
    const data = {};

    items.forEach((item, itemIndex) => {
      data[item.layerId] = [];

      const layerControls = layersFormArray.at(itemIndex) as FormGroup;

      if (item.layer.type === 'lottie') {
        data[item.layerId] = this.toDataLottie(layerControls, item);
      } else if (item.layer.type === 'video' || item.layer.type === 'image') {
        data[item.layerId] = this.toDataLayerImageVideo(layerControls, item.layer.type);
      }
    });

    return data;
  }

  getValidationErrors(errors: ValidationError | null) {
    if (errors === null) {
      return [];
    }

    const errorTexts = [];
    Object.keys(errors).forEach((key) => {
      switch (key) {
        case 'required':
          errorTexts.push('This field is required.');
          break;
        case 'maxlength':
          errorTexts.push(`This field has maximum length of ${errors[key].requiredLength} characters.`);
          break;
      }
    });

    return errorTexts;
  }

  private toDataLottie(layerControls: FormGroup, item: TimelineItem) {
    const presetFields = item.preset;

    const controlValues = {};
    const controlAssetChanges = {};
    const controlStyleChanges = {};

    getOrderedPresetFields(presetFields).forEach((key) => {
      switch (presetFields[key].type) {
        case 'text': {
          const { values, styleChanges } = toDataText(presetFields[key], layerControls.controls[key].value);
          controlValues[key] = values;
          controlStyleChanges[key] = styleChanges;
          break;
        }
        case 'image':
        case 'logo': {
          const { values, assetChanges } = toDataImageVideo(presetFields[key].type, layerControls.controls[key].value);
          controlValues[key] = values;
          controlAssetChanges[key] = assetChanges;
          break;
        }
        case 'shape': {
          const { values, styleChanges } = toDataShape(presetFields[key], layerControls.controls[key].value);
          controlValues[key] = values;
          controlStyleChanges[key] = styleChanges;
          break;
        }
      }
    });

    const result = {
      values: controlValues,
      assetChanges: controlAssetChanges,
      styleChanges: controlStyleChanges,
    };

    return result;
  }

  private toDataLayerImageVideo(layerControls: FormGroup, presetFieldType: PresetFieldType) {
    const controlValues = {};
    const controlAssetChanges = {};

    const { values, assetChanges } = toDataImageVideo(presetFieldType, layerControls.controls['default'].value);
    controlValues['default'] = values;
    controlAssetChanges['default'] = assetChanges;

    return {
      values: controlValues,
      assetChanges: controlAssetChanges,
      styleChanges: {},
    };
  }
}
