import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { getRouteHome } from '../../route-utils';

@Component({
  selector: 'openreel-generic-loading',
  templateUrl: './generic-loading.component.html',
  styleUrls: ['./generic-loading.component.scss']
})
export class GenericLoadingComponent {
  @Input()
  message = '';

  @Input()
  error: Error;

  @Output()
  retryRequested = new EventEmitter();

  constructor(private router: Router) {}

  retry() {
    this.retryRequested.emit();
  }

  home() {
    this.router.navigateByUrl(getRouteHome());
  }
}
