import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[orDragOver]',
})
export class DragOverDirective {
  @HostListener('dragenter')
  dragEnter() {
    this.dragged = true;
  }

  @HostListener('dragleave')
  dragLeave() {
    this.dragged = false;
  }

  @HostListener('dragover', ['$event'])
  dragOver(evt: Event) {
    evt.preventDefault();
  }

  @HostListener('drop')
  drop() {
    this.dragged = false;
  }

  @HostBinding('class.dragged')
  dragged = false;
}