import { EventEmitter, OnInit, Output, Directive } from '@angular/core';
import { OnEndedEvent, OnErrorEvent, OnLoadedEvent, OnTimeUpdateEvent, PlayerEvent } from './player-event.interfaces';

import { Cleanupable } from '@openreel/common';
import { v4 } from 'uuid';

export interface PlayerDimensions {
  elementWidth: number;
  elementHeight: number;
  renderedWidth: number;
  renderedHeight: number;
  intrinsicWidth: number;
  intrinsicHeight: number;
  ratio: number;
}

@Directive()
export abstract class CuePlayerBaseComponent extends Cleanupable implements OnInit {
  @Output() onLoaded = new EventEmitter<OnLoadedEvent>();
  @Output() onTimeUpdate = new EventEmitter<OnTimeUpdateEvent>();
  @Output() onEnded = new EventEmitter<OnEndedEvent>();
  @Output() onSeeking = new EventEmitter<PlayerEvent>();
  @Output() onSeeked = new EventEmitter<PlayerEvent>();
  @Output() onError = new EventEmitter<OnErrorEvent>();

  set duration(duration: number) {
    this._duration = duration;
  }
  get duration(): number {
    return this._duration ?? 0;
  }
  _duration: number;

  id: string;
  isLoaded: boolean;

  onLoaded$ = this.onLoaded.asObservable();
  onTimeUpdate$ = this.onTimeUpdate.asObservable();
  onEnded$ = this.onEnded.asObservable();

  ngOnInit() {
    this.id = v4();
  }

  abstract play(): Promise<void>;

  abstract pause();

  abstract stop();

  abstract currentTime(time?: number): number;

  abstract isPlaying(): boolean;

  get dimensions(): PlayerDimensions {
    throw new Error('Not implemented.');
  }

  protected emitLoadedEvent() {
    this.onLoaded.emit({
      id: this.id,
    });
  }

  protected emitTimeUpdateEvent(currentTime: number) {
    this.onTimeUpdate.emit({
      id: this.id,
      currentTime,
    });
  }

  protected emitEndedEvent() {
    this.onEnded.emit({
      id: this.id,
    });
  }

  protected emitSeekingEvent() {
    this.onSeeking.emit({
      id: this.id,
    });
  }

  protected emitSeekedEvent() {
    this.onSeeked.emit({
      id: this.id,
    });
  }

  protected emitErrorEvent(error: Error) {
    this.onError.emit({
      id: this.id,
      error,
    });
  }
}
