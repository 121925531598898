<div class="container">
  <button
    *ngIf="options?.type === 'full'"
    mat-button
    disableRipple
    class="play"
    type="button"
    title="Play"
    (click)="togglePlaying()"
  >
    <mat-icon svgIcon="{{ playing ? 'pause' : 'play' }}"></mat-icon>
    <span>{{ playing ? 'Pause' : 'Play' }}</span>
  </button>

  <div tabindex="0" class="progress" (keypress)="onKeyPress($event)" (click)="onProgressClick($event)">
    <div #progressDuration class="progress-duration">
      <div #progressBar class="progress-bar" [style.width.%]="((currentTime$ | async) / duration) * 100">
        <div class="progress-bar-knob"></div>
      </div>
    </div>
  </div>

  <div *ngIf="duration && duration != Infinity && options?.type === 'full'" class="timer">
    {{ roundDuration(currentTime$ | async) | msToMinSec }} /
    {{ roundDuration(duration) | msToMinSec }}
  </div>

  <button
    *ngIf="options?.type === 'full'"
    mat-icon-button
    disableRipple
    type="button"
    [title]="muted ? 'Unmute' : 'Mute'"
    class="mat-btn-20"
    (click)="toggleMute()"
  >
    <mat-icon class="mat-icon-20" svgIcon="{{ muted ? 'music-copy' : 'music' }}"></mat-icon>
  </button>
  <button
    *ngIf="options?.type === 'full'"
    mat-icon-button
    disableRipple
    type="button"
    [title]="fullScreen ? 'Exit Fullscreen' : 'Fullscreen'"
    class="mat-btn-20"
    (click)="toggleFullscreen()"
  >
    <mat-icon>{{ fullScreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
  </button>
</div>
