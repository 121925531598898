import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { LiveBroadcast, LiveDestinations } from '../../interfaces/live-broadcast.interface';

@Injectable({
  providedIn: 'root',
})
export class LiveBroadcastService {
  public urls = {
    broadcast: `${commonenv.nextGenApiUrl}stream/broadcast/`,
  };
  constructor(public httpClient: HttpClient) {}

  createLiveBroadcast(sessionId: number, streamIds: string[], destinations: LiveDestinations[]) {
    return this.httpClient.post<LiveBroadcast>(`${this.urls.broadcast}${sessionId}`, {
      stream_ids: streamIds,
      destinations: destinations,
    });
  }
  getLiveBroadcast(sessionId: number) {
    return this.httpClient.get<LiveBroadcast>(`${this.urls.broadcast}${sessionId}`);
  }
  updateLiveBroadcast(sessionId: number, addStreamIds: string[], removeStreamIds: string[]) {
    return this.httpClient.put(`${this.urls.broadcast}${sessionId}`, {
      add_stream_ids: addStreamIds,
      remove_stream_ids: removeStreamIds,
    });
  }
  deleteLiveBroadcast(sessionId: number) {
    return this.httpClient.delete(`${this.urls.broadcast}${sessionId}`);
  }
}
