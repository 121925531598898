import { getTimelineById } from '../helpers/timelines.helpers';
import { VideoLayer } from '../interfaces';
import { AddClipsEvent } from '../interfaces/timelines.interfaces';
import { BaseCommand } from './base.command';

export class AddClipsCommand extends BaseCommand<AddClipsEvent> {
  run(event: AddClipsEvent) {
    const { timeline, sectionId } = getTimelineById(event.timelineId, this.source.sections);

    if (event.replace) {
      timeline.layers.forEach((layer) => this.removeAssets((layer as VideoLayer).assetId));
      timeline.layers = [];

      this.insertClips(timeline, event.clips, 0);
    } else {
      this.insertClips(timeline, event.clips, timeline.layers.length);
    }

    if (timeline.type === 'main') {
      this.toggleSection(sectionId, true);
    }

    this.recalculateVisibility(timeline);

    return this.ok();
  }
}
