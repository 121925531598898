import {
  ExtractThumbnailsRequest,
  ExtractThumbnailsResponse,
} from './thumbnails.interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class ThumbnailsService {
  private urls = {
    extract: (videoId: number) =>
      `${commonenv.nextGenApiUrl}thumbnails/${videoId}/extract`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  extract({ videoId, videoType, captureProjectId }: ExtractThumbnailsRequest) {
    return this.httpClient.post<ExtractThumbnailsResponse>(
      this.urls.extract(videoId),
      {
        videoType,
        projectId: captureProjectId,
      }
    );
  }
}
