import { LayoutType, TemplateType } from '../interfaces';

export const LayoutTypeOrder = {
  simple: 1,
  interview: 2,
  presentation: 3,
};

export function layoutSorter(
  layout1: { layoutId: number; type: LayoutType },
  layout2: { layoutId: number; type: LayoutType }
) {
  return LayoutTypeOrder[layout1.type] - LayoutTypeOrder[layout2.type] || layout1.layoutId - layout2.layoutId;
}

// TODO: remove after all templates have layouts.
export function convertTemplateTypeToLayoutType(templateType: TemplateType): LayoutType {
  switch(templateType) {
    case 'standard':
      return 'simple';
    case 'podcast':
      return 'interview';
    case 'presentation':
      return 'presentation';
  }
}
