import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
    selector: 'or-thumbnail-info-icon',
    templateUrl: './thumbnail-info-icon.component.html',
    styleUrls: ['./thumbnail-info-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailInfoIconComponent {
    // eslint-disable-next-line max-len
    infoMessage = 'Use the video player on the right to choose a frame, and click here to set as thumbnail.';
}