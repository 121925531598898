import { ObjectDrawInfo, TimingInfo } from '../canvas.interfaces';
import { sortLayers } from './sortLayersByTiming';

export const getLayersTiming = (items: ObjectDrawInfo[]) => {
  if (items.length === 0) {
    return new TimingInfo(0, 0);
  }

  const sortedItems = sortLayers(items);
  return new TimingInfo(
    sortedItems[0].timing.startAt,
    sortedItems[sortedItems.length - 1].timing.endAt
  );
};
