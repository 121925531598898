import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Cleanupable } from '@openreel/common/classes';
import { milisecondstoStr, strToMiliseconds } from 'libs/common/src/date-utils';
import { noop } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

export interface NumberRange {
  startAt?: number;
  endAt?: number;
}

@Component({
  selector: 'openreel-start-end-range-input',
  templateUrl: './openreel-start-end-range-input.component.html',
  styleUrls: ['./openreel-start-end-range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelStartEndRangeInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenreelStartEndRangeInputComponent extends Cleanupable implements OnInit, ControlValueAccessor {
  set range(range: NumberRange) {
    if (!range) {
      return;
    }

    this._range = range;

    const rangeStr = {
      startAt: milisecondstoStr(range?.startAt ?? this.min),
      endAt: milisecondstoStr(range?.endAt ?? this.max),
    };

    const startControl = this.rangeGroup.get('startAt');
    const endControl = this.rangeGroup.get('endAt');

    if (startControl.value !== rangeStr.startAt) {
      startControl.setValue(rangeStr.startAt, { emitEvent: false });
    }
    if (endControl.value !== rangeStr.endAt) {
      endControl.setValue(rangeStr.endAt, { emitEvent: false });
    }
  }
  get range(): NumberRange {
    return this._range;
  }
  private _range: NumberRange = {
    startAt: null,
    endAt: null,
  };

  propagateOnChanged: (range: NumberRange) => void = noop;
  propagateOnTouched = noop;

  touched = false;
  private isDisabled = false;
  private isEndAtDisabled = false;

  @Input() set disabled(value) {
    this.setDisabledState(value);
  }
  @Input() set disableEndAt(value) {
    this.setEndAtDisabled(value);
  }

  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input() min = 0;
  @Input() max: number;

  @Input() startCaption = 'Start';
  @Input() endCaption = 'End';

  rangeGroup = new FormGroup(
    {
      startAt: new FormControl(),
      endAt: new FormControl(),
    },
    { updateOn: 'blur' }
  );

  ngOnInit() {
    this.rangeGroup.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.onValueChange(this.rangeGroup.getRawValue()));
  }

  onValueChange(value) {
    const range: NumberRange = {
      startAt: null,
      endAt: null,
    };

    if (value.startAt) {
      range.startAt = strToMiliseconds(value.startAt);
    } else {
      range.startAt = this.min;
    }
    if (value.endAt) {
      range.endAt = strToMiliseconds(value.endAt);
    } else {
      range.endAt = this.max;
    }

    if (this.max) {
      if (range.startAt && range.startAt > this.max) {
        range.startAt = this.min;
      }
      if (range.endAt && range.endAt > this.max) {
        range.endAt = this.max;
      }
    }

    if (this.min) {
      if (range.startAt && range.startAt < this.min) {
        range.startAt = this.min;
      }
      if (range.endAt && range.endAt < this.min) {
        range.endAt = this.min;
      }
    }

    if (range.startAt && range.endAt) {
      if (range.startAt > range.endAt) {
        range.startAt = (Math.round(range.endAt / 1000) * 1000) - 1000;

        if (range.startAt < this.min) {
          range.startAt = this.min;
        }
      }
    }

    this.range = range;

    return this.propagateOnChanged(range);
  }

  markAsTouched() {
    if (!this.touched) {
      this.propagateOnTouched();
      this.touched = true;
    }
  }

  writeValue(range: NumberRange): void {
    this.range = range;
  }

  registerOnChange(fn: (range: NumberRange) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (this.isDisabled) {
      this.rangeGroup.disable({ emitEvent: false });
    } else {
      this.rangeGroup.enable({ emitEvent: false });
    }

    this.setEndAtDisabled();
  }

  setEndAtDisabled(isDisabled?: boolean): void {
    const endControl = this.rangeGroup.get('endAt');

    if (isDisabled != null) {
      this.isEndAtDisabled = isDisabled;
    }

    if (this.isDisabled || this.isEndAtDisabled) {
      endControl.disable({ emitEvent: false });
    } else {
      endControl.enable({ emitEvent: false });
    }
  }
}
