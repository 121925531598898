import { Injectable } from '@angular/core';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { EventName, EventProperties, EventPropertyValue, SuperProperties } from './creator-mixpanel.interfaces';
import { commonenv } from '../environments/environment';

@Injectable()
export class CreatorMixpanelService {
  private mixpanelInstance: Mixpanel;

  init(): void {
    this.mixpanelInstance = mixpanel.init(commonenv.creatorMixpanelToken, {}, 'creator');
  }

  setSuperProperty(property: SuperProperties, value?: EventPropertyValue): void {
    if (value) {
      const obj = {};
      obj[`*${property}`] = value;
      this.mixpanelInstance.register(obj);
    } else {
      this.mixpanelInstance.unregister(property);
    }
  }

  logEvent(eventName: EventName, description: string, properties: EventProperties = {}): void {
    if (description) {
      properties = {
        ...properties,
        description,
      };
    }

    const eventProperties = {};
    if (properties) {
      for (const key in properties) {
        eventProperties[`#${key}`] =
          key === 'index' && Number.isInteger(properties[key]) ? (properties[key] as number) + 1 : properties[key];
      }
    }

    this.mixpanelInstance.track(eventName, eventProperties);

    this.mixpanelInstance.people.increment('^total_event_count', 1);
    this.mixpanelInstance.people.set({ '^last_event': new Date() });
    this.setSuperProperty('session_event_count', this.incrementMixPanelSuperProperty('session_event_count'));
  }

  identify(mixpanelId: string) {
    if (mixpanelId) {
      this.mixpanelInstance.identify(mixpanelId);
    }
  }

  private incrementMixPanelSuperProperty(property: string): number {
    let value = this.mixpanelInstance.get_property(`*${property}`);
    if (value && typeof value === 'number') {
      value = value + 1;
    } else {
      value = 1;
    }
    return value;
  }
}
