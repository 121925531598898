import {
  AfterViewInit,
  Component,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ControllerData,
  LottiePlayerData,
  TimelinesPlayerData,
  VideoPlayerData,
} from '../interfaces/player-data.interfaces';
import { OnErrorEvent, OnTimeUpdateEvent } from '../interfaces/player-event.interfaces';
import { BehaviorSubject } from 'rxjs';
import { CuePlayerBaseComponent } from '../interfaces/cue-player-base.interface';
import { CuePlayerControllerComponent } from './cue-player-controller/cue-player-controller.component';
import { CuePlayerMainComponent } from './cue-player-controller-main/cue-player-controller-main.component';
import { TimelineCue } from './cue-player-timelines.interfaces';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'openreel-cue-player-timelines',
  templateUrl: './cue-player-timelines.component.html',
  styleUrls: ['./cue-player-timelines.component.scss'],
  providers: [
    {
      provide: CuePlayerBaseComponent,
      useExisting: forwardRef(() => CuePlayerTimelinesComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerTimelinesComponent extends CuePlayerBaseComponent implements AfterViewInit {
  @ViewChild(CuePlayerMainComponent)
  private readonly mainController: CuePlayerMainComponent;
  @ViewChildren(CuePlayerControllerComponent)
  private readonly controllers: QueryList<CuePlayerControllerComponent>;
  private controllersById: { [id: string]: CuePlayerControllerComponent };
  private controllersToLoad = 0;
  private cues: TimelineCue[] = [];

  backgroundPlayers = new BehaviorSubject<ControllerData[]>([]);
  mainPlayers = new BehaviorSubject<ControllerData[]>([]);
  overlayPlayers = new BehaviorSubject<ControllerData[]>([]);

  @Input()
  set data(data: TimelinesPlayerData) {
    this._data = data;
    this.processPlayers();
  }
  get data(): TimelinesPlayerData {
    return this._data;
  }
  _data: TimelinesPlayerData;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  set duration(duration: number) {
    if (this._duration !== duration) {
      this._duration = duration;
      this.processPlayers();
    }
  }
  get duration(): number {
    return this._duration;
  }
  _duration: number;

  private _resetOnPlay = false;
  get resetOnPlay() {
    return this._resetOnPlay;
  }

  private get loadedControllerCount() {
    const mainCount = this.mainController.isLoaded ? 1 : 0;
    return this.controllers.filter((player) => player.isLoaded).length + mainCount;
  }

  private get activeCues() {
    return this.cues.filter((cue) => this.controllersById[cue.id] && !this.controllersById[cue.id].hide);
  }

  protected emitLoadedEvent() {
    this.onLoaded.emit({
      id: this.id,
    });
  }

  ngAfterViewInit() {
    this.initControllerLists();
    this.controllers.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.initControllerLists();
      this.changeDetectorRef.markForCheck();
    });
  }

  onChildLoaded() {
    if (this.loadedControllerCount === this.controllersToLoad) {
      this.duration = this.mainController.duration;
      this.loadCues();
      this.isLoaded = true;
      this.emitLoadedEvent();
    }
  }

  onChildError({ error }: OnErrorEvent) {
    this.emitErrorEvent(error);
  }

  onMainTimeUpdate({ currentTime }: OnTimeUpdateEvent) {
    this.handleCuedControllers(currentTime);
    this.emitTimeUpdateEvent(currentTime);
  }

  onMainEnded() {
    this._resetOnPlay = true;
    this.emitEndedEvent();
  }

  onMainSeeked() {
    this.emitSeekedEvent();
  }

  currentTime(time?: number): number {
    if (time || time === 0) {
      this.emitSeekingEvent();
      this.handleCuedControllers(time, true);
      this._resetOnPlay = false;
    }

    return this.mainController.currentTime(time);
  }

  isPlaying(): boolean {
    return this.mainController.isPlaying();
  }

  async play() {
    if (this._resetOnPlay) {
      this._resetOnPlay = false;
      this.stop();
    }

    this.activeCues.forEach(({ id }) => {
      this.controllersById[id].play();
    });

    await this.mainController.play();
  }

  pause() {
    this.activeCues.forEach(({ id }) => {
      this.controllersById[id].pause();
    });

    this.mainController.pause();
  }

  stop() {
    this.controllers.forEach((controller) => {
      controller.hide = true;
      controller.stop();
    });

    this.mainController.stop();
  }

  private processPlayers() {
    this.backgroundPlayers.next(this.data.backgroundPlayers);
    this.mainPlayers.next(this.data.mainPlayers);

    let overlayPlayers = [];
    this.data.overlays?.forEach((overlay) => {
      overlay.forEach((player) => {
        overlayPlayers = overlayPlayers.concat(player);
      });
    });

    this.overlayPlayers.next(overlayPlayers);
  }

  private initControllerLists() {
    this.controllersToLoad = 1 + this.controllers.length; // Main Player plus all the other overlay players
    this.controllersById = this.controllers.reduce((acc, controller) => {
      acc[controller.id] = controller;
      return acc;
    }, {});
  }

  private loadCues() {
    this.controllers.forEach((controller) => {
      if (controller.data.cue) {
        const { startAt, endAt } = controller.data.cue;
        const cueEndAt = endAt || this.duration;

        const loops =
          controller.data.playerData instanceof VideoPlayerData ||
          controller.data.playerData instanceof LottiePlayerData
            ? controller.data.playerData.loop || false
            : false;
        this.cues.push({ startAt, endAt: cueEndAt, id: controller.id, loops });
      }
    });
  }

  private handleCuedControllers(currentTime: number, setTimeOnly = false) {
    if (this.cues.length) {
      this.cues.forEach((cue) => {
        const controller = this.controllersById[cue.id];
        const controllerTime = currentTime - cue.startAt;

        if (!controller) {
          return;
        }

        // We update visibility of background & overlay controllers
        if (controller.hide && cue.startAt <= currentTime && currentTime < cue.endAt) {
          // Hidden but should be visible
          controller.hide = false;
          controller.currentTime(controllerTime);

          if (this.isPlaying() && !setTimeOnly) {
            controller.play();
          }
        } else if (!controller.hide && (currentTime < cue.startAt || cue.endAt <= currentTime) && !cue.loops) {
          // Visible but should be hidden
          controller.stop();
          controller.hide = true;
        } else if (!controller.hide && setTimeOnly) {
          controller.currentTime(controllerTime);
        }
      });
    }
  }
}
