import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  DirectorSession,
  IStreamingLibService,
  IStreamingParticipant,
} from '../../interfaces';
import { ISessionService } from '../../interfaces/session-state.interface';
import { CommonApiService } from '../common-api/common-api.service';
import { NetworkQualityService } from '../network-quality/network-quality.service';
import { SessionConfigService } from '../session/session-config.service';
import { BaseStreamingService } from '../streaming/base-streaming.service';

@Injectable()
export class DirectorStreamingService extends BaseStreamingService<
  DirectorSession
> {

  public remoteParticipants$: Observable<IStreamingParticipant[]>

  constructor(
    twilioProvider: IStreamingLibService,
    sessionConfig: SessionConfigService,
    networkQualityService: NetworkQualityService,
    @Inject('ISessionService') sessionService: ISessionService,
    private api: CommonApiService
  ) {
    super(
      twilioProvider,
      sessionConfig,
      networkQualityService,
      sessionService
    );

    this.remoteParticipants$ = this.videoStreamingInstance$.asObservable().pipe(
      filter(streaming => streaming != null),
      switchMap(streaming => streaming.remoteParticipants$)
    );
  }
}
