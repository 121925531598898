import { BehaviorSubject } from 'rxjs';
import {
  asScreenShareConstraints,
  defaultScreenShareConstraints,
  findTrackCapabilities,
} from '../helpers/capabilities.helper';
import { newUnsupportedSource } from '../helpers/error.helper';
import {
  openScreenSharingStream,
  supportsScreenSharing,
} from '../helpers/media-devices.helper';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import { VideoDevice } from '../interfaces/video-device.interface';
import { VideoSource } from '../interfaces/video-source.interface';
import { VideoStream } from '../interfaces/video-stream.interface';
import { ScreenShareService } from './screenshare.service';

export class ScreenShareWebService extends ScreenShareService {
  private device = {
    id: undefined,
    name: 'Screen Sharing',
    source: VideoSource.DESKTOP,
  };

  constructor() {
    super();

    this.devices$ = this.isSupported()
      ? new BehaviorSubject<VideoDevice[]>([this.device])
      : new BehaviorSubject<VideoDevice[]>([]);
  }

  isSupported(): boolean {
    return supportsScreenSharing();
  }

  async openStream(
    _deviceId: string,
    constraints?: VideoConstraints
  ): Promise<VideoStream> {
    if (!this.isSupported()) {
      throw newUnsupportedSource(
        'Desktop sharing is not supported in the current device.'
      );
    }

    const constraintsToApply = constraints
      ? asScreenShareConstraints(constraints)
      : defaultScreenShareConstraints;

    const stream = await openScreenSharingStream(constraintsToApply);
    const track = stream.getTracks()[0];
    const capabilities = await findTrackCapabilities(track);

    return {
      device: this.device,
      track: track,
      stream: stream,
      ...capabilities,
    };
  }

  async applyConstraintsToStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    try {
      await video.track.applyConstraints({
        deviceId: video.device.id,
        ...asScreenShareConstraints(constraints),
      });
    } catch (err) {
      console.error(err);
    }
  }
}
