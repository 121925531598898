import { BehaviorSubject } from 'rxjs';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import { VideoDevice } from '../interfaces/video-device.interface';
import { VideoStream } from '../interfaces/video-stream.interface';

export abstract class ScreenShareService {
  devices$: BehaviorSubject<VideoDevice[]>;

  abstract isSupported(): boolean;
  abstract openStream(
    deviceId: string,
    constraints?: VideoConstraints
  ): Promise<VideoStream>;
  abstract applyConstraintsToStream(
    stream: VideoStream,
    constraints: VideoConstraints
  ): Promise<void>;
}
