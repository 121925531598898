<mat-form-field
  [appearance]="appearance"
>
  <mat-label *ngIf="!noLabel">
    Enter a date range
  </mat-label>
  <mat-date-range-input
    [formGroup]="rangeGroup"
    [rangePicker]="picker"
    [max]="today"
  >
    <input
      matStartDate
      data-cy="start-date-input"
      formControlName="start"
      placeholder="Start date"
      [max]="today"
    >
    <input
      matEndDate
      data-cy="end-date-input"
      formControlName="end"
      placeholder="End date"
      [max]="today"
    >
  </mat-date-range-input>
  <mat-datepicker-toggle
    matSuffix
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-error *ngIf="rangeGroup.controls.start.hasError('matStartDateInvalid')">
    Invalid start date
  </mat-error>
  <mat-error *ngIf="rangeGroup.controls.end.hasError('matEndDateInvalid')">
    Invalid end date
  </mat-error>
</mat-form-field>
