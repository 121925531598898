import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[openreelVideoBlob]',
})
export class VideoBlobDirective implements OnChanges {
  @Input('openreelVideoBlob') blob: Blob;

  constructor(
    private readonly elementRef: ElementRef,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (!('blob' in changes)) {
      return;
    }

    if (!this.blob) {
      this.elementRef.nativeElement.src = '';
    } else {
      const url = URL.createObjectURL(this.blob);
      this.elementRef.nativeElement.src = url;
    }
  }
}
