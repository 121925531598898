<div class="form-container">
  <form *ngIf="userFrm" [formGroup]="userFrm" (submit)="submitUserDetails();">
    <div fxLayout fxLayoutGap="20px">
      <div>
        <div class="form-field-title">Email Address</div>
        <mat-form-field appearance="outline">
          <input matInput formControlName="email" placeholder="Enter Your Email">
          <mat-error>
            <span *ngIf="userFrm?.controls.email?.errors?.required">Required Field</span>
            <span *ngIf="userFrm?.controls.email?.errors?.pattern">Invalid Email</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <div class="form-field-title">Full Name</div>
        <mat-form-field appearance="outline">
          <input matInput formControlName="fullname" placeholder="Enter Your Name">
          <mat-error>
            <span *ngIf="userFrm?.controls.fullname?.errors?.required">Required Field</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showContactUpdate">
        <div class="form-field-title">Contact</div>
        <mat-form-field appearance="outline" style="width:140px">
          <mat-label>Country Code</mat-label>
          <mat-select formControlName="contactCountryCode" (focus)="openedChange()" [errorStateMatcher]="errorMatcher"
            panelClass="user-update-country-phone-code" (closed)="countrySearchDrpDwnClosed()">
            <input #countrySearchInput matInput placeholder="Search Country" type="text"
              (keyup)="onCountrySearch($event.target.value)" formControlName="countrySearch"
              (keydown)="$event.stopPropagation()" class="country-search">
            <mat-option *ngFor="let phoneCodes of filteredPhoneCodes" [value]="phoneCodes.phone_code">
              {{phoneCodes.name}} (+{{phoneCodes.phone_code}})</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput formControlName="contact" [errorStateMatcher]="errorMatcher"
            placeholder="Enter your phone no." numbersOnly>
          <mat-error *ngIf="userFrm?.errors?.contact_require">
            <span>Invalid phone number</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="show2FaToggle">
        <div class="form-field-title">Two Factor Authentication</div>
        <mat-slide-toggle formControlName="tfaEnabled" class="tfa-toggle">
        </mat-slide-toggle>
      </div>
    </div>
    <div fxLayout fxLayoutGap="20px">
      <div>
        <div class="form-field-title">Current Password</div>
        <mat-form-field appearance="outline">
          <input matInput class="fs-exclude" formControlName="currentPassword"
            [type]="currentpasshide ? 'password' : 'text'" placeholder="Enter Your Current Password">
          <mat-icon matSuffix (click)="currentpasshide = !currentpasshide">{{currentpasshide ? 'visibility_off' :
            'visibility'}}</mat-icon>
        </mat-form-field>
      </div>

      <div>
        <div class="form-field-title">New Password</div>
        <mat-form-field appearance="outline">
          <input matInput class="fs-exclude" formControlName="newPassword" type="password"
            [type]="newpasshide ? 'password' : 'text'" placeholder="Enter Your New Password">
          <mat-icon matSuffix (click)="newpasshide = !newpasshide">{{newpasshide ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          <mat-error>
            <span *ngIf="userFrm?.controls.newPassword?.errors?.pattern">Password must have at least 1 uppercase, 1
              lowercase, 1 digit and one special character among the following !@#$%^&* and must be atleast 8 character
              long</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <div class="form-field-title">Confirm</div>
        <mat-form-field appearance="outline">
          <input matInput class="fs-exclude" formControlName="confirmNewPassword" type="password"
            [type]="confirmpasshide ? 'password' : 'text'" placeholder="Confirm New Password">
          <mat-icon matSuffix (click)="confirmpasshide = !confirmpasshide">{{confirmpasshide ? 'visibility_off' :
            'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout fxLayoutGap="20px">
      <button class="submit-button" [disabled]="disabledBtn" style="width: 210px;" mat-raised-button size="large"
        color="primary" type="submit">Save Changes</button>
      <button *ngIf="showContactUpdate" class="submit-button" (click)="clearContact()" style="width: 150px;"
        mat-raised-button size="large" color="primary" type="button">Clear Contact</button>
    </div>
  </form>
</div>