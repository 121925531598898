import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPopupComponent } from '../../interfaces';
export class ModalAlertData {
  title: string;
  content: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  neverShowText?: string;
  isLightThemed?: boolean;
  iconSvg?: string;
  // allow html in content.
  unsanitizedContent?: boolean;
}
export class DialogCloseData {
  value: boolean;
  isNeverShow: boolean;
}

@Component({
  selector: 'openreel-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss'],
})
export class GenericAlertComponent implements IPopupComponent {
  isPopup: true;
  isLightThemed = true;
  isNeverShow = new FormControl();

  constructor(
    private dialog: MatDialogRef<GenericAlertComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ModalAlertData
  ) {
    this.isPopup = true;
    this.isLightThemed = data.isLightThemed;
  }
  onDialogCloseClicked(value: boolean) {
    this.dialog.close({ value: value, isNeverShow: this.isNeverShow.value });
  }
}
