<div
  (resized)="onResized($event)"
  (cdkDragEnded)="onDragEnd($event)"
  [cdkDragFreeDragPosition]="{ x: popupPosition.x, y: popupPosition.y }"
  class="dialog-drag"
  cdkDrag
  cdkDragBoundary=".cdk-overlay-container"
  cdkDragRootElement=".teleprompter-popup"
>
  <div mat-dialog-title cdkDragHandle #previewTitle>
    <button
      mat-button
      class="dialog-close-button"
      (click)="onDialogCloseClicked()"
    >
      <mat-icon color="primary">close</mat-icon>
    </button>
    <div class="preview-menu">
      <div *ngIf="data.device" class="device-title">
        <span class="participant-name">{{ data.device.name }}</span>
        <span class="participant-device-name">{{ data.device.deviceName }}</span>
      </div>
      <mat-form-field
        class="device-selector"
        appearance="outline"
        *ngIf="!data.width && !data.height"
      >
        <mat-label>Device</mat-label>
        <span matPrefix *ngIf="teleprompterPresetDevice.value"
          ><mat-icon>{{ teleprompterPresetDevice.value.icon }}</mat-icon></span
        >
        <mat-select
          [disabled]="isResizeAllowed.value || isCountDown"
          [formControl]="teleprompterPresetDevice"
          (selectionChange)="changeDevice()"
        >
          <mat-select-trigger *ngIf="teleprompterPresetDevice.value">
            {{ teleprompterPresetDevice.value.label }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let device of teleprompterPresetDevices"
            [value]="device"
          >
            <mat-icon>{{ device.icon }}</mat-icon
            >{{ device.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle
        labelPosition="before"
        [formControl]="isResizeAllowed"
        [disabled]="isCountDown"
        (change)="toggleResize()"
        >Resize</mat-slide-toggle
      >
    </div>
  </div>
  <div mat-dialog-content class="dialog-content" cdkDragHandle>
    <div
      class="single-view-grid-list teleprompter-fullscreen"
      [fxLayout]="fxLayout"
      fxLayoutAlign="center center"
    >
      <div

        [fxFlex]="teleprompterFullscreen ? 100 : 50"
        class="telepromter-screen"
      >
        <openreel-subject-teleprompter
          #telepromter
        ></openreel-subject-teleprompter>
        <openreel-countdown
          #teleprompterCountdown
          [for]="teleprompterFullscreen ? 'teleprompter' : 'smallTeleprompter'"
        ></openreel-countdown>
      </div>
      <div fxFlex="50" *ngIf="!teleprompterFullscreen" class="preview-holder">
        <div class="video-area">
          <mat-icon svgIcon="video-camera"></mat-icon>
          <span> Video Area</span>
        </div>
      </div>
    </div>
  </div>
  <mat-icon class="resize-icon" *ngIf="isResizeAllowed.value && !isCountDown">
    aspect_ratio
  </mat-icon>
</div>
