<openreel-cue-player
  [data]="playerData$ | async"
  [captions]="captions"
  [playerBgColor]="playerBgColor"
  (onLoaded)="onLoaded()"
  (onPlaying)="onPlaying($event)"
  (onEnded)="onEnded()"
  (onTimeUpdate)="onTimeUpdate($event)"
  (events)="onPlayerEvent($event)"
  (analyticEvents)="onPlayerAnalyticEvents($event)"
></openreel-cue-player>
