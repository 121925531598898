import { BaseCommand } from './base.command';
import { camelCase, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  WorkflowDataDto,
  LayerOptions,
  SectionLayer,
  Section,
  LayoutType,
  LayoutDto,
  ELEMENT_ASSET_BACKGROUND_VIDEO_ID,
  ElementAssetFeature,
  VideoLayer,
  NewClip,
} from '../interfaces';
import { getSectionTimelines } from '../helpers';

export interface AddSectionEvent {
  template: WorkflowDataDto;
  templateLayoutId: number;
  layoutType: LayoutType;
  layoutData: LayoutDto;
  mainClips?: NewClip[];
}

export class AddSceneCommand extends BaseCommand<AddSectionEvent, string> {
  run({ template, templateLayoutId, layoutType, layoutData, mainClips }: AddSectionEvent) {
    const sectionId = camelCase(uuidv4());

    const newSection: Section = {
      enabled: true,
      timelines: cloneDeep(template.sections.main.timelines),
      sectionType: 'main',
    };

    this.addSection(sectionId, newSection);

    if (templateLayoutId) {
      this.applyLayout(sectionId, templateLayoutId, layoutType, layoutData);
    }
    this.addWatermarkTimeline();
    this.duplicateAssetsForSection(sectionId);
    this.applyGlobalBackgroundState(newSection);

    this.randomizeSectionIds(sectionId);

    if (mainClips) {
      const sectionTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId);
      if (sectionTimelines[0]?.timelines) {
        mainClips.forEach((clip, index) => {
          const timeline = sectionTimelines[0]?.timelines[index];
          timeline.layers = [];
          this.insertClips(sectionTimelines[0]?.timelines[index], [clip], 0);
        });
      }
    }

    return this.ok(sectionId);
  }

  private addSection(sectionId: string, section: Section) {
    this.source.sections = {
      ...this.source.sections,
      [sectionId]: section,
    };

    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    const newLayer: LayerOptions & SectionLayer = {
      layerId: uuidv4(),
      type: 'section',
      sectionId,
      enabled: true,
    };
    const outroIndex = mainTimeline.layers.findIndex(
      (l) => l.type === 'section' && this.source.sections[l.sectionId].sectionType === 'outro'
    );
    mainTimeline.layers.splice(outroIndex, 0, newLayer);
  }

  private addWatermarkTimeline() {
    if (this.source.globalSettings.watermarkLayer?.enabled) {
      this.addWatermark();
    }
  }

  private applyGlobalBackgroundState(section: Section) {
    const backgroundTimeline = section.timelines.find((t) => t.type === 'background');
    if (!backgroundTimeline || backgroundTimeline.layers.length === 0) {
      return;
    }

    const wizardElement = this.source.features.wizard.elements.find(
      (t) => t.type === 'asset' && t.id === ELEMENT_ASSET_BACKGROUND_VIDEO_ID
    ) as ElementAssetFeature;
    if (!wizardElement) {
      return;
    }

    if (!wizardElement.asset?.file) {
      const backgroundLayer = backgroundTimeline.layers[0] as LayerOptions & VideoLayer;
      backgroundLayer.enabled = false;
      backgroundLayer.assetId = null;
    }
  }
}
