<mat-form-field
  [appearance]="appearance"
>
  <mat-label>
    {{ label }}
  </mat-label>
  <input
    type="text"
    [attr.data-cy]="label + '-text-box'"
    aria-label="Session"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    (blur)="onBlur()"
  >
  <button
    *ngIf="control.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="onOptionSelected(null)"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    (optionSelected)="onOptionSelected($event.option.value)"
    (closed)="reset()"
    #auto="matAutocomplete"
  >
    <mat-option [attr.data-cy]="option[optionLabel] + '-label'" *ngFor="let option of options | async" [value]="option">
      {{ option[optionLabel] }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
