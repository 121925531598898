import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  passPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}';
  // eslint-disable-next-line no-useless-escape
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  roleArr = [
    { name: 'Director', value: 'admin' }
  ];
  // eslint-disable-next-line max-len
  passErrorMessage = 'Password must have at least 1 uppercase, 1 lowercase, 1 digit and one special character among the following !@#$%^&* and must be atleast 8 character long';

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  bytesToSize(bytes: number = 0) {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '0';
    const sizedata: number = bytes / (1024 * 1024);
    return sizedata;
  }
}
