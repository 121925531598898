import {
  TeleprompterScript,
  TeleprompterScriptCreate,
  TeleprompterScriptEdit,
} from '../../../interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';

const URL_SCRIPT_GET_BY_PROJECT = 'teleprompter/in-project/';
const URL_SCRIPT_EDIT_CREATE = 'teleprompter';

@Injectable({
  providedIn: 'root',
})
export class TelepromterApiService {
  constructor(private httpClient: HttpClient) {}

  getScriptsInProject(projectId: number) {
    return this.httpClient
      .get<TeleprompterScript[]>(
        commonenv.nextGenApiUrl + URL_SCRIPT_GET_BY_PROJECT + projectId
      )
      .toPromise();
  }

  editScript(scriptId: number, newContent: TeleprompterScriptEdit) {
    return this.httpClient
      .put<TeleprompterScript>(
        commonenv.nextGenApiUrl + URL_SCRIPT_EDIT_CREATE + '/' + scriptId,
        newContent
      )
      .toPromise();
  }

  createScript(newContent: TeleprompterScriptCreate) {
    return this.httpClient
      .post<TeleprompterScript>(
        commonenv.nextGenApiUrl + URL_SCRIPT_EDIT_CREATE,
        newContent
      )
      .toPromise();
  }
}
