import * as fromActions from '../actions/font.actions';

import { createReducer, on } from '@ngrx/store';
import { Font } from '../interfaces/font.interface';

export const initialState: Font = {
  suggestedFonts: [],
  trendingFonts: [],
};

const reducer = createReducer(
  initialState,

  on(fromActions.loadSuggestedFontsSuccess, (state, { suggestedFonts }) => ({
    ...state,
    suggestedFonts,
  })),

  on(fromActions.loadTrendingFontsSuccess, (state, { trendingFonts }) => ({
    ...state,
    trendingFonts,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
