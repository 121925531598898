import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private routeTitle: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {}

  init() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .pipe(
        map(() => {
          let route = this.activatedRoute.snapshot.firstChild;
          let child = route;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }

          return route;
        }),
        map((route) => route.data)
      )
      .subscribe((event) => {
        this.routeTitle = event.pageTitle ? `${event.pageTitle}` : '';
        this.title.setTitle(`${this.routeTitle}`);
      });
  }

  append(textToAppend: string) {
    this.title.setTitle(`${this.routeTitle}${textToAppend}`);
  }
}
