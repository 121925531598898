 <mat-card class="openreel-card">
  	<img style="margin-bottom: 5px;" mat-card-image [src]="carddata.img"  [openreelFallback]="'assets/common/images/placeholder.jpg'" alt="">
  	<span class="schedule-date" *ngIf="carddata.status">{{carddata.status}}</span>
  	<mat-card-content style="margin: -5px;">
	    <div style="font-size: 15px; line-height: 30px;">{{carddata.name}}</div>
	    <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="5px" style="font-size: 10px; line-height: 20px;"> 
		    <div style="height: 16px;">
		    	<ngx-avatar [name]="carddata.author" *ngIf="!shwimg" bgColor="#3546ff" size="16"></ngx-avatar>
		    	<img (error)="shwimg = false" *ngIf="shwimg" style="height: 16px; width: 16px;" mat-card-avatar [src]="carddata.avator" alt="User Avatar">
		    </div>
		    <div style="min-height: 0px;" class="crd-icon-container" style="color: #B0BAC9;">{{carddata.author}}</div>
				
				<div fxFlex style="text-align: right;">
		    	<span class="crd-icon-container" style="border: none;" *ngIf="carddata.date">
		    		<span class="material-icons crd-icon">calendar_today</span> {{carddata.date | date: 'MMM dd'}}
		    	</span>
		    	<span class="crd-icon-container">
		    		<span class="material-icons crd-icon">group</span> / {{carddata.totalUsers}}
		    	</span>
		    	<span class="crd-icon-container">
		    		<span class="material-icons crd-icon">radio</span> /	{{carddata.totalVideos}}
		    	</span>
		    </div>
		</div>
  	</mat-card-content>
</mat-card>
