<openreel-wf-time-bar #timeBar [timelinesEl]="timelines" [durations$]="durations$"></openreel-wf-time-bar>
<div #timelines>
  <openreel-wf-timelines-canvas
    [currentTime]="currentTime"
    [timelines$]="timelines$"
    [durations$]="durations$"
    [workflow$]="workflow$"
    [trimmers]="trimmers"
    [canvasCreateOptions]="canvasCreateOptions"
    (updateTrimmer)="onUpdateTrimmer($event)"
    (seekTime)="onSeekTime($event)"
  ></openreel-wf-timelines-canvas>
  <openreel-wf-scroll-zoom-bar></openreel-wf-scroll-zoom-bar>
</div>
