import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs';
interface Speed {
  bps?: number;
  kbps?: number;
  mbps?: number;
}
@Injectable({
  providedIn: 'root',
})
export class InternetSpeedService {
  imageAddr = 'https://static.openreel.com/uploads/speed-test.jpg';
  downloadSize = 5616998;
  downStartTime: number;
  upStartTime: number;
  uploadSize = 1500000;
  uploadSpeed$: Subject<Speed> = new Subject();
  downloadSpeed$: Subject<Speed> = new Subject();

  constructor(public httpClient: HttpClient) {}

  getDownloadSpeed() {
    this.downStartTime = new Date().getTime();
    const downloadImg = new Image();
    downloadImg.onload = () => {
      this.calculateDownloadSpeed();
    };
    downloadImg.onerror = (_event, _source, _lineno, _colno, error) => {
      this.downloadSpeed$.error(
        new Error(
          `Unable to get download speed: ${
            error ? error.message : 'No message available'
          }`
        )
      );
    };
    downloadImg.src = this.imageAddr + '?time=' + this.downStartTime;
  }

  calculateDownloadSpeed() {
    const endTime: number = new Date().getTime();
    const duration: number = (endTime - this.downStartTime) / 1000;
    const bitsLoaded: number = this.downloadSize * 8;
    const speedBps: number = bitsLoaded / duration;
    const speedKbps: number = speedBps / 1024;
    const speedMbps: number = speedKbps / 1024;
    this.downloadSpeed$.next({
      bps: speedBps,
      kbps: speedKbps,
      mbps: speedMbps,
    });
  }

  getUploadSpeed() {
    const randData = { randomDataString: this.randomString(this.uploadSize) };
    this.upStartTime = new Date().getTime();
    const req = new HttpRequest(
      'POST',
      commonenv.apiUrl + 'upload-speed-test',
      randData,
      {
        reportProgress: true,
      }
    );
    this.httpClient.request(req).subscribe(
      (event) => {
        if (event.type === HttpEventType.Response) {
          this.calculateUploadSpeed();
        }
      },
      (error) => {
        this.uploadSpeed$.error(
          new Error(
            `Unable to get upload speed: ${
              error ? error.message : 'No message available'
            }`
          )
        );
      }
    );
  }

  calculateUploadSpeed() {
    const endTime: number = new Date().getTime();
    const duration: number = (endTime - this.upStartTime) / 1000;
    const bitsLoaded: number = this.uploadSize * 8;
    const speedBps: number = bitsLoaded / duration;
    const speedKbps: number = speedBps / 1024;
    const speedMbps: number = speedKbps / 1024;
    this.uploadSpeed$.next({
      bps: speedBps,
      kbps: speedKbps,
      mbps: speedMbps,
    });
  }

  randomString(length) {
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
