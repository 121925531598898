import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { commonenv } from '../environments/environment';
import { Observable } from 'rxjs';
// Prepend api url to all requests that don't have full url
@Injectable()
export class ApiUrlInterceptor<T> implements HttpInterceptor {
  intercept(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (
      !req.url.toLowerCase().startsWith('http://') &&
      !req.url.toLowerCase().startsWith('https://') &&
      !req.url.toLowerCase().startsWith('ws://') &&
      !req.url.toLowerCase().startsWith('wss://') &&
      !req.url.toLowerCase().startsWith('//') &&
      !req.url.toLowerCase().endsWith('.svg')
    ) {
      // req = req.clone({url: this.config.apiUrl + req.url});
      req = req.clone({ url: commonenv.apiUrl + req.url });
    }
    return next.handle(req);
  }
}
