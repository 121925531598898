import {
  EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID,
  EVT_WORKFLOW_RENDER_PROGRESS,
  EVT_WORKFLOW_THUMBNAILS_DONE,
  EVT_WORKFLOW_TRANSCRIBE_PROGRESS,
  RenderProgress,
  ThumbnailSpritesheetFinished,
  TranscribeProgress
} from '../workflow/workflow-project.interfaces';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Cleanupable } from '../../classes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowsSocketService } from './workflows-socket.service';
import { commonenv } from '@openreel/common/env/environment';

@Injectable()
export class WorkflowProjectSocketService extends Cleanupable {
  renderProgress$: Observable<RenderProgress>;
  transcribeProgress$: Observable<TranscribeProgress>;
  thumbnailSpritesheets$: Observable<ThumbnailSpritesheetFinished>;

  constructor(private readonly socket: WorkflowsSocketService) {
    super();
    this.observeRenderProgress();
    this.observeTranscribeProgress();
    this.observeThumbnailSpritesheet();
  }

  connect(sessionInfo, reconnect = false) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.connect(
      commonenv.websocketUrl + '?access-token=' + payload.token
    );

    if (reconnect) {
      this.socket.socket.connectionStatus$
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter((status) => status.connected),
          map((status) => status.connected)
        )
        .subscribe(() => {
          this.joinRoom(sessionInfo);
        });
    } else {
      this.joinRoom(sessionInfo);
    }
  }

  joinRoom(sessionInfo) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.emit(EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID, payload);
  }

  private observeRenderProgress() {
    this.renderProgress$ = this.socket
      .getSocketEventByName<RenderProgress>(EVT_WORKFLOW_RENDER_PROGRESS)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private observeTranscribeProgress() {
    this.transcribeProgress$ = this.socket
      .getSocketEventByName<TranscribeProgress>(EVT_WORKFLOW_TRANSCRIBE_PROGRESS)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private observeThumbnailSpritesheet() {
    this.thumbnailSpritesheets$ = this.socket
      .getSocketEventByName<ThumbnailSpritesheetFinished>(
        EVT_WORKFLOW_THUMBNAILS_DONE
      )
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private getSessionInfoPayload(sessionInfo) {
    return {
      device_type: 'WEB',
      user_type: sessionInfo.userType,
      token: sessionInfo.token,
      captureProjectId: sessionInfo.captureProjectId,
    };
  }
}
