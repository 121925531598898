/*eslint no-bitwise: [2, { allow: ["&", "<<"] }] */
import { BufferScan } from './buffer-scan';
import { MediaMeta, Track } from './validate-media';

export class mediaValidatorWebM {

    tagDictionary: Record<string, string>;
    data: MediaMeta;

    videoParsedMeta:Track[] = [];
    currentTrack:Track;
    limit100K = 100*1024; // 100K limit - don't read more

    constructor(){
        this.tagDictionary = this.setupTagDictionary();
    }

    //
    // Setup local dictionary - easy to debug
    // 
    setupTagDictionary() {
        // T - Element Type - The form of data the element contains. 
        // m: Master, u: unsigned int, i: signed integer, s: string, 8: UTF-8 string, b: binary, f: float, d: date
        
        const tagDict = {};
        tagDict['[1A][45][DF][A3]'] = 'EBML';       // EBML 0	[1A][45][DF][A3] m
        tagDict['[42][86]'] = 'EBMLVersion';        // EBMLVersion	1	[42][86] u
        tagDict['[42][F7]'] =  'EBMLReadVersion';   // EBMLReadVersion	1	[42][F7] u
        tagDict['[42][F2]'] =  'EBMLMaxIDLength';   // EBMLMaxIDLength	1	[42][F2] u
        tagDict['[42][F3]'] =  'EBMLMaxSizeLength'; // EBMLMaxSizeLength	1	[42][F3] u
        tagDict['[42][82]'] =  'DocType';           // DocType	1	[42][82] s
        tagDict['[42][87]'] =  'DocTypeVersion';    // DocTypeVersion	1	[42][87] u
        tagDict['[42][85]'] =  'DocTypeReadVersion';// DocTypeReadVersion	1	[42][85] u
        
        tagDict['[EC]'] =  'Void';              // Void	g	[EC] b
        tagDict['[BF]'] =  'CRC-32';            // CRC-32	g	[BF] b
        tagDict['[1C][53][BB][6B]'] =  'Cues';  // Cues	1	[1C][53][BB][6B] m
        
        tagDict['[18][53][80][67]'] = 'Segment';  // Segment	0	[18][53][80][67] m
        tagDict['[11][4D][9B][74]'] = 'SeekHead'; // SeekHead	1	[11][4D][9B][74] m
        tagDict['[4D][BB]'] = 'Seek';             // Seek	2	[4D][BB] m
        tagDict['[53][AB]'] = 'SeekID';           // SeekID	3	[53][AB] b
        tagDict['[53][AC]'] = 'SeekPosition';     // SeekPosition	3	[53][AC] u
        
        tagDict['[15][49][A9][66]'] = 'Info'; // Info	1	[15][49][A9][66] m 

        tagDict['[16][54][AE][6B]'] = 'Tracks';         // Tracks	1	[16][54][AE][6B] m
        tagDict['[AE]'] = 'TrackEntry';                 // TrackEntry	2	[AE] m
        tagDict['[D7]'] = 'TrackNumber';                // TrackNumber	3	[D7] u
        tagDict['[73][C5]'] = 'TrackUID';               // TrackUID	3	[73][C5] u
        tagDict['[83]'] = 'TrackType';                  // TrackType	3	[83] u
        tagDict['[23][E3][83]'] = 'DefaultDuration';    // DefaultDuration	3	[23][E3][83] u
        tagDict['[23][31][4F]'] = 'TrackTimecodeScale'; // TrackTimecodeScale	3	[23][31][4F] f
        tagDict['[86]'] = 'CodecID';                    // CodecID	3	[86] s
        tagDict['[63][A2]'] = 'CodecPrivate';           // CodecPrivate	3	[63][A2] b
        tagDict['[25][86][88]'] = 'CodecName';          // CodecName	3	[25][86][88] 8
        tagDict['[E0]'] = 'Video';                      // Video	3	[E0] m
        tagDict['[B0]'] = 'PixelWidth';                 // PixelWidth	4	[B0] u
        tagDict['[BA]'] = 'PixelHeight';                // PixelHeight	4	[BA] u
        tagDict['[23][83][E3]'] = 'FrameRate';          // FrameRate	4	[23][83][E3] f
        tagDict['[E1]'] = 'Audio';                      // Audio	3	[E1] m
        tagDict['[B5]'] = 'SamplingFrequency';          // SamplingFrequency	4	[B5] f
        tagDict['[9F]'] = 'Channels';                   // Channels	4	[9F] u
        
        tagDict['[1F][43][B6][75]'] = 'Cluster';    // Cluster	1	[1F][43][B6][75] m
        tagDict['[E7]'] = 'Timecode';               // Timecode	2	[E7] u
        tagDict['[A3]'] = 'SimpleBlock';            // SimpleBlock	2	[A3] b
        
        return tagDict;
    }

    //
    // scanWebmTag
    //
    private scanWebmTag(buff, pos) {
        let tagSize = 0;
        const firstByte = buff[pos];
        const firstMask = 0xff;
        
        if (firstByte & 0x80) {
            tagSize = 1;
        }
        else if (firstByte & 0x40) {
            tagSize = 2;
        }
        else if (firstByte & 0x20) {
            tagSize = 3;
        }
        else if (firstByte & 0x10) {
            tagSize = 4;
        }
        else {
            // ERROR: bad TAG byte
            return null;
        }

        const decodeRes = this.decodeBytes(buff, pos, tagSize, firstByte, firstMask); 
        return decodeRes;
    }

    //
    // decodeBytes
    //
    private decodeBytes(buff, pos, size, firstByte, firstMask) {
        let value = firstByte & firstMask;
        let str = ('[' + this.byteToHex(firstByte) + ']');
        let followByte;
        for (let i = 1; i < size; i++) {
            followByte = buff[pos + i];
            str += '[';
            str += this.byteToHex(followByte);
            str += ']';
            value = (value << 8) + followByte;
        }
        
        const res = {
            str: str,
            size: size,
            value: value,
        };
        return res;
    }

    //
    // byteToHex
    //
    private byteToHex(b) {
        const str = '0' + b.toString(16);
        const len = str.length;
        return str.substring(len - 2).toUpperCase();
    }

    //
    // scanDataSize
    //
    private scanDataSize(buff, pos) {
        let dataSizeSize = 0;
        const firstByte = buff[pos];
        let firstMask;

        if (firstByte & 0x80) {
            dataSizeSize = 1;
            firstMask = 0x7f;
        }
        else if (firstByte & 0x40) {
            dataSizeSize = 2;
            firstMask = 0x3f;
        }
        else if (firstByte & 0x20) {
            dataSizeSize = 3;
            firstMask = 0x1f;
        }
        else if (firstByte & 0x10) {
            dataSizeSize = 4;
            firstMask = 0x0f;
        }
        else if (firstByte & 0x08) {
            dataSizeSize = 5;
            firstMask = 0x07;
        }
        else if (firstByte & 0x04) {
            dataSizeSize = 6;
            firstMask = 0x03;
        }
        else if (firstByte & 0x02) {
            dataSizeSize = 7;
            firstMask = 0x01;
        }
        else if (firstByte & 0x01) {
            dataSizeSize = 8;
            firstMask = 0x00;
        }
        else {
            // ERROR: bad DATA byte
            return null;
        }            

        const decodeRes = this.decodeBytes(buff, pos, dataSizeSize, firstByte, firstMask); 
        return decodeRes;
    }

    async parse(file, offset, position, maxPosition){

        offset += position;
        if(maxPosition >= this.limit100K){
            maxPosition = this.limit100K;
        }
        const buffer = await BufferScan.readFile(file, offset, maxPosition);
        position = 0; //reset offset

        while(position < maxPosition){
            // -- TAG --
            let result = this.scanWebmTag(buffer, position);
            if (!result) {
                break; 
            }

            const tagName = this.tagDictionary[result.str];
            position += result.size;

            // --- DATA SIZE ---
            result = this.scanDataSize(buffer, position);
            if (! result) {
                break; 
            }
            position += result.size;

            // check - can be huge 64 bit value
            // limit it to 100K
            if(result.value === -1){
                result.value = this.limit100K;
            }

            // ---- DATA ----
            if (tagName === 'EBML') {
                await this.parse(file, offset, position, result.value);
            }
            else if (tagName === 'Tracks') {
                await this.parse(file, offset, position, result.value);
            }
            else if (tagName === 'TrackEntry') {                
                this.currentTrack = new Track();
                this.videoParsedMeta.push(this.currentTrack);

                await this.parse(file, offset, position, result.value);
            }
            else if( tagName === 'TrackType'){
                const trackType = BufferScan.scanDataValueU(buffer, position, result.value);
                if(trackType === 1){
                    this.currentTrack.type = 'video';
                }else if(trackType === 2){
                    this.currentTrack.type = 'audio';
                }
            }
            else if(tagName === 'CodecID'){
                const codecId = BufferScan.scanDataUTF8(buffer, position, result.value);
                this.currentTrack.codec = codecId;
            }
            else if (tagName === 'Video') {
                await this.parse(file, offset, position, result.value);
            }
            else if (tagName === 'PixelWidth') {
                const width = BufferScan.scanDataValueU(buffer, position, result.value);
                this.currentTrack.width = width;
            }
            else if (tagName === 'PixelHeight') {
                const height = BufferScan.scanDataValueU(buffer, position, result.value);
                this.currentTrack.height = height;
            }
            else if (tagName === 'Segment') {
                await this.parse(file, offset, position, result.value);
            }
            else if (tagName === 'Cluster') {   
                // we done - can come out
                break;
            }

            if (result.value >= 0) {
                position += result.value;
            }

            // -- check EOF ---
            if (position === maxPosition) {
                break;
            }
            else if (position > maxPosition) {
                break;
            }
        } 
    }

    //
    // get data from the scan
    //
    getData():Track[]{
        return this.videoParsedMeta;
    }
} 