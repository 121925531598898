import { Injectable } from '@angular/core';
import { containsOther } from '../../utils';
import {
  asScreenShareConstraints,
  asWebcamConstraints,
  defaultScreenShareConstraints,
  defaultWebcamConstraints,
  findTrackCapabilities,
} from '../helpers/capabilities.helper';
import { newUnsupportedSource } from '../helpers/error.helper';
import {
  getTrackSettings,
  openScreenSharingStream,
} from '../helpers/media-devices.helper';
import { isChrome } from '../helpers/ua.helper';
import { AudioDevice } from '../interfaces/audio-device.interface';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import { VideoDeviceOptions } from '../interfaces/video-device.interface';
import { VideoSource } from '../interfaces/video-source.interface';
import { VideoStream } from '../interfaces/video-stream.interface';
import {
  AudioStream,
  AudioStreamConstraints,
} from '../interfaces/audio-stream.interface';
import {
  ParticipantAudioProperties,
  AudioSettingsRequest,
} from '../../interfaces/socket-events';

/**
 * Service for enumerating, manipulating and acquiring video devices. This
 * service contains instance of currently acquired stream, and when you are
 * done with this service, you should call closeStream() function. Whenever you
 * need a stream, you can do so by getting through currentStream$ subject. At
 * the moment only one video stream can be manipulated, but in the future we
 * could extend this functionality to include multiple streams at the same
 * time, at the cost of huge refactoring of course.
 */
@Injectable()
export class MediaStreamService {
  private gainNode: GainNode;
  private twilioGainNode: GainNode;

  constructor() {}

  /**
   * Gets a list of all supported video sources.
   */
  getSupportedSources(): VideoSource[] {
    const ret = [VideoSource.WEBCAM];
    ret.push(VideoSource.DESKTOP);
    return ret;
  }

  async openVideoStream(
    { source, id }: VideoDeviceOptions,
    constraints?: VideoConstraints
  ) {
    if (source !== VideoSource.DESKTOP && source !== VideoSource.WEBCAM) {
      throw newUnsupportedSource('The requested source is not supported');
    }
    let stream: VideoStream;

    try {
      stream =
        source === VideoSource.WEBCAM
          ? await this.openWebcamStream(id, constraints)
          : await this.openScreenshareStream(id);

      const settings = this.getTrackSettings(stream.track);
      stream.settings = settings;
    } catch (error) {
      stream = {
        device: { id, source },
        error,
      };
    }
    return stream;
  }

  async openAudioStream(
    audioDevice?: AudioDevice,
    audioRequest?: AudioSettingsRequest | ParticipantAudioProperties,
    isTwilio?: boolean
  ) {
    let audioStream: AudioStream;
    const audioConstraints = this.getAudioStreamConstraints(audioRequest);

    try {
      const audioSettings = audioDevice
        ? {
            deviceId: audioDevice.id,
            ...audioConstraints,
          }
        : true;
      let streamWithConstraints = await navigator.mediaDevices.getUserMedia({
        audio: audioSettings,
        video: false,
      });

      streamWithConstraints = this.setupGainContext(
        streamWithConstraints,
        audioRequest,
        isTwilio
      );

      const track = streamWithConstraints.getTracks()[0];
      const settings = track.getSettings();
      audioStream = {
        device: audioDevice
          ? audioDevice
          : {
              id: settings.deviceId,
              name: track.label,
            },
        track,
        stream: streamWithConstraints,
      };
    } catch (error) {
      audioStream = {
        device: audioDevice,
        error,
      };
    }
    return audioStream;
  }

  private setupGainContext(
    stream: MediaStream,
    audioRequest?: AudioSettingsRequest | ParticipantAudioProperties,
    isTwilio?: boolean
  ) {
    if (audioRequest && audioRequest.proAudio) {
      const audioCtx = new AudioContext();
      const audioTrack = stream.getTracks()[0];
      const contextSource = audioCtx.createMediaStreamSource(
        new MediaStream([audioTrack])
      );
      const contextDestination = audioCtx.createMediaStreamDestination();
      if (isTwilio) {
        this.twilioGainNode = audioCtx.createGain();
        this.twilioGainNode.gain.value = audioRequest.gain;
        contextSource.connect(this.twilioGainNode).connect(contextDestination);
      } else {
        this.gainNode = audioCtx.createGain();
        this.gainNode.gain.value = audioRequest.gain;
        contextSource.connect(this.gainNode).connect(contextDestination);
      }
      stream.removeTrack(audioTrack);
      stream.addTrack(contextDestination.stream.getTracks()[0]);
    }
    return stream;
  }

  updateGain(gain: number) {
    this.gainNode.gain.value = gain;
    this.twilioGainNode.gain.value = gain;
  }

  private getAudioStreamConstraints(audioRequest): AudioStreamConstraints {
    // when pro-audio is enabled set all constraints to false to disable all audio processing properties
    const audioStreamConstraints: AudioStreamConstraints = audioRequest
      ? {
          echoCancellation: !audioRequest.proAudio,
          autoGainControl: !audioRequest.proAudio,
          noiseSuppression: !audioRequest.proAudio,
          googEchoCancellation: !audioRequest.proAudio,
          googAutoGainControl: !audioRequest.proAudio,
          googNoiseSuppression: !audioRequest.proAudio,
        }
      : {};
    return audioStreamConstraints;
  }

  private getStreamConstraints(track?: MediaStreamTrack): VideoConstraints {
    let constraints: VideoConstraints = null;
    if (track) {
      const settings = getTrackSettings(track);
      constraints = {
        fps: Math.floor(settings?.frameRate || 0),
        height: settings?.height || 0,
        width: settings?.width || 0,
        exposure: settings?.exposureTime,
        iso: settings?.exposureCompensation,
        contrast: settings?.contrast,
        colorTemperature: settings?.colorTemperature,
      };
    }
    return constraints;
  }

  private async applyConstraintsToStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    if (video.device.source === VideoSource.WEBCAM) {
      await this.applyConstraintsToWebcamStream(video, constraints);
    } else {
      await this.applyConstraintsToScreenshareStream(video, constraints);
    }
  }

  private getTrackSettings(track?: MediaStreamTrack): VideoConstraints {
    let constraints: VideoConstraints = null;
    if (track) {
      const settings = getTrackSettings(track);
      constraints = {
        fps: Math.floor(settings?.frameRate || 0),
        height: settings?.height || 0,
        width: settings?.width || 0,
        exposure: settings?.exposureTime,
        iso: settings?.exposureCompensation,
        contrast: settings?.contrast,
        colorTemperature: settings?.colorTemperature,
      };
    }
    return constraints;
  }

  private checkIsDefault(id: string, name?: string): boolean {
    return (
      id === 'default' ||
      id === 'communications' ||
      name?.toLowerCase()?.indexOf('microphone') >= 0 ||
      name?.toLowerCase()?.indexOf('mic') >= 0
    );
  }

  closeStream(stream: MediaStream) {
    if (stream) {
      console.log('Close Stream', stream);
      stream.getTracks().forEach((track) => track.stop());
    }
  }

  async openWebcamStream(
    deviceId?: string,
    constraints?: VideoConstraints
  ): Promise<VideoStream> {
    // If no specific constraints were requested, applies the default ones
    const constraintsToApply = constraints
      ? asWebcamConstraints(constraints)
      : defaultWebcamConstraints;

    if (deviceId) {
      constraintsToApply.deviceId = deviceId;
    }

    console.log('[openVideoStream] Get User Media', constraintsToApply);
    delete constraintsToApply.advanced;

    if (
      constraintsToApply.deviceId &&
      (typeof constraintsToApply.deviceId === 'string' ||
        constraintsToApply.deviceId instanceof String)
    ) {
      constraintsToApply.deviceId = {
        exact: constraintsToApply.deviceId as string,
      };
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: constraintsToApply ? constraintsToApply : true,
    });

    const track = stream.getTracks()[0];
    const settings = track.getSettings();
    const capabilities = await findTrackCapabilities(track);

    return {
      device: {
        id: settings.deviceId,
        name: track.label,
        source: VideoSource.WEBCAM,
      },
      track,
      stream: stream,
      ...capabilities,
    };
  }

  async openScreenshareStream(
    _deviceId: string,
    constraints?: VideoConstraints
  ): Promise<VideoStream> {
    const constraintsToApply = constraints
      ? asScreenShareConstraints(constraints)
      : defaultScreenShareConstraints;

    const stream = await openScreenSharingStream(constraintsToApply);
    const track = stream.getTracks()[0];
    const capabilities = await findTrackCapabilities(track);

    return {
      device: {
        id: undefined,
        name: 'Screen Sharing',
        source: VideoSource.DESKTOP,
      },
      track: track,
      stream: stream,
      ...capabilities,
    };
  }

  async applyConstraintsToScreenshareStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    try {
      await video.track.applyConstraints({
        deviceId: video.device.id,
        ...asScreenShareConstraints(constraints),
      });
    } catch (err) {
      console.error(err);
    }
  }
  async applyConstraintsToWebcamStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    try {
      await video.track.applyConstraints({
        deviceId: video.device.id,
        ...asWebcamConstraints(constraints),
      });
    } catch (err) {
      console.error(err);
    }
  }

  async changeStreamParamerters(
    currentStream: VideoStream,
    oldConstraints: VideoConstraints,
    newConstraints: VideoConstraints
  ) {
    if (containsOther(oldConstraints, newConstraints)) {
      console.log(
        'Attempted to set existing constraints:',
        oldConstraints,
        newConstraints
      );
      return oldConstraints;
    }

    console.log('New passed constraints', newConstraints);

    if (!currentStream || !currentStream.stream.active) {
      throw new Error('Video stream not running');
    }

    let constraints = newConstraints;
    if (currentStream.device.source === VideoSource.WEBCAM) {
      constraints = {
        fps: constraints.fps,
        width: constraints.width,
        height: constraints.height,
        colorTemperature: constraints?.colorTemperature,
      };
    }

    console.log('New constraints', constraints);

    //In chrome we'll need to create a new video stream
    if (isChrome() && currentStream.device.source === VideoSource.WEBCAM) {
      console.log('open2');
      this.closeStream(currentStream.stream);
      currentStream = await this.openVideoStream(currentStream.device);
    } else {
      await this.applyConstraintsToWebcamStream(currentStream, constraints);
    }
    return this.getStreamConstraints(currentStream.track);
  }
}
