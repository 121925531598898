import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_1 } from '../transformation_1_1/schema_after';
import { WorkflowDataDto_1_2 } from './schema_after';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateBorderRadius(styles: any) {
  if (Array.isArray(styles?.borderRadius)) {
    return;
  }

  styles.borderRadius = [styles.borderRadius];
}

export const SchemaTransformation_1_2: SchemaTransformation = {
  version: '1.2',

  run(oldSchema: WorkflowDataDto_1_1.WorkflowDataDto) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_2.WorkflowDataDto;
    if (newSchema.features.layouts?.styles?.borderRadius) {
      updateBorderRadius(newSchema.features.layouts.styles);
    }

    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        if (timeline.styles?.borderRadius) {
          updateBorderRadius(timeline.styles);
        }

        for (const layer of timeline.layers) {
          if (layer.styles?.borderRadius) {
            updateBorderRadius(layer.styles);
          }
        }
      }
    }
    return newSchema;
  },
};
