import { Component } from '@angular/core';
import { GUEST_MAGIC_LINK } from '../../route-utils';

@Component({
  selector: 'openreel-guest-magiclink-sent-form',
  templateUrl: './guest-magiclink-sent-form.component.html',
  styleUrls: ['./guest-magiclink-sent-form.component.scss'],
})
export class GuestMagiclinkSentFormComponent {
  magicLinkRoute = '/' + GUEST_MAGIC_LINK;
}
