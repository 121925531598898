import * as fromActions from '../actions/project.actions';
import * as fromGlobalActions from '../actions/global.actions';
import * as fromFontActions from '../actions/font.actions';
import * as fromCaptionsActions from '../actions/captions.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AuthService,
  FeatureFlaggingService,
  ROUTE_DASHBOARD,
  WorkflowProjectDto,
  WorkflowProjectService,
  WorkflowProjectSocketService,
  WORKFLOWS_ADDITIONAL_FONTS,
} from '@openreel/common';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  getLayers,
  getSectionTimelines,
  getTimelineLayers,
  UpdateSectionLayoutCommand,
} from '@openreel/creator/common';
import { ProjectFacade } from '../facades/project.facade';
import { cloneDeep, isEqual, flattenDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { MixpanelService } from '../../analytics/mixpanel.service';
import { HostableTypes, HostingService } from '@openreel/common/hosting';
import { AlertService } from '@openreel/ui/openreel-alert';
import { environment } from '@openreel/creator/environments/environment';

@Injectable()
export class ProjectEffects {
  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProjectAPI),
      switchMap(({ projectId }) =>
        this.projectService.getProjectById(projectId).pipe(
          map((data) =>
            fromActions.loadProjectSuccess({
              data: this.cleanupData(data),
            })
          ),
          catchError((error) => {
            this.logError(error);
            return of(fromActions.loadProjectFailure());
          })
        )
      )
    )
  );

  loadProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProjectSuccess),
      tap(({ data }) => {
        this.mixpanelService.init();
        this.mixpanelService.setSuperProperty('template_id', data.templateId);
        this.mixpanelService.setSuperProperty('project_id', data.id);
      }),
      switchMap(() => {
        const actions: Action[] = [fromActions.loadProjectFinished(), fromFontActions.loadTrendingFonts()];

        if (this.featureFlagService.getFlag(WORKFLOWS_ADDITIONAL_FONTS)) {
          actions.push(fromFontActions.loadSuggestedFonts());
        }
        return actions;
      })
    )
  );

  loadProjectFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadProjectFinished),
        withLatestFrom(this.projectFacade.captureProjectId$),
        map(([, captureProjectId]) => {
          const sessionInfo = {
            deviceType: 'WEB',
            userType: this.authService.role,
            token: this.authService.token$.value,
            captureProjectId: captureProjectId,
          };
          this.workflowProjectSocketService.connect(sessionInfo, true);
        })
      ),
    { dispatch: false }
  );

  loadProjectFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadProjectFailure),
        map(() => (window.location.href = `${environment.nextGenAppUrl}#/${ROUTE_DASHBOARD}`))
      ),
    { dispatch: false }
  );

  updateProjectWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectWorkflowAPI),
      withLatestFrom(this.projectFacade.data$),
      concatMap(([action, { data, lastSavedData }]) => {
        const timelinesLayers = this.getTimelinesLayers(data);
        const previousTimelinesLayers = this.getTimelinesLayers(lastSavedData);
        const layersAreEqual = isEqual(timelinesLayers, previousTimelinesLayers);

        return this.projectService
          .updateProjectById({
            id: data.id,
            captureProjectId: data.captureProjectId,
            name: data.name,
            workflow: action.data,
            updateBrandKitToLatestTimestamp: action.updateBrandKitToLatestTimestamp ?? false,
          })
          .pipe(
            concatMap((response) => [
              fromActions.updateProjectSuccess({ data: response }),
              ...(layersAreEqual ? [] : [fromCaptionsActions.toggleCaptionsUpToDate({ upToDate: false })]),
            ]),
            catchError((error) => {
              this.logError(error);
              return this.getUpdateErrorActions();
            })
          );
      })
    )
  );

  updateProjectNameAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectNameAPI),
      withLatestFrom(this.projectFacade.id$),
      switchMap(([action, id]) =>
        this.projectService.updateProjectNameById(id, action.name).pipe(
          switchMap((response) => {
            this.mixpanelService.logEvent('project_edit_name', 'Edit the Video Name', { new_name: action.name });
            return this.getUpdateSuccessActions(response);
          }),
          catchError((error) => this.logError(error))
        )
      )
    )
  );

  updateSectionLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectLayout),
      withLatestFrom(this.projectFacade.workflow$, this.projectFacade.layouts$),
      map(([{ templateLayoutId }, workflow, layouts]) => {
        const layout = layouts.find((item) => item.id === templateLayoutId);

        if (!layout) {
          return fromGlobalActions.noOp();
        }

        const { success, updatedWorkflow } = new UpdateSectionLayoutCommand(workflow).run({
          sectionId: 'main',
          project: workflow,
          templateLayoutId,
          layoutType: layout.type,
          layoutData: layout.data,
        });

        if (!success) {
          return fromGlobalActions.noOp();
        }

        return fromActions.updateProjectWorkflowAPI({
          data: updatedWorkflow,
        });
      })
    )
  );

  triggerRenderProject = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.triggerRenderProject),
      withLatestFrom(this.projectFacade.id$),
      switchMap(([, id]) => {
        if (!this.authService.getUserDetails().data.hostingAllowed) {
          return of(true);
        }
        return this.hostingService.getVideoByParentIdType(id, HostableTypes.WorkflowVideo).pipe(
          switchMap(async (video) => {
            if (video) {
              const result = await this.alertService.confirm(
                'Looks like this video has already been added to your library. Your video will be updated there as well.',
                {
                  confirmButtonText: 'Continue',
                  rejectButtonText: 'Cancel',
                }
              );
              if (!result) {
                return false;
              }
            }
            return true;
          }),
          catchError(async (error) => {
            console.error(error)
            return true;
          })
        );
      }),
      switchMap((result) => {
        if (result) {
          return [fromActions.renderProject()];
        }
        return [fromActions.cancelProjectRender()];
      })
    )
  );

  constructor(
    private readonly mixpanelService: MixpanelService,
    private readonly actions$: Actions,
    private readonly projectService: WorkflowProjectService,
    private readonly projectFacade: ProjectFacade,
    private readonly workflowProjectSocketService: WorkflowProjectSocketService,
    private readonly alertService: AlertService,
    private readonly hostingService: HostingService,
    private readonly authService: AuthService,
    private readonly featureFlagService: FeatureFlaggingService
  ) {}

  // TODO: move this to backend
  private cleanupData(data: WorkflowProjectDto) {
    const newData = cloneDeep(data);

    // Add missing enabled to sections
    const timelines = newData.workflow.timelines.find((t) => t.type === 'main');
    timelines.layers.forEach((l) => {
      l.enabled = l.enabled !== undefined ? l.enabled : true;
    });

    // Add autogenerated IDs to layers if they dont contain it already
    for (const { layer } of getLayers(newData.workflow)) {
      layer.layerId = layer.layerId ?? uuidv4();
    }

    return newData;
  }

  private getUpdateSuccessActions(data: WorkflowProjectDto, additionalActions: Action[] = []) {
    return [fromActions.updateProjectSuccess({ data }), ...additionalActions] as Action[];
  }

  private getUpdateErrorActions() {
    return [fromActions.updateProjectFailure()] as Action[];
  }

  private logError(error) {
    console.error(error);
    return EMPTY;
  }

  private getTimelinesLayers(data: WorkflowProjectDto) {
    const { workflow } = data;
    const mainSections = getSectionTimelines(workflow.sections, 'main', 'main');
    return flattenDeep(mainSections.map((section) => section.timelines.map((t) => getTimelineLayers(t.id, workflow))));
  }
}
