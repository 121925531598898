import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TIME_CURSOR_NAME, ObjectDrawInfo } from '../canvas.interfaces';
import { getLayersTiming, sortLayers } from '../helpers';
import { getLayersBounds } from '../helpers/getLayersBounds';
import { DrawDataService } from '../services/draw-data.service';

@Injectable()
export class SelectionService {
  private _items: ObjectDrawInfo[] = [];

  private selectionChanged = new Subject<{
    oldValues: ObjectDrawInfo[];
    newValues: ObjectDrawInfo[];
  }>();
  selectionChanged$ = this.selectionChanged.asObservable();

  constructor(private readonly drawDataService: DrawDataService) {}

  get items() {
    return this._items;
  }

  get leftmostItem() {
    return this._items[0];
  }

  get bounds() {
    return getLayersBounds(this._items);
  }

  get timing() {
    return getLayersTiming(this._items);
  }

  add(name: string, shiftKey: boolean = false) {
    const item = this.drawDataService.get(name);

    const singleSelection = this.isSingleSelection(item, shiftKey);

    const newSelection: ObjectDrawInfo[] = [];
    if (singleSelection) {
      newSelection.push(item);
    } else {
      const parentItems = sortLayers(item.parent.children);

      const indexOld = parentItems.findIndex(
        (c) => c.name === this._items[0].name
      );
      const indexNew = parentItems.findIndex((c) => c.name === item.name);

      const indexStart = Math.min(indexOld, indexNew);
      const indexEnd = Math.max(indexOld, indexNew);

      for (let i = indexStart; i <= indexEnd; i++) {
        newSelection.push(parentItems[i]);
      }
    }

    this.createSelection(newSelection);
  }

  addWithSiblings(name: string) {
    const item = this.drawDataService.get(name);
    const itemParent = item.parent;

    this.createSelection(itemParent.children);
  }

  clear() {
    if (this._items.length === 0) {
      return;
    }

    this.selectionChanged.next({
      oldValues: this._items,
      newValues: [],
    });

    this._items = [];
  }

  isSelected(name: string) {
    return this._items.some((i) => i.name === name);
  }

  private createSelection(selection: ObjectDrawInfo[]) {
    const selectionSorted = sortLayers(selection);

    this.selectionChanged.next({
      oldValues: this._items,
      newValues: [...selectionSorted],
    });

    this._items = [...selectionSorted];
  }

  private isSingleSelection(item: ObjectDrawInfo, shiftKey: boolean) {
    return (
      this._items.length === 0 ||
      item.name === TIME_CURSOR_NAME ||
      item.singleSelectionOnly ||
      !shiftKey ||
      this._items[0].parent !== item.parent
    );
  }
}
