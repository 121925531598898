import { Inject, Injectable } from '@angular/core';
import {
  FontAsset,
  FontExtension,
  fontExtensionToFormatName,
  getConcreteFontAsset,
  UrlFile,
} from '@openreel/creator/common';
import { DOCUMENT } from '@angular/common';
import WebFont from 'webfontloader';
import { FontService } from './font.service';
import { ReplaySubject } from 'rxjs';

const CUSTOM_FONT_DIRECTORY = '/assets/common/fonts/';
export type FONT_LOAD_STATE = 'pending' | 'loading' | 'active';

@Injectable({
  providedIn: 'root',
})
export class FontDOMService {
  private readonly fontsInDOM = new Map<string, FONT_LOAD_STATE>();
  public readonly fontsLoading$ = new ReplaySubject(1);

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.fontsLoading$.next(false);
  }

  addFontsToDOM(fonts: FontAsset[]) {
    const google: WebFont.Google = { families: [] }
    const custom: WebFont.Custom = { families: [] }

    for (const font of fonts) {
      const [googleFont, customFont] = getConcreteFontAsset(font);
      if (googleFont) {
        let weights: string[];
        if (googleFont.weights) {
          weights = FontService.filterGoogleFontsVariants(googleFont.weights);
        } else {
          weights = ['400'];
        }

        weights.forEach((weight) => {
          const fontId = this.processFont(googleFont.family, weight);
          if (fontId) {
            google.families.push(fontId);
          }
        });

      } else if (customFont) {
        let weights: string[] = customFont.weights;
        if (!weights) {
          weights = ['400'];
        }

        weights.forEach((weight) => {
          const fontId = this.processFont(customFont.family, weight);
          if (fontId) {
            custom.families.push(fontId);
            const elem = this.getFontElement(font);
            this.document.head.appendChild(elem);
          }
        });
      }
    }

    const config: WebFont.Config = {};

    if (google.families?.length) {
      config.google = google;
    }
    if (custom.families?.length) {
      config.custom = custom;
    }

    if (config.google || config.custom) {
      WebFont.load({
        classes: false,
        fontloading: (family, fvd) => {
          this.fontsInDOM.set(`${family}:${fvd}`, 'loading');
          this.refreshFontsLoadingState();
        },
        fontactive: (family, fvd) => {
          this.fontsInDOM.set(`${family}:${fvd}`, 'active');
          this.refreshFontsLoadingState();
        },
        fontinactive: (family, fvd) => {
          this.fontsInDOM.delete(`${family}:${fvd}`);
          console.warn('Cannot import font ', family);
          this.refreshFontsLoadingState();
        },
        ...config
      });
    }
  }

  private processFont(family: string, weight: string) {
    const fvd = `n${weight.charAt(0)}` // italics is not supported
    const fontId = `${family}:${fvd}`

    if (this.fontsInDOM.get(fontId)) {
      return null;
    } else {
      this.fontsInDOM.set(fontId, 'pending');
      this.fontsLoading$.next(true);
      return `${family}:${weight}`;
    }
  }

  private refreshFontsLoadingState() {
    const states = Array.from(this.fontsInDOM.values());
    this.fontsLoading$.next(states.includes('pending') || states.includes('loading'));
  }

  getFontElement(font: FontAsset) {
    const [googleFont, customFont] = getConcreteFontAsset(font);
    return googleFont
      ? this.createLink(googleFont.file.path.toString())
      : this.createStyle(customFont.family, customFont.files, customFont.formats);
  }

  createLink(href: string) {
    const link = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;

    return link;
  }

  createStyle(family: string, files: UrlFile[], formats: FontExtension[]) {
    const style = this.document.createElement('style');
    style.innerHTML = `
      @font-face {
        font-family: "${family}";
        src: ${files
          .map(
            (file, index) =>
              `url("${CUSTOM_FONT_DIRECTORY}${file.path}") format("${fontExtensionToFormatName(formats[index])}")`
          )
          .join(',')};
      }
    `;

    return style;
  }
}
