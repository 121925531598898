import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Cleanupable } from '@openreel/common';
import { UpdateTrimBoundsEvent } from '@openreel/creator/app/common/timelines-canvas/canvas.interfaces';
import { CanvasCreateOptions, TrimData } from '@openreel/creator/app/common/timelines-canvas/services/canvas.service';
import { ViewportService } from '../../services/viewport.service';
import { Durations, TimelinesWithOptions, WorkflowDataDto } from '@openreel/creator/common';
import { TimeBarComponent } from '../time-bar/time-bar.component';

@Component({
  selector: 'openreel-wf-timelines',
  templateUrl: './timelines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewportService],
})
export class TimelinesComponent extends Cleanupable implements AfterViewInit, OnDestroy {
  @Input() currentTime: number;
  @Input() timelines$: Observable<TimelinesWithOptions>;
  @Input() durations$: Observable<Durations>;
  @Input() workflow$: Observable<WorkflowDataDto>;
  @Input() trimmers: TrimData[];
  @Input() canvasCreateOptions: Partial<CanvasCreateOptions>;
  @Output() updateTrimmer = new EventEmitter<UpdateTrimBoundsEvent>();
  @Output() seekTime = new EventEmitter<number>();

  @ViewChild(TimeBarComponent) timeBarCmp: TimeBarComponent;
  @ViewChild('timelines') timelinesEl: ElementRef<HTMLDivElement>;

  private readonly timelinesWidthChanged = new Subject<void>();
  private readonly timelinesResizeObserver = new ResizeObserver(() => {
    this.timelinesWidthChanged.next();
    this.timeBarCmp.updateTimeBar();
  });

  ngAfterViewInit() {
    this.timelinesResizeObserver.observe(this.timelinesEl.nativeElement);
  }

  ngOnDestroy() {
    this.timelinesResizeObserver.disconnect();
    super.ngOnDestroy();
  }

  onUpdateTrimmer = (event: UpdateTrimBoundsEvent) => this.updateTrimmer.emit(event);

  onSeekTime = (seekTime: number) => this.seekTime.emit(seekTime);
}
