import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrAssetsListFile } from '@openreel/creator/common';
import { noop } from 'lodash-es';

@Component({
  selector: 'openreel-email-logo-picker',
  templateUrl: './openreel-email-logo-picker.component.html',
  styleUrls: ['./openreel-email-logo-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelEmailLogoComponent,
    },
  ],
})
export class OpenreelEmailLogoComponent implements OnChanges, ControlValueAccessor {
  @Input() logoAssets: OrAssetsListFile[];

  @Output() selectedLogoChanged = new EventEmitter<OrAssetsListFile>();

  selectedLogo: OrAssetsListFile;

  ngOnChanges(changes: SimpleChanges) {
    if (
      'logoAssets' in changes &&
      this.selectedLogo &&
      !this.arrayContainsObject(changes.logoAssets.currentValue, this.selectedLogo)
    ) {
      this.setDefaultLogo(changes.logoAssets.currentValue);
    }
  }

  propagateOnChanged: (logo: OrAssetsListFile) => void = noop;
  propagateOnTouched = noop;

  writeValue(logo: OrAssetsListFile[]) {
    this.selectedLogo = this.selectedLogo ?? logo[0];
    if (this.selectedLogo) {
      this.setSelectedLogo(this.selectedLogo);
    }
  }

  registerOnChange(fn: (logo: OrAssetsListFile) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  onLogoChange(logoId: number) {
    const logo = this.logoAssets.find((f) => f.path === logoId);

    if (!logo) {
      throw new Error('Logo not found.');
    }

    this.setSelectedLogo(logo);

    this.propagateOnChanged(logo);
  }

  onSelectToggled(opened: boolean) {
    if (!opened) {
      this.propagateOnTouched();
    }
  }

  private setSelectedLogo({ path }: OrAssetsListFile) {
    this.selectedLogo = this.logoAssets.find((logo) => logo.path === path);
  }

  private setDefaultLogo(newLogoAssets: OrAssetsListFile[]) {
    this.selectedLogo = newLogoAssets.find((logo) => logo.isDefault);
    this.selectedLogoChanged.emit(this.selectedLogo);
  }

  private arrayContainsObject(array: object[], object: object) {
    return array.some((item) => Object.keys(item).every((key) => item[key] === object[key]));
  }
}
