import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AssetsBaseService } from '../services/assets/assets-base.service';

const RETRIES_COUNT = 3;

const assetsCache = new Map<number, string>();

@Directive({
  selector: '[openreelAssetImage]',
})
export class AssetImageDirective implements OnChanges {
  @Input('openreelAssetImage') assetId: number;

  private fetchCounter = 0;

  constructor(
    private readonly elementRef: ElementRef,
    private assetService: AssetsBaseService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.elementRef.nativeElement.style.display = 'none';
    if (!('assetId' in changes)) {
      return;
    }

    if (!this.assetId) {
      this.elementRef.nativeElement.src = '';
      return;
    }

    this.showImage();
  }

  @HostListener('load') onLoad() {
    this.elementRef.nativeElement.style.display = 'inline-block';
  }
  @HostListener('error') onError() {
    assetsCache.delete(this.assetId);
    this.showImage();
  }

  private async showImage() {
    if (this.fetchCounter > RETRIES_COUNT) {
      this.elementRef.nativeElement.alt = 'Could not fetch the image.';
      this.elementRef.nativeElement.style.display = 'inline-block';
      return;
    }

    if (assetsCache.has(this.assetId)) {
      this.elementRef.nativeElement.src = assetsCache.get(this.assetId);
      return;
    }

    const assetUrl = await this.assetService
      .getAssetUrlById('or-assets', this.assetId)
      .toPromise();

    assetsCache.set(this.assetId, assetUrl);
    this.elementRef.nativeElement.src = assetUrl;
    this.fetchCounter++;
  }
}
