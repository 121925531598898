import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ROUTE_DASHBOARD, ROUTE_DIRECTOR_LOGIN } from '@openreel/common';

import { Injectable } from '@angular/core';
import { environment } from '../../../ui/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectToCaptureGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot) {
    const link = route.queryParams?.redirect
      ? `${environment.nextGenAppUrl}#/${ROUTE_DIRECTOR_LOGIN}?redirect=${environment.creatorAppUrl}/#${route.queryParams.redirect}`
      : `${environment.nextGenAppUrl}#/${ROUTE_DASHBOARD}`;

    window.location.href = link;
    return true;
  }
}
