import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { map } from 'rxjs/operators';
import { SocialConfigService } from '../services/social-login/social-config.service';

export const SocialConfigFactory = (socialConfigService: SocialConfigService) =>
  socialConfigService
    .getClientConfig()
    .pipe(
      map((config) => {
        const providers = [];
        if (config?.google_client_id?.length > 0) {
          providers.push({
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(config.google_client_id),
          });
        }

        return {
          autoLogin: false,
          providers: providers,
        } as SocialAuthServiceConfig;
      })
    )
    .toPromise();
