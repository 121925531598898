import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
    selector: 'or-slug-info-icon',
    templateUrl: './slug-info-icon.component.html',
    styleUrls: ['./slug-info-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlugInfoIconComponent {
    // eslint-disable-next-line max-len
    infoMessage = 'Your slug will be used in your content\'s public URL, so it cannot be changed. A random ID will be attached to your slug to keep it unique.';
}