import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
// import { } from 'rxjs/operators';
// import { IStreamingParticipant } from '../../interfaces/streaming-lib-service.interface';

@Component({
  selector: 'openreel-audio-participant',
  templateUrl: './audio-participant.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioParticipantComponent {
  _source = new MediaStream();
  @Input() set source(source: MediaStreamTrack) {
    if (!source) return;
    this._source = new MediaStream([source]);
  }

  @Input() muted: boolean;
}
