import { Injectable } from '@angular/core';
import { pixelsToMs } from '../helpers';
import { ObjectDrawInfo, TimingInfo } from '../canvas.interfaces';
import { DrawDataService } from '../services/draw-data.service';
import { InteractionService } from './interaction-base.service';
import { SelectionService } from './selection.service';

export enum StretchSide {
  Left,
  Right,
}

export interface StretchCalcResult {
  snapped: boolean;
}

@Injectable()
export class StretchService extends InteractionService {
  type = 'stretch' as const;

  private _stretchSide: StretchSide;

  constructor(
    private readonly drawDataService: DrawDataService,
    private readonly selectionService: SelectionService
  ) {
    super();
  }

  reset() {
    super.reset();
    this._stretchSide = null;
  }

  start(stretchSide: StretchSide) {
    super.startInteraction(
      this.selectionService.items,
      this.selectionService.bounds,
      this.selectionService.timing
    );
    this._stretchSide = stretchSide;
  }

  calcStretchPosition(
    currentLeft: number,
    currentScaleX: number,
    minDuration: number
  ): StretchCalcResult {
    this._snapped = false;

    const currentTiming = TimingInfo.from(this.validTiming);

    if (this._stretchSide === StretchSide.Left) {
      currentTiming.startAt = pixelsToMs(currentLeft, this.getItemsParent());
    } else {
      currentTiming.endAt = pixelsToMs(
        currentLeft + this._startBounds.width * currentScaleX,
        this.getItemsParent()
      );
    }

    this._moveDirection =
      (this._stretchSide === StretchSide.Left &&
        currentTiming.duration > this.validTiming.duration) ||
      (this._stretchSide === StretchSide.Right &&
        currentTiming.duration < this.validTiming.duration)
        ? -1
        : 1;

    const minDurationResult = this.checkMinDuration(currentTiming, minDuration);

    if (!minDurationResult.valid) {
      this._validTiming.set(minDurationResult.timing);

      return { snapped: false }
    }

    const overlapResult = this.checkSiblingOverlaps(
      this.getItemsParent(),
      currentTiming,
      this._items
    );

    if (overlapResult.overlaps) {
      this._snapped = overlapResult.snap;

      if (overlapResult.snap) {
        this._validTiming.set(overlapResult.snapTiming);
      }
      return { snapped: overlapResult.snap };
    }

    const parentResult = this.snapParentBounds(
      this.getItemsParent(),
      currentTiming,
      this._items
    );

    if (!parentResult.inBounds) {
      this._snapped = parentResult.snapped;

      this._validTiming.set(parentResult.snapTiming ?? this._validTiming);
      return { snapped: parentResult.snapped };
    }
    this._validTiming.set(currentTiming);

    return { snapped: false };
  }

  private checkSiblingOverlaps(
    parent: ObjectDrawInfo,
    currentTiming: TimingInfo,
    exceptObjects: ObjectDrawInfo[]
  ) {
    const overlapItems = this.drawDataService.getOverlappingObjects(
      parent,
      currentTiming,
      exceptObjects
    );

    if (overlapItems.length === 0) {
      return { overlaps: false, snapped: false };
    }

    // We take first overlap in direction of movement
    const firstOverlap =
      this._stretchSide === StretchSide.Right
        ? overlapItems[0]
        : overlapItems[overlapItems.length - 1];

    const firstOverlapTiming = firstOverlap.item.timing;

    const snapStartAt =
      this._stretchSide === StretchSide.Left
        ? firstOverlapTiming.endAt + 1
        : currentTiming.startAt;

    const snapEndAt =
      this._stretchSide === StretchSide.Left
        ? currentTiming.endAt
        : firstOverlapTiming.startAt - 1;

    const snapTiming = new TimingInfo(snapStartAt, snapEndAt);

    const isSnapInParentBounds = this.drawDataService.isInBounds(
      parent,
      snapTiming
    );
    const isSnapOverlapping =
      this.drawDataService.getOverlappingObjects(
        parent,
        snapTiming,
        exceptObjects
      ).length > 0;

    const canSnap = isSnapInParentBounds && !isSnapOverlapping;

    return {
      overlaps: true,
      snap: canSnap,
      snapTiming: canSnap ? snapTiming : null,
    };
  }

  private snapParentBounds(
    parent: ObjectDrawInfo,
    currentTiming: TimingInfo,
    exceptObjects: ObjectDrawInfo[]
  ) {
    const isInBounds = this.drawDataService.isInBounds(parent, currentTiming);
    if (isInBounds) {
      return { inBounds: true };
    }

    const leftBound = parent.timing.startAt;
    const rightBound = parent.timing.endAt;

    const snapStartAt =
      this._stretchSide === StretchSide.Left
        ? leftBound
        : currentTiming.startAt;

    const snapEndAt =
      this._stretchSide === StretchSide.Left ? currentTiming.endAt : rightBound;

    const snapTiming = new TimingInfo(snapStartAt, snapEndAt);

    const timelineBoundOverlaps = this.drawDataService.getOverlappingObjects(
      parent,
      snapTiming,
      exceptObjects
    );

    const canSnap = timelineBoundOverlaps.length === 0;

    return {
      inBounds: false,
      snapped: canSnap,
      snapTiming: canSnap ? snapTiming : null,
    };
  }

  private checkMinDuration(currentTiming: TimingInfo, minDuration: number) {
    if (!minDuration || currentTiming.duration >= minDuration) {
      return { valid: true, timing: null };
    }

    const result = {
      valid: false,
      timing: new TimingInfo(currentTiming.startAt, currentTiming.endAt),
    };

    if (this._stretchSide === StretchSide.Left) {
      result.timing.startAt = currentTiming.endAt - minDuration;
    } else {
      result.timing.endAt = currentTiming.startAt + minDuration;
    }

    return result;
  }
}
