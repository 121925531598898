import { Component, Input } from '@angular/core';

@Component({
  selector: 'openreel-waiting-room',
  templateUrl: './waiting-room.component.html',
})
export class WaitingRoomComponent {
  @Input()
  message = '';
}
