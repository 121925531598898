import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  CAPTURE_CREATE_TEAM,
  FeatureFlaggingService,
  IUserAssociatedAccount,
  RegistrationRespDto,
} from '@openreel/common';
import { ToastrService } from 'ngx-toastr';
import { GUEST_CREATE_PASSWORD, ROUTE_DIRECTOR_LOGIN } from '../../route-utils';
import { AuthService } from '../../services/auth/auth.service';
import {
  GuestUserAccountInfo,
  GUEST_USER_ACCOUNT_INFO,
} from '../guest-fill-account-info-form/guest-fill-account-info-form.util';
export enum AccountSelectionType {
  SELF_REGISTRATION = 'self_registration',
  LOGIN = 'login',
  EXISTING_USER_REGISTRATION = 'existing_user_registration',
}

@Component({
  selector: 'openreel-guest-select-account',
  templateUrl: './guest-select-account.component.html',
  styleUrls: ['./guest-select-account.component.scss'],
})
export class GuestSelectAccountComponent implements OnInit {
  guest: GuestUserAccountInfo;
  accounts: IUserAssociatedAccount[];
  accountSelectionType: AccountSelectionType;
  AccountSelectionType = AccountSelectionType;
  createTeam = false;
  creatingTeam = false;
  selectingAccount = false;
  canCreateTeam$ = this.featureFlagService.isFeatureFlagEnabled(CAPTURE_CREATE_TEAM);

  @Output()
  loginEvent = new EventEmitter();

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private featureFlagService: FeatureFlaggingService
  ) {}

  ngOnInit(): void {
    // for registration (create account) flow
    const guestDetails = JSON.parse(localStorage.getItem(GUEST_USER_ACCOUNT_INFO)) as GuestUserAccountInfo;
    if (guestDetails) {
      this.guest = guestDetails;
      this.accounts = this.guest.available_accounts;
      this.accountSelectionType = AccountSelectionType.SELF_REGISTRATION;
    }
    // for login flow
    const userDetails = this.authService.getUserDetails();
    if (userDetails && !userDetails.data.account_id) {
      this.accountSelectionType = AccountSelectionType.LOGIN;
      this.accounts = userDetails.data?.associated_accounts || [];
    }

    if (!userDetails && !guestDetails) {
      this.router.navigate(['/' + ROUTE_DIRECTOR_LOGIN]);
    }
  }

  selectAccount(account: IUserAssociatedAccount) {
    if (!this.selectingAccount) {
      this.selectingAccount = true;
      switch (this.accountSelectionType) {
        case AccountSelectionType.SELF_REGISTRATION:
          this.guest.account_id = account.id;
          this.guest.company_name = account.name;
          this.createAccount();
          this.selectingAccount = false;
          break;
        case AccountSelectionType.LOGIN:
          this.updateAccountDetails(account.id);
          break;
        case AccountSelectionType.EXISTING_USER_REGISTRATION:
          this.existingUserRegistration(account.id, '');
          break;
      }
    }
  }

  async existingUserRegistration(id: number, companyName: string) {
    if (this.creatingTeam || (!id && !companyName.trim())) return;
    this.creatingTeam = true;
    this.authService.existingUserRegistration(id, companyName.trim()).subscribe(
      async (res: RegistrationRespDto) => {
        await this.updateAccountDetails(res.data.account_id);
        this.creatingTeam = false;
        this.selectingAccount = false;
      },
      (error) => {
        this.toastr.error(error.message, 'Error!');
        this.creatingTeam = false;
        this.selectingAccount = false;
      }
    );
  }
  async updateAccountDetails(id: number) {
    try {
      const res = await this.authService.updateAccountDetails(id).toPromise();
      if (res.data?.nextgenAllowed) {
        this.loginUser(res);
      }
    } catch (error) {
      this.toastr.error(error.message, 'Error!');
      this.selectingAccount = false;
    }
  }
  joinTeam() {
    const userDetails = this.authService.getUserDetails();
    this.accountSelectionType = AccountSelectionType.EXISTING_USER_REGISTRATION;
    this.accounts = userDetails.data.available_accounts;
  }
  backToLogin() {
    const userDetails = this.authService.getUserDetails();
    this.accountSelectionType = AccountSelectionType.LOGIN;
    this.accounts = userDetails.data.associated_accounts;
    this.createTeam = false;
  }

  private loginUser(res) {
    this.loginEvent.emit();
    this.toastr.success(res.message, 'Success!');
    this.selectingAccount = false;
  }

  createAccount() {
    switch (this.accountSelectionType) {
      case AccountSelectionType.SELF_REGISTRATION:
        localStorage.setItem(GUEST_USER_ACCOUNT_INFO, JSON.stringify(this.guest));
        this.router.navigate(['/' + GUEST_CREATE_PASSWORD]);
        break;
      case AccountSelectionType.EXISTING_USER_REGISTRATION:
        this.createTeam = true;
        break;
    }
  }

  getInitials(name: string) {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1);

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1);
    }
    return initials.toUpperCase();
  }
}
