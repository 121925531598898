<div [formGroup]="formArray" class="color-tags" [class.hasPalette]="showPalette">
  <openreel-color-picker
    *ngFor="let tag of formArray.controls; index as i"
    [formControlName]="i"
    [showName]="showTagName"
    [showColorString]="showColorString"
    [showPalette]="showPalette"
    (colorPickerClosed)="onColorPickerClosed()"
    (valueChanged)="onColorPickerValueChanged()"
  ></openreel-color-picker>
</div>
