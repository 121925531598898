import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'openreel-hosting-confirm',
  templateUrl: './hosting-confirm.component.html',
  styleUrls: ['./hosting-confirm.component.scss']
})
export class HostingConfirmComponent {

  header = 'Confirm';
  text = ''

  isLightThemed = true;
  isPopup = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {header: string, text:string},
      public dialogRef: MatDialogRef<HostingConfirmComponent>) { 

    this.header = data.header;
    this.text = data.text;
  }

  //
  // send confirm result to caller
  //
  onConfirm(){
    const data = {confirm:true};
    this.dialogRef.close(data);
  }

}
