import { Directive, TemplateRef, ViewContainerRef } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { Cleanupable } from '../classes/cleanupable';

@Directive()
export abstract class BaseConditionalStructuralDirective extends Cleanupable {
  private renderElse$ = new ReplaySubject<void>(1)
  private hasView = false
  abstract elseTemplate?: TemplateRef<unknown>

  constructor(private templateRef: TemplateRef<unknown>, 
              private viewContainer: ViewContainerRef) {
    super();
    this.mortalize(this.renderElse$).subscribe(() => {
        if (this.elseTemplate) {
            this.viewContainer.createEmbeddedView(this.elseTemplate)
        }
    })
  }

  protected renderView(shouldShow: boolean) {
    if (shouldShow && !this.hasView) {
        this.viewContainer.clear()
        this.viewContainer.createEmbeddedView(this.templateRef)
        this.hasView = true
    } else if (this.hasView) {
        this.viewContainer.clear()
        this.hasView = false
        this.renderElse$.next()
    }
  }
}
