<div class="upload-container" orDragOver>
  <openreel-uploader
    type="video"
    allowDragAndDrop="true"
    (started)="onUploadStarted($event)"
    (failed)="onUploadFailed()"
  >
    <div class="upload-message">
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
      <p>
        <a>Click to upload</a>
        <span> or drag and drop</span>
      </p>
      <p class="sub-text">.mp4, .mov or .webm (max 5GB per file)</p>
    </div>
  </openreel-uploader>

  <div class="upload-tos">
    By Uploading, you agree to OpenReel's &nbsp;<a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.openreel.com/terms-of-service/"
      >Terms of Service</a
    >
  </div>
</div>
