import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DirectorState } from './director.state';

export const selectFeature = createFeatureSelector<DirectorState>('director');

export const selectDirectorUserDetails = createSelector(
  selectFeature,
  (director) => director.user
);

export const selectDirectorUserID = createSelector(
  selectFeature,
  (director) => director.user?.id
);

export const selectDirectorRecordings = createSelector(
  selectFeature,
  (director) => director.recordings
);

export const selectDirectorFavouriteRecordings = createSelector(
  selectFeature,
  (director) => director.favouriteRecordings
);

export const selectDirectorArchivedRecordings = createSelector(
  selectFeature,
  (director) => director.archivedRecordings
);

export const selectDirectorAllRecordings = createSelector(
  selectFeature,
  (director) => [
    ...director.favouriteRecordings,
    ...director.recordings.rows,
    ...director.archivedRecordings.rows,
  ]
);

export const selectRecordingsLoading = createSelector(
  selectFeature,
  (director) =>
    director.favouritesLoading ||
    director.recordingsLoading ||
    director.archivesLoading
);
