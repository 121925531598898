import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'or-user-upload-dialog',
    templateUrl: './user-upload-dialog.component.html',
    styleUrls: ['./user-upload-dialog.component.scss'],
})
export class UserUploadDialogComponent {
    isLightThemed = true;
    isPopup = true;

    constructor(
        private dialogRef: MatDialogRef<UserUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
    ) {}

    uploadStarted(id: number) {
        this.dialogRef.close(id);
    }
}
