import { Injectable } from '@angular/core';
import { SubjectSocketService } from '../../socket/subject-socket.service';
import {
  EVT_S2D_APP_BACKGROUND,
  BackgroundSet,
  DirectorLiveStatus,
  EVT_D2S_DIRECTOR_LIVE_STATUS,
} from '../../../interfaces/socket-events';
import { Observable } from 'rxjs';

@Injectable()
export class SocketExtensionAppService {
  directorOnline$: Observable<DirectorLiveStatus>;

  constructor(private socket: SubjectSocketService) {
    this.directorOnline$ = this.socket.getSocketEventByName(
      EVT_D2S_DIRECTOR_LIVE_STATUS
    );
  }

  async sendSetBackground(isBackground: boolean) {
    const resp: BackgroundSet = {
      stat: '1',
      is_background: isBackground ? 1 : 0,
    };
    return await this.socket.sendAndWaitAck({
      eventName: EVT_S2D_APP_BACKGROUND,
      data: resp,
    });
  }
}
