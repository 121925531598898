/**
 * @param blob 
 * @param readAs 
 * @returns Promise<ArrayBuffer>
 */
 const blobToBufferOrString = (blob: Blob, readAs: 'string' | 'buffer'): Promise<Uint8Array | string> => 
 new Promise((resolve, reject) => {
   const reader = new FileReader();
   /**
    * @param event 
    */
   const loadedCb = (event: ProgressEvent<FileReader>) => {
     const buf = event.target.result;
     reader.removeEventListener('loadend', loadedCb);
     resolve(typeof buf === 'string' ? buf : new Uint8Array(buf));
   };

   const errorCb = () => {
     reader.removeEventListener('error', errorCb);
     reject(new Error('Error while reading the Blob object'));
   };

   reader.addEventListener('loadend', loadedCb);
   reader.addEventListener('error', errorCb);
   if (readAs !== 'string') {
     reader.readAsArrayBuffer(blob);
   } else {
     reader.readAsText(blob);
   }
 });

/**
* @param text 
* @returns Blob
*/
const textToBlob = (text: string): Blob => new Blob([text], { type: 'text/vtt' });

/**
 * @param text 
 * @returns ObjectURL
 */
const blobToURL = (text: string): string => URL
  .createObjectURL(textToBlob(text));

/**
* @param utf8str 
* @returns string
*/
const toVTT = (utf8str: string) => utf8str
 .replace(/\{\\([ibu])\}/g, '</$1>')
 .replace(/\{\\([ibu])1\}/g, '<$1>')
 .replace(/\{([ibu])\}/g, '<$1>')
 .replace(/\{\/([ibu])\}/g, '</$1>')
 .replace(/(\d\d:\d\d:\d\d),(\d\d\d)/g, '$1.$2')
 .concat('\r\n\r\n');

/**
* @param resource 
* @returns Promise<string|Blob>
*/
export async function toWebVTT(resource: Blob, returnAs?: 'blob'): Promise<Blob>;
export async function toWebVTT(resource: Blob, returnAs: 'text'|'url'): Promise<string>;
export async function toWebVTT(resource: Blob, returnAs: 'text'|'blob'|'url' = 'blob'): Promise<Blob | string> {
 let text;
 const vttString = 'WEBVTT FILE\r\n\r\n'; // leading text
 try {
   const buffer = await blobToBufferOrString(resource, 'string');
   text = vttString.concat(toVTT(buffer as string));
 } catch (e) {
   const buffer = await blobToBufferOrString(resource, 'buffer');
   const decode = new TextDecoder('utf-8').decode(buffer as Uint8Array);
   text = vttString.concat(toVTT(decode));
 }
 switch (returnAs) {
    case 'blob':
        return textToBlob(text);
    case 'text':
        return text;
    case 'url':
        return blobToURL(text);
    default:
        throw Error('Invalid return type.');
 }
}
