import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { FeatureFlaggingService } from '../services';
import { Injectable } from '@angular/core';
import { ROUTE_DASHBOARD } from '../route-utils';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly featureFlaggingService: FeatureFlaggingService,
    private readonly toastr: ToastrService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.featureFlaggingService.flagChange$.pipe(
      map((flags) => {
        const flagsToCheck: string[] = Array.isArray(route.data.featureFlag)
          ? route.data.featureFlag
          : [route.data.featureFlag];

        const someFailed = flagsToCheck.some((f) => !flags[f]);

        if (someFailed) {
          this.router.navigate([ROUTE_DASHBOARD]);
          this.toastr.error('This feature is not enabled!');
          return false;
        }

        return true;
      })
    );
  }
}
