<openreel-cue-player-image
  *ngIf="isImagePlayer"
  [data]="playerData"
  (onLoaded)="emitLoadedEvent($event)"
  (onTimeUpdate)="onChildPlayerTimeUpdate($event)"
  (onEnded)="emitEndedEvent($event)"
  (onSeeked)="emitSeekedEvent($event)"
  (onSeeking)="emitSeekingEvent($event)"
  (onError)="emitErrorEvent($event)"
></openreel-cue-player-image>
<openreel-cue-player-video
  *ngIf="isVideoPlayer"
  [data]="playerData"
  [styles]="styles"
  (onLoaded)="emitLoadedEvent($event)"
  (onTimeUpdate)="onChildPlayerTimeUpdate($event)"
  (onEnded)="emitEndedEvent($event)"
  (onSeeked)="emitSeekedEvent($event)"
  (onSeeking)="emitSeekingEvent($event)"
  (onError)="emitErrorEvent($event)"
></openreel-cue-player-video>
<openreel-cue-player-lottie
  *ngIf="isLottiePlayer"
  [data]="playerData"
  (onLoaded)="emitLoadedEvent($event)"
  (onTimeUpdate)="onChildPlayerTimeUpdate($event)"
  (onEnded)="emitEndedEvent($event)"
  (onSeeked)="emitSeekedEvent($event)"
  (onSeeking)="emitSeekingEvent($event)"
  (onError)="emitErrorEvent($event)"
></openreel-cue-player-lottie>
<openreel-cue-player-timelines
  *ngIf="isTimelinesPlayer"
  [data]="playerData"
  (onLoaded)="emitLoadedEvent($event)"
  (onTimeUpdate)="onChildPlayerTimeUpdate($event)"
  (onEnded)="emitEndedEvent($event)"
  (onSeeked)="emitSeekedEvent($event)"
  (onSeeking)="emitSeekingEvent($event)"
  (onError)="emitErrorEvent($event)"
></openreel-cue-player-timelines>
