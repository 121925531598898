import * as fromStore from './store';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthService,
  DirectorSocketService,
  OpenreelCommonModule,
} from '@openreel/common';
import { SelfRecordUploadProgressComponent } from './components/self-record/self-record-upload-progress.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SelfRecordEffects } from './store/effects/self-record.effects';
import { SelfRecordPreviewComponent } from './components/self-record/self-record-preview.component';
import { SelfRecordGuard } from './self-record.guard';
import { SelfRecordComponent } from './components/self-record/self-record.component';
import { SharedModule } from '../../shared/shared.module';
import { RecordingPreviewDialogComponent } from './components/recording-preview/recording-preview-dialog.component';
import { SelfRecordTeleprompterEffects } from './store/effects/self-record-teleprompter.effects';
import { SelfRecordTeleprompterDialogComponent } from './components/self-record-teleprompter/self-record-teleprompter-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelfRecordTrimComponent } from './components/self-record/self-record-trim.component';
import { TrimmerModule } from '../trimmer/trimmer.module';
import { SelfRecordClipsEffects } from './store/effects/self-record-clips.effects';
import { OpenreelVideoControlsModule } from '@openreel/ui/openreel-video-controls';

@NgModule({
  declarations: [
    SelfRecordComponent,
    SelfRecordUploadProgressComponent,
    SelfRecordPreviewComponent,
    RecordingPreviewDialogComponent,
    SelfRecordTeleprompterDialogComponent,
    SelfRecordTrimComponent,
  ],
  imports: [
    SharedModule,
    OpenreelVideoControlsModule,
    TrimmerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OpenreelCommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers),
    EffectsModule.forFeature([
      SelfRecordEffects,
      SelfRecordClipsEffects,
      SelfRecordTeleprompterEffects,
    ]),
  ],
  exports: [SelfRecordComponent],
  providers: [AuthService, DirectorSocketService, SelfRecordGuard],
})
export class RecordingModule {}
