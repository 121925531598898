import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  CAPTURE_AUDIO_METER,
  CAPTURE_SESSION_CLIPS,
  CHATTING,
  TELEPROMPTER,
} from './feature-flagging.constants';

@Injectable()
export class FeatureFlaggingServiceMock {
  public flags;
  flagChange: Subject<Record<string, boolean>> = new Subject<
    Record<string, boolean>
  >();
  constructor() {
    this.flags = {
      [CHATTING]: true,
      [TELEPROMPTER]: true,
      [CAPTURE_SESSION_CLIPS]: true,
      [CAPTURE_AUDIO_METER]: true,
    };
  }
}
