import { fabric } from 'fabric';
import { clamp, round } from 'lodash-es';
import { TimelineType } from '@openreel/creator/common';
import { CanvasConfig } from '../canvas.config';

const FABRIC_TYPE = 'trimmer';

const TRIM_HANDLE_TYPE = 'trim-handle';
const TRIMMED_AREA_TYPE = 'trimmed-area';

const LEFT_HANDLE_NAME = 'left-handle';
const RIGHT_HANDLE_NAME = 'right-handle';

const RECT_COMMON_OPTIONS: fabric.IRectOptions = {
  rx: 0,
  ry: 0,
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  hasControls: false,
  hasBorders: false,
  strokeWidth: 0,
};

const TRIM_HANDLE_OPTIONS: fabric.IRectOptions = {
  ...RECT_COMMON_OPTIONS,
  hoverCursor: 'ew-resize',
  type: TRIM_HANDLE_TYPE,
  selectable: true,
};

const TRIMMED_AREA_OPTIONS: fabric.IRectOptions = {
  ...RECT_COMMON_OPTIONS,
  hoverCursor: 'move',
  type: TRIMMED_AREA_TYPE,
  selectable: true,
};

export interface TrimHandle {
  grayArea: fabric.Rect;
  handle: fabric.Path;
  position: number;
  type: 'left' | 'right';
}

export interface TrimmerDataOptions {
  timelineType: TimelineType;
  duration: number;
  from: number;
  to: number;
  config: CanvasConfig;
  updateTrimBounds?: (from: number, to: number, done: boolean) => void;
}

export interface TrimmerOptions extends fabric.IGroupOptions {
  data: TrimmerDataOptions;
}

export class Trimmer extends fabric.Group {
  type: typeof FABRIC_TYPE;
  data: TrimmerDataOptions;

  // Fabric Objects
  trimHandles: [TrimHandle, TrimHandle];
  trimmedArea: fabric.Rect;
  trimTexts: [fabric.Text, fabric.Text];

  // Trim moving
  movingStartPx: number;
  movingHandles: TrimHandle[];
  movingHandlesMs: number[];

  eventsInitialized: boolean;

  constructor(options: TrimmerOptions) {
    const objects: fabric.Object[] = [];

    const timelineHeight = options.data.config.timelines[options.data.timelineType].height;

    objects.push(
      new fabric.Rect({
        ...RECT_COMMON_OPTIONS,
        opacity: 0.7,
        fill: options.data.config.trimmer.backgroundAreaColor,
      }),
      new fabric.Rect({
        ...RECT_COMMON_OPTIONS,
        opacity: 0.7,
        fill: options.data.config.trimmer.backgroundAreaColor,
      }),
      new fabric.Rect({
        ...TRIMMED_AREA_OPTIONS,
        opacity: 0,
      }),

      new fabric.Path(getLeftHandleSvgPath(timelineHeight), {
        ...TRIM_HANDLE_OPTIONS,
        fill: options.data.config.trimmer.handleColor,
        name: LEFT_HANDLE_NAME,
      }),
      new fabric.Path(getRightHandleSvgPath(timelineHeight), {
        ...TRIM_HANDLE_OPTIONS,
        fill: options.data.config.trimmer.handleColor,
        name: RIGHT_HANDLE_NAME,
      }),
      new fabric.Text('', {
        left: 0,
        top: 0,
        fill: '#9da6af',
        fontFamily: 'Rubik',
        fontSize: 10,
      }),

      new fabric.Text('', {
        left: 0,
        top: 0,
        fill: '#9da6af',
        fontFamily: 'Rubik',
        fontSize: 10,
      })
    );

    super(objects, { ...options, type: FABRIC_TYPE });

    this.setupChildObjects();
  }

  updateBounds() {
    this.trimHandles[0].position = this.data.from;
    this.trimHandles[1].position = this.data.to;
  }

  render(ctx: CanvasRenderingContext2D) {
    if (!this.eventsInitialized) {
      this.eventsInitialized = true;
      this.listenToEvents();
    }
    if (this.visible) {
      this.renderTrimHandles();
      this.renderTrimmedArea();
      this.renderTrimTexts();
    }

    super.render(ctx);
  }

  private setupChildObjects() {
    const objects = this.getObjects();
    this.trimHandles = [
      {
        grayArea: objects[0],
        handle: objects[3] as fabric.Path,
        position: this.data.from,
        type: 'left',
      },
      {
        grayArea: objects[1],
        handle: objects[4] as fabric.Path,
        position: this.data.to,
        type: 'right',
      },
    ];
    this.trimTexts = [objects[5] as fabric.Text, objects[6] as fabric.Text];
    this.trimmedArea = objects[2];
  }

  private listenToEvents() {
    this.trimHandles.forEach((obj) => {
      obj.handle.on('mousedown', (opt) => {
        this.movingStartPx = opt.pointer.x;
        this.movingHandlesMs = [obj.position];
        this.movingHandles = [obj];
      });
    });
    this.trimHandles.forEach((obj) => {
      obj.handle.on('mousedblclick', () => {
        obj.position = obj.type === 'left' ? 0 : this.data.duration;
        this.emitUpdateEvent(true);
        this.canvas.requestRenderAll();
      });
    });

    this.trimmedArea.on('mousedown', (opt) => {
      this.movingStartPx = opt.pointer.x;
      this.movingHandlesMs = [...this.trimHandles.map((t) => t.position)];
      this.movingHandles = [...this.trimHandles];
    });

    this.canvas.on('mouse:move', (opt) => {
      if (!this.movingHandles?.length) {
        return;
      }

      const deltaPx = opt.pointer.x - this.movingStartPx;
      const deltaMs = (deltaPx / this.width) * this.data.duration;

      if (this.movingHandles.length > 1) {
        const moveMs =
          deltaMs > 0
            ? Math.min(this.data.duration - this.movingHandlesMs[1], deltaMs)
            : Math.max(-this.movingHandlesMs[0], deltaMs);

        this.movingHandles.forEach((handle, index) => (handle.position = round(this.movingHandlesMs[index] + moveMs)));
      } else {
        this.movingHandles[0].position = this.calculateNewTrimHandlePosition(
          this.movingHandles[0],
          this.movingHandlesMs[0],
          deltaMs
        );
      }

      this.emitUpdateEvent(false);
      this.canvas.requestRenderAll();
    });

    this.canvas.on('mouse:up', () => {
      if (!this.movingHandles?.length) {
        return;
      }

      this.movingHandles = [];
      this.emitUpdateEvent(true);
      this.canvas.requestRenderAll();
    });
  }

  private calculateNewTrimHandlePosition(trimHandle: TrimHandle, startMs: number, deltaMs: number) {
    const newPosition =
      trimHandle.type === 'left'
        ? clamp(startMs + deltaMs, 0, this.trimHandles[1].position)
        : clamp(startMs + deltaMs, this.trimHandles[0].position, this.data.duration);
    return round(newPosition);
  }

  private renderTrimHandles() {
    const common = {
      top: -this.height / 2,
      height: this.height,
    };

    this.trimHandles.forEach((obj, index) => {
      // Trimmed grayArea
      if (index === 0) {
        const width = (obj.position / this.data.duration) * this.width;
        obj.grayArea.set({
          ...common,
          left: -this.width / 2,
          width,
        });
      } else {
        const width = Math.max(
          0,
          ((this.data.duration - obj.position) / this.data.duration) * this.width - this.data.config.trimmer.handleWidth
        );
        obj.grayArea.set({
          ...common,
          left: this.width / 2 - width,
          width,
        });
      }

      // Trimmmed handle
      const handlePos = -this.width / 2 + (obj.position / this.data.duration) * this.width;
      obj.handle.set({
        ...common,
        left: Math.min(handlePos, this.width / 2 - this.data.config.trimmer.handleWidth),
      });

      obj.grayArea.setCoords();
      obj.handle.setCoords();
    });
  }

  private renderTrimmedArea() {
    const left = this.trimHandles[0].handle.left;
    const width = this.trimHandles[1].handle.left - left + this.data.config.trimmer.handleWidth;
    this.trimmedArea.set({
      top: -this.height / 2,
      height: this.height,
      left,
      width,
    });

    this.trimmedArea.setCoords();
  }

  private renderTrimTexts() {
    const textLeft = formatMs(this.trimHandles[0].position);
    const textRight = formatMs(this.trimHandles[1].position);
    const textCombined = `${textLeft} - ${textRight}`;

    this.trimTexts[0].set({ text: textLeft });
    this.trimTexts[1].set({ text: textRight });

    const spaceNeeded =
      this.trimTexts[0].getBoundingRect().width +
      this.trimTexts[1].getBoundingRect().width +
      this.data.config.trimmer.textSpaceBetween;

    const spacePresent =
      this.trimHandles[1].handle.left + this.data.config.trimmer.handleWidth - this.trimHandles[0].handle.left;

    const hasEnoughSpace = spacePresent > spaceNeeded;

    if (hasEnoughSpace) {
      this.trimTexts[0].set({
        left: this.trimHandles[0].handle.left,
        top: this.height / 2,
      });

      const boundingRectRight = this.trimTexts[1].getBoundingRect();
      this.trimTexts[1].set({
        opacity: 1,
        left: Math.floor(
          this.trimHandles[1].handle.left + this.data.config.trimmer.handleWidth - boundingRectRight.width
        ),
        top: Math.floor(this.height / 2),
      });
    } else {
      this.trimTexts[0].set({
        text: textCombined,
        left: this.trimHandles[0].handle.left,
        top: this.height / 2,
      });

      this.trimTexts[1].set({ opacity: 0 });
    }

    this.trimTexts[0].setCoords();
    this.trimTexts[1].setCoords();
  }

  private emitUpdateEvent(done: boolean) {
    const [from, to] = this.trimHandles;
    this.data.updateTrimBounds(from.position, to.position, done);
  }
}

function formatMs(ms: number) {
  const secondsInMs = Math.floor(ms / 1000);
  const minutes = Math.floor(secondsInMs / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(secondsInMs % 60)
    .toString()
    .padStart(2, '0');
  const milis = Math.floor(ms % 1000)
    .toString()
    .padStart(3, '0');

  return `${minutes}:${seconds}.${milis}`;
}

function getLeftHandleSvgPath(timelineHeight: number) {
  return (
    'M 0 2 ' +
    `l 0 ${timelineHeight - 4} ` +
    'q 0 2 2 2 ' +
    'l 8 0 ' +
    'q 1 0 1 -1 ' +
    'l 0 -1 ' +
    'q 0 -1 -1 -1 ' +
    'l -4 0 ' +
    `l 0 -${timelineHeight - 6} ` +
    'l 4 0 ' +
    'q 1 0 1 -1 ' +
    'l 0 -1 ' +
    'q 0 -1 -1 -1 ' +
    'l -8 0 ' +
    'q -2 0 -2 2'
  );
}

function getRightHandleSvgPath(timelineHeight: number) {
  return (
    'M 11 2 ' +
    `l 0 ${timelineHeight - 4} ` +
    'q 0 2 -2 2 ' +
    'l -8 0 ' +
    'q -1 0 -1 -1 ' +
    'l 0 -1 ' +
    'q 0 -1 1 -1 ' +
    'l 4 0 ' +
    `l 0 -${timelineHeight - 6} ` +
    'l -4 0 ' +
    'q -1 0 -1 -1 ' +
    'l 0 -1 ' +
    'q 0 -1 1 -1 ' +
    'l 8 0 ' +
    'q 2 0 2 2'
  );
}
