import { createAction } from '@ngrx/store';

const actionType = '[Brand Kits]';

export const applyLatestBrandKitVersion = createAction(
  `${actionType} Apply Latest Brand Kit Version`
);
export const ignoreLatestBrandKitVersion = createAction(
  `${actionType} Ignore Latest Brand Kit Version`
);
