import { Observable, Subject } from 'rxjs';
import {
  NextgenParticipant,
  PartialNextgenParticipant,
  UserRoleType,
} from '../../interfaces';
export interface StreamMapper {
  participantConnected$: Observable<NextgenParticipant>;
  participanReconnecting$: Observable<string>;
  connected$: Observable<void>;
  disconnected$: Observable<string>;
  reconnecting$: Observable<void>;
  participantEvent$: Observable<PartialNextgenParticipant>;
  networkQualityChange$: Observable<number>;
  dominantSpeakerEvent$: Observable<string>;
  localTrackPublished$: Observable<{
    type: 'audio' | 'video' | 'data';
    id: string;
  }>;
  initialLocal: NextgenParticipant;
  initialRemote: NextgenParticipant[];
  connected: boolean;

  toggleMuteStatus(status: boolean): void;
  toggleVideoEnabledStatus(status: boolean): void;
  disconnectRoom(): void;
  publishVideoTrack(
    videoSource: MediaStreamTrack,
    destroyPreviousTrack?: boolean
  );
  publishAudioTrack(audioSource: MediaStreamTrack);
  unpublishVideo();
  unpublishAudio();
}
export class ParticipantMapper {
  protected initialParticipant: NextgenParticipant = {
    identity: null,
    loginId: null,
    name: null,
    email: null,
    deviceName: null,
    deviceSupport: null,

    isIosDevice: null,
    isPinned: null,
    isConnected: true,
    isMuted: null,
    isVideoDisabled: null,
    role: UserRoleType.Subject,

    audioInputEnabled: null,
    audioOutputEnabled: null,

    audioTracks: [],
    videoTracks: [],

    videoProperties: {
      colorBalance: undefined,
      colorBalanceHighs: undefined,
      colorBalanceLow: undefined,
      colorBalanceMids: undefined,
      contrast: undefined,
      exposure: undefined,
      autoExposureLock: undefined,
      iso: undefined,
      fps: undefined,
      width: undefined,
      resolution: undefined,
      tint: undefined,
      colorTemperature: undefined,
      guide: false,
    },
    deviceProperties: {
      battery0_100: undefined,
      storageGB: undefined,
      speed: undefined,
      micSettings: undefined,
      externalMic: undefined,
      isFrontCamera: undefined,
      browserAnimationFps: undefined,
      enableManualFocus: true,
    },
    teleprompterProperties: {
      isTeleprompterVisible: false,
      teleprompterId: 0,
    },
    isMe: false,
    location: null,
    isRecording: false,
    isAdjustmentsPanelOpen: false,
    audioStreamId: null,
    videoStreamId: null,
    isLive: false,

    audio: {
      proAudio: false,
      inputMuted: null,
      outputMuted: null,
      speakerOverride: false,
      gain: 1,
      lavaMic: null,
      selectedInput: null,
      availableInputs: [],
      selectedOutput: null,
      availableOutputs: [],
    },
  };
  protected _participantConnected = new Subject<NextgenParticipant>();
  public participantConnected$ = this._participantConnected.asObservable();

  protected _participantReconnecting = new Subject<string>();
  public participanReconnecting$ = this._participantReconnecting.asObservable();

  protected _connected = new Subject<void>();
  public connected$ = this._connected.asObservable();

  protected _disconnected = new Subject<string>();
  public disconnected$ = this._disconnected.asObservable();

  protected _reconnected = new Subject<void>();
  public reconnected$ = this._reconnected.asObservable();

  protected _reconnecting = new Subject<void>();
  public reconnecting$ = this._reconnecting.asObservable();

  protected _participantEvent = new Subject<PartialNextgenParticipant>();
  public participantEvent$ = this._participantEvent.asObservable();

  protected _dominantSpeakerEvent = new Subject<string>();
  public dominantSpeakerEvent$ = this._dominantSpeakerEvent.asObservable();

  protected _networkQualityChange = new Subject<number>();
  public networkQualityChange$ = this._networkQualityChange.asObservable();

  protected _localTrackPublished = new Subject<{
    type: 'audio' | 'video' | 'data';
    id: string;
  }>();
  public localTrackPublished$ = this._localTrackPublished.asObservable();

  protected makeId(identity: string) {
    const splitIdentity = identity.split('_');
    const isSubject = splitIdentity[0] !== 'web';
    return `${isSubject ? 's' : 'd'}_${
      splitIdentity[splitIdentity.length - 1]
    }`;
  }
}
