import { Cleanupable } from '../../classes/cleanupable';

export abstract class LoggingService extends Cleanupable {
  abstract log(...args);

  abstract debug(...args);

  abstract warn(...args);

  abstract trace(...args);

  abstract info(...args);

  abstract fatal(...args);

  abstract error(...args);
}
