import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AccountRecordingHoursResponse {
  status: number;
  message: string;
  data: AccountTotalCountStaticsData;
}

export interface AccountTotalCountStaticsResponse {
  status: number;
  message: string;
  data: AccountTotalCountStaticsData;
}

export interface AccountTotalCountStaticsData {
  totalCount?: number;
  totalCountByLast30Days?: number;
  teamMembersCount?: number;
  directorsCount?: number;
  hoursUsed?: string;
  remainingHours?: string;
  accountRemainingDays?: number;
}

export interface AccountTotalCounts {
  clipsMetric: AccountTotalCountStaticsData;
  sessionsMetric: AccountTotalCountStaticsData;
  hoursMetric: AccountTotalCountStaticsData;
  teamMembersMetric: AccountTotalCountStaticsData;
}

export interface DashboardStatistics {
  recordingHoursMetric: AccountTotalCountStaticsData;
  clipsMetric: AccountTotalCountStaticsData;
  sessionsMetric: AccountTotalCountStaticsData;
  usersMetric: AccountTotalCountStaticsData
}

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  private urls = {
    recordHour: commonenv.nextGenApiUrl + 'company/statistics/total-video-hours',
    clipsCount: commonenv.nextGenApiUrl + 'company/statistics/total-clips',
    sessionsCount: commonenv.nextGenApiUrl + 'company/statistics/total-sessions',
    teamMembersCount: commonenv.nextGenApiUrl + 'company/statistics/total-users',
  };

  constructor(public httpClient: HttpClient) {}

  getAccountRecordingTotalHours(): Observable<AccountRecordingHoursResponse> {
    return this.httpClient.get<AccountRecordingHoursResponse>(this.urls.recordHour);
  }

  getAccountClipsTotalCount(): Observable<AccountTotalCountStaticsResponse> {
    return this.httpClient.get<AccountTotalCountStaticsResponse>(this.urls.clipsCount);
  }

  getAccountSessionsTotalCount(): Observable<AccountTotalCountStaticsResponse> {
    return this.httpClient.get<AccountTotalCountStaticsResponse>(this.urls.sessionsCount);
  }

  getAccountTeamMembersTotalCount(): Observable<AccountTotalCountStaticsResponse> {
    return this.httpClient.get<AccountTotalCountStaticsResponse>(this.urls.teamMembersCount);
  }

  getDashboardStats(): Observable<DashboardStatistics> {

    const accountRecordingTotalHours$ = this.getAccountRecordingTotalHours().pipe(map((res) => res.data ));

    const accountClipsTotalCount$ = this.getAccountClipsTotalCount().pipe(map((res) => res.data ));

    const accountSessionsTotalCount$ = this.getAccountSessionsTotalCount().pipe(map((res) => res.data ));

    const accountTeamMembersTotalCount$ = this.getAccountTeamMembersTotalCount().pipe(map((res) => res.data ));

    return forkJoin([accountRecordingTotalHours$, accountClipsTotalCount$, accountSessionsTotalCount$, accountTeamMembersTotalCount$])
      .pipe(map(
        (res) => ({
          recordingHoursMetric: res[0] as AccountTotalCountStaticsData,
          clipsMetric: res[1] as AccountTotalCountStaticsData,
          sessionsMetric: res[2] as AccountTotalCountStaticsData,
          usersMetric: res[3] as AccountTotalCountStaticsData
        } as DashboardStatistics)));
  }

}
