import { Directive, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CtaEditorService } from './cta-editor.service';
import { HostingPlayerComponent } from '../player/player.component';
import { EMPTY, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Directive({
    selector: 'openreel-hosting-player[orCtaEditor]'
})
export class CtaEditorDirective implements AfterViewInit, OnDestroy {
    private subs: Subscription[] = [];


    @Output()
    extractModeToggle = new EventEmitter<boolean>();

    constructor(
        private ctaEditorService: CtaEditorService,
        private player: HostingPlayerComponent,
    ) {}

    ngAfterViewInit() {
        console.log('alive')
        this.player.player.ready(() => {
            this.subs.push(
                this.ctaEditorService.toggleEditMode$.pipe(
                    distinctUntilChanged(),
                    switchMap(on => {
                        const cta = this.player.player.overlays().toggleEdit(on);
                        return cta ? cta.ctaUpdated$ : EMPTY;
                    }),
                ).subscribe(
                    v => console.log(v)
                )
            );
        })
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }

}