<div class="preview-container"
    [class.loaded]="imgLoaded"
    (mouseleave)="setThumb(thumbUrl)"
    (mouseenter)="setThumb(previewUrl)"
    (dragstart)="setThumb(thumbUrl)"
    draggable="true">
    <img class="mini-player-image dont-drag" 
        (load)="imgLoaded = true"
        [src]="imgSrc$ | async"
        [openreelFallback]="'assets/common/images/placeholder16x9.gif'" alt={{alt}}>
    <mat-icon *ngIf="showPlayButton"
        (click)="onPlayClick($event)"
        inline=true
        class="mini-player-play-video"
        draggable="true">play_circle</mat-icon>
</div>