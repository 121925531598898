import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'openreel-create-hub',
  templateUrl: './create-hub.component.html',
  styleUrls: ['./create-hub.component.scss']
})
export class CreateHubComponent implements OnInit {
  isLightThemed = true;
  isPopup = true;

  hubForm: FormGroup;
  
  validation_messages = {
    'name': [
      { type: 'required', message: 'Name is required' },
      { type: 'maxlength', message: 'Hub Name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Hub name must contain numbers and letters' }
    ],
    'slug': [
      { type: 'required', message: 'Slug is required' },
      { type: 'maxlength', message: 'Slug cannot be more than 100 characters long' },
    ]
  }

  constructor(public dialogRef: MatDialogRef<CreateHubComponent>,) { 
    this.hubForm = new FormGroup({
      name: new FormControl('', Validators.compose([
        Validators.required, 
        Validators.maxLength(20),
        Validators.pattern(/[\S]/),
      ])),
      slug: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      permission: new FormControl('private', Validators.required),
    });
  }

  ngOnInit() {
    this.hubForm.get('name').valueChanges.subscribe(
      (title) => {
          if (this.hubForm.get('slug').pristine) {
              this.hubForm.get('slug').setValue(title.trim());
          }
      }
    )
  }

  //
  // Validates input and closing dialog
  //
  createHub(){
    if (!this.hubForm.valid) return;

    const data = {
      hub: this.hubForm.value.name.trim(),
      permission: this.hubForm.value.permission.trim(),
      slug: this.hubForm.value.slug,
    };    
    this.dialogRef.close(data);
  }
}
