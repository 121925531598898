import { CachedAsset } from '../interfaces/asset-cache.interface';
import { createAction, props } from '@ngrx/store';
import { AssetId, AssetsFileProviderType } from '@openreel/creator/common';

const actionType = '[Asset Cache]';

export const loadAsset = createAction(
  `${actionType} Load Asset`,
  props<{
    id: AssetId;
    provider: 'url' | 'or-assets' | 'or-recordings';
  }>()
);

export const fetchAssetUrl = createAction(
  `${actionType} Fetch Asset URL`,
  props<{
    id: AssetId;
    provider: 'url' | 'or-assets' | 'or-recordings';
  }>()
);

export const cacheAsset = createAction(`${actionType} Cache Asset`, props<CachedAsset>());

export const removeCachedAsset = createAction(
  `${actionType} Remove Cached Asset`,
  props<{
    id: AssetId;
    provider: 'url' | 'or-assets' | 'or-recordings';
  }>()
);

// Video thumbnails spritesheets
export const loadSpritesheet = createAction(
  `${actionType} Load spritesheet`,
  props<{ videoId: number; videoType: AssetsFileProviderType }>()
);

export const getSpritesheetS3Url = createAction(
  `${actionType} Get spritesheet S3 URL`,
  props<{ videoId: number; videoType: AssetsFileProviderType }>()
);

export const getSpritesheet = createAction(
  `${actionType} Get spritesheet`,
  props<{ videoId: number; signedUrl: string }>()
);

export const saveSpritesheet = createAction(
  `${actionType} Save spritesheet`,
  props<{ videoId: number; spritesheet: HTMLImageElement }>()
);
