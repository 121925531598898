import { WorkflowDataDto } from '@openreel/creator/common';
import { RenderProgress, TranscribeProgress, WorkflowProjectDto } from '@openreel/common';

export enum ProjectUpdateSource {
  Previewer,
}

export interface Project {
  loaded: boolean;
  loading: boolean;
  building: boolean;
  loadFinished: boolean;
  data: WorkflowProjectDto;
  lastSavedData: WorkflowProjectDto;
  renderProgress: RenderProgress;
  transcribeProgress: TranscribeProgress;
  updateSource?: ProjectUpdateSource;
}

export interface WorkflowWithUpdateSource {
  workflow: WorkflowDataDto;
  updateSource: ProjectUpdateSource;
}

export interface BrandKitPermissionsCurrentUser {
  logo: boolean;
  watermark: boolean;
  background: boolean;
  colors: boolean;
  font: boolean;
}
