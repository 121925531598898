import {
  Bounds,
  ChildLayer,
  Layer,
  LayerOptions,
  OrAssetsFile,
  Section,
  SectionId,
  SectionLayer,
  Sections,
  SectionType,
  Timeline,
  VideoLayer,
  VideoLayerInfo,
  WorkflowDataDto,
} from '../interfaces';
import { getTimelineDuration } from './workflow.helpers';

// Coppied from @openreel/common -> move to common library for frontend/backend
const padZerosStart = (num: number, maxLength) => num.toString().padStart(maxLength, '0');

export const formatMillisecondsAsTime = (ms: number): string => {
  const h = Math.floor(ms / 3_600_000);
  const m = Math.floor((ms - h * 3_600_000) / 60_000);
  const s = Math.floor((ms - h * 3_600_000 - m * 60_000) / 1_000);
  ms = Math.trunc(ms - h * 3_600_000 - m * 60_000 - s * 1_000);

  return `${h ? `${h}:` : ''}${padZerosStart(m, h ? 2 : 0)}:${padZerosStart(s, 2)}.${padZerosStart(ms, 3)}`;
};

export function getSectionDuration(section: Section) {
  const mainTimeline = section?.timelines.find((t) => t.type === 'main');
  if (!mainTimeline || mainTimeline.layers.length === 0) {
    return 0;
  }

  let duration = 0;
  if (mainTimeline.layers[0].type === 'timelines') {
    mainTimeline.layers[0].children.forEach(
      (childTimeline) => (duration = Math.max(duration, getTimelineDuration(childTimeline)))
    );
  } else {
    duration = getTimelineDuration(mainTimeline);
  }

  return duration;
}

export function getSectionsOfType(sections: Sections, sectionType: SectionType) {
  return Object.keys(sections)
    .map((sectionId) => ({ sectionId, section: sections[sectionId] }))
    .filter(({ section }) => section.sectionType === sectionType);
}

export function getIntroOutroDurations(workflow: WorkflowDataDto): [number, number] {
  const mainTimeline = workflow.timelines.find(t => t.type === 'main');

  let introDuration = 0;
  const introLayer = mainTimeline.layers.find(layer => layer.type === 'section' && layer.sectionId === SectionId.Intro);
  if (introLayer?.enabled) {
    const introSections = getSectionsOfType(workflow.sections, 'intro');
    introDuration = introSections.reduce((duration, {section}) => duration + getSectionDuration(section), 0);
  }

  let outroDuration = 0;
  const outroLayer = mainTimeline.layers.find(layer => layer.type === 'section' && layer.sectionId === SectionId.Outro);
  if (outroLayer?.enabled) {
    const outroSections = getSectionsOfType(workflow.sections, 'outro');
    outroDuration = outroSections.reduce((duration, {section}) => duration + getSectionDuration(section), 0);
  }

  return [introDuration, outroDuration];
}

export function getMainDuration(workflow: WorkflowDataDto) {
  let mainDuration = 0;
  const mainSections = getSectionsOfType(workflow.sections, 'main');
  mainSections.forEach(({ section }) => {
    mainDuration += getSectionDuration(section);
  });

  return mainDuration;
}

export function getWorkflowDuration(workflow: WorkflowDataDto) {
  const mainTimeline = workflow?.timelines.find((t) => t.type === 'main');
  let duration = 0;
  mainTimeline.layers
    .filter((l) => l.enabled)
    .forEach(
      (sectionLayer: SectionLayer) => (duration += getSectionDuration(workflow.sections[sectionLayer.sectionId]))
    );

  return duration;
}

export const getTimelineById = (id: string, sections: Sections) => {
  if (!id) {
    return null;
  }

  const mainSections = getSectionsOfType(sections, 'main');

  for (const { section, sectionId } of mainSections) {
    const timeline = section.timelines.find((t) => t.id === id);
    if (timeline) {
      return { timeline, sectionId };
    }

    for (const sectionTimeline of section.timelines) {
      if (sectionTimeline.layers.length === 0 || sectionTimeline.layers[0].type !== 'timelines') {
        continue;
      }

      for (const childTimeline of sectionTimeline.layers[0].children) {
        if (childTimeline.id === id) {
          return { timeline: childTimeline, sectionId };
        }
      }
    }
  }

  return null;
};

export function* getLayers(
  workflow: WorkflowDataDto,
  types?: string[]
): Generator<{ layer: Layer; timeline?: Timeline }> {
  const doesTypeMatch = (layer: Layer) => !types || types.includes(layer.type);

  function* getLayersFromTimeline(timeline: Timeline, sectionId: string) {
    for (const layer of timeline.layers) {
      if (layer.type === 'timelines') {
        for (const childTimeline of layer.children) {
          yield* getLayersFromTimeline(childTimeline, sectionId);
        }
      } else {
        if (doesTypeMatch(layer)) {
          yield { layer, timeline };
        }
      }
    }
  }

  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  for (const layer of mainTimeline.layers) {
    yield { layer, timeline: mainTimeline };
  }

  const sections: [string, Section][] = Object.entries(workflow.sections);
  for (const [sectionId, section] of sections) {
    for (const timeline of section.timelines) {
      yield* getLayersFromTimeline(timeline, sectionId);
    }
  }

  const transitionLayers = workflow.timelines[0].layers
    .filter((l) => l.type === 'section' && l.transitions?.crossLayer)
    .map((l) => l.transitions.crossLayer.layer);

  for (const layer of transitionLayers) {
    if (doesTypeMatch(layer)) {
      yield { layer };
    }
  }
}

export const getLayerFromId = (
  layerId: string,
  workflow: WorkflowDataDto
): {
  sectionId: string;
  layer: Layer;
  childLayer?: ChildLayer;
  timeline?: Timeline;
  timelineId?: string;
} => {
  function checkTimeline(timeline: Timeline, sectionId: string) {
    for (const layer of timeline.layers) {
      if (layer.layerId === layerId) {
        return { sectionId, layer, timeline, timelineId: timeline.id };
      }
      if (layer.type === 'timelines') {
        for (const childTimeline of layer.children) {
          const result = checkTimeline(childTimeline, sectionId);
          if (result) {
            return result;
          }
        }
      }
    }

    return null;
  }

  const sections: [string, Section][] = Object.entries(workflow.sections);
  for (const [sectionId, section] of sections) {
    for (const timeline of section.timelines) {
      const result = checkTimeline(timeline, sectionId);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const getTimelineLayers = (timelineId: string, workflow: WorkflowDataDto): VideoLayerInfo[] => {
  if (!timelineId) {
    return [];
  }

  const timelineInfo = getTimelineById(timelineId, workflow.sections);
  if (!timelineInfo) {
    return [];
  }

  const { timeline } = timelineInfo;
  const layers = timeline.layers.map((layer: LayerOptions & VideoLayer) => {
    const asset = workflow.assets.find((a) => a.id === layer.assetId);
    const duration = layer.visibility.endAt - layer.visibility.startAt;
    return {
      layerId: layer.layerId,
      asset,
      assetFile: asset.file as OrAssetsFile,
      startAt: layer.visibility.startAt,
      duration,
      tooltip: `Starts at: ${formatMillisecondsAsTime(layer.visibility.startAt)}`,
      bounds: layer.bounds,
    };
  });

  return layers;
};

export const isSectionEmpty = (workflow: WorkflowDataDto, sectionId: string) => {
  const section = workflow.sections[sectionId];
  const mainTimeline = section.timelines.find((t) => t.type === 'main');
  if (mainTimeline.layers.length === 0) {
    return true;
  }

  const firstLayer = mainTimeline.layers[0];
  if (firstLayer.type !== 'timelines') {
    return false;
  }

  return firstLayer.children.reduce((prev, curr) => (prev && curr.layers.length > 0 ? false : prev), true);
};

export const isBoundsFullScreen = (bounds: Bounds) =>
  !bounds || (bounds.x === 0 && bounds.y === 0 && bounds.width === 100 && bounds.height === 100);
