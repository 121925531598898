<h2 mat-dialog-title>New Video Hub</h2>

<mat-dialog-content>
    <form #formRef="ngForm" [formGroup]="hubForm" (submit)="createHub()">

        <!-- N A M E -->
        <div class="form-field-title">Name<sup>*</sup></div>
        <mat-form-field class="form-field-input"  appearance="outline">
            <input matInput formControlName="name" required placeholder="Enter here">

            <mat-error *ngFor="let validation of validation_messages.name">
                <mat-error class="error-message" *ngIf="hubForm.get('name').hasError(validation.type) && (hubForm.get('name').dirty || hubForm.get('name').touched)">{{validation.message}}</mat-error>
            </mat-error>

        </mat-form-field>

        <div class="form-field-title">URL Slug<sup>*</sup> (Cannot be changed after creation) <or-slug-info-icon></or-slug-info-icon></div>
        <mat-form-field class="form-field-input"  appearance="outline">
            <input matInput formControlName="slug" required placeholder="URL Slug" slugField>

            <mat-error *ngFor="let validation of validation_messages.slug">
                <mat-error class="error-message" *ngIf="hubForm.get('slug').hasError(validation.type) && (hubForm.get('slug').dirty || hubForm.get('slug').touched)">{{validation.message}}</mat-error>
            </mat-error>
        </mat-form-field>

        <!-- P E R M I S S I O N S -->
        <div class="form-field-title">Permissions<sup>*</sup></div>
        <mat-form-field class="form-field-input"  appearance="outline">
            <mat-select formControlName="permission" class="form-field-input" appearance="outline">
                <mat-option value="private">Private</mat-option>
                <mat-option value="public">Public</mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="hubForm?.controls.permission?.errors?.required">Required Field</span>
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="formRef.onSubmit(); createHub()">Create</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
