import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GdprMailRequest, GdprPurgeRequest, SessionOtpLoginRequest, SessionResponse } from '../../../interfaces';
import { commonenv } from '@openreel/common/env/environment';

const URL_OTP_LOGIN = `${commonenv.nextGenApiUrl}session-otp-login`;
const DOWNLOAD_GDPR = `${commonenv.nextGenApiUrl}gdpr/mail`;
const PURGE_GDPR = `${commonenv.nextGenApiUrl}gdpr/purge`;
const DELETE_EMAIL_TOKEN = `${commonenv.nextGenApiUrl}invitations/delete-email-token`;
const LOGOUT_SUBJECT = `${commonenv.nextGenApiUrl}logout`;

@Injectable({
  providedIn: 'root',
})
export class SubjectApiService {
  constructor(private httpClient: HttpClient) {}

  joinOtp(request: SessionOtpLoginRequest) {
    return this.httpClient.post<SessionResponse>(URL_OTP_LOGIN, request);
  }
  downloadGDPR(request: GdprMailRequest) {
    return this.httpClient.get(`${DOWNLOAD_GDPR}?email=${request.email}&identity=${request.identity}`);
  }
  purgeGDPR(request: GdprPurgeRequest) {
    let uri = `${PURGE_GDPR}/${request.identity}`;
    if (request.session_id) {
      uri = uri + `?session_id=${request.session_id}`;
    }
    return this.httpClient.delete(uri);
  }
  deleteEmailToken(emailToken) {
    return this.httpClient.post(DELETE_EMAIL_TOKEN, {
      emailToken,
    });
  }

  logoutSubject() {
    return this.httpClient.get<{ data: { invited_token: string; name: string; otp: string } }>(LOGOUT_SUBJECT);
  }
}
