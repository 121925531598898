import { Recording } from ".";

export enum UgcVideoQuality {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface UgcVideoQualityOption {
  label: string;
  value: UgcVideoQuality;
}

export const ugcVideoQualityOptions: UgcVideoQualityOption[] = [
  {
    label: 'Low',
    value: UgcVideoQuality.Low,
  },
  {
    label: 'Medium',
    value: UgcVideoQuality.Medium,
  },
  {
    label: 'High',
    value: UgcVideoQuality.High,
  },
];

export enum UgcEmailNotification {
  Instant = 'instant',
  Hourly = 'hourly',
  FourHourInterval = '4_hour',
  EightHourInterval = '8_hour',
  Weekly = 'weekly',
}

export interface UgcEmailNotificationOption {
  label: string;
  value: UgcEmailNotification;
}

export const ugcEmailNotificationOptions: UgcEmailNotificationOption[] = [
  {
    label: 'Instant',
    value: UgcEmailNotification.Instant,
  },
  {
    label: 'Hourly',
    value: UgcEmailNotification.Hourly,
  },
  {
    label: 'Every 4 Hours',
    value: UgcEmailNotification.FourHourInterval,
  },
  {
    label: 'Every 8 Hours',
    value: UgcEmailNotification.EightHourInterval,
  },
  {
    label: 'Weekly',
    value: UgcEmailNotification.Weekly,
  },
];

export enum UgcSupportType {
  Question = 'question',
  Teleprompter = 'teleprompter',
  VideoInstruction = 'video_instruction',
}

export interface UgcSupportTypeOption {
  label: string;
  value: UgcSupportType;
}

export const UgcSupportTypeOptions: UgcSupportTypeOption[] = [
  {
    label: 'Teleprompter',
    value: UgcSupportType.Teleprompter,
  },
  {
    label: 'Questions',
    value: UgcSupportType.Question,
  },
  {
    label: 'Video Instruction',
    value: UgcSupportType.VideoInstruction,
  },
];
export interface UgcSupportQuestion {
  question: string;
  suggestion: string;
  maxDuration: number;
  durationUnit: DurationUnit;
  createdAt?: string;
  updatedAt?: string;
  id?: number;
  sessionId?: number;
}

export interface UgcQuestionDurationOption {
  label: string;
  value: string;
}

export enum DurationUnit {
  Secs = 'secs',
  Mins = 'mins',
  Hours = 'hours',
}

export const ugcQuestionDurationOptions: UgcQuestionDurationOption[] = [
  {
    label: 'Sec',
    value: DurationUnit.Secs,
  },
  {
    label: 'Mins',
    value: DurationUnit.Mins,
  },
  {
    label: 'Hours',
    value: DurationUnit.Hours,
  },
  {
    label: 'No Time Limit',
    value: '',
  },
];

export interface InvitedSubject {
  email: string;
  invalid: boolean;
  createdAt?: string;
  updatedAt?: string;
  id?: number;
  sessionId?: number;
}

export interface CreateUpdateUgcSessionPayload {
  projectId: number;
  sessionName: string;
  description?: string;
  startAt?: string;
  expireAt: string;
  invitedSubjects?: InvitedSubject[];
  frameRate: UgcVideoQuality;
  resolution: UgcVideoQuality;
  pageCountDownEnabled: boolean;
  emailNotificationInterval: UgcEmailNotification;
  supportType?: UgcSupportType;
  questions?: UgcSupportQuestion[];
  teleprompterId?: number;
  instructionVideoId?: number;
}

export interface UgcSessionLink {
  startAt: string;
  expireAt: string;
  link: string;
}

export interface CreateUpdateUgcSessionData extends CreateUpdateUgcSessionPayload {
  links: UgcSessionLink[];
  createdAt: string;
  updatedAt: string;
  sessionId: number;
}

export interface UgcSessionResponse {
  data: CreateUpdateUgcSessionData;
  message: string;
}

export interface UgcRecordingGroup {
  name: string;
  recordings: Recording[];
}
