import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ImagePlayerData,
  LottiePlayerData,
  PlayerData,
  TimelinesPlayerData,
  VideoPlayerData,
} from '../../interfaces/player-data.interfaces';

import { CuePlayerBaseComponent, PlayerDimensions } from '../../interfaces/cue-player-base.interface';
import { OnTimeUpdateEvent } from '../../interfaces/player-event.interfaces';
import { LayerStyles } from '@openreel/creator/common';

@Component({
  selector: 'openreel-cue-player-item',
  templateUrl: './cue-player-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerItemComponent extends CuePlayerBaseComponent implements OnInit, OnDestroy {
  @Input() playerData: PlayerData;
  @Input() styles: LayerStyles;
  @ViewChild(CuePlayerBaseComponent) player: CuePlayerBaseComponent;

  get isVideoPlayer(): boolean {
    return this.playerData instanceof VideoPlayerData;
  }

  get isLottiePlayer(): boolean {
    return this.playerData instanceof LottiePlayerData;
  }

  get isTimelinesPlayer(): boolean {
    return this.playerData instanceof TimelinesPlayerData;
  }

  get isImagePlayer(): boolean {
    return this.playerData instanceof ImagePlayerData;
  }

  get duration(): number {
    return this.player.duration;
  }

  get dimensions(): PlayerDimensions {
    return this.player.dimensions;
  }

  onChildPlayerTimeUpdate({ currentTime }: OnTimeUpdateEvent) {
    this.emitTimeUpdateEvent(currentTime);
  }

  currentTime(time?: number): number {
    return this.player?.currentTime(time);
  }

  play(): Promise<void> {
    return this.player.play();
  }

  pause() {
    return this.player.pause();
  }

  stop() {
    return this.player.stop();
  }

  isPlaying(): boolean {
    return this.player?.isPlaying();
  }
}
