<div fxLayout="row" fxFill>
  <div fxLayout="row" fxFill class="login-wrapper" fxLayoutAlign="left stretch">
    <div fxFlex.gt-md="34" fxFill>
      <div fxLayout="column" fxFill fxLayoutAlign="space-around stretch">
        <img
          fxFlex="50"
          class="logo"
          alt="blue-gradient.svg"
          style="position: absolute; top: 10px; left: 10px"
          src="assets/common/wordmarks/blue-gradient.svg"
        />
        <div fxFlex="50" fxLayoutAlign="center stretch">
          <ng-content></ng-content>
        </div>
      </div>
    </div>

    <div fxFlex="66" class="background-guest-image" fxHide.lt-md fxLayout="column" fxLayoutAlign="center center"></div>
  </div>
</div>
