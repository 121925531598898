import * as fromActions from '../actions/project.actions';
import * as fromSelectors from '../selectors/project.selectors';
import * as fromTemplateSelectors from '../selectors/template.selectors';

import { Actions, ofType } from '@ngrx/effects';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkflowDataDto } from '@openreel/creator/common';
import { map } from 'rxjs/operators';
import { BrandKitPermissionsCurrentUser } from '../interfaces/project.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectFacade {
  projectUpdated$ = this.actions$.pipe(ofType(fromActions.updateProjectSuccess));
  projectUpdateFailed$ = this.actions$.pipe(ofType(fromActions.updateProjectFailure));
  projectFailedToLoad$ = this.actions$.pipe(ofType(fromActions.loadProjectFailure));

  id$ = this.store.select(fromSelectors.selectProjectId);
  projectLayoutType$ = this.store.select(fromSelectors.selectProjectLayoutType);

  projectName$ = this.store.select(fromSelectors.selectProject).pipe(map((p) => p?.data?.name));

  captureProjectId$ = this.store.select(fromSelectors.selectProject).pipe(map((p) => p?.data?.captureProjectId));

  data$ = this.store.select(fromSelectors.selectProject);
  workflow$ = this.store.select(fromSelectors.selectProjectWorkflow);
  workflowWithUpdateSource$ = this.store.select(fromSelectors.selectProjectWorkflowWithUpdateSource);

  features$ = this.store.select(fromSelectors.selectProjectFeatures);

  templateSettings$ = this.store.select(fromSelectors.selectProjectTemplateSettings);

  isBuilding$ = this.store.select(fromSelectors.selectIsBuilding);

  layouts$ = this.store.select(fromSelectors.selectProjectLayouts);

  templateLayoutId$ = this.store.select(fromSelectors.selectProjectTemplateLayoutId);

  mainTimeline$ = this.store.select(fromSelectors.selectProjectMainTimeline);

  renderProgress$ = this.store.select(fromSelectors.selectRenderProgress);
  isRenderInProgress$ = this.store.select(fromSelectors.selectIsRenderInProgress);

  isWorkflowLoaded$ = this.store.select(fromSelectors.selectProjectLoadFinished);

  brandKitHasNewerVersion$ = this.store.select(fromSelectors.selectProjectBrandKitHasNewerVersion);

  brandKitPermissions$ = this.store.select(fromSelectors.selectProjectBrandKitPermissions);
  brandKitPermissionsForCurrentUser$ = this.brandKitPermissions$.pipe(
    map(
      (permissions): BrandKitPermissionsCurrentUser => ({
        logo: permissions ? permissions.logo.user.canEdit : true,
        watermark: permissions ? permissions.watermark.user.canEdit : true,
        background: permissions ? permissions.background.user.canEdit : true,
        colors: permissions ? permissions.colors.user.canEdit : true,
        font: permissions ? permissions.font.user.canEdit : true,
      })
    )
  );

  // Template
  templateWorkflow$ = this.store.select(fromTemplateSelectors.selectTemplateWorkflow);

  video$ = this.store.select(fromSelectors.selectProject).pipe(map((p) => p?.data?.video));

  constructor(private store: Store, private actions$: Actions) {}

  cleanup = () => this.store.dispatch(fromActions.cleanupState());

  loadProject(projectId: number) {
    this.store.dispatch(fromActions.loadProjectAPI({ projectId }));
  }

  updateProjectName(name: string) {
    this.store.dispatch(fromActions.updateProjectNameAPI({ name }));
  }

  updateProjectLayout(templateLayoutId: number) {
    this.store.dispatch(fromActions.updateProjectLayout({ templateLayoutId }));
  }

  updateProjectWorkflow(data: WorkflowDataDto) {
    this.store.dispatch(fromActions.updateProjectWorkflowAPI({ data }));
  }

  renderProject() {
    this.store.dispatch(fromActions.renderProject());
  }

  triggerRenderProject() {
    this.store.dispatch(fromActions.triggerRenderProject());
  }

  cancelProjectRender() {
    this.store.dispatch(fromActions.cancelProjectRender());
  }
}
