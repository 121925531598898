import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { 
  AuthService,
  WORKFLOWS_TEMPLATE,
  USER_UPLOAD,
  HOSTING,
  FeatureFlaggingService,
} from '..';

@Injectable({ providedIn: 'root' })
export class FeatureEnabledService {
  private onFlagOrUser$ = combineLatest([
    this.auth.accessDetail$,
    this.featureFlagService.flagChange$,
  ]).pipe(
    map(([data]) => data),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private auth: AuthService,
    private featureFlagService: FeatureFlaggingService
  ) {}

  private readonly ACCESS_RULES: { [key: string]: Observable<boolean> } = {
    [HOSTING]: this.onFlagOrUser$.pipe(
      map((data) => {
        const flagAllowed = this.featureFlagService.getFlag(HOSTING);
        return flagAllowed && data && data.hostingAllowed;
      })
    ),
    [WORKFLOWS_TEMPLATE]: this.onFlagOrUser$.pipe(
      map((data) => {
        const flagAllowed = this.featureFlagService.getFlag(WORKFLOWS_TEMPLATE);
        return flagAllowed && data && data.creatorAllowed;
      })
    ),
    [USER_UPLOAD]: this.onFlagOrUser$.pipe(
      map(() => {
        const flagAllowed = this.featureFlagService.getFlag(
          USER_UPLOAD
        );
        return flagAllowed;
      })
    ),
  };

  featureEnabled(feature: string): Observable<boolean> {
    const accessRule = this.ACCESS_RULES[feature];
    if (!accessRule) {
      throw Error('No access rule defined for feature');
    }
    return accessRule;
  }
}
