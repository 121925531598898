import { StoreSubject } from './store-subject';


import { map, distinctUntilChanged } from 'rxjs/operators';


export type ArrayData<T> = Array<T>;

type ArrayInput<T> = Array<T>;
type ItemInput<T> = T;

type ArrayPredicate<T> = (item: T) => boolean;

export class ArraySubject<T> extends StoreSubject<ArrayData<T>> {

    private arrayInsertAt(state: ArrayData<T>, data: ArrayData<T>, index?: number) {
        index = index !== undefined ? index : state.length;
        const head = state.slice(0, index);
        const tail = state.slice(index);
        return [...head, ...data, ...tail];
    }

    private arrayRemoveAt(state: ArrayData<T>, index?: number) {
        index = index !== undefined ? index : state.length - 1;
        const head = state.slice(0, index);
        const tail = state.slice(index + 1);
        return [...head, ...tail];
    }

    private insertModifier = (state: ArrayData<T>, payload: {index?: number, data: ArrayInput<T>}) => 
        this.arrayInsertAt(state, payload.data, payload.index)

    private removeWhereModifier = (state: ArrayData<T>, predicate: ArrayPredicate<T>) => {
        const idx = state.findIndex(predicate);
        if (idx > -1) {
            return this.arrayRemoveAt(state, idx)
        }
        return state;
    }

    private removeModifier = (state: ArrayData<T>, index?: number) => 
        this.arrayRemoveAt(state, index)

    constructor(initialState: ArrayData<T> = []) {
        super(initialState);
    }

    insertAtIndex(data: ArrayInput<T>, index?: number) {
        this.modify({index, data}, this.insertModifier);
    }

    concat(items: ArrayInput<T>) {
        this.insertAtIndex(items);
    }

    push(...items: ItemInput<T>[]) {
        this.concat(items);
    }

    unshift(item: ItemInput<T>) {
        this.insertAtIndex([item], 0);
    }

    removeAtIndex(index?: number) {
        this.modify(index, this.removeModifier);
    }

    removeWhere(predicate: ArrayPredicate<T>) {
        this.modify(predicate, this.removeWhereModifier)
    }

    remove(item: ItemInput<T>) {
        this.removeWhere((i: T) => i === item)
    }

    shift() {
        this.removeAtIndex(0);
    }

    pop() {
        this.removeAtIndex();
    }

    reset(state: ArrayData<T> = []) {
        super.reset(state);
    }

    get length$() {
        return this.pipe(
            map(arr => arr.length),
            distinctUntilChanged()
        );
    }

    getIndex$(index?: number) {
        return this.pipe(
            map(arr => arr[index || arr.length - 1]),
            distinctUntilChanged()
        );
    }

    get first$() {
        return this.getIndex$(0);
    }

    get last$() {
        return this.getIndex$();
    }

    find$(predicate: ArrayPredicate<T>) {
        return this.pipe(
            map(array => array.find(predicate)),
            distinctUntilChanged()
        );
    }

    findIndex$(predicate: ArrayPredicate<T>) {
        return this.pipe(
            map(array => array.findIndex(predicate)),
            distinctUntilChanged()
        );
    }

    filter$(predicate: ArrayPredicate<T>) {
        return this.pipe(
            map(array => array.filter(predicate))
        );
    }

    sort$(compareFn?: (a: T, b:T) => number) {
        return this.pipe(
            map(array => array.sort(compareFn))
        );
    }

    includes$(item: ItemInput<T>) {
        return this.pipe(
            map(array => array.includes(item)),
            distinctUntilChanged()
        )
    }

    indexOf$(item: ItemInput<T>) {
        return this.pipe(
            map(array => array.indexOf(item)),
            distinctUntilChanged()
        )
    }

    lastIndexOf$(item: ItemInput<T>) {
        return this.pipe(
            map(array => array.lastIndexOf(item)),
            distinctUntilChanged()
        )
    }

    some$(predicate: ArrayPredicate<T>) {
        return this.pipe(
            map(array => array.some(predicate)),
            distinctUntilChanged()
        );
    }

    every$(predicate: ArrayPredicate<T>) {
        return this.pipe(
            map(array => array.every(predicate)),
            distinctUntilChanged()
        );
    }
}