import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../services';
import { Injectable } from '@angular/core';
import { ROUTE_DIRECTOR_LOGIN } from '../route-utils';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.authService.isInternalUser$,
      this.authService.cookieDeleted$,
      this.authService.selectedAccountId$,
    ]).pipe(
      map(
        ([isInternalUser, cookieDeleted, selectedAccountId]) =>
          isInternalUser && !cookieDeleted && !!selectedAccountId
      ),
      tap((result) => {
        if (!result) {
          this.router.navigate([ROUTE_DIRECTOR_LOGIN], {
            queryParams: {
              redirect: state.url,
            },
          });
        }
      })
    );
  }
}
