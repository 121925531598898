import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PlayerAnalyticEvent, CueEventsService, PlayerExternalEvent } from '../services/cue-events.service';
import { OnErrorEvent, OnTimeUpdateEvent } from '../interfaces/player-event.interfaces';
import { distinctUntilChanged, first, take, takeUntil } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';
import { CueControlsComponent } from '../cue-controls/cue-controls.component';
import { CuePlayerBaseComponent } from '../interfaces/cue-player-base.interface';
import { CuePlayerTimelinesComponent } from '../cue-player-timelines/cue-player-timelines.component';
import { TimelinesPlayerData } from '../interfaces/player-data.interfaces';
import { PlayerCaptionsData } from '@openreel/ui/openreel-cue-player/cue-player-captions/cue-player-captions.interfaces';
import { DOCUMENT } from '@angular/common';
import { ControlsOptions } from '@openreel/ui/openreel-video-controls/openreel-video-controls.component';
import { CueSelectionService } from '../services/cue-selection.service';

@Component({
  selector: 'openreel-cue-player',
  templateUrl: './cue-player.component.html',
  styleUrls: ['./cue-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CueSelectionService],
})
export class CuePlayerComponent extends CuePlayerBaseComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(CuePlayerTimelinesComponent)
  private readonly timelinesPlayer?: CuePlayerTimelinesComponent;
  @ViewChild(CueControlsComponent)
  private readonly controls: CueControlsComponent;
  private readonly isLoading = new BehaviorSubject<boolean>(true);
  private readonly totalDuration = new BehaviorSubject<number>(0);
  private readonly totalTime = new BehaviorSubject<number>(0);

  private playAfterSeek = false;

  @Input() data: TimelinesPlayerData;
  @Input() playerBgColor: string;
  @Input() captions: PlayerCaptionsData = null;
  @Input() hasControls = true;
  @Input() isStandAlone = false;
  @Input() controlOptions: ControlsOptions;

  @Output() onPlaying = new EventEmitter<boolean>();

  @Output() events = new EventEmitter<PlayerExternalEvent>();
  @Output() analyticEvents = new EventEmitter<PlayerAnalyticEvent>();
  @Output() switchMainVideos = new EventEmitter();

  @HostBinding('class.fullscreen') fullscreen = false;

  captionsCurrentTime = 0;

  totalDuration$ = this.totalDuration.asObservable();
  totalTime$ = this.totalTime.asObservable().pipe(distinctUntilChanged());
  isLoading$ = this.isLoading.asObservable().pipe(distinctUntilChanged());
  shouldReset = false;

  layerSelected$ = this.cueSelectionService.selected$;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private readonly cueSelectionService: CueSelectionService,
    private readonly cueEventsService: CueEventsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit() {
    this.cueEventsService.fullscreen$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((fullscreen) => (this.fullscreen = fullscreen));

    this.cueEventsService.playerEvents$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event) => this.events.emit(event));

    this.cueEventsService.analyticsEvents$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event) => this.analyticEvents.emit(event));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.timelinesPlayer?.pause();
      this.isLoading.next(true);
      this.cleanupLottieFontSpans();
    }
  }

  ngOnDestroy() {
    this.cleanupLottieFontSpans();
  }

  get resetOnPlay() {
    return this.timelinesPlayer?.resetOnPlay ?? false;
  }

  onTimelineLoaded() {
    this.isLoading.next(false);
    this.totalDuration.next(this.timelinesPlayer.duration);
    this.emitLoadedEvent();

    if (!this.isStandAlone) {
      this.cueEventsService.previousPlayerState$.pipe(first()).subscribe((state) => {
        this.shouldReset = state.shouldReset;
        this.currentTime(state.time);
        if (state.wasPlaying) {
          this.play();
        }
      });
    }
  }

  onTimelineTimeUpdate(event: OnTimeUpdateEvent) {
    if (this.captions) {
      this.captionsCurrentTime = event.currentTime;
    }
    this.totalTime.next(event.currentTime);
    this.emitTimeUpdateEvent(event.currentTime);
  }

  onTimelineEnded() {
    this.pause();
    this.emitEndedEvent();
  }

  onTimelineSeeking() {
    this.emitSeekedEvent();
  }

  onTimelineSeeked() {
    if (this.playAfterSeek) {
      this.playAfterSeek = false;
      this.play();
    }

    this.emitSeekedEvent();
  }

  onTimelineError({ error }: OnErrorEvent) {
    this.emitErrorEvent(error);
  }

  async onControlsSeek(time: number) {
    this.seek(time);
  }

  onControlsPlay() {
    this.play(this.shouldReset);
  }

  onControlsPause() {
    this.pause();
  }

  onControlsFullScreen() {
    try {
      this.elementRef.nativeElement.requestFullscreen();
    } catch {}
  }

  async play(shouldReset = false) {
    const isLoading = await this.isLoading$.pipe(take(1)).toPromise();
    if (isLoading) {
      console.warn('Cannot play while not loaded');
      return;
    }

    if (shouldReset) {
      this.timelinesPlayer.stop();
    }

    await this.timelinesPlayer.play();

    this.cueEventsService.setAnalyticsEvent('preview_play', 'Preview play video');

    this.controls.playing = true;
    this.onPlaying.emit(this.timelinesPlayer.isPlaying());
  }

  pause() {
    this.isLoading$.pipe(take(1)).subscribe((isLoading) => {
      if (isLoading) {
        console.warn('Cannot pause while not loaded');
        return;
      }

      this.timelinesPlayer.pause();

      this.cueEventsService.setAnalyticsEvent('preview_pause', 'Preview pause video');

      this.onPlaying.emit(this.timelinesPlayer.isPlaying());
    });
  }

  stop() {
    this.isLoading$.pipe(take(1)).subscribe((isLoading) => {
      if (isLoading) {
        console.warn('Cannot stop while not loaded');
        return;
      }

      this.timelinesPlayer.stop();
      this.onPlaying.emit(this.timelinesPlayer.isPlaying());
    });
  }

  currentTime(time?: number): number {
    return this.timelinesPlayer?.currentTime(time);
  }

  seek(time: number) {
    this.totalTime.next(time);
    this.playAfterSeek = this.isPlaying();
    this.currentTime(time);
  }

  isPlaying(): boolean {
    return this.timelinesPlayer?.isPlaying() || false;
  }

  onSwitchMainVideos() {
    this.switchMainVideos.emit();
  }

  private cleanupLottieFontSpans() {
    const elements = Array.from(this.document.querySelectorAll('body > span[aria-hidden]')).filter(
      (el: HTMLElement) => el.style.position === 'absolute' && el.style.left === '-10000px'
    );

    elements.forEach((el) => el.remove());
  }
}
