import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as fromActions from '@openreel/creator/app/store/actions/captions.actions';
import * as fromProjectActions from '@openreel/creator/app/store/actions/project.actions';

import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CaptionsToggleBurnInCommand, CaptionsToggleUpToDateCommand } from '@openreel/creator/common';
import { ProjectFacade } from '@openreel/creator/app/store/facades/project.facade';
import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';
import { of } from 'rxjs';
import { WorkflowProjectService, WorkflowProjectSocketService } from '@openreel/common';

@Injectable()
export class CaptionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly projectFacade: ProjectFacade,
    private readonly mixpanelService: MixpanelService,
    private readonly projectService: WorkflowProjectService,
    private readonly workflowProjectSocketService: WorkflowProjectSocketService
  ) {}

  toggleCaptionsBurnIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleCaptionsBurnIn),
      withLatestFrom(this.projectFacade.workflow$),
      map(([{ burnIn }, workflow]) => {
        const { updatedWorkflow } = new CaptionsToggleBurnInCommand(workflow).run({ burnIn });

        this.mixpanelService.logEvent(
          `wizard_${burnIn ? 'add' : 'remove'}_captions_burn_in`,
          `From the wizard video options click on ${burnIn ? 'add' : 'remove'} _captions_burn_in`
        );

        return fromProjectActions.updateProjectWorkflowAPI({
          data: updatedWorkflow,
        });
      })
    )
  );

  toggleCaptionsUpToDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleCaptionsUpToDate),
      withLatestFrom(this.projectFacade.workflow$),
      map(([{ upToDate }, workflow]) => {
        const { updatedWorkflow } = new CaptionsToggleUpToDateCommand(workflow).run({ upToDate });

        this.mixpanelService.logEvent(
          `wizard_${upToDate ? 'add' : 'remove'}_captions_up_to_date`,
          `From the wizard video options click on ${upToDate ? 'add' : 'remove'} _captions_up_to_date`
        );

        return fromProjectActions.updateProjectWorkflowAPI({
          data: updatedWorkflow,
        });
      })
    )
  );

  setCaptionsUpToDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjectActions.setTranscribeProgress),
      concatMap(({ transcribeProgress }) => [
        fromActions.toggleCaptionsUpToDate({
          upToDate: transcribeProgress.state === 'done',
        }),
        ...(transcribeProgress.state === 'done'
          ? [
              fromProjectActions.toggleHasCaptions({
                hasCaptions: transcribeProgress.state === 'done',
              }),
            ]
          : []),
      ])
    )
  );

  transcribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.transcribe),
      withLatestFrom(this.projectFacade.data$),
      switchMap(([, { data }]) =>
        this.projectService.transcribe(data.id).pipe(
          map((response) => {
            this.mixpanelService.logEvent('transcribe_start', 'Initiate transcribe');
            return fromProjectActions.setTranscribeProgress({
              transcribeProgress: {
                projectId: data.id,
                state: response.done ? 'done' : 'transcribing',
                progress: response.done ? 100 : 0,
              },
            });
          }),
          catchError(() =>
            of(
              fromProjectActions.setTranscribeProgress({
                transcribeProgress: {
                  projectId: data.id,
                  state: 'failed',
                  progress: 0,
                },
              })
            )
          )
        )
      )
    )
  );

  cancelTranscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelTranscribe),
      withLatestFrom(this.projectFacade.data$),
      switchMap(([, { data }]) =>
        this.projectService.cancelTranscribe(data.id).pipe(
          map(() => {
            this.mixpanelService.logEvent('transcribe_cancel', 'Cancel transcribe');

            return fromProjectActions.setTranscribeProgress({
              transcribeProgress: {
                projectId: data.id,
                state: 'cancelled',
                progress: 0,
              },
            });
          })
        )
      )
    )
  );

  transcribeProgressUpdate$ = createEffect(() =>
    this.workflowProjectSocketService.transcribeProgress$.pipe(
      withLatestFrom(this.projectFacade.data$),
      filter(([transcribeProgress, data]) => data.data?.id === transcribeProgress.projectId),
      switchMap(([transcribeProgress]) => of(fromProjectActions.setTranscribeProgress({ transcribeProgress })))
    )
  );
}
