import { TeleprompterScript } from '../../interfaces/interfaces';
import { LensInfo } from '../../interfaces/socket-events';
import { CaptureTeleprompterStatus, TeleprompterPresetsDto, TeleprompterSocketRequest } from '../../interfaces/teleprompter.interface';
import { VideoSource } from '../../media/interfaces/video-source.interface';

export enum OpenreelParticipantStatus {
  Connected,
  Disconnected,
}

// A session participant class that represents a local-copy of a session
// participant in session. This particular class is instantiated in case of
// local-copy of my participant; but it is extended in case of remote
// participant. All data here should be same across the network as well; for
// each participant.

export interface ParticipantVideoProperties {
  fps: string | undefined;
  width: string | undefined;
  resolution: string | undefined;

  // 500 - 11000
  colorTemperature?: number | undefined;
  // 500 - 11000
  colorBalance?: number | undefined;
  // -150 - +150
  tint?: number | undefined;

  // -4 - +4
  exposure?: number | undefined;
  //0 or 1
  autoExposureLock?: number | undefined;
  // 500 - 11000
  iso?: number | undefined;
  // -100 - +100
  contrast?: number | undefined;

  // ?? - ??
  colorBalanceLow?: number | undefined;
  // ?? - ??
  colorBalanceMids?: number | undefined;
  // ?? - ??
  colorBalanceHighs?: number | undefined;
  guide: boolean; // show grid lines
  videoSource?: VideoSource;
  activeVideoSources?: VideoSource[];
}

export interface ParticipantDeviceProperties {
  battery0_100?: number;
  storageGB?: number;
  // Internet Speed Upload:Download
  speed?: string;
  browserAnimationFps: number;
  micSettings?: string;
  externalMic?: string;
  isFrontCamera?: boolean;
  cameras?: LensInfo[];
  selectedCamera?: string;
  selectedOrientation?: string;
  enableManualFocus?: boolean;
}
export interface ParticipantTeleprompterProperties {
  isTeleprompterVisible: boolean;
  teleprompterId: number;
  
  status?: CaptureTeleprompterStatus;
  script?: TeleprompterScript;
  presets?: TeleprompterPresetsDto;
  data?: TeleprompterSocketRequest;
}

