import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GUEST_CREATE_PASSWORD, GUEST_SELECT_ACCOUNT } from '../../route-utils';
import {
  GUEST_USER_ACCOUNT_INFO,
  GuestUserAccountInfo,
} from '../guest-fill-account-info-form/guest-fill-account-info-form.util';
import { commonenv } from '@openreel/common/env/environment';
import { MagicLinkResDto } from '@openreel/common';

@Component({
  selector: 'openreel-guest-create-account',
  templateUrl: './guest-create-account.component.html',
  styleUrls: ['./guest-create-account.component.scss'],
})
export class GuestCreateAccountComponent implements OnInit {
  guest = {} as GuestUserAccountInfo;
  jwt: string;
  jwtVerified: boolean;
  private urls = {
    validateMagicLink: commonenv.nextGenApiUrl + 'magic-link/:token',
    createPassword: '/' + GUEST_CREATE_PASSWORD,
    selectAccount: '/' + GUEST_SELECT_ACCOUNT,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    const guestDetails = JSON.parse(
      localStorage.getItem(GUEST_USER_ACCOUNT_INFO)
    ) as GuestUserAccountInfo;
    if (guestDetails) {
      this.guest = guestDetails;
    }
    this.validateMagicLinkToken();
  }

  validateMagicLinkToken() {
    // if localstorage exists - then user logged using social
    const socialUser = localStorage.getItem(GUEST_USER_ACCOUNT_INFO);
    if (socialUser) {
      this.guest = JSON.parse(socialUser);
      localStorage.removeItem(GUEST_USER_ACCOUNT_INFO);
      this.jwt = this.guest.token;
      this.tokenValidatedSuccessfully();
    } else {
      this.getTokenFromUrl();
    }
  }

  getTokenFromUrl() {
    this.route.queryParams.subscribe((params) => {
      this.jwt = params['token'];
      this.urls.validateMagicLink = this.urls.validateMagicLink.replace(
        ':token',
        this.jwt
      );
      this.httpClient.get(this.urls.validateMagicLink).subscribe(
        (resp: MagicLinkResDto) => {
          this.guest.available_accounts = resp?.data;
          this.tokenValidatedSuccessfully();
        },
        (error) => {
          this.toastr.error(
            error.message
              ? error.message
              : 'An error occurred. Please try again later.',
            'Error!'
          );
          this.jwtVerified = false;
        }
      );
    });
  }

  tokenValidatedSuccessfully() {
    this.jwtVerified = true;
    this.guest.token = this.jwt;
    localStorage.setItem(GUEST_USER_ACCOUNT_INFO, JSON.stringify(this.guest));
    // if any accounts are available - allow the user to select else go to next step
    if (this.guest.available_accounts?.length) {
      this.router.navigate([this.urls.selectAccount]);
    } else {
      this.router.navigate([this.urls.createPassword]);
    }
  }
}
