import { HttpClient, HttpUploadProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { S3 } from 'aws-sdk';
import * as AWSx from 'aws-sdk/global';
import { BehaviorSubject, EMPTY, forkJoin, of } from 'rxjs';
import { commonenv } from '../../environments/environment';
import { DeleteVideoResponse, NewVideoResponseVideo, UserRoleType } from '../../interfaces/interfaces';
import { HelperService } from '../helper/helper.service';
import { ILocalRecorderService } from '../subject/local-recording/local-recorder-base.service';
import {
  RecordingMetadata,
  UploadCredentials,
  UploadDetailsResponse,
  UploadFileInfo,
  UploadFileNetworkStatus,
  UploadMultipartFileDetails,
  MultiPartUploadInfo,
} from './dto/upload.dto';
import * as Evaporate from 'evaporate';
import { catchError, filter, first, map, retryWhen, switchMap } from 'rxjs/operators';
import { MultiPartUploader } from './multi-part-uploader';

import { ArraySubject } from '../../classes';
import { genericRetryStrategy } from '../../utils';
import { ToastrService } from 'ngx-toastr';

const REQUEST_UPLOAD_DETAILS = 'upload-details';
const URL_TRANSCODE_REQUEST = 'transcode';
const MAX_RETRIES = 5;

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private http: HttpClient,
    private recorder: ILocalRecorderService,
    private helper: HelperService,
    private toastr: ToastrService
  ) {
    this.queue$.subscribe((newQueue) => {
      setTimeout(() => {
        this.handleQueueChange(newQueue);
      }, 2000);
    });
  }

  protected mpuQueueSource = new ArraySubject<MultiPartUploadInfo>([]);
  mpuQueue$ = this.mpuQueueSource.asObservable();

  queue$ = new BehaviorSubject<UploadFileInfo[]>([]);
  s3ManagedUpload: { [videoId: number]: S3.ManagedUpload } = {};

  public static recordingMetadataEqual(a: RecordingMetadata, b: RecordingMetadata) {
    return (
      a.token === b.token &&
      a.identity === b.identity &&
      a.localFileName === b.localFileName &&
      a.sessionId === b.sessionId &&
      a.videoId === b.videoId
    );
  }

  private handleQueueChange(newQueue: UploadFileInfo[]) {
    const filtered = newQueue.filter((q) => this.doesMetadataExist(q.metadata));
    if (filtered.length !== newQueue.length) {
      console.warn('Trimmed some upload videos in upload queue ', newQueue, filtered);
      this.queue$.next(filtered);
      return;
    }
    // console.log("Upload queue changed");
    let nextToUpload: UploadFileInfo = null;
    for (let i = 0; i < newQueue.length; i++) {
      if (!newQueue[i].status$.value.hasFailed) {
        nextToUpload = newQueue[i];
        break;
      }
    }

    if (nextToUpload) {
      if (nextToUpload?.metadata.uploadCompleted) {
        this.handleUploadComplete(nextToUpload);
      } else if (Boolean(nextToUpload?.status$.value.isUploading) === false) {
        this.doUploadVideoRecording(nextToUpload);
      }
    }
  }

  cancelAllUploads() {
    const cancelledVideoIds = [];
    while (this.queue$.value.length > 0) {
      const videoId = this.queue$.value[0].metadata.videoId;
      cancelledVideoIds.push(videoId);
      this.cancelVideoUpload(videoId);
    }
    return cancelledVideoIds;
  }

  async uploadVideoRecording(metadata: RecordingMetadata) {
    if (!metadata) {
      return;
    }

    if (!this.doesMetadataExist(metadata)) {
      this.saveRecordingMetadata(metadata);
    }

    for (const info of this.queue$.value) {
      if (UploadService.recordingMetadataEqual(info.metadata, metadata)) {
        if (info.status$.value.hasFailed) {
          info.status$.next({
            ...info.status$.value,
            hasFailed: false,
          });
          info.metadata.retryNumber = 0;
          this.queue$.next([info, ...this.queue$.value.filter((testFileInfo) => testFileInfo !== info)]); // add on top of the queue
        } else {
          console.warn('Uploading already exists for videoId: ' + metadata.videoId);
        }
        return info;
      }
    }
    let localFileData: Blob;
    try {
      localFileData = await this.recorder.getFileData(metadata.localFileName);
    } catch (err) {
      throw new Error(`Unable to obtain local file data: ${err.message}`);
    }
    const ret: UploadFileInfo = {
      localFileData,
      metadata,
      status$: new BehaviorSubject<UploadFileNetworkStatus>({
        isUploading: false,
        percentage: NaN,
        totalMB: 0,
        uploadedMB: 0,
        isCanceled: false,
        hasFailed: false,
        videoId: metadata.videoId,
      }),
    };
    this.queue$.next([...this.queue$.value, ret]);
    return ret;
  }

  private async uploadMultipartEvaporate(
    fileDetails: UploadMultipartFileDetails,
    credentials: UploadCredentials,
    fileInfo: UploadFileInfo
  ) {
    // AWS SDK typing is stupid: https://github.com/aws/aws-sdk-js/issues/1729
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const AWS = AWSx as any;

    const evaporate = await Evaporate.create({
      signerUrl: `${commonenv.nextGenApiUrl}videos/sign`,
      signHeaders: { 'device-token': fileInfo.metadata.token },
      bucket: fileDetails.bucket,
      awsSignatureVersion: '4',
      computeContentMd5: true,
      logging: true,
      cryptoMd5Method: function (data) {
        return AWS.util.crypto.md5(data, 'base64');
      },
      cryptoHexEncodedHash256: function (data) {
        return AWS.util.crypto.sha256(data, 'hex');
      },
    });

    evaporate
      .add({
        name: fileDetails.filePath,
        file: fileDetails.file,
        progress: fileDetails.progressCallback,
        complete: fileDetails.completeCallback,
        uploadInitiated: fileDetails.uploadInitCallback,
      })
      .then(console.log, console.error);
  }

  private async uploadMultipart(
    fileDetails: UploadMultipartFileDetails,
    credentials: UploadCredentials,
    fileInfo: UploadFileInfo
  ) {
    const useEvaporate = false;

    if (useEvaporate) {
      await this.uploadMultipartEvaporate(fileDetails, credentials, fileInfo);
    } else {
      await this.uploadMultipartAws(fileDetails, credentials, fileInfo);
    }
  }
  private async uploadMultipartAws(
    fileDetails: UploadMultipartFileDetails,
    credentials: UploadCredentials,
    fileInfo: UploadFileInfo
  ) {
    // AWS SDK typing is stupid: https://github.com/aws/aws-sdk-js/issues/1729
    const s3 = new S3({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      useAccelerateEndpoint: credentials.useAccelerateEndpoint,
      region: fileDetails.region,
      logger: console,
    });
    const upload = s3.upload(
      {
        Bucket: fileDetails.bucket,
        Key: fileDetails.filePath,
        Body: fileDetails.file,
      },
      {
        partSize: 5 * 1024 * 1024,
        queueSize: 4,
      }
    );

    upload.on('httpUploadProgress', (progress) => {
      fileDetails.progressCallback(progress.loaded / progress.total);
    });
    upload.send(async (err) => {
      if (err) {
        this.handleUploadError(fileInfo, err);
        await new Promise((resolve) => setTimeout(resolve, 5000));

        return;
      }

      fileDetails.completeCallback();
      delete this.s3ManagedUpload[fileInfo.metadata.videoId];
    });

    this.s3ManagedUpload[fileInfo.metadata.videoId] = upload;
  }

  private async doUploadVideoRecording(fileInfo: UploadFileInfo) {
    fileInfo.status$.next({
      ...fileInfo.status$.value,
      isUploading: true,
      hasFailed: false,
    });

    let details: UploadDetailsResponse;
    try {
      details = await this.getUploadDetails(fileInfo.metadata).toPromise();
    } catch (error) {
      this.handleUploadError(fileInfo, error);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return;
    }

    const file = new File([fileInfo.localFileData], fileInfo.metadata.fileNameForUpload);

    this.uploadMultipart(
      {
        file: file,
        filePath: details.path,
        bucket: details.bucket,
        region: details.region,

        progressCallback: (progress) => {
          fileInfo.status$.next({
            ...fileInfo.status$.value,
            percentage: progress,
          });
          fileInfo.metadata.uploadCompleted = progress === 1;
          this.updateRecordingMetadata(fileInfo.metadata);
        },
        completeCallback: () => this.handleUploadComplete(fileInfo),
      },
      {
        accessKeyId: details.accessKeyId,
        secretAccessKey: details.secretAccessKey,
        sessionToken: details.sessionToken,
        useAccelerateEndpoint: details.useAccelerateEndpoint,
      },
      fileInfo
    );
  }

  private handleUploadError(fileInfo: UploadFileInfo, error: Error) {
    const retryNumber = !fileInfo.metadata.retryNumber ? 1 : fileInfo.metadata.retryNumber + 1;
    fileInfo.metadata.retryNumber = retryNumber;
    this.updateRecordingMetadata(fileInfo.metadata);

    fileInfo.status$.next({
      ...fileInfo.status$.value,
      percentage: NaN,
      hasFailed: retryNumber >= MAX_RETRIES,
      isUploading: false,
    });
    console.error('retryNumber: ' + retryNumber, error);

    this.queue$.next(this.queue$.value);
  }

  private handleUploadProgress(fileInfo: UploadFileInfo, evt: HttpUploadProgressEvent) {
    fileInfo.status$.next({
      ...fileInfo.status$.value,
      totalMB: evt.total / 1024,
      uploadedMB: evt.loaded / 1024,
      percentage: evt.loaded / evt.total,
    });
  }

  private async handleUploadComplete(fileInfo: UploadFileInfo) {
    this.initTranscodeRequest(fileInfo.metadata)
      .pipe(filter((response) => response?.status === 1))
      .subscribe(() => {
        this.removeUpload(fileInfo);
      });
  }

  private removeUpload(fileInfo: UploadFileInfo) {
    this.removeRecordingMetadata(fileInfo.metadata);
    fileInfo.status$.complete();
    this.queue$.next(this.queue$.value.filter((testFileInfo) => fileInfo !== testFileInfo));
  }

  cancelVideoUpload(videoId: number) {
    const videoUpload = this.queue$.value.find((f) => f.metadata.videoId === videoId);
    if (!videoUpload) {
      console.log('Video upload not found');
      return;
    }
    videoUpload.status$.next({
      ...videoUpload.status$.value,
      isCanceled: true,
    });

    const upload = this.s3ManagedUpload[videoId];
    if (upload) {
      upload.abort();
    }

    delete this.s3ManagedUpload[videoId];
    this.removeUpload(videoUpload);
  }

  async uploadAllStoredRecordings() {
    try {
      await this.checkVideoData();
      const allMetadata = this.getAllRecordingMetadata();
      const uploadAtEndMd = allMetadata.filter((m) => !m.uploadDuringRecording);
      const uploadDuringMd = allMetadata.filter((m) => m.uploadDuringRecording);
      this.uploadAllStoredMultiPartUploads(uploadDuringMd);
      const promises = uploadAtEndMd.map((m) => this.uploadVideoRecording(m));
      await Promise.all(promises);
    } catch (error) {
      console.error('A problem ocurred while uploading all stored recordings', error);
      Sentry.captureException(error);
    }
  }

  private uploadAllStoredMultiPartUploads(recordings: RecordingMetadata[]) {
    const uploads$ = recordings.map((r) => this.initMultiPartUpload(r));
    if (uploads$.length) {
      forkJoin(uploads$).subscribe((uploads) => {
        setTimeout(() => {
          uploads.forEach((mpu) => {
            mpu.retryUpload();
            mpu.complete();
          });
        }, 5000);
      });
    }
  }

  saveRecordingMetadata(metadata: RecordingMetadata) {
    if (this.doesMetadataExist(metadata)) {
      throw new Error('Cannot save metadata because recording already exists');
    }
    const allMetadata = this.getAllRecordingMetadata();
    localStorage.setItem('recording-metadata', JSON.stringify([...allMetadata, metadata]));
  }

  private updateRecordingMetadata(metadata: RecordingMetadata) {
    const metadataIndex = this.findMetadata(metadata);
    if (metadataIndex === -1) throw new Error('Cannot update metadata because the recording does not exist.');

    const allMetadata = this.getAllRecordingMetadata();
    allMetadata[metadataIndex] = metadata;

    localStorage.setItem('recording-metadata', JSON.stringify(allMetadata));
  }

  private findMetadata(metadata: RecordingMetadata) {
    const allMetadata = this.getAllRecordingMetadata();
    const foundMetadataIdx = allMetadata.findIndex((r) => UploadService.recordingMetadataEqual(metadata, r));
    return foundMetadataIdx;
  }

  private doesMetadataExist(metadata: RecordingMetadata) {
    return this.findMetadata(metadata) !== -1;
  }

  public removeRecordingMetadata(metadata: RecordingMetadata) {
    if (!this.doesMetadataExist(metadata)) {
      return;
    }
    this.recorder.removeFileData(metadata.localFileName);
    localStorage.setItem(
      'recording-metadata',
      JSON.stringify(this.getAllRecordingMetadata().filter((r) => !UploadService.recordingMetadataEqual(r, metadata)))
    );

    this.queue$.next(
      this.queue$.value.filter((testFileInfo) => !UploadService.recordingMetadataEqual(metadata, testFileInfo.metadata))
    );
  }

  public getAllRecordingMetadata(): RecordingMetadata[] {
    return JSON.parse(localStorage.getItem('recording-metadata')) || [];
  }

  private updateUploadID(uploadId: string, metadata: RecordingMetadata) {
    const url = `${commonenv.nextGenApiUrl}videos/${metadata.videoId}`;
    this.http.patch(url, { upload_id: uploadId }, {  headers: this.getUploadHeader(metadata) }).toPromise();
  }

  private async checkVideoData() {
    const allMetadata = this.getAllRecordingMetadata();
    for (const data of allMetadata) {
      const videoDetails = await this.getVideoDetails(data);
      if (
        videoDetails &&
        videoDetails.length > 0 &&
        videoDetails[0].ovra_session_videos_id === data.videoId &&
        (videoDetails[0].is_Deleted === 1 || videoDetails[0].status === 4)
      ) {
        this.removeRecordingMetadata(data);
      }
    }
  }
  private async getVideoDetails(metadata) {
    const url = `${commonenv.nextGenApiUrl}videos?ids=${metadata.videoId}`;
    return this.http
      .get<NewVideoResponseVideo[]>(url, {
        headers: this.getUploadHeader(metadata),
      })
      .toPromise();
  }

  private initTranscodeRequest(metadata: RecordingMetadata) {
    const url = `${commonenv.nextGenApiUrl}videos/${metadata.videoId}/${URL_TRANSCODE_REQUEST}/`;
    return this.http
      .post<DeleteVideoResponse>(url, { transcode_type: 'sd' }, { headers: this.getUploadHeader(metadata) })
      .pipe(
        retryWhen(genericRetryStrategy({ maxRetryAttempts: 10, scalingDuration: 5000, excludedStatusCodes: [404] })),
        catchError(() => {
          this.toastr.error('Transcode Failed');
          return EMPTY;
        })
      );
  }

  getUploadHeader(metadata: RecordingMetadata) {
    if (metadata.role === UserRoleType.Subject) {
      return { 'device-token': metadata.token };
    } else if (metadata.role === UserRoleType.Collaborator) {
      return { 'email-token': metadata.token };
    } else {
      return { 'access-token': metadata.token };
    }
  }

  private getUploadDetails(metadata: RecordingMetadata) {
    const url = `${commonenv.nextGenApiUrl}videos/${metadata.videoId}/${REQUEST_UPLOAD_DETAILS}/`;

    return this.http.post<UploadDetailsResponse>(
      url,
      {
        name: metadata.fileNameForUpload,
      },
      { headers: this.getUploadHeader(metadata) }
    );
  }

  private handleMultiPartUploadComplete(info: MultiPartUploadInfo) {
    this.cleanMpuQueue(info);
    this.initTranscodeRequest(info.metadata).subscribe((response) => {
      if (response.status === 1) {
        this.cleanMpuQueue(info);
      }
    });
  }

  private cleanMpuQueue(info: MultiPartUploadInfo) {
    this.removeRecordingMetadata(info.metadata);
    this.mpuQueueSource.remove(info);
  }

  hanldeMultiPartUploadError(info: MultiPartUploadInfo, error) {
    console.error('mpu error', info, error);
  }

  private _initMultiPartUpload(metadata: RecordingMetadata) {
    return this.getUploadDetails(metadata).pipe(
      map((uploadDetails) => {
        const uploader = new MultiPartUploader(uploadDetails, {
          dbKey: metadata.localFileName,
        });
        const mpuInfo = { uploader, metadata };
        uploader.uploadId$.pipe(filter((id)=> !!id)).subscribe((id:string) => this.updateUploadID(id,metadata));
        uploader.complete$.subscribe(() => this.handleMultiPartUploadComplete(mpuInfo));
        uploader.aborted$.subscribe(() => this.cleanMpuQueue(mpuInfo));
        uploader.error$.subscribe((err) => this.hanldeMultiPartUploadError(mpuInfo, err));
        uploader.credentialError$
          .pipe(switchMap(() => this.getUploadDetails(metadata)))
          .subscribe((ud) => uploader.refreshS3(ud));
        this.mpuQueueSource.push(mpuInfo);
        return uploader;
      })
    );
  }

  initMultiPartUpload(metadata: RecordingMetadata) {
    return this.mpuQueueSource
      .find$((mpu) => UploadService.recordingMetadataEqual(mpu.metadata, metadata))
      .pipe(
        first(),
        switchMap((mpu) => (mpu ? of(mpu.uploader) : this._initMultiPartUpload(metadata)))
      );
  }
}
