import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  AuthGuard,
  FeatureFlagGuard,
  RedirectToCaptureGuard,
  WorkflowsAllowedOnAccountGuard,
  WORKFLOWS_INSTANT_WIZARD,
} from '@openreel/common';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: 'wizard',
    canActivate: [AuthGuard, WorkflowsAllowedOnAccountGuard, FeatureFlagGuard],
    data: {
      featureFlag: WORKFLOWS_INSTANT_WIZARD,
    },
    loadChildren: () => import('./wizard/wizard.module').then((m) => m.WizardModule),
  },
  {
    path: 'project',
    redirectTo: 'creator',
  },
  {
    path: '**',
    pathMatch: 'full',
    canActivate: [RedirectToCaptureGuard],
    component: AppComponent,
  },
];

export const routableComponents = [];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
