import { TimelineType } from '@openreel/creator/common';

export interface SectionConfig {
  backgroundColor: string;
  backgroundColorLight: string;
  icon?: string;
}

export interface SectionCommonConfig {
  gap: number;
  minWidthToShowIcon: number;
}

export interface TimelineConfig {
  height: number;
  layerColor: string;
  layerFill: boolean;
  itemsStretchable: boolean;
  stretchHandleColor?: string;
  itemsReorderable: boolean;
  emptyText: string;
  hasThumbnails: boolean;
}

export interface TimelineTrackConfig {
  backgroundColor: string;
  textColor: string;
  backgroundColorLight: string;
  textColorLight: string;
  bottomMargin: number;
  stretchHandleWidth: number;
  showLayerBorder: boolean;
}

export interface TimeCursorConfig {
  color: string;
  width: number;
}

export interface SelectionConfig {
  borderColor: string;
  nearThresholdRatio: number;
  nearThresholdMs: number;
}

export interface ThumbnailsConfig {
  enabled: boolean;
  height: number;
  borderRadius: number;
  spritesheet: {
    thumbsPerRow: number;
  };
  padding: {
    x: number;
    y: number;
  };
}

export interface CanvasConfig {
  debug: boolean;
  canvas: {
    padding: {
      x: number;
      y: number;
    };
  };
  selection: SelectionConfig;
  scroll: {
    amount: number;
  };
  timeCursor: TimeCursorConfig;
  sectionOptions: SectionCommonConfig;
  sections: {
    intro: SectionConfig;
    main: SectionConfig;
    outro: SectionConfig;
  };
  timelineTrackOptions: TimelineTrackConfig;
  timelines: {
    [key in TimelineType]?: TimelineConfig;
  };
  trimmer: {
    backgroundAreaColor: string;
    handleColor: string;
    handleWidth: number;
    textSpaceBetween: number;
  };
  thumbnails: ThumbnailsConfig;
}

export const defaultConfig: CanvasConfig = {
  debug: false,
  canvas: {
    padding: {
      x: 4,
      y: 12,
    },
  },
  selection: {
    borderColor: '#ffffff',
    nearThresholdRatio: 0.1,
    nearThresholdMs: 50,
  },
  scroll: {
    amount: 100,
  },
  timeCursor: {
    color: '#0065ffbf',
    width: 4,
  },
  sectionOptions: {
    gap: 4,
    minWidthToShowIcon: 30,
  },
  sections: {
    intro: {
      backgroundColor: '#283244',
      backgroundColorLight: '#888A91',
      icon: 'video-timeline',
    },
    main: {
      backgroundColor: 'rgba(0,0,0,0)',
      backgroundColorLight: 'rgba(0,0,0,0)',
    },
    outro: {
      backgroundColor: '#283244',
      backgroundColorLight: '#888A91',
      icon: 'video-timeline',
    },
  },
  timelineTrackOptions: {
    backgroundColor: '#0f2b53',
    backgroundColorLight: '#BFC2CC',
    textColor: 'white',
    textColorLight: 'black',
    bottomMargin: 8,
    stretchHandleWidth: 8,
    showLayerBorder: true,
  },
  timelines: {
    main: {
      height: 50,
      layerColor: '#cfd600',
      layerFill: false,
      itemsStretchable: false,
      itemsReorderable: true,
      emptyText: 'No Clips',
      hasThumbnails: true,
    },
    'b-roll': {
      height: 50,
      layerColor: '#358dff',
      layerFill: false,
      itemsStretchable: false,
      itemsReorderable: true,
      emptyText: 'No Video Overlays',
      hasThumbnails: true,
    },
    overlays: {
      height: 20,
      layerColor: '#de5258',
      layerFill: true,
      itemsStretchable: true,
      itemsReorderable: false,
      stretchHandleColor: '#80181D',
      emptyText: 'No Text Overlays',
      hasThumbnails: false,
    },
  },
  trimmer: {
    backgroundAreaColor: '#202125',
    handleColor: '#eab707',
    handleWidth: 11,
    textSpaceBetween: 6,
  },
  thumbnails: {
    enabled: true,
    height: 48,
    borderRadius: 6,
    spritesheet: {
      thumbsPerRow: 60,
    },
    padding: {
      x: 3,
      y: 1,
    },
  },
};

export const trimmerConfig: Partial<CanvasConfig> = {
  timelineTrackOptions: {
    ...defaultConfig.timelineTrackOptions,
    showLayerBorder: false,
    bottomMargin: 20,
  },
};
