<video
  #video
  id="video"
  preload="auto"
  [loop]="data.loop"
  (canplaythrough)="onVideoCanPlayThrough()"
  (ended)="onVideoEnded()"
  (seeking)="onVideoSeeking()"
  (seeked)="onVideoSeeked()"
  (error)="onVideoError()"
  [style]="{
    objectFit: styles?.objectFit,
    objectPosition: calculateObjectPosition()
  }"
>
  <source
    [src]="sanitizer.bypassSecurityTrustResourceUrl(data.source)"
    [type]="data.type"
  />
</video>
