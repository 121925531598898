import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
    selector: 'or-preview-thumb',
    templateUrl: './preview-thumb.component.html',
    styleUrls: ['./preview-thumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewThumbComponent {
    private _thumbUrl: string;
    @Input()
    set thumbUrl(url: string) {
        this.imgSrc.next(url);
        this._thumbUrl = url;
    }
    get thumbUrl() {
        return this._thumbUrl;
    }

    @Input()
    previewUrl: string;

    @Input()
    showPlayButton = true;

    @Input()
    alt = '';

    @Output()
    playButtonClick = new EventEmitter<MouseEvent>();

    private imgSrc = new ReplaySubject<string>(1);

    imgSrc$ = this.imgSrc.pipe(
        filter(s => !!s),
        distinctUntilChanged(),
    );

    imgLoaded = false;

    onPlayClick($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.playButtonClick.emit($event);
    }

    setThumb(src: string = '') {
        this.imgSrc.next(src);
    }
}