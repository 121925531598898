import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Output,
  ViewChild,
  Optional,
  ChangeDetectorRef,
} from '@angular/core';
import { splitNameAndExtension } from 'libs/common/src/file.utils';
import { AssetId, AssetsFileProviderType } from '@openreel/creator/common';
import { merge, noop } from 'lodash-es';
import { combineLatest, EMPTY, isObservable, Observable, of } from 'rxjs';
import { last, switchMap, tap } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertService } from './../openreel-alert/openreel-alert.service';

const ACCEPTED_FILE_EXTENSIONS = {
  image: '.png,.jpg,.jpeg',
  video: '.mp4,.webm,.mov',
  document: '.vtt,.srt,.json',
};

interface S3Credentials {
  credentials: {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
  };
  region: string;
  bucket: string;
  key: string;
}

export type UploadTypes = 'image' | 'video' | 'document';

type UploadCredentials = string | S3Credentials;

export const UPLOADER_SERVICE_TOKEN = new InjectionToken<UploaderBaseService<UploadCredentials>>(
  'UPLOADER SERVICE TOKEN'
);

export abstract class UploaderBaseService<T extends UploadCredentials> {
  abstract getUploadCredentials(
    type: UploadTypes,
    extension: string,
    name?: string
  ): Observable<{ id: string | number; credential: T }>;

  abstract upload(credentials: T, file: File, id?: AssetId): Observable<{ loaded: number; total: number }>;
}

export const VALIDATOR_SERVICE_TOKEN = new InjectionToken<FileValidatorBaseService>('FILE VALIDATOR SERVICE TOKEN');

export type FileValidationError = null | string;

export abstract class FileValidatorBaseService {
  abstract validate(file: File): FileValidationError | Observable<FileValidationError>;
}

export interface UploaderOptions {
  showBackgroundColor?: boolean;
  showRemoveAction?: boolean;
}

const DEFAULT_OPTIONS: UploaderOptions = {
  showBackgroundColor: true,
  showRemoveAction: true,
};

function clipSize(mbs: number) {
  if (mbs >= 1024) {
    return Math.round(mbs / 1024) + 'GB';
  }
  if (mbs < 1) {
    return Math.round(mbs * 1024) + 'KB';
  }
  return Math.round(mbs) + 'MB';
}

export class SelectingFileEvent {
  preventDefault = false;
}

@Component({
  selector: 'openreel-uploader',
  templateUrl: './openreel-uploader.component.html',
  styleUrls: ['./openreel-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelUploaderComponent,
    },
  ],
})
export class OpenreelUploaderComponent implements ControlValueAccessor, OnInit {
  @Input() uploadCaption: string;
  @Input() type: UploadTypes;
  @Input() selectedSourceProvider?: AssetsFileProviderType;

  @Input() allowDragAndDrop = false;
  @Input() set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  @Input() fullSize = false;
  @Input() uploadedClasses = '';
  @Input() options: UploaderOptions;

  @Input() @HostBinding('class.dark') darkBackground = false;
  @HostBinding('class.dragging') isDraggingOver = false;

  @Output() started = new EventEmitter<AssetId>();
  @Output() metadata = new EventEmitter<{ file: File; fileName: string }>();
  @Output() upload = new EventEmitter<AssetId>();
  @Output() failed = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() fileValidationStarted = new EventEmitter();
  @Output() fileValidationEnded = new EventEmitter<boolean>();
  @Output() selectingFile = new EventEmitter<SelectingFileEvent>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  parsedOptions: UploaderOptions;

  sourceId: AssetId;

  propagateOnChanged: (sourceId: AssetId) => void = noop;
  propagateOnTouched = noop;

  isUploaded = false;
  isUploading = false;
  uploadProgress = 0;

  isDisabled = false;

  fileName: string;
  fileSize: string;

  get acceptedFileExtensions() {
    return ACCEPTED_FILE_EXTENSIONS[this.type];
  }

  constructor(
    @Inject(UPLOADER_SERVICE_TOKEN) public uploaderService: UploaderBaseService<UploadCredentials>,
    private readonly alertService: AlertService,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(VALIDATOR_SERVICE_TOKEN) private fileValidatorService?: FileValidatorBaseService
  ) {}

  async ngOnInit() {
    this.parsedOptions = merge({}, DEFAULT_OPTIONS, this.options);
  }

  writeValue(sourceId: AssetId) {
    this.setSourceId(sourceId);
    this.resetState();
    this.cd.markForCheck();
  }

  registerOnChange(fn: (sourceId: AssetId) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  private setSourceId(sourceId: AssetId, propagateChanged = false) {
    const changed = this.sourceId !== sourceId;

    this.sourceId = sourceId;
    this.resetState();

    if (changed && propagateChanged) {
      this.propagateOnChanged(this.sourceId);
    }
  }

  private clearFile(propagateChanged = false) {
    this.fileInput.nativeElement.value = null;
    this.fileName = null;
    this.fileSize = null;
    this.setSourceId(null, propagateChanged);
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  onSelectFile() {
    const event = new SelectingFileEvent();
    this.selectingFile.emit(event);

    if (!event.preventDefault) {
      this.selectFile();
    }
  }

  removeFile() {
    this.propagateOnTouched();
    this.clearFile(true);
    this.remove.emit();
  }

  onDragOver(event: Event) {
    event.preventDefault();
  }

  onDragEnter(event: Event) {
    event.preventDefault();

    if (!this.allowDragAndDrop) {
      return;
    }

    this.isDraggingOver = true;
  }

  onDragLeave(event: Event) {
    event.preventDefault();

    if (!this.allowDragAndDrop) {
      return;
    }

    this.isDraggingOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (!this.allowDragAndDrop || !event.dataTransfer.files.length) {
      return;
    }

    this.uploadFile(event.dataTransfer.files.item(0));
  }

  onFileDialogChange($event: Event) {
    $event.stopPropagation();

    this.propagateOnTouched();

    const target = $event.target as HTMLInputElement;
    if (target.files.length === 0) {
      return;
    }

    this.uploadFile(target.files.item(0));
  }

  private validateFile(file: File) {
    this.fileValidationStarted.emit();

    let validation$ = of(null);
    if (this.fileValidatorService) {
      const validation = this.fileValidatorService.validate(file);
      validation$ = isObservable(validation) ? validation : of(validation);
    }
    return validation$.pipe(
      switchMap((error) => {
        if (!error) {
          this.fileValidationEnded.emit(true);
          return of(file);
        }
        this.isUploading = false;
        this.alertService.error(error, 'File not allowed');
        this.clearFile(true);
        this.cd.markForCheck();
        this.fileValidationEnded.emit(false);
        return EMPTY;
      })
    );
  }

  private uploadFile(file) {
    const [name, extension] = splitNameAndExtension(file.name);
    if (!extension) {
      return;
    }

    this.isUploading = true;
    this.fileName = file.name;
    this.fileSize = clipSize(file.size / (1024 * 1024));

    this.validateFile(file)
      .pipe(
        tap(() => this.metadata.emit({ file, fileName: this.fileName })),
        switchMap(() => this.uploaderService.getUploadCredentials(this.type, extension, name)),
        tap(({ id }) => this.started.emit(id)),
        switchMap(({ id, credential }) =>
          combineLatest([
            of(id),
            this.uploaderService.upload(credential, file, id).pipe(
              tap((event) => {
                this.uploadProgress = Math.round((event.loaded / event.total) * 100);
              }),
              last()
            ),
          ])
        )
      )
      .subscribe(
        ([id]) => {
          this.setSourceId(+id, true);
          this.upload.emit(this.sourceId);
        },
        (error) => {
          this.isUploading = false;
          this.alertService.error(error.message);
          this.clearFile(true);
          this.failed.emit();
        },
        () => this.cd.markForCheck()
      );
  }

  private resetState() {
    this.uploadProgress = 0;
    this.isUploading = false;

    this.isUploaded = !!this.sourceId;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
