export interface SeatsInfo {
  webinarSeats: number;
  hostingSeats: number;
  creatorSeats: number;
  captureSeats: number;
  captureSeatsExist: number;
  webinarSeatsExist: number;
  hostingSeatsExist: number;
  creatorSeatsExist: number;
  packageWebinar: string;
  packageCreator: string;
  packageHosting: string;
  packageCapture: string;
  captureExpireAt: Date;
  creatorExpireAt: Date;
  webinarExpireAt: Date;
  hostingExpireAt: Date;
  defaultCaptureAccessLevel: CAPTURE_ACCESS_LEVEL;
  defaultCreatorAccessLevel: CREATOR_ACCESS_LEVEL;
  defaultHostingAccessLevel: HOSTING_ACCESS_LEVEL;
  defaultWebinarAccessLevel: WEBINAR_ACCESS_LEVEL;
  captureOnTrial: boolean;
  joiningRules: TeamJoiningRules;
  subscription?: Subscription;
}

export interface Subscription {
  status: string;
  price: number;
  interval: string;
  interval_count: number;
  creditBalance: number;
  managePackage: boolean;
}

export interface SeatsResponse {
  rows: Seats[];
  count: number;
}

export interface Seats {
  email: string;
  name: string;
  lastActiveDate: Date;
  userId: number;
  active: boolean;
  isSuperAdmin: boolean;
  captureAccessLevel: CAPTURE_ACCESS_LEVEL;
  creatorAccessLevel: CREATOR_ACCESS_LEVEL;
  hostingAccessLevel: HOSTING_ACCESS_LEVEL;
  webinarAccessLevel: WEBINAR_ACCESS_LEVEL;
}

export interface UpdateSeats {
  isSuperAdmin?: boolean;
  captureAccessLevel?: CAPTURE_ACCESS_LEVEL;
  creatorAccessLevel?: CREATOR_ACCESS_LEVEL;
  hostingAccessLevel?: HOSTING_ACCESS_LEVEL;
  webinarAccessLevel?: WEBINAR_ACCESS_LEVEL;
  isActive?: boolean;
}

export enum CAPTURE_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Director = 'Director',
}

export enum CREATOR_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Creator = 'Creator',
}

export enum HOSTING_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Director = 'Director',
}

export enum WEBINAR_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Director = 'Director',
}

export enum TeamJoiningRules {
  InvitationOnly = 'InvitationOnly',
  RequestToJoin = 'RequestToJoin',
  AutoJoin = 'AutoJoin',
}

export interface UpdateDefaultProductAccess {
  defaultCaptureAccessLevel?: CAPTURE_ACCESS_LEVEL;
  defaultCreatorAccessLevel?: CREATOR_ACCESS_LEVEL;
  defaultHostingAccessLevel?: HOSTING_ACCESS_LEVEL;
  defaultWebinarAccessLevel?: WEBINAR_ACCESS_LEVEL;
}

export const SubscriptionStatus = {
  active: 'Active',
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete Expired',
  trialing: 'Trialing',
  past_due: 'Past Due',
  canceled: 'Canceled',
  unpaid: 'Unpaid',
};

export interface SeatsQuantity {
  quantity: number;
  minQuantity: number;
  maxQuantity: number;
}

export const MaxSeatsAllowed = 999;
export const TrialMaxSeatsAllowed = 10;
