import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SocialConfigService {
  constructor(private http: HttpClient) {}

  getClientConfig() {
    return this.http.get<{ google_client_id: string }>(
      `${commonenv.nextGenApiUrl}client-configs`
    );
  }
}
