<div
  (resized)="onResized($event)"
  (cdkDragEnded)="onDragEnd($event)"
  [cdkDragFreeDragPosition]="{ x: popupPosition.x, y: popupPosition.y }"
  class="dialog-drag"
  cdkDrag
  cdkDragBoundary=".cdk-overlay-container"
  cdkDragRootElement=".teleprompter-popup"
>
  <div mat-dialog-content class="dialog-content">
    <div
      class="single-view-grid-list teleprompter-fullscreen"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div
        [fxFlex]="100"
        class="telepromter-screen"
      >
        <mat-icon class="close-icon" [ngClass]="teleprompter?.background ?? 'white_in_black'" (click)="onDialogCloseClicked()">close</mat-icon>
        <openreel-subject-teleprompter cdkDragHandle
          #telepromter
        ></openreel-subject-teleprompter>
        <mat-icon class="resize-icon">
          aspect_ratio
        </mat-icon>
      </div>
    </div>
  </div>
</div>
