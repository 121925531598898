import 'froala-editor/js/plugins/paragraph_format.min.js';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AngularResizedEventModule } from 'angular-resize-event';
import { AnimationModule } from './components/animation/animation.module';
import { UiModule } from '@openreel/ui';
import { AudioGainControlComponent } from './components/audio-gain-control/audio-gain-control.component';
import { AudioMeterComponent } from './components/audio-meter/audio-meter/audio-meter.component';
import { AudioParticipantComponent } from './components/audio-participant/audio-participant.component';
import { AvatarModule } from 'ngx-avatar';
import { CaptureTeleprompterComponent } from './components/teleprompter/capture-teleprompter/capture-teleprompter.component';
import { CollaboratorLoginComponent } from './components/collaborator-login-form/collaborator-login-form.component';
import { CommonModule } from '@angular/common';
import { CountdownComponent } from './components/countdown/countdown.component';
import { DeviceInfoComponent } from './components/device-info-popup/device-info-popup.component';
import { DirectorForgotPasswordComponent } from './components/director-forgot-password-form/director-forgot-password-form.component';
import { DirectorForgotPasswordResetComponent } from './components/director-forgot-reset-password-form/director-forgot-reset-password-form.component';
import { DirectorLoginComponent } from './components/director-login-form/director-login-form.component';
import { DoubleVideoComponent } from './components/double-video/double-video.component';
import { FlatChatComponent } from './components/chat/flat-chat/flat-chat.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GenericAlertComponent } from './components/generic-alert/generic-alert.component';
import { GenericLoadingComponent } from './components/generic-loading/generic-loading.component';
import { GenericReconnectSpinnerComponent } from './components/generic-reconnect-spinner/generic-reconnect.component';
import { GuestAccountActivatedConfettiesComponent } from './components/guest-account-activated-confetties/guest-account-activated-confetties.component';
import { GuestCreatePasswordFormComponent } from './components/guest-create-password-form/guest-create-password-form.component';
import { GuestFillAccountInfoFormComponent } from './components/guest-fill-account-info-form/guest-fill-account-info-form.component';
import { GuestMagiclinkFormComponent } from './components/guest-magiclink-form/guest-magiclink-form.component';
import { GuestMagiclinkSentFormComponent } from './components/guest-magiclink-sent-form/guest-magiclink-sent-form.component';
import { GuestWizardContainerComponent } from './components/guest-wizard-container/guest-wizard-container.component';
import { HighlightableTextareaComponent } from './components/teleprompter/highlightable-textarea/highlightable-textarea.component';
import { HttpClientModule } from '@angular/common/http';
import { MicWavesComponent } from './components/mic-waves/mic-waves.component';
import { NgModule } from '@angular/core';
import { NgxStronglyTypedFormsModule } from 'ngx-strongly-typed-forms';
import { OpenreelCommonDirectivesModule } from './directives/openreel-common-directives.module';
import { OpenreelCommonPipesModule } from './pipes/openreel-common-pipes.module';
import { OrInviteInternalComponent } from './components/or-invite-internal-user/or-invite-internal-user.component';
import { OrUserInfoUpdateComponent } from './components/or-user-info-update/or-user-info-update.component';
import { PerformanceScreenPopupComponent } from './components/performance-screen-popup/performance-screen-popup.component';
import { PreviewRecordingPopupComponent } from './components/preview-recording-popup/preview-recording-popup.component';
import { QuestionPopupComponent } from './components/question-popup/question-popup.component';
import { RecordingStatisticsComponent } from './components/recording-statistics/recording-statistics.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { RouterModule } from '@angular/router';
import { SessionAudioComponent } from './components/session-audio/session-audio.component';
import { SingleVideoComponent } from './components/single-video/single-video.component';
import { SubjectTeleprompterComponent } from './components/teleprompter/subject-teleprompter/subject-teleprompter.component';
import { TeleprompterPresetsFormComponent } from './components/teleprompter/teleprompter-presets-form/teleprompter-presets-form.component';
import { TeleprompterPreviewPopupComponent } from './components/teleprompter/teleprompter-preview-popup/teleprompter-preview-popup.component';
import { TitleLineComponent } from './components/title-line/title-line.component';
import { UploadIndicatorComponent } from './components/upload-indicator/upload-indicator.component';
import { VideoEditorModule } from './components/video-editor/video-editor.module';
import { VolumeMeterComponent } from './components/volume-meter/volume-meter.component';
import { WaitingRoomComponent } from './components/waiting-room/waiting-room.component';
import { VideoParticipantComponent } from 'libs/ui/src/components/video-participant/video-participant.component';
import { GuestSelectAccountComponent } from './components/guest-select-account/guest-select-account.component';
import { TeleprompterParticipantComponent } from './components/teleprompter/teleprompter-participant/teleprompter-participant.component';
import { GuestCreateAccountComponent } from './components/guest-create-account/guest-create-account.component';
import { SubjectTeleprompterPopupComponent } from './components/teleprompter/subject-teleprompter-popup/subject-teleprompter-popup.component';
import { TfaPopupComponent } from './components/tfa-popup/tfa-popup.component';

@NgModule({
  declarations: [
    GenericLoadingComponent,
    VideoParticipantComponent,
    AudioParticipantComponent,
    MicWavesComponent,
    DirectorLoginComponent,
    DirectorForgotPasswordComponent,
    DirectorForgotPasswordResetComponent,
    CollaboratorLoginComponent,
    SingleVideoComponent,
    DoubleVideoComponent,
    AudioMeterComponent,
    SubjectTeleprompterComponent,
    CaptureTeleprompterComponent,
    HighlightableTextareaComponent,
    TeleprompterPresetsFormComponent,
    UploadIndicatorComponent,
    FlatChatComponent,
    OrInviteInternalComponent,
    TitleLineComponent,
    OrUserInfoUpdateComponent,
    CountdownComponent,
    SessionAudioComponent,
    GenericReconnectSpinnerComponent,
    RichTextEditorComponent,
    GenericAlertComponent,
    AudioGainControlComponent,
    RecordingStatisticsComponent,
    WaitingRoomComponent,
    GuestWizardContainerComponent,
    // Popups
    TeleprompterPreviewPopupComponent,
    PreviewRecordingPopupComponent,
    QuestionPopupComponent,
    PerformanceScreenPopupComponent,
    GuestMagiclinkFormComponent,
    GuestMagiclinkSentFormComponent,
    GuestCreatePasswordFormComponent,
    GuestFillAccountInfoFormComponent,
    GuestAccountActivatedConfettiesComponent,
    DeviceInfoComponent,
    VolumeMeterComponent,
    GuestSelectAccountComponent,
    TeleprompterParticipantComponent,
    GuestCreateAccountComponent,
    SubjectTeleprompterPopupComponent,
    TfaPopupComponent,
  ],
  entryComponents: [
    TeleprompterPreviewPopupComponent,
    SubjectTeleprompterPopupComponent,
    PreviewRecordingPopupComponent,
    PerformanceScreenPopupComponent,
    QuestionPopupComponent,
    GenericAlertComponent,
    VideoParticipantComponent,
    DeviceInfoComponent,
    TfaPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    UiModule,
    FlexLayoutModule,
    // ThemeModule,
    AvatarModule,
    AnimationModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    NgxStronglyTypedFormsModule,
    AngularResizedEventModule,
    VideoEditorModule,
    OpenreelCommonDirectivesModule,
    OpenreelCommonPipesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    UiModule,
    AngularResizedEventModule,
    FlexLayoutModule,
    GenericLoadingComponent,
    AudioMeterComponent,
    VideoParticipantComponent,
    AudioParticipantComponent,
    MicWavesComponent,
    DirectorLoginComponent,
    DirectorForgotPasswordComponent,
    DirectorForgotPasswordResetComponent,
    CollaboratorLoginComponent,
    SingleVideoComponent,
    DoubleVideoComponent,
    SubjectTeleprompterComponent,
    CaptureTeleprompterComponent,
    UploadIndicatorComponent,
    FlatChatComponent,
    OrInviteInternalComponent,
    TitleLineComponent,
    OrUserInfoUpdateComponent,
    CountdownComponent,
    SessionAudioComponent,
    VideoEditorModule,
    GenericReconnectSpinnerComponent,
    OpenreelCommonDirectivesModule,
    OpenreelCommonPipesModule,
    RichTextEditorComponent,
    AudioGainControlComponent,
    RecordingStatisticsComponent,
    WaitingRoomComponent,
    GuestWizardContainerComponent,
    TeleprompterParticipantComponent,
    // popups
    PerformanceScreenPopupComponent,
    PreviewRecordingPopupComponent,
    GuestMagiclinkFormComponent,
    GuestMagiclinkSentFormComponent,
    GuestCreatePasswordFormComponent,
    GuestFillAccountInfoFormComponent,
    GuestAccountActivatedConfettiesComponent,
    DeviceInfoComponent,
    VolumeMeterComponent,
    GuestSelectAccountComponent,
    GuestCreateAccountComponent,
    SubjectTeleprompterPopupComponent,
    TfaPopupComponent,
  ],
  providers: [],
})
export class OpenreelCommonModule {}
