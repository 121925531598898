export const ROUTE_HOME = 'dashboard';
export const ROUTE_SUBJECT = 'subject/:mode';
export const ROUTE_SUBJECT_OTP = 'subject/MODE_OTP';
export const ROUTE_SUBJECT_SESSION = 'subject/session';
export const ROUTE_DOWNLOAD_MOBILE_APP = 'download-mobile-app';
export const ROUTE_DIRECTOR_LOGIN = 'login';
export const ROUTE_FORGOT_PASSWORD = 'forgot-password';
export const ROUTE_VERIFY_FORGOT_PASSWORD = 'password-reset';
export const ROUTE_DIRECTOR_SESSIONS = 'director/sessions';
export const ROUTE_DIRECTOR_SESSION = 'director/session/:id';
export const ROUTE_DIRECTOR_LOBBY = 'director/lobby/:id';
export const ROUTE_COLLABORATOR_INVITE = 'collaborator/invite/:email/:token';
export const ROUTE_DIRECTOR_INVITE = 'director/invite';
export const ROUTE_SUBJECT_INVITE = 'appinvite';
export const ROUTE_PROJECTS = 'projects';
export const ROUTE_ARCHIVED_PROJECTS = 'archived-projects';
export const ROUTE_PROJECT_DETAILS = 'projects/:id';
export const ROUTE_PROJECT_ARCHIVED_SESSIONS = 'archived-sessions';
export const ROUTE_PROJECT_ARCHIVED_WORKFLOWS = 'archived-workflows';
export const ROUTE_DIRECTOR_WAITING_ROOM = 'director/waiting-room';

export const ROUTE_TEMPLATES = 'templates';

export const ROUTE_HOST = 'library';

export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_SETTINGS = 'settings';
export const ROUTE_EDITOR = 'editor';
export const ROUTE_VIDEO_PREVIEW = 'video-preview';
export const ROUTE_VIDEO_RETRY = 'video-retry';
export const ROUTE_SEATS = 'settings';
export const ROUTE_UGC_SUBJECT = 'ugc-subject';

export const ROUTE_SSO_AUTH = 'sso-auth';
export const ROUTE_SSO_LOGIN = 'sso-login';
export const ROUTE_SSO_SETUP = 'sso-setup';

export const GUEST_MAGIC_LINK = 'account-signup';
export const GUEST_MAGIC_LINK_SENT = 'signup-email-sent';
export const GUEST_CREATE_PASSWORD = 'create-password';
export const GUEST_FILL_ACCOUNT_INFO = 'fill-account-info';
export const GUEST_ACCOUNT_ACTIVATED = 'account-activated';
export const GUEST_SELECT_ACCOUNT = 'select-account';
export const GUEST_ACCOUNT_CREATE = 'account-create';

export const ROUTE_UNSUPPORTED_BROWSER = 'unsupported-browser';

export const ROUTE_GDPR = 'gdpr';

export const ROUTE_IMPORT_OLD_SESSIONS = 'import-old-sessions';

export function getRouteHome() {
  return '/' + ROUTE_HOME;
}

export function getRouteLoginOtp(otp?: string, name?: string) {
  let ret = '/' + ROUTE_SUBJECT.replace(':mode', SubjectMode[SubjectMode.MODE_OTP]);
  if (otp) {
    ret += '?otp=' + otp;
    if (name) {
      ret += '&name=' + name;
    }
  }

  return ret;
}

export enum SubjectMode {
  MODE_OTP,
  MODE_INVITE,
}

export function getRouteSubjectOtp(otp: string, name: string) {
  return '/' + ROUTE_SUBJECT.replace(':mode', SubjectMode[SubjectMode.MODE_OTP]) + '?otp=' + otp + '&name=' + name;
}
export function getRouteSubjectInvitation(token: string) {
  return '/' + ROUTE_SUBJECT.replace(':mode', SubjectMode[SubjectMode.MODE_INVITE]) + '?token=' + token;
}

export function getRouteDirectorSession(sessionId: number) {
  return '/' + ROUTE_DIRECTOR_SESSION.replace(':id', sessionId.toString());
}

export function getRouteDirectorLobby(sessionId: number) {
  return '/' + ROUTE_DIRECTOR_LOBBY.replace(':id', sessionId.toString());
}

export function getRouteDirectorSessions() {
  return ROUTE_DIRECTOR_SESSIONS;
}

export function getRouteAppInvite(token: string, name: string, otp: string) {
  return '/' + ROUTE_SUBJECT_INVITE + '?token=' + token + '&otp=' + otp + '&name=' + name;
}

export function getRouteProjectDetails(id) {
  return '/' + ROUTE_PROJECT_DETAILS.replace(':id', id.toString());
}

export function getRouteProjectArchivedSessions(id) {
  return '/' + (ROUTE_PROJECT_DETAILS + '/' + ROUTE_PROJECT_ARCHIVED_SESSIONS).replace(':id', id.toString());
}

export function getRouteProjectArchivedWorkflows(id) {
  return '/' + (ROUTE_PROJECT_DETAILS + '/' + ROUTE_PROJECT_ARCHIVED_WORKFLOWS).replace(':id', id.toString());
}

export enum LoginRoute {
  Login = 'login',
  ForgotPassword = 'forgot_password',
  ResetPassword = 'reset_password',
  MagicLink = 'account-signup',
  MagicLinkSent = 'signup-email-sent',
  AccountCreate = 'account-create',
  CreatePassword = 'create-password',
  FillAccountInfo = 'fill-account-info',
  AccountActivated = 'account-activated',
  SelectAccount = 'select-account',
}
