import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DropItemEvent, UpdateTrimBoundsEvent } from '../canvas.interfaces';

@Injectable()
export class CanvasEventsService {
  private itemsSelected = new Subject<string[]>();
  itemsSelected$ = this.itemsSelected.asObservable();

  private scroll = new Subject<number>();
  scroll$ = this.scroll.asObservable();

  private zoom = new Subject<{ delta: 1 | -1; targetPointMs?: number }>();
  zoom$ = this.zoom.asObservable();

  private drop = new Subject<DropItemEvent[]>();
  drop$ = this.drop.asObservable();

  private seek = new Subject<number>();
  seek$ = this.seek.asObservable();

  private stretch = new Subject<{
    layerId: string;
    newStartAtMs: number;
    newEndAtMs: number;
  }>();
  stretch$ = this.stretch.asObservable();

  private trimBounds = new Subject<UpdateTrimBoundsEvent>();
  trimBounds$ = this.trimBounds.asObservable();

  // UI changing events
  public setItemSelected(layerIds: string[]) {
    this.itemsSelected.next(layerIds);
  }

  public setScroll(msToScroll: number) {
    this.scroll.next(msToScroll);
  }

  public setZoom(delta: 1 | -1, targetPointMs?: number) {
    this.zoom.next({ delta, targetPointMs });
  }

  public setSeek(seekMs: number) {
    this.seek.next(seekMs);
  }

  // Workflow changing events
  public setDrop(changes: DropItemEvent[]) {
    this.drop.next(changes);
  }

  public setStretch(layerId: string, newStartAtMs: number, newEndAtMs: number) {
    this.stretch.next({
      layerId,
      newStartAtMs: Math.floor(newStartAtMs),
      newEndAtMs: Math.floor(newEndAtMs),
    });
  }

  // Trimmer events
  public setTrimBounds(event: UpdateTrimBoundsEvent) {
    this.trimBounds.next(event);
  }
}
