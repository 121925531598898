<div *ngIf="options.caption && visibleControlsCount > 0" class="caption">
  {{ options.caption }}
</div>
<div
  class="controls-layer"
  *ngFor="let layerGroup of layersFormArray.controls; let layerIndex = index; trackBy: trackByLayerFn"
>
  <ng-container *ngFor="let uiControl of uiControls[layerIndex]; trackBy: trackByLayerGroupControlFn">
    <ng-container *ngIf="isFieldVisible(uiControl)">
      <div class="controls-field" [ngSwitch]="uiControl.type">
        <openreel-layer-item-text
          *ngSwitchCase="UiControlTypeEnum.Text"
          [uiControl]="uiControl"
          [control]="getFieldControl(layerGroup, uiControl)"
          [options]="options"
        ></openreel-layer-item-text>
        <openreel-layer-item-image
          *ngSwitchCase="UiControlTypeEnum.Image"
          [uiControl]="uiControl"
          [control]="getFieldControl(layerGroup, uiControl)"
          [disabled]="options.types?.image?.disabled"
          [uploadedClasses]="options.types?.image?.uploadedClasses"
        ></openreel-layer-item-image>
        <openreel-layer-item-video
          *ngSwitchCase="UiControlTypeEnum.Video"
          [uiControl]="uiControl"
          [control]="getFieldControl(layerGroup, uiControl)"
          [disabled]="options.types?.video?.disabled"
          [uploadedClasses]="options.types?.video?.uploadedClasses"
        ></openreel-layer-item-video>
      </div>
    </ng-container>
  </ng-container>
</div>
