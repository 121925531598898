/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * We might need to type it based on Lottie schema, hard but possible.
 * UPDATE: Types are finally here: check ./lottie.interface.ts
 */
import { Animation } from './interfaces/lottie.interface';
import { CreatorExtractedData, ProcessorBase } from './processors/processor.base';
import { ProcessorFill } from './processors/processor.fill';
import { ProcessorImage } from './processors/processor.image';
import { ProcessorText } from './processors/processor.text';

export const CREATOR_KEY = 'creator';
export const NAME_FIELD_KEY = 'nm';
export const EDITABLE_NODE_PREFIX = '#';

export class LottieParser {
  private json: Animation;
  private processed: any;

  private processors: ProcessorBase[] = [new ProcessorText(), new ProcessorFill(), new ProcessorImage()];

  constructor(json: Animation) {
    this.json = json;
  }

  process() {
    const programmaticFields = this.extractAllNodesWithProgrammaticVariables();
    const creatorObj = this.createCreatorObject(programmaticFields);
    this.processed = { ...this.json, [CREATOR_KEY]: creatorObj };
    return this.processed;
  }

  extractAllNodesWithProgrammaticVariables(): any[] {
    const allWithNames = this.extractAllNodesWithNames();
    const filtered = allWithNames.filter((field: any) => this.hasProgrammaticName(field));
    return filtered;
  }

  extractAllNodesWithNames(): any[] {
    const results: any[] = [];
    this.recursivelyFindNodeWithKey(this.json, NAME_FIELD_KEY, results, '');
    return results;
  }

  private createCreatorObject(programmaticFields: any[]) {
    const extracted: CreatorExtractedData = {
      duration: (this.json.op - this.json.ip) * this.json.fr,
      nodes: [],
    };

    for (const node of programmaticFields) {
      let found = false;
      for (const processor of this.processors) {
        if (processor.test(node)) {
          const creatorNode = processor.process(node);
          if (extracted.nodes.find((n) => n.token === creatorNode.token)) {
            throw new Error(`Node tokens must be unique. Found duplicate token: ${creatorNode.token}`);
          }
          extracted.nodes.push(creatorNode);
          found = true;
          break;
        }
      }

      if (!found) {
        throw new Error(`No processor found for node with name: ${node.nm} and type ${node.ty}`);
      }
    }
    return extracted;
  }

  private hasProgrammaticName(field: any) {
    return field[NAME_FIELD_KEY].startsWith(EDITABLE_NODE_PREFIX);
  }

  private recursivelyFindNodeWithKey(object: any, key: string, results: any[], pathSoFar: string) {
    let value;
    Object.keys(object).forEach((k) => {
      if (k === CREATOR_KEY) {
        return false;
      }
      if (key == k) {
        results.push({ ...object, path: pathSoFar });
      }

      let path = pathSoFar;
      path += Array.isArray(object) ? `[${k}]` : (pathSoFar ? '.' : '') + `${k}`;

      if (object[k] && typeof object[k] === 'object') {
        value = this.recursivelyFindNodeWithKey(object[k], key, results, path);
        return value !== undefined;
      }
      return false;
    });
    return value;
  }
}
