<mat-form-field appearance="outline" class="or-select">
  <mat-select
    [(ngModel)]="selection"
    [compareWith]="compareWithFn"
    (selectionChange)="onSelectionChange($event)"
  >
    <mat-option *ngFor="let item of items" [value]="item">
      {{ getItemLabel(item) }}
    </mat-option>
  </mat-select>
</mat-form-field>
