import * as fromActions from '../actions/timelines.actions';
import * as fromProjectActions from '@openreel/creator/app/store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UpdateClipTrimsCommand } from '@openreel/creator/common';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ProjectFacade } from '@openreel/creator/app/store/facades/project.facade';

@Injectable()
export class TrimmerEffects {
  updateClipTrims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateClipTrims),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const { updatedWorkflow } = new UpdateClipTrimsCommand(workflow).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
          }),
        ];
      })
    )
  );

  constructor(private actions$: Actions, private projectFacade: ProjectFacade) {}
}
