import { Component, EventEmitter, Output } from '@angular/core';
import {
  UPLOADER_SERVICE_TOKEN,
  VALIDATOR_SERVICE_TOKEN,
} from '@openreel/ui/openreel-uploader/openreel-uploader.component';
import { UserUploadStateService } from '../../services/user-upload-state.service';
import { UserUploadFileValidatorService } from '../../services/user-upload-file-validator.service';

@Component({
  selector: 'or-user-upload-selector',
  templateUrl: './user-upload-selector.component.html',
  styleUrls: ['./user-upload-selector.component.scss'],
  providers: [
    { provide: UPLOADER_SERVICE_TOKEN, useExisting: UserUploadStateService },
    { provide: VALIDATOR_SERVICE_TOKEN, useExisting: UserUploadFileValidatorService },
  ],
})
export class UserUploadSelectorComponent {
  @Output() uploadStarted = new EventEmitter<number>();
  @Output() uploadFailed = new EventEmitter<void>();

  onUploadStarted(id: number) {
    this.uploadStarted.emit(id);
  }

  onUploadFailed() {
    this.uploadFailed.emit();
  }
}
