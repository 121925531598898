import * as fromActions from '../actions/brand-kits.actions';
import * as fromProjectActions from '../actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProjectFacade } from '../facades/project.facade';
import { BrandKitService } from '@openreel/common';
import { ApplyBrandKitCommand } from '@openreel/creator/common';

@Injectable()
export class BrandKitsEffects {
  applyLatestBrandKitVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applyLatestBrandKitVersion),
      withLatestFrom(this.projectFacade.data$, this.projectFacade.workflow$),
      switchMap(([, data, workflow]) =>
        this.brandKitService.getBrandKitById(data.data.brandKitId).pipe(
          map((brandKit) => {
            const { updatedWorkflow } = new ApplyBrandKitCommand(workflow).run({
              brandKit: brandKit.assets,
            });

            return fromProjectActions.updateProjectWorkflowAPI({
              data: updatedWorkflow,
              updateBrandKitToLatestTimestamp: true,
            });
          })
        )
      )
    )
  );

  ignoreLatestBrandKitVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ignoreLatestBrandKitVersion),
      withLatestFrom(this.projectFacade.workflow$),
      map(([, workflow]) =>
        fromProjectActions.updateProjectWorkflowAPI({
          data: {
            ...workflow,
          },
          updateBrandKitToLatestTimestamp: true,
        })
      )
    )
  );

  constructor(
    private readonly projectFacade: ProjectFacade,
    private readonly brandKitService: BrandKitService,
    private readonly actions$: Actions
  ) {}
}
