export interface AudioGainRange {
  min: number;
  max: number;
  step: number;
}

export const mobileAudioGainRange: AudioGainRange = {
  min: -20,
  max: 20,
  step: 1,
};
export const mobileAudioGainSliderDisplayRange: AudioGainRange = {
  min: -20,
  max: 20,
  step: 5,
};
export const mobileAudioGainLabel = 'dB';

export const webAudioGainRange: AudioGainRange = {
  min: 0,
  max: 2,
  step: 0.1,
};
export const webAudioGainSliderDisplayRange: AudioGainRange = {
  min: 0,
  max: 2,
  step: 0.25,
};
