import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface RecordingHourGraphResponse {
  status: number;
  message: string;
  data: { total_hour: string; hour_used: number; percentage: number };
}

export interface TeamMemberHoursStats {
  user_id: number;
  fullname: string;
  roles: string[];
  user_avatar: string;
  total_video_length: number;
}

@Injectable({
  providedIn: 'root',
})
export class RecordingHourService {
  public urls = {
    recordHour: commonenv.nextGenApiUrl + 'company/statistics/total-video-hours',
    recordHoursByTeamMembers: commonenv.nextGenApiUrl + 'company/statistics/hours-by-team-members',
  };

  constructor(public httpClient: HttpClient) {}

  getRecordingHour() {
    return this.httpClient.get<RecordingHourGraphResponse>(this.urls.recordHour);
  }

  getRecordedHoursByTeamMembers() {
    return this.httpClient.get<TeamMemberHoursStats[]>(this.urls.recordHoursByTeamMembers).toPromise();
  }
}
