import { NewVideoResponse, UserRoleType } from './../../interfaces/interfaces';
import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  NewVideoRequest,
  MultiSendSocketCompleteInfo,
  DirectorSelfRecordData,
  NextgenParticipant,
  DirectorSession,
} from '../../interfaces';
import {
  take,
  filter,
  shareReplay,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import { DirectorApiService } from './director-api/director-api.service';
import { SocketDirectorExtensionRecordingService } from './socket-extensions/socket-director-extension-recording.service';
import { StartVideo, VideoStatus } from '../../interfaces/socket-events';
import { SocketDirectorExtensionControlService } from './socket-extensions/socket-director-extension-control.service';
import { SessionSettingsDto } from '../session/session.interfaces';
import { ISessionService } from '../../interfaces/session-state.interface';
import { VideoSource } from '../../media';
export enum PossibleSessionState {
  CONNECTING,
  IDLE,
  PRERECORDING,
  RECORDING,
}

export interface IDirectorService {
  sessionState$: Observable<SessionStateBase>;
  directorSessionDetails$: Observable<DirectorSession>;

  setSessionTimerValue(identity: string, value: string);
  setSessionRecordingState(identity: string, value: PossibleSessionState);
  startSelfRecord(data: DirectorSelfRecordData);
}

export interface SessionStateBase {
  states?: { [key: string]: { timer: string; state: PossibleSessionState } };
  videoId?: number;
}

export function isStateRecording(state: SessionStateBase, identity: string) {
  return identity
    ? state.states[identity].state === PossibleSessionState.RECORDING
    : Object.keys(state.states).some(
        (identity) =>
          state.states[identity].state === PossibleSessionState.RECORDING
      );
}

const COLLABORATORS = [UserRoleType.Internal, UserRoleType.Collaborator];

@Injectable()
export class DirectorSessionControlsService extends Cleanupable {
  constructor(
    private api: DirectorApiService,
    private socketRecord: SocketDirectorExtensionRecordingService,
    private socketControl: SocketDirectorExtensionControlService,
    @Inject('IDirectorService') private directorService: IDirectorService,
    @Inject('ISessionService') private sessionService: ISessionService
  ) {
    super();
    this.subscriptions.push(
      this.socketRecord
        .listenToRecordingStatus()
        .pipe(
          // we would like to cover the following three cases:
          // - state is RECORDING & identity exists in recordingIdentities => update recording timer
          // - state is RECORDING & identity does not exist in recordingIdentities (director just joined)
          //    => add this identity to recordingIdentities and update recording timer
          // - state is PRERECORDING => change state to RECORDING, add this identity to recordingIdentities and update recording timer
          withLatestFrom(this.directorService.sessionState$)
        )
        .subscribe(([status, state]) => {
          Object.keys(state.states ?? {}).forEach((identity) => {
            if (!isStateRecording(state, identity)) {
              this.directorService.setSessionRecordingState(
                status.from,
                PossibleSessionState.RECORDING
              );
            } else {
              this.directorService.setSessionTimerValue(status.from, status.data.time);
            }
          });
        })
    );

    this.subscribeRecording();
  }
  public collaborators = COLLABORATORS;

  grid = false;

  public requestedControl = false;
  public isCollaborator$ = this.sessionService.myParticipant$.pipe(
    filter((me) => me != null),
    map((me) => COLLABORATORS.includes(me.role)),
    shareReplay()
  );

  public isWatcher$ = this.sessionService.myParticipant$.pipe(
    filter((me) => me != null),
    map((me) => UserRoleType.Watcher === me.role),
    shareReplay()
  );

  public canAccessChat$ = this.isCollaborator$;
  public isInternal$ = this.sessionService.myParticipant$.pipe(
    filter((me) => me != null),
    map((me) => me.role === UserRoleType.Internal)
  );

  public canAccessPrivateChat$ = this.sessionService.myParticipant$.pipe(
    filter((me) => me != null),
    map((me) => COLLABORATORS.includes(me.role) && me.isPinned),
    shareReplay()
  );
  public canManipulateClips$ = this.isCollaborator$;
  public canAccessTeleprompter$ = this.isCollaborator$;
  public canManipulateSession$ = this.isCollaborator$;

  public canRecord(identity?: string) {
    return combineLatest([
      this.isCollaborator$,
      this.directorService.sessionState$,
    ]).pipe(
      map(
        ([isCollab, state]) =>
          isCollab &&
          (identity
            ? state.states[identity].state === PossibleSessionState.IDLE
            : Object.keys(state.states).some(
                (key) => state.states[key].state === PossibleSessionState.IDLE
              ))
      )
    );
  }

  public canStopRecording(identity?: string) {
    return combineLatest([
      this.isCollaborator$,
      this.directorService.sessionState$,
    ]).pipe(
      map(([isCollab, state]) => isCollab && isStateRecording(state, identity))
    );
  }

  public ifCollaborator(callback: () => void) {
    return this.isCollaborator$
      .pipe(
        take(1),
        filter((isCollab) => isCollab)
      )
      .subscribe(callback);
  }

  togglePinStatus(id: number, status: boolean, to: string) {
    this.socketControl.sendTogglePinStatus(id, status, to);
  }

  // eslint-disable-next-line max-lines-per-function
  async startRecording(
    recordingParticipants: (NextgenParticipant & {
      videoName: string;
      videoSource: VideoSource;
    })[],
    finishedIdentities$: Subject<MultiSendSocketCompleteInfo>,
    sessionSettings: SessionSettingsDto,
    directors: DirectorSelfRecordData[],
    individual: boolean
  ) {
    const videoInfo: NewVideoResponse =
      await this.initializeRemoteRecordingState(
        recordingParticipants,
        directors
      ).toPromise();
    const beforeRecordListen: { [index: string]: '1' | '0' } = {};
    const videoIdObj: { [index: string]: number } = {};
    for (const video of videoInfo.videos) {
      beforeRecordListen[video.identity] = '1';
      videoIdObj[video.identity] = video.ovra_session_videos_id;
    }

    const recordStartRequests = recordingParticipants.map(
      (orParticipant, index) => {
        const ret: StartVideo = {
          EmployeeID: '123456',
          VideoNameObj: {
            [orParticipant.identity]: videoInfo.videos[index].video_name,
          },
          VideoSize: orParticipant.videoProperties.resolution.toString(),
          admin_talkback: 0,
          before_record_listen_audio: beforeRecordListen,
          file_size: 0,
          fps: orParticipant.videoProperties.fps,
          resolution: orParticipant.videoProperties.resolution,
          sound: 1,
          start_stop: 0,
          videoSource: orParticipant.videoSource,
          status: VideoStatus.RECORDING,
          identity: orParticipant.identity,
          timer: sessionSettings.recording_countdown_enabled
            ? sessionSettings.countdown_value
            : 0,
          uploadDuringRecording: sessionSettings.upload_during_recording,
          videoIdObj: {
            [orParticipant.identity]:
              videoInfo.videos[index].ovra_session_videos_id,
          },
        };
        return ret;
      }
    );
    const recordingIdentities = recordingParticipants.map(
      (part) => part.identity
    );
    recordingIdentities.forEach((identity) =>
      this.directorService.setSessionRecordingState(
        identity,
        PossibleSessionState.PRERECORDING
      )
    );
    try {
      this.socketRecord.startRecording(
        recordStartRequests,
        finishedIdentities$,
        individual
      );
      recordingIdentities.forEach((identity) =>
        this.directorService.setSessionRecordingState(
          identity,
          PossibleSessionState.RECORDING
        )
      );
      //director start recording
      if (directors.length > 0) {
        const selfRecordData = directors[0];
        selfRecordData.videoId = videoIdObj[selfRecordData.identity];
        selfRecordData.countdown = sessionSettings.recording_countdown_enabled
          ? sessionSettings.countdown_value
          : 0;
        selfRecordData.uploadDuringRecording =
          sessionSettings.upload_during_recording || [];
        this.directorService.startSelfRecord(selfRecordData);
      }
    } catch (err) {
      recordingIdentities.forEach((identity) =>
        this.directorService.setSessionRecordingState(
          identity,
          PossibleSessionState.RECORDING
        )
      );
    }
  }

  private initializeRemoteRecordingState(
    recordingParticipants: (NextgenParticipant & { videoName: string })[],
    directors: DirectorSelfRecordData[]
  ) {
    return this.directorService.directorSessionDetails$.pipe(
      map((session) => {
        const request: NewVideoRequest = {
          session_id: session.session_id,
          videos: recordingParticipants.map((orParticipant) => ({
            admin_ovra_id: 0,
            bit_rate: '',
            device_name: orParticipant.deviceName,
            fps: orParticipant.videoProperties.fps.toString(),
            identity: orParticipant.identity,
            resolution: orParticipant.videoProperties.resolution.toString(),
            video_name: orParticipant.videoName,
            video_type: 0,
          })),
        };
        if (directors.length > 0) {
          const selfRecord = directors[0];
          request.videos.push({
            admin_ovra_id: 0,
            bit_rate: '',
            device_name: selfRecord.deviceName,
            fps: selfRecord.fps,
            identity: selfRecord.identity,
            resolution: selfRecord.resolution.toString(),
            video_name: selfRecord.videoName,
            video_type: 1,
          });
        }

        return request;
      }),
      take(1),
      switchMap((request) => this.api.createNewVideo(request))
    );
  }
  async stopRecording(
    finishedIdentities$: Subject<MultiSendSocketCompleteInfo>,
    identity?: string
  ) {
    this.directorService.sessionState$
      .pipe(
        filter((state) => isStateRecording(state, identity)),
        take(1)
      )
      .subscribe((state) => {
        this.socketRecord.stopRecording(
          { EmployeeID: '123456', status: VideoStatus.RECORDED, videoid: 0 },
          identity ? [identity] : Object.keys(state.states),
          finishedIdentities$
        );
        if (identity) {
          this.directorService.setSessionRecordingState(
            identity,
            PossibleSessionState.IDLE
          );
          this.directorService.setSessionTimerValue(identity, '00:00:00');
        } else {
          Object.keys(state.states).forEach((i) => {
            this.directorService.setSessionRecordingState(
              i,
              PossibleSessionState.IDLE
            );
            this.directorService.setSessionTimerValue(i, '00:00:00');
          });
        }
      });
  }

  subscribeRecording() {
    this.subscriptions.push(
      this.socketRecord
        .listenToRecordingStart()
        .pipe(withLatestFrom(this.directorService.directorSessionDetails$))
        .subscribe(([req, session]) => {
          if (req.session.toString() === session.session_id.toString()) {
            this.directorService.setSessionRecordingState(
              req.from,
              PossibleSessionState.RECORDING
            );
          }
        }),
      this.socketRecord
        .listenToRecordingStop()
        .pipe(withLatestFrom(this.directorService.directorSessionDetails$))
        .subscribe(([req, session]) => {
          if (req.session.toString() === session.session_id.toString()) {
            this.directorService.setSessionRecordingState(
              req.from,
              PossibleSessionState.IDLE
            );
            this.directorService.setSessionTimerValue(req.from, '00:00:00');
          }
        })
    );
  }
}
