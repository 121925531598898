import { createAction, props } from '@ngrx/store';

import { RenderProgress, TranscribeProgress, WorkflowProjectDto, WorkflowProjectVideoDto } from '@openreel/common';
import { WorkflowDataDto } from '@openreel/creator/common';
import { ProjectUpdateSource } from '../interfaces/project.interface';

const actionType = '[Project]';

export const loadProjectAPI = createAction(`${actionType} Load API`, props<{ projectId: number }>());

export const loadProjectSuccess = createAction(`${actionType} Load Success`, props<{ data: WorkflowProjectDto }>());

export const loadProjectFinished = createAction(`${actionType} Project Load Finished`);

export const loadProjectFailure = createAction(`${actionType} Load Failure`);

export const updateProjectWorkflowAPI = createAction(
  `${actionType} Update Project Workflow API`,
  props<{
    data: WorkflowDataDto;
    updateSource?: ProjectUpdateSource;
    updateBrandKitToLatestTimestamp?: boolean;
  }>()
);

export const updateProjectSuccess = createAction(`${actionType} Update Success`, props<{ data: WorkflowProjectDto }>());

export const updateProjectFailure = createAction(`${actionType} Update Failure`);

export const cleanupState = createAction(`${actionType} Cleanup State`);

export const updateProjectNameAPI = createAction(`${actionType} Update Name API`, props<{ name: string }>());

export const updateProjectLayout = createAction(
  `${actionType} Update Section Layout`,
  props<{ templateLayoutId: number }>()
);

// Render actions
export const triggerRenderProject = createAction(`${actionType} Trigger Render`);

export const renderProject = createAction(`${actionType} Render`);

export const renderProjectAccepted = createAction(
  `${actionType} Render Accepted`,
  props<{ videoData: WorkflowProjectVideoDto }>()
);

export const cancelProjectRender = createAction(`${actionType} Cancel Render`);

export const cancelProjectRenderAccepted = createAction(`${actionType} Cancel Render Accepted`);

export const setRenderProgress = createAction(
  `${actionType} Set Render Progress`,
  props<{ renderProgress: RenderProgress }>()
);

export const setTranscribeProgress = createAction(
  `${actionType} Set Transcribe Progress`,
  props<{ transcribeProgress: TranscribeProgress }>()
);

export const toggleHasCaptions = createAction(`${actionType} Toggle Has Captions`, props<{ hasCaptions: boolean }>());
