import { Injectable } from '@angular/core';

@Injectable()
export abstract class INativeLibService {
  abstract getOsName(): Promise<string>;
  abstract getAppVersion(): Promise<string>;
  abstract getCpuSpeedGHz(): Promise<number>;
  abstract getCpuCores(): Promise<number>;
  abstract getCpuUsage(): Promise<number>;
  abstract getAppCpuUsage(): Promise<number>;
  abstract getMemoryGB(): Promise<number>;
  abstract getMemoryUsage(): Promise<number>;
  abstract getMemoryAppUsage(): Promise<number>;
}
