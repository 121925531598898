<div class="container">
  <ng-container *ngIf="video">
    <div class="video-container">
      <openreel-player
        [video$]="video$"
        [options]="{ controls: true, fluid: true }"
        [trimming]="trimming$ | async"
      ></openreel-player>
    </div>
    <div class="trimmer-container">
      <openreel-trimmer
        [video$]="video$"
        (trimmingChange)="onTrimmingChange($event)"
      ></openreel-trimmer>
    </div>
  </ng-container>
  <ng-container *ngIf="!video">
    <h3 class="no-video-text">No video available</h3>
  </ng-container>
</div>
