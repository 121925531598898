import { createSelector } from '@ngrx/store';
import { selectSelfRecordFeatureState, SelfRecordFeatureState } from '..';
import * as fromReducer from '../reducers/self-record-teleprompter.reducer';

export const selectTeleprompterState = createSelector(
  selectSelfRecordFeatureState,
  (state: SelfRecordFeatureState) =>
    state ? state[fromReducer.key] : undefined
);

export const selectScripts = createSelector(
  selectTeleprompterState,
  (streamState) => streamState.scripts
);

export const selectStatus = createSelector(
  selectTeleprompterState,
  (streamState) => streamState.status
);
