import * as fromStore from './store';

import { AppRoutingModule, routableComponents } from './app-routing.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { MixpanelService } from './analytics/mixpanel.service';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@openreel/creator/app/core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RecordingModule } from './common/recording/recording.module';
import { SharedModule } from './shared/shared.module';
import { OpenreelAlertModule } from '@openreel/ui/openreel-alert';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { of } from 'rxjs';

const appModules = [RecordingModule, SharedModule, OpenreelAlertModule];

@NgModule({
  declarations: [AppComponent, ...routableComponents],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(fromStore.rootReducers),
    EffectsModule.forRoot(fromStore.effects),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    HttpClientModule,
    ToastrModule.forRoot(),
    CoreModule,
    ...appModules,
    // Has to be last for catch all route to work
    AppRoutingModule,
  ],
  providers: [
    ToastrService,
    {
      provide: 'ISessionService',
      useValue: {
        isSubject$: of(false),
      },
    },
    MixpanelService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
