<mat-form-field class="control-email-list" appearance="outline">
    <mat-chip-list matInput #chipList [formControl]="formCtrl">
        <div class="email-chip-list" #chipWrapper>
            <mat-chip *ngFor="let email of emailList" (removed)="remove(email)">
                {{email}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <input class="email-ctrl" *ngIf="isMoreEmailsAllow()" placeholder="Enter emails..."
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
        </div>
    </mat-chip-list>

    <mat-error *ngFor="let validation of validation_messages.email">
        <mat-error class="email-error-message" *ngIf="formCtrl.hasError(validation.type) && (formCtrl.dirty || formCtrl.touched)">{{validation.message}}</mat-error>
    </mat-error>
</mat-form-field>

