import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, flatMap, take, switchMap } from 'rxjs/operators';
import { GlobalService } from '../services/global/global.service';
import { AuthService } from '../services/auth/auth.service';
import { UserRoleType } from '../interfaces';

@Injectable()
export class AddTokenInterceptor<T> implements HttpInterceptor {
  constructor(private authService: AuthService, private global: GlobalService) {}

  private subjectFlow$ = (request: HttpRequest<T>, next: HttpHandler) =>
    this.authService.token$.pipe(
      take(1),
      flatMap((token) => {
        let authReq = request;
        if (token) {
          authReq = request.clone({
            setHeaders: {
              'device-token': token,
            },
          });
        }
        return next.handle(authReq);
      })
    );

  private authFlow$ = (request: HttpRequest<T>, next: HttpHandler) =>
    this.authService.token$.pipe(
      first(),
      flatMap((token) => {
        let authReq = request;
        if (token) {
          const headers = {};
          if (this.authService.role !== UserRoleType.Internal) headers['email-token'] = token;
          else headers['access-token'] = token;

          authReq = request.clone({
            setHeaders: headers,
          });
        }
        return next.handle(authReq);
      })
    );

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (
      request.headers.has('device-token') ||
      request.headers.has('email-token') ||
      request.headers.has('access-token')
    ) {
      return next.handle(request);
    }
    return this.authService.role$.pipe(
      take(1),
      switchMap((role) =>
        role === UserRoleType.Subject ? this.subjectFlow$(request, next) : this.authFlow$(request, next)
      )
    );
  }
}
