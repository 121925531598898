import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[openreelFallback]',
})
export class ImgFallbackDirective {
  @Input() openreelFallback: string;

  constructor(private eRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.openreelFallback || 'https://via.placeholder.com/200';
  }
}
