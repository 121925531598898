import { Cleanupable } from './cleanupable';
import { FormGroup } from 'ngx-strongly-typed-forms';

export abstract class FormHolder<T> extends Cleanupable {
  form: FormGroup<T>;
  successMessage?: string;

  constructor() {
    super();
  }

  async submit() {
    // it is important to save value of form.valid to local variable before disabling it,
    // otherwise you get false no matter what
    const isFormValid = this.form.valid;
    this.form.disable();
    if (isFormValid) {
      await this.cancelablePromise(this.doSubmit());
    }
    this.form.enable();
  }

  protected setValue(value: T) {
    this.form.setValue(value);
  }

  protected abstract doSubmit(): Promise<void>;
}
