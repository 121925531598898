export const CHATTING = 'chatting';
export const TELEPROMPTER = 'teleprompter';
export const CAPTURE_SESSION_CLIPS = 'capture-session-clips';
export const CAPTURE_AUDIO_METER = 'capture-audio-meter';
export const UPLOAD_WHILE_RECORDING = 'upload-while-recording';
export const ADMIT_USERS = 'admit-users';
export const SESSION_STREAM_TYPE = 'stream-type';
export const LIVE_STREAM_DESTINATIONS = 'live-stream-destinations';
export const LIVE_STREAM_DESTINATION_FACEBOOK = 'live-stream-destination-facebook';
export const LIVE_STREAM_DESTINATION_YOUTUBE = 'live-stream-destination-youtube';

export const SSO_SETUP_SCREEN = 'sso-setup-screen';

export const WORKFLOWS_CREATOR = 'workflows-creator';
export const WORKFLOWS_TEMPLATE = 'workflows-template';
export const WORKFLOWS_CAPTIONS = 'workflows-captions';
export const WORKFLOWS_BRAND_KITS = 'workflows-brand-kits';
export const WORKFLOWS_SELF_RECORDING = 'workflows-self-recording';
export const WORKFLOWS_INSTANT_WIZARD = 'workflows-instant-wizard';
export const WORKFLOWS_LAYOUTS = 'workflows-layouts';
export const WORKFLOWS_ADDITIONAL_FONTS = 'workflows-additional-fonts';
export const WORKFLOWS_TEMPLATE_BUILDER = 'workflows-template-builder';
export const USER_PILOT = 'user-pilot';
export const HOSTING = 'hosting';
export const USER_UPLOAD = 'user-upload';
export const TRANSCRIPTION = 'transcription';
export const CAPTURE_ENABLE_CONVERT_OLDGEN_SESSIONS = 'capture-enable-convert-oldgen-sessions';
export const CAPTURE_ARCHIVE_PROJECTS = 'capture-archive-projects';
export const CAPTURE_ARCHIVE_CLIPS = 'capture-archive-clips';
export const CAPTURE_UGC_SESSION_CREATION = 'ugc-session-creation';
export const CAPTURE_UGC_SUBJECT = 'ugc-subject';
export const CAPTURE_EXTERNAL_USER_LOGOUT = 'external-user-logout';
export const CAPTURE_OTP_2FA = 'otp-2fa';
export const CAPTURE_CONTACT_UPDATE = 'contact-update';
export const CAPTURE_CREATE_TEAM = 'create_team';
export const VIDEO_ENCODE_TYPE = 'video-encode-type';
