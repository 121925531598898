import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@openreel/common/material';
import { OpenreelCommonDirectivesModule } from '@openreel/common/directives/openreel-common-directives.module';
import { OpenreelUploaderComponent } from './openreel-uploader.component';

@NgModule({
  declarations: [OpenreelUploaderComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, OpenreelCommonDirectivesModule],
  exports: [OpenreelUploaderComponent],
})
export class OpenreelUploaderModule {}
