import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

// TODO: Use https://material.angular.io/guide/creating-a-custom-form-field-control
@Component({
  selector: 'openreel-date-picker',
  templateUrl: './ordatepicker.component.html',
  styleUrls: ['./ordatepicker.component.css'],
})
export class OrDatePickerComponent {
  @Input() labelname: string;
  @Output() onDateChange = new EventEmitter<MatDatepickerInputEvent<Date>>();

  getDate(event: MatDatepickerInputEvent<Date>) {
    this.onDateChange.emit(event);
  }
}
