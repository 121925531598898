import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'apps/desktop/src/app/app-state/root-state';
import { selectDirectorUserDetails } from 'apps/desktop/src/app/app-state/director/director.selector';
import { first, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const store: Store<RootState> = this.injector.get(Store);
    if (store) {
      combineLatest([
        store.select(selectDirectorUserDetails),
        store.select((state) => state.session),
      ])
        .pipe(
          first(),
          tap(([directorUser, session]) => {
            if (directorUser) {
              Sentry.setUser({
                id: directorUser.id.toString(),
                email: directorUser.email,
                username: directorUser.fullname,
              });
            }
            if(session) {
              Sentry.setTag('session_id', session.sessionId);
              Sentry.setExtra('session', session);
            }
            Sentry.captureException(error.originalError || error);
          })
        )
        .subscribe();
    } else {
      Sentry.captureException(error.originalError || error);
    }

    console.error('Captured error from sentry: ', error);
    throw error;
  }
}
