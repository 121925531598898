export enum KeepCopyValues {
  true = '1',
  false = '0',
}

export interface S3IntegrationStatus {
  connected: boolean;
  keepCopy: boolean;
}

export interface Destination {
  id: number;
  name: string;
}

export interface MyDestination {
  id: number;
  destinationId: number;
  destination: Destination;
  integrationName: string;
  disabledAt: Date;
}

export interface DestinationAuthUrlResponse {
  url: string;
}
