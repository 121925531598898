import * as Handlebars from 'handlebars';

import { Observable, of } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import { AssetFetchService } from './asset-fetch.service';
import { Asset } from '@openreel/creator/common';
import { Injectable } from '@angular/core';
import { TemplatingBaseService } from '../../../../../../libs/ui/src/components/openreel-cue-player';

@Injectable({ providedIn: 'root' })
export class FileTemplatingService extends TemplatingBaseService {
  private readonly templateCache: {
    [key: string]: HandlebarsTemplateDelegate;
  } = {};

  constructor(private readonly assetFetchService: AssetFetchService) {
    super();
  }

  apply<T>(fileAsset: Asset, values: unknown): Observable<T> {
    return of(fileAsset).pipe(
      concatMap((asset) => this.getCachedOrFetch(asset)),
      map((template) => {
        try {
          return JSON.parse(template(values)) as T;
        } catch (error) {
          console.error('fileAsset', fileAsset);
          console.error('values', values);
          console.error(template(values));
          throw error;
        }
      })
    );
  }

  private getCachedOrFetch(fileAsset: Asset): Observable<HandlebarsTemplateDelegate> {
    if (fileAsset.id in this.templateCache) {
      return of(this.templateCache[fileAsset.id]);
    } else {
      return this.assetFetchService.fetchContentAsText(fileAsset.file.provider, fileAsset.file.path).pipe(
        map((content) => Handlebars.compile(content)),
        tap((template) => (this.templateCache[fileAsset.id] = template))
      );
    }
  }
}
