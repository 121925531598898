import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { min, max } from 'lodash-es';
import { NextgenParticipant } from '../../../interfaces/nextgen-participant.interface';
import { ActiveConversationDetails } from 'apps/desktop/src/app/app-state/session/chat/interfaces/chat-store.interface';
import { Conversation } from 'libs/common/src/components/chat/conversation.interface';
import { FlatChatService } from './flat-chat.service';
import { noWhitespaceValidator } from 'libs/common/src/form.util';

const TOP_SCROLL_GAP = 40;

@Component({
  selector: 'openreel-flat-chat',
  templateUrl: './flat-chat.component.html',
  styleUrls: ['./flat-chat.component.scss'],
})
export class FlatChatComponent implements OnChanges {
  @Input() maxConversations = 3;
  @Input() amIPinned = true;
  @Input() isLoading: boolean;
  @Input() activeConversationDetails: ActiveConversationDetails[];
  @Input() selectedConversation: Conversation;
  @Input() allChatOptions: { id: number; name: string }[];
  @Input() myId: string;
  @Input() typingParticipantNames: string[];

  @ViewChild('messagesHolder') messagesHolder: ElementRef<HTMLDivElement>;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  public selectedTabIndex: number;

  constructor(private flatChatService: FlatChatService) {}

  form = new FormGroup({
    message: new FormControl(undefined, [
      Validators.required,
      noWhitespaceValidator,
    ]),
  });

  groupConversation: Partial<NextgenParticipant> = {
    name: 'Everyone',
    loginId: 0,
  };

  ngOnChanges(changes: SimpleChanges) {
    // set the selected index based on the active conversation and the list of conversations
    if (changes.selectedConversation || changes.activeConversationDetails) {
      const selectedIndex = this.activeConversationDetails?.findIndex(
        (acd) => acd.conversation.id === this.selectedConversation?.id
      );
      if (selectedIndex >= 0) {
        this.selectedTabIndex = selectedIndex;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.tabGroup?._tabHeader as any)?._scrollToLabel(selectedIndex);
      }
    }

    if (changes.selectedConversation) {
      this.adjustChatScroll(
        changes.selectedConversation.previousValue,
        changes.selectedConversation.currentValue
      );
    }
  }

  onSelectedTabChange(event: { index: number }) {
    const selectedId =
      this.activeConversationDetails[event.index].conversation.id;
    this.flatChatService.handleSelectConversation(selectedId);
  }

  sendMessage() {
    if (this.form.valid) {
      this.flatChatService.sendMessage(
        this.form.value.message,
        this.selectedConversation.id
      );
      this.form.reset();
    }
  }

  markConversationRead() {
    this.flatChatService.markConversationAsRead(this.selectedConversation?.id);
  }

  selectedConversationChange(participantId: number) {
    this.flatChatService.handleSelectDropownparticipant(
      participantId,
      this.allChatOptions.find((co) => co.id === participantId).name
    );
  }

  deactivateConversation(conversationDetails: ActiveConversationDetails) {
    this.flatChatService.handleRemoveActiveConversation(
      conversationDetails.conversation.id
    );
  }

  textChange() {
    this.flatChatService.handleTextChange();
  }

  paginateChat() {
    if (this.messagesHolder.nativeElement.scrollTop === 0) {
      this.flatChatService.paginateChat();
    }
  }

  private adjustChatScroll(
    previousConversation: Conversation,
    currentConversation: Conversation
  ) {
    if (
      this.isScrolledToBottom() ||
      previousConversation?.id !== currentConversation?.id
    ) {
      this.setScrollPosition('bottom');
    } else if (currentConversation) {
      const currentCreatedAts = currentConversation?.messages?.map(
        (c) => c.createdAt
      );
      const prevCreatedAts = previousConversation?.messages?.map(
        (c) => c.createdAt
      );
      const maxNew = max(currentCreatedAts);
      const maxPrev = max(prevCreatedAts);
      const minNew = min(currentCreatedAts);
      const minPrev = min(prevCreatedAts);
      if (!maxPrev || maxNew > maxPrev) {
        // newer messages were added
        this.setScrollPosition('bottom');
      } else if (minNew < minPrev) {
        // older messages were added
        this.setScrollPosition('top');
      }
    }
  }

  private isScrolledToBottom() {
    const nativeElement = this.messagesHolder?.nativeElement;
    if (!nativeElement) {
      return false;
    }
    return (
      Math.abs(
        nativeElement.scrollTop -
          (nativeElement.scrollHeight - nativeElement.offsetHeight)
      ) <= 1
    );
  }

  private setScrollPosition(type: 'bottom' | 'top') {
    // timeout needed because the new message is not inserted into DOM yet
    setTimeout(() => {
      const value =
        type === 'bottom'
          ? this.messagesHolder.nativeElement.scrollHeight
          : TOP_SCROLL_GAP;
      this.messagesHolder.nativeElement.scrollTo(0, value);
    }, 0);
  }
}
