import { AdvancedMediaSettings } from '../interfaces/advanced-media-settings.interface';
import {
  AdvancedMediaTrackConstraints,
  AdvancedMediaTrackSupportedConstraints,
} from '../interfaces/advanced-mediatrack-constraints.interface';
import { AudioDevice } from '../interfaces/audio-device.interface';
import { VideoDevice } from '../interfaces/video-device.interface';
import { VideoSource } from '../interfaces/video-source.interface';
/**
 * To be deprecated
 */
declare global {
  interface MediaDevices {
    getDisplayMedia?(
      constraints?: MediaStreamConstraints
    ): Promise<MediaStream>;
  }
}

export const getSupportedConstraints = (): AdvancedMediaTrackSupportedConstraints =>
  navigator.mediaDevices.getSupportedConstraints();

export const getTrackSettings = (
  track: MediaStreamTrack
): AdvancedMediaSettings =>
  track?.getSettings() || ({} as AdvancedMediaSettings);

export const stopStream = (stream: MediaStream) => {
  if (!stream) {
    return;
  }

  stream.getTracks().forEach((track) => {
    track.stop();
  });
};

export const openAudioStream = async (
  deviceId?: string
): Promise<MediaStream> => {
  const audio = deviceId ? { deviceId } : true;
  return await navigator.mediaDevices.getUserMedia({
    audio,
    video: false,
  });
};


export const startStream = async (
  constraints?: MediaStreamConstraints
): Promise<MediaStream> =>
  await navigator.mediaDevices.getUserMedia(constraints);

export const startScreenStream = async (
  constraints?: MediaStreamConstraints
): Promise<MediaStream> =>
  await navigator.mediaDevices.getDisplayMedia(constraints);

// To override with 240 for debugging:
// await navigator.mediaDevices.getUserMedia({
//   audio: false,
//   video: {
// height: 240
// }});
export const supportsScreenSharing = (): boolean =>
  !!navigator.mediaDevices.getDisplayMedia;

export const openScreenSharingStream = async (
  constraints: MediaTrackConstraints | boolean = {}
): Promise<MediaStream> => {
  try {
    return await navigator.mediaDevices.getDisplayMedia({
      audio: false,
      video: true,
    });
  } catch (error) {
    console.log(error);
  }
};



export const applyConstraints = async (
  track: MediaStreamTrack,
  deviceId: string,
  constraints: AdvancedMediaTrackConstraints = {}
): Promise<void> => {
  console.log('[applyConstraints]', constraints);
  return await track.applyConstraints({
    deviceId,
    ...constraints,
  });
};

export const filterDevices = (devices) => {
  const filtered = devices.filter(
    (device) => device.deviceId && device.label
  );
  const audioDevices: AudioDevice[] = filtered
    .filter((device) => device.kind === 'audioinput')
    .map(({ deviceId, label }) => ({
      id: deviceId,
      name: label,
    }));
  const videoDevices: VideoDevice[] = filtered
    .filter((device) => device.kind === 'videoinput')
    .map(({ deviceId, label }) => ({
      id: deviceId,
      name: label,
      source: VideoSource.WEBCAM,
    }));

  return {audioDevices, videoDevices};
}
