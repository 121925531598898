import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService, FeatureFlaggingService, ROUTE_DASHBOARD } from '@openreel/common';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../ui/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsAllowedOnAccountGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly featureFlaggingService: FeatureFlaggingService
  ) {}

  canActivate() {
    this.featureFlaggingService.checkAccountFeatureFlags();

    return this.authService.userDetails$.pipe(
      filter((user) => !!user),
      take(1),
      map((userDetails) => {
        if (!userDetails.site_users.creatorAllowed) {
          window.location.href = `${environment.nextGenAppUrl}#/${ROUTE_DASHBOARD}`;
          return false;
        }

        return true;
      })
    );
  }
}
