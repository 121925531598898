import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InvitationsService } from '../../services/invitations/invitations.service';
import { HostingService, HostingHubService } from '../services';
import { HostableTypes } from '../constants';
import { CreateHostableComponent } from './create-hostable/create-hostable.component';
import { CreateHubComponent } from './create-hub/create-hub.component';
import { HostingShareComponent } from './hosting-share/hosting-share.component';
import { AddToHubComponent } from './add-to-hub/add-to-hub.component';
import { HostingConfirmComponent } from './hosting-confirm/hosting-confirm.component';

@Injectable({providedIn: 'root'})
export class HostingDialogService {
    constructor(
        private dialog: MatDialog,
        private hostingHubService: HostingHubService,
        private hostingService: HostingService,
        private invitationService: InvitationsService,
    ) {}

    openCreateHostable(sourceType: HostableTypes, sourceId: number, title = '') {
        return this.dialog.open(CreateHostableComponent, {
            width: '405px',
            disableClose: true,
            data: {
                id: sourceId,
                title,
                type: sourceType,
            },
        }).afterClosed().pipe(
            switchMap(res => res ? of(res) : EMPTY),
        );
    }

    openAddVideoToHubs(videoId: number) {
        return this.dialog.open(AddToHubComponent, {
            width: '405px',
            height: '410px',
            data: { videoId },
        }).afterClosed().pipe(
            switchMap(result => {
                if (!result || (!result.checked && !result.unchecked)) {
                    return EMPTY;
                }
                return this.hostingHubService.linkUnlinkHubs(videoId, result);
            }),
        );
    }

    openCreateHub() {
        return this.dialog.open(CreateHubComponent, {
            width: '405px',
        }).afterClosed().pipe(
            switchMap(result => {
                if(!result || !result.hub || !result.permission) 
                    return EMPTY;
                return this.hostingService.createHub(result.hub, result.permission, result.slug);
            }),
        );
    }

    private openShare(type: 'video' | 'hub', id: number, slug: string) {
        return this.dialog.open(HostingShareComponent, {
            width: '450px',
            data: {type, id, slug},
            autoFocus: false,
        }).afterClosed().pipe(
            switchMap(data => data ? this.invitationService.invite2Hosting({
                email: data.email,
                message: data.message,
                type,
                id,
            }) : EMPTY),
        );
    }

    openShareVideo(id: number, slug: string) {
        return this.openShare('video', id, slug);
    }

    openShareHub(id: number, slug: string) {
        return this.openShare('hub', id, slug);
    }

    private openConfirm(header: string, text: string) {
        return this.dialog.open(HostingConfirmComponent, {
            width: '405px',
            data: {
                header, 
                text,
            },
        }).afterClosed();
    }

    openDeleteHub(id: number, title: string) {
        return this.openConfirm('Confirm Delete', `Do you want to delete '${title}' Hub?`).pipe(
            switchMap(confirm => confirm ? this.hostingService.deleteHub(id) : EMPTY),
        );
    }

    openDeleteVideo(id: number, title: string) {
        return this.openConfirm('Confirm Delete', `Do you want to delete '${title}' clip?`).pipe(
            switchMap(confirm => confirm ? this.hostingService.deleteVideo(id) : EMPTY),
        );
    }

    openCancelVideo(id: number, title: string) {
        return this.openConfirm('Confirm Remove', `Do you want to remove '${title}' from the queue?`).pipe(
            switchMap(confirm => confirm ? this.hostingService.cancel(id) : EMPTY),
        );
    }

    openGenericCancel() {
        return this.openConfirm('Confirm Cancel', 'Are you sure you want to cancel?');
    }

    openConfirmResetThumb() {
        return this.openConfirm('Confirm Reset Thumbnail', 'Are you sure you want to reset your thumbnail?');
    }

    openConfirmDelteCta() {
        return this.openConfirm('Confirm Delete Call to Action', 'Are you sure you want to delte your call to action?');
    }
}