import { NgModule } from '@angular/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { AspectRatioDirective } from './aspect-ratio.directive';
import { AutoresizeDirective } from './auto-resize.directive';
import { ImgFallbackDirective } from './img-fallback.directive';
import { PhoneNumberDirective } from './phone-number.directive';
import { FeatureFlagDirective } from './feature-flag.directive';
import { FeatureEnabledDirective } from './feature-enabled.directive';
import { VirtualInfiniteScrollDirective } from './infinite-scroll.directive';
import { AssetImageDirective } from './asset-image.directive';
import { AssetVideoDirective } from './asset-video.directive';
import { AutofocusDirective } from './autofocus.directive';
import { SlugFieldDirective } from './slug-field.directive';
import { DragOverDirective } from './drag-over.directive';
import { NumberInputDirective } from './number-input.directive';
import { DragStartDirective } from './drag-start.directive';
import { VideoBlobDirective } from './video-blob.directive';

@NgModule({
  declarations: [
    ImgFallbackDirective,
    AspectRatioDirective,
    PhoneNumberDirective,
    AutoresizeDirective,
    FeatureFlagDirective,
    FeatureEnabledDirective,
    VirtualInfiniteScrollDirective,
    AssetImageDirective,
    AssetVideoDirective,
    AutofocusDirective,
    SlugFieldDirective,
    DragOverDirective,
    NumberInputDirective,
    DragStartDirective,
    VideoBlobDirective,
  ],
  imports: [AngularResizedEventModule],
  exports: [
    ImgFallbackDirective,
    AspectRatioDirective,
    PhoneNumberDirective,
    AutoresizeDirective,
    FeatureFlagDirective,
    FeatureEnabledDirective,
    VirtualInfiniteScrollDirective,
    AssetImageDirective,
    AssetVideoDirective,
    AutofocusDirective,
    SlugFieldDirective,
    DragOverDirective,
    NumberInputDirective,
    DragStartDirective,
    VideoBlobDirective,
  ],
})
export class OpenreelCommonDirectivesModule {}
