import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/self-record-teleprompter.actions';
import { SelfRecordTeleprompterFeatureState } from '../interfaces/self-record-teleprompter.interfaces';

export const key = 'teleprompter';

export const initialState: SelfRecordTeleprompterFeatureState = {
  scripts: [],
  status: 'stopped',
};

const reducer = createReducer(
  initialState,
  on(fromActions.cleanup, () => ({
    ...initialState,
  })),
  on(fromActions.refreshScriptsSuccess, (state, { scripts }) => ({
    ...state,
    scripts,
  })),
  on(fromActions.updateStatus, (state, { status }) => ({
    ...state,
    status,
  })),
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
