import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AudioDevice, RecordingType, VideoDevice } from '@openreel/common';
import * as fromActions from '../actions/self-record.actions';
import {
  SelfRecordClipTrim,
  SelfRecordOptions,
  SelfRecordStreamStatus,
} from '../interfaces/self-record.interfaces';
import * as fromSelectors from '../selectors/self-record.selectors';

@Injectable({
  providedIn: 'root',
})
export class SelfRecordFacade {
  options$ = this.store.select(fromSelectors.selectOptions);

  availableWebcamDevices$ = this.store.select(
    fromSelectors.selectAvailableWebcamDevices
  );
  availableAudioDevices$ = this.store.select(
    fromSelectors.selectAvailableAudioDevices
  );
  selectedWebcamDevice$ = this.store.select(fromSelectors.selectWebcamDevice);
  selectedAudioDevice$ = this.store.select(fromSelectors.selectAudioDevice);
  source$ = this.store.select(fromSelectors.selectSource);
  screenStream$ = this.store.select(fromSelectors.selectScreenStream);
  webcamStream$ = this.store.select(fromSelectors.selectWebcamStream);
  uploadWebcamStatus$ = this.store.select(
    fromSelectors.selectUploadWebcamStatus
  );
  uploadScreenStatus$ = this.store.select(
    fromSelectors.selectUploadScreenStatus
  );
  transcodeWebcamStatus$ = this.store.select(
    fromSelectors.selectTranscodeWebcamStatus
  );
  transcodeScreenStatus$ = this.store.select(
    fromSelectors.selectTranscodeScreenStatus
  );
  status$ = this.store.select(fromSelectors.selectStatus);
  streamStatus$ = this.store.select(fromSelectors.selectStreamStatus);
  webcamStreamStatus$ = this.store.select(
    fromSelectors.selectWebcamStreamStatus
  );
  screenStreamStatus$ = this.store.select(
    fromSelectors.selectScreenStreamStatus
  );
  recordingStartedAt$ = this.store.select(
    fromSelectors.selectRecordingStartedAt
  );
  sessionId$ = this.store.select(fromSelectors.selectSessionId);
  uploadWebcamVideo$ = this.store.select(fromSelectors.selectUploadWebcamVideo);
  uploadScreenVideo$ = this.store.select(fromSelectors.selectUploadScreenVideo);
  lastUploadWebcamVideo$ = this.store.select(
    fromSelectors.selectLastUploadWebcamVideo
  );
  lastUploadScreenVideo$ = this.store.select(
    fromSelectors.selectLastUploadScreenVideo
  );

  trims$ = this.store.select(fromSelectors.selectTrims);

  constructor(private readonly store: Store) {}

  goToSelfRecord(options?: {
    timelineIds: string[];
    limit?: number;
    count?: number;
  }) {
    this.store.dispatch(fromActions.gotToSelfRecord(options));
  }

  setOptions(options: SelfRecordOptions) {
    this.store.dispatch(fromActions.setOptions({ options }));
  }

  requestDevicesList() {
    this.store.dispatch(fromActions.requestDevicesList());
  }

  setSource(source: RecordingType) {
    this.store.dispatch(fromActions.setSource({ source }));
  }

  setSelectedAudioDevice(device: AudioDevice) {
    this.store.dispatch(fromActions.setSelectedAudioDevice({ device }));
  }

  setSelectedWebcamDevice(device: VideoDevice) {
    this.store.dispatch(fromActions.setSelectedWebcamDevice({ device }));
  }

  toggleRecording() {
    this.store.dispatch(fromActions.toggleRecording());
  }

  init(options?: SelfRecordOptions) {
    this.store.dispatch(fromActions.init({ options }));
  }

  cleanup() {
    this.store.dispatch(fromActions.cleanup());
  }

  reset() {
    this.store.dispatch(fromActions.reset());
  }

  retry() {
    this.store.dispatch(fromActions.retry());
  }

  cancelVideoUpload(videoId: number) {
    this.store.dispatch(fromActions.cancelVideoUpload({ videoId }));
  }

  updateScreenStreamStatus(status: SelfRecordStreamStatus) {
    this.store.dispatch(fromActions.updateScreenStreamStatus({ status }));
  }

  updateWebcamStreamStatus(status: SelfRecordStreamStatus) {
    this.store.dispatch(fromActions.updateWebcamStreamStatus({ status }));
  }

  applyTrims(trims: SelfRecordClipTrim[]) {
    const event = { trims };
    this.store.dispatch(fromActions.applyTrimToSelfRecordClips({ event }));
  }
}
