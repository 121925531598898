import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'openreel-video-participant',
  templateUrl: './video-participant.component.html',
  styleUrls: ['./video-participant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoParticipantComponent implements AfterViewInit, OnChanges {
  @ViewChild('videoElement')
  localVideo: ElementRef<HTMLVideoElement>;
  private _source: MediaStreamTrack;

  @Input()
  set source(source) {
    this._source = source;
    this.startCurrentStream();
  }

  get source() {
    return this._source;
  }

  @Input()
  halfBorderCurved = false;

  @Input()
  borderRadius = 4;

  @Input()
  enabledVideo = true;

  @Input()
  isLive = false;

  public isMouseInside = false;
  public isFullscreen = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.startCurrentStream();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enabledVideo?.firstChange || changes.enabledVideo?.currentValue !== changes.enabledVideo?.previousValue) {
      this.startCurrentStream();
    }
  }

  mouseEnter() {
    this.isMouseInside = true;
  }

  mouseLeave() {
    this.isMouseInside = false;
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    if (!this.isFullscreen) {
      this.isMouseInside = false;
    }
  }

  private startCurrentStream() {
    if (this.source && this.localVideo) {
      const currentStream = this.localVideo.nativeElement.srcObject;
      if (!currentStream || (currentStream as MediaStream).getVideoTracks()[0] !== this.source) {
        this.localVideo.nativeElement.srcObject =
          this.source.enabled && this.enabledVideo ? new MediaStream([this.source]) : null;
      }
    }
  }
}
