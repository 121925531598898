import { ToastrService } from 'ngx-toastr';
import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ROUTE_DIRECTOR_LOGIN } from '../../route-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'openreel-director-forgot-password-form',
  templateUrl: './director-forgot-password-form.component.html',
  styleUrls: ['../director-login-form/director-login-form.component.scss'],
})
export class DirectorForgotPasswordComponent {
  disabled = false;
  loginRoute = '/' + ROUTE_DIRECTOR_LOGIN;
  email: string;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  async sendForgotPasswordEmail() {
    this.disabled = true;
    try {
      const res = await this.authService
        .sendForgotPasswordEmail(this.email)
        .toPromise();
      this.toastr.success(res.message, 'Success!');
      this.router.navigateByUrl('/' + ROUTE_DIRECTOR_LOGIN);
    } catch (err) {
      const error =
        err.error && err.error.message ? err.error.message : err.message;
      this.toastr.error(error, 'Error!');
    }
    this.disabled = false;
  }
}
