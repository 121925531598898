import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { commonenv } from '@openreel/common/env/environment'
import { VideoPlaybackData } from '../hosting-interfaces';


@Injectable({providedIn:'root'})
export class HostingVideoPlaybackService {
    constructor(private http: HttpClient) {}
    private baseUrl = commonenv.hostingPlaybackApiUrl;

    getVideoData(id: number) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true, 
        };
        return this.http.get<VideoPlaybackData>(`${this.baseUrl}hosting/videos/${id}/playback`, httpOptions);
    }
}