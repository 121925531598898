import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'openreel-select',
  templateUrl: './orselect.component.html',
  styleUrls: ['./orselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrSelectComponent implements OnInit {
  @Input()
  selection;
  @Input()
  items = [];
  @Input()
  idKey = 'id';
  @Input()
  labelKey: string | ((item: unknown) => string) = 'label';
  @Output()
  selectionChange = new EventEmitter();

  ngOnInit(): void {}

  getItemLabel(item) {
    if (typeof item === 'string') {
      return item;
    } else if (typeof this.labelKey === 'string') {
      return item[this.labelKey];
    } else {
      return this.labelKey(item);
    }
  }

  onSelectionChange(event) {
    this.selectionChange.emit(event.value);
  }

  compareWithFn = (option, value) => {
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return option === value;
    }


    return value[this.idKey] === option[this.idKey];
  }
}
