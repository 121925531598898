import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { BubbleChatComponent } from './bubble-chat/bubble-chat.component';
import { ChatApiService } from './chat-api.service';
import { ChatListComponent } from './chat-list/chat-list.component';
import { CommonModule } from '@angular/common';
import { OpenreelCommonModule } from '../../openreel-common.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ChatListComponent, BubbleChatComponent],
  imports: [CommonModule, OpenreelCommonModule, ReactiveFormsModule],
  exports: [ChatListComponent, BubbleChatComponent],
  // Remove these when chat components are dumb
  providers: [ChatApiService],
})
export class ChatModule {
  static forRoot(extraProviders: Provider[]): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [ChatApiService, ...extraProviders],
    };
  }
}
