import { Component, Input } from '@angular/core';
import { UploadFileNetworkStatus, TranscodeStatus } from '@openreel/common';
import { Observable } from 'rxjs';
import { SelfRecordFacade } from '../../store/facades/self-record.facade';

@Component({
  selector: 'openreel-self-record-upload-progress',
  templateUrl: './self-record-upload-progress.component.html',
  styleUrls: ['./self-record-upload-progress.component.scss'],
})
export class SelfRecordUploadProgressComponent {
  @Input() uploadStatus$: Observable<UploadFileNetworkStatus>;
  @Input() transcodeStatus$: Observable<TranscodeStatus>;

  constructor(public readonly selfRecordFacade: SelfRecordFacade) { }
}
