import { cloneDeep } from 'lodash';
import { TransformerBase } from './transformers/transformer.base';
import { TransformerImage } from './transformers/transformer.image';
import { TransformerShape } from './transformers/transformer.shape';
import { TransformerText } from './transformers/transformer.text';
import { Animation } from './interfaces/lottie.interface';
import { LottieProcessedFieldsData, Preset } from '@openreel/creator/common';

export class LottieTransformer {
  private animation: Animation;
  private transformers: TransformerBase[] = [new TransformerText(), new TransformerShape(), new TransformerImage()];

  constructor(animation: Animation) {
    this.animation = cloneDeep(animation);
  }

  transform(data: LottieProcessedFieldsData, preset: Preset) {
    for (const [key, field] of Object.entries(preset)) {
      let found = false;
      for (const transformer of this.transformers) {
        if (transformer.test(field)) {
          this.animation = transformer.process(this.animation, field, data[key]);
          found = true;
          break;
        }
      }

      if (!found) {
        throw `Are you kidding me? No valid transformer for field type ${field.type}`;
      }
    }

    return this.animation;
  }
}
