import { getLayerFromId } from '../helpers';
import { Layer } from '../interfaces';
import { BaseCommand } from './base.command';

export interface LayersToggleEvent {
  layerIds: string[];
  enabled: boolean;
}

export class LayersToggleCommand extends BaseCommand<LayersToggleEvent, Layer[]> {
  run({ layerIds, enabled }: LayersToggleEvent) {
    layerIds.forEach((layerId) => {
      this.toggleLayer(layerId, enabled);
    });

    const layers = layerIds.map((layerId) => getLayerFromId(layerId, this.source)).map(({ layer }) => layer);
    return this.ok(layers);
  }
}
