export const EVT_SUBJECT_REQUEST_TO_JOIN = 'request_to_join';
export const EVT_REQUEST_TO_JOIN_ACK = 'request_to_join_ack';
export const EVT_REQUEST_FOR_APPROVAL = 'request_for_approval';
export const EVT_ADMISSION_REQUEST_RESPONSE = 'director_request_response';
export const EVT_ADMISSION_REQUEST_RESPONSE_ACK =
  'director_request_response_ack';

export class SocketRequestToJoin {
  name: string;
  id: number;
}
