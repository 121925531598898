import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { commonenv } from '../../environments/environment';
import {
  HostableVideo,
  HostingHub,
  HostingParentObject,
  HostingVideoAnalytics,
  UpdateVideoDetails,
  SortOption,
  HostingCaption,
  CreateHostingCaption,
  UpdateHostingCaption,
  HostingThumbnail,
  CreateHostingThumbail,
  HostableCountFilters,
  HostableListFilters,
  CreateHostingCta,
  HostingCta,
  UpdateHostingCta,
  HostingCreateData,
} from '../hosting-interfaces';
import { HostableTypes, HostablePermissionTypes } from '../constants';

@Injectable({ providedIn: 'root' })
export class HostingService {
  private baseUrl = commonenv.nextGenApiUrl;

  constructor(private http: HttpClient) {}

  // ----------------------- H O S T I N G   V I D E O S -----------------------

  //
  // Creates hostable video - HLS variant with JPG thumbnail and Animated PNG thumbnail
  //
  hostVideo(id: number, type: HostableTypes, createData: HostingCreateData) {
    return this.http.post<HostableVideo>(`${this.baseUrl}hosting/videos`, {
      id,
      type,
      ...createData,
    });
  }

  //
  // Update Video details
  //
  putVideo(videoId: number, videoDetails: UpdateVideoDetails) {
    return this.http.patch<HostableVideo>(`${this.baseUrl}hosting/videos/${videoId}`, videoDetails);
  }

  //
  // Delete/Archive video clip
  //
  deleteVideo(id: number) {
    return this.http.delete<void>(this.baseUrl + 'hosting/videos/' + id);
  }

  rerender(id: number) {
    return this.http.post<HostableVideo>(this.baseUrl + 'hosting/videos/' + id + '/rerender', {});
  }

  render(id: number) {
    return this.http.post<HostableVideo>(this.baseUrl + 'hosting/videos/' + id + '/render', {});
  }

  cancel(id: number) {
    return this.http.post<void>(this.baseUrl + 'hosting/videos/' + id + '/cancel', {});
  }

  //
  // Get all hostable videos for an account
  //
  getAllHostedVideos(pageNumber: number, pageSize: number, sort?: SortOption, filters?: HostableListFilters) {
    let params = new HttpParams().set('page', pageNumber.toString()).set('perPage', pageSize.toString());
    if (sort) params = params.set('sortDir', sort.direction).set('sortOn', sort.field);
    if (filters) Object.entries(filters).forEach(([k, v]) => (params = params.set(k, v)));

    return this.http.get<{ count: number; rows: HostableVideo[] }>(`${this.baseUrl}hosting/videos`, { params });
  }

  countAllHostedVideos(filters?: HostableCountFilters) {
    let params = new HttpParams();
    if (filters) Object.entries(filters).forEach(([k, v]) => (params = params.set(k, v)));
    return this.http.get<{ count: number }>(`${this.baseUrl}hosting/videos/count`, { params });
  }

  //
  // Get All hostable videos by given Hub id
  //
  getAllHostedVideos4Hub(hubId: number, pageNumber: number, pageSize: number, sort?: SortOption) {
    let params = new HttpParams().set('page', pageNumber.toString()).set('perPage', pageSize.toString());
    if (sort) params = params.set('sortDir', sort.direction).set('sortOn', sort.field);

    return this.http.get<{ count: number; rows: HostableVideo[] }>(`${this.baseUrl}hosting/hubs/${hubId}/videos`, {
      params,
    });
  }

  //
  // Get single video by id
  //
  getVideoById(id: number) {
    return this.http.get<HostableVideo>(`${this.baseUrl}hosting/videos/${id}`);
  }

  //
  // Get video object by parent id and parent type
  //
  getVideoByParentIdType(id: number, type: HostableTypes) {
    return this.http.get<HostableVideo>(this.baseUrl + 'hosting/videos/parent/' + id + '/' + type);
  }

  //
  // Get paren object by video id
  //
  getParentObjectByHostableId(id: number) {
    return this.http.get<HostingParentObject>(this.baseUrl + 'hosting/videos/' + id + '/source');
  }

  //
  // Get Analytics details on video
  //
  getVideoAnalytics(id: number) {
    return this.http.get<HostingVideoAnalytics>(this.baseUrl + 'hosting/analytics/video/' + id);
  }

  // ----------------------- V I D E O   H U B S -----------------------

  //
  // Create a New Hub
  //
  createHub(title: string, permission: HostablePermissionTypes, slug: string) {
    return this.http.post<HostingHub>(`${this.baseUrl}hosting/hubs`, { title, permission, slug });
  }

  //
  // Get Hub details
  //
  getHub(id: number) {
    return this.http.get<HostingHub>(`${this.baseUrl}hosting/hubs/${id}`);
  }

  //
  // Delete a Hub
  //
  deleteHub(id: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/hubs/${id}`);
  }

  //
  // Put Hub Details
  //
  putHub(id: number, hubDetails: { title: string; permission: HostablePermissionTypes }) {
    return this.http.put<HostingHub>(`${this.baseUrl}hosting/hubs/${id}`, { ...hubDetails });
  }

  //
  // get all Hubs for the account
  //
  getHubs(pageNumber: number, pageSize: number) {
    const params = new HttpParams().set('page', pageNumber.toString()).set('perPage', pageSize.toString());

    return this.http.get<{ count: number; rows: HostingHub[] }>(`${this.baseUrl}hosting/hubs`, { params });
  }

  //
  // Get Video Count for given Hub
  //
  getVideoCount4Hub(hubId: number) {
    return this.http.get<number>(`${this.baseUrl}hosting/hubs/${hubId}/videos/count`);
  }

  //
  // Links one video to one Hub
  //
  link2Hub(videoId: number, hubId: number) {
    return this.http.post<HostingHub>(`${this.baseUrl}hosting/hubs/${hubId}/video`, { id: videoId });
  }

  //
  // UnLinks one video to one Hub
  //
  unlinkFromHub(videoId: number, hubId: number) {
    return this.http.delete<HostingHub>(`${this.baseUrl}hosting/hubs/${hubId}/video/${videoId}`);
  }

  //
  // Get all Hubs linked to the video
  //
  getHubs4video(videoid: number) {
    return this.http.get<number[]>(`${this.baseUrl}hosting/hubs/video/${videoid}`);
  }

  addCaption(hostableId: number, body: CreateHostingCaption) {
    return this.http.post<HostingCaption>(`${this.baseUrl}hosting/videos/${hostableId}/captions`, {
      ...body,
    });
  }

  updateCaption(hostableId: number, captionId: number, body: UpdateHostingCaption) {
    return this.http.put<HostingCaption>(`${this.baseUrl}hosting/videos/${hostableId}/captions/${captionId}`, {
      ...body,
    });
  }

  deleteCaption(hostableId: number, captionId: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${hostableId}/captions/${captionId}`);
  }

  getCaptions(hostableId: number) {
    return this.http.get<HostingCaption[]>(`${this.baseUrl}hosting/videos/${hostableId}/captions`);
  }

  createCustomThumbnail(hostableId: number, body: CreateHostingThumbail) {
    return this.http.post<HostingThumbnail>(`${this.baseUrl}hosting/videos/${hostableId}/thumbnail`, body);
  }

  getCustomThumbnail(hostableId: number) {
    return this.http.get<HostingThumbnail>(`${this.baseUrl}hosting/videos/${hostableId}/thumbnail`);
  }

  deleteCustomThumbnail(hostableId: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${hostableId}/thumbnail`);
  }

  createCta(hostableId: number, body: CreateHostingCta) {
    return this.http.post<HostingCta>(`${this.baseUrl}hosting/videos/${hostableId}/cta`, body);
  }

  updateCta(hostableId: number, body: UpdateHostingCta) {
    return this.http.put<HostingCta>(`${this.baseUrl}hosting/videos/${hostableId}/cta`, body);
  }

  getCta(hostableId: number) {
    return this.http.get<HostingCta>(`${this.baseUrl}hosting/videos/${hostableId}/cta`);
  }

  deleteCta(hostableId: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${hostableId}/cta`);
  }
}
