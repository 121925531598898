import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CueControlsComponent } from './cue-controls/cue-controls.component';
import { CueLoaderComponent } from './cue-loader/cue-loader.component';
import { CuePlayerComponent } from './cue-player/cue-player.component';
import { CuePlayerControllerComponent } from './cue-player-timelines/cue-player-controller/cue-player-controller.component';
import { CuePlayerImageComponent } from './cue-player-image/cue-player-image.component';
import { CuePlayerLottieComponent } from './cue-player-lottie/cue-player-lottie.component';
import { CuePlayerMainComponent } from './cue-player-timelines/cue-player-controller-main/cue-player-controller-main.component';
import { CuePlayerTimelinesComponent } from './cue-player-timelines/cue-player-timelines.component';
import { CuePlayerVideoComponent } from './cue-player-video/cue-player-video.component';
import { MaterialModule } from '@openreel/common/material';
import { CuePlayerSelectableComponent } from './cue-player-timelines/cue-player-selectable/cue-player-selectable.component';
import { CuePlayerItemComponent } from './cue-player-timelines/cue-player-controller/cue-player-item.component';
import { OpenreelLoaderModule } from '@openreel/ui/openreel-loader';
import { OpenreelVideoControlsModule } from '@openreel/ui/openreel-video-controls';
import { CuePlayerService } from './services/cue-player.service';
import { CuePlayerCaptionsComponent } from '@openreel/ui/openreel-cue-player/cue-player-captions/cue-player-captions.component';
import { CueEventsService } from './services/cue-events.service';
import { CueSelectionToolbarComponent } from './cue-selection-toolbar/cue-selection-toolbar.component';

const components = [
  CuePlayerComponent,
  CuePlayerMainComponent,
  CuePlayerVideoComponent,
  CuePlayerLottieComponent,
  CuePlayerCaptionsComponent,
  CuePlayerImageComponent,
  CuePlayerTimelinesComponent,
  CuePlayerControllerComponent,
  CueControlsComponent,
  CueLoaderComponent,
  CuePlayerSelectableComponent,
  CuePlayerItemComponent,
  CueSelectionToolbarComponent,
];

@NgModule({
  imports: [CommonModule, OpenreelLoaderModule, OpenreelVideoControlsModule, MaterialModule],
  declarations: [...components],
  exports: [CuePlayerComponent],
  providers: [CueEventsService, CuePlayerService],
})
export class OpenreelCuePlayerModule {}
