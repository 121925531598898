import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RenderFpsService {
  private times = [];
  private fps = 30;

  constructor(
    private zone: NgZone
  ) {
    this.zone.runOutsideAngular(() => {
      this.refreshFPSCalcLoop();
    })
  }

  private refreshFPSCalcLoop() {
    requestAnimationFrame(() => {
      const now = performance.now();
      while (this.times.length > 0 && this.times[0] <= now - 1000) {
        this.times.shift();
      }
      this.times.push(now);
      this.fps = this.times.length;

      this.refreshFPSCalcLoop();
    });
  }

  getFps(){
      return this.fps;
  }
}
