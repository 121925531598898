import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {finalize, tap} from 'rxjs/operators';
import {StatisticsService} from '@openreel/common/services';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'openreel-recording-statistics',
  templateUrl: './recording-statistics.component.html',
  styleUrls: ['./recording-statistics.component.scss'],
})
export class RecordingStatisticsComponent implements OnInit {
  loading = false;
  recordingHour: number;
  recordedClipsCount: number;

  @Input()
  addStatMarginClass = false;

  constructor(
    private change: ChangeDetectorRef,
    private statisticsService: StatisticsService) {}

  ngOnInit() {
    this.getRecordingStatistics();
  }

  getRecordingStatistics() {
    const accountRecordingTotalHours$ = this.statisticsService.getAccountRecordingTotalHours()
      .pipe(tap((res) => {
        const hrsUsed: string = res.data.hoursUsed;
        const colIndx = hrsUsed && hrsUsed.indexOf(':');
        if (colIndx && colIndx > 0) {
          this.recordingHour = Number(hrsUsed.substr(0, colIndx));
        }
      }));

    const accountClipsTotalCount$ = this.statisticsService.getAccountClipsTotalCount()
      .pipe(tap((res) => this.recordedClipsCount = res.data.totalCount));

    this.loading = true;
    forkJoin([accountRecordingTotalHours$, accountClipsTotalCount$]).pipe(
      finalize(() => this.loading = false),
    ).subscribe()
  }
}
