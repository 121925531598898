import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ElectronService } from '../../services/electron/electron.service';
import {
  defaultFps,
  defaultResolution,
  findTrackCapabilities,
} from '../helpers/capabilities.helper';
import { newStreamError, newUnsupportedSource } from '../helpers/error.helper';
import { AdvancedMediaTrackConstraints } from '../interfaces/advanced-mediatrack-constraints.interface';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import { VideoDevice } from '../interfaces/video-device.interface';
import { VideoSource } from '../interfaces/video-source.interface';
import { VideoStream } from '../interfaces/video-stream.interface';
import { ScreenShareService } from './screenshare.service';

@Injectable()
export class ScreenShareElectronService extends ScreenShareService {
  constructor(private readonly electronService: ElectronService) {
    super();
    this.devices$ = new BehaviorSubject<VideoDevice[]>([]);
    this.fetchCapturers();
  }

  private fetchCapturers() {
    this.electronService.desktopCapturer
      .getSources({
        types: ['screen', 'window'],
      })
      .then((sources) =>
        this.devices$.next(
          sources.map(({ id, name }) => ({
            id,
            name,
            source: VideoSource.DESKTOP,
          }))
        )
      );
  }

  isSupported(): boolean {
    return true;
  }

  async openStream(
    deviceId: string,
    constraints?: VideoConstraints
  ): Promise<VideoStream> {
    if (!this.isSupported()) {
      throw newUnsupportedSource(
        'Desktop sharing is not supported in the current device.'
      );
    }

    const desktopCapturer = this.electronService.desktopCapturer;
    const sources = await desktopCapturer.getSources({
      types: ['screen', 'window'],
    });

    if (sources.length === 0) {
      throw newStreamError("Couldn't fetch video stream.");
    }

    const source = sources.find((s) => s.id === deviceId);
    if (!source) {
      throw newStreamError(`Screen share stream for '${deviceId}' not found`);
    }
    const constraintsToApply: AdvancedMediaTrackConstraints = {
      mandatory: {
        chromeMediaSource: 'desktop',
        chromeMediaSourceId: source.id,
        minWidth: constraints ? constraints.width : defaultResolution.width,
        maxWidth: constraints ? constraints.width : defaultResolution.width,
        minHeight: constraints ? constraints.height : defaultResolution.height,
        maxHeight: constraints ? constraints.height : defaultResolution.height,
        maxFrameRate: constraints ? constraints.fps : defaultFps,
        minFrameRate: constraints ? constraints.fps : defaultFps,
      },
    };

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: constraintsToApply ? constraintsToApply : true,
    });
    const track = stream.getTracks()[0];
    const settings = track.getSettings();
    const capabilities = await findTrackCapabilities(track);

    return {
      device: {
        id: settings.deviceId,
        name: track.label,
        source: VideoSource.DESKTOP,
      },
      stream,
      track,
      ...capabilities,
    };
  }

  async applyConstraintsToStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    try {
      const constraintsToApply: AdvancedMediaTrackConstraints = {
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: video.device.id,
          minWidth: constraints.width,
          maxWidth: constraints.width,
          minHeight: constraints.height,
          maxHeight: constraints.height,
          maxFrameRate: constraints.fps,
          minFrameRate: constraints.fps,
        },
      };

      await video.track.applyConstraints(constraintsToApply);
    } catch (err) {
      console.error(err);
    }
  }
}
