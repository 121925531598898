import { Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { commonenv } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

export function duplicateNameValidator(emailList: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let forbidden = false;
    if(emailList.includes(control.value)){
      forbidden = true;
    }
    return forbidden ? {duplicate: {value: control.value}} : null;
  };
}

@Component({
  selector: 'openreel-hosting-share',
  templateUrl: './hosting-share.component.html',
  styleUrls: ['./hosting-share.component.scss']
})
export class HostingShareComponent {
  isLightThemed = true;
  isPopup = true;

  shareForm: FormGroup;
  emailList: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<HostingShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {type: string, id: number, slug: string},
    private clipboard: Clipboard,
    private toastr: ToastrService,
  ) {

    this.shareForm = new FormGroup({
      link: new FormControl(''),
      embed: new FormControl(''),
      // this control is not visible
      // we keep it to use validation only
      // pass it to email-list control to use validation
      email: new FormControl('', Validators.compose([
        Validators.required, 
        Validators.email,
        duplicateNameValidator(this.emailList),
      ])),
      message: new FormControl('', Validators.compose([
        Validators.maxLength(512)
      ])),
    });

    // construct URL
    let embed = '';
    let link = '';
    if (data) {
      if (data.type === 'hub') {
        link = commonenv.hostingAppUrl + 'hub/' + data.slug;
      } else if (data.type === 'video') {
        link = commonenv.hostingAppUrl + data.slug;
        // eslint-disable-next-line max-len
        embed = `<iframe width="960" height="540" src="${commonenv.hostingAppUrl}embed/${data.slug}" title="OpenReel Video Player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>`;
      }
    }

    this.shareForm.patchValue({
      link,
      embed,
    });
  }

  // Share
  share() {
    if (!this.shareForm.valid) return;

    const data = {
      email: this.emailList,
      message: this.shareForm.value.message.trim(),
    };
    this.dialogRef.close(data);
  }

  // copy URL to Clipboard
  copyLink(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.clipboard.copy(this.shareForm.value.link);
    this.toastr.success('Copied Share Link');
  }

  copyEmbed(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.clipboard.copy(this.shareForm.value.embed);
    this.toastr.success('Copied Embed Code');
  }
}
