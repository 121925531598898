import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'wordCount' })
export class WordCount implements PipeTransform {
  transform(text: string) {
    if (text && text.trim()) {
      text = text.replace(/<[^>]*>/g,' ');
      text = text.replace(/\s+/g, ' ');
      text = text.trim();
      text = text.replace(/Powered by Froala Editor/gi,'');
      return text.split(' ').filter(item => item.trim()).length
    }
    return 0;
  }
}
