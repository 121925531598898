import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'openreel-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss'],
})
export class AnimationComponent implements OnInit {
  @Input()
  public name;

  @Input()
  public loop = false;

  @Input()
  public autoplay = true;

  @Input()
  public speed: number;

  @Output()
  public animationComplete = new EventEmitter<void>();

  public config;

  ngOnInit(): void {
    this.config = {
      path: 'assets/common/animations/' + this.name + '.json',
      autoplay: this.autoplay,
      loop: this.loop,
    };
  }

  onCreated(ev) {
    if (this.speed) {
      ev.setSpeed(this.speed);
    }
  }
}
