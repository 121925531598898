import { AssetId } from '../interfaces';
import { BaseCommand } from './base.command';

export class GlobalSettingsBackgroundDeleteCommand extends BaseCommand<{ assetFileId: AssetId }> {
  run({ assetFileId }) {
    const index = this.source.globalSettings.backgroundVideo.uploaded.findIndex(asset => asset.file.path === assetFileId);

    if (index === -1) {
      return this.error('Background video not found');
    }

    this.source.globalSettings.backgroundVideo.uploaded.splice(index, 1);

    return this.ok();
  }
}
