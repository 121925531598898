<div
  class="drag-wrapper"
  cdkDrag
  cdkDragBoundary=".cdk-overlay-container"
  cdkDragRootElement=".teleprompter-popup"
  (resized)="onResize()"
>
  <div
    mat-dialog-title
    cdkDragHandle
  >
    <button class="close" mat-icon-button mat-dialog-close>
      <mat-icon class="mat-icon-12" svgIcon="close"></mat-icon>
    </button>
  </div>

  <ng-container *ngIf="status$ | async as status">
    <mat-dialog-content>
      <div
        #editorWrapper
        class="editor-wrapper"
      >
        <openreel-rich-text-editor
          [hidden]="status !== 'stopped'"
          (editorContentChange)="onTextChanged($event)"
          [editorContent]="text$ | async"
          [height]="editorHeight"
          [isDark]="false"
          [isPlayable]="false"
        ></openreel-rich-text-editor>

        <openreel-subject-teleprompter
          (click)="edit()"
          [hidden]="status == 'stopped'"
        ></openreel-subject-teleprompter>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions cdkDragHandle>
      <div class="actions">
        <form class="actions-row" ngNativeValidate (submit)="save()">
          <div class="save-wrapper">
            <mat-form-field class="no-padding" appearance="outline">
              <mat-select
                name="selected_script"
                [disabled]="
                  status === 'playing' || status === 'paused' || loading
                "
                placeholder="Create new script"
                [(ngModel)]="selectedScript"
                (selectionChange)="onScriptChanged()"
              >
                <mat-option>Create new script</mat-option>
                <mat-option
                  *ngFor="
                    let script of selfRecordTeleprompterFacade.scripts$ | async
                  "
                  [value]="script"
                >
                  {{ script.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              *ngIf="dirty"
              mat-icon-button
              [disabled]="loading"
              type="submit"
            >
              <mat-icon svgIcon="save"></mat-icon>
            </button>
          </div>
          <mat-form-field class="no-padding" appearance="outline">
            <input
              [disabled]="status === 'playing' || status === 'paused' || loading"
              required
              type="text"
              placeholder="Title"
              (ngModelChange)="onTitleChanged()"
              matInput
              name="name"
              [(ngModel)]="scriptTitle"
              class="w-full"
            />
          </mat-form-field>
        </form>
        <div class="actions-row">
          <div *ngIf="status === 'playing'" class="pause-btn">
            <button
              mat-icon-button
              [disabled]="loading"
              type="button"
              (click)="pause()"
            >
              <mat-icon
                class="mat-icon-40"
                svgIcon="teleprompter-pause"
              ></mat-icon>
            </button>
            <div class="reset-btn">Pause</div>
          </div>
          <div *ngIf="status !== 'playing'" class="play-btn">
            <button
              mat-icon-button
              [disabled]="loading"
              type="button"
              (click)="play()"
            >
              <mat-icon class="mat-icon-40" svgIcon="play-circle"></mat-icon>
            </button>
            <div class="reset-btn">
              <div>{{ text$ | async | wordCount }} word count</div>
              <div *ngIf="status === 'paused'">
                <button
                  mat-button
                  type="button"
                  color="primary"
                  (click)="reset()"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div class="actions-row">
            <mat-form-field class="no-padding" appearance="outline">
              <mat-select
                [disabled]="
                  status === 'playing' || status === 'paused' || loading
                "
                placeholder="Create new script"
                [(ngModel)]="selectedSpeed"
              >
                <mat-option
                  *ngFor="let speed of options.speeds"
                  [value]="speed.value"
                >
                  {{ speed.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="no-padding" appearance="outline">
              <mat-select
                [disabled]="
                  status === 'playing' || status === 'paused' || loading
                "
                placeholder="Create new script"
                [(ngModel)]="selectedFontSize"
              >
                <mat-option
                  *ngFor="let fontSize of options.fontSizes"
                  [value]="fontSize.value"
                >
                  {{ fontSize.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-actions>
  </ng-container>
</div>
