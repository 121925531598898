import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_8 } from '../transformation_0_8/schema_after';
import { WorkflowDataDto_0_9 } from './schema_after';

function setGlobalLayouts(schema: WorkflowDataDto_0_9.WorkflowDataDto, templateLayoutId: number) {
  schema.globalSettings.layout = {
    defaultLayoutId: templateLayoutId
  };
}

function setSectionLayouts(
  schema: WorkflowDataDto_0_9.WorkflowDataDto,
  templateLayoutId: number,
  layoutType: WorkflowDataDto_0_9.LayoutType
) {
  const mainSections = Object.entries(schema.sections).filter(([, section]) => section.sectionType === 'main');

  for (const [, section] of mainSections) {
    section.layout = {
      layoutId: templateLayoutId,
      layoutType,
    };
  }
}

export const SchemaTransformation_0_9: SchemaTransformation = {
  version: '0.9',

  run(
    oldSchema: WorkflowDataDto_0_8.WorkflowDataDto,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { layout }: { layout: any }
  ) {
    if (!layout) {
      return oldSchema;
    }

    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_0_9.WorkflowDataDto;

    setGlobalLayouts(newSchema, layout.id);
    setSectionLayouts(newSchema, layout.id, layout.type);

    // TODO: add placeholder assets from templates?

    return newSchema;
  },
};
