import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { MouseEventsService } from '@openreel/creator/app/core/services/mouse-events.service';
import { RecordingUploadService } from '@openreel/creator/app/core/services/recording-upload.service';
import { TitleService } from '@openreel/creator/app/core/services/title.service';
import { Userpilot } from 'userpilot';
import { NavigationEnd, Router } from '@angular/router';
import {
  AuthService,
  Cleanupable,
  FeatureFlaggingService,
  USER_PILOT,
} from '@openreel/common';
import { MixpanelService } from './analytics/mixpanel.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'openreel-wf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent extends Cleanupable implements OnInit {
  private mouseDownTarget: HTMLElement;
  private mouseUpTarget: HTMLElement;

  @HostListener('document:mousedown', ['$event'])
  documentMouseDown = (event: MouseEvent) =>
    (this.mouseDownTarget = event.target as HTMLElement);

  @HostListener('document:mouseup', ['$event'])
  documentMouseUp = (event: MouseEvent) => {
    this.mouseUpTarget = event.target as HTMLElement;
    this.mouseEventsService.setClickTargets(
      this.mouseDownTarget ?? this.mouseUpTarget,
      this.mouseUpTarget
    );
  };

  constructor(
    private readonly router: Router,
    public readonly uploadService: RecordingUploadService,
    private readonly titleService: TitleService,
    private readonly mouseEventsService: MouseEventsService,
    private readonly authService: AuthService,
    private readonly mixpanelService: MixpanelService,
    private readonly featureFlagsService: FeatureFlaggingService
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.init();
    this.uploadService.uploadAllStoredRecordings();
    this.initMixpanel();

    this.featureFlagsService
      .isFeatureFlagEnabled(USER_PILOT)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((enabled) => enabled && this.setupUserPilot());
  }

  private setupUserPilot() {
    if (environment.userPilotToken) {
      Userpilot.initialize(environment.userPilotToken);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          Userpilot.reload('');
        }
      });
    }
  }

  private initMixpanel() {
    this.mixpanelService.init();
    this.mixpanelService.identify(
      this.authService.getUserDetails()?.data?.analytics_id
    );
  }
}
