<div class="color-container" [class.hasPalette]="showPalette">
  <div class="color-container-outer">
    <div class="title" *ngIf="showName">
      {{ colorTag?.name }}
    </div>
    <div class="wizard-box">
      <div class="color-container-inner">
        <div
          data-cy="selected-color"
          [class.disabled]="isDisabled"
          [style.backgroundColor]="colorTag?.color"
          (click)="onColorClick()"
        ></div>
        <span *ngIf="showColorString" data-cy="selected-color-text">{{ colorTag?.color }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="showPalette && colorTag?.color" class="color-palette-container">
    <div class="title">Palette</div>
    <openreel-color-palette *ngIf="showPalette" [color]="colorTag?.color"></openreel-color-palette>
  </div>
</div>
