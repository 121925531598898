import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class SanitizeFilenameService {
  public sanitize(input) {
    const illegalRe = /[/?<>\\:*|"]/g;
    const customRe = /[',&$#?%!@^+]/g;
    const notAlphanumeric = /[^a-zA-Z0-9.\- ]/gi;
    // eslint-disable-next-line no-control-regex
    const controlRe = /[\x00-\x1f\x80-\x9f]/g;
    const reservedRe = /^\.+$/;
    const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
    const windowsTrailingRe = /[. ]+$/;

    if (typeof input !== 'string') {
      throw new Error('Input must be string');
    }

    const sanitized = input
      .replace(illegalRe, '-')
      .replace(controlRe, '-')
      .replace(customRe, '-')
      .replace(reservedRe, '-')
      .replace(windowsReservedRe, '-')
      .replace(windowsTrailingRe, '-')
      // Some customers still have a problem with some non-unicode special characters like EM dash
      // This should only allow alphanumeric and replace everything else with a dash
      .replace(notAlphanumeric, '-');

    return sanitized;
  }
}
