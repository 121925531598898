<div class="input-bg-white input-primary-underline">
  <mat-form-field
    [class]="'w-full ' + options.types?.text.fieldClasses"
    [ngClass]="{}"
    [formGroup]="control"
    [appearance]="options.appearance"
  >
    <input
      [attr.data-cy]="uiControl.label + '-text-box'"
      matInput
      [placeholder]="uiControl.label"
      formControlName="value"
    />
    <mat-error *ngIf="control.invalid">
      <div *ngFor="let error of validationErrors()">
        {{ error }}
      </div>
    </mat-error>
  </mat-form-field>
</div>
