<openreel-video-controls
  [currentTime]="currentTime"
  [duration]="duration"
  [muted]="muted"
  [playing]="playing"
  (onSeek)="seek($event)"
  (onPlay)="play()"
  (onPause)="pause()"
  (onMute)="mute()"
  (onUnmute)="unmute()"
  (onFullscreen)="fullscreen()"
  (onExitFullscreen)="exitFullscreen()"
  [options]="options"
></openreel-video-controls>
