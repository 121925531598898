import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Cleanupable } from '@openreel/common/classes';
import { takeUntil } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'openreel-project-name',
  templateUrl: './openreel-project-name.component.html',
  styleUrls: ['./openreel-project-name.component.scss'],
})
export class OpenreelProjectNameComponent extends Cleanupable implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() projectName$: Observable<string>;
  @Output() titleChange = new EventEmitter<void>();

  isInEditMode = false;
  nameControl = new FormControl(['', [Validators.required]]);

  private valueBeforeEdit: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.projectName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectName) => this.nameControl.patchValue(projectName));
  }

  switchEdit() {
    this.valueBeforeEdit = this.nameControl.value;
    this.isInEditMode = true;
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.save();
    } else if (event.code === 'Escape') {
      this.cancel();
    }
  }

  onBlur() {
    this.save();
  }

  private cancel() {
    this.nameControl.patchValue(this.valueBeforeEdit);
    this.isInEditMode = false;
  }

  private save() {
    this.titleChange.emit(this.nameControl.value);
    this.isInEditMode = false;
  }
}
