import { BaseSocketService } from '../../services';

import { Injectable } from '@angular/core';
import { ISocketIoLibService } from '@openreel/common/interfaces';

@Injectable()
export class WorkflowsSocketService extends BaseSocketService {
  private readonly namespace = '/projects';

  constructor(readonly socketIoProvider: ISocketIoLibService) {
    super(socketIoProvider);
  }

  connect(url: string) {
    this.socket.connect(url, this.namespace);
  }

  basicSocketEmit<T>(event: string, value: T): void {
    this.emit(event, value);
  }

  emit<T>(event: string, data: T): void {
    this.socket.emit(event, data);
  }
}
