import { Component, OnDestroy } from '@angular/core';
import { IStreamingParticipant } from '../../interfaces';
import { SessionConfigService } from '../../services';
import { Cleanupable } from '../../classes';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'openreel-session-audio',
  templateUrl: './session-audio.component.html',
  styleUrls: ['./session-audio.component.css'],
})
export class SessionAudioComponent extends Cleanupable implements OnDestroy {
  public mediaStreams;
  participantTracks = new Map<IStreamingParticipant, MediaStream>();

  constructor(public config: SessionConfigService) {
    super();

    // this.subscriptions.push(
    //   streaming.remoteParticipants$.subscribe(participants => this.handleNewStreamingAvailable(participants))
    // );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private handleNewStreamingAvailable(participants: IStreamingParticipant[]) {
    if (participants) {
      this.detectMissingParticipantStreams(participants);
    }
  }

  getMediaStreams(): MediaStream[] {
    return Array.from(this.participantTracks.values()).filter((t) => !!t);
  }

  private newParticipantTrackAvailable(
    participant: IStreamingParticipant,
    track: MediaStreamTrack
  ) {
    this.participantTracks.delete(participant);
    if (track) {
      this.participantTracks.set(participant, new MediaStream([track]));
      console.log('SessionAudio: Adding track for ' + participant.identity);
    }
    this.mediaStreams = Array.from(this.participantTracks.values()).filter(
      (t) => !!t
    );
  }

  private detectMissingParticipantStreams(
    participants: IStreamingParticipant[]
  ) {
    for (const participant of this.participantTracks.keys()) {
      const stillExists = participants.some(
        (particip) => particip.id === participant.id
      );
      if (!stillExists) this.participantTracks.delete(participant);
    }
    this.mediaStreams = Array.from(this.participantTracks.values()).filter(
      (t) => !!t
    );
    for (const participant of participants) {
      if (!this.participantTracks.has(participant)) {
        console.log(
          'SessionAudio: New participant detected ' + participant.identity
        );
        this.participantTracks.set(participant, undefined);
        this.subscriptions.push(
          participant.audioStream$
            .pipe(distinctUntilChanged())
            .subscribe((stream) => {
              this.newParticipantTrackAvailable(participant, stream);
            })
        );
      }
    }
  }
}
