import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ROUTE_DIRECTOR_LOGIN } from '../route-utils';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services';

enum BackendResponseStatus {
  ERROR = 0,
  OK = 1,
  VIDEO_UPLOAD_OK = 4,
}

const NO_TOKEN_MESSAGE = 'Authenticate token is missing';
const INVALID_TOKEN_MESSAGE = 'Invalid Token';
const TOKEN_IS_REQUIRED_MESSAGE = 'Token is required';
const TOKEN_EXPIRED_MESSAGE = 'Your Token has expired. Please Login again';
const USER_DISABLED_MESSAGE = 'Your Account has been disabled.Please contact your manager';

interface BackendResponseSuccess {
  status: BackendResponseStatus.OK;
}

interface BackendResponseFailure {
  status: number;
  message?: string;
  msg?: string;
  error?: string;
}

type BackendResponse<ResponseType extends BackendResponseSuccess> = ResponseType | BackendResponseFailure;

function isBackendResponse(
  response: BackendResponse<BackendResponseSuccess>
): response is BackendResponse<BackendResponseSuccess> {
  if (response && typeof response === 'object' && 'status' in response) {
    return true;
  }
}

function isSuccessBackendResponse(response: BackendResponseSuccess): response is BackendResponseSuccess {
  return (
    isBackendResponse(response) &&
    (response.status === BackendResponseStatus.OK || response.status === BackendResponseStatus.VIDEO_UPLOAD_OK)
  );
}
function isFailureBackendResponse<T>(response: BackendResponseFailure): response is BackendResponseFailure {
  return isBackendResponse(response) && response.status !== BackendResponseStatus.OK;
}

@Injectable()
export class ResponseParserInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (
            !req.url.endsWith('login') &&
            (err.status === 401 ||
              err.error?.message === INVALID_TOKEN_MESSAGE ||
              err.error?.message === TOKEN_IS_REQUIRED_MESSAGE)
          ) {
            if (this.toastr.toasts.length < 1 && !this.router.url.endsWith(ROUTE_DIRECTOR_LOGIN)) {
              this.toastr.error(TOKEN_EXPIRED_MESSAGE, 'Error!');
            }
            this.redirectToLogin();
          } else if (err.error.loginRedirect) {
            this.toastr.error(err.error.message, 'Error!');
            this.redirectToLogin();
          } else if (err.error.revertData) {
            throw err.error;
          } else {
            throw new Error(err.error?.message ? err.error.message : err.statusText);
          }
        }
        throw err;
      }),
      map((event) => {
        // the below cluster of IFs smells bad code
        // let's refactor here once we are fully RESTful and have standard response body
        if (event instanceof HttpResponse) {
          if (!event.ok) {
            throw new Error(event.body?.message ? event.body.message : event.statusText);
          } else {
            if (isBackendResponse(event.body)) {
              if (isSuccessBackendResponse(event.body)) {
                // all good
              } else if (isFailureBackendResponse(event.body)) {
                // currently the only way to find out if our token expired or user is disabled
                // in the future let's do this via status codes
                if (
                  event.body.message === TOKEN_EXPIRED_MESSAGE ||
                  event.body.message === NO_TOKEN_MESSAGE ||
                  event.body.message === USER_DISABLED_MESSAGE
                ) {
                  this.redirectToLogin();
                }
                throw new Error(event.body.message || event.body.msg || event.body.error);
              } else {
                console.error('Unknown body:');
                console.log(event.body);
              }
            }
          }
        }
        return event;
      })
    );
  }

  redirectToLogin() {
    this.authService.invalidateToken();
    this.router.navigateByUrl(ROUTE_DIRECTOR_LOGIN);
  }
}
