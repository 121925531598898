import { Directive, OnInit, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SlugService } from '../services/slug/slug.service';
import { Cleanupable } from '../classes/cleanupable';

@Directive({
  selector: '[formControl][slugField],[formControlName][slugField]'
})
export class SlugFieldDirective extends Cleanupable implements OnInit {

  @HostListener('blur')
  onBlur() {
    this.confirmSlug();
  }

  constructor(private ngControl: NgControl, private slugService: SlugService) {
    super();
  }

  ngOnInit() {
    this.confirmSlug();
    this.mortalize(
      this.ngControl.control.valueChanges
    ).subscribe(
        v => this.setValue(v)
    )
  }

  private confirmSlug() {
    const val = this.ngControl.control.value || '';
    this.setValue(val, true);
  }

  private setValue(text: string, trim = false) {
    this.ngControl.control.setValue(this.slugService.toSlug(text, trim), {emitEvent: false})
  }

}
