<div class="{{containerClass}}">
  <div class="icon-section" *ngIf="iconSvg">
    <mat-icon [svgIcon]="iconSvg" class="image">
    </mat-icon>
  </div>
  <div class="text-section">
    <div *ngIf="title">{{ title }}</div>
    <div>{{ message }}</div>
    <div class="buttons-section">
      <button mat-button
        [className]="containerClass == 'confirmation-toast-red' ? 'mat-stroked-button' : 'mat-raised-button'"
        (click)="onSelection.emit(true)">{{yesText}}</button>
      <button mat-button
        [className]="containerClass == 'confirmation-toast-red' ? 'mat-stroked-button' : 'mat-raised-button'"
        (click)="onSelection.emit(false)">{{noText}}</button>
    </div>
  </div>
</div>