import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'openreel-highlightable-textarea',
  templateUrl: './highlightable-textarea.component.html',
  styleUrls: ['./highlightable-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HighlightableTextareaComponent {
  @Input() text: string;
  @Output() textChange = new EventEmitter<string>();
  @Output() highlightedTextChange = new EventEmitter<string>();
  @Output() cursorPositionChange = new EventEmitter<number>();

  @ViewChild('highlightableTextarea') highlightableTextarea: ElementRef<
    HTMLInputElement
  >;

  selectedRange: number[] = [0, 0];

  onTextChanged(text) {
    this.text = text;
    this.textChange.next(text);
  }

  private updateSelection() {
    const {
      selectionStart,
      selectionEnd,
    } = this.highlightableTextarea.nativeElement;
    this.selectedRange = [selectionStart, selectionEnd];
    let selectedText = '';

    if (this.text) {
      selectedText = this.text.substring(
        this.selectedRange[0],
        this.selectedRange[1]
      );
    }

    this.highlightedTextChange.next(selectedText);
  }
  updateClickPosition() {
    const {
      selectionStart,
      selectionEnd,
    } = this.highlightableTextarea.nativeElement;
    //check if no selection
    if (selectionStart === selectionEnd) {
      this.cursorPositionChange.next(selectionStart);
    }
  }

  onTextAreaClicked() {
    this.updateSelection();
  }

  onTextAreaKeyPressed() {
    this.updateSelection();
  }
}
