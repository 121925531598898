import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SingleInvitation, UserRoleType } from '../../interfaces/interfaces';
import { SessionApiService } from '../session/session-api.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  public urls = {
    subject: commonenv.nextGenApiUrl + 'invitations/send-appinvite-link',
    collaborator: commonenv.nextGenApiUrl + 'invitations/send-invite-link',
    director: commonenv.nextGenApiUrl + 'company/invite-directors',
    hosting: commonenv.nextGenApiUrl + 'invitations/send-hosting-link',
  };
  constructor(public httpClient: HttpClient, private sessionApiService: SessionApiService) {}

  inviteCollaborator(session_id: number, email: string, role: UserRoleType, name?: string) {
    const data = {
      session_id,
      user_data: JSON.stringify([
        {
          email,
          role,
          name,
        },
      ]),
    };
    return this.httpClient.post(this.urls.collaborator, data);
  }

  inviteSubject(session_id: number, email: string, name?: string) {
    const data = {
      session_id,
      invite_type: 'email',
      user_data: JSON.stringify([
        {
          email,
          name,
        },
      ]),
    };
    return this.httpClient.post(this.urls.subject, data);
  }

  async inviteFromListCreator(sessionId: number, invitations: SingleInvitation[], oldInvitations: SingleInvitation[]) {
    const toRemoveInvitees = oldInvitations.filter((o) =>
      !invitations.find((x) => x.mapping_id === o.mapping_id) ? true : false
    );
    if (toRemoveInvitees.length > 0) {
      await this.removeInviteFromList(toRemoveInvitees);
    }

    const newInvitees = invitations.filter((o) => !o.mapping_id);
    await this.newInviteFromListCreator(sessionId, newInvitees);
    const updateOldInvitees = invitations.filter((o) => {
      const oldInvitee = oldInvitations.find((x) => x.mapping_id === o.mapping_id);
      if (o.mapping_id && (oldInvitee.email !== o.email || oldInvitee.name !== o.name || oldInvitee.type !== o.type)) {
        return o;
      }
    });
    if (updateOldInvitees.length > 0) {
      await this.updateAndResendInviteFromList(sessionId, updateOldInvitees);
    }
  }

  async newInviteFromListCreator(session_id: number, invitations: SingleInvitation[]) {
    const promises: Promise<unknown>[] = [];
    let sent = 0;
    for (const inv of invitations) {
      if (!!inv.email && !!inv.name) {
        if (inv.type === UserRoleType.Subject) {
          promises.push(this.inviteSubject(session_id, inv.email, inv.name).toPromise());
        } else {
          promises.push(this.inviteCollaborator(session_id, inv.email, inv.type, inv.name).toPromise());
        }
        sent++;
      }
    }
    await Promise.all(promises);
    return sent;
  }

  async updateAndResendInviteFromList(sessionId: number, invitations: SingleInvitation[]) {
    const promises: Promise<unknown>[] = [];
    let sent = 0;
    for (const inv of invitations) {
      if (!!inv.email && !!inv.name) {
        promises.push(
          this.sessionApiService
            .updateAndResendInvitationMail(inv.mapping_id, {
              session_id: sessionId,
              invitee_email: inv.email,
              invitee_name: inv.name,
              invitee_type: inv.type,
            })
            .toPromise()
        );
        sent++;
      }
    }
    await Promise.all(promises);
    return sent;
  }

  async removeInviteFromList(invitations: SingleInvitation[]) {
    const promises: Promise<unknown>[] = [];
    let sent = 0;
    for (const inv of invitations) {
      if (!!inv.email && !!inv.name) {
        promises.push(this.sessionApiService.removeInviteeImmediately(inv.mapping_id).toPromise());
        sent++;
      }
    }
    await Promise.all(promises);
    return sent;
  }

  inviteDirectors(invitations: SingleInvitation[]) {
    return this.httpClient.post(this.urls.director, { directors: invitations });
  }

  //
  // Invite to Hosting platform
  //
  invite2Hosting(data: { email: string[]; message: string; type: string; id: number }) {
    const data2go = {
      email: data.email,
      message: data.message,
      type: data.type,
      id: Number(data.id),
    };

    return this.httpClient.post(this.urls.hosting, data2go);
  }
}
