import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  GenericAlertComponent,
  ModalAlertData,
  DialogCloseData,
} from '../../components/generic-alert/generic-alert.component';

@Injectable({
  providedIn: 'root',
})
export class GenericAlertService {
  constructor(public dialog: MatDialog) {}

  openAlertModal(data: ModalAlertData): Observable<DialogCloseData> {
    const dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '500px',
      disableClose: true,
      data: data,
    });
    return dialogRef.afterClosed();
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
