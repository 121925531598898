import { NgModule } from '@angular/core';
import { ClipDuration } from './clip-duration.pipe';
import { ClipGroupStatus } from './clip-group-status.pipe';
import { ClipSize } from './clip-size.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SecondsToHms } from './secondsToHms.pipe';
import { SecondsToMinSec } from './secondsToMinSec.pipe';
import { UserImgDataPipe } from './user-img-data.pipe';
import { WordCount } from './word-count.pipe';
import { DownloadUrlPipe } from './download-url.pipe';
import { TrimPipe } from './trim.pipe';
import { IsExpiredPipe } from './is-expired.pipe';
import { RoundPipe } from './round.pipe';
import { MsToMinSecPipe } from './msToMinSec.pipe';

const pipes = [
  SafeHtmlPipe,
  UserImgDataPipe,
  ClipDuration,
  ClipSize,
  SecondsToHms,
  SecondsToMinSec,
  MsToMinSecPipe,
  WordCount,
  ClipGroupStatus,
  DownloadUrlPipe,
  TrimPipe,
  RoundPipe,
  IsExpiredPipe,
];

@NgModule({
  declarations: [...pipes],
  imports: [],
  exports: [...pipes],
})
export class OpenreelCommonPipesModule {}
