import { createSelector } from '@ngrx/store';
import { RootState } from '../root-state';
import { AuthState } from './auth.state';

const selectFeature = (state: RootState): AuthState => state.auth;

export const selectAuthToken = createSelector(
  selectFeature,
  (authState) => authState.token
);

export const selectAuthRole = createSelector(
  selectFeature,
  (authState) => authState.role
);

export const selectAdmissionStatus = createSelector(
  selectFeature,
  authState => ({ admissionStatus: authState.admissionStatus, admissionDirector: authState.admissionDirector })
)
