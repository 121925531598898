import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FontDOMService } from '@openreel/common';
import { cloneDeep } from 'lodash';
import Lottie, { AnimationConfigWithData, AnimationItem } from 'lottie-web';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { CuePlayerBaseComponent } from '../interfaces/cue-player-base.interface';
import { LottiePlayerData, LottieRenderer } from '../interfaces/player-data.interfaces';
import { LottieTransformer } from '@openreel/lottie-parser';

const SIXTY_FRAMES_PER_SECOND = 1000 / 60;

@Component({
  selector: 'openreel-cue-player-lottie',
  templateUrl: './cue-player-lottie.component.html',
  styleUrls: ['./cue-player-lottie.component.scss'],
  providers: [
    {
      provide: CuePlayerBaseComponent,
      useExisting: forwardRef(() => CuePlayerLottieComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerLottieComponent extends CuePlayerBaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container')
  private readonly container: ElementRef<HTMLDivElement>;
  private animation: AnimationItem;
  private framesPerSecond = 0;
  private currentFrame = 0;

  private animationDuration = 0;
  private interval = null;

  private elapsedTime: number;
  private lastTimeTick: number;

  @Input() data: LottiePlayerData;

  constructor(private readonly fontDomService: FontDOMService) {
    super();
  }

  ngAfterViewInit(): void {
    this.fontDomService.fontsLoading$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((fontsLoading) => fontsLoading === true),
        take(1)
      )
      .subscribe(() => this.loadAnimation());
  }

  loadAnimation(): void {
    this.animation = Lottie.loadAnimation(this.createOptions());
    this.animation.addEventListener('data_failed', () => this.onAnimationDataFailed());
    this.animation.addEventListener('DOMLoaded', () => this.onAnimationDataReady());
  }

  ngOnDestroy() {
    this.stopTimeUpdateInterval();

    if (this.animation) {
      this.animation.destroy(this.id);
    }
  }

  onAnimationDataReady() {
    if (this.isLoaded) {
      return;
    }

    this.animationDuration = this.animation.getDuration(false);
    if (this.data.loop) {
      this.duration = Number.MAX_SAFE_INTEGER;
    } else {
      this.duration = this.data.duration ?? this.animationDuration * 1000;
    }

    this.framesPerSecond = this.animation.getDuration(true) / this.animationDuration;

    this.isLoaded = true;
    this.emitLoadedEvent();
  }

  onAnimationDataFailed() {
    this.emitErrorEvent(new Error('Failed to load animation data'));
  }

  onAnimationTimeUpdate() {
    if (!this.isLoaded) {
      return;
    }

    const offset = this.elapsedTime - this.duration;

    if (offset >= 0 && this.isPlaying()) {
      this.elapsedTime = 0;
      this.pause();
      this.emitEndedEvent();
    } else {
      this.emitTimeUpdateEvent(this.elapsedTime);
    }
  }

  currentTime(time?: number): number {
    if (time || time === 0) {
      this.emitSeekingEvent();
      this.elapsedTime = time;

      this.currentFrame = Math.min(this.animation.totalFrames, (time / 1000) * this.framesPerSecond);
      this.stopTimeUpdateInterval();
      if (this.isPlaying()) {
        this.startTimeUpdateInterval();
      }

      this.animation.goToAndStop(this.currentFrame, true, this.id);
      this.emitSeekedEvent();
    }

    return (this.currentFrame / this.framesPerSecond) * 1000;
  }

  isPlaying(): boolean {
    return this.interval !== null;
  }

  async play() {
    if (!this.isPlaying()) {
      this.animation.play(this.id);
      this.startTimeUpdateInterval();
    }

    return Promise.resolve();
  }

  pause() {
    this.animation.pause(this.id);
    this.stopTimeUpdateInterval();
  }

  stop() {
    if (this.isPlaying() || this.currentFrame > 0) {
      this.animation.stop(this.id);
      this.stopTimeUpdateInterval();
      this.elapsedTime = null;
    }
  }

  private startTimeUpdateInterval() {
    if (this.elapsedTime === null || this.elapsedTime === undefined) {
      this.elapsedTime = 0;
    }
    this.lastTimeTick = performance.now();
    this.interval = setInterval(() => {
      const timeTick = performance.now();
      const timeDiff = timeTick - this.lastTimeTick;
      this.lastTimeTick = timeTick;
      this.elapsedTime += timeDiff;

      this.onAnimationTimeUpdate();
    }, SIXTY_FRAMES_PER_SECOND);
  }

  private stopTimeUpdateInterval() {
    this.lastTimeTick = null;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private createOptions(): AnimationConfigWithData<LottieRenderer> {
    const hasTransformationFields = Object.entries(this.data.preset).some(
      ([, presetField]) => !!presetField.lottiePath
    );

    let animationData: unknown;
    if (hasTransformationFields) {
      const transformer = new LottieTransformer(this.data.animation);
      animationData = transformer.transform(this.data.data, this.data.preset);
    } else {
      animationData = cloneDeep(this.data.animation);
    }

    const config: AnimationConfigWithData<LottieRenderer> = {
      name: this.id,
      container: this.container.nativeElement,
      renderer: this.data.renderer,
      loop: !!this.data.loop,
      autoplay: false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        imagePreserveAspectRatio: 'xMidYMid meet',
      },
      animationData,
    };

    return config;
  }
}
