import { CuePlayerItemComponent } from './cue-player-item.component';
import { TransitionType } from '@openreel/creator/common';

export class TransitionPlayer {
  public get currentTime() {
    return this.animation.currentTime;
  }

  private animation: Animation;

  constructor(
    private readonly element: HTMLElement,
    private readonly startAt: number,
    private readonly duration: number,
    private readonly transitionType: TransitionType,
    private readonly transitionPlayer?: CuePlayerItemComponent,
    readonly onTransitionEnd?: () => void
  ) {
    if (this.transitionType === 'fade-out') {
      this.animation = this.element.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: this.duration,
        easing: 'linear',
        fill: 'forwards',
      });
    } else if (this.transitionType === 'fade-in') {
      this.animation = this.element.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: this.duration,
        easing: 'linear',
        fill: 'forwards',
      });
    } else if (this.transitionType === 'layer') {
      this.animation = this.element.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: this.duration,
        easing: 'steps(2, jump-none)',
        fill: 'forwards',
      });
    } else {
      throw new Error(`TransitionType "${this.transitionType}" not supported`);
    }
    if (onTransitionEnd) {
      this.animation.onfinish = onTransitionEnd;
    }

    this.animation.pause();
  }

  play(time: number): boolean {
    if (!this.canPlay(time)) {
      return false;
    }

    const currentTime = time - this.startAt;

    this.animation.currentTime = currentTime;
    this.animation.play();

    if (this.transitionPlayer) {
      this.transitionPlayer.currentTime(currentTime);
      this.transitionPlayer.play();
    }

    return true;
  }

  pause(time: number) {
    const currentTime = time - this.startAt;

    this.animation.currentTime = currentTime;
    this.animation.pause();

    if (this.transitionPlayer) {
      this.transitionPlayer.currentTime(currentTime);
      this.transitionPlayer.pause();
    }
  }

  stop() {
    this.animation.cancel();
    this.transitionPlayer?.stop();
  }

  update(time: number): boolean {
    if (!this.canPlay(time)) {
      this.stop();
      return false;
    }

    this.pause(time);
    return true;
  }

  private canPlay(time): boolean {
    if (this.transitionType === 'fade-in' && time > this.startAt + this.duration) return false;
    if ((this.transitionType === 'fade-out' || this.transitionType === 'layer') && time < this.startAt) return false;
    return true;
  }
}
