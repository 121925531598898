import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  OnEndedEvent,
  OnErrorEvent,
  OnTimeUpdateEvent,
  OnTransitionStartEvent,
  PlayerEvent,
} from '../../interfaces/player-event.interfaces';

import { ControllerData } from '../../interfaces/player-data.interfaces';
import { CuePlayerBaseComponent } from '../../interfaces/cue-player-base.interface';
import { CuePlayerControllerComponent } from '../cue-player-controller/cue-player-controller.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'openreel-cue-player-controller-main',
  templateUrl: './cue-player-controller-main.component.html',
  styleUrls: ['./cue-player-controller-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerMainComponent extends CuePlayerBaseComponent implements AfterViewInit {
  @ViewChildren(CuePlayerControllerComponent)
  private readonly controllers: QueryList<CuePlayerControllerComponent>;
  private controllerIds: string[];
  private controllersById: { [id: string]: CuePlayerControllerComponent };
  private transitioningControllerId: string;
  private activeControllerIndex = 0;
  private totalCurrentTime = 0;
  private resetOnPlay = false;

  private get activeController() {
    return this.getPlayerByIndex(this.activeControllerIndex);
  }

  private get loadedControllerCount() {
    return this.controllerIds.filter((id) => this.controllersById[id].isLoaded).length;
  }

  @Input() data: ControllerData[];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.initControllerLists();

    this.controllers.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.initControllerLists();
      this.changeDetectorRef.markForCheck();
    });
  }

  onChildPlayerLoaded() {
    if (this.loadedControllerCount === this.controllerIds.length) {
      this.duration = this.calcDurationUpToIndex();
      this.isLoaded = true;

      this.emitLoadedEvent();
      this.emitTimeUpdateEvent(this.totalCurrentTime);
    }
  }

  onChildPlayerTimeUpdate({ id, currentTime }: OnTimeUpdateEvent) {
    if (this.controllerIds[this.activeControllerIndex] !== id) {
      return;
    }

    this.totalCurrentTime = currentTime + this.calcDurationUpToIndex(this.activeControllerIndex);
    this.emitTimeUpdateEvent(this.totalCurrentTime);
  }

  async onChildPlayerEnded({ id }: OnEndedEvent) {
    if (this.controllerIds[this.activeControllerIndex] !== id) {
      this.controllersById[id].stop();
      this.controllersById[id].hide = true;

      if (this.transitioningControllerId === id) {
        this.transitioningControllerId = null;
      }

      return;
    }

    if (this.activeControllerIndex === this.controllerIds.length - 1) {
      this.resetOnPlay = true;
      this.emitEndedEvent();
      return;
    }

    if (id === this.transitioningControllerId) {
      await this.keepAndPlayNext(0);
    } else {
      await this.switchAndPlayNext();
    }
  }

  async onChildPlayerTransitionStart({ id }: OnTransitionStartEvent) {
    if (this.activeControllerIndex === this.controllerIds.length - 1) {
      return;
    }

    if (this.controllerIds[this.activeControllerIndex] !== id) {
      return;
    }

    this.transitioningControllerId = id;
    await this.keepAndPauseNext(0);
  }

  async onChildPlayerTransitionEnd({ id }: PlayerEvent) {
    if (this.transitioningControllerId !== id) {
      return;
    }

    this.controllersById[id].hide = true;
    this.controllersById[id].stop();

    this.transitioningControllerId = null;
  }

  onChildPlayerSeeked() {
    this.emitSeekedEvent();
  }

  onChildPlayerError({ error }: OnErrorEvent) {
    this.emitErrorEvent(error);
  }

  currentTime(time?: number): number {
    if (time || time === 0) {
      this.emitSeekingEvent();
      this.resetOnPlay = false;
      const { index, isInTransition } = this.seekPlayerIndexesForTime(time);
      if (index === -1) {
        /* Some problem occurred. */
        this.stop();
        return this.totalCurrentTime;
      }

      this.activeControllerIndex = index;
      this.transitioningControllerId = null;
      const timeInController = time - this.calcDurationUpToIndex(index);

      for (const [i, controller] of this.controllers.toArray().entries()) {
        if (i === index) {
          controller.currentTime(timeInController);
          controller.hide = false;
        } else if (isInTransition && i === index - 1) {
          this.transitioningControllerId = controller.id;
          controller.currentTime(controller.transitionStart + timeInController);
          controller.hide = false;
        } else {
          controller.hide = true;
          controller.stop();
        }
      }

      this.totalCurrentTime = time;
    }

    return this.totalCurrentTime;
  }

  isPlaying(): boolean {
    if (this.activeController) {
      return this.activeController.isPlaying();
    } else {
      console.warn('no active player');
      return false;
    }
  }

  async play() {
    if (this.resetOnPlay) {
      this.resetOnPlay = false;
      this.stop();
    }

    if (this.transitioningControllerId) {
      await this.controllersById[this.transitioningControllerId].play();
    }

    await this.activeController.play();
  }

  pause() {
    if (this.transitioningControllerId) {
      this.controllersById[this.transitioningControllerId].pause();
    }

    this.activeController.pause();
  }

  stop() {
    this.switchPlayers(0);

    this.controllerIds.forEach((id) => {
      this.controllersById[id].stop();
    });

    this.totalCurrentTime = 0;
    this.emitTimeUpdateEvent(0);
  }

  private initControllerLists() {
    this.controllerIds = this.controllers.map((controller) => controller.id);
    this.controllersById = this.controllers.reduce((acc, controller) => {
      acc[controller.id] = controller;
      return acc;
    }, {});
  }

  private async switchAndPlayNext() {
    const nextPlayer = this.activeControllerIndex + 1;
    this.switchPlayers(nextPlayer);
    await this.activeController.play();
  }

  private async keepAndPauseNext(offset: number = 0) {
    const nextIndex = this.activeControllerIndex + 1;
    if (nextIndex >= this.controllers.length) {
      return;
    }

    const nextPlayer = this.getPlayerByIndex(nextIndex);
    nextPlayer.hide = false;
    nextPlayer.currentTime(offset);
    await nextPlayer.pause();
  }

  private async keepAndPlayNext(offset: number = 0) {
    const nextIndex = this.activeControllerIndex + 1;
    if (nextIndex >= this.controllers.length) {
      return;
    }

    const nextPlayer = this.getPlayerByIndex(nextIndex);
    this.activeControllerIndex = nextIndex;
    nextPlayer.hide = false;
    nextPlayer.currentTime(offset);
    await nextPlayer.play();
  }

  private calcDurationUpToIndex(lastIndex?: number): number {
    const toCalc = lastIndex >= 0 ? this.controllerIds.slice(0, lastIndex) : this.controllerIds;

    return toCalc.reduce(
      (p, c, i) =>
        p +
        (i < toCalc.length - 1 || lastIndex
          ? this.controllersById[c].controlDuration
          : this.controllersById[c].duration),
      0
    );
  }

  private switchPlayers(nextIndex: number) {
    if (nextIndex === this.activeControllerIndex) {
      return;
    }

    const previousPlayer = this.getPlayerByIndex(this.activeControllerIndex);
    const nextPlayer = this.getPlayerByIndex(nextIndex);
    this.activeControllerIndex = nextIndex;

    if (previousPlayer) {
      previousPlayer.stop();
      previousPlayer.hide = true;
    }

    nextPlayer.hide = false;
  }

  private getPlayerByIndex(index: number): CuePlayerControllerComponent {
    return this.controllersById[this.controllerIds[index]];
  }

  private seekPlayerIndexesForTime(time: number): {
    index: number;
    isInTransition: boolean;
  } {
    let seekTime = 0;
    let seekIndex = 0;
    let isInTransition = false;

    while (seekIndex < this.controllerIds.length && seekTime < time) {
      isInTransition = seekIndex > 0 && time < seekTime + this.getPlayerByIndex(seekIndex - 1).transitionDuration / 2;
      seekTime += this.getPlayerByIndex(seekIndex).controlDuration;

      if (seekTime < time) {
        seekIndex++;
      }
    }

    if (seekIndex >= this.controllerIds.length) {
      /* The index was not found */
      this.emitErrorEvent(new Error(`Unable to find the correct time location for ${time}ms`));
      seekIndex = -1;
    }

    return {
      index: seekIndex,
      isInTransition,
    };
  }
}
