<div
  #controllerContainer
  class="controller"
  [id]="id"
  [ngStyle]="{
    top: styles?.layout?.y,
    left: styles?.layout?.x,
    width: hide ? 0 : styles?.layout?.width,
    height: hide ? 0 : styles?.layout?.height,
    opacity: hide ? 0 : 1,
    background: styles?.background,
    padding: styles?.padding,
    borderWidth: styles?.borderWidth,
    borderColor: styles?.borderColor,
    borderRadius: styles?.borderRadiusOuter,
    borderStyle: styles?.borderWidth ? 'solid' : 'none'
  }"
>
  <openreel-cue-player-selectable
    *ngIf="isInteractable"
    [layout]="layout"
    [defaultLayout]="defaultLayout"
    [styles]="data.styles"
    [interactions]="interactions"
    [player]="player"
    (updateBounds)="onUpdateBounds($event)"
  ></openreel-cue-player-selectable>
  <div
    #playerContainer
    class="content"
    [ngStyle]="{
      borderRadius: styles?.borderRadiusInner
    }"
  >
    <openreel-cue-player-item
      #player
      [playerData]="data.playerData"
      [styles]="data.styles"
      (onLoaded)="onChildPlayerLoaded()"
      (onTimeUpdate)="onChildPlayerTimeUpdate($event)"
      (onEnded)="onChildPlayerEnded()"
      (onSeeked)="onChildSeeked()"
      (onSeeking)="onChildSeeking()"
      (onError)="onChildError($event)"
    ></openreel-cue-player-item>
  </div>
</div>

<div *ngIf="data.transitions?.crossLayer?.playerData" class="transition">
  <openreel-cue-player-item
    #transitionCuePlayer
    [playerData]="data.transitions?.crossLayer?.playerData"
    (onEnded)="emitTransitionEndEvent()"
  ></openreel-cue-player-item>
</div>
