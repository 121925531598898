import { CommonModule } from '@angular/common';
import { Ng5SliderModule } from 'ng5-slider';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { pipes } from './pipes';
import { OpenreelCommonDirectivesModule } from '../../../../../libs/common/src/directives/openreel-common-directives.module';
import { MaterialModule } from '@openreel/ui';

const declarations = [...pipes];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    OpenreelCommonDirectivesModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ...declarations,
  ],
})
export class SharedModule {}
