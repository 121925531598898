export interface RtmpData {
  id: string;
  status: string;
}
export interface LiveBroadcast {
  hls: string;
  liveStatus?: boolean;
  streams?: string[];
  rtmp: RtmpData[];
}

export const liveDestinationData = [
  { value: 'facebook', name: 'Facebook Live' },
  { value: 'youtube', name: 'YouTube' },
  { value: 'custom', name: 'Custom RTMP' },
];

export const getDestinationName = (value: string) => liveDestinationData.filter((data) => data.value === value)[0].name;
export interface LiveDestinations {
  name: string;
  value: string;
  url: string;
  key: string;
}
