import * as fromActions from '../actions/project.actions';

import { createReducer, on } from '@ngrx/store';

import { Project } from './../interfaces/project.interface';

export const initialState: Partial<Project> = {
  loaded: false,
  loading: false,
  building: false,
  loadFinished: false,
  data: null,
  lastSavedData: null,
};

const reducer = createReducer(
  initialState,
  on(fromActions.loadProjectAPI, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    loadFinished: false,
  })),
  on(fromActions.cleanupState, () => ({ ...initialState })),

  on(fromActions.loadProjectSuccess, (state, { data }) => ({
    loaded: true,
    loading: false,
    loadFinished: false,
    data,
    lastSavedData: data,
  })),
  on(fromActions.loadProjectFailure, () => ({
    loaded: false,
    loading: false,
  })),
  on(fromActions.loadProjectFinished, (state) => ({
    ...state,
    loadFinished: true,
  })),

  // NOTE: saving updated workflow when triggering API call so that subsequent requests can use this new updated workflow
  // even before response comes
  on(fromActions.updateProjectWorkflowAPI, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      workflow: action.data,
      brandKitHasNewerVersion: action.updateBrandKitToLatestTimestamp
        ? false
        : state.data.brandKitHasNewerVersion,
    },
    updateSource: action.updateSource,
  })),
  on(fromActions.updateProjectSuccess, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      name: action.data.name,
      brandKitHasNewerVersion: action.data.brandKitHasNewerVersion,
    },
    lastSavedData: { ...action.data },
  })),
  on(fromActions.updateProjectFailure, (state) => ({
    ...state,
    data: { ...state.lastSavedData },
  })),

  // Rendering
  on(fromActions.triggerRenderProject, (state) => ({
    ...state,
    building: true,
  })),
  on(fromActions.renderProject, (state) => ({
    ...state,
    building: true
  })),
  on(fromActions.cancelProjectRender, (state) => ({
    ...state,
    building: false
  })),
  on(fromActions.renderProjectAccepted, (state) => ({
    ...state,
  })),
  on(fromActions.setRenderProgress, (state, { renderProgress }) => ({
    ...state,
    data: {
      ...state.data,
      video: {
        renderedAt: renderProgress.renderedAt,
        state: renderProgress.state,
        signedUrl: renderProgress.signedUrl,
      },
    },
    renderProgress,
    building: renderProgress.state === 'rendering'
  })),
  // Transcribing
  on(fromActions.setTranscribeProgress, (state, { transcribeProgress }) => ({
    ...state,
    transcribeProgress,
  })),
  on(fromActions.toggleHasCaptions, (state, { hasCaptions }) => ({
    ...state,
    data: {
      ...state.data,
      hasCaptions
    },
  })),
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
