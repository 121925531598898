import { Manager } from 'socket.io-client';

// import { patch } from './socket.wildcard';
import {
  AnySocketDataHandler,
  ISocketIoLib,
  ISocketIoLibService,
  SocketConnectionStatus,
  SocketData,
} from '../../interfaces/socket-lib-service.interface';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { NetworkService } from '../network/network.service';
import { isRunningHotReload } from '../../utils';
import { skip } from 'rxjs/operators';

export interface AnySocketData {
  data: [string, SocketData];
}

// Concrete implementation of socket.io wrapper. More info you can find in
// ISocketIoLib comments.
class SocketIoWrapper implements ISocketIoLib {
  // socket.io instance
  private mySocket: SocketIOClient.Socket;

  // Any handlers that were added before we called .connect()
  private addedHandlersBeforeInitialization = new Map<
    string,
    Array<AnySocketDataHandler>
  >();

  // subscription to service that tells us if we are connected to internet or
  // not
  private onlineSubscription: Subscription;

  constructor(private networkService: NetworkService) {}
  connectionStatus$ = new BehaviorSubject<SocketConnectionStatus>({
    connected: false,
  });
  connect(url: string, namespace?: string) {
    const options: SocketIOClient.ConnectOpts = { transports: ['websocket'] };
    const { pathname, origin, search } = new URL(url);
    if (pathname && pathname !== '/') {
      options.path = pathname;
    }

    const manager = new Manager(`${origin}${search || ''}`, options);
    const ret = manager.socket(namespace ? namespace : '/');

    const patch = require('socketio-wildcard');
    // by default socket.io doesn't give us ability to listen to any event.
    // we use this patcher to give us .on('*') listener
    patch(Manager)(ret);
    // Add any handlers we added before calling connect function
    for (const [eventName, handlers] of this
      .addedHandlersBeforeInitialization) {
      for (const handler of handlers) {
        ret.on(eventName, handler);
      }
    }
    // since data received in * event is not fully compatible with what we
    // want, we manually re-transmit data to *any* listeners
    ret.on('*', (data: AnySocketData) => {
      const listeners = ret.listeners('*any*') as AnySocketDataHandler[];
      listeners.forEach((l) => l(data.data[0], data.data[1]));
    });
    ret.on('disconnect', () => {
      this.handleDisconnect();
    });
    ret.on('reconnect', () => {
      this.handleReconnect();
    });
    ret.on('connect', () => {
      this.handleConnect();
    });
    // If you want to test connecting/disconnecting from sockets manually
    if (isRunningHotReload()) {
      (window as Window &
        typeof globalThis & {
          currentSocket: SocketIOClient.Socket;
        }).currentSocket = ret;
    }
    this.mySocket = ret;
    this.onlineSubscription = this.networkService.online$
      .pipe(skip(1))
      .subscribe((online) => {
        this.handleNetworkStatus(online);
      });
  }

  // When browser tells us that we turned off/on wifi connection
  private handleNetworkStatus(online: boolean) {
    if (!this.mySocket) {
      return;
    }
    if (online) {
      this.mySocket.connect();
    }
    if (!online) {
      this.mySocket.disconnect();
    }
  }

  private handleDisconnect() {
    console.log('Socket.io disconnected');
    this.connectionStatus$.next({
      connected: false,
    });
  }

  private handleReconnect() {
    console.log('Socket.io reconnected');
    // this.connectionStatus.next({
    //     connected: true
    // });
  }

  private handleConnect() {
    console.log('Socket.io connected');
    this.connectionStatus$.next({
      connected: true,
    });
  }

  on(eventName: '*any*', handler: AnySocketDataHandler) {
    // if we initialized the socket, we add the handler to .on regularly
    if (this.mySocket) {
      this.mySocket.on(eventName, handler);
    } else {
      // Otherwise, we put it in array to be added after connection is made
      if (!this.addedHandlersBeforeInitialization.has(eventName)) {
        this.addedHandlersBeforeInitialization.set(eventName, []);
      }
      this.addedHandlersBeforeInitialization.get(eventName).push(handler);
    }
  }

  emit(event: string, data: unknown) {
    if (!this.mySocket) {
      return;
    }
    this.mySocket.emit(event, data);
  }

  disconnect() {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }
    if (this.mySocket) {
      this.mySocket.disconnect();
    }
  }
}

// Utility service that returns instance of socket.io wrapper
@Injectable({
  providedIn: 'root',
})
export class SocketIoProvider extends ISocketIoLibService {
  constructor(private networkService: NetworkService) {
    super();
  }
  createSocket(): ISocketIoLib {
    return new SocketIoWrapper(this.networkService);
  }
}
