import { TimelineType } from '@openreel/creator/common';
import { clamp } from 'lodash-es';

export const SECTION_INDEX_TIMELINES = 1;

export const PROGRAMATIC_SELECTION_CLEAR_EVENT_TYPE = 'programatic:selection:clear';
export const PROGRAMATIC_GROUP_SELECTION_EVENT_TYPE = 'programatic:group:selection';

export const CANVAS_NAME = 'canvas';
export const TIME_CURSOR_NAME = 'time-cursor';
export const GROUP_SELECTION_NAME = 'group-selection-name';

export const TRIMMER_Z_INDEX = 9000;
export const TIME_CURSOR_Z_INDEX = 99999;

export class Viewport {
  startAt = 0;
  duration = 0;

  width = 0;

  private totalDuration = 0;

  get endAt() {
    return this.startAt + this.duration;
  }

  isZoomed() {
    return this.startAt > 0 || this.duration < this.totalDuration;
  }

  setTime(startAt: number, viewportDuration: number, totalDuration: number) {
    this.startAt = startAt;
    this.duration = viewportDuration;
    this.totalDuration = totalDuration;
  }

  setWidth(width: number) {
    this.width = width;
  }
}

export type SectionType = 'intro' | 'outro' | 'main';

// Data
export interface ObjectTiming {
  startAt: number;
  endAt: number;
  duration: number;
}
export interface SectionData extends ObjectTiming {
  type: SectionType;
  timelines: TimelineData[];
}

export interface TimelineData extends ObjectTiming {
  type: TimelineType;
  index: number;
  items: ItemData[];
}

export interface ItemData extends ObjectTiming {
  layerId: string;
  linkedLayerId?: string;
  minDuration?: number;
  video?: {
    id: string;
    trimFrom: number;
    trimTo: number;
  };
}

// Draw
export class ObjectDrawInfo {
  bounds: BoundsInfo = BoundsInfo.empty();
  timing: TimingInfo = TimingInfo.empty();
  children: ObjectDrawInfo[] = [];

  singleSelectionOnly: boolean;
  reorderable: boolean;
  gap: number;
  linkedObjectName: string;
  minDuration?: number;
  video?: {
    id: string;
    trimFrom: number;
    trimTo: number;
  };

  constructor(public name: string, public parent?: ObjectDrawInfo) {}

  setBoundsAndTiming(bounds: BoundsInfo, timing: TimingInfo) {
    this.update(bounds, timing);
  }

  calculateBoundsFromTiming(count: number = 0, index: number = 0) {
    const { bounds: parentBounds, timing: parentTiming, gap } = this.parent;

    // Calculate width & positions with regards to gaps
    const gapsTotalWidth = (count - 1) * gap;
    const widthMinusGaps = parentBounds.width - gapsTotalWidth;
    const parentPxPerMs = widthMinusGaps / parentTiming.duration;
    const previousGaps = gap * index;

    const objectLeft = parentBounds.left + this.timing.startAt * parentPxPerMs + previousGaps;
    const objectLeftRelativeToParent = objectLeft - parentBounds.left;

    // Calculate width for object but make sure it doesnt break out of parent bound
    // We use this to prevent video overlays from breaking out when they finish after last main clip
    const objectWidth = clamp(this.timing.duration * parentPxPerMs, 0, widthMinusGaps - objectLeftRelativeToParent);

    this.update(
      BoundsInfo.from({
        ...this.bounds,
        left: objectLeft,
        width: objectWidth,
      })
    );
  }

  private update(bounds: BoundsInfo, timing: TimingInfo = null) {
    this.bounds.set(bounds);

    if (timing) {
      this.timing.setValues(timing.startAt, timing.endAt);
    }
  }
}

export interface Bounds {
  left: number;
  top: number;
  width: number;
  height: number;
}

export class BoundsInfo implements Bounds {
  constructor(public left: number, public top: number, public width: number, public height: number) {}

  static empty() {
    return new BoundsInfo(0, 0, 0, 0);
  }

  static from({ left, width, top, height }: Bounds) {
    return new BoundsInfo(left, top, width, height);
  }

  get bottom() {
    return this.top + this.height;
  }

  get right() {
    return this.left + this.width;
  }

  get centerPoint() {
    return this.left + Math.floor(this.width / 2);
  }

  set({ left, width, top, height }: Bounds) {
    this.left = left;
    this.width = width;
    this.top = top;
    this.height = height;
  }
}

export class TimingInfo {
  constructor(public startAt: number, public endAt: number) {}

  static empty() {
    return new TimingInfo(0, 0);
  }

  static from({ startAt, endAt }: TimingInfo) {
    return new TimingInfo(startAt, endAt);
  }

  get duration() {
    return this.endAt - this.startAt;
  }

  get centerPoint() {
    return this.startAt + Math.floor(this.duration / 2);
  }

  addDelta(delta: number) {
    this.startAt += delta;
    this.endAt += delta;
  }

  setValues(startAt: number, endAt: number) {
    this.startAt = startAt;
    this.endAt = endAt;
  }

  set({ startAt, endAt }: TimingInfo) {
    this.startAt = startAt;
    this.endAt = endAt;
  }
}

export interface OverlapObjectInfo {
  middlePointPassed: boolean;
  item: ObjectDrawInfo;
}

// Events
export interface DropItemEvent {
  layerId: string;
  newStartAt: number;
}

export interface UpdateTrimBoundsEvent {
  index: number;
  from: number;
  to: number;
  done: boolean;
}
