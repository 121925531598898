import { Component, Input } from '@angular/core';
import { LayerService, UiControl } from '../../services/layer.service';

import { FormControl } from '@angular/forms';
import { LayerEventsService } from '../../services/layer-events.service';

@Component({
  selector: 'openreel-layer-item-video',
  templateUrl: './layer-item-video.component.html',
  styleUrls: ['./layer-item-video.component.scss'],
})
export class LayerItemVideoComponent {
  @Input() uiControl: UiControl;
  @Input() control: FormControl;
  @Input() disabled = false;
  @Input() uploadedClasses = '';

  constructor(private readonly layerService: LayerService, private readonly layerEventsService: LayerEventsService) {}

  onVideoRemove() {
    const existingAssetId = this.control.value.assetId;
    this.control.patchValue({
      assetId: null,
      assetFileId: null,
      newAssetFileId: null,
      removedAssetId: existingAssetId,
    });
  }

  onUploadStarted() {
    this.layerEventsService.setAssetUploading(this.uiControl.field, true);
  }

  onUploadFailed() {
    this.layerEventsService.setAssetUploading(this.uiControl.field, false);
  }

  onUploadDone(sourceId: number) {
    this.layerEventsService.setAssetUploading(this.uiControl.field, false);
    const existingAssetId = this.control.value.assetId;

    this.control.patchValue({
      assetId: null,
      assetFileId: sourceId,
      newAssetFileId: sourceId,
      removedAssetId: existingAssetId,
    });
  }

  validationErrors = () => this.layerService.getValidationErrors(this.control.errors);
}
