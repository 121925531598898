export interface TemplateSettings {
  moveAndResize: boolean;
  showOnTemplatePage: boolean;
}

export type TemplateSettingsLoggingValue = 'move_and_resize' | 'show_on_template_page';
export type TemplateSettingsLoggingLabel = 'move and resize' | 'show on templates page';

export const TEMPLATE_SETTINGS_LOGGING_VALUE: { [key: string]: TemplateSettingsLoggingValue } = {
  moveAndResize: 'move_and_resize',
  showOnTemplatePage: 'show_on_template_page',
};

export const TEMPLATE_SETTINGS_LOGGING_LABEL: { [key: string]: TemplateSettingsLoggingLabel } = {
  moveAndResize: 'move and resize',
  showOnTemplatePage: 'show on templates page',
};
