import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'openreel-text-field',
  templateUrl: './ortextfield.component.html',
  styleUrls: ['./ortextfield.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrTextFieldComponent {
  @Input()
  placeholder: string;

  @Input()
  value: string;

  @Output()
  valueChange = new EventEmitter<string>();

  onChange() {
    this.valueChange.emit(this.value);
  }
}
