import {
  ConnectableObservable,
  from,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';
import { CountdownComponent } from './../../countdown/countdown.component';
import { SocketDirectorExtensionTeleprompterService } from './../../../services/director/socket-extensions/socket-director-extension-teleprompter.service';
import { Cleanupable } from '../../../classes/cleanupable';
import { TelepromterApiService } from './../../../services/director/director-api/telepromter-api.service';
import { MatDialog } from '@angular/material/dialog';
import {
  TeleprompterScript,
  TeleprompterScriptCreate,
  TeleprompterScriptEdit,
  TeleprompterScriptProgressStatus,
} from './../../../interfaces/interfaces';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { TeleprompterPreviewPopupComponent } from '../teleprompter-preview-popup/teleprompter-preview-popup.component';
import { ToastrService } from 'ngx-toastr';
import {
  TeleprompterSocketRequest,
  CaptureTeleprompterStatus,
  CaptureTeleprompterCommand,
  TeleprompterUpdateInfo,
  TeleprompterPresetsDto,
  POPUP_BASE_WIDTH,
  POPUP_BASE_HEIGHT,
} from '../../../interfaces/teleprompter.interface';
import { TeleprompterPresetsFormComponent } from '../teleprompter-presets-form/teleprompter-presets-form.component';
import { SessionSettingsDto } from 'libs/common/src/services/session/session.interfaces';
import { SessionApiService } from 'libs/common/src/services/session/session-api.service';
import { FormControl } from '@angular/forms';
import {
  filter,
  first,
  map,
  publish,
  scan,
  switchMap,
  takeUntil,
  takeWhile,
  throttleTime,
} from 'rxjs/operators';
import { RichTextEditorComponent } from '../../rich-text-editor/rich-text-editor.component';
import { FroalaCommand } from '../../rich-text-editor/rich-text-custom-command';
import { NextgenParticipant, TeleprompterParticipant } from '@openreel/common';
import { throttle } from '../../../utils';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

const CURSOR_HTML = '<span class="currentPosition"></span>';
const MAIN_PREVIEW_ID = '-1';

/**
 * A director-side component, used for setting up, previewing, and remotely controlling
 * subject's teleprompter. This component supports controlling multiple subjects, however only
 * in the same state. For example, when paused all selected subjects are paused
 *
 * We have two scenarios:
 * - script is selected from the dropdown (can update)
 * - no script is selected (can create)
 *
 * In both scenarios, text in the textarea can be sent to the subject or be previewed
 */
@Component({
  selector: 'openreel-capture-teleprompter',
  templateUrl: './capture-teleprompter.component.html',
  styleUrls: ['./capture-teleprompter.component.scss'],
})
export class CaptureTeleprompterComponent
  extends Cleanupable
  implements OnInit, OnChanges, OnDestroy
{
  // reference to countdown component
  @ViewChild(CountdownComponent) countdown: CountdownComponent;
  @Input() projectId: number;
  @Input() sessionId: number;
  @Input() set subjects(participants: TeleprompterParticipant[]) {
    this.devices = participants;
    this.checkTeleprompterStatus();
  }
  devices: TeleprompterParticipant[];

  @Input()
  teleprompterCounting$: Observable<boolean>;

  @Input()
  teleprompterStopped$: Observable<boolean>;

  @Input()
  isTeleprompterEnabledForAll$: Observable<boolean>;

  @Input()
  isPlayable$: Observable<boolean>;

  @Input()
  activeParticipantIdentities$: Observable<string[]>;

  @Input()
  activePlayingTeleprompterParticipantIdentities$: Observable<string[]>;
  // settings such as countdown value, should close script box once have been played
  @Input() sessionSettings: SessionSettingsDto;
  // settings such as speed, font size, screen setup, background color
  settings: TeleprompterPresetsDto;
  // list of teleprompter scripts for the project
  scripts: TeleprompterScript[] = [];
  selectedScript = new FormControl();

  @Output()
  teleprompterActiveToggle = new EventEmitter<{
    identities: string[];
    active: boolean;
    data?: TeleprompterSocketRequest;
  }>();

  @Output()
  playClick = new EventEmitter<string[]>();

  @Output()
  stopClick = new EventEmitter<string[]>();

  @Output()
  restartClick = new EventEmitter<string[]>();

  @Output()
  pauseClick = new EventEmitter<string[]>();

  // current text contained by textarea
  text: string;
  // highlighted part of the textarea
  // when this property is set, it is used for sending/previewing instead of text property
  highlightedText: string;
  CaptureTeleprompterStatus = CaptureTeleprompterStatus;
  cursorHtml: string;
  private contentChangeDetect = new Subject<{
    text: string;
    contentChange: boolean;
  }>();
  pendingTitleControl = new FormControl();
  // track all the preview windows open by user id
  previewPopupComponents = new Map<string, TeleprompterPreviewPopupComponent>();
  @ViewChild('teleprompterPreset')
  teleprompterPreset: TeleprompterPresetsFormComponent;
  @ViewChild('richTextEditor')
  richTextEditor: RichTextEditorComponent;
  hasSubjects$: Observable<boolean>;
  requestTeleprompterInfo = false;
  isScreenChange = false;
  changingTeleprompter = false;
  creatingScript = false;
  FroalaCommand = FroalaCommand;

  countdown$: ConnectableObservable<number>;

  constructor(
    private dialog: MatDialog,
    private socketTeleprompter: SocketDirectorExtensionTeleprompterService,
    private telepromterApiService: TelepromterApiService,
    public sessionApiService: SessionApiService,
    private toastr: ToastrService
  ) {
    super();
    this.subscriptions.push(
      this.socketTeleprompter.listenToTeleprompterInfo().subscribe((data) => {
        const [device] = this.devices.filter(
          (participant) => participant.identity === data.from
        );
        if (
          this.requestTeleprompterInfo &&
          !this.previewPopupComponents.has(device.identity)
        ) {
          this.requestTeleprompterInfo = false;
          this.openTeleprompterPreviewPopup(
            device.identity,
            data.data.teleprompterCurrentLine,
            data.data.teleprompterTotalLines,
            data.data.teleprompterWidth
              ? data.data.teleprompterWidth
              : POPUP_BASE_WIDTH,
            data.data.teleprompterHeight
              ? data.data.teleprompterHeight
              : POPUP_BASE_HEIGHT,
            device,
            data.data.subjectWindowActive
          );
        } else if (
          !device.isIosDevice &&
          this.previewPopupComponents.has(device.identity)
        ) {
          //if preview window already open & device is not ios & device is selected, resize it
          const component = this.previewPopupComponents.get(device.identity);
          component.onSubjectResize(data.data);
        }
      })
    );
    this.subscriptions.push(
      this.contentChangeDetect
        .debounceTime(500)
        .subscribe(({ contentChange, text }) => {
          //if text changed while playing
          if (text) {
            this.sendFlyChanges(contentChange);
          }
        })
    );

    this.socketTeleprompter.directorScriptUpdate$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: TeleprompterUpdateInfo) => {
        this.changingTeleprompter = false;
        this.updateTeleprompterInfo(data);
        if (data.commandRequest) {
          this.updatePreviewPopups(
            data.commandRequest,
            Object.keys(data.commandRequest?.enabledStatus ?? {}).concat(
              MAIN_PREVIEW_ID
            )
          );
        }
      });
  }

  async ngOnInit() {
    // fetch settings (speed, font-size, etc) from backend
    this.settings = await this.sessionApiService.getTeleprompterPresets(
      this.sessionId
    );
    this.mortalize(this.activeParticipantIdentities$).subscribe(
      async (activeIdentities) => {
        if (activeIdentities.length === 0 && this.canStop) {
          await this.onStopButtonClicked();
        }
      }
    );
    this.teleprompterCounting$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((counting) => counting),
        throttleTime(1000)
      )
      .subscribe(() => this.createCountdown());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.closeAllPreviewPopups();
  }
  //persist teleprompter status if director refresh
  // eslint-disable-next-line max-lines-per-function
  async checkTeleprompterStatus() {
    let teleprompterId = 0;
    this.devices?.forEach((device) => {
      if (device.teleprompterProperties.teleprompterId) {
        teleprompterId = device.teleprompterProperties.teleprompterId;
      }
    });
    //select the script if subject send the script ID
    if (teleprompterId && !this.selectedScript.value) {
      this.selectedScript.setValue(teleprompterId);
      const script = this.getCurrentScript();
      this.text = script ? script.content : '';
      this.pendingTitleControl.setValue(script?.title);
      this.richTextEditor?.setEditorContent(this.text);
    }
  }
  async sendFlyChanges(contentChange: boolean = false) {
    // we would like to update the teleprompter with new presets
    // if it is currently active (either playing or paused)
    (contentChange
      ? this.activePlayingTeleprompterParticipantIdentities$
      : this.activeParticipantIdentities$
    )
      .pipe(first())
      .subscribe(async (identities) => {
        let data: TeleprompterSocketRequest;
        const statuses = [
          CaptureTeleprompterStatus.PLAYING,
          CaptureTeleprompterStatus.PAUSED,
          CaptureTeleprompterStatus.IDLE,
        ];
        if (
          this.devices.some((device) =>
            statuses.includes(device.teleprompterProperties.status)
          ) ||
          statuses.includes(
            this.previewPopupComponents.get(MAIN_PREVIEW_ID)?.teleprompter
              ?.status$?.value
          )
        ) {
          data = this.getScriptRequest(
            CaptureTeleprompterCommand.PLAY,
            identities,
            true,
            contentChange
          );
        } else {
          data = this.getScriptRequest(
            CaptureTeleprompterCommand.LOAD,
            identities,
            true,
            true
          );
        }
        this.startTeleprompter(identities, data);
      });
  }

  updatePreviewPopups(data: TeleprompterSocketRequest, identities?: string[]) {
    if (identities) {
      identities.forEach((identity) => {
        this.previewPopupComponents.get(identity)?.updateTeleprompter(data);
      });
    } else {
      for (const component of this.previewPopupComponents.values()) {
        component.updateTeleprompter(data);
      }
    }
  }

  closeAllPreviewPopups() {
    for (const component of this.previewPopupComponents.values()) {
      component.onDialogCloseClicked();
    }
  }

  onPresetsChange(settings: TeleprompterPresetsDto) {
    if (this.settings && this.settings.screen_setup !== settings.screen_setup) {
      this.isScreenChange = true;
    }
    this.settings = settings;
    // persist presets to session level settings
    // note the absence of await, as we don't want to wait until API call succeeds
    this.sessionApiService.updateTeleprompterPresets(this.sessionId, settings);
    this.cursorHtml = null;
    this.loadTeleprompterToAllDevices();
  }

  async onScriptChanged() {
    const script = this.getCurrentScript();
    this.text = script ? script.content : '';
    this.pendingTitleControl.setValue(script?.title);
    this.richTextEditor?.setEditorContent(this.text);
    this.highlightedText = null;
    if (this.canStop) {
      await this.onStopButtonClicked();
    }
    this.loadTeleprompterToAllDevices();
  }

  async loadScripts() {
    const allScripts = await this.telepromterApiService.getScriptsInProject(
      this.projectId
    );
    this.scripts = allScripts.filter(
      (script) =>
        script.progress_status === TeleprompterScriptProgressStatus.Ready
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('projectId' in changes) {
      this.loadScripts();
    }
  }

  getCurrentScript(): TeleprompterScript {
    let returnScript: TeleprompterScript;
    this.scripts.forEach((script) => {
      if (script.ovra_teleprompter_script_id === this.selectedScript.value) {
        returnScript = script;
      }
    });
    return returnScript;
  }

  onTextChanged(text: string) {
    this.text = text;
    this.highlightedText = null;
    this.cursorHtml = null;
    if (this.selectedScript.value) {
      this.contentChangeDetect.next({ text: this.text, contentChange: true });
      //update the main teleprompter script set when textarea updated
      this.scripts.forEach((script) => {
        if (script.ovra_teleprompter_script_id === this.selectedScript.value) {
          script.content = this.text;
        }
      });
    }
  }
  onHighlightedTextChanged(text: string) {
    this.cursorHtml = null;
    if (text) {
      this.highlightedText = this.replaceEditorMarker(text, '');
      this.contentChangeDetect.next({
        text: this.highlightedText,
        contentChange: true,
      });
    } else {
      this.highlightedText = null;
    }
  }

  onCursorPositionChanged(event: string) {
    this.highlightedText = null;
    //on cursor click send cursor position if teleprompter play/preview window open
    this.cursorHtml = event;
    this.contentChangeDetect.next({
      text: this.cursorHtml,
      contentChange: true,
    });
  }

  @throttle(2000)
  async createUpdateScript(backgroundUpdate = true) {
    if (!this.text || !this.text.trim()) {
      return;
    } else if (this.selectedScript.value) {
      await this.onUpdateButtonClicked();
      if (!backgroundUpdate) {
        this.toastr.success('The script was updated successfully.', 'Success!');
      }
    } else {
      await this.onCreateButtonClicked();
      if (!backgroundUpdate) {
        this.toastr.success('New script created!');
      }
    }
  }

  // teleprompter control buttons
  // click event handlers
  async onUpdateButtonClicked() {
    // in oldgen, script settings (speed, fontsize, etc) were part of teleprompter script record
    // in nextgen, these settings are part of session settings
    // thus, some of the following properties are just to comply with oldgen columns
    // and ignored
    const script = this.getCurrentScript();
    if (this.pendingTitleControl.value) {
      script.title = this.pendingTitleControl.value;
    }
    const request: TeleprompterScriptEdit = {
      title: script.title,
      content: this.text,
      scroll_speed: 50, // never used, just to be able to save a script
      session_id: script.session_id,
      ts_backgroundColor: '#ffffff', // never used, just to be able to save a script
      ts_backgroundOption: 'solid', // never used, just to be able to save a script
      ts_fontsize: 14, // never used, just to be able to save a script
    };
    const scriptId = script.ovra_teleprompter_script_id;
    try {
      const res = await this.telepromterApiService.editScript(
        scriptId,
        request
      );
      this.sendUpdateTeleprompterInfo();
      return res;
    } catch (err) {
      this.toastr.error(err.message, 'Failed');
    }
  }

  async onCreateButtonClicked() {
    // since we don't have an input for script title
    // default title + random number is used for creating a script

    // in oldgen, script settings (speed, fontsize, etc) were part of teleprompter script record
    // in nextgen, these settings are part of session settings
    // thus, some of the following properties are to comply with oldgen columns
    // and ignored
    const request: TeleprompterScriptCreate = {
      title:
        this.pendingTitleControl.value ||
        'Default title ' + Math.round(10000 * Math.random()),
      content: this.text,
      scroll_speed: 50, // never used, just to be able to save a script
      session_id: this.sessionId, // not really needed since scripts are attached to projects (not sessions)
      ts_backgroundColor: '#ffffff', // never used, just to be able to save a script
      ts_backgroundOption: 'solid', // never used, just to be able to save a script
      ts_fontsize: 14, // never used, just to be able to save a script
      project_id: this.projectId,
      progress_status: TeleprompterScriptProgressStatus.Ready,
    };
    this.creatingScript = true;
    const res = await this.telepromterApiService.createScript(request);
    await this.loadScripts();
    this.selectedScript.setValue(res.ovra_teleprompter_script_id);
    this.pendingTitleControl.setValue(res.title);
    this.creatingScript = false;
    this.sendUpdateTeleprompterInfo();
    return res;
  }

  async onStartButtonClicked(identity?: string) {
    this.highlightedText = '';
    this.cursorHtml = '';
    //create or update the script before send
    await this.createUpdateScript();
    this.activeParticipantIdentities$
      .pipe(
        first(),
        filter((identities) => !identity || identities.includes(identity)),
        switchMap((identities) =>
          from(this.startTeleprompter(identity ? [identity] : identities))
        )
      )
      .subscribe();

    if (!identity) {
      this.previewPopupComponents
        .get(MAIN_PREVIEW_ID)
        ?.teleprompter?.status$?.pipe(
          filter((s) => s === CaptureTeleprompterStatus.COUNTING),
          first()
        )
        .subscribe(() => this.createCountdown());
    }
  }

  async onRestartButtonClicked(identity?: string) {
    this.createUpdateScript();
    if (identity) {
      await this.restartTeleprompter([identity]);
    } else {
      this.activeParticipantIdentities$
        .pipe(
          first(),
          switchMap((identities) => from(this.restartTeleprompter(identities)))
        )
        .subscribe();
    }
  }

  async onPauseButtonClicked(identity?: string) {
    if (identity) {
      await this.pauseTeleprompter([identity]);
    } else {
      this.activeParticipantIdentities$
        .pipe(
          first(),
          switchMap((identities) => from(this.pauseTeleprompter(identities)))
        )
        .subscribe();
    }
  }

  async onStopButtonClicked(identity?: string) {
    if (identity) {
      await this.stopTeleprompter([identity]);
    } else {
      this.activeParticipantIdentities$
        .pipe(
          first(),
          switchMap((identities) => from(this.stopTeleprompter(identities)))
        )
        .subscribe();
    }
  }

  async onPreviewButtonClicked(
    previewId = MAIN_PREVIEW_ID,
    requestSubject = false
  ) {
    if (!requestSubject) {
      this.openTeleprompterPreviewPopup(previewId);
    } else {
      //if teleprompter not stopped get the teleprompter position from subject
      this.requestTeleprompterInfo = true;
      const isFullScreen = this.settings.screen_setup === 'fullscreen';
      this.socketTeleprompter.getTeleprompterInfo(
        { isFullScreen: isFullScreen },
        [previewId]
      );
    }
  }

  openTeleprompterPreviewPopup(
    previewId: string,
    teleprompterCurrentLine = 0,
    teleprompterTotalLines = 0,
    width = 0,
    height = 0,
    device: TeleprompterParticipant = null,
    subjectWindowActive = true
  ) {
    if (this.previewPopupComponents.has(previewId)) {
      return;
    }
    if (!subjectWindowActive) {
      this.toastr.warning('Subject is not active!');
      return;
    }
    const scriptData = this.getScriptRequest(
      CaptureTeleprompterCommand.PLAY,
      [],
      true
    );
    const dialogRef = this.dialog.open(TeleprompterPreviewPopupComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        script: scriptData,
        teleprompterCurrentLine: teleprompterCurrentLine,
        teleprompterTotalLines: teleprompterTotalLines,
        width: width,
        height: height,
        device: device,
        isPlay: [
          CaptureTeleprompterStatus.PLAYING,
          CaptureTeleprompterStatus.STOPPED,
        ].includes(
          this.devices?.find((d) => d.identity === previewId)
            ?.teleprompterProperties?.status
        ),
        paused:
          this.devices?.find((d) => d.identity === previewId)
            ?.teleprompterProperties?.status ===
          CaptureTeleprompterStatus.PAUSED,
      },
      disableClose: true,
      hasBackdrop: false,
      panelClass: 'teleprompter-popup',
      scrollStrategy: new NoopScrollStrategy(),
    });
    this.previewPopupComponents.set(previewId, dialogRef.componentInstance);
    dialogRef.afterClosed().subscribe(() => {
      this.previewPopupComponents.delete(previewId);
    });
  }

  // teleprompter socket requests
  async startTeleprompter(
    identities: string[],
    data?: TeleprompterSocketRequest
  ) {
    identities = identities.filter((i) =>
      this.devices
        .filter((d) => d.teleprompterProperties.isTeleprompterVisible)
        .map((d) => d.identity)
        .includes(i)
    );
    data =
      data ??
      this.getScriptRequest(CaptureTeleprompterCommand.PLAY, identities, true);
    await this.socketTeleprompter.playTeleprompter(data, identities);
    this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data, identities.concat(MAIN_PREVIEW_ID));
    this.socketTeleprompter.getTeleprompterInfo({}, identities);
    this.playClick.emit(identities);
  }

  async restartTeleprompter(identities: string[]) {
    const data = this.getScriptRequest(
      CaptureTeleprompterCommand.RESTART,
      identities,
      true
    );
    await this.socketTeleprompter.restartTeleprompter(data, identities);
    this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data, identities.concat(MAIN_PREVIEW_ID));
    this.restartClick.emit(identities);
  }

  private async stopTeleprompter(identities: string[]) {
    const data = this.getScriptRequest(
      CaptureTeleprompterCommand.CLOSE,
      identities,
      true
    );
    await this.socketTeleprompter.closeTeleprompter(data, identities);
    this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data, identities.concat(MAIN_PREVIEW_ID));
    this.stopClick.emit(identities);
  }

  private async pauseTeleprompter(identities: string[]) {
    const data = this.getScriptRequest(
      CaptureTeleprompterCommand.PAUSE,
      identities,
      true
    );
    await this.socketTeleprompter.pauseTeleprompter(data, identities);

    this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data, identities.concat(MAIN_PREVIEW_ID));
    this.pauseClick.emit(identities);
  }

  private isMainPreviewOpen() {
    return this.previewPopupComponents.has(MAIN_PREVIEW_ID);
  }

  get canStart(): Observable<boolean> {
    return this.teleprompterCounting$.pipe(
      map(
        (counting) =>
          (!counting &&
            !!this.text &&
            !!this.text.trim() &&
            this.devices.some(
              (device) => device.teleprompterProperties.isTeleprompterVisible
            ) &&
            this.devices?.filter((d) =>
              [
                CaptureTeleprompterStatus.PLAYING,
                CaptureTeleprompterStatus.COUNTING,
              ].includes(d.teleprompterProperties.status)
            ).length === 0 &&
            !this.creatingScript) ||
          this.isMainPreviewOpen()
      )
    );
  }

  get canStartDuringRecording() {
    return !!this.text && !!this.text.trim();
  }

  get canPreview(): boolean {
    return this.text?.trim()?.length > 0;
  }

  get canUpdate(): boolean {
    return !!this.selectedScript;
  }

  get canCreate(): boolean {
    return !this.selectedScript && !!this.text && !!this.text.trim();
  }

  get canStop(): Observable<boolean> {
    const statuses = [
      CaptureTeleprompterStatus.PLAYING,
      CaptureTeleprompterStatus.PAUSED,
      CaptureTeleprompterStatus.IDLE,
    ];
    return this.teleprompterCounting$.pipe(
      map(
        (counting) =>
          (!counting &&
            !!this.devices?.find((d) =>
              statuses.includes(d.teleprompterProperties.status)
            )) ||
          statuses.includes(
            this.previewPopupComponents.get(MAIN_PREVIEW_ID)
              ?.teleprompterCurrentStatus
          )
      )
    );
  }

  get canPause(): boolean {
    const statuses = [
      CaptureTeleprompterStatus.COUNTING,
      CaptureTeleprompterStatus.PLAYING,
    ];
    return (
      !!this.devices?.find((d) =>
        statuses.includes(d.teleprompterProperties.status)
      ) ||
      statuses.includes(
        this.previewPopupComponents.get(MAIN_PREVIEW_ID)
          ?.teleprompterCurrentStatus
      )
    );
  }

  get canResume(): boolean {
    return !!this.devices?.find(
      (d) =>
        d.teleprompterProperties.status === CaptureTeleprompterStatus.PAUSED
    );
  }

  private async sendUpdateTeleprompterInfo(
    data: TeleprompterSocketRequest = null
  ) {
    this.socketTeleprompter.sendDirectorScriptUpdate({
      script: this.getCurrentScript(),
      settings: this.settings,
      commandRequest: data,
    });
  }

  // websocket message payload helpers
  private getScriptRequest(
    command: CaptureTeleprompterCommand,
    identities: string[],
    active: boolean,
    contentChange: boolean = false
  ): TeleprompterSocketRequest {
    const script = this.getCurrentScript();
    const settings = this.settings;
    const textToSend = this.getTextWithCursor(
      this.highlightedText || this.text,
      contentChange
    );
    return {
      command,
      SessionID: script?.session_id,
      script_content: {
        scriptData: [textToSend],
        timerData: [],
      },
      script_title: script?.title,
      // oldgen properties
      scriptContentLink: '',
      scroll_speed: parseInt(this.getScrollSpeed(), 10),
      font_size: settings.font_size + '',
      content_change: contentChange ? 1 : 0,
      tele_script_id: script?.ovra_teleprompter_script_id,
      txtColor: this.getTextColor(),
      value: this.getBackgroundColor(),
      // nextgen properties
      countDown:
        contentChange ||
        !this.sessionSettings.teleprompter_countdown_enabled ||
        this.cursorHtml ||
        this.highlightedText
          ? 0
          : this.sessionSettings.countdown_value,
      fontSize: settings.font_size,
      split: settings.screen_setup,
      speed: settings.speed,
      background: settings.background,
      keepOnScreen: this.sessionSettings.keep_teleprompter_on_screen,
      enabledStatus: identities.reduce(
        (prev, curr) => ({ ...prev, [curr]: active }),
        {}
      ),
    };
  }

  private getTextWithCursor(text: string, contentChange: boolean) {
    if (text && contentChange && this.cursorHtml) {
      //replace editor cursor with custom cursor
      text = this.replaceEditorMarker(this.cursorHtml, CURSOR_HTML);
    }
    return text;
  }

  private replaceEditorMarker(text: string, replaceText: string) {
    return text.replace(/<span(\s)+class="fr-marker".*?<\/span>/g, replaceText);
  }

  // needed for IOS subjects
  private getBackgroundColor() {
    const bckValue = this.settings.background;
    return bckValue === 'white_in_black'
      ? '#FFFFFF'
      : bckValue === 'black_in_white'
      ? '#000000'
      : '-1';
  }

  // needed for IOS subjects
  private getTextColor() {
    const bckValue = this.settings.background;
    return bckValue === 'white_in_black'
      ? '#000000'
      : bckValue === 'black_in_white'
      ? '#FFFFFF'
      : '-1';
  }

  // needed for IOS subjects
  private getScrollSpeed() {
    const speedValue = this.settings.speed;
    return speedValue ? speedValue.toString() : '100';
  }

  // Handle teleprompter script/preset update from web sockets
  private async updateTeleprompterInfo(data: TeleprompterUpdateInfo) {
    if (data.settings) {
      this.settings = data.settings;
      //no update api call during websocket update
      this.teleprompterPreset.needUpdate = false;
      this.teleprompterPreset.form.setValue(this.settings);
    }
    if (data.script) {
      const scriptData = data.script;
      let scriptFound = false;
      this.scripts.forEach((script, index) => {
        if (
          script.ovra_teleprompter_script_id ===
          scriptData.ovra_teleprompter_script_id
        ) {
          this.scripts[index] = scriptData;
          scriptFound = true;
        }
      });
      if (!scriptFound) {
        this.scripts.push(scriptData);
      }
      this.selectedScript.setValue(scriptData?.ovra_teleprompter_script_id);
      this.text = scriptData ? scriptData.content : '';
      this.pendingTitleControl.setValue(scriptData?.title);
      this.richTextEditor?.setEditorContent(this.text);
    }
  }

  toggleGlobalTeleprompterScreen(event) {
    this.toggleTeleprompter({
      identities: this.devices.map((d) => d.identity),
      active: event.checked,
    });
  }

  toggleTeleprompter(event: { identities: string[]; active: boolean }) {
    this.teleprompterActiveToggle.emit({
      active: event.active,
      identities: event.identities,
      data: this.getScriptRequest(
        CaptureTeleprompterCommand.LOAD,
        event.identities,
        event.active
      ),
    });
  }

  async loadTeleprompterToAllDevices() {
    this.changingTeleprompter = true;
    this.sendUpdateTeleprompterInfo();
    if (!this.selectedScript.value) {
      //if user does not select any script,
      //create the script first before send to the subjects as subject needs the script id
      await this.createUpdateScript();
    }
    this.activeParticipantIdentities$
      .pipe(
        first(),
        switchMap((identities) => {
          const data = this.getScriptRequest(
            CaptureTeleprompterCommand.LOAD,
            identities,
            true
          );
          this.updatePreviewPopups(data, identities.concat(MAIN_PREVIEW_ID));

          return from(
            this.socketTeleprompter.loadTeleprompter(data, identities)
          );
        })
      )
      .subscribe();
  }

  get canGlobalEnableDisable() {
    return this.teleprompterCounting$.pipe(
      map(
        (counting) => !counting && this.devices?.length > 0 && this.canPreview
      )
    );
  }

  get isCursorPlayable$() {
    return this.isPlayable$.pipe(
      switchMap(
        (p) =>
          this.previewPopupComponents
            .get(MAIN_PREVIEW_ID)
            ?.teleprompter?.status$?.pipe(
              map((s) =>
                [
                  CaptureTeleprompterStatus.PLAYING,
                  CaptureTeleprompterStatus.PAUSED,
                  CaptureTeleprompterStatus.IDLE,
                ].includes(s)
              )
            ) ?? of(p)
      )
    );
  }

  deviceTrackBy(index: number, device: NextgenParticipant) {
    return device.identity;
  }
  handlePropagation(event: Event) {
    event.stopPropagation();
  }

  createCountdown() {
    this.countdown$ = timer(0, 1000).pipe(
      scan(
        (acc) => --acc,
        (this.sessionSettings.teleprompter_countdown_enabled
          ? this.sessionSettings.countdown_value
          : -1) + 1
      ),
      takeWhile((x) => x >= 0),
      publish()
    ) as ConnectableObservable<number>;

    this.countdown$.connect();
  }
}
