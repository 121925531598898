import { IPopupComponent } from '@openreel/common';
import { SubjectTeleprompterOptions } from '../subject-teleprompter/subject-teleprompter.component';
import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubjectTeleprompterComponent } from '../subject-teleprompter/subject-teleprompter.component';
import { createCounterFor, teleprompterOptions, transformToOptions } from '../teleprompter-options';
import {
  TeleprompterSocketRequest,
  CaptureTeleprompterStatus,
  CaptureTeleprompterCommand,
  TeleprompterPresetDevices,
  POPUP_PADDING,
  POPUP_BASE_WIDTH,
  POPUP_BASE_HEIGHT,
  TELEPROMPTER_MARGIN,
  AckTeleprompterInfo,
  PresetDevice,
  TELEPROMPTER_PADDING,
  TELEPROMPTER_BORDER,
} from '../../../interfaces/teleprompter.interface';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { Cleanupable } from '../../../classes/cleanupable';
import { FormControl } from '@angular/forms';
import { GenericAlertService } from '../../../services/alert/alerts.service';
import { CountdownComponent } from '../../countdown/countdown.component';
import { NextgenParticipant } from 'libs/common/src/interfaces';

interface TeleprompterPreviewPopupInput {
  script: TeleprompterSocketRequest;
  isPlay: boolean;
  paused: boolean;
  teleprompterCurrentLine: number;
  teleprompterTotalLines: number;
  width: number;
  height: number;
  device: NextgenParticipant;
}
interface PopupPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * A director-side component which previews teleprompter script play.
 * We try to match subject side as much as possible: screen setup, font size, background, speed
 * We use dummy picture to represent video streams on subject side.
 *
 * It also complies with keep_teleprompter_on_screen setting.
 */
@Component({
  selector: 'openreel-teleprompter-preview-popup',
  templateUrl: './teleprompter-preview-popup.component.html',
  styleUrls: ['./teleprompter-preview-popup.component.scss'],
})
export class TeleprompterPreviewPopupComponent extends Cleanupable implements OnInit, IPopupComponent, OnDestroy {
  @ViewChild(SubjectTeleprompterComponent)
  teleprompter: SubjectTeleprompterComponent;
  fxLayout = 'row';
  teleprompterFullscreen = false;
  resizing = false;
  dragDisabled = false;
  isPopup: true;
  popupPosition: PopupPosition = {
    x: 0,
    y: 0,
    width: POPUP_BASE_WIDTH,
    height: POPUP_BASE_HEIGHT,
  };
  teleprompterOptions: SubjectTeleprompterOptions;
  private resizeDetect = new BehaviorSubject<boolean>(null);
  teleprompterPresetDevices = TeleprompterPresetDevices;
  teleprompterPresetDevice = new FormControl();
  isResizeAllowed = new FormControl(false);
  teleprompterCurrentStatus: CaptureTeleprompterStatus;
  teleprompterLayout: string;
  @ViewChild('previewTitle') previewTitle: ElementRef<HTMLDivElement>;
  @ViewChild('teleprompterCountdown') teleprompterCountdown: CountdownComponent;
  isCountDown = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TeleprompterPreviewPopupInput,
    private dialogRef: MatDialogRef<TeleprompterPreviewPopupComponent>,
    private genericAlertService: GenericAlertService
  ) {
    super();
  }

  ngOnInit(): void {
    this.teleprompterOptions = transformToOptions(this.data.script);
    this.setTeleprompterLayout();
    if (this.data.isPlay || this.data.paused) {
      this.teleprompterOptions.countDown = 0;
    }
    this.teleprompterOptions.totalLines = this.data.teleprompterTotalLines;
    this.teleprompterOptions.currentLine = this.data.teleprompterCurrentLine;
    // timeout is used to wait for teleprompter component to become available
    setTimeout(() => {
      this.setPreviewWidthHeight();
      this.startTeleprompter(this.teleprompterOptions);
    });
    if (!this.isSubjectSync()) {
      //if subject not in sync set default preset
      this.teleprompterPresetDevice.setValue(this.teleprompterPresetDevices[0]);
    }
    this.subscriptions.push(
      this.resizeDetect.debounceTime(500).subscribe((isResize: boolean) => {
        if (isResize) {
          this.resizing = false;
          this.storePosition();
          //recalculate the speed
          if (this.teleprompterCurrentStatus === CaptureTeleprompterStatus.PLAYING) {
            this.teleprompterOptions.countDown = 0;
            this.teleprompter.loadTeleprompter(this.teleprompterOptions, true, CaptureTeleprompterCommand.LOAD);
          }
        }
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setPreviewWidthHeight() {
    //when subject sends width & height
    if (this.isSubjectSync()) {
      const [width, height] = this.getPreviewScreenWidthHeight(this.data.width, this.data.height);
      this.data.width = width;
      this.data.height = height;
      this.changePreviewWindow(width, height);
    } else {
      //if no subject then select default preset
      this.changeDevice();
    }
  }

  getPreviewScreenWidthHeight(width: number, height: number) {
    //calculate the main preview width height from telerprompter screen width & height
    let actualWidth: number;
    let actualHeight: number;
    const titleHeight = this.previewTitle.nativeElement.clientHeight;
    switch (this.teleprompterLayout) {
      case 'top':
      case 'bottom':
        actualWidth = Math.round(width + POPUP_PADDING + TELEPROMPTER_PADDING * 2 + TELEPROMPTER_BORDER);
        actualHeight = Math.round(height * 2 + titleHeight + POPUP_PADDING + TELEPROMPTER_BORDER);
        break;
      case 'left':
      case 'right':
        actualWidth = Math.round(
          width * 2 + POPUP_PADDING + TELEPROMPTER_BORDER + TELEPROMPTER_MARGIN * 2 + TELEPROMPTER_PADDING * 2
        );
        actualHeight = Math.round(height + titleHeight + TELEPROMPTER_BORDER + POPUP_PADDING);
        break;
      default:
        actualWidth = Math.round(
          width + POPUP_PADDING + TELEPROMPTER_BORDER + TELEPROMPTER_MARGIN * 2 + TELEPROMPTER_PADDING * 2
        );
        actualHeight = Math.round(height + titleHeight + POPUP_PADDING + TELEPROMPTER_BORDER);
    }
    return [actualWidth, actualHeight];
  }
  setTeleprompterLayout() {
    if (
      (this.data.device && this.data.device.isIosDevice) ||
      (this.teleprompterPresetDevice.value && this.teleprompterPresetDevice.value.isMobile)
    ) {
      //for iOS subject teleprompter always fullscreen
      this.teleprompterLayout = 'fullscreen';
    } else {
      this.teleprompterLayout = this.teleprompterOptions.split;
    }
    const split = teleprompterOptions.splits.find((s) => s.value === this.teleprompterLayout);
    if (split) {
      this.fxLayout = split.fxLayout;
      this.teleprompterFullscreen = split.value === 'fullscreen';
    }
  }
  isWebPlayPause() {
    return this.data.device && this.data.teleprompterTotalLines !== 0 && !this.data.device.isIosDevice;
  }
  isIosPlayPause() {
    return (
      this.data.device &&
      this.data.device.isIosDevice &&
      [CaptureTeleprompterStatus.PLAYING, CaptureTeleprompterStatus.PAUSED].includes(
        this.data.device.teleprompterProperties.status
      )
    );
  }
  startTeleprompter(options: SubjectTeleprompterOptions) {
    const autoStart = this.isWebPlayPause() || this.isIosPlayPause();
    this.teleprompter.loadTeleprompter(options, autoStart, CaptureTeleprompterCommand.LOAD);
    this.subscriptions.push(
      this.teleprompter.status$.subscribe((status) => {
        this.teleprompterCurrentStatus = status;
        if (status === CaptureTeleprompterStatus.PLAYING && this.data.paused) {
          this.teleprompter.pause();
          this.data.isPlay = true;
        }
      })
    );
  }
  onResized(event) {
    //if width/height changed
    if (this.isResizeAllowed.value && event.oldWidth && event.oldHeight) {
      if (!this.resizing) {
        this.resizing = true;
        //before resizing store the position
        const teleprompterPosition = this.teleprompter.getTeleprompterPosition();
        this.teleprompterOptions.currentLine = Math.round(teleprompterPosition.currentLine);
        this.teleprompterOptions.totalLines = Math.round(teleprompterPosition.totalLines);
      }
      this.popupPosition.width = Math.round(event.newWidth + POPUP_PADDING);
      this.popupPosition.height = Math.round(event.newHeight + POPUP_PADDING);
      this.resizeDetect.next(true);
    }
  }

  onDragEnd(event) {
    const position = event.source.getFreeDragPosition();
    this.popupPosition.x = Math.round(position.x);
    this.popupPosition.y = Math.round(position.y);
    this.storePosition();
  }

  storePosition() {
    localStorage.setItem('teleprompterPreviewPosition', JSON.stringify(this.popupPosition));
  }

  updateTeleprompter(data: TeleprompterSocketRequest) {
    let isScreenChange = false;
    if (
      this.isSubjectSync() &&
      this.teleprompterOptions &&
      this.teleprompterLayout !== data.split &&
      !this.data.device.isIosDevice
    ) {
      isScreenChange = true;
      // for screen change,
      // we will not change on the fly,
      //we will only change the teleprompter when getting subject width/height
      data.content_change = 0;
    }
    this.teleprompterOptions = transformToOptions(data);
    this.setTeleprompterLayout();
    if (!isScreenChange) {
      this.checkTeleprompterCommand(data);
    }
  }
  checkTeleprompterCommand(data: TeleprompterSocketRequest) {
    //TODO: this function logic should be in shared service for both subject & director preview
    switch (data.command) {
      case CaptureTeleprompterCommand.LOAD:
        if (
          this.teleprompterCurrentStatus === CaptureTeleprompterStatus.IDLE ||
          this.teleprompterCurrentStatus === CaptureTeleprompterStatus.PENDING ||
          this.teleprompterCurrentStatus === CaptureTeleprompterStatus.STOPPED
        ) {
          if (this.teleprompterPresetDevice.value) {
            //if one device preset already selected reset the preview window width/height
            this.setPresetPreview();
          }
          this.teleprompter.loadTeleprompter(this.teleprompterOptions, false, CaptureTeleprompterCommand.LOAD);
        } else if (this.teleprompterCurrentStatus === CaptureTeleprompterStatus.PLAYING) {
          const { currentLine, totalLines } = this.teleprompter.getTeleprompterPosition();

          this.teleprompterOptions.totalLines = totalLines;
          this.teleprompterOptions.currentLine = currentLine;
          this.teleprompterOptions.countDown = 0;

          this.teleprompter.loadTeleprompter(this.teleprompterOptions, true, CaptureTeleprompterCommand.PLAY);
        }
        break;
      case CaptureTeleprompterCommand.PAUSE:
        this.teleprompter.pause();
        break;
      case CaptureTeleprompterCommand.RESUME:
        this.teleprompter.resume();
        break;
      case CaptureTeleprompterCommand.CLOSE:
        this.teleprompter.loadTeleprompter(this.teleprompterOptions, false, CaptureTeleprompterCommand.CLOSE);
        break;
      case CaptureTeleprompterCommand.RESTART:
        this.showTeleprompterCountDown();
        this.teleprompter.loadTeleprompter(this.teleprompterOptions, true, CaptureTeleprompterCommand.RESTART);
        break;
      default:
        if (data.content_change === 0 && this.teleprompterCurrentStatus === CaptureTeleprompterStatus.PAUSED) {
          this.teleprompter.resume();
        } else {
          //on the fly autoplay if teleprompter already playing
          this.showTeleprompterCountDown();
          if (this.teleprompterPresetDevice.value) {
            //if one device preset already selected reset the preview window width/height
            this.setPresetPreview();
          }
          this.teleprompter.loadTeleprompter(this.teleprompterOptions, true, CaptureTeleprompterCommand.IDLE);
        }
        break;
    }
  }
  onDialogCloseClicked() {
    this.teleprompter.destroyAllTeleprompterEvent();
    this.dialogRef.close();
  }
  changeDevice() {
    this.setTeleprompterLayout(); //if device is iphone/ios set fullscreen
    this.setPresetPreview();
    this.teleprompterOptions.countDown = 0;
    const autoPlay = this.teleprompterCurrentStatus === CaptureTeleprompterStatus.PLAYING;
    this.teleprompter.loadTeleprompter(this.teleprompterOptions, autoPlay, CaptureTeleprompterCommand.LOAD);
  }
  setPresetPreview() {
    const currentScreenWidth = this.getDevicePresetScreen(this.teleprompterPresetDevice.value).width;
    const currentScreenHeight = this.getDevicePresetScreen(this.teleprompterPresetDevice.value).height;
    const [actualWidth, actualHeight] = this.getPreviewScreenWidthHeight(currentScreenWidth, currentScreenHeight);
    this.changePreviewWindow(actualWidth, actualHeight);
  }
  toggleResize() {
    if (this.isResizeAllowed.value && this.isSubjectSync()) {
      this.genericAlertService
        .openAlertModal({
          title: 'Are you sure?',
          content: 'Resize the preview screen will not show exactly what the subject display looks like',
          confirmButtonLabel: 'Ok',
          cancelButtonLabel: 'Cancel',
          isLightThemed: false,
        })
        .subscribe((result) => {
          if (!result.value) this.isResizeAllowed.setValue(false);
          this.togglePanelResizeClass();
        });
    } else {
      this.togglePanelResizeClass();
    }
  }
  onSubjectResize(data: AckTeleprompterInfo) {
    //when web subject resize preview window also resize
    this.isResizeAllowed.setValue(false);
    this.data.width = data.teleprompterWidth;
    this.data.height = data.teleprompterHeight;
    this.setPreviewWidthHeight();
    const options = {
      ...this.teleprompterOptions,
      countDown: [CaptureTeleprompterStatus.PLAYING, CaptureTeleprompterStatus.PAUSED].includes(
        this.teleprompterCurrentStatus
      )
        ? 0
        : this.teleprompterOptions.countDown,
      totalLines: data.teleprompterTotalLines,
      currentLine: data.teleprompterCurrentLine,
    };

    this.teleprompter.loadTeleprompter(options, data.teleprompterTotalLines !== 0, CaptureTeleprompterCommand.LOAD);
  }
  private togglePanelResizeClass() {
    if (this.isResizeAllowed.value) {
      this.addRemoveResizeClass(true);
      this.teleprompterPresetDevice.setValue(null);
    } else {
      this.addRemoveResizeClass(false);
      //return default subject width/height
      if (this.isSubjectSync()) this.changePreviewWindow(this.data.width, this.data.height);
    }
  }
  private addRemoveResizeClass(isResize: boolean) {
    if (isResize) {
      this.dialogRef.addPanelClass('teleprompter-resize-popup');
    } else {
      this.dialogRef.removePanelClass('teleprompter-resize-popup');
    }
  }
  private changePreviewWindow(width: number, height: number) {
    this.dialogRef.updateSize(width + 'px', height + 'px');
    this.popupPosition.width = width;
    this.popupPosition.height = height;
    this.storePosition();
  }
  private isSubjectSync() {
    return this.data.width && this.data.height ? true : false;
  }
  private async showTeleprompterCountDown() {
    if (this.teleprompterOptions.countDown) {
      this.isCountDown = true;
      this.addRemoveResizeClass(false);
      const count$ = createCounterFor(this.teleprompterOptions.countDown);
      await this.teleprompterCountdown.countDown(count$);
      this.isCountDown = false;
      this.addRemoveResizeClass(this.isResizeAllowed.value);
    }
  }
  private getDevicePresetScreen(screen: PresetDevice) {
    return this.teleprompterLayout === 'fullscreen' ? screen.fullScreen : screen.halfScreen;
  }
}
