import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { Cleanupable } from '../../classes';
import { PerformanceService } from '../../services/performance/performance.interface';
import { isOnWeb } from '../../utils';
import { NextgenParticipant } from '../../interfaces/nextgen-participant.interface';

const APP_DEVICE_NAME = 'NEXTGEN DESKTOP CLIENT';

interface PerformanceScreenPopupData {
  participant$?: Observable<NextgenParticipant>;
  performanceService: PerformanceService;
  networkQuality$: Observable<number>;
}

@Component({
  selector: 'openreel-performance-screen-popup',
  templateUrl: './performance-screen-popup.component.html',
  styleUrls: ['./performance-screen-popup.component.css'],
})
export class PerformanceScreenPopupComponent
  extends Cleanupable
  implements OnInit, OnDestroy {
  constructor(
    private readonly dialogRef: MatDialogRef<PerformanceScreenPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: PerformanceScreenPopupData
  ) {
    super();
  }

  isRunning = true;
  cpuSpeed = 'Calculating...';
  cpuCount = 'Calculating...';
  cpuUsage = 'Calculating...';
  cpuAppUsage = 'Calculating...';
  memory = 'Calculating...';
  memoryUsage = 'Calculating...';
  memoryAppUsage = 'Calculating...';
  uploadSpeed = 'Calculating...';
  downloadSpeed = 'Calculating...';
  networkQuality = 0;
  isOnWeb = false;
  isRemoteIos = false;

  ngOnInit(): void {
    if (this.data.participant$) {
      this.data.participant$.subscribe((participant) => {
        this.onParticipantChange(participant);
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onParticipantChange(participant: NextgenParticipant): void {
    if (participant && participant.isIosDevice) {
      //fetching internet speed for iOS
      const appNetworkSpeed = participant.deviceProperties.speed.split(',');
      this.downloadSpeed = appNetworkSpeed[0]
        ? appNetworkSpeed[0].split(':')[1]
        : 'N/A';
      this.uploadSpeed = appNetworkSpeed[1]
        ? appNetworkSpeed[1].split(':')[1]
        : 'N/A';
      this.isRemoteIos = true;
    }
    if (this.data.networkQuality$) {
      this.subscriptions.push(
        this.data.networkQuality$.subscribe((level: number) => {
          this.networkQuality = level;
        })
      );
    }
    /* There should be a simpler way of figuring out whether a remote participant is using the electron app */
    this.isOnWeb = participant?.deviceName
      ? participant.deviceName.toUpperCase() !== APP_DEVICE_NAME
      : isOnWeb();
    if (!this.isRemoteIos) {
      this.subscriptions.push(
        this.data.performanceService.hardwareConsumption$.subscribe(
          ({
            cpuUsage,
            cpuSpeed,
            cpuCount,
            cpuAppUsage,
            memoryUsage,
            memoryAppUsage,
            memory,
            networkQuality,
          }) => {
            this.cpuCount = this.withUnit(cpuCount);
            this.cpuAppUsage = this.withUnit(cpuAppUsage?.toFixed(2), '%');
            this.cpuSpeed = this.withUnit(cpuSpeed, ' GHz');
            this.cpuUsage = this.withUnit(cpuUsage?.toFixed(2), '%');
            this.memory = this.withUnit(memory, ' GB');
            this.memoryAppUsage = this.withUnit(
              memoryAppUsage?.toFixed(2),
              '%'
            );
            this.memoryUsage = this.withUnit(memoryUsage?.toFixed(2), '%');
            if (networkQuality) this.networkQuality = networkQuality;
          }
        )
      );
      this.subscriptions.push(
        this.data.performanceService.networkSpeed$.subscribe(
          ({ upload, download }) => {
            this.uploadSpeed = this.withUnit(upload?.toFixed(2), ' Mbps');
            this.downloadSpeed = this.withUnit(download?.toFixed(2), ' Mbps');
          }
        )
      );

      this.subscriptions.push(
        timer(1000, 1000).subscribe(() => {
          this.data.performanceService.fetchHardwarePerformance(participant);
        })
      );

      this.data.performanceService.fetchHardwarePerformance(participant);
      this.data.performanceService.fetchNetworkSpeed(participant);
    }
  }

  public gradeFpsAsPerformance(browserAnimationFps) {
    if (browserAnimationFps > 29) {
      return 4;
    } else if (browserAnimationFps > 25) {
      return 3;
    } else if (browserAnimationFps > 20) {
      return 2;
    } else return 1;
  }
  close() {
    this.dialogRef.close();
  }

  private withUnit(value: number | string, unit?: string): string {
    return value ? `${value}${unit ? unit : ''}` : 'N/A';
  }
}
