<div class="select-account">
  <span class="account-title" *ngIf="!createTeam">Select Team</span>
  <span class="account-title" *ngIf="createTeam">Create Team</span>
  <div
    class="create-account-card"
    fxLayout="column"
    fxFill
    *ngIf="accountSelectionType !== AccountSelectionType.LOGIN && (canCreateTeam$ | async)"
  >
    <mat-card fxFlex="100%" class="select-account-card">
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center" (click)="createAccount()" *ngIf="!createTeam">
          <div fxLayout="column" fxLayoutGap="20px">
            <div class="name-circle">+</div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center">
            <span class="account-card-title">Create New Team</span>
          </div>
        </div>

        <div fxLayout="column" class="create-team-wrapper" *ngIf="createTeam">
          <div>
            <mat-form-field appearance="outline" style="width: 100%">
              <input matInput placeholder="Enter Team Name" #teamName />
            </mat-form-field>
          </div>

          <div>
            <button
              mat-raised-button
              color="primary"
              [disabled]="creatingTeam"
              (click)="existingUserRegistration(0, teamName.value)"
            >
              Save
            </button>
            <button mat-button [disabled]="creatingTeam" (click)="createTeam = false">Cancel</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <p class="account-sub-title-gray" *ngIf="accounts?.length > 1">
    We have found multiple teams, please select which one you would like to enter.
  </p>
  <p class="account-sub-title-gray" *ngIf="!accounts?.length && accountSelectionType === AccountSelectionType.LOGIN">
    User doesn't have any associated account.
  </p>
  <div class="card-wrapper slim-scroll" fxLayout="column" fxFill>
    <mat-card
      fxFlex="100%"
      class="select-account-card"
      *ngFor="let account of accounts"
      (click)="selectAccount(account)"
      [ngClass]="{ 'disable-card': account.isGeoLocationRestricted }"
    >
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" fxLayoutGap="20px">
            <div class="name-circle">{{ getInitials(account.name) }}</div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center">
            <span class="account-card-title">{{ account.name }}</span>
            <span class="account-card-sub-title" *ngIf="account.isGeoLocationRestricted">
              Account is not available in this location</span
            >
          </div>
          <div fxLayout="column" fxLayoutAlign="center" *ngIf="!account.isGeoLocationRestricted">
            <mat-icon color="primary" class="navigate-icon"> navigate_next </mat-icon>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="join-team-wrapper" *ngIf="accountSelectionType !== AccountSelectionType.SELF_REGISTRATION">
    <button mat-button color="primary" (click)="joinTeam()" *ngIf="accountSelectionType === AccountSelectionType.LOGIN">
      <mat-icon>add</mat-icon> Join a Team
    </button>
    <button
      mat-button
      color="primary"
      (click)="backToLogin()"
      *ngIf="accountSelectionType === AccountSelectionType.EXISTING_USER_REGISTRATION"
    >
      <mat-icon>arrow_back</mat-icon>Back to Login
    </button>
  </div>
</div>
