<div>
  <div style="padding: 10px 0">{{ device.name }}</div>
  <div class="device-item" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="10" class="device-item-icon">
      <mat-icon
        [svgIcon]="device.isIosDevice ? 'phone' : 'computer'"
      ></mat-icon>
    </div>
    <div fxFlex="20" class="device-item-name">
      {{ device.deviceName }}
    </div>
    <div
      fxFlex="30"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="6px grid"
      fxLayoutGap.xs="12px grid"
      class="device-item-stats"
    >
      <div fxFlex="33" fxFlex.xs="34">
        <button
          mat-stroked-button
          class="action-btn"
          [disabled]="!canStart || isCounting"
          (click)="playClick.emit()"
          *ngIf="!canPause"
        >
          <mat-icon svgIcon="teleprompter-play"></mat-icon>
        </button>
        <button
          mat-flat-button
          color="warn"
          class="action-btn"
          (click)="pauseClick.emit()"
          [disabled]="isCounting"
          *ngIf="canPause"
        >
          <mat-icon svgIcon="teleprompter-pause"></mat-icon>
        </button>
      </div>
      <div fxFlex="33" fxFlex.xs="34">
        <button
          mat-stroked-button
          class="action-btn"
          [disabled]="!canStop || isCounting"
          (click)="restartClick.emit()"
        >
          <mat-icon svgIcon="teleprompter-reset"></mat-icon>
        </button>
      </div>
      <div fxFlex="33" fxFlex.xs="34">
        <button
          mat-button
          [ngClass]="canStop ? 'mat-flat-button' : 'mat-stroked-button'"
          class="action-btn"
          color="primary"
          [disabled]="!canStop || isCounting"
          (click)="stopClick.emit()"
        >
          <mat-icon svgIcon="teleprompter-stop"></mat-icon>
        </button>
      </div>
    </div>
    <div fxFlex="15" class="device-item-checkbox">
      <mat-slide-toggle
        [checked]="device.teleprompterProperties.isTeleprompterVisible"
        style="margin-left: 5px"
        [disabled]="activationDisabled || isCounting"
        (change)="
          teleprompterActiveToggle.emit({
            identities: [device.identity],
            active: $event.checked
          })
        "
      ></mat-slide-toggle>
    </div>
    <div fxFlex="25">
      <button
        mat-stroked-button
        class="action-btn device-preview"
        (click)="previewClicked.emit()"
        [disabled]="previewDisabled || !device.teleprompterProperties.isTeleprompterVisible || isCounting"
      >
        <mat-icon svgIcon="teleprompter-preview"></mat-icon>
        <span>Preview</span>
      </button>
    </div>
  </div>
</div>
