import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'userImgData' })
export class UserImgDataPipe implements PipeTransform {
  transform(value) {
    const data = [];
    for (let i = 0; i < value.length; i++) {
      if(value[i].clearly_account_users){
        data.push({
          fullname: value[i].clearly_account_users.fullname,
          thumb_image: value[i].clearly_account_users.thumb_image,
        });
      }
    }
    return data;
  }
}
