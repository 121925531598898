import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import ls from '@livesession/sdk';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LiveSessionIOService {
  devMode: boolean;
  constructor(
    private authService: AuthService
  ) {
    this.devMode = document.URL.toLowerCase().startsWith('http://localhost');
  }

  initLiveSession() {
    //init livesession.io
    if (!this.devMode) {
      ls.init(commonenv.liveSessionTrackID);
      this.setUserDetails();
      ls.newPageView();
    }
  }
  setUserDetails() {
    if (this.authService.getCookie() && !this.devMode) {
      const cookieData = JSON.parse(this.authService.getCookie());
      ls.identify({
        name: cookieData.data.loggedin_fullname,
        email: cookieData.data.email,
        params: {
          account_id: cookieData.data.account_id,
          account_name: cookieData.data.company_name,
          user_id: cookieData.data.user_id,
        },
      });
    }
  }
}
