import { Component, OnInit } from '@angular/core';
import { ROUTE_DASHBOARD, ROUTE_DIRECTOR_LOGIN } from '../../route-utils';
import { Router } from '@angular/router';
import {
  GUEST_USER_ACCOUNT_INFO,
  GuestUserAccountInfo,
} from '../guest-fill-account-info-form/guest-fill-account-info-form.util';
import { getEmailByToken } from '../../jwt.util';
import { startConfetties } from './guest-account-activated-confetties.util';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'openreel-guest-account-activated-confetties',
  templateUrl: './guest-account-activated-confetties.component.html',
  styleUrls: ['./guest-account-activated-confetties.component.scss'],
})
export class GuestAccountActivatedConfettiesComponent implements OnInit {
  username: string;
  password: string;
  selectedAccount: number;

  constructor(
    private router: Router,
    protected toastr: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const guest = JSON.parse(
      localStorage.getItem(GUEST_USER_ACCOUNT_INFO)
    ) as GuestUserAccountInfo;
    localStorage.removeItem(GUEST_USER_ACCOUNT_INFO);

    this.username = getEmailByToken(guest?.token);
    this.password = guest?.password;
    this.selectedAccount = guest?.account_id;

    startConfetties('canvas');
  }

  async continue() {
    const userLoginDone = await this.loginByActivatedCreds();
    if (userLoginDone && this.selectedAccount) {
      const accountLoginDone = await this.loginToAccount();
      if (accountLoginDone?.data?.nextgenAllowed) {
        this.router.navigate(['/' + ROUTE_DASHBOARD]);
      }
    }
  }

  async loginByActivatedCreds() {
    try {
      const res = await this.authService
        .login(this.username, this.password, true)
        .toPromise();
      return res.status === 1;
    } catch (err) {
      this.toastr.error(
        err.message
          ? err.message
          : 'An error occurred. Please try again later.',
        'Error!'
      );
      this.router.navigate(['/' + ROUTE_DIRECTOR_LOGIN]);
    }
    return false;
  }

  async loginToAccount() {
    try {
      return await this.authService
        .updateAccountDetails(this.selectedAccount)
        .toPromise();
    } catch (err) {
      this.toastr.error(
        err.message
          ? err.message
          : 'An error occurred. Please try again later.',
        'Error!'
      );
      this.router.navigate(['/' + ROUTE_DIRECTOR_LOGIN]);
    }
  }
}
