import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Asset, Layer, LottieLayerFieldData, PresetField, PresetFieldType, Style } from '@openreel/creator/common';
import { FormControlImage, FormControlText } from '../services/layer.service';

export function getValidators(preset: PresetField): ValidatorFn[] | undefined {
  if (!preset.validationRules) {
    return;
  }

  const validators = [];

  Object.keys(preset.validationRules).forEach((key) => {
    switch (key) {
      case 'required':
        validators.push(Validators.required);
        break;
      case 'maxlength':
        validators.push(Validators.maxLength(preset.validationRules[key] as number));
        break;
    }
  });

  return validators;
}

export function toFormControlText(
  layerData: LottieLayerFieldData,
  validators: ValidatorFn[],
  styles: Style[],
  formBuilder: FormBuilder
): FormGroup {
  let color = null;
  let fontIndex = null;
  let fontWeight = null;

  if (layerData?.styleId) {
    const style = styles?.find((a) => a.id === layerData.styleId);
    if (style) {
      color = style.color;
      fontIndex = style.fontIndex;
      fontWeight = style.fontWeight;
    }
  }

  return formBuilder.group({
    value: [layerData?.value, validators],
    color,
    fontIndex,
    fontWeight,
    styleId: layerData.styleId,
  });
}

export function toFormControlShape(
  layerData: LottieLayerFieldData,
  validators: ValidatorFn[],
  styles: Style[],
  formBuilder: FormBuilder
) {
  let color = null;

  if (layerData?.styleId) {
    const style = styles?.find((a) => a.id === layerData.styleId);
    if (style) {
      color = style.color;
    }
  }

  return formBuilder.group({
    value: [layerData?.value, validators],
    color,
    styleId: layerData.styleId,
  });
}

export function toFormControlImage(layerData: LottieLayerFieldData, assets: Asset[]) {
  const asset = layerData?.assetId ? assets.find((a) => a.id === layerData.assetId) : null;

  return {
    assetId: layerData.assetId,
    assetFileId: asset?.file?.path,
    newAssetFileId: null,
    removedAssetId: null,
  };
}

export function toFormControlFromImageVideoLayer(layer: Layer, assets: Asset[]) {
  if (layer.type !== 'image' && layer.type !== 'video') {
    return null;
  }

  const asset = layer?.assetId ? assets.find((a) => a.id === layer.assetId) : null;

  return {
    assetId: layer.assetId,
    assetFileId: asset?.file?.path,
    newAssetFileId: null,
    removedAssetId: null,
  };
}

export function toDataText(preset: PresetField, formControlValue: FormControlText) {
  const { styleId, color, fontIndex, fontWeight, value } = formControlValue;

  return {
    values: {
      type: preset.type,
      styleId,
      value,
    },
    styleChanges: {
      color,
      fontIndex,
      fontWeight,
    },
  };
}

export function toDataShape(preset: PresetField, formControlValue: FormControlText) {
  const { styleId, color, value } = formControlValue;

  return {
    values: {
      type: preset.type,
      styleId,
      value,
    },
    styleChanges: {
      color,
    },
  };
}

export function toDataImageVideo(presetFieldType: PresetFieldType, formControlValue: FormControlImage) {
  const { assetId, newAssetFileId, removedAssetId } = formControlValue;

  return {
    values: {
      type: presetFieldType,
      assetId,
    },
    assetChanges: {
      newAssetFileId,
      removedAssetId,
    },
  };
}
