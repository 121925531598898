import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FeatureFlaggingService } from '@openreel/common/services';

@Pipe({
  name: 'featureflag',
  // Non pure because the feature flag can change mid session
  pure: false,
})
export class FeatureFlagPipe implements PipeTransform {
  constructor(
    @Inject(FeatureFlaggingService)
    private featureFlaggingService: FeatureFlaggingService
  ) {}
  transform(value: string) {
    return this.featureFlaggingService.getFlag(value);
  }
}
