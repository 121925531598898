import { StreamType } from './interfaces';

export interface SessionHourRequest {
  limit: number;
  page: number;
  order?: number;
  csv?: number;
}
export interface SessionHourData {
  fullname: string;
  recording_length: string;
  session_id: number;
  session_name: string;
}
export interface SessionHourResponse {
  status: number;
  count: number;
  total_recording_time: number;
  result: Array<SessionHourData>;
}
export interface IntegrationResponse {
  status: number;
  data: {
    frameIO: boolean;
    drive: boolean;
    s3: {
      keep_copy: boolean;
      allowIntegration: boolean;
      s3Exist: boolean;
    };
    accountDestinations: string[];
  };
}

export enum TeleprompterStartMode {
  'AUTOMATIC' = 'automatic',
  'MANUAL' = 'manual',
}
export interface NamingConvention {
  value: string;
  label: string;
}

export const NamingConventionData: NamingConvention[] = [
  { value: 'session_name', label: 'Session name' },
  { value: 'project_name', label: 'Project name' },
  { value: 'subject_name', label: 'Subject name' },
  { value: 'subject_device', label: 'Subject device name' },
  { value: 'manual_entry', label: 'Manual Entry' },
];

export const UploadDuringRecordSettings = [
  { value: ['web', 'mobile'], label: 'All Devices' },
  { value: ['web'], label: 'Web Devices' },
  { value: ['mobile'], label: 'Mobile Devices' },
  { value: [], label: 'Disable' },
];

export enum AdmitUsersOptions {
  AllUser = 'all',
  SubjectOnly = 'subject',
  NoAdmit = 'no',
}

export const StreamTypeSettings = [
  {
    value: StreamType.Twilio,
    text: 'Remote',
  },
  {
    value: StreamType.Opentok,
    text: 'Remote + Live',
  },
];

export const AdmitUserSettings = [
  {
    // This Would automatically allow everyone in
    value: AdmitUsersOptions.NoAdmit,
    text: 'No Waiting Room',
  },
  {
    // This would automatically allow all NON subjects in
    value: AdmitUsersOptions.SubjectOnly,
    text: 'Subjects Only',
  },
  {
    // This wouldn't allow anyone in without admission
    value: AdmitUsersOptions.AllUser,
    text: 'Everyone',
  },
];

export enum VideoEncodeOptions {
  AVC = 'avc',
  HEVC = 'hevc',
}

export const VideoEncodeSettings = [
  {
    value: VideoEncodeOptions.AVC,
    text: 'AVC',
  },
  {
    value: VideoEncodeOptions.HEVC,
    text: 'HEVC',
  },
];

export interface SessionSettings {
  admin_talkback_enabled: boolean;
  recording_countdown_enabled: boolean;
  teleprompter_countdown_enabled: boolean;
  countdown_value: number;
  teleprompter_start_mode: TeleprompterStartMode;
  mirror_remote_feed_enabled: boolean;
  admin_feed: string;
  recording_camera_options: string;
  flashlight_enabled: boolean;
  zoom_enabled: boolean;
  keep_teleprompter_on_screen: boolean;
  naming_convention: string[];
  upload_during_recording: string[];
  admit_users: AdmitUsersOptions;
  stream_type: StreamType;
  video_encode_type: VideoEncodeOptions;
}

export interface CompanyLevelSessionSettings extends SessionSettings {}
export interface ProjectLevelSessionSettings extends SessionSettings {}
