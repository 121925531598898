import * as fromActions from './../actions/asset-cache.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { AlertService } from '@openreel/ui/openreel-alert/openreel-alert.service';
import { AssetFetchService } from '@openreel/creator/app/core/services/asset-fetch.service';
import { AssetsCacheFacade } from '../facades/asset-cache.facade';
import { Injectable } from '@angular/core';
import { ThumbnailsService } from '@openreel/creator/api/thumbnails/thumbnails.service';
import { ProjectFacade } from './../../store/facades/project.facade';

@Injectable()
export class AssetCacheEffects {
  loadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAsset),
      concatMap(({ id, provider }) => of({ id, provider }).pipe(delay(10))), // Rate limits to avoid concurrent requests
      withLatestFrom(this.assetsCacheFacade.cachedAssets$),
      mergeMap(([action, cachedAssets]) => {
        const { id, provider } = action;
        const cached = cachedAssets.find(
          (asset) =>
            asset.assetId === id.toString() && asset.provider === provider
        );

        if (
          cached &&
          (!cached.expiresAt || new Date(cached.expiresAt) > new Date())
        ) {
          return EMPTY;
        }

        return [
          fromActions.cacheAsset({
            assetId: id.toString(),
            provider,
            isLoading: true,
          }),
          fromActions.fetchAssetUrl({ id, provider }),
        ];
      })
    )
  );

  fetchAssetUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchAssetUrl),
      mergeMap(({ id, provider }) =>
        this.assetFetchService.fetchUrl(provider, id).pipe(
          map(({ url, expiresAt }) =>
            fromActions.cacheAsset({
              assetId: id.toString(),
              provider,
              isLoading: false,
              url,
              expiresAt,
            })
          ),
          catchError((error) => {
            this.logError(error);
            return [fromActions.removeCachedAsset({ id, provider })];
          })
        )
      )
    )
  );

  loadSpritesheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSpritesheet),
      withLatestFrom(
        this.assetsCacheFacade.transcodingThumbnails$,
        this.assetsCacheFacade.thumbnailSpritesheets$
      ),
      filter(
        ([{ videoId }, transcoding, spritesheets]) =>
          !transcoding.has(videoId) && !spritesheets.has(videoId)
      ),
      map(([{ videoId, videoType }]) =>
        fromActions.getSpritesheetS3Url({
          videoId: Number(videoId),
          videoType,
        })
      )
    )
  );

  getSpritesheetS3Url$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSpritesheetS3Url),
      withLatestFrom(this.projectFacade.captureProjectId$),
      mergeMap(([{ videoId, videoType }, captureProjectId]) =>
        this.thumbnailsService
          .extract({ videoId, videoType, captureProjectId })
          .pipe(
            filter((response) => response.state === 'done'),
            map(({ signedUrl }) =>
              fromActions.getSpritesheet({
                videoId,
                signedUrl,
              })
            )
          )
      )
    )
  );

  getSpritesheet = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSpritesheet),
      mergeMap(({ videoId, signedUrl }) => {
        const imgLoadPromise = new Promise<{
          videoId: number;
          spritesheet: HTMLImageElement;
        }>((resolve) => {
          const spritesheet = new Image();
          spritesheet.crossOrigin = 'Anonymous';
          spritesheet.onload = () => {
            spritesheet.onload = null;
            resolve({ videoId, spritesheet });
          };
          spritesheet.src = signedUrl;
        });

        return from(imgLoadPromise);
      }),
      map(({ videoId, spritesheet }) =>
        fromActions.saveSpritesheet({
          videoId,
          spritesheet,
        })
      )
    )
  );

  private logError(
    error,
    errorText: string = 'An error occurred while fetching assets for caching'
  ) {
    this.alertService.error(errorText);
    console.error(error);
  }

  constructor(
    private readonly alertService: AlertService,
    private readonly projectFacade: ProjectFacade,
    private readonly assetsCacheFacade: AssetsCacheFacade,
    private readonly assetFetchService: AssetFetchService,
    private readonly thumbnailsService: ThumbnailsService,
    private readonly actions$: Actions
  ) {}
}
