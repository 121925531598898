import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Router } from '@angular/router';
  import { EMPTY, Observable } from 'rxjs';
  import { catchError, map } from 'rxjs/operators';
  import { ROUTE_DIRECTOR_LOGIN } from '../route-utils';
  import { ToastrService } from 'ngx-toastr';
  import { AuthService } from '../services';
  
  @Injectable()
  export class RedirectForbiddenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {}
  
    intercept<T>(
      req: HttpRequest<T>,
      next: HttpHandler
    ): Observable<HttpEvent<T>> {
      return next.handle(req).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
           if (err.status === 403) {
              // Forbidden
              this.router.navigateByUrl(ROUTE_DIRECTOR_LOGIN);
              return EMPTY;
           }
          }
          throw err;
        }),
        map((event) => event)
      );
    }
  
    redirectToLogin() {
      this.authService.invalidateToken();
      this.router.navigateByUrl(ROUTE_DIRECTOR_LOGIN);
    }
  }
  