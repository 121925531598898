<div class='or-pager'>
    <!-- P A G I N A T I O N -->
    <div class='or-paginator-pages'>
        <button mat-icon-button aria-label="Previous Page" [disabled]="disableLeft" (click)="onPrev()">
            <mat-icon class="or-paginator-arrows" [class.arrows-disabled]="disableLeft">chevron_left</mat-icon>
        </button>

        <ng-container *ngFor="let idx of pages">
            <ng-container *ngIf="idx === -1; else pageTmp">
                <span class="or-paginator-page">...</span> 
            </ng-container>
            <ng-template #pageTmp>
                <button
                    mat-button
                    class="or-paginator-page"
                    (click)="selectPage(idx)"
                    [class.active-page]="pageIndex === idx"
                    [disabled]="pageIndex === idx">
                    {{idx + 1}}
                </button>
            </ng-template>
        </ng-container>

        <button mat-icon-button aria-label="Next Page" [disabled]="disableRight" (click)="onNext()">
            <mat-icon class="or-paginator-arrows" [class.arrows-disabled]="disableRight">chevron_right</mat-icon>
        </button> 
    </div>

    <!-- P A G E   S I Z E -->
    <div class="or-paginator-entries">
        <mat-select 
            [value]="pageSize" 
            (selectionChange)="selectPageSize($event.value)">
            <mat-option *ngFor="let page of pageSizeOptions" [value]="page">{{page}}</mat-option>
        </mat-select>
        <span class="or-paginator-entries-label">Entries per page</span>
    </div>
</div>