import { WorkflowDataDto } from '../interfaces';
import { CommandResult } from './base.command';

export type SomeFuncReturn<R> = {
  success: boolean;
  result?: R;
  workflow: WorkflowDataDto;
};

export type SomeFunc<R, P = null> = (workflow: WorkflowDataDto, previous?: P) => CommandResult<R>;

// NOTE: for improvements
// - add result array somehow into SomeFunc (what if a command whats to use a result from 2 or 3 commands before?)
// - allow chaining multiple pipes
// - allow more then 5 commands
// - general cleanup, maybe not use arraw functions since they add a lot of boilerplate, separate operators for each command?
export class WorkflowBuilder {
  constructor(private workflow: WorkflowDataDto) {}

  pipe<A, B, C, D, E>(
    opt1: SomeFunc<A>,
    opt2?: SomeFunc<B, A>,
    opt3?: SomeFunc<C, B>,
    opt4?: SomeFunc<D, C>,
    opt5?: SomeFunc<E, D>
  ) {
    const results: [A?, B?, C?, D?, E?] = [];

    const fn1 = this.call(opt1, results);
    if (!opt2) {
      return [this.workflow, ...results] as const;
    }

    const fn2 = this.call(opt2, results, fn1);
    if (!opt3) {
      return [this.workflow, ...results] as const;
    }

    const fn3 = this.call(opt3, results, fn2);
    if (!opt4) {
      return [this.workflow, ...results] as const;
    }

    const fn4 = this.call(opt4, results, fn3);
    if (!opt5) {
      return [this.workflow, ...results] as const;
    }

    this.call(opt5, results, fn4);
    return [this.workflow, ...results] as const;
  }

  private call<A, P = null>(toCall: SomeFunc<A, P>, results: unknown[], previous?: P) {
    const fnResult = toCall(this.workflow, previous);
    this.workflow = fnResult.updatedWorkflow;
    results.push(fnResult.result);
    return fnResult.result;
  }
}
