import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common';
import { ViewportService } from '../../services/viewport.service';
import { Durations } from '@openreel/creator/common';

const BUCKET_WIDTH_PX = 80;
const BUCKET_REMAINDER_MIN_SIZE_FOR_BOTH_LABELS = 40;

@Component({
  selector: 'openreel-wf-time-bar',
  templateUrl: './time-bar.component.html',
  styleUrls: ['./time-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBarComponent extends Cleanupable implements OnInit {
  @Input() timelinesEl: HTMLDivElement;
  @Input() durations$: Observable<Durations>;

  buckets: { width: number; value: number }[] = [];
  bucketRemainder = 0;

  private viewportStartAt = 0;
  private viewportDuration = 0;

  constructor(private readonly viewportService: ViewportService, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.viewportService.viewport$
      .pipe(takeUntil(this.ngUnsubscribe), withLatestFrom(this.durations$))
      .subscribe(([{ from, to }, { total }]) => {
        const startAt = from * total;
        const endAt = to * total;

        this.viewportStartAt = Math.floor(startAt / 1000);
        this.viewportDuration = endAt - startAt;
        this.updateTimeBar();
      });

    this.durations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ total }) => {
      this.viewportStartAt = 0;
      this.viewportDuration = total;
      this.updateTimeBar();
    });
  }

  showLabel = (isLast: boolean, size: number) => !isLast || size > BUCKET_REMAINDER_MIN_SIZE_FOR_BOTH_LABELS;

  updateTimeBar() {
    if (!this.timelinesEl) {
      this.buckets = [];
      return;
    }

    const timelineWidth = this.timelinesEl.getBoundingClientRect().width;
    const durationInSec = this.viewportDuration / 1000;
    const bucketCountMax = Math.floor(timelineWidth / BUCKET_WIDTH_PX);

    let bucketSizeInSec = Math.ceil(durationInSec / bucketCountMax);
    if (bucketSizeInSec > 10 && bucketSizeInSec <= 40) {
      bucketSizeInSec = Math.ceil(bucketSizeInSec / 5) * 5;
    } else if (bucketSizeInSec > 40) {
      bucketSizeInSec = Math.ceil(bucketSizeInSec / 10) * 10;
    }

    const bucketCountUsed = Math.floor(durationInSec / bucketSizeInSec) + 1;
    const bucketRemainder = durationInSec - (bucketCountUsed - 1) * bucketSizeInSec;

    this.buckets = [];
    this.bucketRemainder = Math.round(bucketRemainder);
    for (let i = 0; i < bucketCountUsed; i++) {
      const lastBucket = i < bucketCountUsed - 1;
      const width = lastBucket
        ? (bucketSizeInSec / durationInSec) * timelineWidth
        : (bucketRemainder / durationInSec) * timelineWidth;

      this.buckets.push({
        width,
        value: this.viewportStartAt + bucketSizeInSec * i,
      });
    }

    this.cdr.markForCheck();
  }
}
