import {
  Component,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export interface OpenreelSortItem {
  label: string,
  field: string,
  direction: 'desc' | 'asc'
}

@Component({
  selector: 'openreel-sort-input',
  templateUrl: './openreel-sort-input.component.html',
})
export class OpenreelSortInputComponent{
  @Input() appearance: MatFormFieldAppearance;
  @Input() labelname: string;
  @Input() defaultValue: OpenreelSortItem;
  @Input() sortdata: OpenreelSortItem[] = [];
  @Output() onsort = new EventEmitter<OpenreelSortItem>();

  searchChange(event){
    this.onsort.emit(event.value);
  }
}
