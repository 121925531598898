import { Directive, Input, ElementRef, OnDestroy } from '@angular/core';
import { ResizedDirective } from 'angular-resize-event';
import { Cleanupable } from '../classes';

@Directive({
  selector: '[openreelAspectRatio]',
})
export class AspectRatioDirective extends Cleanupable implements OnDestroy {
  @Input() openreelAspectRatio: number;
  @Input() dominantSide: 'width' | 'height' = 'width';
  resizeDirective: ResizedDirective;

  constructor(private eRef: ElementRef<HTMLElement>) {
    super();
  }

  private adjustHeight() {
    const element = this.eRef.nativeElement;
    element.style.height =
      element.clientWidth / this.openreelAspectRatio + 'px';
  }

  private adjustWidth() {
    const element = this.eRef.nativeElement;
    element.style.width =
      element.clientHeight * this.openreelAspectRatio + 'px';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
