<div class="chat-window mat-elevation-z10" *ngIf="conversation">
  <div class="chat-header">
    <div class="chat-avatar">
      <div class="over-border">
        <img
          [src]="conversation.image"
          [openreelFallback]="'assets/common/logos/all-white.svg'"
        />
      </div>
    </div>
    <div class="chat-info">
      <p class="role">{{ conversation.role }}</p>
      <h3 class="name">{{ conversation.name }}</h3>
    </div>
    <div class="chat-tools">
      <mat-icon svgIcon="caret" (click)="close()"></mat-icon>
    </div>
  </div>
  <div #chatBody class="chat-body slim-scroll" (scroll)="paginateChat()">
    <div
      *ngFor="let message of conversation.messages"
      class="message"
      [class.local]="isSenderMe(message)"
      [class.remote]="!isSenderMe(message)"
    >
      <img
        [src]="message.thumb_image"
        [openreelFallback]="'assets/common/logos/all-white.svg'"
        class="profile-image"
        *ngIf="!isSenderMe(message)"
      />
      <div>
        <p class="sender">
          {{ isSenderMe(message) ? 'You' : message.senderName }}
        </p>
        <div class="bubble">
          <div class="content">
            <div class="text">
              {{ message.text }}
            </div>
            <div class="time">
              {{ message.created_at | date: 'HH:mm' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-footer">
    <form autocomplete="off" [formGroup]="messageForm">
      <textarea
        (keydown.enter)="sendMessage()"
        matInput
        name="message"
        placeholder="Type message"
        formControlName="message"
        class="message-composer"
        (input)="onTextChange()"
        rows="1"
        autoResize
        [maxHeight]="40"
      ></textarea>
    </form>
  </div>
</div>
