import * as fromActions from '../actions/self-record.actions';

import { createReducer, on } from '@ngrx/store';
import { TranscodeStatus } from '@openreel/common';
import { SelfRecordState, SelfRecordStatus } from '../interfaces/self-record.interfaces';

export const key = 'record';

export const initialState: SelfRecordState = {
  availableWebcamDevices: [],
  availableAudioDevices: [],
  audioDevice: null,
  webcamDevice: null,
  source: 'camera',
  status: 'idle',
  sessionId: null,
  sessionAccountId: null,
  recordingStartedAt: null,
  screenStream: null,
  screenStreamStatus: null,
  webcamStream: null,
  webcamStreamStatus: null,
  uploadWebcamStatus: null,
  uploadScreenStatus: null,
  lastUploadWebcamVideo: null,
  lastUploadScreenVideo: null,
  transcodeWebcamStatus: null,
  transcodeScreenStatus: null,
  uploadWebcamVideo: null,
  uploadScreenVideo: null,
  trims: [],
};

const reducer = createReducer(
  initialState,
  on(fromActions.init, (_, { options }) => {
    let source = initialState.source;
    if (options.allowedSources && !options.allowedSources.includes(source)) {
      source = options.allowedSources[0]
    }

    return ({
      ...initialState,
      source
    });
  }),
  on(fromActions.cleanupSuccess, () => ({
    ...initialState,
    status: 'cleanup' as SelfRecordStatus,
  })),
  on(fromActions.setOptions, (state, { options }) => ({
    ...state,
    options,
  })),
  on(fromActions.setWebcamDevices, (state, { devices }) => ({
    ...state,
    availableWebcamDevices: devices,
  })),
  on(fromActions.setAudioDevices, (state, { devices }) => ({
    ...state,
    availableAudioDevices: devices,
  })),
  on(fromActions.setSelectedWebcamDevice, (state, { device }) => ({
    ...state,
    webcamDevice: device,
  })),
  on(fromActions.setSelectedAudioDevice, (state, { device }) => ({
    ...state,
    audioDevice: device,
  })),
  on(fromActions.setSelectedAudioVideoDevice, (state, { audioDevice, videoDevice }) => ({
    ...state,
    audioDevice,
    webcamDevice: videoDevice,
  })),
  on(fromActions.setSource, (state, { source }) => ({
    ...state,
    source,
  })),
  on(fromActions.updateScreenStreamStatus, (state, { status }) => ({
    ...state,
    screenStreamStatus: status,
  })),
  on(fromActions.updateWebcamStreamStatus, (state, { status }) => ({
    ...state,
    webcamStreamStatus: status,
  })),
  on(fromActions.openScreenStream, (state) => ({
    ...state,
    screenStreamStatus: 'initializing'
  })),
  on(fromActions.openWebcamStream, (state) => ({
    ...state,
    webcamStreamStatus: 'initializing'
  })),
  on(fromActions.openScreenStreamSuccess, (state, { stream }) => ({
    ...state,
    screenStream: stream,
    screenStreamStatus: 'loading'
  })),
  on(fromActions.openWebcamStreamSuccess, (state, { stream }) => ({
    ...state,
    webcamStream: stream,
    webcamStreamStatus: 'loading'
  })),
  on(fromActions.closeWebcamStreamSuccess, (state) => ({
    ...state,
    webcamStream: null,
    webcamStreamStatus: null,
  })),
  on(fromActions.closeScreenStreamSuccess, (state) => ({
    ...state,
    screenStream: null,
    screenStreamStatus: null,
  })),
  on(fromActions.startRecording, (state) => ({
    ...state,
    status: 'recording',
    recordingStartedAt: new Date(),
  })),
  on(fromActions.stopRecordingSuccess, (state) => ({
    ...state,
    recordingStartedAt: null,
  })),
  on(fromActions.reset, (state) => ({
    ...state,
    status: 'idle',
    uploadWebcamStatus: null,
    transcodeWebcamStatus: null,
    uploadScreenStatus: null,
    transcodeScreenStatus: null,
    uploadScreenVideo: null,
    lastUploadWebcamVideo: null,
  })),
  on(fromActions.cancelWebcamUpload, (state) => ({
    ...state,
    transcodeWebcamStatus: null,
    uploadWebcamVideo: null,
    lastUploadWebcamVideo: null,
  })),
  on(fromActions.cancelScreenUpload, (state) => ({
    ...state,
    transcodeScreenStatus: null,
    uploadScreenVideo: null,
    lastUploadScreenVideo: null,
  })),
  on(fromActions.startUploading, (state) => ({
    ...state,
    status: 'loading',
  })),
  on(
    fromActions.startUploadWebcamSuccess,
    (state, { uploadBlob, uploadVideoId, uploadVideoDuration }) => ({
      ...state,
      status: 'uploading',
      uploadWebcamVideo: {
        blob: uploadBlob,
        videoId: uploadVideoId,
        duration: uploadVideoDuration,
      },
    })
  ),
  on(fromActions.uploadWebcamFinish, (state) => {
    const transcodeWebcamStatus: TranscodeStatus = {
      SessionID: state.sessionId,
      account_id: state.sessionAccountId,
      status: 'queued',
      type: 'sd_transcoding',
      video_id: String(state.uploadWebcamVideo.videoId),
      transcoding_percentage: 0,
    };

    return {
      ...state,
      status: 'uploading',
      uploadWebcamStatus: null,
      transcodeWebcamStatus,
    };
  }),
  on(fromActions.uploadWebcamProgress, (state, { uploadStatus }) => ({
    ...state,
    status: 'uploading',
    uploadWebcamStatus: uploadStatus,
  })),
  on(fromActions.transcodeWebcamProgress, (state, { transcodeStatus }) => {
    if (Number(transcodeStatus.video_id) === state.uploadWebcamVideo?.videoId) {
      return {
        ...state,
        status: 'uploading',
        transcodeWebcamStatus: transcodeStatus,
        uploadWebcamStatus: null,
      };
    }
    return state;
  }),
  on(fromActions.transcodeWebcamFinish, (state, { uploadWebcamVideo }) => {
    let newState = {
      ...state,
      uploadWebcamStatus: null,
      transcodeWebcamStatus: null,
      uploadWebcamVideo: null,
      lastUploadWebcamVideo: {
        videoId: uploadWebcamVideo.videoId,
        duration: uploadWebcamVideo.duration,
      },
    };

    if (!state.transcodeScreenStatus && !state.uploadScreenStatus) {
      newState = {
        ...newState,
        status: 'preview',
        uploadWebcamVideo: null,
        uploadScreenStatus: null,
        transcodeScreenStatus: null,
        uploadScreenVideo: null,
      };
    }

    return newState;
  }),
  on(fromActions.transcodeFinish, (state) => ({
    ...state,
    status: 'preview',
    uploadWebcamVideo: null,
    uploadScreenStatus: null,
    transcodeScreenStatus: null,
    uploadScreenVideo: null,
  })),
  on(fromActions.sessionCreated, (state, { sessionId, sessionAccountId }) => ({
    ...state,
    sessionId,
    sessionAccountId,
  })),
  on(fromActions.sessionSelect, (state, { sessionId, sessionAccountId }) => ({
    ...state,
    sessionId,
    sessionAccountId,
  })),
  on(
    fromActions.startUploadScreenSuccess,
    (state, { uploadBlob, uploadVideoId, uploadVideoDuration }) => ({
      ...state,
      status: 'uploading',
      uploadScreenVideo: {
        blob: uploadBlob,
        videoId: uploadVideoId,
        duration: uploadVideoDuration,
      },
    })
  ),
  on(fromActions.uploadScreenFinish, (state) => {
    const transcodeScreenStatus: TranscodeStatus = {
      SessionID: state.sessionId,
      account_id: state.sessionAccountId,
      status: 'queued',
      type: 'sd_transcoding',
      video_id: String(state.uploadScreenVideo.videoId),
      transcoding_percentage: 0,
    };

    return {
      ...state,
      status: 'uploading',
      uploadScreenStatus: null,
      transcodeScreenStatus,
    };
  }),
  on(fromActions.uploadScreenProgress, (state, { uploadStatus }) => ({
    ...state,
    status: 'uploading',
    uploadScreenStatus: uploadStatus,
  })),
  on(fromActions.transcodeScreenProgress, (state, { transcodeStatus }) => {
    if (Number(transcodeStatus.video_id) === state.uploadScreenVideo?.videoId) {
      return {
        ...state,
        status: 'uploading',
        transcodeScreenStatus: transcodeStatus,
        uploadScreenStatus: null,
      };
    }
    return state;
  }),
  on(fromActions.transcodeScreenFinish, (state, { uploadScreenVideo }) => {
    let newState = {
      ...state,
      uploadScreenStatus: null,
      transcodeScreenStatus: null,
      lastUploadScreenVideo: {
        videoId: uploadScreenVideo.videoId,
        duration: uploadScreenVideo.duration,
      },
      uploadScreenVideo: null,
    };

    if (!state.transcodeWebcamStatus && !state.uploadWebcamStatus) {
      newState = {
        ...newState,
        status: 'preview',
        uploadWebcamVideo: null,
        uploadScreenStatus: null,
        transcodeScreenStatus: null,
        uploadScreenVideo: null,
      };
    }

    return newState;
  }),
  on(fromActions.applyTrimToSelfRecordClips, (state, { event: { trims } }) => ({
    ...state,
    trims,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
