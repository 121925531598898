import {
  Component,
  Input,
} from '@angular/core';
import { CardData } from '../../interfaces/interfaces';
@Component({
  selector: 'openreel-card-view',
  templateUrl: './orcardview.component.html',
  styleUrls: ['./orcardview.component.scss'],
})
export class OrCardViewComponent{

  @Input() carddata:CardData;
  shwimg = true;
  constructor() {
  }
}
