import { NgModule } from '@angular/core';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// Note: Original ng-lottie doesn't support ivy
// import { LottieAnimationViewModule } from 'ng-lottie';

import { AnimationComponent } from './animation.component';
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [LottieModule.forRoot({ player: playerFactory })],
  declarations: [AnimationComponent],
  exports: [AnimationComponent],
})
export class AnimationModule {}
