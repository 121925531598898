import { BaseCommand } from './base.command';
import {
  TimelineItem,
} from '../interfaces/element.interfaces';
import { UpdateLayerFitPositionEvent } from '../interfaces/timelines.interfaces';
import { getLayerFromId } from '../helpers/timelines.helpers';

export interface UpdateLayerFitPositionResult {
  item: TimelineItem;
  timelineId: string;
}

export class UpdateLayerFitPositionCommand extends BaseCommand<
  UpdateLayerFitPositionEvent,
  UpdateLayerFitPositionResult
> {
  run(event: UpdateLayerFitPositionEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer, childLayer } = layerInfo;

    const layerToChange = childLayer ?? layer;
    if (layerToChange.styles) {
      layerToChange.styles.objectPosition = event.objectPosition
    } else {
      layerToChange.styles = { objectPosition: event.objectPosition }
    }

    return this.ok();
  }
}
