import { Pipe, PipeTransform } from '@angular/core';

const SECONDS_TO_MINUTE = 60;
const MS_TO_SECOND = 1000;

type TimeUnit = 's' | 'ms';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(duration?: number, units: TimeUnit = 's'): string {
    const durationInSeconds = this.asSeconds(duration || 0, units);

    if (durationInSeconds >= SECONDS_TO_MINUTE) {
      return this.roundAndAppend(
        durationInSeconds / SECONDS_TO_MINUTE,
        'minute'
      );
    }
    return this.roundAndAppend(durationInSeconds, 'second');
  }

  private asSeconds(duration: number, units: string): number {
    const positiveDuration = duration < 0 ? 0 : duration;
    switch (units) {
      case 'ms':
        return positiveDuration / MS_TO_SECOND;
      case 's':
        return positiveDuration;
    }
  }

  private roundAndAppend(value: number, word: string): string {
    return `${Math.floor(value)} ${word}${value !== 1 ? 's' : ''}`;
  }
}
