import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first, filter, takeUntil } from 'rxjs/operators';
import { AssetsCacheFacade } from '@openreel/creator/app/store/facades/asset-cache.facade';
import { BehaviorSubject } from 'rxjs';
import { SelfRecordFacade } from '../../store/facades/self-record.facade';
import {
  Cleanupable,
  PlayerComponent,
  PlayerState,
  RecordingType,
  Video,
} from '@openreel/common';
import { SelfRecordingVideo } from '../../store/interfaces/self-record.interfaces';

@Component({
  selector: 'openreel-wf-self-record-preview',
  templateUrl: './self-record-preview.component.html',
  styleUrls: ['./self-record-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfRecordPreviewComponent extends Cleanupable implements OnInit {
  @Input() previewType: 'upload' | 'uploaded';
  recordingType: RecordingType;

  @ViewChild('wrapper') wrapper: ElementRef<HTMLDivElement>;
  @ViewChild('videoPlayer') videoPlayer: PlayerComponent;

  isPlaying$ = new BehaviorSubject<boolean>(false);
  video$ = new BehaviorSubject<Video>(null);

  constructor(
    public readonly selfRecordFacade: SelfRecordFacade,
    private readonly assetsCacheFacade: AssetsCacheFacade,
    public readonly sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.selfRecordFacade.source$.pipe(first()).subscribe((recordingType) => {
      this.video$.next({
        contentType: 'video/webm',
        recordingType,
      });
      this.recordingType = recordingType;
    });

    if (this.previewType === 'uploaded') {
      this.initUploadedPreview();
    } else if (this.previewType === 'upload') {
      this.initUploadPreview();
    }
  }

  private initUploadedPreview() {
    let webcamVideo: SelfRecordingVideo, screenVideo: SelfRecordingVideo;

    this.selfRecordFacade.lastUploadWebcamVideo$
      .pipe(first())
      .subscribe((video) => (webcamVideo = video));

    this.selfRecordFacade.lastUploadScreenVideo$
      .pipe(first())
      .subscribe((video) => (screenVideo = video));

    if (webcamVideo?.videoId) {
      this.assetsCacheFacade
        .getAssetUrlById('or-recordings', webcamVideo.videoId)
        .pipe(first())
        .subscribe((url) => {
          const video = this.video$.value;
          if (video.recordingType === 'camera') {
            video.mainStream = url;
          } else {
            video.secondaryStream = url;
          }
          video.duration = webcamVideo.duration;
          this.video$.next(video);
        });
    }

    if (screenVideo?.videoId) {
      this.assetsCacheFacade
        .getAssetUrlById('or-recordings', screenVideo.videoId)
        .pipe(first())
        .subscribe((url) => {
          const video = this.video$.value;
          video.mainStream = url;
          video.duration = screenVideo.duration;
          this.video$.next(video);
        });
    }
  }

  private initUploadPreview() {
    if (this.recordingType === 'camera') {
      this.selfRecordFacade.uploadWebcamVideo$
        .pipe(filter((video) => !!video))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((uploadWebcamVideo) => {
          this.video$.next({
            recordingType: this.recordingType,
            contentType: 'video/webm',
            mainBlob: uploadWebcamVideo.blob,
            duration: uploadWebcamVideo.duration,
          });
        });
    } else if (this.recordingType === 'screen') {
      this.selfRecordFacade.uploadScreenVideo$
        .pipe(filter((video) => !!video))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((uploadScreenVideo) => {
          this.video$.next({
            recordingType: this.recordingType,
            contentType: 'video/webm',
            mainBlob: uploadScreenVideo.blob,
            duration: uploadScreenVideo.duration,
          });
        });
    } else {
      this.selfRecordFacade.uploadWebcamVideo$
        .pipe(filter((video) => !!video))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((uploadWebcamVideo) => {
          const video = this.video$.value;
          this.video$.next({
            ...video,
            secondaryBlob: uploadWebcamVideo.blob,
            duration: uploadWebcamVideo.duration,
          });
        });
      this.selfRecordFacade.uploadScreenVideo$
        .pipe(filter((video) => !!video))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((uploadScreenVideo) => {
          const video = this.video$.value;
          this.video$.next({
            ...video,
            mainBlob: uploadScreenVideo.blob,
            duration: uploadScreenVideo.duration,
          });
        });
    }
  }

  playStateUpdate(playState: PlayerState) {
    this.isPlaying$.next(playState === 'playing');
  }

  onSeek(ms: number) {
    this.videoPlayer.player.currentTime(ms / 1000);
  }

  async fullscreen() {
    try {
      await this.wrapper.nativeElement.requestFullscreen();
    } catch {}
  }

  exitFullscreen() {
    document.exitFullscreen();
  }
}
