<mat-form-field class="telepromter-form-field" appearance="outline">
  <div id="hwt-container" class="hwt-container">
    <textarea
      #highlightableTextarea
      id="highlightable-textarea"
      class="slim-scroll hwt-input hwt-content"
      [ngModel]="text"
      (ngModelChange)="onTextChanged($event)"
      matInput
      rows="10"
      name="telepromter-text"
      (click)="updateClickPosition()"
      (select)="onTextAreaClicked()"
      (keyup)="onTextAreaKeyPressed()"
    ></textarea>
  </div>
</mat-form-field>
