/*eslint no-bitwise: [2, { allow: ["&", "<<"] }] */
export class BufferScan {
    
    //
    // scanDataValueU
    //
    static scanDataValueU(buff, pos, size) {
        let uVal = 0;
        let byteVal;
        for (let i = 0; i < size; i++) {
            byteVal = buff[pos + i];
            uVal = (uVal << 8) + byteVal;
        }
        return uVal;
    }

    //
    // scanDataUTF8
    //
    static scanDataUTF8(buff, pos, size) {
        const part = buff.slice(pos, pos + size);
        const sVal = String.fromCharCode(... part);
        return sVal;
    }

    //
    // reads a chunk of file sync
    //
    static async readFile(file:File, offset:number, length:number){
        return new Promise((resolve, reject) => {
            const fr = new FileReader();  
            fr.onload = () => {
                const buffer:ArrayBuffer = new Uint8Array(fr.result as ArrayBuffer);
                resolve(buffer)
            };
            fr.onerror = reject;
            const slice = file.slice(offset, offset + length);
            fr.readAsArrayBuffer(slice);
        });
    }
}