import { Injectable, OnDestroy } from '@angular/core';
import { NewVideoInfo } from '@openreel/common/interfaces';
import { BehaviorSubject, Subject, zip } from 'rxjs';
import { filter, take, switchMap, shareReplay } from 'rxjs/operators';

import { AudioDevice, VideoDevice } from '../../../media';

export enum RecordingState {
  IDLE,
  STARTING_RECORDING,
  RECORDING,
  STOPPING_RECORDING,
}

// is the raw recording process. There may be different implementations of the
// recorder service. It can provide you with raw data that is recorded.
// It is also "filename-based" meaning that, it should be able to recover any
// previous video recorded with that particular name. You can delete any
// previous videos as well.
@Injectable()
export abstract class ILocalRecorderService implements OnDestroy {
  newVideoInfo: NewVideoInfo;
  lastFileName: string;
  lastFileResolution: number;
  recordingState$ = new BehaviorSubject<RecordingState>(RecordingState.IDLE);
  recordingStartedAt$ = new BehaviorSubject<Date>(null);
  lastRecordingDuration$ = new BehaviorSubject<number>(null);
  lastError$ = new BehaviorSubject<Error>(null);
  // stop$: Subject<boolean>;
  protected stopSource = new Subject<{ value: true; videoId: number }>();
  protected partsStopSource = new Subject<{ value: true; videoId: number }>();
  stop$ = zip(this.stopSource, this.partsStopSource);
  protected chunkAvailableSource = new Subject<{
    localFileName: string;
    data: Blob;
    videoId: number;
  }>();
  private chunkResetSource = new BehaviorSubject<true>(true);
  chunkAvailable$ = this.chunkResetSource.pipe(
    switchMap(() => this.chunkAvailableSource.pipe(shareReplay(1)))
  );
  globalVideoId: number;
  public stopRecordingOnError = new Subject<string>();

  constructor() {
    this.recordingState$.subscribe((state) => {
      console.log('Recording state is now: ' + RecordingState[state]);
    });
    window.addEventListener('beforeunload', () => {
      this.ngOnDestroy();
    });
  }

  async ngOnDestroy() {
    await this.stopRecording();
  }

  async startRecordingStream(
    videoId: number = null,
    stream: MediaStream,
    fileName = null
  ) {
    if (!videoId) {
      this.globalVideoId = new Date().getTime();
      videoId = this.globalVideoId;
    }
    // first try to stop recording
    await this.stopRecording(videoId);
    this.lastError$.next(null);
    this.lastFileName = fileName ? fileName : this.getNewFileName('');
    this.recordingStartedAt$.next(new Date());
    this.recordingState$.next(RecordingState.STARTING_RECORDING);
    await this.doStartRecordingStream(videoId, stream, this.lastFileName);
    await this.waitForState(RecordingState.RECORDING);
  }

  async startRecording(
    videoId: number = null,
    resolution: number,
    fps: number,
    audioDevice: AudioDevice,
    videoDevice: VideoDevice
  ) {
    if (!videoId) {
      this.globalVideoId = new Date().getTime();
      videoId = this.globalVideoId;
    }
    // first try to stop recording
    await this.stopRecording(videoId);
    this.lastError$.next(null);
    this.lastFileResolution = resolution;
    this.lastFileName = this.getNewFileName('');
    this.recordingStartedAt$.next(new Date());
    this.recordingState$.next(RecordingState.STARTING_RECORDING);
    await this.doStartRecording(fps, audioDevice, videoDevice);
    await this.waitForState(RecordingState.RECORDING);
  }
  async stopRecording(videoId: number = null) {
    if (!videoId) videoId = this.globalVideoId;
    if (this.recordingState$.value === RecordingState.RECORDING) {
      this.recordingState$.next(RecordingState.STOPPING_RECORDING);
      await this.doStopRecording(videoId);
      await this.waitForState(RecordingState.IDLE);
      const current = new Date();
      this.lastRecordingDuration$.next(
        current.getTime() - (this.recordingStartedAt$.value?.getTime() || 0)
      );
      this.recordingStartedAt$.next(null);
    }
  }
  protected chunkReset() {
    this.chunkResetSource.next(true);
  }
  protected async waitForState(requestedState: RecordingState) {
    // Wait for recording state to be idle
    await this.recordingState$
      .pipe(
        filter((state) => state === requestedState),
        take(1)
      )
      .toPromise();
  }
  protected abstract doStartRecording(
    fps: number,
    audioDevice: AudioDevice,
    videoDevice: VideoDevice
  );
  abstract doStartRecordingStream(
    videoId: number,
    stream: MediaStream,
    filename: string
  );
  abstract getNewFileName(fileNameAppend: string): string;
  abstract doStopRecording(videoId: number);
  abstract getFileSizeMB(): Promise<number>;
  abstract getFileLengthSeconds(): Promise<number>;
  abstract getFileData(localFileName: string): Promise<Blob>;
  abstract removeFileData(localFileName: string): Promise<void>;
  abstract getFileNameForUpload(): string;
}
