import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
  constructor(private overlayContainer: OverlayContainer) {}

  setTheme(theme: string) {
    this.overlayContainer.getContainerElement().classList.add(theme);
  }
}