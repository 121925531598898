import { createAction, props } from '@ngrx/store';
import { TeleprompterScript } from '@openreel/common';
import { SelfRecordTeleprompterStatus } from '../interfaces/self-record-teleprompter.interfaces';

const actionType = '[SelfRecordTeleprompter]';

export const refreshScripts = createAction(
  `${actionType} Refresh scripts list`
);

export const refreshScriptsSuccess = createAction(
  `${actionType} Refresh scripts list success`,
  props<{ scripts: TeleprompterScript[] }>()
);

export const openTeleprompterDialog = createAction(
  `${actionType} Open teleprompter dialog`
);

export const changeText = createAction(
  `${actionType} change teleprompter text`,
  props<{ text: string }>()
);

export const updateStatus = createAction(
  `${actionType} Update status`,
  props<{ status: SelfRecordTeleprompterStatus }>()
);

export const cleanup = createAction(`${actionType} Cleanup`);
