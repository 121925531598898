import { WorkflowDataDto } from '../interfaces/workflow.interfaces';
import { BaseCommand } from './base.command';

export class RemoveFreemiumWatermarkCommand extends BaseCommand<void> {
  run() {
    this.removeFreemiumWatermark(this.source);

    return this.ok();
  }

  private removeFreemiumWatermark(workflow: WorkflowDataDto) {
    Object.values(workflow.sections).forEach((section) => {
      section.timelines = section.timelines.filter((t) => t.type !== 'freemium');
    });
  }
}
