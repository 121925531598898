import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'openreel-confirmation-toast',
  templateUrl: './confirmation-toast.component.html',
  styleUrls: ['./confirmation-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class ConfirmationToastComponent extends Toast implements OnDestroy {
  @Input()
  iconSvg?: string;
  @Input()
  yesText: string;
  @Input()
  noText: string;

  @Input()
  containerClass = 'confirmation-toast';

  // true if yes is clicked, false if no
  @Output()
  onSelection = new EventEmitter<boolean>();

  ngOnDestroy() {
    // we have to subscribe explicitly to this so make sure subscriptions end
    this.onSelection.complete();
  }
}
