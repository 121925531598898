import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormControl } from 'ngx-strongly-typed-forms';
import { Cleanupable } from 'libs/common/src/classes';
import { teleprompterOptions } from '../teleprompter-options';
import { TeleprompterPresetsDto } from '../../../interfaces/teleprompter.interface';

@Component({
  selector: 'openreel-teleprompter-presets-form',
  templateUrl: './teleprompter-presets-form.component.html',
  styleUrls: ['./teleprompter-presets-form.component.scss'],
})
export class TeleprompterPresetsFormComponent
  extends Cleanupable
  implements OnInit, OnDestroy {
  @Output() presetsChange = new EventEmitter<TeleprompterPresetsDto>();
  @Input() settings: TeleprompterPresetsDto;
  form: FormGroup<TeleprompterPresetsDto>;
  options = teleprompterOptions;
  needUpdate = true;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setupForm() {
    this.form = new FormGroup<TeleprompterPresetsDto>({
      speed: new FormControl(),
      font_size: new FormControl(),
      screen_setup: new FormControl(),
      background: new FormControl(),
    });

    this.form.setValue(this.settings);

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        if (this.needUpdate) {
          this.presetsChange.emit(value);
        }
        this.needUpdate = true;
      })
    );
  }
}
