import { ColorPaletteGenerator } from './color-palette-generator';

const DEFAULT_COLOR = '#000000';

export function getColorLottieTransformation(color: string, paletteKey?: number) {
  if (paletteKey) {
    const entirePalette = ColorPaletteGenerator.generatePalette(color);
    const paletteValue = entirePalette.find((p) => p.shade === paletteKey);
    return hexToRgb(paletteValue?.hex ?? color);
  }

  return hexToRgb(color);
}

export function getColorLottie(fieldKey: string, color: string, generatePalette: boolean) {
  let colors = getSingleColorLottie(fieldKey, color);

  if (generatePalette) {
    ColorPaletteGenerator.generatePalette(color).forEach((shadeColor) => {
      colors = {
        ...colors,
        ...getSingleColorLottie(fieldKey, shadeColor.hex, shadeColor.shade),
      };
    });
  }

  return colors;
}

export function getSingleColorLottie(fieldKey: string, color: string, shade: number = null) {
  const rgbValues = hexToRgb(color ?? DEFAULT_COLOR);
  const shadeString = shade ? shade : '';

  return {
    [`${fieldKey}Color${shadeString}`]: `[${rgbValues.toString()}]`,
    [`${fieldKey}Color${shadeString}R`]: rgbValues[0].toString(),
    [`${fieldKey}Color${shadeString}G`]: rgbValues[1].toString(),
    [`${fieldKey}Color${shadeString}B`]: rgbValues[2].toString(),
    [`${fieldKey}Color${shadeString}Hex`]: `"${color}"`,
  };
}

// Source: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex: string): number[] | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : null;
}
