import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';
import { getTimelineById } from '../helpers/timelines.helpers';

export class ChangeItemTimelineCommand extends BaseCommand<TimelineInterfaces.ItemTimelineChangedEvent> {
  run(event: TimelineInterfaces.ItemTimelineChangedEvent) {
    const { previousTimelineId, currentTimelineId, previousIndex, currentIndex } = event;

    const { timeline: previousTimeline, sectionId } = getTimelineById(previousTimelineId, this.source.sections);
    const { timeline: currentTimeline } = getTimelineById(currentTimelineId, this.source.sections);

    if (!previousTimeline || !currentTimeline) {
      return this.error('Can not find timeline to update.');
    }

    const [layer] = previousTimeline.layers.splice(previousIndex, 1);
    if (!layer) {
      return this.error('Can not find layer to update.');
    }

    [layer.bounds, layer.styles] = this.getTimelineBoundsAndStyles(currentTimeline);

    currentTimeline.layers.splice(currentIndex, 0, layer);

    this.recalculateVisibility(previousTimeline);
    this.recalculateVisibility(currentTimeline);
    this.removeLayersOutOfBounds(sectionId);

    return this.ok();
  }
}
