import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PlayerCaptionsData } from './cue-player-captions.interfaces';
import { TimeboxDto } from '../../../../../common/src/services/captions/captions.interfaces';

@Component({
  selector: 'openreel-cue-player-captions',
  templateUrl: './cue-player-captions.component.html',
  styleUrls: ['./cue-player-captions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerCaptionsComponent implements OnChanges {
  @Input() data: PlayerCaptionsData = null;
  @Input() currentTime: number;

  currentTimebox: TimeboxDto = null;

  ngOnChanges(changes: SimpleChanges): void {
    if ('currentTime' in changes) {
      if (
        this.currentTimebox &&
        this.currentTimebox.startMs <= this.currentTime &&
        this.currentTimebox.endMs >= this.currentTime
      ) {
        return;
      }
      this.getCurrentCaption();
    } else if ('data' in changes) {
      this.getCurrentCaption();
    }
  }

  private getCurrentCaption() {
    let timeboxFound = false;
    for (const paragraph of this.data.paragraphs) {
      const foundTimebox = paragraph.timeboxes.find(
        (timebox) => timebox.startMs <= this.currentTime && timebox.endMs >= this.currentTime
      );

      if (foundTimebox) {
        timeboxFound = true;
        this.currentTimebox = foundTimebox;
        break;
      }
    }
    if (!timeboxFound) {
      this.currentTimebox = null;
    }
  }
}
