import { Injectable } from '@angular/core';
import slugify from 'slugify';

@Injectable({ providedIn: 'root' })
export class SlugService {
    toSlug(text: string, trim = true) {
        return slugify(text, {
            lower: true,
            remove: /[^\w\s_.\-~]+/g,
            trim,
        })
    }
}