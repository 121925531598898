import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControllerData, Layout } from '../../interfaces/player-data.interfaces';
import {
  OnErrorEvent,
  OnTimeUpdateEvent,
  OnTransitionStartEvent,
  PlayerEvent,
} from '../../interfaces/player-event.interfaces';

import { CuePlayerBaseComponent } from '../../interfaces/cue-player-base.interface';
import { TransitionPlayer } from './transition.player';
import { CueEventsService } from '../../services/cue-events.service';
import { clamp, cloneDeep, isEqual, round } from 'lodash-es';
import { CuePlayerItemComponent } from './cue-player-item.component';
import {
  calculateStylesBackground,
  calculateStylesBorderRadius,
  calculateStylesBorderWidth,
} from '@openreel/creator/common';
import { Interactions } from '../cue-player-selectable/cue-player-selectable.component';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';

export interface ControllerStyles {
  layout?: { width: string; height: string; x: string; y: string };
  borderRadiusOuter?: string;
  borderRadiusInner?: string;
  borderWidth?: string;
  padding?: string;
  borderColor?: string;
  background?: string;
}

const ZOOM_TO_FIT_TOLERANCE_PX = 5;

@Component({
  selector: 'openreel-cue-player-controller',
  templateUrl: './cue-player-controller.component.html',
  styleUrls: ['./cue-player-controller.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerControllerComponent extends CuePlayerBaseComponent implements OnInit, OnDestroy {
  @Input() data: ControllerData;
  @Input() hide: boolean;

  @Output() onTransitionStart = new EventEmitter<OnTransitionStartEvent>();
  @Output() onTransitionEnd = new EventEmitter<PlayerEvent>();

  @ViewChild('player') player: CuePlayerItemComponent;
  @ViewChild('transitionCuePlayer') transitionCuePlayer: CuePlayerItemComponent;
  @ViewChild('controllerContainer') controllerContainer: ElementRef<HTMLDivElement>;
  @ViewChild('playerContainer') playerContainer: ElementRef<HTMLDivElement>;

  @HostBinding('class.interactable') get isInteractable() {
    return (
      !!this.data.selection?.selectable ||
      !!this.data.controls?.zoomToFit ||
      !!this.data.controls?.switch ||
      (this.allowZoomedDrag && this.data.styles?.objectFit === 'cover')
    );
  }

  interactions: Interactions;

  layout: Layout;
  defaultLayout: Layout;

  private mouseDownStart: number[];
  private initialObjectPosition: number[];
  private finalObjectPosition: number[];
  private mouseMoveSubscription = new Subscription();
  private mouseUpSubscription = new Subscription();
  private resizeObserver: ResizeObserver;

  get allowZoomedDrag() {
    return (
      !!this.data.controls?.zoomToFit &&
      !!this.player?.isVideoPlayer &&
      (Math.abs(this.player.dimensions.elementHeight - this.player.dimensions.renderedHeight) >
        ZOOM_TO_FIT_TOLERANCE_PX ||
        Math.abs(this.player.dimensions.elementWidth - this.player.dimensions.renderedWidth) > ZOOM_TO_FIT_TOLERANCE_PX)
    );
  }

  @HostListener('mousedown', ['$event'])
  mousedown(event: MouseEvent) {
    if (!this.allowZoomedDrag || this.data.styles?.objectFit !== 'cover') {
      return;
    }

    this.initialObjectPosition = this.data.styles?.objectPosition;
    if (!this.initialObjectPosition) {
      this.initialObjectPosition = [50, 50];
    }
    this.finalObjectPosition = [...this.initialObjectPosition];

    this.mouseDownStart = [event.pageX, event.pageY];

    this.mouseMoveSubscription = fromEvent(document, 'mousemove').subscribe((moveEvent: MouseEvent) => {
      const mousePositionOffset = [this.mouseDownStart[0] - moveEvent.pageX, this.mouseDownStart[1] - moveEvent.pageY];

      if (this.player.dimensions.elementHeight === this.player.dimensions.renderedHeight) {
        const currentOffset = Math.abs(this.player.dimensions.elementWidth - this.player.dimensions.renderedWidth);
        const mousePositionOffsetPc = (mousePositionOffset[0] * 100) / currentOffset;
        this.finalObjectPosition = [round(clamp(this.initialObjectPosition[0] + mousePositionOffsetPc, 0, 100), 2), 50];
      } else if (this.player.dimensions.elementWidth === this.player.dimensions.renderedWidth) {
        const currentOffset = Math.abs(this.player.dimensions.elementHeight - this.player.dimensions.renderedHeight);
        const mousePositionOffsetPc = (mousePositionOffset[1] * 100) / currentOffset;
        this.finalObjectPosition = [50, round(clamp(this.initialObjectPosition[1] + mousePositionOffsetPc, 0, 100), 2)];
      } else {
        throw new Error('Drag for zoom to fill works only on object-fit: cover');
      }

      this.onUpdateZoomPosition(this.finalObjectPosition, false);
    });
    this.mouseUpSubscription = fromEvent(document, 'mouseup').subscribe(() => {
      this.onUpdateZoomPosition(this.finalObjectPosition, true);
      this.mouseMoveSubscription.unsubscribe();
      this.mouseUpSubscription.unsubscribe();
    });
  }

  styles?: ControllerStyles = {};

  private transitionPlayers: {
    entrance?: TransitionPlayer;
    exit?: TransitionPlayer;
    cross?: TransitionPlayer;
  } = {};
  private transitionReported = false;

  get transitionDuration(): number {
    return this.data.transitions?.cross?.duration || this.transitionCuePlayer?.duration || 0;
  }

  get transitionStart(): number {
    return this.duration - this.transitionDuration / 2;
  }

  get transitionEnd(): number {
    return this.duration + this.transitionDuration / 2;
  }

  get hasTransitions(): boolean {
    return Object.values(this.transitionPlayers).length > 0;
  }

  get controlDuration(): number {
    return this.duration;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private host: ElementRef,
    private cueEventsService: CueEventsService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.layout = this.data.layout;
    this.defaultLayout = this.data.defaultLayout;
    this.calculateLayoutStyles(this.layout);

    this.interactions = {
      resizeable: !!this.data.selection?.selectable,
      actions: {
        canSwitch: !!this.data.controls?.switch,
        canZoom: !!this.data.controls?.zoomToFit,
      },
    };

    this.resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      this.calculateStyles({ hostWidth: width, hostHeight: height });
    });

    this.resizeObserver.observe(this.host.nativeElement);

    // Listen for previewer events
    this.cueEventsService.playerEvents$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (event.type === 'reset-bounds') {
        if (event.externalId === this.data.playerData.externalId) {
          this.layout = cloneDeep(event.defaultBounds);
          this.calculateLayoutStyles(this.layout);
        }
      }

      if (event.type === 'toggle-layer-fit' && event.externalId === this.data.playerData.externalId) {
        this.data.styles = { ...this.data.styles, objectFit: event.objectFit };
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.resizeObserver?.unobserve(this.host.nativeElement);
  }

  onChildPlayerLoaded() {
    this.isLoaded = true;
    this.duration = this.player.duration;

    if (this.data.transitions?.cross) {
      this.transitionPlayers.cross = new TransitionPlayer(
        this.controllerContainer.nativeElement,
        this.transitionStart,
        this.transitionDuration,
        this.data.transitions.cross.type,
        null,
        this.emitTransitionEndEvent.bind(this)
      );
    }
    if (this.data.transitions?.exit) {
      this.transitionPlayers.exit = new TransitionPlayer(
        this.controllerContainer.nativeElement,
        this.duration - this.data.transitions.exit.duration,
        this.data.transitions.exit.duration,
        this.data.transitions.exit.type
      );
    }
    if (this.data.transitions?.entrance) {
      this.transitionPlayers.entrance = new TransitionPlayer(
        this.controllerContainer.nativeElement,
        0,
        this.data.transitions.entrance.duration,
        this.data.transitions.entrance.type
      );
    }
    if (this.data.transitions?.crossLayer) {
      this.transitionPlayers.cross = new TransitionPlayer(
        this.controllerContainer.nativeElement,
        this.transitionStart,
        this.transitionDuration,
        'layer',
        this.transitionCuePlayer
      );
    }

    this.emitLoadedEvent();
  }

  onChildPlayerTimeUpdate({ currentTime }: OnTimeUpdateEvent) {
    if (this.hasTransitions) {
      if (this.isPlaying()) {
        if (!this.transitionReported) {
          this.transitionReported = this.playAllTransitions(currentTime);

          if (this.transitionPlayers?.cross || this.transitionCuePlayer) {
            const offset = currentTime - this.transitionStart;
            if (offset >= 0) {
              this.emitTransitionStartEvent(0);
            }
          }
        }
      }
    }

    this.emitTimeUpdateEvent(currentTime);
  }

  onChildPlayerEnded() {
    this.emitEndedEvent();
  }

  onChildSeeking() {
    this.emitSeekingEvent();
  }

  onChildSeeked() {
    this.emitSeekedEvent();
  }

  onChildError({ error }: OnErrorEvent) {
    this.emitErrorEvent(error);
  }

  play(): Promise<void> {
    if (this.hasTransitions) {
      this.playAllTransitions(this.currentTime());
    }

    if (this.currentTime() <= this.player.currentTime()) {
      return this.player.play();
    }
  }

  pause() {
    if (this.hasTransitions) {
      this.pauseAllTransitions(this.currentTime());
    }
    return this.player.pause();
  }

  stop() {
    if (this.hasTransitions) {
      this.stopAllTransitions();
    }

    this.transitionReported = false;
    this.changeDetectorRef.detectChanges();
    return this.player.stop();
  }

  currentTime(time?: number): number {
    if (time || time === 0) {
      if (this.hasTransitions) {
        this.updateAllTransitions(time);
      }
    }

    if (this.transitionDuration > 0) {
      const transitionOffset = this.transitionPlayers.cross?.currentTime - this.transitionDuration / 2;
      if (transitionOffset > 0) {
        return this.player?.currentTime(time) + transitionOffset;
      }
    }

    return this.player?.currentTime(time);
  }

  isPlaying(): boolean {
    return this.player.isPlaying();
  }

  // Actions
  onUpdateBounds({ layout, done }: { layout: Layout; done: boolean }) {
    this.calculateLayoutStyles(layout);
    if (done) {
      const hasChanges = !isEqual(layout, this.layout);

      if (hasChanges) {
        this.layout = layout;

        const externalId = this.data.playerData.externalId;
        this.cueEventsService.setPlayerEvent({
          type: 'update-bounds',
          externalId,
          bounds: this.layout,
          maintainAspectRatio: this.player.isVideoPlayer,
        });
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  onUpdateZoomPosition(objectPosition: number[], done: boolean) {
    this.data.styles = {
      ...this.data.styles,
      objectPosition,
    };

    this.changeDetectorRef.markForCheck();

    if (done) {
      const externalId = this.data.playerData.externalId;
      this.cueEventsService.setPlayerEvent({
        type: 'update-layer-fit-position',
        externalId,
        objectPosition: this.data.styles.objectPosition,
      });
    }
  }

  protected emitTransitionStartEvent(offset: number) {
    this.onTransitionStart.emit({
      id: this.id,
      offset,
    });
  }

  protected emitTransitionEndEvent() {
    this.onTransitionEnd.emit({ id: this.id });
  }

  protected playAllTransitions(time: number): boolean {
    let playOk = true;
    Object.values(this.transitionPlayers).forEach((player) => {
      if (!player.play(time)) {
        playOk = false;
      }
    });
    return playOk;
  }

  protected updateAllTransitions(time: number) {
    Object.values(this.transitionPlayers).forEach((player) => player.update(time));
  }

  protected stopAllTransitions() {
    Object.values(this.transitionPlayers).forEach((player) => player.stop());
  }

  protected pauseAllTransitions(time: number) {
    Object.values(this.transitionPlayers).forEach((player) => {
      player.pause(time);
    });
  }

  private calculateLayoutStyles(data: Layout) {
    const x = data?.x ? `${data?.x}%` : '0';
    const y = data?.y ? `${data?.y}%` : '0';

    const width = data?.width ? `${data?.width}%` : data?.height ? 'auto' : '100%';

    const height = data?.height ? `${data?.height}%` : data?.width ? 'auto' : '100%';

    this.styles = {
      ...this.styles,
      layout: { x, y, width, height },
    };

    this.changeDetectorRef.markForCheck();
  }

  private calculateStyles({ hostWidth, hostHeight }: { hostWidth: number; hostHeight: number }) {
    if (!this.data.styles) {
      return;
    }

    const { border } = this.data.styles;

    const { cssBorderWidth, borderWidthPx } = calculateStylesBorderWidth(
      this.data.styles.border,
      hostWidth,
      hostHeight
    );

    const { cssBackground } = calculateStylesBackground(this.data.styles.backgroundColor);

    const { cssBorderRadiusOuter, cssBorderRadiusInner } = calculateStylesBorderRadius(
      this.data.styles.borderRadius,
      borderWidthPx,
      hostWidth,
      hostHeight
    );

    const newStyles: ControllerStyles = {
      background: cssBackground,
      borderRadiusOuter: cssBorderRadiusOuter,
      borderRadiusInner: cssBorderRadiusInner,
    };

    if (cssBorderWidth) {
      if (border?.color) {
        newStyles.borderWidth = cssBorderWidth;
        newStyles.borderColor = border?.color;
      } else {
        newStyles.padding = cssBorderWidth;
      }
    }

    this.styles = {
      ...this.styles,
      ...newStyles,
    };

    this.changeDetectorRef.markForCheck();
  }
}
