<openreel-teleprompter-presets-form
  #teleprompterPreset
  *ngIf="settings"
  (presetsChange)="onPresetsChange($event)"
  [settings]="settings"
>
</openreel-teleprompter-presets-form>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="20px"
  style="margin-top: 30px; padding-top: 30px; border-top: 2px solid #3b4151"
>
  <div fxFlex="40" fxFlex.xs="100">
    <div>
      Script
      <span style="color: #697080; margin-left: 10px">
        <span *ngIf="highlightedText">{{ highlightedText | wordCount }}/</span
        ><span>{{ text | wordCount }} Words</span>
      </span>
    </div>
  </div>
  <div fxFlex="60" fxFlex.xs="100">
    <span class="save-icon-holder" (click)="createUpdateScript(false)">
      <mat-icon svgIcon="save"></mat-icon>
    </span>
    <mat-form-field class="telepromter-form-field" appearance="outline">
      <mat-select
        placeholder="Create new script"
        name="scripts-list"
        [formControl]="selectedScript"
        (selectionChange)="onScriptChanged()"
        style="padding-bottom: 0px"
        fxFill
      >
        <mat-option>Create new script</mat-option>
        <mat-option
          *ngFor="let script of scripts"
          [value]="script.ovra_teleprompter_script_id"
        >
          {{ script.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="editor-wrapper">
  <mat-form-field>
    <input
      type="text"
      placeholder="Title"
      matInput
      name="scriptName"
      (keyup)="handlePropagation($event)"
      (keydown)="handlePropagation($event)"
      [formControl]="pendingTitleControl"
    />
  </mat-form-field>
  <openreel-rich-text-editor
    #richTextEditor
    (highlightedTextChange)="onHighlightedTextChanged($event)"
    (cursorPositionChange)="onCursorPositionChanged($event)"
    (editorContentChange)="onTextChanged($event)"
    [teleprompterCounting$]="teleprompterCounting$"
    [editorContent]="text"
    [height]="200"
    [isDark]="true"
    [isPlayable]="isCursorPlayable$ | async"
    [customEditorCommands]="[
      FroalaCommand.CURSOR_PLAY,
      FroalaCommand.SELECTION_PLAY
    ]"
  ></openreel-rich-text-editor>
  <openreel-countdown
    for="teleprompter"
    [overrideCountdown]="countdown$ | async"
  ></openreel-countdown>
</div>

<div class="buttons-wrapper">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxLayoutGap="6px grid"
    fxLayoutGap.xs="12px grid"
  >
    <div fxFlex="25" fxFlex.xs="34">
      <button
        mat-stroked-button
        class="action-btn"
        [disabled]="!(canStart | async)"
        *ngIf="!canPause"
        (click)="onStartButtonClicked()"
      >
        <mat-icon svgIcon="teleprompter-play"></mat-icon>
        <span>Play</span>
      </button>
      <button
        mat-flat-button
        color="warn"
        class="action-btn"
        [disabled]="teleprompterCounting$ | async"
        (click)="onPauseButtonClicked()"
        *ngIf="canPause"
      >
        <mat-icon svgIcon="teleprompter-pause"></mat-icon>
        <span>Pause</span>
      </button>
    </div>
    <div fxFlex="25" fxFlex.xs="34">
      <button
        mat-stroked-button
        class="action-btn"
        [disabled]="!(canStop | async)"
        (click)="onRestartButtonClicked()"
      >
        <mat-icon svgIcon="teleprompter-reset"></mat-icon>
        <span>Restart</span>
      </button>
    </div>
    <div fxFlex="25" fxFlex.xs="34">
      <button
        mat-button
        [ngClass]="canStop ? 'mat-flat-button' : 'mat-stroked-button'"
        class="action-btn"
        color="primary"
        [disabled]="!(canStop | async)"
        (click)="onStopButtonClicked()"
      >
        <mat-icon svgIcon="teleprompter-stop"></mat-icon>
        <span>Stop</span>
      </button>
    </div>
    <div fxFlex="25" fxFlex.xs="34">
      <button
        mat-stroked-button
        class="action-btn"
        (click)="onPreviewButtonClicked()"
        [disabled]="!canPreview || devices.length > 0"
      >
        <mat-icon svgIcon="teleprompter-preview"></mat-icon>
        <span>Preview</span>
      </button>
    </div>
  </div>
</div>

<div class="devices-header">
  Display on Devices
  <mat-slide-toggle
    (change)="toggleGlobalTeleprompterScreen($event)"
    [checked]="isTeleprompterEnabledForAll$ | async"
    [disabled]="!(canGlobalEnableDisable | async)"
    labelPosition="before"
    class="global-toogle"
    >All Devices</mat-slide-toggle
  >
</div>

<div *ngFor="let device of devices; trackBy: deviceTrackBy">
  <openreel-teleprompter-participant
    [device]="device"
    [activationDisabled]="!canPreview"
    [isCounting]="teleprompterCounting$ | async"
    [previewDisabled]="
      !canPreview || previewPopupComponents.has(device.identity)
    "
    (previewClicked)="onPreviewButtonClicked(device.identity, true)"
    (teleprompterActiveToggle)="toggleTeleprompter($event)"
    (playClick)="onStartButtonClicked(device.identity)"
    (pauseClick)="onPauseButtonClicked(device.identity)"
    (stopClick)="onStopButtonClicked(device.identity)"
    (restartClick)="onRestartButtonClicked(device.identity)"
  ></openreel-teleprompter-participant>
</div>
