import { getLayerFromId, getTimelineById } from '../helpers';
import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { LayerOptions, VideoLayer } from './../interfaces/workflow.interfaces';
import { BaseCommand } from './base.command';

export class UpdateClipTrimsCommand extends BaseCommand<TimelineInterfaces.UpdateClipTrimsEvent> {
  run(event: TimelineInterfaces.UpdateClipTrimsEvent) {
    const { trimmerData } = event;

    trimmerData.clips.forEach((clip) => {
      this.sortLayersByVisibility();

      const { layer: layerToUpdate, timelineId, sectionId } = getLayerFromId(clip.layerId, this.source);
      const { timeline } = getTimelineById(timelineId, this.source.sections);

      const clipToUpdateAsset = this.getAsset((layerToUpdate as LayerOptions & VideoLayer).assetId);

      clipToUpdateAsset.trimFrom = clip.splits[0].startAt;
      clipToUpdateAsset.trimTo = clip.splits[0].endAt;

      const newDuration = clipToUpdateAsset.trimTo - clipToUpdateAsset.trimFrom;

      layerToUpdate.visibility.endAt = layerToUpdate.visibility.startAt + newDuration;

      this.recalculateVisibility(timeline);

      if (timeline.type === 'main') {
        this.removeLayersOutOfBounds(sectionId);
      }
    });

    return this.ok();
  }
}
