import {
  BrandKitAssets,
  BrandKitAssetsData,
  BrandKitFonts,
  ElementAssetFeature,
  Layer,
  FontAsset,
  FileAssetType,
  BRAND_KIT_LOGO_TAG_NAME,
  BRAND_KIT_WATERMARK_TAG_NAME,
  BRAND_KIT_BACKGROUND_VIDEO_TAG_NAME,
  AssetType,
} from '../interfaces';
import { BaseCommand } from './base.command';
import { v4 as uuidv4 } from 'uuid';
import { getConcreteFontAsset, getFontAssetWeight, getLayers } from '../helpers';

export interface ApplyBrandKitEvent {
  brandKit: BrandKitAssets;
}

export class ApplyBrandKitCommand extends BaseCommand<ApplyBrandKitEvent> {
  run({ brandKit }: ApplyBrandKitEvent) {
    // Apply assets
    this.applyAssets(brandKit.assets);

    // Apply font
    this.applyFonts(brandKit.fonts);

    // Apply colors
    brandKit.colors.data.forEach((brandKitColor) => {
      this.applyGlobalColor(brandKitColor.asset.value, brandKitColor.tag);

      if (brandKitColor.tag === 'primary') {
        this.updatePrimaryColor(brandKitColor.asset.value);
      }
    });

    return this.ok();
  }

  private applyAssets(brandKitAssets: BrandKitAssetsData) {
    const types: FileAssetType[] = ['logo', 'watermark', 'background-video'];
    const assetTypes: AssetType[] = ['image', 'image', 'clip'];
    const tags = [BRAND_KIT_LOGO_TAG_NAME, BRAND_KIT_WATERMARK_TAG_NAME, BRAND_KIT_BACKGROUND_VIDEO_TAG_NAME];

    types
      .map((type) => brandKitAssets.data.find((asset) => asset.type === type && asset.isDefault))
      .forEach((defaultAsset, index) => {
        if (!defaultAsset) {
          return;
        }

        const { asset, tag } = defaultAsset ?? {
          asset: { file: { path: null, provider: null }, type: assetTypes[index] },
          tag: tags[index],
          type: types[index],
        };

        // Update assets + remove it from all layers that use this asset
        const affectedAssets = this.source.assets.filter((a) => a.tags?.includes(tag));
        affectedAssets.forEach((affectedAsset) => {
          affectedAsset.file = asset?.file;
          affectedAsset.type = asset?.type;

          if (!asset?.file?.path) {
            for (const { layer } of getLayers(this.source)) {
              this.removeAssetFromLayer(layer, affectedAsset.id);
            }
          }
        });

        // Update wizard elements + remove it from all layers that are tied to that wizard element
        const affectedWizardElements = this.source.features.wizard.elements.filter(
          (e) => e.type === 'asset' && e.tags?.includes(tag)
        ) as ElementAssetFeature[];

        affectedWizardElements.forEach((t) => {
          t.asset.file = asset?.file;
          t.asset.type = asset?.type;

          if (!asset?.file?.path) {
            for (const { layer } of getLayers(this.source)) {
              if (!layer?.tags?.includes(tag)) {
                continue;
              }

              this.removeAssetFromLayer(layer);
            }
          }
        });
      });
  }

  private removeAssetFromLayer(layer: Layer, assetId?: string) {
    if (layer.type === 'image' || layer.type === 'video') {
      if (!assetId || layer.assetId === assetId) {
        layer.assetId = null;
        layer.enabled = false;
      }
    }

    if (layer.type === 'lottie') {
      for (const [, value] of Object.entries(layer.data)) {
        if (value.type !== 'logo') {
          continue;
        }

        if (!assetId || value.assetId === assetId) {
          value.assetId = null;
        }
      }
    }
  }

  private applyFonts(fonts: BrandKitFonts) {
    if (fonts.data.length === 0) {
      return;
    }

    this.source.fonts = [];

    fonts.data.forEach((brandKitFont) => {
      const [googleFont, customFont] = getConcreteFontAsset(brandKitFont.asset as FontAsset);
      this.source.fonts.push({
        id: uuidv4(),
        name: brandKitFont.asset.name,
        type: 'font',
        ...(customFont
          ? {
              custom: true,
              files: customFont.files,
              formats: customFont.formats,
            }
          : {
              file: googleFont.file,
              weight: googleFont.weight,
            }),
        family: brandKitFont.asset.family,
        weights: brandKitFont.asset.weights,
      });
    });

    this.source.styles.forEach((style) => {
      style.fontIndex = 0;
      style.fontWeight = +getFontAssetWeight(fonts.data[0].asset as FontAsset);
    });

    this.source.globalSettings.fontIndex = 0;
  }
}
