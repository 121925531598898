import { Component, Input } from '@angular/core';
import { LayerService, UiControl } from '../../services/layer.service';

import { FormGroup } from '@angular/forms';
import { Cleanupable } from '@openreel/common';
import { LayerFormPresetOptions } from '../layer-form-preset/layer-form-preset.component';

@Component({
  selector: 'openreel-layer-item-text',
  templateUrl: './layer-item-text.component.html',
  styleUrls: ['./layer-item-text.component.scss'],
})
export class LayerItemTextComponent extends Cleanupable {
  @Input() uiControl: UiControl;
  @Input() control: FormGroup;
  @Input() options: LayerFormPresetOptions;

  constructor(private readonly layerService: LayerService) {
    super();
  }

  // NOTE: removed validation errors until we figure out how to handle saving project with errors
  // this.layerService.getValidationErrors(this.control.errors || this.control.get('value').errors);
  validationErrors = () => [];
}
