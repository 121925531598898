import { CameraPresetCreate, DeviceInfo } from '../../interfaces';
import { ParticipantVideoProperties } from './openreel-participant';

export function getSettingsAsPreset(
  presetName: string,
  sessionId: number,
  videoProperties: ParticipantVideoProperties
): CameraPresetCreate {
  const v = videoProperties;
  const autoFocus = 1;
  const autoExposure = v.exposure === undefined ? true : false;
  const autoTemperature = v?.colorTemperature === undefined ? true : false;
  return {
    aspect_ratio: 'Ratio169',
    audio_sample_rate: '44.1',
    cSp_autoExposure_status: !autoExposure ? '1' : '0',
    cSp_autoFocus_status: autoFocus.toString() as '1',
    cSp_cameraPotrait_status: '0',
    cSp_exposure_value: !autoExposure ? v.exposure.toString() : '0',
    cSp_focus_value: '50',
    cSp_fps_value: v.fps.toString(),
    cSp_iso_value: !autoExposure ? v.iso.toString() : '0',
    cSp_title: presetName,
    cSp_whiteBalance_value: !autoTemperature
      ? v.colorTemperature.toString()
      : '0',
    color_overlay_color: '-1',
    color_overlay_value: '-1',
    flashlight: 0,
    focus_peaking: 0,
    highlight: '-1',
    is_autoexposure: autoExposure ? 1 : 0,
    is_autofocus: autoFocus,
    is_autoiso: autoExposure ? 1 : 0,
    is_autotemperature: autoTemperature ? 1 : 0,
    log_mode: -1,
    mbit: 12,
    resolution: v.resolution.toString(),
    saturation: '-1',
    session_id: sessionId,
    shadow: '-1',
    stabilize: '0',
    tint: '-1',
    vibrance: '-1',
    zoom: 0,
    cSp_contrast_value: v.contrast === undefined ? '-1' : v.contrast.toString(),
    is_autocontrast: v.contrast === undefined ? 1 : 0,
  };
}

export function getIosSupportedFPS(
  camera_name: 'front_cam' | 'back_cam',
  deviceSupport: DeviceInfo
): { value: string; label: string }[] {
  const fpsArr = deviceSupport[camera_name].fps_support.split(',');
  return fpsArr.map((item) => ({ value: item, label: item }));
}

export function getMappingFromIdentity(identity: string): number | undefined {
  if (identity && identity.startsWith('web_')) {
    const splits = identity.split('_');
    const lastPart = splits[splits.length - 1];
    return parseInt(lastPart, 10);
  }
}
