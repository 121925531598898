import { Component, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';

import { CuePlayerBaseComponent } from '../interfaces/cue-player-base.interface';
import { ImagePlayerData } from '../interfaces/player-data.interfaces';

@Component({
  selector: 'openreel-cue-player-image',
  templateUrl: './cue-player-image.component.html',
  styleUrls: ['./cue-player-image.component.scss'],
  providers: [
    {
      provide: CuePlayerBaseComponent,
      useExisting: forwardRef(() => CuePlayerImageComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerImageComponent extends CuePlayerBaseComponent {
  @Input() data: ImagePlayerData;

  onImageLoaded() {
    this.isLoaded = true;
    this.duration = 0;
    this.emitLoadedEvent();
  }

  onImageError(error: UIEvent) {
    this.emitErrorEvent(new Error(`Unable to load image: ${error.type}`));
  }

  play(): Promise<void> {
    return Promise.resolve();
  }

  pause() {
    // do nothing
  }

  stop() {
    // do nothing
  }

  currentTime(): number {
    return 0;
  }

  isPlaying(): boolean {
    return false;
  }
}
