import { selectProjectFonts } from '../selectors/project.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AssetsFacade {
  fonts$ = this.store.select(selectProjectFonts);

  constructor(private store: Store) {}
}
