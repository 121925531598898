import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { FontAsset } from '@openreel/creator/common';
import { catchError, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common/classes';
import { FontService } from 'libs/common/src/services/font/font.service';
import { FontDOMService } from 'libs/common/src/services/font/font-dom.service';
import { merge, noop } from 'lodash-es';
import { of } from 'rxjs';
import { FeatureFlaggingService, WORKFLOWS_ADDITIONAL_FONTS } from '../../../../common/src';

export interface FontPickerOptions {
  selectClasses?: string;
  searchClasses?: string;
  showCompanyFonts?: boolean;
  showSuggestedFonts?: boolean;
  showTrendingFonts?: boolean;
  showOtherFonts?: boolean;
  autoProvideCompanyFonts?: boolean;
  autoProvideTrendingFonts?: boolean;
  suggestedFontsCaption: string;
}

const DEFAULT_FONT_PICKER_OPTIONS: FontPickerOptions = {
  showCompanyFonts: true,
  showSuggestedFonts: true,
  showTrendingFonts: true,
  showOtherFonts: true,
  autoProvideCompanyFonts: true,
  autoProvideTrendingFonts: false,
  suggestedFontsCaption: '',
};

@Component({
  selector: 'openreel-font-picker',
  templateUrl: './openreel-font-picker.component.html',
  styleUrls: ['./openreel-font-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelFontPickerComponent,
    },
  ],
})
export class OpenreelFontPickerComponent extends Cleanupable implements ControlValueAccessor, OnInit, OnChanges {
  @Input() set suggestedFonts(value: FontAsset[]) {
    this.loadedSuggestedFonts = value;
  }
  get suggestedFonts() {
    return this.loadedSuggestedFonts;
  }

  @Input() set companyFonts(value: FontAsset[]) {
    this.providedCompanyFonts = value;
  }
  get companyFonts() {
    return this.options.autoProvideCompanyFonts ? this.loadedCompanyFonts : this.providedCompanyFonts;
  }

  @Input() set trendingFonts(value: FontAsset[]) {
    this.providedTrendingFonts = value;
  }
  get trendingFonts() {
    return this.options.autoProvideTrendingFonts ? this.loadedTrendingFonts : this.providedTrendingFonts;
  }

  @Input() set options(value: FontPickerOptions) {
    this._options = merge({}, DEFAULT_FONT_PICKER_OPTIONS, value);
    if (!this.featureFlagService.getFlag(WORKFLOWS_ADDITIONAL_FONTS)) {
      this._options.showCompanyFonts = false;
      this._options.showSuggestedFonts = false;
    }
  }
  get options() {
    return this._options;
  }

  @Input() set disabled(value) {
    this.setDisabledState(value);
  }

  @ViewChild(MatSelect) matSelect: MatSelect;

  private _options = DEFAULT_FONT_PICKER_OPTIONS;

  selectedFont: FontAsset;
  isDisabled = false;

  filterQueryControl: FormControl = new FormControl();

  loadedCompanyFonts: FontAsset[] = [];
  providedCompanyFonts: FontAsset[] = [];

  loadedSuggestedFonts: FontAsset[] = [];

  loadedTrendingFonts: FontAsset[] = [];
  providedTrendingFonts: FontAsset[] = [];

  filteredCompanyFonts: FontAsset[] = [];
  filteredSuggestedFonts: FontAsset[] = [];
  filteredTrendingFonts: FontAsset[] = [];
  filteredOtherFonts: FontAsset[] = [];

  isLoadingOtherFonts = false;

  propagateOnChanged: (font: FontAsset) => void = noop;
  propagateOnTouched = noop;

  get filterQuery(): string {
    return this.filterQueryControl.value?.toLowerCase();
  }

  private fontFilterPredicate = (font: FontAsset, query: string) =>
    !query || (font.name && font.name?.toLowerCase().indexOf(query) !== -1);

  constructor(
    private readonly fontService: FontService,
    private readonly featureFlagService: FeatureFlaggingService,
    private readonly fontDOMService: FontDOMService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.filterQueryControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap(() => {
          this.setCompanyFonts();
          this.setSuggestedFonts();
          this.setTrendingFonts();
          this.isLoadingOtherFonts = true;
        }),
        debounceTime(500),
        switchMap(() => this.loadOtherFonts()),
        catchError(() => of([]))
      )
      .subscribe((fontDtos) => {
        this.setOtherFonts(fontDtos);
        this.isLoadingOtherFonts = false;
        this.changeDetectorRef.markForCheck();
      });

    if (this.options.showSuggestedFonts) {
      this.loadSuggestedFonts();
    }

    if (this.options.showCompanyFonts && this.options.autoProvideCompanyFonts) {
      this.loadCompanyFonts();
    }

    if (this.options.showTrendingFonts && this.options.autoProvideTrendingFonts) {
      this.loadTrendingFonts();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('companyFonts' in changes && !this.options.autoProvideCompanyFonts) {
      this.setCompanyFonts();
    }

    if ('trendingFonts' in changes && !this.options.autoProvideTrendingFonts) {
      this.setTrendingFonts();
    }

    if ('suggestedFonts' in changes) {
      this.setSuggestedFonts();
    }
  }

  setCompanyFonts() {
    if (!this.options.showCompanyFonts) {
      return;
    }

    this.filteredCompanyFonts = this.companyFonts.filter((font) => this.fontFilterPredicate(font, this.filterQuery));

    this.fontDOMService.addFontsToDOM(this.filteredCompanyFonts);
  }

  loadCompanyFonts() {
    this.fontService
      .companyFonts()
      .pipe(map((fontDtos) => fontDtos.map((dto) => FontService.customFontToFontAsset(dto))))
      .subscribe((fonts) => {
        this.loadedCompanyFonts = fonts;
        this.setCompanyFonts();
        this.changeDetectorRef.markForCheck();
      });
  }

  setTrendingFonts() {
    if (!this.options.showTrendingFonts) {
      return;
    }

    this.filteredTrendingFonts = this.trendingFonts.filter((font) => this.fontFilterPredicate(font, this.filterQuery));

    this.fontDOMService.addFontsToDOM(this.filteredTrendingFonts);
  }

  loadTrendingFonts() {
    this.fontService
      .trendingFonts()
      .pipe(map((fontDtos) => fontDtos.map((dto) => FontService.fontToFontAsset(dto))))
      .subscribe((fonts) => {
        this.loadedTrendingFonts = fonts;
        this.setTrendingFonts();
        this.changeDetectorRef.markForCheck();
      });
  }

  setSuggestedFonts() {
    if (!this.options.showSuggestedFonts) {
      return;
    }

    this.filteredSuggestedFonts = this.suggestedFonts.filter((font) =>
      this.fontFilterPredicate(font, this.filterQuery)
    );

    this.fontDOMService.addFontsToDOM(this.filteredSuggestedFonts);
  }

  loadSuggestedFonts() {
    this.fontService
      .suggestedFonts()
      .pipe(map((fontDtos) => fontDtos.map((dto) => FontService.fontToFontAsset(dto))))
      .subscribe((fonts) => {
        this.loadedSuggestedFonts = fonts;
        this.setSuggestedFonts();
        this.changeDetectorRef.markForCheck();
      });
  }

  setOtherFonts(fonts: FontAsset[]) {
    this.filteredOtherFonts = fonts;

    this.fontDOMService.addFontsToDOM(this.filteredOtherFonts);
  }

  loadOtherFonts() {
    if (!this.options.showOtherFonts || !this.filterQuery) {
      return of([]);
    }

    return this.fontService.searchFont(this.filterQuery).pipe(
      take(1),
      map((fontDtos) => fontDtos.map((dto) => FontService.fontToFontAsset(dto)))
    );
  }

  setSelectedFont(font: FontAsset) {
    this.selectedFont = font;

    if (font) {
      this.fontDOMService.addFontsToDOM([font]);
    }
  }

  writeValue(font: FontAsset) {
    this.setSelectedFont(font);
  }

  registerOnChange(fn: (font: FontAsset) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  onFontChange(fontId: string) {
    const font = [...this.companyFonts, ...this.suggestedFonts, ...this.trendingFonts, ...this.filteredOtherFonts].find(
      (f) => f.id === fontId
    );

    if (!font) {
      throw new Error('Font not found.');
    }

    this.setSelectedFont(font);

    this.propagateOnChanged(font);
  }

  onSelectToggled(opened: boolean) {
    if (!opened) {
      this.propagateOnTouched();
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
