import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatar';
import { MaterialModule } from './material.module';
import { OpenreelCommonDirectivesModule } from 'libs/common/src/directives/openreel-common-directives.module';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { components } from '../components';
import { VideoEditorModule } from 'libs/common/src/components/video-editor/video-editor.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    AvatarModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    OpenreelCommonDirectivesModule,
    ColorPickerModule,
    NgxMatSelectSearchModule,
    VideoEditorModule,
  ],
  declarations: [...components],
  exports: [MaterialModule, ...components],
})
export class UiModule {}
