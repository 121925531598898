import { BaseCommand } from './base.command';
import { TimelineItem, timelineItemFactory } from '../interfaces/element.interfaces';
import { UpdateBoundsEvent } from '../interfaces/timelines.interfaces';
import { getLayerFromId, getTimelineById } from '../helpers/timelines.helpers';
import { Bounds } from './../interfaces/workflow.interfaces';
import { round } from 'lodash';

export interface UpdateBoundsResult {
  item: TimelineItem;
  timelineId: string;
}

export class UpdateBoundsCommand extends BaseCommand<UpdateBoundsEvent, UpdateBoundsResult> {
  run(event: UpdateBoundsEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer, childLayer, sectionId, timelineId } = layerInfo;

    const validationMessage = this.validateLayerBounds(
      event.bounds,
      (childLayer ?? layer).bounds,
      event.maintainAspectRatio
    );

    if (!validationMessage) {
      return { success: false, errorMessage: validationMessage as string };
    }

    const layerToChange = childLayer ?? layer;
    layerToChange.bounds = {
      ...layerToChange.bounds,
      ...event.bounds,
    };

    let result: UpdateBoundsResult = null;
    if (sectionId === 'main' && timelineId !== null) {
      const { timeline } = getTimelineById(timelineId, this.source.sections);
      result = {
        item: timelineItemFactory(layer, timeline.type, this.source),
        timelineId,
      };
    }

    return this.ok(result);
  }

  private validateLayerBounds(newBounds: Bounds, oldBounds: Bounds, maintainAspectRatio: boolean) {
    if (!isNaN(newBounds.width) && newBounds.width < 0) {
      return 'Width must be greater then 0.';
    }

    if (!isNaN(newBounds.height) && newBounds.height < 0) {
      return 'Height must be greater then 0.';
    }

    if (oldBounds.height && maintainAspectRatio) {
      const currentAspectRatio = oldBounds.width / oldBounds.height;
      const newAspectRatio = newBounds.width / newBounds.height;

      if (round(currentAspectRatio, 2) !== round(newAspectRatio, 2)) {
        return 'Aspect ratio cannot be changed.';
      }
    }

    return true;
  }
}
