import { DirectorSocketService } from '../../socket/director-socket.service';
import {
  TogglePinStatus,
  EVT_TOGGLE_PIN_STATUS,
} from '../../../interfaces/socket-events';
import { Injectable } from '@angular/core';

@Injectable()
export class SocketDirectorExtensionControlService {

  constructor(private socket: DirectorSocketService) { }

  sendTogglePinStatus(id: number, status: boolean, to: string) {
    const data: TogglePinStatus = {
      ovra_user_id: id?.toString(),
      status
    };
    this.socket.emitSocketTo(
      EVT_TOGGLE_PIN_STATUS,
      data,
      to
    )
  }
}
