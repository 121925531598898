import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Cleanupable } from '@openreel/common';
import { CueSelectionService, SelectedActions, SelectedLayer } from '../services/cue-selection.service';
import { filter, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CueEventsService } from '../services/cue-events.service';

interface ToolbarAction {
  icon: string;
  tooltip: string;
  run: () => void;
}

@Component({
  selector: 'openreel-cue-selection-toolbar',
  templateUrl: './cue-selection-toolbar.component.html',
  styleUrls: ['./cue-selection-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CueSelectionToolbarComponent extends Cleanupable implements OnInit, OnDestroy {
  @Output() switchMainVideos = new EventEmitter();

  left: number;
  top: number;

  actions: ToolbarAction[] = [];

  isSelectionMoving: boolean;

  private selection: SelectedLayer;

  private globalPlayerElement: Element;
  private playerResizeObserver: ResizeObserver;
  private selectedPlayerElement?: Element;

  constructor(
    private readonly cueSelectionService: CueSelectionService,
    private readonly cueEventsService: CueEventsService,
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([this.cueSelectionService.selected$, this.cueSelectionService.actions$])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(([selection]) => !!selection)
      )
      .subscribe(([selection, actions]) => {
        this.selection = selection;

        this.isSelectionMoving = false;
        this.selectedPlayerElement = selection.playerElement;
        this.recalcToolbarPosition();

        setTimeout(() => {
          this.actions = this.buildToolbarActions(actions);
          this.cdr.markForCheck();
        });
      });

    this.cueSelectionService.moving$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isMoving) => {
      this.isSelectionMoving = isMoving;
      this.cdr.markForCheck();
    });

    // Toolbar position
    this.globalPlayerElement = this.elementRef.nativeElement.parentNode.childNodes[0];

    this.playerResizeObserver = new ResizeObserver(() => this.recalcToolbarPosition());
    this.playerResizeObserver.observe(this.globalPlayerElement);
  }

  ngOnDestroy() {
    this.playerResizeObserver?.unobserve(this.globalPlayerElement);
  }

  private recalcToolbarPosition() {
    if (!this.selectedPlayerElement || !this.globalPlayerElement) {
      return;
    }

    const TOP_OFFSET = 12;

    const globalRect = this.globalPlayerElement.getBoundingClientRect();
    const playerRect = this.selectedPlayerElement.getBoundingClientRect();

    const visibleWidth = Math.min(
      globalRect.right - playerRect.left,
      playerRect.right - globalRect.left,
      playerRect.width
    );
    const visibleLeft = Math.max(playerRect.left - globalRect.left, 0);
    this.left = visibleLeft + visibleWidth / 2;

    this.top =
      playerRect.top - globalRect.top + Math.min(globalRect.bottom - playerRect.top, playerRect.height) + TOP_OFFSET;

    this.cdr.markForCheck();
  }

  private buildToolbarActions(selectedActions: SelectedActions[]) {
    const actions: ToolbarAction[] = [];

    for (const selectedAction of selectedActions) {
      switch (selectedAction) {
        case SelectedActions.ResetBounds:
          actions.push({
            icon: 'reset',
            tooltip: 'Reset to default position',
            run: () =>
              this.cueEventsService.setPlayerEvent({
                type: 'reset-bounds',
                externalId: this.selection.externalId,
                defaultBounds: this.selection.defaultLayout,
              }),
          });
          break;
        case SelectedActions.SwitchMainVideos:
          actions.push({
            icon: 'swap-horizontal',
            tooltip: 'Switch main videos',
            run: () => {
              this.switchMainVideos.emit();
              return this.cueEventsService.setPlayerEvent({ type: 'switch-main-videos' });
            },
          });
          break;
        case SelectedActions.ContainContent:
          actions.push({
            icon: 'contain',
            tooltip: 'Contain video',
            run: () =>
              this.cueEventsService.setPlayerEvent({
                type: 'toggle-layer-fit',
                externalId: this.selection.externalId,
                objectFit: 'contain',
              }),
          });

          break;
        case SelectedActions.FillContent:
          actions.push({
            icon: 'fill',
            tooltip: 'Fill video',
            run: () =>
              this.cueEventsService.setPlayerEvent({
                type: 'toggle-layer-fit',
                externalId: this.selection.externalId,
                objectFit: 'cover',
              }),
          });
          break;
      }
    }

    return actions;
  }
}
