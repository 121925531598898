import * as fromActions from '../actions/timelines.actions';
import * as fromProjectActions from '@openreel/creator/app/store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { StretchTextCommand } from '@openreel/creator/common';
import { ProjectFacade } from '@openreel/creator/app/store/facades/project.facade';

@Injectable()
export class TextOverlaysEffects {
  stretchTextOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.stretchTextOverlay),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const { updatedWorkflow } = new StretchTextCommand(workflow).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
          }),
        ];
      })
    )
  );

  constructor(private readonly actions$: Actions, private readonly projectFacade: ProjectFacade) {}
}
