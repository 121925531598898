import { createAction, props } from '@ngrx/store';

const actionType = '[Captions]';

export const toggleCaptionsBurnIn = createAction(`${actionType} Toggle Captions Burn in`, props<{ burnIn: boolean }>());

export const toggleCaptionsUpToDate = createAction(
  `${actionType} Toggle Captions Up to date`,
  props<{ upToDate: boolean }>()
);

export const transcribe = createAction(`${actionType} Transcribe`);

export const cancelTranscribe = createAction(`${actionType} Cancel Transcribe`);
