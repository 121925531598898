import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
   selector: '[orDragStart]',
})
export class DragStartDirective {
    @Input()
    dragData: unknown 

    @Input()
    disabled: boolean;

    element:HTMLElement;
    ghostElement:HTMLElement;

    constructor(private eRef: ElementRef<HTMLElement>, 
        private platform: Platform) {
        this.element = eRef.nativeElement;
    }

    @HostListener('dragstart', ['$event'])
    dragEnter(event:DragEvent) {

        let scale = window.devicePixelRatio;
        const rect = this.element.getBoundingClientRect();

        if(this.disabled){
            return false;
        }

        // force scale to 1 for Firefox and Safari
        if(this.platform.SAFARI || this.platform.FIREFOX){
            scale = 1;
        }

        if (this.dragData){
            event.dataTransfer.setData('text', this.dragData.toString());
        }
        const x = (event.clientX - rect.left)*scale;
        const y = (event.clientY - rect.top)*scale;

        // add ghost element
        // and style it
        this.ghostElement = document.createElement('div');
        this.ghostElement.style.position = 'absolute';
        this.ghostElement.style.top = '-1000px';

        const nodeCopy = this.element.cloneNode(true) as HTMLElement;

        nodeCopy.style.opacity = '0.5';
        nodeCopy.style.width = this.element.offsetWidth.toString() + 'px';
        nodeCopy.style.height = this.element.offsetHeight.toString() + 'px';

        // border
        this.ghostElement.style.border = 'solid';
        this.ghostElement.style.borderColor = 'blue';
        this.ghostElement.style.borderRadius = '12px';

        this.ghostElement.appendChild(nodeCopy);

        document.body.appendChild(this.ghostElement);
        event.dataTransfer.setDragImage(this.ghostElement, x, y);

        this.dragged = true;        
    }

    @HostListener('dragend')
    dragLeave() {
        if(this.ghostElement){
            document.body.removeChild(this.ghostElement); 
        }
        this.dragged = false;
    }

    @HostBinding('draggable') 
    draggable = true;

    @HostBinding('class.dragged')
    dragged = false;
} 