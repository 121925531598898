import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_9 } from '../transformation_0_9/schema_after';
import { WorkflowDataDto_1_0 } from './schema_after';

export const SchemaTransformation_1_0: SchemaTransformation = {
  version: '1.0',

  run(
    oldSchema: WorkflowDataDto_0_9.WorkflowDataDto,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { template }: { template: any }
  ) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_1_0.WorkflowDataDto;
    if (newSchema.features.layouts) {
      return newSchema;
    }

    newSchema.features.layouts = template.features.layouts;
    return newSchema;
  },
};
