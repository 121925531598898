import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
} from '@angular/core';

@Directive({
  selector: '[autoResize]',
})
export class AutoresizeDirective implements OnInit {
  @Input()
  maxHeight = 100;

  private initialHeight;

  constructor(private element: ElementRef) {}

  @HostListener(':keyup', ['$event'])
  onKeyUp(event: Event) {
    this.handlePropagation(event);
  }

  @HostListener(':keydown', ['$event'])
  onKeyDown(event: Event) {
    this.handlePropagation(event);
  }

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  @HostListener(':keydown.enter', ['$event'])
  onEnter(event: Event) {
    event.preventDefault();
    this.resetHeight();
  }

  ngOnInit() {
    this.initialHeight = this.element.nativeElement.style.height;
    this.element.nativeElement.style.overflow = 'auto';
    this.resize();
  }

  resetHeight() {
    this.element.nativeElement.style.height = this.initialHeight;
  }

  resize(): void {
    const textArea = this.element.nativeElement;
    textArea.style.height = 'auto';
    const newHeight = this.maxHeight
      ? Math.min(textArea.scrollHeight, this.maxHeight)
      : textArea.scrollHeight;
    textArea.style.height = newHeight + 'px';
  }

  handlePropagation(event: Event) {
    event.stopPropagation();
  }
}
