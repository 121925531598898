import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';
import { round } from 'lodash';
import { getLayerFromId } from '../helpers/timelines.helpers';

export class MoveItemsCommand extends BaseCommand<TimelineInterfaces.ItemsMovedEvent> {
  run(event: TimelineInterfaces.ItemsMovedEvent) {
    event.items.forEach(({ layerId, newStartAt }) => {
      const { layer } = getLayerFromId(layerId, this.source);
      if (!layer) {
        console.error('Cannot find layer to update.');
        return;
      }

      const layerDuration = layer.visibility.endAt - layer.visibility.startAt;

      layer.visibility = {
        ...layer.visibility,
        startAt: round(newStartAt),
        endAt: round(newStartAt) + layerDuration,
      };
    });

    this.sortLayersByVisibility();

    return this.ok();
  }
}
