import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { LayerOptions, LottieLayer } from './../interfaces/workflow.interfaces';
import { BaseCommand } from './base.command';
import { TimelineItem, timelineItemFactory } from '../interfaces/element.interfaces';
import { getLayerFromId } from '../helpers/timelines.helpers';

export class StretchTextCommand extends BaseCommand<TimelineInterfaces.StretchTextOverlayEvent, TimelineItem> {
  run(event: TimelineInterfaces.StretchTextOverlayEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    const layerToUpdate = layerInfo.layer as LottieLayer & LayerOptions;

    layerToUpdate.visibility = {
      ...layerToUpdate.visibility,
      startAt: event.newStartAt,
      endAt: event.newEndAt,
    };

    return this.ok(timelineItemFactory(layerToUpdate, 'overlays', this.source));
  }
}
