import { Directive, OnInit, OnDestroy, HostBinding, Output, EventEmitter } from '@angular/core';
import { ImageExtractorService } from './image-extractor.service';
import { HostingPlayerComponent } from '../player/player.component';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'openreel-hosting-player[orImageExtractor]'
})
export class ImageExtractorDirective implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    private lastTs;

    @HostBinding('class.extract-mode')
    extractMode = false;

    @Output()
    extractModeToggle = new EventEmitter<boolean>();

    constructor(
        private imageExtractorService: ImageExtractorService,
        private player: HostingPlayerComponent,
    ) {}

    ngOnInit() {
        this.subs.push(
            this.imageExtractorService.extractImage$.subscribe(
                () => this.extractImage(),
            ),
            this.imageExtractorService.toggleExtraceMode$.subscribe(
                (on) => {
                    console.warn('IMG EXTRACT MODE NOT FULLY IMPLEMENTED');
                    if (on && !this.extractMode) {
                        this.player.player.play();
                        this.player.player.pause();
                        this.extractMode = true;
                    } else if (!on && this.extractMode) {
                        this.player.player.initChildren();
                        this.extractMode = false;
                    }
                    this.extractModeToggle.emit(this.extractMode);
                }
            )
        );
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }

    extractImage() {
        const video = this.player.videoEl.nativeElement;
        const ts = video.currentTime;
        if (ts !== this.lastTs) {
            this.lastTs = ts;
            let canvas: HTMLCanvasElement = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(blob => {
                this.imageExtractorService.extractedImage(blob, ts);
                canvas = null;
            });
        }
    }
}