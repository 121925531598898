/* eslint-disable @typescript-eslint/no-explicit-any */
import { CanActivate, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { ROUTE_UNSUPPORTED_BROWSER } from '../route-utils';
import { isBrowserSupported } from '../utils';

@Injectable()
export class SupportedBrowserGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (isBrowserSupported()) {
      return true;
    } else {
      this.router.navigate([ROUTE_UNSUPPORTED_BROWSER]);
      return false;
    }
  }
}
