import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AssetsBaseService } from '../services/assets/assets-base.service';

@Directive({
  selector: '[openreelAssetVideo]',
})
export class AssetVideoDirective implements OnChanges {
  @Input('openreelAssetVideo') assetId: number;
  @Input() assetVideoProvider?: 'or-assets' | 'or-recordings';

  constructor(
    private readonly elementRef: ElementRef,
    private assetService: AssetsBaseService
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (!('assetId' in changes)) {
      return;
    }

    if (!this.assetId) {
      this.elementRef.nativeElement.src = '';
    } else {
      const provider = this.assetVideoProvider ?? 'or-assets';

      const assetUrl = await this.assetService
        .getAssetUrlById(provider, this.assetId)
        .toPromise();
      this.elementRef.nativeElement.src = assetUrl;
    }
  }
}
