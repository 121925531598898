import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
} from '@angular/core';

import { CueEventsService } from '../services/cue-events.service';
import { CuePlayerBaseComponent, PlayerDimensions } from '../interfaces/cue-player-base.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoPlayerData } from '../interfaces/player-data.interfaces';
import { takeUntil } from 'rxjs/operators';
import { LayerStyles } from '@openreel/creator/common';
import { round } from 'lodash';

const TIME_UPDATE_MAX_INTERVAL = 250;
const SIXTY_FRAMES_PER_SECOND = 1000 / 60;

@Component({
  selector: 'openreel-cue-player-video',
  templateUrl: './cue-player-video.component.html',
  styleUrls: ['./cue-player-video.component.scss'],
  providers: [
    {
      provide: CuePlayerBaseComponent,
      useExisting: forwardRef(() => CuePlayerVideoComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuePlayerVideoComponent extends CuePlayerBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('video') private readonly video: ElementRef<HTMLVideoElement>;

  protected set nativeVideoCurrentTime(time: number) {
    this.video.nativeElement.currentTime = time / 1000;
  }

  protected get nativeVideoCurrentTime(): number {
    return this.video.nativeElement.currentTime * 1000;
  }

  protected get nativeVideoDuration(): number {
    return this.video.nativeElement.duration * 1000;
  }

  protected get startAt(): number {
    return this.data.startAt || 0;
  }

  protected get endAt(): number | undefined {
    return this.data.endAt;
  }

  get dimensions(): PlayerDimensions {
    const dimensions: PlayerDimensions = {
      elementWidth: round(this.video.nativeElement.getBoundingClientRect().width, 2),
      elementHeight: round(this.video.nativeElement.getBoundingClientRect().height, 2),
      intrinsicWidth: this.video.nativeElement.videoWidth,
      intrinsicHeight: this.video.nativeElement.videoHeight,
      renderedHeight: null,
      renderedWidth: null,
      ratio: null,
    };

    const zoomRatio = dimensions.intrinsicHeight / dimensions.elementHeight;
    dimensions.renderedHeight = round(dimensions.intrinsicHeight / zoomRatio, 2);
    dimensions.renderedWidth = round(dimensions.intrinsicWidth / zoomRatio, 2);
    dimensions.ratio = zoomRatio;

    if (dimensions.renderedWidth < dimensions.elementWidth) {
      const ratio = dimensions.elementWidth / dimensions.renderedWidth;
      dimensions.renderedHeight = round(dimensions.renderedHeight * ratio, 2);
      dimensions.renderedWidth = dimensions.elementWidth;
    } else if (dimensions.renderedHeight < dimensions.elementHeight) {
      const ratio = dimensions.elementHeight / dimensions.renderedHeight;
      dimensions.renderedWidth = round(dimensions.renderedWidth * ratio, 2);
      dimensions.renderedHeight = dimensions.elementHeight;
    }

    return dimensions;
  }

  @Input() data: VideoPlayerData;
  @Input() styles: LayerStyles;

  private interval = null;
  private endedEventTimeout = null;

  constructor(public readonly sanitizer: DomSanitizer, private readonly cueEventsService: CueEventsService) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.video.nativeElement.muted = !this.data.hasAudio;

    this.cueEventsService.volume$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((volume) => (this.video.nativeElement.volume = volume));
  }

  ngOnDestroy() {
    this.stopTimeUpdateInterval();
    super.ngOnDestroy();
  }

  onVideoCanPlayThrough() {
    if (!this.isLoaded) {
      const endAt = this.endAt ? this.endAt : this.nativeVideoDuration;
      this.isLoaded = true;
      this.duration = endAt - this.startAt;
      this.nativeVideoCurrentTime = this.startAt;

      this.emitLoadedEvent();
    }
  }

  onVideoTimeUpdate() {
    const currentTime = this.currentTime();
    const offset = currentTime - this.duration;

    if (this.data.loop) {
      this.emitTimeUpdateEvent(currentTime);
      return;
    }

    if (offset >= 0 && this.isPlaying()) {
      this.pause();
      this.emitEndedEvent();
      return;
    } else if (this.endAt && offset > -TIME_UPDATE_MAX_INTERVAL) {
      // NOTE: we might no longer need this, we are not using video element timeUpdate anymore.
      // We are using setInterval at 60fps to poll video element for current time

      // Hack to overcome timeUpdate's lack of granularity
      if (this.endedEventTimeout) {
        clearInterval(this.endedEventTimeout);
      }
      this.endedEventTimeout = setTimeout(() => {
        if (this.isPlaying()) {
          this.pause();
          this.emitEndedEvent();
        }
      }, Math.abs(offset));
    }

    this.emitTimeUpdateEvent(currentTime);
  }

  onVideoEnded() {
    this.emitEndedEvent();
  }

  onVideoSeeking() {
    this.emitSeekingEvent();
  }

  onVideoSeeked() {
    this.emitSeekedEvent();
  }

  onVideoError() {
    this.emitErrorEvent(new Error(this.video.nativeElement.error.message));
  }

  currentTime(time?: number): number {
    const currentTime = this.nativeVideoCurrentTime - this.startAt;

    if (time || time === 0) {
      const timeToSet = time + this.startAt;
      if (timeToSet !== currentTime) {
        this.nativeVideoCurrentTime = timeToSet;
        this.emitTimeUpdateEvent(time);
      }
    }

    return currentTime >= 0 ? currentTime : 0;
  }

  isPlaying(): boolean {
    return !this.video.nativeElement.paused;
  }

  async play() {
    if (!this.isPlaying()) {
      await this.video.nativeElement.play();
      this.startTimeUpdateInterval();
    }
  }

  pause() {
    this.video.nativeElement.pause();
    this.stopTimeUpdateInterval();
  }

  stop() {
    if (this.isPlaying()) {
      this.pause();
    }

    this.stopTimeUpdateInterval();

    if (this.nativeVideoCurrentTime > this.startAt) {
      this.nativeVideoCurrentTime = this.startAt;
    }
  }

  private startTimeUpdateInterval() {
    this.interval = setInterval(() => {
      this.onVideoTimeUpdate();
    }, SIXTY_FRAMES_PER_SECOND);
  }

  private stopTimeUpdateInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  calculateObjectPosition() {
    if (this.styles?.objectPosition) {
      return `${this.styles?.objectPosition[0]}% ${this.styles?.objectPosition[1]}%`;
    }

    return null;
  }
}
