import { BaseCommand } from './base.command';
import {
  AssetsFileProviderType,
  LayoutDto,
  LayoutType,
  NewClip,
  Timeline,
  VideoLayer,
  WorkflowDataDto,
} from '../interfaces';
import { getChangesEventFromLayers, getSectionTimelines, getTimelineById } from '../helpers';

export interface UpdateLayoutEvent {
  sectionId: string;
  project: WorkflowDataDto;
  templateLayoutId: number;
  layoutType: LayoutType;
  layoutData: LayoutDto;
}

export class UpdateSectionLayoutCommand extends BaseCommand<UpdateLayoutEvent> {
  run({ sectionId, project, templateLayoutId, layoutType, layoutData }: UpdateLayoutEvent) {
    this.applyLayout(sectionId, templateLayoutId, layoutType, layoutData, true);
    this.randomizeSectionIds(sectionId);

    // Apply watermark if present on old project
    this.applyWatermark(project);

    // Copy main clips from old project
    // If same number of timelines: copy as-is
    // If new project contains less timelines then old project, move missing timeline clips into new last timeline
    // If new project contains more timelines then old project, duplicate clips
    this.applyMainClips(project, sectionId);

    // Copy text overlays from old project
    // If same number of timelines: copy as-is
    // If new project contains less timelines then old project, copy text overlays for existing timelines
    // If new project contains more timelines then old project, create new text overlays and fill with old text
    this.applyTextOverlays(project, sectionId);

    return this.ok();
  }

  private applyWatermark(project: WorkflowDataDto) {
    if (!project.globalSettings.watermarkLayer?.enabled) {
      return;
    }

    this.addWatermark();
  }

  private applyMainClips(project: WorkflowDataDto, sectionId: string) {
    const oldTimelines = getSectionTimelines(project.sections, 'main', 'main', sectionId)[0].timelines;
    const newTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId)[0].timelines;

    const timelinesMap = new Map<string, Timeline[]>();
    if (newTimelines.length <= oldTimelines.length) {
      newTimelines.forEach(({ id }, index) => timelinesMap.set(id, [oldTimelines[index]]));
    }

    if (newTimelines.length > oldTimelines.length) {
      newTimelines.forEach(({ id }, index) => {
        const oldTimelineIndex = index < oldTimelines.length ? index : oldTimelines.length - 1;
        return timelinesMap.set(id, [oldTimelines[oldTimelineIndex]]);
      });
    }

    for (const [newTimelineId, oldMappedTimelines] of timelinesMap.entries()) {
      const { timeline: newTimeline } = getTimelineById(newTimelineId, this.source.sections);

      oldMappedTimelines.forEach((oldMappedTimeline) => {
        this.insertClips(
          newTimeline,
          oldMappedTimeline.layers.map((oldMappedLayer: VideoLayer): NewClip => {
            const asset = project.assets.find((a) => a.id === oldMappedLayer.assetId);
            return {
              source: asset.data.source,
              assetId: asset.id,
              assetFileId: asset.file.path,
              assetProviderType: asset.file.provider as AssetsFileProviderType,
              duration: asset.data.duration,
              name: asset.data.name,
              trimFrom: asset.trimFrom,
              trimTo: asset.trimTo,
              isPlaceholder: asset.isPlaceholder,
            };
          }),
          newTimeline.layers.length
        );
      });

      this.recalculateVisibility(newTimeline);
    }

    const assetIds = this.getTimelinesAssetIds(oldTimelines);
    this.removeAssets(assetIds);
  }

  private applyTextOverlays(project: WorkflowDataDto, sectionId: string) {
    const newTextOverlayTimelines = getSectionTimelines(this.source.sections, 'main', 'overlays', sectionId)[0]
      .timelines;
    const oldTextOverlayTimelines = getSectionTimelines(project.sections, 'main', 'overlays', sectionId)[0].timelines;

    if (newTextOverlayTimelines.length === 0) {
      return;
    }

    const newTextOverlayLayers = newTextOverlayTimelines.map((timeline) => this.addTextOverlay(timeline, 0));
    const oldTextOverlayLayers = oldTextOverlayTimelines.map((timeline) =>
      timeline.layers.length > 0 ? timeline.layers[0] : null
    );

    if (oldTextOverlayLayers.length > 0) {
      newTextOverlayLayers.forEach((newLayer, index) => {
        const oldLayerIndex = index < oldTextOverlayLayers.length ? index : 0;
        const oldLayerId = oldTextOverlayLayers[oldLayerIndex].layerId;

        newLayer.enabled = oldTextOverlayLayers[oldLayerIndex].enabled;

        const oldTextOverlayChanges = getChangesEventFromLayers([oldLayerId], project)[oldLayerId];
        this.applyLayerChanges(newLayer, oldTextOverlayChanges);
      });
    }
  }
}
