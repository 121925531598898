<div class="flex-col flex-align-center">
  <div class="player-wrapper">
    <div class="flex-col flex-align-center">
      <div class="previewer-container">
        <openreel-wf-trimmer-preview
          [data]="data"
          [loadedProjectWorkflow]="loadedProjectWorkflow"
          (loaded)="onPreviewLoaded()"
        ></openreel-wf-trimmer-preview>
      </div>
      <ng-container *ngIf="isPreviewLoaded">
        <div class="trimmer-container">
          <div class="splits">
            <openreel-wf-trimmer-split
              *ngFor="
                let split of [].constructor(data.splitCount);
                index as i;
                count as cnt
              "
              [data]="data"
              [allowDelete]="parsedOptions.allowSplits && cnt > 1"
              [trimmers]="trimData[i]"
              [loadedProjectWorkflow]="loadedProjectWorkflow"
              (trim)="onTrim(i, $event)"
              (remove)="onRemoveSplit(i)"
            ></openreel-wf-trimmer-split>
          </div>
          <button
            *ngIf="parsedOptions.allowSplits"
            data-cy="add-split-button"
            mat-button
            type="button"
            class="add-split mat-button-flex-8 l-height-24 px-4"
            (click)="onAddSplit()">
            <span class="f-light-12">
              Add Split
            </span>
            <mat-icon class="mat-icon-14">
              add_circle
            </mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div>
    <button
      *ngIf="parsedOptions.showTrimButton"
      mat-flat-button
      type="button"
      [attr.data-cy]="parsedOptions.trimButtonCaption"
      class="next-step m-top-20"
      color="primary"
      [disabled]="!isPreviewLoaded || loading"
      (click)="save()"
    >
      {{ parsedOptions.trimButtonCaption }}
    </button>
    <button
      *ngIf="parsedOptions.showCancelButton"
      mat-flat-button
      type="button"
      [attr.data-cy]="parsedOptions.cancelButtonCaption"
      class="next-step m-top-20"
      (click)="onCancel()"
    >
      {{ parsedOptions.cancelButtonCaption }}
    </button>
  </div>
</div>
