import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';
import { getTimelineById } from '../helpers/timelines.helpers';
import { cloneDeep } from 'lodash';

export class SwapMainVideosCommand extends BaseCommand<TimelineInterfaces.SwapTimelinesEvent> {
  run(event: TimelineInterfaces.SwapTimelinesEvent) {
    const { previousTimelineId, currentTimelineId } = event;

    const { timeline: previousTimeline } = getTimelineById(previousTimelineId, this.source.sections);
    const { timeline: currentTimeline } = getTimelineById(currentTimelineId, this.source.sections);

    if (!previousTimeline || !currentTimeline) {
      return this.error('Can not find timeline to update.');
    }

    const previousTimelineLayers = cloneDeep(previousTimeline.layers);
    const currentTimelineLayers = cloneDeep(currentTimeline.layers);

    previousTimelineLayers.forEach((layer) => {
      [layer.bounds, layer.styles] = this.getTimelineBoundsAndStyles(currentTimeline);
    });
    currentTimelineLayers.forEach((layer) => {
      [layer.bounds, layer.styles] = this.getTimelineBoundsAndStyles(previousTimeline);
    });

    previousTimeline.layers = [...currentTimelineLayers];
    currentTimeline.layers = [...previousTimelineLayers];

    this.recalculateVisibility(previousTimeline);
    this.recalculateVisibility(currentTimeline);

    return this.ok();
  }
}
