import * as fromActions from '../actions/self-record-teleprompter.actions';
import * as fromSelectors from '../selectors/self-record-teleprompter.selectors';

import { Injectable } from '@angular/core';
import { SelfRecordTeleprompterStatus } from '../interfaces/self-record-teleprompter.interfaces';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SelfRecordTeleprompterFacade {
  scripts$ = this.store.select(fromSelectors.selectScripts);
  status$ = this.store.select(fromSelectors.selectStatus);

  constructor(private store: Store) {}

  cleanup() {
    this.store.dispatch(fromActions.cleanup());
  }

  refreshScripts() {
    this.store.dispatch(fromActions.refreshScripts());
  }

  updateStatus(status: SelfRecordTeleprompterStatus) {
    this.store.dispatch(fromActions.updateStatus({ status }));
  }

  openTeleprompterDialog() {
    this.store.dispatch(fromActions.openTeleprompterDialog());
  }
}
