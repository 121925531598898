import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'or-inline-editable',
    templateUrl: './inline-editable.component.html',
    styleUrls: ['./inline-editable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditableComponent {
    private _value = '';
    @Input() 
    set value(val: string) {
        this.editing = false;
        this._value = val;
        this.valueCtrl.setValue(val);
    }
    get value() {
        return this._value;
    }

    @Input() placeholder = '';

    private _disabledEdit = false;
    @Input()
    set disableEdit(disable: boolean) {
        this._disabledEdit = disable;
        if (this.disableEdit) {
            this.editing = false;
        }
    }
    get disableEdit() {
        return this._disabledEdit;
    }

    private _required = false;
    @Input()
    set required(r: boolean) {
        this._required = r;
        this.setValidators();
    }
    get required() {
        return this._required
    }
    private _maxLength?: number;
    @Input()
    set maxLength(max: number) {
        this._maxLength = max;
        this.setValidators();
    }
    get maxLength() {
        return this._maxLength
    }

    @Output() valueChanged = new EventEmitter<string>();

    valueCtrl = new FormControl(this.value);

    editing = false;

    private setValidators() {
        const validators = [];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (this.maxLength) {
            validators.push(Validators.maxLength(this.maxLength));
        }
        this.valueCtrl.setValidators(validators);
    }

    toggleEdit() {
        this.editing = !this.editing;
    }

    saveValue() {
        if (this.valueCtrl.valid) {
            this.toggleEdit();
            if (this.value !== this.valueCtrl.value) {
                this._value = this.valueCtrl.value;
                this.valueChanged.emit(this.value);
            }
        }
    }

    cancelEdit() {
        this.toggleEdit();
        this.valueCtrl.setValue(this.value);
    }

}