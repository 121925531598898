import * as fromActions from '../actions/timelines.actions';
import { createReducer, on } from '@ngrx/store';
import { TrimmerAction } from '@openreel/creator/common';

export const key = 'timelines';

export interface TimelinesState {
  sentToTrimmer?: SentToTrimmerData;
}

export interface SentToTrimmerData {
  trimmmerAction: TrimmerAction;
  timelineId: string;
  layerId: string;
}

export const initialState: TimelinesState = {};

const reducer = createReducer(
  initialState,
  // Trimmer interaction
  on(fromActions.setTrimmerData, (state, { event: { trimmmerAction, timelineId, layerId } }) => ({
    ...state,
    hardReload: false,
    sentToTrimmer: {
      trimmmerAction,
      timelineId,
      layerId,
    },
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
