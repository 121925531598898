<openreel-wf-timelines
  class="flex-auto"
  [timelines$]="timelines$"
  [durations$]="durations$"
  [workflow$]="workflow$"
  [trimmers]="trimmers"
  [canvasCreateOptions]="{
    allowItemInteraction: false,
    showTrimmers: true,
    showTimeCursor: false
  }"
  (updateTrimmer)="onTrim($event)"
></openreel-wf-timelines>
<button
  *ngIf="allowDelete"
  data-cy="remove-split-button"
  mat-icon-button
  type="button"
  class="mat-btn-14 remove-btn"
  (click)="onRemove()"
>
  <mat-icon class="mat-icon-14">remove_circle</mat-icon>
</button>
