import {
  Component,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'openreel-grid-list-toogle',
  templateUrl: './orgridlist.component.html',
  styleUrls: ['./orgridlist.component.css'],
})
export class OrGridListToogleComponent{
  @Input() viewtype = 'grid';
  @Output() viewtypeChange = new EventEmitter<string>();

  changeView(type){
    this.viewtype = type;
    this.viewtypeChange.emit(this.viewtype);
  }
}
