import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { LoginViaTokenResp } from '../../interfaces';
import { CompanySsoInfo, CreateSsoSetupData, SsoSetupData } from '../../interfaces/sso.interface';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  public urls = {
    setUp: `${commonenv.nextGenApiUrl}sso/set-up`,
    checkToken: `${commonenv.nextGenApiUrl}login/token`,
    getSsoInfo: (slug: string) => `${commonenv.nextGenApiUrl}sso/info/${slug}`,
    getCallbackUrl: (slug: string) => `${commonenv.nextGenApiUrl}sso/return/${slug}`,
    getLoginUrl: (slug: string) => `${commonenv.nextGenApiUrl}sso/login/${slug}`,
  };

  constructor(public httpClient: HttpClient) {}

  getSsoInfo(slug: string): Promise<CompanySsoInfo> {
    return this.httpClient.get<CompanySsoInfo>(this.urls.getSsoInfo(slug)).toPromise();
  }
  getSsoSetup() {
    return this.httpClient.get<SsoSetupData>(this.urls.setUp).toPromise();
  }
  createSsoSetup(data: CreateSsoSetupData) {
    return this.httpClient.post(this.urls.setUp, data).toPromise();
  }
  checkSsoToken(token: string) {
    return this.httpClient.post<LoginViaTokenResp>(this.urls.checkToken, { token: token }).toPromise();
  }
  getFormattedSlug(slug: string) {
    return (
      slug
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
    );
  }
}
