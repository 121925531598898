import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { GdprDeviceLogsYearWise } from '../../interfaces/gdpr.interface';


@Injectable({
  providedIn: 'root',
})
export class GdprLogsService {
  gdprStorageName = 'gdpr-pass';
  public urls = {
    verify: `${commonenv.nextGenApiUrl}gdpr/verify`,
    deviceLogs: `${commonenv.nextGenApiUrl}gdpr/device-logs`,
    downloadDeviceLogs: `${commonenv.nextGenApiUrl}gdpr/download-device-logs`,
  };
  constructor(public httpClient: HttpClient) {}

  verifyTokenAndPass(token: string, pass: string) {
    return this.httpClient.get<{ data: { identity: string } }>(
      `${this.urls.verify}/${token}/${pass}`
    );
  }

  deviceLogs(identity: string, pass: string, token: string) {
    return this.httpClient.get<{ data: GdprDeviceLogsYearWise[] }>(
      `${this.urls.deviceLogs}/${identity}?pass_code=${pass}&token=${token}`
    );
  }

  savePassToLocalStorage(passCode: string) {
    localStorage.setItem(this.gdprStorageName, passCode);
  }

  getPassCodeFromStorage() {
    return localStorage.getItem(this.gdprStorageName);
  }

  destroyGdprPassStorage() {
    localStorage.removeItem(this.gdprStorageName);
  }

  downloadLogsUrl(month: string, pass: string, token: string, year: string) {
    return `${this.urls.downloadDeviceLogs}?pass_code=${pass}&token=${token}&month=${month}&year=${year}`;
  }
}
