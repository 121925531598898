import { BaseCommand } from './base.command';
import {
  TimelineItem,
} from '../interfaces/element.interfaces';
import { ToggleLayerFitEvent } from '../interfaces/timelines.interfaces';
import { getLayerFromId } from '../helpers/timelines.helpers';

export interface UpdateLayerFitResult {
  item: TimelineItem;
  timelineId: string;
}

export class UpdateLayerFitCommand extends BaseCommand<
  ToggleLayerFitEvent,
  UpdateLayerFitResult
> {
  run(event: ToggleLayerFitEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer, childLayer } = layerInfo;

    const layerToChange = childLayer ?? layer;
    if (layerToChange.styles) {
      layerToChange.styles.objectFit = event.objectFit
    } else {
      layerToChange.styles = { objectFit: event.objectFit }
    }

    return this.ok();
  }
}
