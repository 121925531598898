export interface UserData {
  id: number;
  account_id?: number;
  username?: string;
  fullname: string;
  email?: string;
  thumb_image: string;
  role?: string;
  imgfailed?: boolean;
  is_added?: boolean;
}
export interface UserListRequest {
  search_string?: string;
  team_id?: number;
  limit?: number;
  page?: number;
}
export interface UserListResponse {
  status: number;
  message: string;
  data: Array<UserData>;
}
export interface Member {
  member_id: number;
  role: string;
  email?: string;
  name?: string;
  is_required?: boolean;
  mappingId?: number;
}
export interface ExternalMember {
  mappingId: number;
  role: string;
  email: string;
  name: string;
  type?: string;
  is_online?: boolean;
  device_ids?: number[];
}
export interface CreateTeamRequest {
  team_name: string;
  id?: number;
  members: Array<Member>;
}
export interface TeamResponse {
  status: number;
  message: string;
  data?: { id: number };
}
export interface TeamListRequest {
  limit: number;
  page: number;
  team_name?: string;
}
export interface OvraTeamMember {
  id: number;
  role: string;
  type: string;
  clearly_account_users: UserData;
}
export class TeamMember {
  id: number;
  team_name: string;
  is_deleted: number;
  created_at: Date;
  updated_at: Date;
  ovra_team_members: Array<OvraTeamMember>;
}
export interface TeamListResponse {
  status: number;
  message: string;
  data: { count: number; rows: Array<TeamMember> };
}
export interface TeamDetailsResponse {
  status: number;
  message: string;
  data: TeamMember;
}

export interface MyTeam {
  id: number;
  name: string;
  email: string;
  role: string;
}

export interface TeamUserResponse {
  rows: TeamUser[];
  count: number;
}

export interface TeamUser {
  id: number;
  image: string;
  name: string;
  email: string;
  isRootAdmin: boolean;
}
