<div class="resize-handles">
  <div data-position="nw" class="nw"></div>
  <div data-position="n" class="n" *ngIf="!maintainAspectRatio"></div>
  <div data-position="ne" class="ne"></div>
  <div data-position="w" class="w" *ngIf="!maintainAspectRatio"></div>
  <div data-position="e" class="e" *ngIf="!maintainAspectRatio"></div>
  <div data-position="sw" class="sw"></div>
  <div data-position="s" class="s" *ngIf="!maintainAspectRatio"></div>
  <div data-position="se" class="se"></div>
</div>
