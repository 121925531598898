import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { Product } from './billing.interface';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public urls = {
    products: `${commonenv.nextGenApiUrl}billing/products`,
    checkout: `${commonenv.nextGenApiUrl}billing/checkout-session`,
    update: `${commonenv.nextGenApiUrl}billing/update`,
    customerPortal: `${commonenv.nextGenApiUrl}billing/customer-portal`,
  };
  constructor(public httpClient: HttpClient) {}

  getAllProducts() {
    return this.httpClient.get<Product[]>(this.urls.products);
  }

  getCustomerPortalLink() {
    return this.httpClient.get<{ url: string }>(this.urls.customerPortal);
  }

  getCheckoutLink(priceId: string, quantity: number, minQuantity: number, maxQuantity: number) {
    return this.httpClient.get<{ url: string }>(this.urls.checkout, {
      params: {
        quantity: quantity,
        priceId: priceId,
        minQuantity: minQuantity,
        maxQuantity: maxQuantity,
      },
    });
  }
  updateBilling(quantity: number) {
    return this.httpClient.put(this.urls.update, { quantity: quantity });
  }
}
