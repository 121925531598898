import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msToMinSec',
})
export class MsToMinSecPipe implements PipeTransform {
  public transform(value: number) {
    value = Number(value);

    const seconds = value / 1000;
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }
}
