import { Timeline, TimelinesLayer, VideoLayer, WorkflowDataDto } from './../interfaces/workflow.interfaces';
import { round, sortBy } from 'lodash';
import { getSectionTypeTimelines, getTimelineDuration } from '../helpers';

const GAP_THRESHOLD_MS = 50;

export class WorkflowValidators {
  static fixVisibilityOverlaps(source: WorkflowDataDto) {
    const fixVisibility = (timeline: Timeline) => {
      if (timeline.layers.length === 0) {
        return;
      }
      const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);

      if (sortedLayers[0].type === 'timelines') {
        const timelinesLayer = sortedLayers[0] as TimelinesLayer;
        timelinesLayer.children.forEach((child) => fixVisibility(child));
      } else {
        let currentTime = sortedLayers[0].visibility.endAt;
        for (let i = 1; i < sortedLayers.length; i++) {
          const layer = sortedLayers[i];

          const diffMs = layer.visibility.startAt - currentTime;
          const shouldJoin = diffMs < GAP_THRESHOLD_MS && diffMs !== 1;

          if (shouldJoin) {
            const fixDiffMs = layer.visibility.startAt - currentTime + 1;
            const layerDuration = layer.visibility.endAt - layer.visibility.startAt;
            layer.visibility = {
              ...layer.visibility,
              startAt: currentTime + 1,
              endAt: currentTime + 1 + layerDuration,
            };

            for (let j = i + 1; j < sortedLayers.length; j++) {
              sortedLayers[j].visibility = {
                ...sortedLayers[j].visibility,
                startAt: sortedLayers[j].visibility.startAt + fixDiffMs,
                endAt: sortedLayers[j].visibility.endAt + fixDiffMs,
              };
            }
          }

          currentTime = layer.visibility.endAt;
        }
      }
    };

    const mainTimelines = getSectionTypeTimelines(source.sections, 'main');
    const timelinesToFix = mainTimelines.filter((t) => t.type === 'main' || t.type === 'b-roll');
    timelinesToFix.forEach((timeline) => fixVisibility(timeline));

    return true;
  }

  static ValidateNoOverlappingVisibility(source: WorkflowDataDto) {
    const checkTimeline = (timeline: Timeline) => {
      if (timeline.layers.length === 0) {
        return true;
      }

      let currentPos = null;
      const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);
      for (const layer of sortedLayers) {
        if (layer.type === 'timelines') {
          const timelinesLayer = layer as TimelinesLayer;
          for (const childTimeline of timelinesLayer.children) {
            if (!checkTimeline(childTimeline)) {
              return false;
            }
          }
        } else {
          if (currentPos !== null && layer.visibility.startAt <= currentPos) {
            console.error(
              `Error on: ${layer.layerId}. Previous layer: ${currentPos}, Current Layer: ${layer.visibility.startAt}`
            );
            return false;
          }
          currentPos = layer.visibility.endAt;
        }
      }

      return true;
    };

    const mainTimelines = getSectionTypeTimelines(source.sections, 'main');
    for (const mainTimeline of mainTimelines) {
      if (!checkTimeline(mainTimeline)) {
        return false;
      }
    }

    return true;
  }

  static ValidateNoNegativeVisibilty(source: WorkflowDataDto) {
    const checkTimeline = (timeline: Timeline) => {
      if (timeline.layers.length === 0) {
        return true;
      }

      for (const layer of timeline.layers) {
        if (layer.type === 'timelines') {
          const timelinesLayer = layer as TimelinesLayer;
          for (const childTimeline of timelinesLayer.children) {
            if (!checkTimeline(childTimeline)) {
              return false;
            }
          }
        } else {
          const { startAt, endAt } = layer.visibility;
          if (startAt < 0 || endAt < 0) {
            console.error(`Error on ${layer.layerId}. ${startAt}, ${endAt}`);
            return false;
          }
        }
      }

      return true;
    };

    const mainTimelines = getSectionTypeTimelines(source.sections, 'main');
    for (const mainTimeline of mainTimelines) {
      if (!checkTimeline(mainTimeline)) {
        return false;
      }
    }

    return true;
  }

  static ValidateVisibilityMatchesAssetTrims(source: WorkflowDataDto) {
    const checkTimeline = (timeline: Timeline) => {
      if (timeline.layers.length === 0) {
        return true;
      }

      for (const layer of timeline.layers) {
        if (layer.type === 'timelines') {
          const timelinesLayer = layer as TimelinesLayer;
          for (const childTimeline of timelinesLayer.children) {
            if (!checkTimeline(childTimeline)) {
              return false;
            }
          }
        } else {
          const visibilityDiff = layer.visibility.endAt - layer.visibility.startAt;

          const asset = source.assets.find((a) => a.id === (layer as VideoLayer).assetId);
          const assetTrimDiff = (asset.trimTo || asset.data.duration) - (asset.trimFrom || 0);
          if (round(visibilityDiff - assetTrimDiff) !== 0) {
            console.error(`Error on ${layer.layerId}. Visiblity diff: ${visibilityDiff}, trim diff: ${assetTrimDiff}`);
            return false;
          }
        }
      }

      return true;
    };

    const mainTimelines = getSectionTypeTimelines(source.sections, 'main');
    const timelinesToCheck: Timeline[] = mainTimelines.filter((t) => t.type === 'main' || t.type === 'b-roll');

    for (const timeline of timelinesToCheck) {
      if (!checkTimeline(timeline)) {
        return false;
      }
    }

    return true;
  }

  static ValidateMainClipsNoGaps(source: WorkflowDataDto) {
    const checkTimeline = (timeline: Timeline, longerTimelineDuration: number) => {
      if (timeline.layers.length === 0) {
        return true;
      }

      // Just check longer timeline since shorter one can have gaps
      const timelineDuration = getTimelineDuration(timeline);
      if (timelineDuration !== longerTimelineDuration) {
        return true;
      }

      const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);
      let currentPos = null;
      for (const layer of sortedLayers) {
        if (layer.type === 'timelines') {
          const timelinesLayer = layer as TimelinesLayer;
          let failedChildTimelines = 0;
          timelinesLayer.children.forEach((child) => {
            if (!checkTimeline(child, longerTimelineDuration)) {
              failedChildTimelines++;
            }
          });

          // Its OK for one timeline to have gaps since it might be shorter one
          // Both cant have gaps though
          return failedChildTimelines < 2;
        } else {
          const { startAt, endAt } = layer.visibility;
          if (currentPos !== null && startAt - currentPos > GAP_THRESHOLD_MS) {
            return false;
          }
          currentPos = endAt;
        }
      }

      return true;
    };

    const mainTimelines = getSectionTypeTimelines(source.sections, 'main');
    const timelinesToCheck = mainTimelines.filter((t) => t.type === 'main' || t.type === 'b-roll');

    const allTimelinesPass = timelinesToCheck.every((t) => checkTimeline(t, getTimelineDuration(t)));
    return allTimelinesPass;
  }
}
