import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { SelfRecordFacade } from '../../store/facades/self-record.facade';
import { Cleanupable, RecordingType } from '@openreel/common';
import { first } from 'rxjs/operators';
import { SelfRecordClipTrim, SelfRecordingVideo } from '../../store/interfaces/self-record.interfaces';

import { TrimmerData, TrimmerMainClip, TrimmerVideoOverlayClip } from '@openreel/creator/common';

@Component({
  selector: 'openreel-wf-self-record-trim',
  templateUrl: './self-record-trim.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfRecordTrimComponent extends Cleanupable implements OnInit {
  trimmerData: TrimmerData;

  private recordingType: RecordingType;
  private lastUploadWebcamVideo: SelfRecordingVideo;
  private lastUploadScreenVideo: SelfRecordingVideo;

  constructor(private readonly selfRecordFacade: SelfRecordFacade) {
    super();
  }

  ngOnInit() {
    this.selfRecordFacade.source$.pipe(first()).subscribe((recordingType) => (this.recordingType = recordingType));

    this.selfRecordFacade.lastUploadWebcamVideo$
      .pipe(first())
      .subscribe((lastUploadWebcamVideo) => (this.lastUploadWebcamVideo = lastUploadWebcamVideo));

    this.selfRecordFacade.lastUploadScreenVideo$
      .pipe(first())
      .subscribe((lastUploadScreenVideo) => (this.lastUploadScreenVideo = lastUploadScreenVideo));

    this.prepareTrimmerData();
  }

  prepareTrimmerData() {
    const { clips } = this.getClips();

    this.trimmerData = {
      clips,
      splitCount: 1,
    };
  }

  onTrimChanged($event: TrimmerData) {
    const trims: SelfRecordClipTrim[] = $event.clips.map((clip) => ({
      videoId: clip.assetFileId.toString(),
      trimFrom: clip.splits[0].startAt,
      trimTo: clip.splits[0].endAt,
    }));

    this.selfRecordFacade.applyTrims(trims);
  }

  private getClips() {
    const clips: (TrimmerMainClip | TrimmerVideoOverlayClip)[] = [];
    switch (this.recordingType) {
      case 'camera':
        clips.push(
          this.getWebcamVideo({
            timelineType: 'main',
          })
        );
        break;
      case 'screen':
        clips.push(
          this.getScreenVideo({
            timelineType: 'main',
          })
        );
        break;
      case 'both-pip':
        if (this.lastUploadWebcamVideo && this.lastUploadScreenVideo) {
          const trimTo = Math.min(this.lastUploadScreenVideo.duration, this.lastUploadScreenVideo.duration);

          clips.push(
            this.getWebcamVideo(
              {
                timelineType: 'b-roll',
              },
              trimTo
            )
          );

          clips.push(
            this.getScreenVideo(
              {
                timelineType: 'main',
              },
              trimTo
            )
          );
        } else if (this.lastUploadWebcamVideo) {
          clips.push(
            this.getWebcamVideo({
              timelineType: 'main',
            })
          );
        } else if (this.lastUploadScreenVideo) {
          clips.push(
            this.getScreenVideo({
              timelineType: 'main',
            })
          );
        }

        break;
    }

    return { clips };
  }

  private getScreenVideo(restProps: Record<string, string>, trimTo?: number) {
    return {
      ...restProps,
      assetFileId: this.lastUploadScreenVideo.videoId,
      assetProviderType: 'or-recordings',
      duration: this.lastUploadScreenVideo.duration * 1000,
      name: 'screen_recording',
      splits: [
        {
          startAt: 0,
          endAt: trimTo ? trimTo * 1000 : this.lastUploadScreenVideo.duration * 1000,
        },
      ],
    } as TrimmerMainClip | TrimmerVideoOverlayClip;
  }

  private getWebcamVideo(restProps: Record<string, string>, trimTo?: number) {
    return {
      ...restProps,
      assetFileId: this.lastUploadWebcamVideo.videoId,
      assetProviderType: 'or-recordings',
      duration: this.lastUploadWebcamVideo.duration * 1000,
      name: 'webcam_recording',
      splits: [
        {
          startAt: 0,
          endAt: trimTo ? trimTo * 1000 : this.lastUploadWebcamVideo.duration * 1000,
        },
      ],
    } as TrimmerMainClip | TrimmerVideoOverlayClip;
  }
}
