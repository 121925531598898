import * as fromActions from '../actions/timelines.actions';
import * as fromProjectActions from '@openreel/creator/app/store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ProjectFacade } from '@openreel/creator/app/store/facades/project.facade';
import { UpdateVideoOverlayPipCommand } from '@openreel/creator/common';

@Injectable()
export class VideoOverlaysEffects {
  changeVideoOverlayType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeVideoOverlayType),
      withLatestFrom(this.projectFacade.workflow$),
      concatMap(([{ event }, workflow]) => {
        const { updatedWorkflow } = new UpdateVideoOverlayPipCommand(workflow).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            data: updatedWorkflow,
          }),
        ];
      })
    )
  );

  constructor(private readonly actions$: Actions, private readonly projectFacade: ProjectFacade) {}
}
