import { createSelector } from '@ngrx/store';
import { selectSelfRecordFeatureState, SelfRecordFeatureState } from '..';
import * as fromReducer from '../reducers/self-record.reducer';

export const selectRecordState = createSelector(
  selectSelfRecordFeatureState,
  (state: SelfRecordFeatureState) =>
    state ? state[fromReducer.key] : undefined
);

export const selectWebcamStreamStatus = createSelector(
  selectRecordState,
  (state) => state?.webcamStreamStatus
);

export const selectScreenStreamStatus = createSelector(
  selectRecordState,
  (state) => state?.screenStreamStatus
);

export const selectStreamStatus = createSelector(
  selectRecordState,
  (state) => {
    switch (state.source) {
      case 'camera':
        return state.webcamStreamStatus;
      case 'screen':
        return state.screenStreamStatus;
      case 'both-pip':
      case 'both-split':
        if (!state.webcamStreamStatus || !state.screenStreamStatus) {
          return null;
        }
        if (state.webcamStreamStatus === 'denied' || state.screenStreamStatus === 'denied') {
          return 'denied';
        }
        if (state.webcamStreamStatus === 'initializing' || state.screenStreamStatus === 'initializing') {
          return 'initializing';
        }
        if (state.webcamStreamStatus === 'loading' || state.screenStreamStatus === 'loading') {
          return 'loading';
        }
        if (state.webcamStreamStatus === 'playing' && state.screenStreamStatus === 'playing') {
          return 'playing';
        }

        throw new Error(
          'Unknown stream status. webcamStreamStatus: ' + state.webcamStreamStatus + '; screenStreamStatus: ' + state.screenStreamStatus
        );
    }
  }
);

export const selectOptions = createSelector(
  selectRecordState,
  (state) => state?.options
);

export const selectAvailableWebcamDevices = createSelector(
  selectRecordState,
  (streamState) => streamState.availableWebcamDevices
);

export const selectAvailableAudioDevices = createSelector(
  selectRecordState,
  (streamState) => streamState.availableAudioDevices
);

export const selectWebcamDevice = createSelector(
  selectRecordState,
  (streamState) => streamState.webcamDevice
);

export const selectAudioDevice = createSelector(
  selectRecordState,
  (streamState) => streamState.audioDevice
);

export const selectSource = createSelector(
  selectRecordState,
  (streamState) => streamState.source
);

export const selectScreenStream = createSelector(
  selectRecordState,
  (streamState) => streamState.screenStream
);

export const selectWebcamStream = createSelector(
  selectRecordState,
  (streamState) => streamState.webcamStream
);

export const selectUploadScreenVideo = createSelector(
  selectRecordState,
  (streamState) => streamState.uploadScreenVideo
);

export const selectUploadWebcamVideo = createSelector(
  selectRecordState,
  (streamState) => streamState.uploadWebcamVideo
);

export const selectUploadWebcamStatus = createSelector(
  selectRecordState,
  (streamState) => streamState.uploadWebcamStatus
);

export const selectUploadScreenStatus = createSelector(
  selectRecordState,
  (streamState) => streamState.uploadScreenStatus
);

export const selectTranscodeWebcamStatus = createSelector(
  selectRecordState,
  (streamState) => streamState.transcodeWebcamStatus
);

export const selectTranscodeScreenStatus = createSelector(
  selectRecordState,
  (streamState) => streamState.transcodeScreenStatus
);

export const selectStatus = createSelector(
  selectRecordState,
  (streamState) => streamState.status
);

export const selectSessionId = createSelector(
  selectRecordState,
  (streamState) => streamState.sessionId
);

export const selectRecordingStartedAt = createSelector(
  selectRecordState,
  (streamState) => streamState.recordingStartedAt
);

export const selectLastUploadWebcamVideo = createSelector(
  selectRecordState,
  (streamState) => streamState.lastUploadWebcamVideo
);

export const selectLastUploadScreenVideo = createSelector(
  selectRecordState,
  (streamState) => streamState.lastUploadScreenVideo
);

export const selectTrims = createSelector(
  selectRecordState,
  (state) => state.trims
);
