import { File } from '../interfaces';
import { BaseCommand } from './base.command';

export interface UpdateWatermarkEvent {
  assetFile: File;
  sectionId?: string;
}

export class UpdateWatermarkCommand extends BaseCommand<UpdateWatermarkEvent> {
  run({ assetFile, sectionId }: UpdateWatermarkEvent) {
    this.updateWatermark(assetFile, sectionId);
    return this.ok();
  }
}
