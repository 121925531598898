import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import {selectAuthRole} from '../../../../../apps/desktop/src/app/app-state/auth/auth.selector';
import {filter, first, tap} from 'rxjs/operators';
import {DirectorUserDetails, UserRoleType} from '@openreel/common/interfaces';
import {fetchDirectorUserDetails} from '../../../../../apps/desktop/src/app/app-state/director/director.actions';
import {Observable} from 'rxjs';

@Injectable()
export class DirectorUserDetailsService {

  constructor(private store: Store) {}

  fetchDirectorUserDetails(): Observable<DirectorUserDetails | UserRoleType> {
    return this.store
      .select(selectAuthRole)
      .pipe(
        filter((auth) => auth === UserRoleType.Internal),
        first(),
        tap(() => this.store.dispatch(fetchDirectorUserDetails()))
      )
  }
}
