import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import {
  Asset,
  AssetId,
  AssetMetadata,
  AssetsFileProviderType,
  LayerOptions,
  Style,
  Timeline,
  TimelinesLayer,
  VideoLayer,
  WorkflowDataDto,
  Layer,
  LayerStyles,
  Bounds,
  LottieLayer,
  SectionLayer,
  Section,
  AssetLayer,
  File,
} from './../interfaces/workflow.interfaces';
import { LayerDataChanges, TimelineItem } from '../interfaces/element.interfaces';

import { cloneDeep, flatten, isEqual, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getLayerFromId, getLayers, isSectionEmpty } from '../helpers/timelines.helpers';
import {
  getDefaultPresetData,
  getSectionTimelines,
  getSectionTypeTimelines,
  getTimelineDuration,
  updateMainClipTransitions,
  updateMainSceneTransitions,
} from '../helpers';
import { LayoutDto, LayoutType } from '../interfaces';

export abstract class WorkflowBaseBuilder {
  protected source: WorkflowDataDto;

  constructor(original: WorkflowDataDto) {
    this.source = cloneDeep(original);
  }

  toWorkflow = () => {
    for (const [sectionId] of Object.entries(this.source.sections).filter(
      ([, section]) => section.sectionType === 'main'
    )) {
      this.toggleSection(sectionId, !isSectionEmpty(this.source, sectionId));
    }
    updateMainSceneTransitions(this.source);
    updateMainClipTransitions(this.source);

    return this.source;
  };

  protected getUniqueId() {
    return uuidv4();
  }

  protected addWatermark(sectionId: string = null) {
    const watermarkSettings = this.source.globalSettings.watermarkLayer;
    if (!sectionId) {
      watermarkSettings.enabled = true;
    }

    const sections = getSectionTimelines(this.source.sections, 'main', 'watermark', sectionId);
    sections
      .filter((s) => s.timelines[0].layers.length === 0)
      .forEach((s) => {
        const { layer, styles } = this.createWatermarkLayer();
        this.source.styles.push(...styles);

        return s.timelines[0].layers.push({
          ...layer,
          bounds: s.timelines[0].bounds,
        });
      });
  }

  protected removeWatermark(sectionId: string) {
    const watermarkSettings = this.source.globalSettings.watermarkLayer;
    if (!sectionId) {
      watermarkSettings.enabled = false;
    }

    const watermarkTimelines = getSectionTypeTimelines(this.source.sections, 'main', sectionId).filter(
      (t) => t.type === 'watermark'
    );
    watermarkTimelines.forEach((wt) => {
      if (wt.layers[0]) {
        const assetId = (wt.layers[0] as AssetLayer).assetId;
        this.removeAssets(assetId);
      }
      wt.layers = [];
    });
  }

  protected updateWatermark(assetFile: File, sectionId?: string) {
    const isGlobalUpdate = sectionId === null || sectionId === undefined || sectionId === '';

    const watermarkTimelines = flatten(
      getSectionTimelines(this.source.sections, 'main', 'watermark', sectionId).map((wt) => wt.timelines)
    );

    if (isGlobalUpdate && assetFile) {
      // Apply global watermark for timelines with disabled watermark
      this.addWatermark(sectionId);
    }

    const watermarkLayerIds = flatten(watermarkTimelines.map((wt) => wt.layers)).map((l) => l.layerId);

    const globalAssetId = this.source.globalSettings.watermarkLayer.assetId;
    const globalAsset = this.getAsset(globalAssetId);
    if (isGlobalUpdate) {
      globalAsset.file = assetFile;
    }

    for (const layerId of watermarkLayerIds) {
      const { layer } = getLayerFromId(layerId, this.source);
      const layerWithAsset = layer as AssetLayer;

      const existingAsset = layerWithAsset.assetId
        ? this.source.assets.find((a) => a.id === layerWithAsset.assetId)
        : null;

      const assetSameAsCurrentGlobal = isEqual(globalAsset.file, assetFile);
      if (isGlobalUpdate || assetSameAsCurrentGlobal) {
        // Here we couple back section to global watermark asset
        if (existingAsset) {
          this.removeAssets(existingAsset.id);
        }
        layerWithAsset.assetId = globalAsset.id;
      } else {
        // Here we de-couple section from global watermark asset
        if (!existingAsset || existingAsset.isGlobal) {
          const newAsset = this.addAsset(assetFile.path, 'image');
          layerWithAsset.assetId = newAsset.id;
        } else if (assetFile) {
          existingAsset.file = assetFile;
        } else {
          this.removeWatermark(sectionId);
        }
      }
    }
  }

  protected toggleLayer(layerId: string, enabled: boolean) {
    const layerInfo = getLayerFromId(layerId, this.source);
    if (!layerInfo) {
      return;
    }

    const { layer } = layerInfo;
    layer.enabled = enabled;
  }

  protected toggleSection(sectionId: string, enabled: boolean) {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    const elIndex = mainTimeline.layers.findIndex((el) => el.type === 'section' && el.sectionId === sectionId);
    mainTimeline.layers[elIndex].enabled = enabled;
  }

  protected createWatermarkLayer() {
    const watermarkSettings = this.source.globalSettings.watermarkLayer;

    const commonOptions: Partial<LayerOptions | AssetLayer> = {
      layerId: this.getUniqueId(),
      assetId: watermarkSettings.assetId,
      tags: watermarkSettings.tags,
      visibility: {
        startAt: 0,
      },
    };

    let layer;
    const styles: Style[] = [];
    if (watermarkSettings.type === 'image') {
      layer = {
        ...commonOptions,
        type: 'image',
      };
    } else {
      const assetId = watermarkSettings.assetId;
      const animationAsset = this.getAsset(assetId.toString());
      const { data, styles: watermarkStyles } = getDefaultPresetData(animationAsset, this.source);

      layer = {
        ...commonOptions,
        type: 'lottie',
        data,
        renderer: 'svg',
      };

      styles.push(...watermarkStyles);
    }

    return { layer, styles };
  }

  protected reorderMainSections(mainSectionIds: string[]) {
    let sections = this.getSectionLayers();
    const introSections = sections.filter((section) => this.source.sections[section.sectionId].sectionType === 'intro');
    const mainSections = sections.filter((section) => this.source.sections[section.sectionId].sectionType === 'main');
    const outroSections = sections.filter((section) => this.source.sections[section.sectionId].sectionType === 'outro');

    if (mainSections.length !== mainSectionIds.length) {
      throw new Error('Main section count different from the new section IDs order.');
    }

    const orderedMainSections: SectionLayer[] = [];

    mainSectionIds.forEach((sectionId) => {
      const section = mainSections.find((s) => s.sectionId === sectionId);
      if (!section) {
        throw new Error(`Main section with ID ${sectionId} not found.`);
      }

      orderedMainSections.push(section);
    });

    sections = [...introSections, ...orderedMainSections, ...outroSections];

    this.source.timelines[0].layers = sections;
  }

  protected addTextOverlay(timeline: Timeline, startAt?: number) {
    const assetId = this.source.globalSettings.textOverlays.assetId;

    const animationAsset = this.getAsset(assetId.toString());
    const { data, styles } = getDefaultPresetData(animationAsset, this.source);
    this.addStyles(styles);

    const visibility = {
      startAt: startAt,
      endAt: startAt + animationAsset.data.duration,
    };

    const layer: LayerOptions & LottieLayer = {
      type: 'lottie',
      layerId: uuidv4(),
      assetId,
      renderer: 'svg',
      bounds: timeline.bounds,
      visibility,
      data,
      colorTags: animationAsset.colorTags,
      enabled: false,
    };

    timeline.layers.push(layer);

    return layer;
  }

  protected addClipAsset(
    assetId: AssetId,
    assetProvider: AssetsFileProviderType,
    trimFrom: number,
    trimTo: number,
    data: AssetMetadata
  ) {
    if (!this.source.assets) {
      this.source.assets = [];
    }

    const newAsset: Asset = {
      id: uuidv4(),
      type: 'clip',
      file: {
        path: assetId,
        provider: assetProvider,
      },
      data,
      ...(trimFrom && { trimFrom }),
      ...(trimTo && { trimTo }),
    };

    this.source.assets.push(newAsset);

    return newAsset;
  }

  protected addAsset(newAssetFileId: number | string, type: 'image' | 'clip') {
    const newAsset: Asset = {
      id: uuidv4(),
      type,
      file: {
        path: newAssetFileId,
        provider: 'or-assets',
      },
    };

    this.source.assets.push(newAsset);

    return newAsset;
  }

  protected removeAssets(assetIds: string | string[]) {
    const assetIdsToRemove = Array.isArray(assetIds) ? [...assetIds] : [assetIds];

    assetIdsToRemove.forEach((assetId) => {
      const assetToRemove = this.source.assets.find((a) => a.id === assetId);
      if (!assetToRemove || assetToRemove.isGlobal || assetToRemove.isPlaceholder) {
        return;
      }

      let usedCnt = 0;
      for (const { layer } of getLayers(this.source)) {
        if (
          (layer.type === 'video' || layer.type === 'image' || layer.type === 'lottie') &&
          layer.assetId === assetId
        ) {
          usedCnt += 1;
        }

        if (layer.type === 'lottie') {
          Object.keys(layer.data).forEach((key) => {
            if (layer.data[key].assetId === assetId) {
              usedCnt += 1;
            }
          });
        }
      }

      if (usedCnt <= 1) {
        this.source.assets = this.source.assets.filter((a) => a.id !== assetId);
      }
    });
  }

  protected getAsset(assetId: string) {
    return this.source.assets.find((a) => a.id === assetId);
  }

  protected getSectionLayers(): SectionLayer[] {
    return this.source.timelines[0].layers.filter((layer) => layer.type === 'section') as SectionLayer[];
  }

  protected addStyles(styles: Style[]) {
    if (!this.source.styles) {
      this.source.styles = [];
    }
    this.source.styles.push(...styles);
  }

  protected getAssetDuration(asset: Asset) {
    let duration = asset.data?.duration ?? 0;
    if (asset.trimFrom || asset.trimTo) {
      duration = (asset.trimTo || duration) - (asset.trimFrom || 0);
    }

    return duration;
  }

  protected insertClips(
    timeline: Timeline,
    clips: TimelineInterfaces.NewClip[],
    insertIndex: number,
    options: LayerOptions = {}
  ) {
    const createdItems: TimelineItem[] = [];

    const [bounds, styles] = this.getTimelineBoundsAndStyles(timeline);

    clips.forEach((clip) => {
      const newAsset = clip.isPlaceholder
        ? null
        : this.addClipAsset(clip.assetFileId, clip.assetProviderType, clip.trimFrom, clip.trimTo, {
            name: clip.name,
            duration: clip.duration,
            ...(clip.source && { source: clip.source }),
          });

      const newLayer: VideoLayer & LayerOptions = {
        layerId: uuidv4(),
        type: 'video',
        assetId: clip.isPlaceholder ? clip.assetId : newAsset.id,
        visibility: {
          startAt: 0,
          endAt: clip.trimTo - clip.trimFrom,
        },
        bounds: bounds,
        styles: styles,
        ...options,
      };

      createdItems.push(new TimelineItem(newLayer, newAsset, timeline.type));
    });

    timeline.layers.splice(insertIndex, 0, ...createdItems.map((item) => item.layer));

    return createdItems;
  }

  protected applyLayerChanges(layer: Layer, changes: LayerDataChanges) {
    for (const [key, value] of Object.entries(changes.values)) {
      const assetChanges = changes.assetChanges[key];
      const styleChanges = changes.styleChanges[key];

      if (['image', 'logo', 'video'].includes(value.type)) {
        if (assetChanges.removedAssetId) {
          this.removeAssets(assetChanges.removedAssetId);
        }
        if (assetChanges.newAssetFileId) {
          // NOTE: special case, watermark
          const layerInfo = getLayerFromId(layer.layerId, this.source);
          if (!layerInfo) {
            throw new Error(`No layer found for id: ${layer.layerId}`);
          }

          if (layerInfo.timeline.type === 'watermark') {
            const assetId = this.source.globalSettings.watermarkLayer.assetId;
            const asset = this.getAsset(assetId);
            asset.file.path = assetChanges.newAssetFileId;
            value.assetId = assetId;
          } else {
            const assetType = value.type === 'video' ? 'clip' : 'image';
            const addedAsset = this.addAsset(assetChanges.newAssetFileId, assetType);
            value.assetId = addedAsset.id;
          }
        }
      }

      if (['text', 'shape'].includes(value.type)) {
        if (styleChanges) {
          let style: Style;
          if (value.styleId) {
            style = this.source.styles.find((s) => s.id === value.styleId);
          } else {
            style = { id: uuidv4() };
            this.source.styles.push(style);
            value.styleId = style.id;
          }

          if (style) {
            if (value.type === 'text') {
              style.fontIndex = styleChanges.fontIndex || 0;
              style.fontWeight = styleChanges.fontWeight;
            }

            style.color = styleChanges.color;
          }
        }
      }
    }

    if (layer.type === 'lottie') {
      layer.data = changes.values;
    }

    if (layer.type === 'video' || layer.type === 'image') {
      const assetId = changes.values['default'].assetId as string;
      layer.assetId = assetId;
      layer.enabled = !!assetId;
    }
  }

  protected updateLottieLayerColors(layer: LottieLayer, fieldsToUpdate: string[], color: string) {
    fieldsToUpdate.forEach((field) => {
      const styleId = layer.data[field].styleId;
      const style = this.source.styles.find((s) => s.id === styleId);
      style.color = color;
    });
  }

  protected sortLayersByVisibility() {
    function sortTimelineLayers(timeline: Timeline) {
      timeline.layers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);
    }

    const mainTimelines = getSectionTypeTimelines(this.source.sections, 'main');

    mainTimelines.forEach((timeline) => {
      if (['main', 'b-roll'].indexOf(timeline.type) === -1 || timeline.layers.length === 0) {
        return;
      }

      if (timeline.layers[0].type === 'timelines') {
        const timelinesLayer = timeline.layers[0] as TimelinesLayer;
        timelinesLayer.children.forEach((childTimeline) => sortTimelineLayers(childTimeline));
      } else {
        sortTimelineLayers(timeline);
      }
    });
  }

  protected removeLayersOutOfBounds(sectionId: string) {
    const sectionMainTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId);
    const sectionVideoOverlayTimelines = getSectionTimelines(this.source.sections, 'main', 'b-roll', sectionId);

    const assetsToRemove = [];

    sectionMainTimelines.forEach((section, index) => {
      const mainTimelineDuration = section.timelines.reduce(
        (prev, curr) => Math.max(prev, getTimelineDuration(curr)),
        0
      );

      const checkTimelines: Timeline[] = [];
      if (sectionVideoOverlayTimelines[index].timelines.length > 0) {
        checkTimelines.push(...sectionVideoOverlayTimelines[index].timelines);
      }

      checkTimelines.forEach((timeline) => {
        timeline.layers = timeline.layers
          // Remove layers out of bounds
          .filter((layer: LayerOptions & VideoLayer) => {
            if (layer.visibility.snapToEnd) {
              return true;
            }

            if (timeline.type === 'b-roll' && layer.visibility.endAt > mainTimelineDuration) {
              assetsToRemove.push(layer.assetId);
            }
            return layer.visibility.endAt <= mainTimelineDuration;
          })
          // Recalculate position for layers that snap to main timeline's end
          .map((layer: Layer) => {
            if (!layer.visibility.snapToEnd) {
              return layer;
            }

            const layerDuration = layer.visibility.endAt - layer.visibility.startAt;

            return {
              ...layer,
              visibility: {
                ...layer.visibility,
                startAt: mainTimelineDuration - layerDuration,
                endAt: mainTimelineDuration,
              },
            };
          });
      });
    });

    if (assetsToRemove.length > 0) {
      this.removeAssets(assetsToRemove);
    }
  }

  protected recalculateVisibility(timeline: Timeline) {
    let startAt = 0;
    timeline.layers.forEach((layer: LayerOptions & VideoLayer) => {
      const asset = this.getAsset(layer.assetId);
      const assetDuration = this.getAssetDuration(asset);
      const layerDuration = (asset.trimTo ?? assetDuration) - (asset.trimFrom ?? 0);

      layer.visibility = {
        startAt,
        endAt: startAt + layerDuration,
      };

      startAt += layerDuration + 1;
    });
  }

  protected getTimelineBoundsAndStyles(timeline: Timeline): [Bounds, LayerStyles] {
    const bounds = timeline.bounds;
    const styles = timeline.styles;

    return [bounds, styles];
  }

  protected clearTimelinesLayer(timelinesLayer: TimelinesLayer) {
    timelinesLayer.children.forEach((timeline) => {
      this.removeAssets(timeline.layers.map((layer: VideoLayer) => layer.assetId));
      timeline.layers = [];
    });
  }

  protected applyGlobalColor(color: string, tag: string) {
    const assetsChanged = new Map<string, { fieldsToUpdate: string[]; colorTag: string; colorValue: string }>();

    // Update assets
    this.source.assets
      .filter((a) => a.type === 'json' && a.colorTags?.length)
      .forEach((a) => {
        const affectedColorTags = a.colorTags.filter((ct) => ct.tag === tag);
        affectedColorTags.forEach((colorTag) => {
          colorTag.color = color;

          const fieldsToUpdate = Object.keys(a.preset).filter((key) => a.preset[key].colorTag === colorTag.tag);

          assetsChanged.set(a.id, {
            fieldsToUpdate,
            colorTag: colorTag.tag,
            colorValue: colorTag.color,
          });
        });
      });

    // Update layers
    for (const { layer } of getLayers(this.source, ['lottie'])) {
      const lottieLayer = layer as LottieLayer;
      const assetChange = assetsChanged.get(lottieLayer.assetId);
      if (!assetChange) {
        continue;
      }

      const affectedColorTag = (lottieLayer.colorTags || []).find((layerCt) => layerCt.tag === assetChange.colorTag);
      if (affectedColorTag) {
        affectedColorTag.color = assetChange.colorValue;
      }

      this.updateLottieLayerColors(lottieLayer, assetChange.fieldsToUpdate, assetChange.colorValue);
    }
  }

  protected updatePrimaryColor(color: string) {
    if (!color) {
      return;
    }

    this.source.globalSettings.primaryColor = {
      type: 'solid',
      color,
    };
  }

  // #region Apply/Update Layout

  protected applyLayout(
    sectionId: string,
    templateLayoutId: number,
    layoutType: LayoutType,
    layoutData: LayoutDto,
    skipPlaceholders = false
  ) {
    const newLayout = cloneDeep(layoutData);
    const section = sectionId ? this.source.sections[sectionId] : null;

    section.layout = {
      ...section.layout,
      templateLayoutId,
      layoutType,
    };

    this.applyTemplateStylesToLayout(newLayout);

    this.applyLayoutTimelines(section, newLayout);
    this.applyLayoutFeatures(newLayout);

    if (!skipPlaceholders) {
      this.addLayoutPlaceholders(sectionId, layoutType);
    }
  }

  private shouldApplyStylesToLayout(layout: LayoutDto) {
    const mainLayoutTimeline = layout.timelines.find((t) => t.type === 'main');
    if (mainLayoutTimeline.layers.length > 0 && mainLayoutTimeline.layers[0].type === 'timelines') {
      return true;
    }

    const bounds = mainLayoutTimeline.bounds;
    return bounds && !(bounds.x === 0 && bounds.y === 0 && bounds.width === 100 && bounds.height === 100);
  }

  private applyTemplateStylesToLayout(layout: LayoutDto) {
    const { styles: templateStyles } = this.source.features.layouts;

    if (this.shouldApplyStylesToLayout(layout)) {
      const mainTimeline = layout.timelines.find((t) => t.type === 'main');
      if (mainTimeline.layers.length > 0 && mainTimeline.layers[0].type === 'timelines') {
        mainTimeline.layers[0].children.forEach((ct) => (ct.styles = templateStyles));
      } else {
        mainTimeline.styles = templateStyles;
      }
    }
  }

  private applyLayoutTimelines(section: Section, layout: LayoutDto) {
    // NOTE: Remove all timelines except background
    section.timelines = section.timelines.filter((t) => t.type === 'background');
    section.timelines.push(...cloneDeep(layout.timelines));
  }

  private applyLayoutFeatures(layout: LayoutDto) {
    this.source.features = {
      ...this.source.features,
    };

    // Set wizard features
    const { wizard } = this.source.features;
    const hasTextTimelines = layout.timelines.some((t) => t.type === 'overlays');
    wizard.textOverlays.visible = hasTextTimelines;
    wizard.textOverlays.enabled = false;

    const videoOverlayTimeline = layout.timelines.find((t) => t.type === 'b-roll');
    if (videoOverlayTimeline) {
      wizard.videoOverlays.visible = true;
      wizard.videoOverlays.enabled = false;
      wizard.videoOverlays.timelineId = videoOverlayTimeline.id;
    } else {
      wizard.videoOverlays.visible = false;
      wizard.videoOverlays.enabled = false;
      wizard.videoOverlays.timelineId = null;
    }

    wizard.watermark = {
      visible: !!this.source.globalSettings.watermarkLayer,
    };
  }

  private addLayoutPlaceholders(sectionId: string, layoutType: LayoutType) {
    const placeholderAssetIds = [];

    const placeholderSettings = this.source.features.layouts.placeholders.mainClips;
    switch (layoutType) {
      case 'simple':
        if (placeholderSettings.simpleAssetId) {
          placeholderAssetIds.push(placeholderSettings.simpleAssetId);
        }
        break;
      case 'interview':
        placeholderAssetIds.push(...placeholderSettings.interviewAssetIds);
        break;
      case 'presentation':
        placeholderAssetIds.push(...placeholderSettings.presentationAssetIds);
        break;
    }

    if (placeholderAssetIds.length === 0) {
      return;
    }

    const sectionTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId);
    for (const { timelines } of sectionTimelines) {
      timelines.forEach((timeline, index) => {
        if (!placeholderAssetIds.length) {
          return;
        }

        const placeholderAssetId = placeholderAssetIds[index] || placeholderAssetIds[0];
        const placeholderAsset = this.source.assets.find((a) => a.id === placeholderAssetId);

        if (!placeholderAsset) {
          return;
        }

        const newClip: VideoLayer & LayerOptions = {
          layerId: uuidv4(),
          type: 'video',
          assetId: placeholderAssetId,
          visibility: {
            startAt: 0,
            endAt: placeholderAsset.data.duration,
          },
          styles: timeline.styles,
          bounds: timeline.bounds,
        };

        timeline.layers.push(newClip);

        this.recalculateVisibility(timeline);
      });
    }
  }

  //#endregion

  protected getSectionAssetIds(sectionId: string) {
    const section = this.source.sections[sectionId];

    return this.getTimelinesAssetIds(section.timelines);
  }

  protected getTimelinesAssetIds(timelines: Timeline[]): string[] {
    const assets: string[] = [];

    timelines
      .filter((t) => t.type === 'main' || t.type === 'b-roll')
      .forEach((timeline) => {
        timeline.layers.forEach((layer: VideoLayer | TimelinesLayer) => {
          if (layer.type === 'timelines') {
            assets.push(...this.getTimelinesAssetIds(layer.children));
            return;
          }

          assets.push(layer.assetId);
        });
      });

    return assets;
  }

  protected duplicateAssetsForSection(sectionId: string) {
    const section = this.source.sections[sectionId];

    this.duplicateAssetsForTimelines(section.timelines);
  }

  private duplicateAssetsForTimelines(timelines: Timeline[]) {
    const newAssets: Asset[] = [];

    timelines
      .filter((t) => t.type === 'main' || t.type === 'b-roll')
      .forEach((timeline) => {
        timeline.layers.forEach((layer: VideoLayer | TimelinesLayer) => {
          if (layer.type === 'timelines') {
            this.duplicateAssetsForTimelines(layer.children);
            return;
          }

          const asset = this.source.assets.find((a) => a.id === layer.assetId);
          if (asset.isGlobal || asset.isPlaceholder) {
            return;
          }

          const newAsset: Asset = {
            ...asset,
            id: uuidv4(),
            isGlobal: false,
            isPlaceholder: false,
          };

          newAssets.push(newAsset);
          layer.assetId = newAsset.id;
        });
      });

    this.source.assets.push(...newAssets);
  }

  protected randomizeSectionIds(sectionId: string) {
    const section = this.source.sections[sectionId];

    section.timelines.forEach((t) => {
      t.id = uuidv4();

      t.layers.forEach((l) => {
        l.layerId = uuidv4();
        if (l.type === 'timelines') {
          l.children.forEach((ct) => {
            ct.id = uuidv4();
            ct.layers.forEach((ctl) => (ctl.layerId = uuidv4()));
          });
        }
      });
    });
  }
}
