import { from } from 'rxjs';
import {
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video';
import {
  NextgenParticipant,
  PartialNextgenParticipant,
} from '../../interfaces';
import { ParticipantMapper, StreamMapper } from './participant-mapper';

export class TwilioParticipantMapper
  extends ParticipantMapper
  implements StreamMapper
{
  public connected = true;
  get initialLocal(): NextgenParticipant {
    return {
      ...this.initialParticipant,
      audioOutputEnabled: true,
      identity: this.room.localParticipant.identity,
      isIosDevice: this.room.localParticipant.identity.split('_')[0] === 'ios',
    };
  }

  get initialRemote(): NextgenParticipant[] {
    if (!this.room.participants.size) return null;
    const result: NextgenParticipant[] = [];
    this.room.participants.forEach((participant) => {
      const audioTracks: MediaStreamTrack[] = [];
      const videoTracks: MediaStreamTrack[] = [];
      participant.audioTracks.forEach((track) =>
        audioTracks.push(track.track?.mediaStreamTrack)
      );
      participant.videoTracks.forEach((track) =>
        videoTracks.push(track.track?.mediaStreamTrack)
      );
      result.push({
        ...this.initialParticipant,
        identity: participant.identity,
        isIosDevice: participant.identity.split('_')[0] === 'ios',
        isMuted: !audioTracks.some((track) => track?.enabled),
        isVideoDisabled: !videoTracks.some((track) => track?.enabled),
        audioTracks,
        videoTracks,
      });
    });
    return result;
  }

  constructor(private room: Room) {
    super();
    this._participantConnectedCreator(room);
    room.on('disconnected', (_room, error) => {
      this.connected = false;
      this._disconnected.next(error?.message);
    });
    room.on('reconnected', () => {
      this.connected = true;
      this._reconnected.next();
    });
    room.on('reconnecting', (error) => this._reconnecting.next());

    room.on('participantDisconnected', (participant) => {
      this._participantEvent.next({
        identity: participant.identity,
        isConnected: false,
      });
    });
    room.on('participantReconnecting', (participant) =>
      this._participantReconnecting.next(this.makeId(participant.identity))
    );
    room.on('participantReconnected', (participant) => {
      this._participantEvent.next({
        identity: participant.identity,
        isConnected: true,
      });
    });

    room.on('trackEnabled', (publication, participant) =>
      this._trackPublication(publication.track, participant)
    );
    room.on('trackPublished', (publication, participant) =>
      this._trackPublication(publication.track, participant)
    );
    room.on('trackUnpublished', (publication, participant) =>
      this._trackPublication(publication.track, participant)
    );
    room.on('trackSubscribed', (track, publication, participant) =>
      this._trackPublication(track, participant)
    );
    room.on('trackStarted', this._trackPublication);
    room.on('trackUnsubscribed', (track, publication, participant) => {
      this._trackPublication(track, participant, false);
    });
    room.on('trackSwitchedOn', (track, publication, participant) =>
      this._trackPublication(track, participant)
    );
    room.on('trackSwitchedOff', (track, publication, participant) =>
      this._trackPublication(track, participant, false)
    );
    room.on('trackDisabled', (publication, participant) =>
      this._trackPublication(publication.track, participant)
    );
    room.on('dominantSpeakerChanged', (participant) => {
      this._dominantSpeakerEvent.next(participant?.identity);
    });
    room.localParticipant.on(
      'networkQualityLevelChanged',
      (networkQualityLevel) =>
        this._networkQualityChange.next(networkQualityLevel)
    );
    room.localParticipant.on('trackPublished', (localTrackPublication) => {
      this._localTrackPublished.next({
        type: localTrackPublication.kind,
        id: '',
      });
    });
    //Fire initial dominant speaker
    this.initDominantSpeaker();
    this._connected.next();
  }

  private _participantConnectedCreator(room: Room) {
    room.on('participantConnected', (participant) => {
      const audioTracks: MediaStreamTrack[] = [];
      const videoTracks: MediaStreamTrack[] = [];
      participant.audioTracks.forEach((track) => {
        if (track.track?.mediaStreamTrack)
          audioTracks.push(track.track.mediaStreamTrack);
      });
      participant.videoTracks.forEach((track) => {
        if (track.track?.mediaStreamTrack)
          videoTracks.push(track.track.mediaStreamTrack);
      });
      this._participantConnected.next({
        ...this.initialParticipant,
        identity: participant.identity,
        isIosDevice: participant.identity.split('_')[0] === 'ios',
        audioTracks,
        videoTracks,
      });
    });
  }

  public toggleMuteStatus(status: boolean) {
    this.room.localParticipant.audioTracks.forEach((track) => {
      if (status) track.track.disable();
      else track.track.enable();
    });
  }

  public toggleVideoEnabledStatus(status: boolean) {
    this.room.localParticipant.videoTracks.forEach((track) => {
      if (status) track.track.disable();
      else track.track.enable();
    });
  }

  public disconnectRoom() {
    this.room?.disconnect();
  }

  public publishVideoTrack(videoSource: MediaStreamTrack) {
    const hasTracks = this.room.localParticipant.videoTracks.size > 0;
    if (hasTracks) {
      this.room.localParticipant.unpublishTracks(
        Array.from(this.room.localParticipant.videoTracks.values()).map(
          (pub) => pub.track
        )
      );
    }
    return from(this.publishTrackToSession(videoSource));
  }
  public publishAudioTrack(audioSource: MediaStreamTrack) {
    const hasTracks = this.room.localParticipant.audioTracks.size > 0;
    if (hasTracks) {
      this.stopAllAudioTracks();
      this.room.localParticipant.unpublishTracks(
        Array.from(this.room.localParticipant.audioTracks.values()).map(
          (pub) => pub.track
        )
      );
    }
    return from(this.publishTrackToSession(audioSource));
  }
  public unpublishVideo() {
    const hasTracks = this.room.localParticipant.videoTracks.size > 0;
    if (hasTracks) {
      this.room.localParticipant.unpublishTracks(
        Array.from(this.room.localParticipant.videoTracks.values()).map(
          (pub) => pub.track
        )
      );
    }
  }

  public unpublishAudio() {
    const hasTracks = this.room.localParticipant.audioTracks.size > 0;
    if (hasTracks) {
      this.room.localParticipant.unpublishTracks(
        Array.from(this.room.localParticipant.audioTracks.values()).map(
          (pub) => pub.track
        )
      );
    }
  }
  private publishTrackToSession(track: MediaStreamTrack) {
    return this.room.localParticipant.publishTrack(track);
  }

  private stopAllAudioTracks() {
    Array.from(this.room.localParticipant.audioTracks.values()).forEach((t) => {
      t.track.stop();
    });
  }

  private _trackPublication = (
    remoteTrack: RemoteTrack,
    participant: RemoteParticipant,
    isVideoEnabled = true
  ) => {
    if (!remoteTrack) return;
    const partial: PartialNextgenParticipant = {
      identity: participant.identity,
    };
    const tracks = [
      (<RemoteAudioTrack | RemoteVideoTrack>remoteTrack)?.mediaStreamTrack,
    ];
    tracks.forEach((track) => (track.enabled = remoteTrack.isEnabled)); //update track enabled status

    switch (remoteTrack.kind) {
      case 'audio':
        partial.audioTracks = tracks;
        partial.isMuted = !remoteTrack.isEnabled;
        break;
      case 'video':
        partial.videoTracks = tracks;
        partial.isVideoDisabled = !isVideoEnabled;
        break;
      default:
        console.warn('Unsopported track kind');
        return;
    }
    this._participantEvent.next(partial);
  };

  private initDominantSpeaker() {
    if (this.room.dominantSpeaker) {
      this._dominantSpeakerEvent.next(this.room.dominantSpeaker.identity);
    }
  }
}
