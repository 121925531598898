import { fabric } from 'fabric';
import { IGroupOptions } from 'fabric/fabric-impl';
import { CanvasConfig, SectionConfig } from '../canvas.config';
import { SectionType } from '../canvas.interfaces';

const FABRIC_TYPE = 'section';

export interface SectionDataOptions {
  config: CanvasConfig;
  sectionType: SectionType;
}

export interface SectionOptions extends IGroupOptions {
  data: SectionDataOptions;
}

export class Section extends fabric.Group {
  type: typeof FABRIC_TYPE;

  sectionRect: fabric.Rect;
  sectionIcon: fabric.Group | fabric.Object;

  data: SectionDataOptions;

  private sectionConfig: SectionConfig;

  constructor(options: SectionOptions) {
    const objects = [];

    const sectionConfig =
      options.data.config.sections[options.data.sectionType];

    objects.push(
      new fabric.Rect({
        fill: sectionConfig.backgroundColorLight,
        strokeWidth: 0,
        hasControls: false,
        selectable: false,
        hoverCursor: 'default',
        rx: 4,
        ry: 4,
      })
    );

    super(objects, { ...options, type: FABRIC_TYPE });

    this.sectionConfig = sectionConfig;
    this.sectionRect = this.item(0) as fabric.Rect;

    this.addSectionIcon();
  }

  render(ctx: CanvasRenderingContext2D) {
    if (this.visible) {
      this.sectionRect.set({
        left: -this.width / 2,
        top: -this.height / 2,
        width: this.width,
        height: this.height,
      });
      this.sectionRect.setCoords();

      if (this.sectionIcon) {
        this.sectionIcon.set({
          left: 0 - this.sectionIcon.width / 2,
          top: 0 - this.sectionIcon.height / 2,
          opacity:
            this.width > this.data.config.sectionOptions.minWidthToShowIcon
              ? 1
              : 0,
        });
        this.sectionIcon.setCoords();
      }
    }

    super.render(ctx);
  }

  private addSectionIcon() {
    if (!this.sectionConfig.icon) {
      return;
    }

    const url = `assets/ui/material-icons/${this.sectionConfig.icon}.svg`;
    fabric.Image.fromURL(url, (sectionIcon) => {
      this.sectionIcon = sectionIcon;

      this.sectionIcon.set({
        name: `${this.name}_icon`,
        hasControls: false,
        selectable: false,
        hoverCursor: 'default',
        left: 0,
        top: 0,
        opacity:
          this.width > this.data.config.sectionOptions.minWidthToShowIcon
            ? 1
            : 0,
      });
      this.sectionIcon.setCoords();

      this.add(this.sectionIcon);
      this.canvas.requestRenderAll();
    });
  }
}
