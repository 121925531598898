import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SimpleChatMessage } from '../chat.interface';
import { Conversation } from 'libs/common/src/components/chat/conversation.interface';
import { BubbleChatService } from './bubble-chat.service';
import { noWhitespaceValidator } from 'libs/common/src/form.util';

@Component({
  selector: 'openreel-bubble-chat',
  templateUrl: './bubble-chat.component.html',
  styleUrls: ['./bubble-chat.component.scss'],
})
export class BubbleChatComponent implements OnChanges {
  @Input()
  myId: number;

  @Input()
  conversation: Conversation;

  @ViewChild('chatBody')
  chatBody: ElementRef<HTMLDivElement>;

  messageForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly bubbleChatService: BubbleChatService
  ) {
    this.messageForm = this.formBuilder.group({
      message: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversation) {
      this.scrollToBottom();
    }
  }

  close(): void {
    this.bubbleChatService.toggleSelectConversation(null);
  }

  isSenderMe(message: SimpleChatMessage): boolean {
    return this.myId === message.sender;
  }

  sendMessage() {
    if (this.messageForm.valid) {
      this.bubbleChatService.sendMessage(
        this.messageForm.value.message,
        this.conversation.id
      );
      this.messageForm.reset();
    }
  }

  onTextChange() {
    this.bubbleChatService.onType();
  }

  paginateChat() {
    if (this.chatBody.nativeElement.scrollTop === 0) {
      this.bubbleChatService.paginateChat();
    }
  }

  handlePropagation(event: Event) {
    event.stopPropagation();
  }

  private scrollToBottom() {
    setTimeout(() => {
      if (this.chatBody) {
        this.chatBody.nativeElement.scrollTo(
          0,
          this.chatBody.nativeElement.scrollHeight
        );
      }
    }, 0);
  }
}
