import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';
import { IntegrationResponse } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  public urls = {
    getIntegrations:
      commonenv.nextGenApiUrl + 'company/statistics/integrations',
  };
  constructor(public httpClient: HttpClient) {}

  getAccountIntegrations() {
    return this.httpClient.get<IntegrationResponse>(this.urls.getIntegrations);
  }
}
