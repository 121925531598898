import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'clipSize' })
export class ClipSize implements PipeTransform {

  transform(mbs: number) {
    if (mbs >= 1024) {
      return Math.round(mbs / 1024) + 'gb';
    }
    if (mbs < 1) {
      return Math.round(mbs * 1024) + 'kb';
    }
    return Math.round(mbs) + 'mb';
  }
}
