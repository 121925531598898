import { WorkflowDataDto, TrimmerData } from '@openreel/creator/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common';
import { TrimData } from '../../../timelines-canvas/services/canvas.service';
import { UpdateTrimBoundsEvent } from '../../../timelines-canvas/canvas.interfaces';
import { ProjectFacade } from '../../../../store/facades/project.facade';
import { merge } from 'lodash-es';
import { TrimmerPreviewComponent } from '../../components/trimmer-preview/trimmer-preview.component';

export interface TrimmerOptions {
  allowSplits: boolean;
  showTrimButton: boolean;
  trimButtonCaption: string;
  showCancelButton: boolean;
  cancelButtonCaption: string;
}

const DEFAULT_TRIMMER_OPTIONS: Partial<TrimmerOptions> = {
  allowSplits: true,
  showTrimButton: true,
  trimButtonCaption: 'Trim Video',
  showCancelButton: false,
  cancelButtonCaption: 'Cancel',
};

@Component({
  selector: 'openreel-wf-trimmer',
  templateUrl: './trimmer.component.html',
  styleUrls: ['./trimmer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrimmerComponent extends Cleanupable implements OnInit, OnChanges {
  @Input() data: TrimmerData;
  @Input() options: Partial<TrimmerOptions>;
  @Input() loading = false;

  @Output() changed = new EventEmitter<TrimmerData>();
  @Output() done = new EventEmitter<TrimmerData>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild(TrimmerPreviewComponent, { static: true })
  preview: TrimmerPreviewComponent;

  trimData: TrimData[][] = [];

  parsedOptions: Partial<TrimmerOptions>;

  isPreviewLoaded = false;

  loadedProjectWorkflow: WorkflowDataDto;

  constructor(private readonly projectFacade: ProjectFacade, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.parsedOptions = merge({}, DEFAULT_TRIMMER_OPTIONS, this.options);

    this.projectFacade.workflow$.pipe(first()).subscribe((workflow) => (this.loadedProjectWorkflow = workflow));

    this.initTrimmer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes && !changes['data'].isFirstChange()) {
      this.initTrimmer();
    }
  }

  onPreviewLoaded = () => (this.isPreviewLoaded = true);

  onTrim(splitIndex: number, { from, to, done }: UpdateTrimBoundsEvent) {
    this.data.clips.forEach(({ duration, splits }) => {
      splits[splitIndex] = { startAt: from, endAt: Math.min(duration, to) };
    });

    this.updateTrimmerData();
    if (done) {
      this.emitChange();
    }
  }

  onAddSplit() {
    this.addSplit();
    this.emitChange();
  }

  onRemoveSplit(index: number) {
    if (this.data.splitCount === 1) {
      return;
    }

    this.removeSplit(index);
    this.emitChange();
  }

  save() {
    this.done.emit(this.data);
  }

  onCancel() {
    this.cancel.emit();
  }

  private initTrimmer() {
    this.isPreviewLoaded = false;
    if (this.data.splitCount === 0) {
      this.addSplit();
    }

    this.updateTrimmerData();
    this.cdr.markForCheck();
  }

  private updateTrimmerData() {
    let trimData: TrimData[][] = [];
    if (this.data.splitCount === 0) {
      this.trimData = trimData;
      return;
    }

    trimData = Array(this.data.splitCount)
      .fill(null)
      .map((_, splitIndex) =>
        this.data.clips.map((clip, index) => ({
          timelineIndex: index,
          from: clip.splits[splitIndex].startAt,
          to: clip.splits[splitIndex].endAt,
        }))
      );

    this.trimData = trimData;
  }

  private addSplit() {
    this.data.splitCount++;
    this.data.clips.forEach((clip) => clip.splits.push({ startAt: 0, endAt: clip.duration }));
  }

  private removeSplit(index: number) {
    this.data.splitCount--;
    this.data.clips.forEach((clip) => clip.splits.splice(index, 1));
  }

  private emitChange() {
    this.preview.update();
    this.changed.emit(this.data);
  }
}
