import { AppState } from '..';
import { createSelector } from '@ngrx/store';

const assetCacheRootState = (state: AppState) => state.assetCache;

export const cachedAssets = createSelector(
  assetCacheRootState,
  ({ assets }) => assets
);

export const transcodingThumbnails = createSelector(
  assetCacheRootState,
  ({ thumbnails }) => thumbnails.transcoding
);

export const thumbnailSpritesheets = createSelector(
  assetCacheRootState,
  ({ thumbnails }) => thumbnails.spritesheets
);
