<mat-list-item class="or-list-view-item">
  <div style="width: 55%;" fxLayout fxLayoutAlign="start center">
    <img
      style="width: 77px; height: 42px; border-radius: 4px; margin-right: 20px;"
      [src]="listdata.img"
      [openreelFallback]="'assets/common/images/placeholder.jpg'"
    />
    <div style="font-size: 14px;">{{ listdata.name }}</div>
    <div class="schedule-date" *ngIf="listdata.status">
      {{ listdata.status }}
    </div>
  </div>
  <div style="width: 45%;" fxLayout fxLayoutAlign="space-between">
    <div fxLayout>
      <ngx-avatar
        [name]="listdata.author"
        *ngIf="!shwimg"
        bgColor="#3546ff"
        size="20"
      ></ngx-avatar>
      <img
        (error)="shwimg = false"
        *ngIf="shwimg"
        style="height: 20px; width: 20px;"
        mat-card-avatar
        [src]="listdata.avator"
        alt="User Avatar"
      />

      <span style="margin-left: 5px; font-size: 12px; color: #b0bac9;">{{
        listdata.author
      }}</span>
    </div>
    <span class="crd-icon-container" style="border: none;">
      <mat-icon>calendar_today</mat-icon>
      {{ listdata.date | date: 'MMM dd' }}
    </span>
    <span class="crd-icon-container">
      <mat-icon>group</mat-icon>
      /
      {{ listdata.totalUsers }}
    </span>
    <span class="crd-icon-container">
      <mat-icon>radio</mat-icon>
      /
      {{ listdata.totalVideos }}
    </span>
  </div>
</mat-list-item>
