<div
  #gainWrapper
  (resized)="onResizeWrapper()"
  class="gain-wrapper"
  [class.vertical]="dragAxis === DragAxis.Y"
  [class.horizontal]="dragAxis === DragAxis.X"
  (mousedown)="stopDragPropagation($event)"
  (mouseup)="stopDragPropagation($event)"
>
  <mat-icon
    class="drag-icon"
    [title]="gainTitle + ' ' + mobileAudioGainLabel"
    #gainControl
    [ngStyle]="dragAxis === DragAxis.Y && { transform: 'rotate(-90deg)' }"
    svgIcon="gain-control"
    cdkDrag
    lockAxis="dragAxis === DragAxis.X ? DragAxis.Y : DragAxis.X"
    (cdkDragEnded)="onDragEnd($event)"
    [cdkDragFreeDragPosition]="position"
    cdkDragBoundary=".gain-wrapper"
  ></mat-icon>
</div>
