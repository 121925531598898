import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(private authService: AuthService, public router: Router) {}

  canActivate() {
    return Promise.all([
      navigator.permissions.query({ name: 'camera' }),
      navigator.permissions.query({ name: 'microphone' }),
    ]).then(([{ state: cameraState }, { state: microphoneState }]) => {
      console.log('Perm', cameraState, microphoneState);
      if (cameraState === 'granted' && microphoneState === 'granted') {
        return true;
      } else {
        this.router.navigate(['permissions']);
      }
    });
  }
}
