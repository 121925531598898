import { DrawDataService } from './draw-data.service';
import { Inject, Injectable } from '@angular/core';
import { CanvasConfig } from '../canvas.config';
import { fabric } from 'fabric';
import { CANVAS_CONFIG_TOKEN } from '../components/timelines-canvas/timelines-canvas.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cleanupable } from '@openreel/common';
import { AssetId } from '@openreel/creator/common';

@Injectable()
export class ThumbnailsService extends Cleanupable {
  private spritesheets: Map<AssetId, HTMLImageElement>;

  private canvasConfig: CanvasConfig;

  constructor(
    private readonly drawDataService: DrawDataService,
    @Inject(CANVAS_CONFIG_TOKEN)
    private readonly canvasConfig$: Subject<CanvasConfig>
  ) {
    super();

    this.canvasConfig$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config) => (this.canvasConfig = config));
  }

  setSpritesheets(spritesheets: Map<AssetId, HTMLImageElement>) {
    this.spritesheets = spritesheets;
  }

  generateThumbs(layerId: string) {
    if (!this.spritesheets) {
      return [];
    }

    const layerInfo = this.drawDataService.get(layerId);
    const spritesheet = this.spritesheets.get(layerInfo.video.id);
    if (!spritesheet) {
      return [];
    }

    const thumbWidth = spritesheet.width / this.canvasConfig.thumbnails.spritesheet.thumbsPerRow;
    const areaForThumbnailsWidth = layerInfo.bounds.width - this.canvasConfig.thumbnails.padding.x * 2;

    const thumbCount = areaForThumbnailsWidth / thumbWidth;

    const thumbIndicesToShow = this.getThumbIxs(thumbCount, layerInfo.timing.duration, layerInfo.video.trimFrom ?? 0);

    const result = [];
    thumbIndicesToShow.forEach((thumbIndex) => {
      result.push(this.getThumbFabricImage(spritesheet, thumbIndex));
    });

    return result;
  }

  private getThumbIxs(visibleCnt: number, videoDurationMs: number, trimFromMs: number) {
    const thumbsCount = Math.floor(videoDurationMs / 1000);
    const skipThumbs = Math.floor(trimFromMs / 1000);

    const indicies = [skipThumbs];
    for (let i = 1; i < visibleCnt - 1; i++) {
      indicies.push(Math.floor(skipThumbs + (thumbsCount / visibleCnt) * i));
    }

    if (visibleCnt > 1) {
      const endThumbIndex = thumbsCount > 0 ? skipThumbs + thumbsCount - 1 : skipThumbs;
      indicies.push(endThumbIndex);
    }

    return indicies;
  }

  private getThumbFabricImage(spritesheet: HTMLImageElement, index: number) {
    const thumbWidth = spritesheet.width / this.canvasConfig.thumbnails.spritesheet.thumbsPerRow;

    const cropX = (index % this.canvasConfig.thumbnails.spritesheet.thumbsPerRow) * thumbWidth;
    const cropY =
      Math.floor(index / this.canvasConfig.thumbnails.spritesheet.thumbsPerRow) * this.canvasConfig.thumbnails.height;

    return new fabric.Image(spritesheet, {
      left: 0,
      top: 0,
      width: thumbWidth,
      height: this.canvasConfig.thumbnails.height,
      cropX,
      cropY,
      selectable: false,
      hasBorders: false,
      hoverCursor: 'pointer',
    });
  }
}
