import { Component, Input } from '@angular/core';
import { S3IntegrationStatus } from '../../interfaces/integration.interface';

@Component({
  selector: 'openreel-title-line',
  templateUrl: './title-line.component.html',
  styleUrls: ['./title-line.component.scss']
})
export class TitleLineComponent {
  @Input()
  title: string;
  @Input()
  titleAlign: 'start' | 'center' | 'end' = 'start';

  @Input() s3IntegrationStatus: S3IntegrationStatus;

  @Input() accountDestinations: string[];

  isOpen: boolean;

}
