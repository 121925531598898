import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UploadService } from '../../services/upload/upload.service';
import {
  VideoUploadStatus,
  WEB_VIDEO_UPLOAD_STATUS,
} from '../../interfaces/socket-events';
import { MyIdentitySendType } from '../../interfaces/socket-lib-service.interface';
import { UploadFileInfo, MultiPartUploadInfo } from '../../services/upload/dto/upload.dto';
import { Cleanupable } from '../../classes';
import { Subscription, merge, combineLatest, of, Observable, EMPTY } from 'rxjs';
import { map, switchMap, tap, shareReplay, filter, withLatestFrom } from 'rxjs/operators';
import { DirectorSocketService } from '../../services/socket/director-socket.service';
import { SubjectSocketService } from '../../services/socket/subject-socket.service';
import { UserRoleType } from '../../interfaces';
import {MultiPartUploader} from '../../services/upload/multi-part-uploader';

@Component({
  selector: 'openreel-upload-indicator',
  templateUrl: './upload-indicator.component.html',
  styleUrls: ['./upload-indicator.component.scss'],
})
export class UploadIndicatorComponent
  extends Cleanupable
  implements OnInit, OnDestroy {
  isNaN = isNaN;
  public show = true;
  isSubject = false;
  public uploads: UploadFileInfo[];

  @Input()
  isConnected$: Observable<boolean>;

  mpUploads$: Observable<
    MultiPartUploadInfo[]
  > = this.uploadService.mpuQueue$.pipe(
    switchMap((mpus) =>
      !mpus.length
        ? of(mpus)
        : combineLatest(
            mpus.map((mpu) =>
              combineLatest([mpu.uploader.isOpen$, mpu.uploader.hasError$]).pipe(
                map(([open, hasError]) => ({ open, hasError, mpu }))
              )
            )
          ).pipe(
            map((res) => res.filter((r) => !r.open || r.hasError).map((r) => r.mpu)),
            shareReplay(1)
          )
    )
  );

  constructor(
    public uploadService: UploadService,
    private subjectSocket: SubjectSocketService,
    private directorSocket: DirectorSocketService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.currentlyUploadingVideos(),
      this.mpUploads$
        .pipe(
          switchMap((mpus) =>
            !mpus.length
              ? EMPTY
              : merge(
                  ...mpus.map((mpu) => {
                    this.isSubject = mpu.metadata.role === UserRoleType.Subject;
                    return combineLatest([mpu.uploader.progress$, mpu.uploader.hasError$]).pipe(
                      filter(([, hasError]) => !hasError),
                      map(([progress]) => ({
                        progress,
                        videoId: mpu.metadata.videoId,
                      }))
                    );
                  })
                )
          )
        )
        .subscribe((uploadInfo) => {
          this.sendUploadProgressViaSocket({
            videoid: uploadInfo.videoId,
            upload_percentage: uploadInfo.progress * 100,
          });
        })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  retryUpload(mpu: MultiPartUploader) {
    mpu.retryUpload();
  }
  
  abortUpload(mpu: MultiPartUploader) {
    mpu.abortUpload();
  }

  private currentlyUploadingVideos(): Subscription {
    return this.uploadService.queue$
      .pipe(
        withLatestFrom(this.isConnected$),
        filter(([, isConnected]) => isConnected),
        map(([uploads]) => uploads.slice(0, 5)),
        tap((uploads) => {
          this.uploads = uploads;
        }),
        map((uploads) =>
          uploads.map((upload) => {
            this.isSubject = upload.metadata.role === UserRoleType.Subject;
            return upload.status$.pipe(
              map((status) => ({
                videoId: upload.metadata.videoId,
                status,
              }))
            );
          })
        ),
        switchMap((uploadFileNetWorkStatus) =>
          merge(...uploadFileNetWorkStatus)
        ),
      )
      .subscribe((uploadFilePercentInfo) => {
        this.sendUploadProgressViaSocket({
          videoid: uploadFilePercentInfo.videoId,
          upload_percentage: uploadFilePercentInfo.status.percentage * 100,
        });
      });
  }

  private sendUploadProgressViaSocket(videoUploadInfo: VideoUploadStatus) {
    if (this.isSubject) {
      this.subjectSocket.emitSocket({
        eventName: WEB_VIDEO_UPLOAD_STATUS,
        data: videoUploadInfo,
      });
    } else {
      this.directorSocket.emitSocket(
        WEB_VIDEO_UPLOAD_STATUS,
        videoUploadInfo,
        MyIdentitySendType.NO_IDENTITY
      );
    }
  }
}
