import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';

import { ProjectFacade } from '../../store/facades/project.facade';

@Injectable()
export class LoadProjectByIdGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly projectFacade: ProjectFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const id = +route.params.id;
    if (!Number.isInteger(Number(id))) {
      this.router.navigate(['/']);
      return;
    }

    return this.projectFacade.id$.pipe(
      first(),
      map((storeProjectId) => {
        if (storeProjectId !== id) {
          this.projectFacade.loadProject(id);
        }

        return true;
      })
    );
  }
}
