import * as uuid from 'uuid';
import { AssetId } from '../interfaces';
import { BaseCommand } from './base.command';

export class GlobalSettingsBackgroundAddCommand extends BaseCommand<{ assetFileId: AssetId, fileName: string }> {
  run({ assetFileId, fileName }) {
    this.source.globalSettings.backgroundVideo.uploaded.push({
      id: uuid.v4(),
      file: {
        path: assetFileId,
        provider: 'or-assets',
      },
      fileName,
      type: 'clip',
    });

    return this.ok();
  }
}
