import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { UiModule } from '@openreel/ui';
import { OpenreelAlertModule } from '@openreel/ui/openreel-alert/openreel-alert.module';
import { UserUploadSelectorComponent } from './components/user-upload-selector/user-upload-selector.component';
import { UserUploadDialogComponent } from './components/user-upload-dialog/user-upload-dialog.component';
import { OpenreelCommonDirectivesModule } from '../directives/openreel-common-directives.module';
import { OpenreelUploaderModule } from '@openreel/ui/openreel-uploader';

const PRIVATE_COMPONENTS = [UserUploadDialogComponent];

const PUBLIC_COMPONENTS = [UserUploadSelectorComponent];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    UiModule,
    ToastrModule,
    OpenreelAlertModule,
    OpenreelCommonDirectivesModule,
    OpenreelUploaderModule,
  ],
  declarations: [...PUBLIC_COMPONENTS, ...PRIVATE_COMPONENTS],
  exports: [...PUBLIC_COMPONENTS],
})
export class UserUploadModule {}
