<div mat-dialog-title>
  <div class="image-wrapper">
    <div class="image-circle-light">
      <div class="image-circle">
        <mat-icon
          svgIcon="check-circle"
          class="image"
        >
        </mat-icon>
      </div>
    </div>
  </div>
  <p class="title">
    Your video has been saved for later
  </p>
</div>
<mat-dialog-content>
  <p class="subtitle">
    Just to let you know your video has been saved for later inside your projects area.
  </p>
  <div
    class="btn-holder"
  >
    <button
      mat-raised-button
      (click)="onDialogCloseClicked(true)"
    >
      View projects
    </button>
    <button
      class="margin-left-12"
      mat-raised-button
      color="primary"
      (click)="onDialogCloseClicked(false)"
    >
      Continue
    </button>
  </div>
</mat-dialog-content>
