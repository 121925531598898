<form class="login-form" [formGroup]="myFormGroup">
  <span class="login-title">Create a password</span>
  <p class="sub-title-gray">Keep your account secure.</p>
  <p style="margin: 8px"></p>

  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input
      class="fs-exclude"
      formControlName="password"
      [errorStateMatcher]="matcher"
      matInput
      placeholder="Enter Password"
      [type]="passwordHide ? 'password' : 'text'"
      name="password"
    />
    <mat-icon
      style="cursor: pointer"
      matSuffix
      (click)="passwordHide = !passwordHide"
      >{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon
    >

    <mat-error *ngIf="formControl.password.hasError('required')"
      >Password is required</mat-error
    >
    <mat-error *ngIf="formControl.password.hasError('minlength')"
      >Password must be at least 12 characters</mat-error
    >
    <mat-error
      *ngIf="
        !formControl.password.hasError('minlength') &&
        formControl.password.hasError('pattern')
      "
      >Passwords should contain lower & upper case and a special
      character.</mat-error
    >
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Confirm Password</mat-label>
    <input
      class="fs-exclude"
      formControlName="confirmPassword"
      matInput
      placeholder="Re-enter Password"
      [errorStateMatcher]="matcher"
      [type]="passwordConfirmHide ? 'password' : 'text'"
      name="confirmPassword"
    />
    <mat-icon
      style="cursor: pointer"
      matSuffix
      (click)="passwordConfirmHide = !passwordConfirmHide"
      >{{ passwordConfirmHide ? 'visibility_off' : 'visibility' }}</mat-icon
    >
    <mat-error *ngIf="formControl.confirmPassword.hasError('required')"
      >Confirm Password is required</mat-error
    >
    <mat-error *ngIf="formControl.confirmPassword.hasError('mustMatch')"
      >Passwords must match</mat-error
    >
  </mat-form-field>

  <button
    class="mat-flat-button"
    (click)="onSubmit()"
    mat-flat-button
    color="primary"
  >
    Continue
  </button>
</form>
