import { CanvasEventsService } from '../../services/canvas-events.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Cleanupable, WorkflowProjectSocketService } from '@openreel/common';
import {
  Durations,
  ItemMovedEvent,
  TimelinesWithOptions,
  AssetsFileProviderType,
  WorkflowDataDto,
  Timeline,
  TimelineItem,
} from '@openreel/creator/common';
import { Subject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';

import { AssetsCacheFacade } from '../../../../store/facades/asset-cache.facade';
import { CanvasService, CanvasCreateOptions, TrimData } from '../../services/canvas.service';
import { DragService } from '../../interactions/drag.service';
import { DrawDataService } from '../../services/draw-data.service';
import { ScrollService } from '../../interactions/scroll.service';
import { SelectionService } from '../../interactions/selection.service';
import { StretchService } from '../../interactions/stretch.service';
import { ThumbnailsService } from '../../services/thumbnails.service';
import { TimelinesFacade } from '@openreel/creator/app/store/facades/timelines.facade';
import { cloneDeep, isEqual, isNumber } from 'lodash-es';
import { mapTimelinesDataToCanvasData } from '../../helpers';
import { ViewportService } from '@openreel/creator/app/common/timelines/services/viewport.service';
import { CanvasConfig, defaultConfig, trimmerConfig } from '../../canvas.config';
import { FabricService } from '../../services/fabric.service';
import { v4 as uuidv4 } from 'uuid';
import { DropItemEvent, UpdateTrimBoundsEvent } from '../../canvas.interfaces';

export const CANVAS_CONFIG_TOKEN = new InjectionToken<Subject<CanvasConfig>>('CANVAS_CONFIG_TOKEN');

@Component({
  selector: 'openreel-wf-timelines-canvas',
  templateUrl: './timelines-canvas.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CANVAS_CONFIG_TOKEN,
      useValue: new Subject<CanvasConfig>(),
    },
    CanvasService,
    CanvasEventsService,
    DragService,
    StretchService,
    ScrollService,
    DrawDataService,
    SelectionService,
    ThumbnailsService,
    FabricService,
  ],
})
export class TimelinesCanvasComponent extends Cleanupable implements OnInit, OnChanges, OnDestroy {
  @Input() currentTime: number;
  @Input() timelines$: Observable<TimelinesWithOptions>;
  @Input() workflow$: Observable<WorkflowDataDto>;
  @Input() durations$: Observable<Durations>;
  @Input() trimmers: TrimData[];

  @Input() canvasCreateOptions: Partial<CanvasCreateOptions>;

  @Output() updateTrimmer = new EventEmitter<UpdateTrimBoundsEvent>();
  @Output() seekTime = new EventEmitter<number>();

  @ViewChild('canvasContainer', { static: true })
  canvasContainer: ElementRef<HTMLElement>;

  canvasId = uuidv4();

  private dataDict = new Map<string, { timeline: Timeline; item: TimelineItem }>();

  private viewportStartAtMs: number = null;
  private viewportEndAtMs: number = null;

  private durations: Durations = null;

  private readonly timelinesWidthChanged = new Subject<void>();
  private readonly timelinesResizeObserver = new ResizeObserver(() => this.timelinesWidthChanged.next());

  constructor(
    @Inject(CANVAS_CONFIG_TOKEN)
    private readonly canvasConfig$: Subject<CanvasConfig>,
    private readonly timelinesFacade: TimelinesFacade,
    private readonly assetsCacheFacade: AssetsCacheFacade,
    private readonly viewportService: ViewportService,
    private readonly canvasService: CanvasService,
    private readonly canvasEventsService: CanvasEventsService,
    private readonly workflowProjectSocketService: WorkflowProjectSocketService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.setupConfig();

    // NOTE: setTimeout is for fabricjs to pick up dynamically assigned ID to canvas element
    setTimeout(() => {
      this.canvasService.initCanvas(this.canvasId, this.canvasCreateOptions);
      this.canvasService.setWidth(this.canvasContainer.nativeElement.clientWidth);

      this.setupResizeObserver();
      this.registerCanvasDataListeners();
      this.registerCanvasViewportListeners();
      this.registerTimelineDataListeners();
      this.registerTrimmerListeners();
      this.registerViewportListeners();
      this.registerThumbnailSpritesheetListeners();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('currentTime' in changes && !changes['currentTime'].isFirstChange()) {
      this.canvasService.setCurrentTime(this.currentTime);
    }

    if ('trimmers' in changes) {
      this.canvasService.setTrimmers(this.trimmers);
    }
  }

  ngOnDestroy() {
    this.timelinesResizeObserver.disconnect();
    super.ngOnDestroy();
  }

  updateViewPort() {
    const viewportDuration = this.viewportEndAtMs - this.viewportStartAtMs;
    this.canvasService.setViewport(this.viewportStartAtMs, viewportDuration);
  }

  private setupConfig() {
    const config: CanvasConfig = {
      ...defaultConfig,
      ...(this.canvasCreateOptions?.showTrimmers ? trimmerConfig : {}),
    };

    this.canvasConfig$.next(config);
  }

  private registerCanvasDataListeners() {
    this.canvasEventsService.drop$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((changes: DropItemEvent[]) => {
      const items: ItemMovedEvent[] = [];

      changes.forEach(({ layerId, newStartAt }) => {
        const cachedItem = this.dataDict.get(layerId);
        const item = cachedItem.item;

        items.push({
          layerId: item.layerId,
          newStartAt: newStartAt,
        });
      });

      this.timelinesFacade.moveItems({ items });
    });

    this.canvasEventsService.stretch$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ layerId, newStartAtMs, newEndAtMs }) => {
        const item = this.dataDict.get(layerId);

        const newStartAt = item.item.startAt + newStartAtMs - item.item.startAt;
        const newEndAt = item.item.endAt + newEndAtMs - item.item.endAt;

        const isValidMove = newStartAt >= 0 && newEndAt <= this.durations.main;
        if (!isValidMove) {
          this.canvasService.redraw();
          return;
        }

        this.timelinesFacade.stretchTextOverlay({
          layerId,
          newStartAt,
          newEndAt,
        });
      });
  }

  private registerCanvasViewportListeners() {
    this.canvasEventsService.scroll$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((msToScroll) => {
      this.viewportService.scrollDelta(msToScroll / this.durations.total);
    });

    this.canvasEventsService.zoom$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ delta, targetPointMs }) => {
      this.viewportService.zoom(delta, targetPointMs === null ? null : targetPointMs / this.durations.total);
    });

    this.canvasEventsService.seek$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((seekMs) => this.seekTime.emit(seekMs));
  }

  private registerTimelineDataListeners() {
    combineLatest([this.durations$, this.timelines$, this.workflow$])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((x, y) => isEqual(x, y))
      )
      .subscribe(([durations, { hardReload, timelines }, workflow]) => {
        const videoDurationChanged = this.durations === null || this.durations.total !== durations.total;
        this.durations = durations;

        const clonedTimelines = cloneDeep(timelines);

        const { data, dataDict } = mapTimelinesDataToCanvasData(this.durations, clonedTimelines, workflow);
        this.dataDict = dataDict;

        this.loadThumbnailSpritesheets();

        this.canvasService.setData(data, this.durations.total, hardReload);

        if (videoDurationChanged) {
          this.viewportStartAtMs = 0;
          this.viewportEndAtMs = durations.total;

          // NOTE: when duration of the video changes, we zoom out since we dont know how to handle that in canvas
          this.viewportService.setViewport(0, 1);
          this.updateViewPort();
        }
      });
  }

  private registerTrimmerListeners() {
    this.canvasEventsService.trimBounds$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.updateTrimmer.emit(data));
  }

  private registerViewportListeners() {
    this.viewportService.viewport$.pipe(takeUntil(this.ngUnsubscribe), skip(1)).subscribe(({ from, to }) => {
      this.viewportStartAtMs = from * this.durations.total;
      this.viewportEndAtMs = to * this.durations.total;

      this.updateViewPort();
    });
  }

  private registerThumbnailSpritesheetListeners() {
    this.assetsCacheFacade.thumbnailSpritesheets$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((spritesheets) => {
      this.canvasService.setSpritesheets(spritesheets);
    });

    this.workflowProjectSocketService.thumbnailSpritesheets$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ videoId, signedUrl }) => {
        this.assetsCacheFacade.getSpritesheet(videoId, signedUrl);
      });
  }

  private setupResizeObserver() {
    this.timelinesResizeObserver.observe(this.canvasContainer.nativeElement);
    this.timelinesWidthChanged
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(15), skip(1))
      .subscribe(() => this.canvasService.setWidth(this.canvasContainer.nativeElement.clientWidth));
  }

  private loadThumbnailSpritesheets() {
    const videos = new Set(
      Array.from(this.dataDict.values())
        .filter(({ item }) => item.type === 'main' || item.type === 'b-roll')
        .map(({ item }) => item.asset.file)
    );

    videos.forEach((video) => {
      if (!isNumber(video.path)) {
        return;
      }

      return this.assetsCacheFacade.loadSpritesheet(video.path, video.provider as AssetsFileProviderType);
    });
  }
}
