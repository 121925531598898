import { TemplateSettingsDto, UpdateTemplateSettingsDto } from './template-settings.interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateSettingsService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/templates-settings`
  };

  constructor(private readonly httpClient: HttpClient) {}

  getTemplatesForAccount() {
    return this.httpClient.get<TemplateSettingsDto[]>(this.urls.root);
  }

  updateTemplateForAccount(templatesSettings: UpdateTemplateSettingsDto[]) {
    return this.httpClient.put<TemplateSettingsDto[]>(this.urls.root, templatesSettings);
  }

  sortByTemplateIdFn = (a: TemplateSettingsDto, b: TemplateSettingsDto) => {
    if (a.templateId > b.templateId) {
      return 1;
    } else if (a.templateId < b.templateId) {
      return -1;
    }
    return 0;
  }
}
