import { Inject, Injectable } from '@angular/core';
import { DirectorSelfRecordData, UserRoleType } from '../../interfaces';
import { ILocalRecorderService } from '../subject/local-recording/local-recorder-base.service';
import { SessionConfigService } from '../session/session-config.service';
import { Cleanupable } from '../../classes/cleanupable';
import { UploadService } from '../upload/upload.service';
import { SocketDirectorExtensionRecordingService } from './socket-extensions/socket-director-extension-recording.service';
import { RecordingMetadata } from '../upload/dto/upload.dto';
import { BehaviorSubject } from 'rxjs';
import { IDirectorSessionService } from '../../interfaces/session-state.interface';
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';

@Injectable()
export class DirectorRecordingService extends Cleanupable {
  private directorRecordingChange = new BehaviorSubject<boolean>(null);
  directorRecordingChange$ = this.directorRecordingChange.asObservable();
  constructor(
    @Inject('ISessionService') private sessionService: IDirectorSessionService,
    private recorder: ILocalRecorderService,
    private sessionConfigService: SessionConfigService,
    private upload: UploadService,
    private socketRecord: SocketDirectorExtensionRecordingService,
    private auth: AuthService
  ) {
    super();
    this.subscriptions.push(
      //on stop subject recording if any director recording is going on, stop the recording
      this.socketRecord.listenToRecordingStop().subscribe(async (data) => {
        const isRecording = await this.isRecording();
        if (isRecording && data.data) {
          this.handleDirectorStopRecording();
        }
      })
    );
  }
  selfRecordData: DirectorSelfRecordData;
  async handleDirectorStopRecording() {
    await this.recorder.stopRecording();
    this.directorRecordingChange.next(false);
    this.startDirectorUploading();
  }
  async startDirectorUploading() {
    this.sessionService.directorSessionDetails$.pipe(
      take(1)
    ).subscribe(async session => {
      const user = this.auth.getUserDetails();
      const metadata: RecordingMetadata = {
        sessionId: session.session_id,
        identity: this.selfRecordData.identity,
        localFileName: this.recorder.lastFileName,
        resolution: this.recorder.lastFileResolution,
        videoId: this.selfRecordData.videoId,
        fileNameForUpload: this.recorder.getFileNameForUpload(),
        token: user.auth_token,
        role: UserRoleType.Internal,
      };

      if (this.auth.isInternalUser()) {
        metadata.role = UserRoleType.Internal;
      } else {
        metadata.role = UserRoleType.Collaborator;
      }

      this.upload.saveRecordingMetadata(metadata);
      await this.upload.uploadVideoRecording(metadata);
    })
  }
  private async isRecording() {
    return await this.directorRecordingChange.pipe(take(1)).toPromise();
  }
}
