import { BehaviorSubject, combineLatest, fromEvent, ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import videojs, { VideoJsPlayer } from 'video.js';

const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');
const ClickableComponent = videojs.getComponent('ClickableComponent');

class OverlayTimelineMarker extends ClickableComponent {
  private totalTime: number;
  private curPosition: number;
  mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
    tap(() => {
      this.removeClass('moving');
    }),
  );
  mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
    distinctUntilChanged((e1,e2) => e1.clientX === e2.clientX),
  );
  mouseDown$ = fromEvent<MouseEvent>(this.el(), 'mousedown').pipe(
    tap(e => {
      this.addClass('moving');
      e.preventDefault();
      e.stopPropagation();
      const el = this.el() as HTMLButtonElement;
      const parent = el.parentElement;
      const pW = parent.clientWidth;
      this.curPosition = (el.offsetLeft / pW) * 100
    }),
  );
  mouseDrag$ = this.mouseDown$.pipe(
    switchMap((d) => this.mouseMove$.pipe(
      map(e => ({start: d, end: e})),
      startWith({start: d, end: d}),
      takeUntil(this.mouseUp$),
    )),
  );

  private dragSub: Subscription;
  private timeSub: Subscription;

  private setTimeSrc = new Subject<number>();
  setTime$ = this.setTimeSrc.asObservable();

  constructor(player: VideoJsPlayer, initialPosition: number) {
    super(player);
    this.totalTime = Math.round(player.duration() * 10) / 10;
    const initialLeft = (initialPosition / this.totalTime) * 100;
    const el = this.el() as HTMLDivElement;
    el.style.left = `${initialLeft}%`;

    this.dragSub = this.mouseDrag$.subscribe({
      next: e => {
        const el = this.el() as HTMLDivElement;
        const parent = el.parentElement;
        const oX = e.start.clientX - e.end.clientX;
        const pW = parent.clientWidth;
        const eW = el.clientWidth;
        const percW = oX / pW;
        const maxW = Math.round((100 - ((eW / pW) * 100)) * 10) / 10;
        const newW = Math.min(Math.max(Math.round((this.curPosition - (percW * 100)) * 10) / 10, 0), maxW);
        const newTime = Math.round((this.totalTime * (newW / 100) * 10)) / 10;
        el.style.left = `${newW}%`;
        this.setTimeSrc.next(newTime);
      },
    });
    this.timeSub = this.setTimeSrc.pipe(
      distinctUntilChanged(),
    ).subscribe(
      t => this.player().currentTime(t),
    );
  }
  createEl() {
    const el = videojs.dom.createEl('div', {
      className: 'btn-overlay-timeline-marker',
    });
    return el;
  }

  dispose(): void {
    this.dragSub.unsubscribe();
    this.timeSub.unsubscribe();
    this.setTimeSrc.complete();
    super.dispose();
  }
}

class OverlayTimeline extends Component {
  startMarker: OverlayTimelineMarker;
  endMarker: OverlayTimelineMarker;

  private timeFrameUpdatedSource = new Subject<{start: number, end: number}>();
  timeFrameUpdated$ = this.timeFrameUpdatedSource.asObservable();
  constructor(player: VideoJsPlayer, public definition: OverlayDefinition) {
    super(player);
    if (this.player().readyState() < 1) {
      player.one('loadedmetadata', () => {
        this.setUp();
      });
    } else {
      this.setUp();
    }
  }

  private setUp() {
    this.startMarker = new OverlayTimelineMarker(this.player(), this.definition.timeFrame.start);
    this.endMarker = new OverlayTimelineMarker(this.player(), this.definition.timeFrame.end);
    this.addChild(this.startMarker);
    this.addChild(this.endMarker);
    combineLatest([
      this.startMarker.setTime$.pipe(startWith(this.definition.timeFrame.start)), 
      this.endMarker.setTime$.pipe(startWith(this.definition.timeFrame.end))
    ]).pipe(map(([start, end]) => ({start, end}))).subscribe(this.timeFrameUpdatedSource);
  }

  createEl(): Element {
      const el = videojs.dom.createEl('div', {
        className: 'btn-overlay-timeline',
      });
      return el;
  }

  dispose(): void {
    super.dispose();
  }
}

class OverlayTimelineContainer extends Component {
  timeline: OverlayTimeline
  constructor(player: VideoJsPlayer, definition: OverlayDefinition) {
    super(player);
    this.timeline = new OverlayTimeline(player, definition)
    this.addChild(this.timeline);
  }

  createEl(): Element {
      const el = videojs.dom.createEl('div', {
        className: 'btn-overlay-timeline-container',
      });
      return el;
  }
}

class OverlayButton extends Button {
  showing = false;
  editing = false;

  private positionUpdatedSource = new BehaviorSubject<{x: number, y: number}>({x:0, y:0});
  positionUpdated$ = this.positionUpdatedSource.asObservable();
  private timeFrameUpdatedSource = new ReplaySubject<{start: number, end: number}>(1);
  timeFrameUpdated$ = this.timeFrameUpdatedSource.asObservable();

  ctaUpdated$ = combineLatest([this.positionUpdated$, this.timeFrameUpdated$]).pipe(
    map(([position, timeframe]) => ({timeframe, position})),
    skip(1),
  );

  mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
    tap(() => {
      this.removeClass('moving');
      this.extractPosition();
      this.positionUpdatedSource.next(this.curPosition);
    }),
  );
  mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
    distinctUntilChanged((e1,e2) => e1.clientX === e2.clientX && e1.clientY === e2.clientY),
  );
  mouseDown$ = fromEvent<MouseEvent>(this.el(), 'mousedown').pipe(
    tap(e => {
      this.extractPosition(this.hasClass('positioned'));
      this.removeClass('positioned');
      this.addClass('moving');
      e.preventDefault();
      e.stopPropagation();
    }),
  );
  mouseDrag$ = this.mouseDown$.pipe(
    switchMap((d) => this.mouseMove$.pipe(
      map(e => ({start: d, end: e})),
      startWith({start: d, end: d}),
      takeUntil(this.mouseUp$),
    )),
  );

  curPosition: {x: number, y: number};
  dragSub: Subscription;

  private extractPosition(positioned = false) {
    const el = this.el() as HTMLButtonElement;
    const parent = el.parentElement;
    const pW = parent.clientWidth;
    const pH = parent.clientHeight;
    this.curPosition = {
      x: ((el.offsetLeft - (positioned ? (el.clientWidth / 2) : 0)) / pW) * 100,
      y: ((el.offsetTop - (positioned ? (el.clientHeight / 2) : 0)) / pH) * 100,
    };
  }

  constructor(player: videojs.Player, public readonly definition: OverlayDefinition) {
    super(player);
    this.addClass('overlay-btn');
    this.controlText(this.definition.buttonText);
    const el = this.el() as HTMLButtonElement;
    el.innerHTML = this.definition.buttonText;
    if (typeof definition.position === 'string') {
      this.addClass('positioned');
      this.addClass(definition.position);
    } else {
      const position = definition.position
      this.player().one('ready', () => {
        this.setPosition(position.x, position.y);
        this.positionUpdatedSource.next(position);
      });
    }
    this.hide();
  }

  hide() {
    super.hide();
    this.showing = false;
  }

  show() {
    super.show();
    this.showing = true;
  }

  handleClick(event: videojs.EventTarget.Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.editing) {
      this.player().pause();
      window.open(this.definition.linkUrl, '_blank');
      this.player().trigger({type: 'overlayClick', target: this});
    }
  }

  private editTimeline: OverlayTimelineContainer;
  setEditing() {
    this.editTimeline = new OverlayTimelineContainer(this.player(), this.definition);
    this.player().addChild(this.editTimeline);
    this.player().currentTime(this.definition.timeFrame.start);
    this.editing = true;
    this.addClass('editing');
    const el = this.el() as HTMLButtonElement;
    const parent = el.parentElement;
    this.dragSub = this.mouseDrag$.subscribe({
      next: e => {
        const oX = e.start.clientX - e.end.clientX;
        const oY = e.start.clientY - e.end.clientY;
        const pW = parent.clientWidth;
        const pH = parent.clientHeight;
        const percW = oX / pW;
        const percH = oY / pH;
        const maxW = 100;
        const maxH = 100;
        const newW = Math.min(Math.max(this.curPosition.x - (percW * 100), 0), maxW);
        const newH = Math.min(Math.max(this.curPosition.y - (percH * 100), 0), maxH);
        this.setPosition(newW, newH);
      },
    });
    this.editTimeline.timeline.timeFrameUpdated$.subscribe(tf => this.timeFrameUpdatedSource.next(tf));
  }

  private setPosition(x: number, y: number) {
    const el = this.el() as HTMLButtonElement;
    const p = el.parentElement;
    if (x > 50) {
      el.style.left = 'unset';
      const offX = (el.clientWidth / p.clientWidth) * 100;
      el.style.right = `${Math.max(100 - x - offX, 0)}%`;
    } else {
      el.style.right = 'unset';
      el.style.left = `${x}%`;
    }
    if (y > 50) {
      el.style.top = 'unset';
      const offY = ((el.clientHeight + 30) / p.clientHeight) * 100;
      el.style.bottom = `calc(${Math.max(100 - y - offY, 0)}% + 30px)`;
    } else {
      el.style.bottom = 'unset';
      el.style.top = `${y}%`;
    }
  }

  setNotEditing() {
    this.player().removeChild(this.editTimeline);
    this.editTimeline.dispose();
    this.editTimeline = null;
    this.editing = false;
    this.removeClass('editing');
    this.dragSub.unsubscribe();
    this.dragSub = null;
  }

  determineVisibility(time: number) {
    const shouldShow = time >= this.definition.timeFrame.start 
      && time <= this.definition.timeFrame.end;
    if (shouldShow && !this.showing) {
      this.show();
    } else if (!shouldShow && this.showing) {
      this.hide();
    }
  }
  
  dispose() {
    if (this.editing && this.dragSub && !this.dragSub.closed) {
      this.dragSub.unsubscribe();
    }
    if (this.editTimeline) {
      this.player().removeChild(this.editTimeline);
      this.editTimeline.dispose();
      this.editTimeline = null;
    }
    super.dispose();
  }
}

videojs.registerComponent('OverlayButton', OverlayButton);

interface OverlayDefinition {
  buttonText: string;
  linkUrl: string;
  position: string | {
    x: number,
    y: number,
  },
  timeFrame: {
    start: number,
    end: number,
  },
}

interface OverlayOptions {
  overlays: OverlayDefinition[];
}

const Plugin = videojs.getPlugin('plugin');

class OverlayPlugin extends Plugin {
  private overlayButtons: OverlayButton[];
  private editing = false;
  constructor(player: VideoJsPlayer, private options: OverlayOptions) {
    super(player);
    this.overlayButtons = this.options.overlays.map(o => new OverlayButton(player, o));
    this.overlayButtons.forEach(b => player.addChild(b));
    this.player.on(this.player, 'timeupdate', this.overlayListener);
  }

  private overlayListener = () => {
    const time = this.player.currentTime();
    this.overlayButtons.forEach(button => button.determineVisibility(time));
  }

  dispose(): void {
    this.player.off(this.player, 'timeupdate', this.overlayListener);
    super.dispose();
  }

  toggleEdit(on: boolean) {
    const ctaEdit = this.overlayButtons[0];
    // console.log('go go gadget edit', ctaEdit)
    if (ctaEdit) {
      if (on && !this.editing) {
        this.editing = true;
        this.player.show();
        this.player.pause();
        this.player.addClass('edit-mode');
        ctaEdit.setEditing();
        return ctaEdit;
      } else if (!on && this.editing) {
        this.editing = false;
        this.player.removeClass('edit-mode');
        ctaEdit.setNotEditing();
      }
    }
    return null;
  }
}

videojs.registerPlugin('overlays', OverlayPlugin);

declare module 'video.js' {
  export interface VideoJsPlayer {
    overlays: (overlays?: OverlayOptions) => OverlayPlugin;
  }

  export interface VideoJsPlayerPluginOptions {
    overlays?: OverlayOptions;
  }
}
