<div mat-dialog-content>
  <sketch-picker
    class="layer-color"
    [control]="customColorControl"
  ></sketch-picker>
</div>
<div mat-dialog-actions>
  <button
    type="button"
    data-cy="button-color-save"
    class="mat-button-flex-8"
    mat-flat-button
    (click)="onSave()"
  >
    Save
  </button>
</div>
