export const isNowBetween = (minDate: Date, maxDate: Date) => {
  if (!minDate || !maxDate) {
    return false;
  }

  const now = Date.now();
  return minDate.getTime() <= now && maxDate.getTime() >= now;
};

export const willBeToday = (date: Date) => {
  if (!date) {
    return false;
  }

  const now = new Date();

  return (
    date.getTime() > now.getTime() &&
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

export const isFuture = (date: Date) => {
  if (!date) {
    return false;
  }

  return date.getTime() > Date.now();
};

export const humanDateDiff = (date1: Date, date2: Date) => {
  const dateDiff = date1.getTime() - date2.getTime();

  return {
    hours: String(Math.floor(dateDiff / 1000 / 60 / 60)).padStart(2, '0'),
    minutes: String(Math.floor((dateDiff / 1000 / 60) % 60)).padStart(2, '0'),
    seconds: String(Math.floor(dateDiff / 1000) % 60).padStart(2, '0'),
  };
};

const padZerosStart = (num: number, maxLength) =>
  num.toString().padStart(maxLength, '0');

export const formatMillisecondsAsTime = (ms: number): string => {
  const h = Math.floor(ms / 3_600_000);
  const m = Math.floor((ms - h * 3_600_000) / 60_000);
  const s = Math.floor((ms - h * 3_600_000 - m * 60_000) / 1_000);
  ms = Math.trunc(ms - h * 3_600_000 - m * 60_000 - s * 1_000);

  return `${h ? `${h}:` : ''}${padZerosStart(m, h ? 2 : 0)}:${padZerosStart(
    s,
    2
  )}.${padZerosStart(ms, 3)}`;
};

// This function handels "HH:MM:SS" as well as "MM:SS" or "SS".
export const strToMiliseconds = (str: string) => {
  if (!str) {
    return null;
  }

  const p = str.split(':');
  let s = 0, m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s * 1000;
}

export const milisecondstoStr = (miliseconds: number) => {
  if (miliseconds === null || miliseconds === undefined) {
    return null;
  }

  return new Date(miliseconds).toISOString().substring(14, 19);
}
