import { Component } from '@angular/core';
import { Cleanupable } from '@openreel/common/classes';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'openreel-save-for-later',
  templateUrl: './openreel-save-for-later.component.html',
  styleUrls: ['./openreel-save-for-later.component.scss'],
})
export class OpenreelSaveForLaterComponent extends Cleanupable {
  constructor(private dialog: MatDialogRef<OpenreelSaveForLaterComponent>) {
    super();
  }

  onDialogCloseClicked(value: boolean) {
    this.dialog.close({ value: value });
  }
}
