import { getSectionTimelines } from '../helpers';
import { BaseCommand } from './base.command';

export interface SwitchSectionMainVideosEvent {
  sectionId: string;
}

export class SwitchSectionMainVideosCommand extends BaseCommand<SwitchSectionMainVideosEvent> {
  run({ sectionId }: SwitchSectionMainVideosEvent) {
    const sectionMainTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId);

    if (sectionMainTimelines.length > 1) {
      throw new Error('Multiple main timelines found');
    }

    if (sectionMainTimelines[0].timelines.length < 2) {
      console.warn('Less than 2 child timelines found, nothing to switch.')
      return this.ok();
    }

    if (sectionMainTimelines[0].timelines.length > 3) {
      throw new Error('More than 2 child timelines found, switch is not supported in this case.');
    }

    sectionMainTimelines[0].timelines[0].layers.forEach((layer1, index) => {
      if (layer1.type === 'video') {
        const layer2 = sectionMainTimelines[0].timelines[1].layers[index]
        if (layer2?.type === 'video') {
          const assetId = layer1.assetId;
          const visibility = layer1.visibility;
          layer1.assetId = layer2.assetId;
          layer1.visibility = layer2.visibility;
          layer2.assetId = assetId;
          layer2.visibility = visibility;
        }
      } else {
        console.warn('Switch is not supported on non video layers')
      }
    });

    return this.ok();
  }
}
