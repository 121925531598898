<form
  class="login-form"
  (submit)="sendForgotPasswordEmail()"
>
  <div class="form-field-label">
    <span>Email address</span>
  </div>
  <mat-form-field appearance="outline">
    <input
      matInput
      placeholder="Email address"
      [(ngModel)]="email"
      name="email"
      [disabled]="disabled"
    />
  </mat-form-field>
  <button
    class="mat-button-large"
    mat-flat-button
    color="primary"
    [disabled]="disabled"
  >
    Submit
  </button>
</form>

<p class="footer-note">
  <a [routerLink]="loginRoute">Login</a>
</p>
