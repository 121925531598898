import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { interval, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import { AuthService } from '../../services/auth/auth.service';
import { GUEST_SELECT_ACCOUNT } from '../../route-utils';

class TfaPopupComponentData {
  jwtToken: string;
}

@Component({
  selector: 'openreel-tfa-popup',
  templateUrl: './tfa-popup-component.html',
  styleUrls: ['./tfa-popup.component.scss'],
})
export class TfaPopupComponent extends Cleanupable implements OnInit, OnDestroy {
  otp: string;
  disabled = false;
  form: FormGroup;
  timerInterval$ = interval(1000); // 1s
  time = 120;
  timer$ = timer(this.time * 1000); // 120sec
  countDown$ = this.timerInterval$.pipe(
    take(this.time),
    map((val) => this.time - val)
  );
  reSentOtp = false;
  countDownCompleted = false;

  constructor(
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: TfaPopupComponentData,
    private dialogRef: MatDialogRef<TfaPopupComponent>,
    private toastr: ToastrService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super();
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      otp: new FormControl('', [Validators.required]),
    });
    this.countDown$.pipe(map((val) => this.time - val));
    this.subscriptions.push(
      this.timer$.subscribe(() => {
        this.countDownCompleted = true;
      })
    );
  }

  async sendOtp() {
    const otp = this.form.get('otp').value;
    this.disabled = true;
    try {
      await this.authService.send2FaOtpVerification(this.data.jwtToken, otp).toPromise();
      let redirect = this.activatedRoute.snapshot.queryParams?.redirect;
      if (this.activatedRoute.snapshot.fragment) {
        redirect += `#${this.activatedRoute.snapshot.fragment}`;
      }
      if (redirect) {
        this.router.navigateByUrl('/' + GUEST_SELECT_ACCOUNT + '?redirect=' + redirect);
      } else {
        this.router.navigate(['/' + GUEST_SELECT_ACCOUNT]);
      }
      this.dialogRef.close();
    } catch (err) {
      const error = err.error && err.error.message ? err.error.message : err.message;
      this.toastr.error(error, 'Error!');
      if (err.status === 401) {
        this.onDialogCloseClicked();
      }
    }
    this.disabled = false;
  }

  onDialogCloseClicked() {
    this.dialogRef.close();
  }

  async resendOtp() {
    try {
      const { message } = await this.authService.reSendTfaOtp(this.data.jwtToken).toPromise();
      this.reSentOtp = true;
      this.toastr.success(message, 'Success');
    } catch (err) {
      this.toastr.error(err.message, 'Error');
    }
  }

  disableResendBtn() {
    return !this.countDownCompleted || this.reSentOtp;
  }
}
