import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'clipDuration' })
export class ClipDuration implements PipeTransform {
  transform(seconds: number) {
    if (seconds >= 60) {
      return Math.round(seconds / 60) + ' mins';
    }
    return (seconds > 0 ? Math.round(seconds) : 0) + ' secs';
  }
}
