export const invalidSourceError = 'InvalidVideoSource';
export const unsupportedCapabilitesError = 'UnsupportedCapabilities';
export const unsupportedSourceError = 'UnsupportedSource';
export const streamError = 'StreamError';

export const newError = (name: string, message: string): Error => {
  const error = new Error(message);
  error.name = name;
  return error;
};

export const newInvalidVideoSourceError = (message: string): Error =>
  newError(invalidSourceError, message);

export const newUnsupportedCapabilities = (message: string): Error =>
  newError(unsupportedCapabilitesError, message);

export const newUnsupportedSource = (message: string): Error =>
  newError(unsupportedSourceError, message);

export const newStreamError = (message: string): Error =>
  newError(streamError, message);
