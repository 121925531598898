import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Cleanupable,
  RichTextEditorComponent,
  SubjectTeleprompterComponent,
  TeleprompterScript,
  TeleprompterScriptEdit,
  TelepromterApiService,
  teleprompterOptions,
} from '@openreel/common';
import { filter, first } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';
import { ProjectFacade } from '../../../../store/facades/project.facade';
import { Router } from '@angular/router';
import { SelfRecordTeleprompterFacade } from '../../store/facades/self-record-teleprompter.facade';
import { SelfRecordTeleprompterStatus } from '../../store/interfaces/self-record-teleprompter.interfaces';
import { MixpanelService } from '@openreel/creator/app/analytics/mixpanel.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'openreel-wf-self-record-teleprompter-dialog',
  templateUrl: './self-record-teleprompter-dialog.component.html',
  styleUrls: ['./self-record-teleprompter-dialog.component.scss'],
})
export class SelfRecordTeleprompterDialogComponent
  extends Cleanupable
  implements AfterViewInit, OnInit, OnDestroy
{
  selectedScript: TeleprompterScript;
  selectedSpeed = 130;
  selectedFontSize = 30;
  dirty = false;
  loading = false;
  options = teleprompterOptions;

  scriptTitle: string;
  private text = new BehaviorSubject<string>(null);
  text$ = this.text.asObservable();
  private status = new BehaviorSubject<SelfRecordTeleprompterStatus>('stopped');
  status$ = this.status.asObservable();

  editorHeight = 330;

  @ViewChild('editorWrapper') editorWrapper: ElementRef;
  @ViewChild(RichTextEditorComponent) richTextEditor: RichTextEditorComponent;
  @ViewChild(SubjectTeleprompterComponent)
  teleprompter: SubjectTeleprompterComponent;

  constructor(
    private dialogRef: MatDialogRef<SelfRecordTeleprompterDialogComponent>,
    public readonly router: Router,
    private readonly projectFacade: ProjectFacade,
    public readonly selfRecordTeleprompterFacade: SelfRecordTeleprompterFacade,
    private readonly mixpanelService: MixpanelService,
    private readonly teleprompterApiService: TelepromterApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selfRecordTeleprompterFacade.refreshScripts();
    this.dialogRef.addPanelClass('teleprompter-resize-popup');
    this.dialogRef.addPanelClass('teleprompter-popup');

    this.subscriptions.push(
      this.selfRecordTeleprompterFacade.status$.subscribe((status) => {
        if (status !== this.status.value) {
          if (status === 'playing') {
            this.play();
          } else if (status === 'stopped') {
            this.reset();
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.selfRecordTeleprompterFacade.scripts$.subscribe((scripts) => {
        if (scripts[0]) {
          this.selectedScript = scripts[0];
          this.onScriptChanged();
        }
      })
    );

    setTimeout(() => {
      this.onResize();
    }, 200)
  }

  ngOnDestroy() {
    this.selfRecordTeleprompterFacade.cleanup();
    this.cleanAllSubscriptions();
  }

  onResize() {
    // subtract 60 for the editor toolbar
    this.editorHeight = this.editorWrapper.nativeElement.getBoundingClientRect().height - 60;
    if (this.editorHeight < 200) {
      this.editorHeight = 205;
    }
  }

  onScriptChanged() {
    this.text.next(this.selectedScript?.content || '');
    this.richTextEditor.setEditorContent(this.text.value);
    this.scriptTitle = this.selectedScript?.title;
    this.dirty = false;
  }

  onTextChanged(text: string) {
    if (this.text.value !== text) {
      this.text.next(text);
      this.dirty = true;
    }
  }

  onTitleChanged() {
    this.mixpanelService.logEvent(
      'self_record_teleprompter_change_name',
      'Self record teleprompter update name'
    );

    this.dirty = true;
  }

  edit() {
    this.teleprompter.stop();
    this.status.next('stopped');
    this.selfRecordTeleprompterFacade.updateStatus('stopped');
  }

  reset() {
    this.mixpanelService.logEvent(
      'self_record_teleprompter_reset',
      'Self record teleprompter reset'
    );

    this.teleprompter.reset();
    this.status.next('paused');
    this.selfRecordTeleprompterFacade.updateStatus('paused');
  }

  play() {
    this.mixpanelService.logEvent(
      'self_record_teleprompter_play',
      'Self record teleprompter play'
    );

    if (this.status.value === 'paused' && this.teleprompter) {
      this.teleprompter.resume();
    } else {
      this.teleprompter.loadTeleprompter({
        countDown: 0,
        fontSize: this.selectedFontSize,
        speed: this.selectedSpeed,
        split: null,
        background: 'black_in_white',
        content: this.text.value,
        keepOnScreen: true,
        contentChange: true,
      });
    }

    this.status.next('playing');
    this.selfRecordTeleprompterFacade.updateStatus('playing');
  }

  pause() {
    this.mixpanelService.logEvent(
      'self_record_teleprompter_pause',
      'Self record teleprompter pause'
    );

    this.status.next('paused');
    this.selfRecordTeleprompterFacade.updateStatus('paused');
    this.teleprompter.pause();
  }

  async save() {
    if (this.loading) return;

    let captureProjectId: number;
    this.projectFacade.captureProjectId$
      .pipe(
        filter((p) => !!p),
        first()
      )
      .subscribe(
        (newCaptureProjectId) => (captureProjectId = newCaptureProjectId)
      );

    this.loading = true;

    this.mixpanelService.logEvent(
      'self_record_teleprompter_save',
      'Self record teleprompter save'
    );

    const script: TeleprompterScriptEdit = {
      title:
        this.scriptTitle ||
        'Default title ' + Math.round(10000 * Math.random()),
      content: this.text.value,
      scroll_speed: this.selectedSpeed,
      ts_fontsize: this.selectedFontSize,
    };

    if (this.selectedScript) {
      await this.teleprompterApiService.editScript(
        this.selectedScript.ovra_teleprompter_script_id,
        script
      );
    } else {
      await this.teleprompterApiService.createScript({
        ...script,
        project_id: captureProjectId,
      });
    }

    this.selfRecordTeleprompterFacade.refreshScripts();

    this.loading = false;
    this.dirty = false;
  }
}
