import {
  Durations,
  AssetLayer,
  Timeline,
  TimelineItem,
  timelineItemFactory,
  WorkflowDataDto,
} from '@openreel/creator/common';
import { sortBy } from 'lodash';
import { SectionData } from '../canvas.interfaces';

/**
 * Maps timeline data to canvas data
 */
const getSectionData = (durations: Durations): SectionData[] => [
  {
    type: 'intro',
    startAt: 0,
    endAt: durations.intro,
    duration: durations.intro,
    timelines: [],
  },
  {
    type: 'main',
    startAt: durations.intro,
    endAt: durations.intro + durations.main,
    duration: durations.main,
    timelines: [],
  },
  {
    type: 'outro',
    startAt: durations.intro + durations.main,
    endAt: durations.intro + durations.main + durations.outro,
    duration: durations.outro,
    timelines: [],
  },
];

export const mapTimelinesDataToCanvasData = (
  durations: Durations,
  timelines: Timeline[],
  workflow: WorkflowDataDto
) => {
  const data: SectionData[] = getSectionData(durations);
  const dataDict = new Map<string, { timeline: Timeline; item: TimelineItem }>();

  // NOTE: hack until we refactor out timelines layer into separate actual timelines
  const timelinesToParse =
    timelines.length === 1 && timelines[0].layers.length === 1 && timelines[0].layers[0].type === 'timelines'
      ? timelines[0].layers[0].children
      : timelines;

  timelinesToParse.forEach((timeline, index) => {
    const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility.startAt);

    data[1].timelines.push({
      type: timeline.type,
      index,
      startAt: 0,
      endAt: durations.main,
      duration: durations.main,
      items: sortedLayers.map((layer) => {
        const item = timelineItemFactory(layer, timeline.type, workflow);

        const duration = layer.visibility.endAt - layer.visibility.startAt;
        const asset = workflow.assets.find((a) => a.id === (layer as AssetLayer).assetId);

        dataDict.set(layer.layerId, { timeline, item });

        return {
          layerId: layer.layerId,
          linkedLayerId: null,
          startAt: layer.visibility.startAt,
          endAt: layer.visibility.endAt,
          duration,
          minDuration: asset.data?.duration,
          video: {
            id: asset.file.path.toString(),
            trimFrom: asset.trimFrom,
            trimTo: asset.trimTo,
          },
        };
      }),
    });
  });

  return { data, dataDict };
};
