import { Injectable } from '@angular/core';
import * as OT from '@opentok/client';

@Injectable()
export class OpentokStreamingService {
  getSession(apiKey: string, sessionId: string, token: string): OT.Session {
    const session = OT.initSession(apiKey, sessionId);
    this.connect(session, token);
    return session;
  }
  connect(session: OT.Session, token: string): Promise<OT.Session> {
    return new Promise((resolve, reject) => {
      session.connect(token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }
}
