import { BrandKitPermissions, LayoutDto, TemplateSettings, WorkflowDataDto } from '@openreel/creator/common';
import { LayoutType, TemplateDto } from '../template/workflow-template.interfaces';

export const EVT_WORKFLOW_RENDER_PROGRESS = 'workflow-render-progress';
export const EVT_WORKFLOW_TRANSCRIBE_PROGRESS = 'workflow-transcribe-progress';
export const EVT_WORKFLOW_THUMBNAILS_DONE = 'workflow-thumbnails-done';
export const EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID = 'join-to-room-via-capture-project-id';
export const getRoomByCaptureProjectId = (captureProjectId): string => 'capture-project-id-' + captureProjectId;

export interface CreateWorkflowProjectRequest {
  templateId: number;
  templateLayoutId?: number;
}

export interface UpdateWorkflowProjectRequest {
  id: number;
  captureProjectId: number;
  name?: string;
  workflow?: WorkflowDataDto;
  updateBrandKitToLatestTimestamp?: boolean;
}

export interface WorkflowLayoutDto {
  id: number;
  layoutId: number;
  name: string;
  type: LayoutType;
  icon: string;
  data: LayoutDto;
}

export interface WorkflowProjectDto {
  id: number;
  createdBy: number;
  name: string;
  templateId: number;
  template?: TemplateDto;
  workflow: WorkflowDataDto;
  video?: WorkflowProjectVideoDto;
  brandKitId?: number;
  brandKitHasNewerVersion?: boolean;
  brandKitPermissions?: BrandKitPermissions;
  templateSettings?: TemplateSettings;
  hasCaptions?: boolean;
  layouts?: WorkflowLayoutDto[];
  templateLayoutId?: number;
  captureProjectId: number;
}

export interface WorkflowProjectVideoDto {
  triggeredAt?: Date;
  renderedAt?: Date;
  signedUrl?: string;
  state: RenderState;
}

export interface RenderProgress {
  state: RenderState;
  progress: number;
  projectId: number;
  signedUrl?: string;
  captureProjectId?: number;
  renderedAt?: Date;
}

export interface TranscribeProgress {
  state: TranscribeState;
  progress: number;
  projectId: number;
  captureProjectId?: number;
}

export interface ThumbnailSpritesheetFinished {
  captureProjectId: number;
  videoId: number;
  signedUrl: string;
}

export interface WorkflowProject {
  captureProjectId: number;
  createdBy: number;
  id: number;
  name: string;
  step: number;
  templateId: number;
  workflow: never;
  render: RenderProgress;
}

export type RenderState = 'rendering' | 'done' | 'failed' | 'cancelled';
export type TranscribeState = 'transcribing' | 'done' | 'failed' | 'cancelled';

export interface WorkflowArchivedData {
  id: number;
  name: string;
  deleted_at: Date;
}
